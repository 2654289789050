import React, {useEffect, useState} from 'react';
import {Label} from 'reactstrap';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {BodyItemStyled, BodyViewStyled, HeaderItemStyled, HeaderViewStyled, TableViewStyled, Row} from './styled';

const TableView = ({columns, data, style, onChange}) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(data);
  }, [JSON.stringify(data)]);// eslint-disable-line

  const TooltipCustom = ({element, keyValue, keyTooltip}) => {
    return (
      <>
        <Label className="float-label m-0 w-100">
          <button className="button-tooltip w-100" type="button">
            <Tooltip
              arrow
              style={{height: '100%'}}
              classes={makeStyles((theme) => ({
                arrow: {
                  color: theme.palette.common.black,
                },
                tooltip: {
                  backgroundColor: theme.palette.common.black,
                  fontSize: '12px',
                },
              }))()}
              title={<p>{element[keyTooltip]}</p>}>
              <BodyItemStyled key={keyValue}>{element[keyValue]}</BodyItemStyled>
            </Tooltip>
          </button>
        </Label>
      </>
    );
  };
  return (
    <TableViewStyled style={style}>
      <HeaderViewStyled style={{gridTemplateColumns: columns?.map((item) => item?.width)?.join(' ')}}>
        {columns?.map((item) => (
          <HeaderItemStyled key={item?.key}>
            {item?.name}
            {item?.checkAll}
          </HeaderItemStyled>
        ))}
      </HeaderViewStyled>
      <BodyViewStyled>
        {rows.map((el, i) => (
          <Row key={`${i}-row`} style={{gridTemplateColumns: columns?.map((item) => item?.width)?.join(' ')}}>
            {columns?.map((item) => {
              if (item.editType === 'booleanedit') {
                return (
                  <BodyItemStyled key={item?.key}>
                    <input
                      type="checkbox"
                      checked={el[item?.key]}
                      onChange={async (element) => {
                        if (onChange) {
                          await onChange(element, el, item?.key);
                        }
                      }}
                    />
                  </BodyItemStyled>
                );
              }
              if (item?.tooltipKey) {
                return <TooltipCustom element={el} keyValue={item?.key} keyTooltip={item?.tooltipKey} />;
              }
              return <BodyItemStyled key={item?.key}>{el[item?.key]}</BodyItemStyled>;
            })}
          </Row>
        ))}
      </BodyViewStyled>
    </TableViewStyled>
  );
};

export default TableView;
