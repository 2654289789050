import apiNew from '../apiNew';

const fetchCategoryOwnFarming = (params = {}) => {
  return apiNew.get(`/category_own_farming`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryOwnFarmingbyId = (id, params = {}) => {
  return apiNew.get(`/category_own_farming/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryOwnFarming = (body = {}) => {
  return apiNew.post(`/category_own_farming`, body);
};

const changeCategoryOwnFarmingbyId = (id, body = {}) => {
  return apiNew.put(`/category_own_farming/${id}`, body);
};

const deleteCategoryOwnFarming = (id) => {
  return apiNew.delete(`/category_own_farming/${id}`);
};

export {fetchCategoryOwnFarming, fetchCategoryOwnFarmingbyId, registerCategoryOwnFarming, changeCategoryOwnFarmingbyId, deleteCategoryOwnFarming};
