import api from '../api';

const fetchCategoryWorkFronts = (params = {}) => {
  return api.get(`/category_work_fronts`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryWorkFrontsbyId = (id, params = {}) => {
  return api.get(`/category_work_fronts/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryWorkFronts = (body = {}) => {
  return api.post(`/category_work_fronts`, body);
};

const changeCategoryWorkFrontsbyId = (id, body = {}) => {
  return api.put(`/category_work_fronts/${id}`, body);
};

const deleteCategoryWorkFronts = (id) => {
  return api.delete(`/category_work_fronts/${id}`);
};

export {
  fetchCategoryWorkFronts,
  fetchCategoryWorkFrontsbyId,
  registerCategoryWorkFronts,
  changeCategoryWorkFrontsbyId,
  deleteCategoryWorkFronts,
};
