const validationFindData = (data) => {
  let validated = true;

  data = data.filter((item) => {
    return item.category_reproduction.title !== 'Fêmeas em cobertura' && item.category_reproduction.title !== 'Fêmeas prenhes';
  });
  data.forEach((row) => {
    validated = validated && row.validated;
  });

  return validated;
};

const rideColumns = (actualHarvest) => [
  {
    key: 'frente_de_trabalho_e_funcao',
    field: 'frente_de_trabalho_e_funcao',
    name: 'Referências',
    disableFilter: true,
    disabledEdit: true,
    subFields: [
      {
        key: 'category_reference',
        valueKey: 'category_reference.title',
        field: 'category_reference',
        name: 'Frente de trabalho',
        disableFilter: true,
        textAlign: 'Center',
        disabledEdit: true,
        groupTitle: (data) => {
          return `Referência: ${data}`;
        },
      },
      {
        key: 'category_reproduction',
        valueKey: 'category_reproduction.title',
        field: 'category_reproduction',
        disableFilter: true,
        name: 'Categorias',
        textAlign: 'Center',
        disabledEdit: true,
      },
    ],
  },
  {
    key: 'amount_harvest_current',
    field: 'amount_harvest_current',
    disableFilter: true,
    name: `Quantidade safra(${actualHarvest.title})`,
    textAlign: 'Center',
    editType: 'weightedit',
    posFix: (line) => {
      const options = [
        'Novilhas',
        'Novilhas desafio',
        'Primíparas',
        'Vacas',
        'Vacas solteiras (chance)',
        'Matrizes',
        'Fertilidade touro',
        '% Matrizes inseminadas',
        '% Fêmeas de reprodução',
        'Fertilidade IATF/IA',
        '% Matrizes inseminadas',
        '% Abortos',
        '% Natimortos',
        'Taxa de aproveitamento de fêmeas',
      ];
      if (options.find((item) => item === line.category_reproduction.title)) {
        return '%';
      }
      return '';
    },
    // required: (e) => {
    //   if (e?.data?.category_reproduction?.title === 'Novilhas desafio em cobertura' || e?.data?.category_reproduction?.title === 'Doses gastas por prenhez') {
    //     return false;
    //   }
    //   return true;
    // },
    returnFormat: (value, item, po) => {
      // eslint-disable-next-line radix
      if (po[22].category_reproduction.title === 'Doses gastas por prenhez') {
        return value;
      }
      // eslint-disable-next-line radix
      return parseInt(value);
    },
    accumulatorItem: (acc, item) => acc + item,
    extraParams: {
      minValue: 1,
    },
  },
  {
    key: 'amount_harvest_next',
    field: 'amount_harvest_next',
    disableFilter: true,
    name: `Quantidade safra(${actualHarvest.first_year + 1}/${actualHarvest.last_year + 1})`,
    returnFormat: (value) => {
      // eslint-disable-next-line radix
      return parseInt(value);
    },
    posFix: (line) => {
      const options = [
        'Novilhas',
        'Novilhas desafio',
        'Primíparas',
        'Vacas',
        'Vacas solteiras (chance)',
        'Matrizes',
        'Fertilidade touro',
        '% Matrizes inseminadas',
        '% Fêmeas de reprodução',
        '% Matrizes inseminadas',
        '% Abortos',
        '% Natimortos',
        'Fertilidade IATF/IA',
        'Taxa de aproveitamento de fêmeas',
      ];
      if (options.find((item) => item === line.category_reproduction.title)) {
        return '%';
      }
      return '';
    },
    textAlign: 'Center',
    editType: 'numericedit',
    required: false,
    accumulatorItem: (acc, item) => acc + item,
    extraParams: {
      minValue: 1,
    },
  },

  {
    key: 'validated',
    field: 'validated',
    name: 'Validar',
    disableFilter: true,
    textAlign: 'Center',
    editType: 'booleanedit',
    validationHeader: {showLabel: false, validationFindData},
  },
];

export default rideColumns;
