import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import {Card} from 'reactstrap';
import Section from '../../components/Section/Section';
import Categories from './BeefCattle/Categories';
import DairyCattleCategories from './DairyCattle/Categories';

const EnvironmentCategories = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Ambientes',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Corte',
              url: match.url.concat('/corte'),
            },
            {
              title: 'Leite',
              url: match.url.concat('/leite'),
            },
          ],
        },
      ],
    },
  ]);

  return (
    <Card style={{border: 'none'}}>
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Categorias'}} render={() => <Section templateData={templateData} />} />
      <GuardedRoute
        path={match.url.concat('/corte')}
        meta={{moduleName: 'Corte'}}
        render={() => (
          <Categories
            match={{
              ...match,
              url: match.url.concat('/corte'),
            }}
          />
        )}
      />
      <GuardedRoute
        path={match.url.concat('/leite')}
        meta={{moduleName: 'Leite'}}
        render={() => (
          <DairyCattleCategories
            match={{
              ...match,
              url: match.url.concat('/leite'),
            }}
          />
        )}
      />
    </Card>
  );
};

export default EnvironmentCategories;
