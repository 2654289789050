import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  environment: 'DAIRY_CATTLE',
};

export const ExpenseServices = {
  endpoint: '/financials',
  costCenterEndpoint: '/cost_centers_description',

  list: async (params = {}) => ServicesHelper.list(ExpenseServices.endpoint, {...params, ...PARAMS}),

  post: async (body = {}) => ServicesHelper.post(ExpenseServices.endpoint, {...body, ...PARAMS}),

  put: async (id, body = {}) => ServicesHelper.put(ExpenseServices.endpoint.concat('/', id), {...body, ...PARAMS}),

  delete: async (id) => ServicesHelper.delete(ExpenseServices.endpoint.concat('/', id)),

  listCostCenterDescription: async (params = {}) => ServicesHelper.list(ExpenseServices.costCenterEndpoint, {...params, ...PARAMS}),
};
