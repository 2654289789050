import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {DataContext} from '../../utils/DataProvider';
import {MASKS} from '../../utils/constants';
import {maskInput, createOrUpdateDataFull} from '../../utils/utils';
import DataGrid from '../../components/DataGrid';
import {fetchHerdDeparture, registerHerdDeparture, changeHerdDeparturebyId, deleteHerdDeparture} from '../../services/herdDeparture';
import {fetchCategoryHerdDepartureType} from '../../services/categoryHerdDepartureType';
import {fetchCategoryLivestock} from '../../services/categoryLivestock';

const SalesGrid = ({origin}) => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [dataFetch, setDataFetch] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getContents = async () => {
      try {
        const response = await fetchHerdDeparture({
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(response.data.size);
        setDataFetch(response.data.resultList?.map((item) => ({...item, aux: item?.status ? 'value_arroba' : 'value_head'})));
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [reset, stateFetchParams]);// eslint-disable-line

  const validationFindData = (dataTemp) => {
    let validated = true;
    dataTemp.forEach((row) => {
      validated = validated && row.validated;
    });

    return dataTemp.length ? validated : false;
  };

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return;
      }
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, registerHerdDeparture, changeHerdDeparturebyId));
    });
    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        const [date] = line.date.split(' ');
        const [day, month, year] = date.split('/');

        if (!editMode) {
          // eslint-disable-next-line eqeqeq
          if (line.period === true) {
            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('MM/YYYY');
          }

          return moment(new Date(year, month - 1, day))
            .locale('pt-br')
            .format('DD/MM/YYYY');
        }

        return moment(new Date(year, month - 1, day));
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'destination',
      field: 'destination',
      name: 'Destino',
      width: '230px',
      required: true,
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'category_herd_departure_type',
      valueKey: 'category_herd_departure_type.title',
      field: 'category_herd_departure_type',
      name: 'Tipo de saída',
      required: true,
      textAlign: 'Center',
      width: '230px',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryHerdDepartureType({
            'order[title]': 'ASC',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'from_confinement',
      field: 'from_confinement',
      valueKey: 'from_confinement',
      name: 'Confinamento ?',
      width: '130px',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
    {
      key: 'category_livestock',
      valueKey: 'category_livestock.title',
      field: 'category_livestock',
      name: 'Categoria',
      required: true,
      textAlign: 'Center',
      width: '280px',
      editType: 'dropdownedit',
      groupBy: (option) => {
        return option.category_age;
      },

      optionsFetch: async () => {
        try {
          const response = await fetchCategoryLivestock({
            'match[show]': 'Vendas e outras',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
              category_age: item.category_age.stage,
            }),
          );
          /* objectTemp.sort((a, b) =>
            b.category_age.localeCompare(a.category_age),
          ); */
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'amount',
      field: 'amount',
      required: true,
      name: 'Quantidade',
      textAlign: 'Center',
      width: '160px',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'weight_alive',
      field: 'weight_alive',
      required: true,
      name: 'Peso vivo (kg)',
      textAlign: 'Center',
      width: '160px',
      editType: 'weightedit',
    },
    {
      key: 'weight_death',
      field: 'weight_death',
      required: (stateContext) => {
        return (
          (stateContext &&
            stateContext.data &&
            stateContext.data.category_herd_departure_type &&
            stateContext.data.category_herd_departure_type.label === 'Abate') ||
          (stateContext &&
            stateContext.data &&
            stateContext.data.category_herd_departure_type &&
            stateContext.data.category_herd_departure_type.label === 'Consumo') ||
          false
        );
      },
      disabledEdit: (dataLine) => {
        if (
          dataLine &&
          dataLine.data &&
          dataLine.data.category_herd_departure_type &&
          dataLine.data.category_herd_departure_type.label &&
          (dataLine.data.category_herd_departure_type.label === 'Transferência' || dataLine.data.category_herd_departure_type.label === 'Venda')
        ) {
          return true;
        }
        return false;
      },
      name: 'Peso morto (kg)',
      textAlign: 'Center',
      width: '160px',
      editType: 'weightedit',
    },
    {
      key: 'value_arroba',
      field: 'value_arroba',
      name: 'R$/@',
      textAlign: 'Center',
      width: '160px',
      editType: 'moneyedit',
      dependencies: ['category_livestock'],
      extraParams: {
        maxValue: (dataRow, value) => {
          const categoryValue = document?.querySelector('.category_livestock')?.querySelector('input').value;
          if (origin === 'DIAGNOSIS') {
            if (categoryValue === 'Touros' || categoryValue === 'Matrizes em reprodução') {
              if (value <= 2000) {
                return value;
              }
              return 2000;
            }
            if (value <= 999) {
              return value;
            }
            return 999;
          }
          return value;
        },
        formatedValue: async (col) => {
          const {data} = col;
          if (data?.aux === 'value_head') {
            if (data?.weight_death && data.value_head) {
              return parseFloat(data.value_head) / (parseFloat(data.weight_death) / 15);
            }
            if (!data?.weight_death && data?.weight_alive && data.value_head) {
              return parseFloat(data.value_head) / (parseFloat(data.weight_alive) / 30);
            }
          }

          return null;
        },
      },
      required: (stateContext) => {
        const {data, mode} = stateContext;
        if (mode === 'NEW_LINE' && data && data.value_head && data.value_head.toString().length > 0) {
          return false;
        }
        if (mode === 'EDIT' && data && !data.value_arroba) {
          return false;
        }
        return true;
      },
      disabledEdit: (dataLine) => {
        const {data, mode} = dataLine;
        if (mode === 'NEW_LINE' && data && data.value_head && data.value_head.toString().length > 0 && data?.aux !== 'value_arroba') {
          data.aux = 'value_head';
          return true;
        }
        if (mode === 'EDIT' && data && data?.aux !== 'value_arroba') {
          return true;
        }
        return false;
      },
    },
    {
      key: 'value_head',
      field: 'value_head',
      name: 'R$/cabeça',
      textAlign: 'Center',
      width: '160px',
      editType: 'moneyedit',
      extraParams: {
        formatedValue: async (col) => {
          const {data} = col;
          if (data.aux === 'value_arroba') {
            if (data?.weight_death && data?.value_arroba) {
              return parseFloat(data?.value_arroba) * (parseFloat(data?.weight_death) / 15);
            }
            if (!data?.weight_death && data?.weight_alive && data?.value_arroba) {
              return parseFloat(data?.value_arroba) * (parseFloat(data?.weight_alive) / 30);
            }
          }

          return null;
        },
      },
      required: (stateContext) => {
        const {data, mode} = stateContext;
        if (mode === 'NEW_LINE' && data && data.value_arroba && data.value_arroba.toString().length > 0) {
          return false;
        }
        if (mode === 'EDIT' && data && !data.value_head) {
          return false;
        }
        return true;
      },
      disabledEdit: (dataLine) => {
        const {data, mode} = dataLine;
        if (mode === 'NEW_LINE' && data && data.value_arroba && data.value_arroba.toString().length > 0 && data?.aux !== 'value_head') {
          data.aux = 'value_arroba';
          return true;
        }
        if (mode === 'EDIT' && data && data?.aux !== 'value_head') {
          return true;
        }
        return false;
      },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      textAlign: 'Center',
      width: '160px',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteHerdDeparture(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  return (
    <>
      {stateMessage}
      {dataFetch && (
        <div className="salesGrid">
          <DataGrid
            onChange={async (dataGridInputs) => {
              if (dataGridInputs && dataGridInputs.date === 'Invalid date') {
                delete dataGridInputs.date;
              }
              if (dataGridInputs && dataGridInputs.weight_death === '') {
                dataGridInputs.weight_death = null;
              }
              // if (dataGridInputs.value_arroba) {
              //   dataGridInputs.value_head = (parseFloat(dataGridInputs.weight_death) / 15) * dataGridInputs.value_arroba;
              // }
              // if (dataGridInputs.value_head) {
              //   dataGridInputs.value_arroba = dataGridInputs.value_head / (parseFloat(dataGridInputs.weight_death) / 15);
              // }
              dataGridInputs.status = dataGridInputs.aux === 'value_arroba';
              dataGridInputs.value_head = parseFloat(dataGridInputs.value_head);
              dataGridInputs.value_arroba = parseFloat(dataGridInputs.value_arroba);
              delete dataGridInputs.aux;
              if (origin === 'DIAGNOSIS' && dataGridInputs.from_stock_valuation) {
                dataGridInputs.from_stock_valuation = false;
                setOpen(dataGridInputs);
              } else {
                const response = await createOrUpdateDataFull(dataGridInputs, state, registerHerdDeparture, changeHerdDeparturebyId);
                setReset(!reset);
                return response;
              }
              return null;
            }}
            header={columns}
            data={dataFetch}
            keyProp="key"
            width="max-content"
            maxHeight="530px"
            validateCallback={validateHeaderCallback}
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
            accumulators={{
              'Total geral': {
                columns: ['amount', 'weight_alive', 'weight_death', 'value_arroba', 'value_head'],
                itemOperation: (acc, line, key) => {
                  if (key === 'weight_alive') {
                    return (acc += Number(Number(line[key]?.toFixed(2)) * line.amount));
                  }
                  if (key === 'weight_death') {
                    return (acc += Number(Number(line[key]?.toFixed(2)) * line.amount));
                  }
                  if (key === 'value_arroba') {
                    return (acc += Number(Number(line[key]?.toFixed(2)) * line.amount));
                  }
                  if (key === 'value_head') {
                    return (acc += Number(Number(line[key]?.toFixed(2)) * line.amount));
                  }
                  return (acc += Number(Number(line[key]?.toFixed(2))));
                },
                resultOperation: (acc, key) => {
                  if (key === 'value_head' || key === 'value_arroba') {
                    return maskInput({value: acc, type: MASKS.MONEY});
                  }

                  return maskInput({
                    value: acc,
                    type: MASKS.DOUBLE,
                  });
                },
              },
              'Ponderado geral': {
                columns: ['weight_alive', 'weight_death', 'value_arroba', 'value_head'],
                itemOperation: (acc, line, key) => {
                  if (acc) {
                    if (key === 'weight_alive') {
                      return {
                        sum: (acc.sum += line[key]?.toFixed(2) * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'weight_death') {
                      return {
                        sum: (acc.sum += line[key]?.toFixed(2) * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'value_arroba') {
                      return {
                        sum: (acc.sum += Number(line[key])?.toFixed(2) * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'value_head') {
                      return {
                        sum: (acc.sum += line[key]?.toFixed(2) * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                  }
                  return {
                    sum: acc ? (acc.sum += line[key]?.toFixed(2) * line.amount) : line[key]?.toFixed(2) * line.amount,
                    quantity: acc ? (acc.quantity += line.amount) : line.amount,
                  };
                },
                resultOperation: (acc, key) => {
                  if (key === 'value_head' || key === 'value_arroba') {
                    return maskInput({
                      value: acc.sum / acc.quantity,
                      type: MASKS.MONEY,
                    });
                  }
                  return maskInput({
                    value: acc.sum / acc.quantity,
                    type: MASKS.DOUBLE,
                  });
                },
              },
            }}
          />
          <Dialog open={!!open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <i className="fas fa-engine-warning" />
              Atenção
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ao manipulador dados na tabela de apoio, os dados da tabela Principal serão substituidos e excluidos. Deseja Realmente realizar essa operação?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  const response = await createOrUpdateDataFull(open, state, registerHerdDeparture, changeHerdDeparturebyId);
                  setReset(!reset);
                  setOpen(false);
                  return response;
                }}
                color="primary"
                autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default SalesGrid;
