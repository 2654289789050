import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateData} from '../../utils/utils';
import {
  fetchLandsImprovements,
  registerLandsImprovements,
  changeLandsImprovementsbyId,
  deleteLandsImprovements,
} from '../../services/landsImprovements';
import PrimaryFilterDisplay from '../../components/PrimaryFilterDisplay/PrimaryFilterDisplay';
import PrimaryFilterForm from '../../components/FilterForms/FilterSettings';
import {FILTER} from '../../utils/constants';

const CategoryAges = () => {
  const {state, dispatch, stateFetchParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  }, []);// eslint-disable-line

  useEffect(() => {
    const loadData = async () => {
      if (!state.harvest || !state.customer || !state.farm) {
        return;
      }
      const response = await fetchLandsImprovements(stateFetchParams);
      const temp = [];
      response.data.resultList.map((value) =>
        temp.push({
          ...value,
          title: 'Terras e benfeitorias',
        }),
      );
      if (!temp.length) {
        temp.push({
          id: 1,
          title: 'Terras e benfeitorias',
        });
      }
      setData(temp);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, state, stateFetchParams]);

  const columns = [
    {
      key: 'title',
      field: 'title',
      disableFilter: true,
      name: 'Terras e benfeitorias',
      textAlign: 'Center',
      defaultValue: 'Terras e benfeitorias',
    },
    {
      key: 'value',
      disableFilter: true,
      field: 'value',
      name: 'Total',
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteLandsImprovements(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {state.filterOpened && <PrimaryFilterForm />}
      {!state.filterOpened && data && (
        <>
          <PrimaryFilterDisplay />
          <DataGrid
            onChange={async (dataGridInputs) => {
              delete dataGridInputs.updated_at;
              delete dataGridInputs.updated_by;
              delete dataGridInputs.created_at;
              delete dataGridInputs.created_by;
              delete dataGridInputs.customer;
              delete dataGridInputs.farm;
              delete dataGridInputs.harvest;
              delete dataGridInputs.title;
              delete dataGridInputs.id;
              const response = await createOrUpdateData(
                dataGridInputs,
                state,
                registerLandsImprovements,
                changeLandsImprovementsbyId,
              );
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            disabledPaginate
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
          />
        </>
      )}
    </>
  );
};

export default CategoryAges;
