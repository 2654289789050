import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../components/Section/Section';
import './style.scss';
import General from '../General/General';
import Cards from './Cards';

const Team = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Equipe',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Geral',
              url: `${match.url}/geral`,
            },
          ],
        },
      ],
    },
    // {
    //   category: 'Cards',
    //   url: `${match.url}/cards`,
    // },
  ]);
  return (
    <div className="team">
      <GuardedRoute
        exact
        path={match.url}
        meta={{moduleName: 'Equipe'}}
        render={() => (
          <Section templateData={templateData}>
            <Cards />
          </Section>
        )}
      />

      <GuardedRoute
        path={`${match.url}/geral`}
        meta={{moduleName: 'Geral'}}
        component={General}
      />

      <GuardedRoute
        path={`${match.url}/cards`}
        meta={{moduleName: 'Cards'}}
        component={Cards}
      />
    </div>
  );
};
export default Team;
