import moment from 'moment';

export class DatePickerHelper {
  static newDate() {
    return moment(new Date()).format('DD/MM/YYYY 00:00:00');
  }

  static _prepareDate(line, editMode) {
    const [date] = line.date.split(' ');
    const [day, month, year] = date.split('/');

    if (!editMode) {
      return moment(new Date(year, month - 1, day))
        .locale('pt-br')
        .format(line.period ? 'MM/YYYY' : 'DD/MM/YYYY');
    }

    return moment(new Date(year, month - 1, day));
  }

  static _loadDate(date) {
    const current = moment(date, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
    if (current === 'Invalid date') {
      return date;
    }
    return current;
  }

  static daily() {
    return {
      editType: 'datepicker',
      mapper: (line, editMode) => this._prepareDate(line, editMode),
      returnFormat: (value) => this._loadDate(value),
    };
  }

  static dailyAndMonth(state) {
    return {
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => value === 'true' || value,
      popoverMapper: (line) => `${line.period}`,
      mapper: (line, editMode) => this._prepareDate(line, editMode),
      returnFormat: (value) => this._loadDate(value),
    };
  }
}
