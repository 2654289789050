import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../../components/Section/Section';
import InventoryValuation from './InventoryValuation/InventoryValuation';
import Breadcrumbs from '../../../components/Breadcrumbs';
import FlockMedia from './FlockMedia/FlockMedia';
import Reproduction from './Reproduction/Reproduction';

const Herd = ({match}) => {
  const [templateData] = useState([
    {
      category: ' ',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Avaliação de estoque',
              url: `${match.url}/avaliacao-estoque`,
            },
            {
              title: 'Média do Rebanho',
              url: `${match.url}/media-rebanho`,
            },
            {
              title: 'Reprodução',
              url: `${match.url}/reproducao`,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <div className="zoodata">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Rebanho'}} render={() => <Section templateData={templateData} />} />
      <GuardedRoute
        path={`${match.url}/avaliacao-estoque`}
        meta={{moduleName: 'Rebanho'}}
        render={() => (
          <>
            <Breadcrumbs
              path={[{title: 'Diagnóstico', url: '/diagnostico'}, {title: 'Rebanho', url: '/diagnostico/rebanho'}, {title: 'Avaliação de estoque'}]}
            />
            <InventoryValuation />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/media-rebanho`}
        meta={{moduleName: 'Rebanho'}}
        render={() => (
          <>
            <Breadcrumbs path={[{title: 'Diagnóstico', url: '/diagnostico'}, {title: 'Rebanho', url: '/diagnostico/rebanho'}, {title: 'Média Rebanho'}]} />
            <FlockMedia />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/reproducao`}
        meta={{moduleName: 'Rebanho'}}
        render={() => (
          <>
            <Breadcrumbs path={[{title: 'Diagnóstico', url: '/diagnostico'}, {title: 'Rebanho', url: '/diagnostico/rebanho'}, {title: 'Reprodução'}]} />
            <Reproduction />
          </>
        )}
      />
    </div>
  );
};
export default Herd;
