import React from 'react';

import {Card, Col, Container, Row} from 'reactstrap';

import logo from '../../assets/images/logo_white.png';
import floatLogo from '../../assets/images/float_logo.png';

import './LoginContainer.scss';

const LoginContainer = ({children}) => {
  return (
    <div className="login-container">
      <Container className="container-front">
        <img className="float-logo" src={floatLogo} alt="DSM LOGO" />
        <Row className="row-logo">
          <Col>
            <img className="logo-login-page img-fluid p-4 p-md-0" src={logo} alt="DSM - Bright Sciene, Brighter Living" />
          </Col>
          <Col xs={12} md={5} className="d-flex align-items-center">
            <Card className="pt-4 pb-4 pl-2 pr-2 w-100">
              <div className="container">{children}</div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginContainer;
