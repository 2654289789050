import React from 'react';
import {GuardedRoute} from 'react-router-guards';
// import Section from '../../components/Section/Section';
import Breadcrumbs from '../../components/Breadcrumbs';
import CategoryFaq from './CategoryFaq.js/categoryFaq';
import RegisterQuestionFaq from './QuestionFaq/register';
import SendMail from './SendMail/initialFormFaq';

const Communication = ({match}) => {
  return (
    <div className="zoodata">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Comunicação'}} />
      <GuardedRoute
        path={`${match.url}/enviar-email`}
        meta={{moduleName: 'Comunicação'}}
        render={() => (
          <>
            <Breadcrumbs path={[{title: 'Comunicação', url: '/corte/comunicacao/enviar-email'}, {title: 'Enviar email'}]} />
            <SendMail />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/categorias-faq`}
        meta={{moduleName: 'Comunicação'}}
        render={() => (
          <>
            <Breadcrumbs path={[{title: 'Comunicação', url: '/corte/comunicacao/categorias-faq'}, {title: 'Categorias FAQ'}]} />
            <CategoryFaq />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/questao-faq`}
        meta={{moduleName: 'Comunicação'}}
        render={() => (
          <>
            <Breadcrumbs path={[{title: 'Comunicação', url: '/corte/comunicacao/questao-faq'}, {title: 'Questão FAQ'}]} />
            <RegisterQuestionFaq />
          </>
        )}
      />
    </div>
  );
};
export default Communication;
