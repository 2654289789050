/* eslint-disable no-nested-ternary */
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from 'react-js-pagination';
import {PaginationContent} from './styled';

const PaginationFooter = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  itemClass,
  linkClass,
  onChange = () => {},
}) => {
  return (
    <PaginationContent className="d-flex justify-content-between align-items-center">
      <div className="d-flex justify-content-center align-items-center">
        <Pagination
          activePage={activePage + 1}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={pageRangeDisplayed}
          itemClass={itemClass}
          linkClass={linkClass}
          onChange={(pageNumber) => {
            onChange({pageNumber});
          }}
        />
        <Select
          className="selecPages"
          labelId="numberPage"
          id="selecPages"
          variant="outlined"
          value={itemsCountPerPage}
          onChange={(value) => {
            onChange({itemsPerPage: value.target.value});
          }}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={300}>300</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
          <MenuItem value={2000}>2000</MenuItem>
          <MenuItem value={3000}>3000</MenuItem>
          <MenuItem value={5000}>5000</MenuItem>
        </Select>
        <small>Itens por página</small>
      </div>
      <small>
        {activePage + 1} de{' '}
        {Math.round(totalItemsCount / itemsCountPerPage) <= 0
          ? 1
          : totalItemsCount % itemsCountPerPage > 0
          ? Math.floor(totalItemsCount / itemsCountPerPage) + 1
          : Math.round(totalItemsCount / itemsCountPerPage)}{' '}
        páginas ({totalItemsCount} itens)
      </small>
    </PaginationContent>
  );
};

export default PaginationFooter;
