import api from '../api';

const fetchStocks = (params = {}) => {
  return api.get(`/stocks`, {
    params: {
      ...params,
    },
  });
};

const fetchStockbyId = (id, params = {}) => {
  return api.get(`/stocks/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeStocksbyId = (id, body = {}) => {
  return api.put(`/stocks/${id}`, body);
};

const registerStock = (body = {}) => {
  return api.post(`/stocks`, body);
};

const deleteStock = (id) => {
  return api.delete(`/stocks/${id}`);
};

export {
  fetchStocks,
  fetchStockbyId,
  changeStocksbyId,
  registerStock,
  deleteStock,
};
