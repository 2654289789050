import React, {useState, useEffect} from 'react';
// import IMask from 'imask';
import {Row, Col} from 'reactstrap';
import {Bar} from 'react-chartjs-2';
import Indicator from '../DataPresenters/Indicator';
import {getDataChart, maskInput} from '../../../utils/utils';
import {MASKS} from '../../../utils/constants';

const DashboardFinancials = ({
  title,
  usedArea,
  averageHerd,
  totalDeaths,
  totalArrobas,
  totalKilos,
  mortalityBarChart,
  gmdBarChart,
  stockingBarChart,
  stockingHerdBarChart,
  globalProductionBarChart,

  totalRevenue,
  totalExpenses,
  costingHeadBarChart,
  disbursementHeadBarChart,
  costingArrobasBarChart,
  averageValueBarChart,
  resultArrobasBarChart,
  marginOnSaleBarChart,
  productiveResultBarChart,
  page,
}) => {
  const pageSettings = {
    PRODUCTIVE: {
      mainColor: '#046c00',
      secondaryColor: '#fff',
      title: {
        backgroundColor: '#fcc',
        icon: 'calendar',
        color: '#000',
        title: 'Período em Análise ',
        subtitle: 'Meses',
      },
      usedArea: {
        backgroundColor: '#ff8001',
        icon: 'map',
        color: '#ff8001',
        iconColor: '#fff',
        title: 'Área Utilizada Pecuária',
        subtitle: 'ha',
      },
      averageHerd: {
        backgroundColor: '#00f',
        icon: 'check',
        color: '#00f',
        iconColor: '#fff',
        title: 'Rebanho Médio',
        subtitle: 'Cabeças',
      },
      totalDeaths: {
        backgroundColor: '#ff0100',
        icon: 'times',
        color: '#ff0100',
        iconColor: '#fff',
        title: 'Total de Mortes',
        subtitle: 'Cabeças',
      },
      totalArrobas: {
        backgroundColor: '#0001a3',
        icon: 'at',
        color: '#0001a3',
        iconColor: '#fff',
        title: 'Total @ Produzidas',
        subtitle: 'Qtde',
      },
      totalKilos: {
        backgroundColor: '#007bf5',
        icon: 'plus-circle',
        color: '#007bf5',
        iconColor: '#fff',
        title: 'Total kg Produzidas',
        subtitle: 'Qtde',
      },
      mortalityBarChart: {
        title: 'Taxa de mortalidade geral',
      },
      gmdBarChart: {
        title: 'GMD Global(kg)',
      },
      stockingBarChart: {
        title: 'Lotação UA/ha',
      },
      stockingHerdBarChart: {
        title: 'Lotação cab/ha',
      },
      globalProductionBarChart: {
        title: 'Produção Global @/ha',
      },
    },
    FINANCIAL: {
      totalRevenue: {
        backgroundColor: '#005b00',
        icon: 'money-bill',
        iconColor: '#fff',
        color: '#005b00',
        title: 'Total de Receitas ',
        // subtitle: '',
      },
      totalExpenses: {
        backgroundColor: '#d60000',
        icon: 'money-bill',
        iconColor: '#fff',
        color: '#d60000',
        title: 'Total de Despesa',
        // subtitle: '',
      },
      costingHeadBarChart: {
        title: 'Custeio Cabeça/Mês (R$)',
      },
      disbursementHeadBarChart: {
        title: 'Desembolso Cabeça/Mês (R$)',
      },
      costingArrobasBarChart: {
        title: 'Desembolso / @  Produzida (R$)',
      },
      averageValueBarChart: {
        title: 'Valor Médio Venda (R$/@)',
      },
      resultArrobasBarChart: {
        title: 'Resultado / @ Produzida (R$)',
      },
      marginOnSaleBarChart: {
        title: 'Margem Sobre Venda (%)',
      },
      productiveResultBarChart: {
        title: 'Resultado Produtivo (R$/ha)',
      },
    },
  };
  const [
    normalizedMortalityBarChart,
    setNormalizedMortalityBarChart,
  ] = useState(null);
  const [normalizeGmdBarChart, setNormalizeGmdBarChart] = useState(null);
  const [normalizeStockingBarChart, setNormalizeStockingBarChart] = useState(
    null,
  );
  const [
    normalizeStockingHerdBarChart,
    setNormalizeStockingHerdBarChart,
  ] = useState(null);
  const [
    normalizeGlobalProductionBarChart,
    setNormalizeGlobalProductionBarChart,
  ] = useState(null);
  const [
    normalizeCostingHeadBarChart,
    setNormalizeCostingHeadBarChart,
  ] = useState(null);
  const [
    normalizeDisbursementHeadBarChart,
    setNormalizeDisbursementHeadBarChart,
  ] = useState(null);
  const [
    normalizeCostingArrobasBarChart,
    setNormalizeCostingArrobasBarChart,
  ] = useState(null);
  const [
    normalizeAverageValueBarChart,
    setNormalizeAverageValueBarChart,
  ] = useState(null);
  const [
    normalizeResultArrobasBarChart,
    setNormalizeResultArrobasBarChart,
  ] = useState(null);
  const [
    normalizeMarginOnSaleBarChart,
    setNormalizeMarginOnSaleBarChart,
  ] = useState(null);
  const [
    normalizeProductiveResultBarChart,
    setNormalizeProductiveResultBarChart,
  ] = useState(null);

  const generateRandomColor = (array) => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);

    return array.map((item) => {
      if (item?.label === 'Minha Fazenda') {
        return `rgb(${r},${g - 60},${b - 50})`;
      }
      return `rgb(${r},${g},${b})`;
    });
  };

  useEffect(() => {
    const loadData = async () => {
      switch (page) {
        case 'PRODUCTIVE':
          if (mortalityBarChart && mortalityBarChart.length) {
            const dataMortalityBarChart = await getDataChart(
              null,
              null,
              mortalityBarChart,
              false,
              null,
              generateRandomColor(mortalityBarChart),
            );
            setNormalizedMortalityBarChart(dataMortalityBarChart);
          }
          if (gmdBarChart && gmdBarChart.length) {
            const dataGmdBarChart = await getDataChart(
              null,
              null,
              gmdBarChart,
              false,
              null,
              generateRandomColor(gmdBarChart),
            );
            setNormalizeGmdBarChart(dataGmdBarChart);
          }
          if (stockingBarChart && stockingBarChart.length) {
            const dataStockingBarChart = await getDataChart(
              null,
              null,
              stockingBarChart,
              false,
              null,
              generateRandomColor(stockingBarChart),
            );
            setNormalizeStockingBarChart(dataStockingBarChart);
          }
          if (stockingHerdBarChart && stockingHerdBarChart.length) {
            const dataStockingHerdBarChart = await getDataChart(
              null,
              null,
              stockingHerdBarChart,
              false,
              null,
              generateRandomColor(stockingHerdBarChart),
            );
            setNormalizeStockingHerdBarChart(dataStockingHerdBarChart);
          }
          if (globalProductionBarChart && globalProductionBarChart.length) {
            const dataGlobalProductionBarChart = await getDataChart(
              null,
              null,
              globalProductionBarChart,
              false,
              null,
              generateRandomColor(globalProductionBarChart),
            );
            setNormalizeGlobalProductionBarChart(dataGlobalProductionBarChart);
          }
          break;
        case 'FINANCIAL':
          if (costingHeadBarChart && costingHeadBarChart.length) {
            const dataCostingHeadBarChart = await getDataChart(
              null,
              null,
              costingHeadBarChart,
              false,
              null,
              generateRandomColor(costingHeadBarChart),
            );
            setNormalizeCostingHeadBarChart(dataCostingHeadBarChart);
          }
          if (disbursementHeadBarChart && disbursementHeadBarChart.length) {
            const dataDisbursementHeadBarChart = await getDataChart(
              null,
              null,
              disbursementHeadBarChart,
              false,
              null,
              generateRandomColor(disbursementHeadBarChart),
            );
            setNormalizeDisbursementHeadBarChart(dataDisbursementHeadBarChart);
          }
          if (costingArrobasBarChart && costingArrobasBarChart.length) {
            const dataCostingArrobasBarChart = await getDataChart(
              null,
              null,
              costingArrobasBarChart,
              false,
              null,
              generateRandomColor(costingArrobasBarChart),
            );
            setNormalizeCostingArrobasBarChart(dataCostingArrobasBarChart);
          }
          if (averageValueBarChart && averageValueBarChart.length) {
            const dataAverageValueBarChart = await getDataChart(
              null,
              null,
              averageValueBarChart,
              false,
              null,
              generateRandomColor(averageValueBarChart),
            );
            setNormalizeAverageValueBarChart(dataAverageValueBarChart);
          }
          if (resultArrobasBarChart && resultArrobasBarChart.length) {
            const dataResultArrobasBarChart = await getDataChart(
              null,
              null,
              resultArrobasBarChart,
              false,
              null,
              generateRandomColor(resultArrobasBarChart),
            );
            setNormalizeResultArrobasBarChart(dataResultArrobasBarChart);
          }
          if (marginOnSaleBarChart && marginOnSaleBarChart.length) {
            const dataMarginOnSaleBarChart = await getDataChart(
              null,
              null,
              marginOnSaleBarChart,
              false,
              null,
              generateRandomColor(marginOnSaleBarChart),
            );
            setNormalizeMarginOnSaleBarChart(dataMarginOnSaleBarChart);
          }
          if (productiveResultBarChart && productiveResultBarChart.length) {
            const dataProductiveResultBarChart = await getDataChart(
              null,
              null,
              productiveResultBarChart,
              false,
              null,
              generateRandomColor(productiveResultBarChart),
            );
            setNormalizeProductiveResultBarChart(dataProductiveResultBarChart);
          }
          break;

        default:
          break;
      }
    };
    loadData();
  }, [mortalityBarChart]);// eslint-disable-line

  return (
    <div>
      {page === 'PRODUCTIVE' && (
        <>
          <div className="page-break">
            {title && page && (
              <div className="pt-2">
                <Indicator
                  icon={pageSettings[page].title.icon}
                  iconSize={4}
                  color={pageSettings[page].title.color}
                  iconColor={pageSettings[page].title.color}
                  iconBackgroundColor={pageSettings[page].title.backgroundColor}
                  data={title}
                  subtitlePosition="bottom"
                  title={pageSettings[page].title.title}
                  subtitle={pageSettings[page].title.subtitle}
                />
              </div>
            )}

            {(usedArea !== null || usedArea !== undefined) && page && (
              <div className="pt-2">
                <Indicator
                  icon={pageSettings[page].usedArea.icon}
                  iconSize={4}
                  color={pageSettings[page].usedArea.color}
                  iconColor={pageSettings[page].usedArea.iconColor}
                  iconBackgroundColor={
                    pageSettings[page].usedArea.backgroundColor
                  }
                  // data={IMask.createMask({
                  //   mask: '000.000,00',
                  // }).resolve(`${usedArea}`)}
                  data={maskInput({type: MASKS.DOUBLE, value: `${usedArea}`})}
                  subtitlePosition="bottom"
                  title={pageSettings[page].usedArea.title}
                  subtitle={pageSettings[page].usedArea.subtitle}
                />
              </div>
            )}
            {(averageHerd !== null || averageHerd !== undefined) && page && (
              <div className="pt-2">
                <Indicator
                  icon={pageSettings[page].averageHerd.icon}
                  iconSize={4}
                  color={pageSettings[page].averageHerd.color}
                  iconColor={pageSettings[page].averageHerd.iconColor}
                  iconBackgroundColor={
                    pageSettings[page].averageHerd.backgroundColor
                  }
                  data={maskInput({
                    type: MASKS.DOUBLE,
                    value: `${Math.round(parseFloat(averageHerd))}`,
                  })}
                  subtitlePosition="bottom"
                  title={pageSettings[page].averageHerd.title}
                  subtitle={pageSettings[page].averageHerd.subtitle}
                />
              </div>
            )}
            {(totalDeaths !== null || totalDeaths !== undefined) && page && (
              <div className="pt-2">
                <Indicator
                  icon={pageSettings[page].totalDeaths.icon}
                  iconSize={4}
                  color={pageSettings[page].totalDeaths.color}
                  iconColor={pageSettings[page].totalDeaths.iconColor}
                  iconBackgroundColor={
                    pageSettings[page].totalDeaths.backgroundColor
                  }
                  data={`${totalDeaths}`}
                  subtitlePosition="bottom"
                  title={pageSettings[page].totalDeaths.title}
                  subtitle={pageSettings[page].totalDeaths.subtitle}
                />
              </div>
            )}
            {(totalArrobas !== null || totalArrobas !== undefined) && page && (
              <div className="pt-2">
                <Indicator
                  icon={pageSettings[page].totalArrobas.icon}
                  iconSize={4}
                  color={pageSettings[page].totalArrobas.color}
                  iconColor={pageSettings[page].totalArrobas.iconColor}
                  iconBackgroundColor={
                    pageSettings[page].totalArrobas.backgroundColor
                  }
                  data={`${maskInput({
                    type: MASKS.DOUBLE,
                    value: `${Math.round(totalArrobas)}`,
                  })}`}
                  subtitlePosition="bottom"
                  title={pageSettings[page].totalArrobas.title}
                  subtitle={pageSettings[page].totalArrobas.subtitle}
                />
              </div>
            )}
            {(totalKilos !== null || totalKilos !== undefined) && page && (
              <div className="pt-2">
                <Indicator
                  icon={pageSettings[page].totalKilos.icon}
                  iconSize={4}
                  color={pageSettings[page].totalKilos.color}
                  iconColor={pageSettings[page].totalKilos.iconColor}
                  iconBackgroundColor={
                    pageSettings[page].totalKilos.backgroundColor
                  }
                  data={maskInput({
                    type: MASKS.DOUBLE,
                    value: `${Math.round(totalKilos)}`,
                  })}
                  subtitlePosition="bottom"
                  title={pageSettings[page].totalKilos.title}
                  subtitle={pageSettings[page].totalKilos.subtitle}
                />
              </div>
            )}
          </div>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizedMortalityBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].mortalityBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].mortalityBarChart.title}
                  data={normalizedMortalityBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}%`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}%`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}%`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeGmdBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].gmdBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].gmdBarChart.title}
                  data={normalizeGmdBarChart}
                  options={{
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${parseFloat(value).toFixed(3)}`,
                            scale: 3,
                          })}`;
                        },
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${parseFloat(
                              data.datasets[0].data[tooltipItem.index],
                            ).toFixed(3)}`,
                            scale: 3,
                          })}`;
                        },
                      },
                    },
                    legend: {
                      display: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            autoSkip: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${`${parseFloat(value).toFixed(3)}`}`,
                                scale: 3,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeStockingBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].stockingBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].stockingBarChart.title}
                  data={normalizeStockingBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeStockingHerdBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].stockingHerdBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].stockingHerdBarChart.title}
                  data={normalizeStockingHerdBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeGlobalProductionBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].globalProductionBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].globalProductionBarChart.title}
                  data={normalizeGlobalProductionBarChart}
                  options={{
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
        </>
      )}
      {page === 'FINANCIAL' && (
        <>
          <div className="page-break">
            {(totalRevenue !== null || totalRevenue !== undefined) && page && (
              <div className="pt-2 ">
                <Indicator
                  icon={pageSettings[page].totalRevenue.icon}
                  iconSize={4}
                  color={pageSettings[page].totalRevenue.color}
                  iconColor={pageSettings[page].totalRevenue.iconColor}
                  iconBackgroundColor={
                    pageSettings[page].totalRevenue.backgroundColor
                  }
                  data={`R$ ${maskInput({
                    type: MASKS.DOUBLE,
                    value: `${Math.round(totalRevenue)}`,
                  })}`}
                  subtotalRevenuePosition="bottom"
                  title={pageSettings[page].totalRevenue.title}
                  subtitle={pageSettings[page].totalRevenue.subtitle}
                />
              </div>
            )}

            {(totalExpenses !== null || totalExpenses !== undefined) && page && (
              <div className="pt-2 ">
                <Indicator
                  icon={pageSettings[page].totalExpenses.icon}
                  iconSize={4}
                  color={pageSettings[page].totalExpenses.color}
                  iconColor={pageSettings[page].totalExpenses.iconColor}
                  iconBackgroundColor={
                    pageSettings[page].totalExpenses.backgroundColor
                  }
                  data={`R$ ${maskInput({
                    type: MASKS.DOUBLE,
                    value: `${Math.round(totalExpenses)}`,
                  })}`}
                  subtitlePosition="bottom"
                  title={pageSettings[page].totalExpenses.title}
                  subtitle={pageSettings[page].totalExpenses.subtitle}
                />
              </div>
            )}
          </div>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeCostingHeadBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].costingHeadBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].costingHeadBarChart.title}
                  data={normalizeCostingHeadBarChart}
                  options={{
                    legend: {
                      display: false,
                    },

                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `R$ ${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeDisbursementHeadBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].disbursementHeadBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].disbursementHeadBarChart.title}
                  data={normalizeDisbursementHeadBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `R$ ${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeCostingArrobasBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].costingArrobasBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].costingArrobasBarChart.title}
                  data={normalizeCostingArrobasBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `R$ ${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeAverageValueBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].averageValueBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].averageValueBarChart.title}
                  data={normalizeAverageValueBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `R$ ${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeResultArrobasBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].resultArrobasBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].resultArrobasBarChart.title}
                  data={normalizeResultArrobasBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `R$ ${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeMarginOnSaleBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].marginOnSaleBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].marginOnSaleBarChart.title}
                  data={normalizeMarginOnSaleBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}%`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}%`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}%`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-center py-5 page-break">
            {normalizeProductiveResultBarChart && (
              <Col xs={10} className="cards p-0">
                <h5 className="d-flex flex-column align-items-center">
                  {pageSettings[page].productiveResultBarChart.title}
                </h5>
                <Bar
                  responsive
                  label={pageSettings[page].productiveResultBarChart.title}
                  data={normalizeProductiveResultBarChart}
                  options={{
                    legend: {
                      display: false,
                    },
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 40,
                        bottom: 0,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem, data) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              data.datasets[0].data[tooltipItem.index]
                            }`,
                          })}`;
                        },
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: {
                            font: {
                              size: 16,
                            },
                          },
                        },
                        anchor: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'end';
                          }
                          return 'start';
                        },
                        align: (val) => {
                          if (val.dataset.data[val.dataIndex] >= 0) {
                            return 'top';
                          }
                          return 'bottom';
                        },
                        formatter: (value) => {
                          return `R$ ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 16,
                          },
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                          },
                          ticks: {
                            display: false,
                            // Create scientific notation labels
                            callback: (value) => {
                              return `R$ ${maskInput({
                                type: MASKS.DOUBLE,
                                value: `${value}`,
                              })}`;
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default DashboardFinancials;
