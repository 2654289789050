/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {createOrUpdateDataFull} from '../../../../utils/utils';
import {fetchFinancials, deleteFinancials, changeFinancialsbyId, createFinancials} from '../../../../services/financials';
import {fetchCostCenters} from '../../../../services/costCenters';

const InputDistribution = () => {
  const [data, setData] = useState([]);
  // const [diferences, setDiferences] = useState(null);

  const [update, setUpdate] = useState(false);
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const dataFactory = {
    // eslint-disable-next-line camelcase
    date: `30/06/${state?.harvest?.last_year} 00:00:00`,
    period: true,
    status: false,
    validated: true,
  };
  const [colFixed, setColFixed] = useState({
    header: [
      {
        key: 'cost_center',
        valueKey: 'cost_center',
        height: 'auto',
        field: 'cost_center',
        name: 'Descrição',
        width: '300px',
        textAlign: 'Center',
        editType: 'defaultedit',
        disabledEdit: true,
        disableFilter: true,
      },
    ],
    data: [
      {
        // eslint-disable-next-line camelcase
        cost_center: 'Nutrição',
      },
      {
        // eslint-disable-next-line camelcase
        cost_center: 'Sanidade',
      },
      {
        // eslint-disable-next-line camelcase
        cost_center: 'Reprodução',
      },
    ],
  });
  const columns = [
    {
      tooltip: `Inserir o valor anual gasto por cada despesa.`,
      key: 'value',
      field: 'value',
      required: true,
      name: 'Valor Anual',
      textAlign: 'Center',
      editType: 'moneyedit',
      tooltipsCel: [
        {value: 'Suplementos, grãos, rações e volumosos', position: 'left'},
        {
          value: 'Vacinas, vermífugos e demais medicamentos',
          position: 'top-left',
        },
        {
          value: 'Sêmen, material de IA, hormônio e outros',
          position: 'top-left',
        },
      ],
    },
  ];

  useEffect(() => {
    (async () => {
      const respCostCenters = await fetchCostCenters({
        'wherein[id]': '306,307,308',
      });
      const dataFixedColumn = [];
      respCostCenters.data.resultList.map((element) =>
        dataFixedColumn.push({
          cost_center: element.title,
          cost: element,
        }),
      );
      setColFixed((prev) => Object.assign(prev, {data: dataFixedColumn}));
      const response = await fetchFinancials({
        'wherein[costCenter.id]': '306,307,308',
        // eslint-disable-next-line camelcase
        date: `30/06/${state?.harvest?.last_year} 00:00:00`,
        ...stateFetchParams,
        ...filterParams,
      });
      const newData = colFixed.data.map((row) => {
        const findData = response.data.resultList.find((item) => item.cost_center.id === row.cost.id);
        if (findData) {
          return findData;
        }
        return {value: null, id: null, cost_center: {...row.cost}};
      });

      setData(newData);
    })();
  }, [update]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const res = await deleteFinancials(value.id);
          setUpdate((prev) => !prev);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      <div className="inputDistribution livesotckStock fixedHeader">
        {!!data?.length && (
          <DataGrid
            onChange={async (dataGridInputs) => {
              try {
                const dataGridFactory = {
                  ...dataFactory,
                  value: parseFloat(dataGridInputs.value),
                  cost_center: {id: dataGridInputs.cost_center.id},
                  type: dataGridInputs.cost_center.type,
                  description: 'DIAGNOSIS',
                  id: dataGridInputs?.id || null,
                };
                const res = await createOrUpdateDataFull(dataGridFactory, state, createFinancials, changeFinancialsbyId, false);
                setUpdate(!update);
                return res;
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            fixedColumn={colFixed || null}
            editable=""
            disabledPaginate
            width="100%"
            actions={actions}
          />
        )}
      </div>
    </>
  );
};

export default InputDistribution;
