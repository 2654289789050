import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 10px 10px 15px;
  width: 100%;
`;

export const BreadCrumbersItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 10px 0 0;

  & a {
    all: unset;
    color: inherit;
    cursor: pointer;
  }
`;

export const NoUrl = styled.span`
  color: #a8a6a6;
`;
