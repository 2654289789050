import apiNew from '../apiNew';

const fetchRegions = (params = {}) => {
  return apiNew.get(`/regions`, {
    params: {
      ...params,
    },
  });
};

const fetchRegionsbyId = (id, params = {}) => {
  return apiNew.get(`/regions/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerRegion = (body = {}) => {
  return apiNew.post(`/regions`, body);
};

const changeRegionbyId = (id, body = {}) => {
  return apiNew.put(`/regions/${id}`, body);
};

const deleteRegion = (id) => {
  return apiNew.delete(`/regions/${id}`);
};

export {fetchRegions, fetchRegionsbyId, registerRegion, changeRegionbyId, deleteRegion};
