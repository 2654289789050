import api from '../api';

const fetchCategoryDsmProduct = (params = {}) => {
  return api.get(`/category_dsm_product`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryDsmProductbyId = (id, params = {}) => {
  return api.get(`/category_dsm_product/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryDsmProduct = (body = {}) => {
  return api.post(`/category_dsm_product`, body);
};

const changeCategoryDsmProductbyId = (id, body = {}) => {
  return api.put(`/category_dsm_product/${id}`, body);
};

const deleteCategoryDsmProduct = (id) => {
  return api.delete(`/category_dsm_product/${id}`);
};

export {fetchCategoryDsmProduct, fetchCategoryDsmProductbyId, registerCategoryDsmProduct, changeCategoryDsmProductbyId, deleteCategoryDsmProduct};
