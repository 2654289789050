import React, {useRef, useEffect} from 'react';

import secureStorage from '../../utils/SecureStorage';

const Import = () => {
  const iframeRef = useRef(null); 
  
  useEffect(() => {
    const iframe = iframeRef.current;
    iframe.addEventListener('load', handleLoad);
    iframe.addEventListener('error', handleError);
    window.addEventListener('message', handleMessage);

    // Cleanup event listeners on component unmount
    return () => {
      iframe.removeEventListener('load', handleLoad);
      iframe.removeEventListener('error', handleError);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleLoad = async () => {
    console.log('Iframe content has loaded.', process.env.REACT_APP_URL_SPA_V2);

    const token = await secureStorage.getItem('token') ?? ''

    iframeRef.current.contentWindow.postMessage({ token }, process.env.REACT_APP_URL_SPA_V2 + '/import');
  };

  const handleError = () => {
    console.log('An error occurred while loading the iframe content.');
  };

  const handleMessage = (event) => {
    // Verifique a origem da mensagem para segurança
    if (event.origin !== process.env.REACT_APP_URL_SPA_V2) {
      console.log('Descartando mensagem', event.origin)
      return;
    }
    console.log('Message received from iframe:', event.data);
  };

  return (
    <>
      <iframe
        title='Import'
        ref={iframeRef}
        src={process.env.REACT_APP_URL_SPA_V2 + '/import'}
        allowFullScreen={true}
        style={{ width: '100%', height: '95%', border: 'none'}}
      />
    </>
  );
};

export default Import;
