import React, {useEffect, useState} from 'react';
import {Checkbox, FormControl, FormGroup, FormControlLabel} from '@material-ui/core';
import {CheckboxList} from './styled';
import {maskInput} from '../../../utils/utils';
import {MASKS} from '../../../utils/constants';

const CheckboxFilter = React.forwardRef(({initialPrefetchedValues, prefetchedValues}, ref) => {
  const [checkboxLocalValues, setCheckboxLocalValues] = useState({});
  const [checkAll, setCheckAll] = useState(true);

  const isFiltered = (value) => {
    return prefetchedValues.map((prefetchedValue) => (prefetchedValue.value.value ? prefetchedValue.value.value : prefetchedValue.value)).includes(value);
  };

  const checkboxValueFinder = (value) => {
    if (value in checkboxLocalValues) {
      return checkboxLocalValues[value];
    }

    return isFiltered(value);
  };

  useEffect(() => {
    setCheckboxLocalValues({});
  }, [prefetchedValues]);

  const verifyCheck = () => {
    const result = ref && ref.current ? !ref.current.querySelectorAll('input[type=checkbox]:not(:checked)').length : null;
    setCheckAll(result);
  };

  const checkallfieds = (newValue) => {
    const fields = {};
    initialPrefetchedValues.forEach((element) => {
      Object.assign(fields, {
        [element.value && element.value.value ? element.value.value : element.value]: newValue,
      });
    });
    setCheckboxLocalValues({
      ...checkboxLocalValues,
      ...fields,
    });
    setCheckAll(newValue);
    // verifyCheck();
  };
  const formatValue = (value) => {
    if (value && typeof value === 'number' && !value.label) {
      return maskInput({
        type: MASKS.DOUBLE,
        value: `${value}`,
      });
    }
    return value && value.label ? value.label : value;
  };
  // console.log(verifyCheck());
  return (
    <CheckboxList>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              onChange={(e, newValue) => {
                checkallfieds(newValue);
              }}
              checked={checkAll}
              name="check-all"
            />
          }
          label="Marcar todos"
        />
        <FormGroup ref={ref}>
          {initialPrefetchedValues &&
            !!initialPrefetchedValues.length &&
            initialPrefetchedValues.map((obj, i) => (
              <FormControlLabel
                key={obj.value && obj.value.value ? `${obj.value.value}-${i}` : `${obj.value}-${i}`}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e, newValue) => {
                      setCheckboxLocalValues({
                        ...checkboxLocalValues,
                        [obj.value && obj.value.value ? obj.value.value : obj.value]: newValue,
                      });
                      verifyCheck();
                    }}
                    checked={checkboxValueFinder(obj.value && obj.value.value ? obj.value.value : obj.value)}
                    name={obj.value && obj.value.value ? obj.value.value.toString() : obj.value.toString()}
                  />
                }
                label={formatValue(obj.value)}
              />
            ))}
        </FormGroup>
      </FormControl>
    </CheckboxList>
  );
});

export default CheckboxFilter;
