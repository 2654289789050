import api from '../api';

const fetchGroupsHarvests = (params = {}) => {
  return api.get(`/groups_harvests`, {
    params: {
      ...params,
    },
  });
};

const fetchGroupsHarvestsbyId = (id, params = {}) => {
  return api.get(`/groups_harvests/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerGroupsHarvests = (body = {}) => {
  return api.post(`/groups_harvests`, body);
};

const deleteGroupsHarvests = (id) => {
  return api.delete(`/groups_harvests/${id}`);
};

const changeGroupsHarvestsbyId = (id, body = {}) => {
  return api.put(`/groups_harvests/${id}`, body);
};

export {
  fetchGroupsHarvests,
  fetchGroupsHarvestsbyId,
  registerGroupsHarvests,
  changeGroupsHarvestsbyId,
  deleteGroupsHarvests,
};
