import api from '../api';

const fetchAverageHerd = (params = {}) => {
  return api.get(`/average_herd_diagnosis`, {
    params: {
      ...params,
    },
  });
};
const createAverageHerd = (body = {}) => {
  return api.post(`/average_herd_diagnosis`, body);
};

export {fetchAverageHerd, createAverageHerd};
