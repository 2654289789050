import {isObject} from '../../../utils/utils';

export class DatagridColumnsHelper {
  static gender() {
    const genders = [
      {value: 'FEMALE', label: 'Fêmea'},
      {value: 'MALE', label: 'Macho'},
    ];

    return {
      editType: 'dropdownedit',
      mapper: (line, editMode) => {
        const option = genders.find((gender) => gender.value === line.sex);

        if (!editMode) return option.label;

        return {value: option.value, label: option.label};
      },

      returnFormat: (value) => {
        if (isObject(value)) return value.value;
        return value;
      },

      optionsFetch: async () => {
        return genders;
      },
    };
  }
}
