import React from 'react';
import PropTypes from 'prop-types';
import GenerateRouteCategory from './GenerateRouteCategory';

const GenerateRouteFactory = ({data, defaultBreadcrumbs}) => {
  return (
    <>
      {data.map(({options: groupOptions}, index) => (
        <React.Fragment key={''.concat('group-', index)}>
          {groupOptions.map(({options}, idx) => (
            <React.Fragment key={''.concat('group-', index, '-option-', idx)}>
              {options.map((option, i) => (
                <GenerateRouteCategory
                  key={''.concat('group-', index, '-option-', idx, '-item-', i)}
                  title={option.title}
                  url={option.url}
                  component={option.component}
                  allowed={option.allowed}
                  defaultBreadcrumbs={defaultBreadcrumbs}
                />
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

GenerateRouteFactory.propTypes = {
  defaultBreadcrumbs: PropTypes.array.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          group: PropTypes.string.isRequired,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
              component: PropTypes.func.isRequired,
              allowed: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default GenerateRouteFactory;
