import React from 'react';
import moment from 'moment';
import {
  IndicatorContainer,
  IconContainer,
  DataContainer,
  DataTitle,
  DataSubTitle,
  Data,
} from './indicatorStyled';

const Indicator = ({
  multiple,
  data,
  color,
  backgroundColor,
  iconPosition,
  alignItems,
  iconSide,
  height,
  iconColor,
  iconBackgroundColor,
  icon,
  iconSize,
  title,
  subtitle,
  iconLabel,
  // labelPosition = 'bottom',
  subtitlePosition = 'bottom',
  iconValue,
}) => {
  const iconSizeClassMaker = (currentIconSize) =>
    currentIconSize ? `fa-${currentIconSize}x` : '';

  return (
    <IndicatorContainer
      iconSide={iconSide}
      color={color}
      height={height}
      alignItems={alignItems}
      backgroundColor={backgroundColor}>
      {!iconPosition && (
        <IconContainer
          className={`${
            iconValue ? 'd-flex flex-column align-items-center' : ''
          }`}
          color={iconColor}
          backgroundColor={iconBackgroundColor}>
          <>
            <i
              className={`icon fas fa-${icon} ${iconSizeClassMaker(iconSize)}`}
            />
            {iconLabel && <small>{iconLabel}</small>}
            {iconValue && <p>{iconValue}</p>}
          </>
        </IconContainer>
      )}
      <DataContainer>
        {title && <DataTitle>{title}</DataTitle>}
        {iconPosition && iconPosition === 'between-title' && (
          <IconContainer
            color={iconColor}
            backgroundColor={iconBackgroundColor}>
            <>
              <i
                className={`icon fas fa-${icon} ${iconSizeClassMaker(
                  iconSize,
                )}`}
              />
              {iconLabel && <small>{iconLabel}</small>}
              {iconValue && <p>{iconValue}</p>}
            </>
          </IconContainer>
        )}
        {!multiple && (
          <>
            {subtitlePosition === 'top' && (
              <DataSubTitle>{subtitle}</DataSubTitle>
            )}
            <Data>{data}</Data>
            {(subtitlePosition !== 'top' ||
              subtitlePosition === 'bottom' ||
              subtitlePosition === undefined) && (
              <DataSubTitle>{subtitle}</DataSubTitle>
            )}
          </>
        )}
        {multiple &&
          Object.keys(data).map((key) => (
            <React.Fragment key={key}>
              {subtitlePosition === 'top' && <DataSubTitle>{key}</DataSubTitle>}
              <Data>{data[key]}</Data>
              {(subtitlePosition !== 'top' ||
                subtitlePosition === 'bottom' ||
                subtitlePosition === undefined) && (
                <DataSubTitle>{key}</DataSubTitle>
              )}
            </React.Fragment>
          ))}
      </DataContainer>
      <span className="updateAt">{`Atualizado ${moment().format(
        'DD/MM/YYYY',
      )} ás ${moment().format('HH:mm:ss')}
      `}</span>
    </IndicatorContainer>
  );
};

export default Indicator;
