import React from 'react';
import {Link} from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Category} from './styles';

const QuestionItem = ({category, url}) => {
  return (
    <Category>
      <Link to={url}>
        <div className="category-head">
          <h2>{category?.title}</h2>
          <ArrowForwardIosIcon />
        </div>
      </Link>
    </Category>
  );
};
export default QuestionItem;
