import React from 'react';
import uuid from 'react-uuid';
import {
  TableContainer,
  TableTitle,
  TableRows,
  TableColumn,
  TableRow,
} from './tableStyled';

const Table = ({
  rows,
  title,
  columns,
  gridTemplate,
  formatter = (arg) => arg,
}) => {
  return (
    <>
      {columns && (
        <TableContainer>
          <TableTitle>{title}</TableTitle>
          <TableRows>
            <TableRow gridTemplate={gridTemplate}>
              {columns.map((column) => (
                <TableColumn className="header" key={column.name}>
                  {column.name}
                </TableColumn>
              ))}
            </TableRow>
            {rows.map((row) => (
              <TableRow
                className="lineRow"
                key={uuid()}
                gridTemplate={gridTemplate}>
                {columns.map((column) => (
                  <TableColumn className="cel" key={row[column.key]}>
                    {formatter(row[column.key], column.key)}
                  </TableColumn>
                ))}
              </TableRow>
            ))}
          </TableRows>
        </TableContainer>
      )}
    </>
  );
};

export default Table;
