import api from '../api';

const fetchArrobaValues = (params = {}) => {
  return api.get(`/arroba_values`, {
    params: {
      ...params,
    },
  });
};

const fetchArrobaValuesDatagrid = (params = {}) => {
  return api.get(`/arroba_values/datagrid`, {
    params: {
      ...params,
    },
  });
};

const fetchArrobaValuesbyId = (id, params = {}) => {
  return api.get(`/arroba_values/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeArrobaValuesbyId = (id, body = {}) => {
  return api.put(`/arroba_values/${id}`, body);
};

const registerArrobaValues = (body = {}) => {
  return api.post(`/arroba_values`, body);
};

const deleteArrobaValues = (id) => {
  return api.delete(`/arroba_values/${id}`);
};

export {
  fetchArrobaValues,
  fetchArrobaValuesDatagrid,
  fetchArrobaValuesbyId,
  changeArrobaValuesbyId,
  registerArrobaValues,
  deleteArrobaValues,
};
