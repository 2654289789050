import api from '../api';

const fetchIndicatorsPlanned = (params = {}) => {
  return api.get(`/indicators_planned`, {
    params: {
      ...params,
    },
  });
};

const fetchIndicatorsPlannedDatagrid = (params = {}) => {
  return api.get(`/indicators_planned/datagrid`, {
    params: {
      ...params,
    },
  });
};

const fetchIndicatorsPlannedbyId = (id, params = {}) => {
  return api.get(`/indicators_planned/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeIndicatorsPlannedbyId = (id, body = {}) => {
  return api.put(`/indicators_planned/${id}`, body);
};

const registerIndicatorsPlanned = (body = {}) => {
  return api.post(`/indicators_planned`, body);
};

const deleteIndicatorsPlanned = (id) => {
  return api.delete(`/indicators_planned/${id}`);
};

export {
  fetchIndicatorsPlanned,
  fetchIndicatorsPlannedDatagrid,
  fetchIndicatorsPlannedbyId,
  changeIndicatorsPlannedbyId,
  registerIndicatorsPlanned,
  deleteIndicatorsPlanned,
};
