/* eslint-disable global-require */
const ComponentsList = {
  Autocomplete: require('./ui/components/formik/autocomplete').default,
  Number: require('./ui/components/formik/number').default,
  Input: require('./ui/components/formik/input').default,
  Textarea: require('./ui/components/formik/textarea').default,
  InputMasked: require('./ui/components/formik/input_masked').default,
  InputPassword: require('./ui/components/formik/input_password').default,
  Select: require('./ui/components/formik/select').default,
  SelectOption: require('./ui/components/formik/select-option').default,
  Button: require('./ui/components/formik/button').default,
  Checkbox: require('./ui/components/formik/checkbox').default,
  Space: require('./ui/components/formik/space').default,
};

export default ComponentsList;
