import styled from 'styled-components';

export const TableViewStyled = styled.div`
  border: 1px solid rgb(221, 221, 221);
  border-left: none;
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgb(221, 221, 221); /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0c4ca3;
    /* color of the scroll thumb */
    /* border: 1px solid rgb(221, 221, 221);  */
    border-radius: 10px; /* creates padding around scroll thumb */
  }
`;
export const HeaderViewStyled = styled.div`
  background-color: #fff;
  border-bottom: 2px solid rgb(221, 221, 221);
  border-top: 1px solid rgb(221, 221, 221);
  display: grid;
  position: sticky;
  top: 0;
`;
export const HeaderItemStyled = styled.label`
  align-items: center;
  border-left: 1px solid rgb(221, 221, 221);
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  text-align: center;
`;
export const Row = styled.div`
  border-top: 1px solid rgb(221, 221, 221);
  display: grid;
  &:nth-child(2n) {
    background-color: #e6e6e6;
  }
`;
export const BodyViewStyled = styled.div``;
export const BodyItemStyled = styled.div`
  align-items: center;
  border-left: 1px solid rgb(221, 221, 221);
  display: flex;
  font-size: 10px;
  font-weight: 100;
  height: 100%;
  justify-content: center;
  min-height: 35px;
  overflow: hidden;
  padding: 6px 3px;
  text-align: center;
`;
