/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import {createOrUpdateDataFull} from '../../../utils/utils';
import {fetchStocks, changeStocksbyId, registerStock, deleteStock} from '../../../services/stocks';
import fetchSupportMaterial from '../../../services/supportMaterial';
import {hasProperty} from '../../../utils/functions';

const InputControl = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParams, stateFetchParamsCustom, stateMessage, filterParams} = useContext(DataContext);
  const dataFactory = {
    validated: true,
    amount: 1,
    material: 'MATERIAL FINAL',
    category_material_type: {
      id: 2,
    },
    category_unit_measurement: {
      id: 6,
    },
  };
  const colFixed = {
    header: [
      {
        tooltip: `Inserir a Descrição dos estoques de insumos no primeiro e no último dia da safra.\n\n`,
        key: 'harvest',
        valueKey: 'harvest',
        height: 'auto',
        field: 'harvest',
        name: 'Descrição',
        width: '300px',
        textAlign: 'Center',
        editType: 'defaultedit',
        disabledEdit: true,
        disableFilter: true,
      },
    ],
    data: [
      {
        // eslint-disable-next-line camelcase
        harvest: `VALOR DO ESTOQUE TOTAL 01/07/${state?.harvest?.first_year}`,
        date_harvest: false,
      },
      {
        // eslint-disable-next-line camelcase
        harvest: `VALOR DO ESTOQUE TOTAL 30/06/${state?.harvest?.last_year}`,
        date_harvest: true,
      },
    ],
  };
  const columns = [
    {
      tooltip: `Inserir valor total (R$) dos estoques de insumos no primeiro e no último dia da safra.\n\n
      Para o cálculo de resultado o balanço de estoques é uma das varíáveis.\n\n
      OBS: Neste Diagnóstico foi inserida planilha de apoio para este preenchimento: "Apoio - ESTOQUES"`,
      key: 'value',
      field: 'value',
      required: true,
      // width: '100%',
      name: 'Valor (R$)',
      textAlign: 'Left',
      editType: 'moneyedit',
    },
  ];

  useEffect(() => {
    const loadStocks = async () => {
      const response = await fetchStocks({
        amount: 1,
        material: 'MATERIAL FINAL',
        ...stateFetchParams,
        ...filterParams,
      });
      const newData = colFixed.data.map((row) => {
        const findData = response.data.resultList.find((item) => item.date_harvest === row.date_harvest);
        if (findData) {
          return findData;
        }
        return {value: null, id: null, date_harvest: row.date_harvest};
      });

      setData(newData);
    };
    loadStocks();
  }, [update, stateFetchParamsCustom, filterParams]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const res = await deleteStock(value.id);
          setUpdate((prev) => !prev);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      <div className="inputControl livesotckStock fixedHeader">
        <div className="container">
          <div className="tip-title">Insumos como adubos, arames, suplementos, óleo diesel, medicamentos, materiais de construção, madeiras, etc.</div>
        </div>

        {!!data?.length && (
          <DataGrid
            onChange={async (dataGridInputs) => {
              if (!hasProperty(dataGridInputs, 'date_harvest')) {
                dataGridInputs = {
                  ...dataGridInputs,
                  date_harvest: false,
                };
              }
              if (dataGridInputs?.value) {
                dataGridInputs = {
                  ...dataGridInputs,
                  value: parseFloat(dataGridInputs?.value),
                };

                try {
                  const res = await createOrUpdateDataFull({...dataFactory, ...dataGridInputs}, state, registerStock, changeStocksbyId, false);
                  setUpdate(!update);
                  return res;
                } catch (error) {
                  console.error(error);
                  return error;
                }
              }
              return null;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            fixedColumn={colFixed || null}
            editable=""
            disabledPaginate
            width="100%"
            actions={actions}
          />
        )}
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={async () => {
              await fetchSupportMaterial('/support_material_diagnosis/stock');
            }}>
            <i className="fas fa-download mr-3" />
            Apoio - Estoques
          </Button>
        </DialogActions>
      </div>
    </>
  );
};

export default InputControl;
