export const textButtons = (configuratorToogle) => ({
  title: 'Filtros de Texto',
  icon: '',
  onClick: () => {},
  options: [
    {
      title: 'Igual...',
      onClick: () => configuratorToogle({label: 'Igual', value: '='}),
    },
    {
      title: 'Não Igual...',
      onClick: () => configuratorToogle({label: 'Não Igual', value: '<>'}),
    },
    {
      title: 'Contém...',
      onClick: () => configuratorToogle({label: 'Contém', value: 'contains'}),
    },
    {
      title: 'Filtro customizado...',
      onClick: () => configuratorToogle(),
    },
  ],
});

export const numericButtons = (configuratorToogle) => ({
  title: 'Filtros numéricos',
  icon: '',
  onClick: () => {},
  options: [
    {
      title: 'Igual...',
      onClick: () => configuratorToogle({label: 'Igual', value: '='}),
    },
    {
      title: 'Não Igual...',
      onClick: () => configuratorToogle({label: 'Não Igual', value: '<>'}),
    },
    {
      title: 'Menor que...',
      onClick: () => configuratorToogle({label: 'Menor Que', value: '<'}),
    },
    {
      title: 'Menor ou igual...',
      onClick: () => configuratorToogle({label: 'Menor ou igual', value: '<='}),
    },
    {
      title: 'Maior que...',
      onClick: () => configuratorToogle({label: 'Maior que', value: '>'}),
    },
    {
      title: 'Maior que ou igual...',
      onClick: () => configuratorToogle({label: 'Maior ou igual', value: '>='}),
    },
    {
      title: 'Entre...',
      onClick: () => configuratorToogle({label: 'Maior ou igual', value: '>='}),
    },
    {
      title: 'Filtro customizado...',
      onClick: () => configuratorToogle(),
    },
  ],
});
