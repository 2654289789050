import React, {useEffect, useState, useContext} from 'react';
import {FaqContainer, Title, CategoriesBox} from './styles';
import Loading from '../../../components/Loading/Loading';
import {DataContext} from '../../../utils/DataProvider';
import CategoryItem from './categoryItem';
import {fetchCategoryFaq} from '../../../services/categoryFaq';

const Faq = () => {
  const [categoryFaq, setCategoryFaq] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [refresh, setRefresh] = useState(true);
  const {state} = useContext(DataContext);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  useEffect(() => {
    (async () => {
      try {
        // setRefresh((prev) => !prev);
        const {data} = await fetchCategoryFaq({environment: ENVS});
        setCategoryFaq(data.resultList);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);// eslint-disable-line

  return (
    <FaqContainer>
      <Title>FAQ</Title>
      {!loading ? (
        <CategoriesBox>
          {!categoryFaq.length && <h4>Nenhuma pergunta cadastrada.</h4>}
          {!!categoryFaq.length && categoryFaq.map((item) => <CategoryItem key={item.id} category={item} url={`/corte/faq-categoria/${item.id}`} />)}
        </CategoriesBox>
      ) : (
        <Loading />
      )}
    </FaqContainer>
  );
};
export default Faq;
