import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DataGrid from '../../components/DataGrid';
import {maskInput, createOrUpdateDataFull} from '../../utils/utils';
import {MASKS} from '../../utils/constants';
import {DataContext} from '../../utils/DataProvider';
import {fetchHerdEntry, registerHerdEntry, changeHerdEntrybyId, deleteHerdEntry} from '../../services/herdEntry';
import {fetchCategoryHerdEntryType} from '../../services/categoryHerdEntryType';
import {fetchCategoryLivestock} from '../../services/categoryLivestock';

const ShoppingGrid = ({origin}) => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getContents = async () => {
      try {
        const response = await fetchHerdEntry({
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(response.data.size);
        setData(response.data.resultList);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [reset, stateFetchParams]);// eslint-disable-line

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    dataGridRows.forEach((row) => {
      if (row.notCreated) {
        return;
      }
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, registerHerdEntry, changeHerdEntrybyId));
    });
    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  const validationFindData = (dataTemp) => {
    let validated = true;
    dataTemp.forEach((row) => {
      validated = validated && row.validated;
    });

    return dataTemp.length ? validated : false;
  };

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        const [date] = line.date.split(' ');
        const [day, month, year] = date.split('/');

        if (!editMode) {
          // eslint-disable-next-line eqeqeq
          if (line.period === true) {
            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('MM/YYYY');
          }

          return moment(new Date(year, month - 1, day))
            .locale('pt-br')
            .format('DD/MM/YYYY');
        }

        return moment(new Date(year, month - 1, day));
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'origin',
      field: 'origin',
      name: 'Origem',
      required: true,
      textAlign: 'Center',
      width: '250px',
      editType: 'defaultedit',
    },
    {
      key: 'category_herd_entry_type',
      valueKey: 'category_herd_entry_type.title',
      field: 'category_herd_entry_type',
      name: 'Tipo de entrada',
      required: true,
      textAlign: 'Center',
      width: '230px',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryHerdEntryType({
            'order[title]': 'ASC',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'category_livestock',
      valueKey: 'category_livestock.title',
      field: 'category_livestock',
      name: 'Categoria',
      required: true,
      textAlign: 'Center',
      width: '230px',
      editType: 'dropdownedit',
      groupBy: (option) => {
        return option.category_age;
      },
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryLivestock({
            'match[show]': 'Compras e outras entradas',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
              category_age: item.category_age.stage,
            }),
          );
          /* objectTemp.sort((a, b) =>
            b.category_age.localeCompare(a.category_age),
          ); */
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'amount',
      field: 'amount',
      name: 'Quantidade',
      required: true,
      textAlign: 'Center',
      width: '160px',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
      returnFormat: (value) => {
        // eslint-disable-next-line radix
        return parseInt(value);
      },
    },
    {
      key: 'weight_alive_head',
      required: true,
      field: 'weight_alive_head',
      name: 'Peso vivo/cabeça (kg)',
      textAlign: 'Center',
      width: '160px',
      editType: 'weightedit',
      // returnFormat: (value) => {
      //   // eslint-disable-next-line radix
      //   return parseInt(value);
      // },
    },

    {
      key: 'value_head',
      field: 'value_head',
      required: true,
      name: 'R$/cabeça',
      textAlign: 'Center',
      width: '160px',
      editType: 'moneyedit',
      // returnFormat: (value) => {
      //   // eslint-disable-next-line radix
      //   return parseInt(value);
      // },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      textAlign: 'Center',
      width: '160px',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteHerdEntry(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  return (
    <>
      {stateMessage}
      {data && (
        <div className="shoppingGrid">
          <DataGrid
            onChange={async (dataGridInputs) => {
              Object.assign(dataGridInputs, {
                status: false,
              });
              if (dataGridInputs && dataGridInputs.date === 'Invalid date') {
                delete dataGridInputs.date;
              }
              if (origin === 'DIAGNOSIS' && dataGridInputs.from_stock_valuation) {
                dataGridInputs.from_stock_valuation = false;
                setOpen(dataGridInputs);
              } else {
                const response = await createOrUpdateDataFull(dataGridInputs, state, registerHerdEntry, changeHerdEntrybyId);
                setReset(!reset);
                return response;
              }
              return null;
            }}
            header={columns}
            data={data}
            keyProp="key"
            width="max-content"
            maxHeight="530px"
            keyValueProp="valueKey"
            titleProp="name"
            validateCallback={validateHeaderCallback}
            editable=""
            actions={actions}
            totalItems={totalItems}
            accumulators={{
              'Total geral': {
                columns: ['amount', 'weight_alive_head', 'value_head'],
                itemOperation: (acc, line, key) => {
                  if (key === 'weight_alive_head') {
                    return (acc += line[key] * line.amount);
                  }
                  if (key === 'value_head') {
                    return (acc += line[key] * line.amount);
                  }
                  return (acc += line[key]);
                },
                resultOperation: (acc, key) => {
                  if (key === 'value_head') {
                    return maskInput({value: acc, type: MASKS.MONEY});
                  }
                  if (key === 'weight_alive_head') {
                    return maskInput({value: acc, type: MASKS.WEIGHT});
                  }

                  return maskInput({
                    value: acc,
                    type: MASKS.DOUBLE,
                  });
                },
              },

              'Ponderado geral': {
                columns: ['weight_alive_head', 'value_head'],
                itemOperation: (acc, line, key) => {
                  if (acc) {
                    if (key === 'weight_alive_head') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                    if (key === 'value_head') {
                      return {
                        sum: (acc.sum += line[key] * line.amount),
                        quantity: (acc.quantity += line.amount),
                      };
                    }
                  }
                  return {
                    sum: acc ? (acc.sum += line[key] * line.amount) : line[key] * line.amount,
                    quantity: acc ? (acc.quantity += line.amount) : line.amount,
                  };
                },
                resultOperation: (acc, key) => {
                  if (key === 'value_head') {
                    return maskInput({
                      value: acc.sum / acc.quantity,
                      type: MASKS.MONEY,
                    });
                  }
                  return maskInput({
                    value: acc.sum / acc.quantity,
                    type: MASKS.NUMBER,
                  });
                },
              },
            }}
          />
          <Dialog open={!!open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <i className="fas fa-engine-warning" />
              Atenção
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ao manipulador dados na tabela de apoio, os dados da tabela Principal serão substituidos e excluidos. Deseja Realmente realizar essa operação?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary" autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  const response = await createOrUpdateDataFull(open, state, registerHerdEntry, changeHerdEntrybyId);
                  setReset(!reset);
                  setOpen(false);

                  return response;
                }}
                color="primary"
                autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ShoppingGrid;
