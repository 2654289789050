import React, {useEffect, useContext, useState} from 'react';
import styled from 'styled-components';
import {Pie, Line} from 'react-chartjs-2';
import {Col, Row, Container} from 'reactstrap';
import IMask from 'imask';
import Card from '../../components/Card';
import InfoGraphic from '../../components/Card/InfoGraphic';
import 'chart.piecelabel.js';
import {getDataChart, colors} from '../../utils/utils';
import {
  fetchGraphicBar,
  fetchGraphicPie,
  fetchGraphicKpi,
  fetchValidated,
} from '../../services/financials/cards';
import {DataContext} from '../../utils/DataProvider';

const CardsFinacials = () => {
  const [dataValidatedReceita, setDataValidatedReceita] = useState(null);
  const [dataValidatedDespesa, setDataValidatedDespesa] = useState(null);
  const [dataKpiDespesa, setDataKpiDespesa] = useState(null);
  const [dataKpiReceita, setDataKpiReceita] = useState(null);
  const [dataPieDespesa, setDataPieDespesa] = useState(null);
  const [dataPieReceita, setDataPieReceita] = useState(null);
  const [dataBarDespesa, setDataBarDespesa] = useState(null);
  const [dataBarReceita, setDataBarReceita] = useState(null);
  const {stateFetchParamsCustom, stateMessage} = useContext(DataContext);

  const optionsBarGraphic = {
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: '#1f77b4',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    // pointBorderColor: '#1f77b4',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 5,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };

  const getKpi = async (setState, params) => {
    try {
      const resp = await fetchGraphicKpi(params);
      setState(resp.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getValidated = async (setState, params) => {
    try {
      const {data} = await fetchValidated(params);
      setState(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const paramsReceita = {...stateFetchParamsCustom, cost_type: 'RECEITAS'};
    const paramsDespesas = {...stateFetchParamsCustom, cost_type: 'DESPESAS'};

    const loadData = async () => {
      getKpi(setDataKpiReceita, paramsReceita);
      getKpi(setDataKpiDespesa, paramsDespesas);
      getValidated(setDataValidatedDespesa, paramsDespesas);
      getValidated(setDataValidatedReceita, paramsReceita);

      setDataBarReceita(
        await getDataChart(
          fetchGraphicBar,
          paramsReceita,
          null,
          false,
          optionsBarGraphic,
        ),
      );
      setDataPieReceita(
        await getDataChart(
          fetchGraphicPie,
          paramsReceita,
          null,
          true,
          null,
          colors,
          true,
        ),
      );
      setDataBarDespesa(
        await getDataChart(
          fetchGraphicBar,
          paramsDespesas,
          null,
          false,
          optionsBarGraphic,
        ),
      );
      setDataPieDespesa(
        await getDataChart(
          fetchGraphicPie,
          paramsDespesas,
          null,
          true,
          null,
          colors,
          true,
        ),
      );
    };
    loadData();
  }, []);

  return (
    <Container fluid>
      {stateMessage}
      <Card type="income" className="card d-flex">
        <Row className="justify-content-between">
          <h5>Receitas</h5>
          <i
            className={`fas fa-${
              dataValidatedReceita && dataValidatedReceita.validated
                ? 'check'
                : 'exclamation'
            }`}
          />
        </Row>
        <Col>
          <Row>
            {dataKpiReceita && (
              <Col xs={2} className="cards p-3">
                <InfoGraphic data={dataKpiReceita} />
              </Col>
            )}
            {dataBarReceita && (
              <Col xs={4} className="cards p-3">
                <Line
                  data={dataBarReceita}
                  height="300px"
                  options={{
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                    },
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return `${IMask.createMask({
                            mask: 'R$ money',
                            blocks: {
                              money: {
                                mask: Number, // enable number mask

                                // other options are optional with defaults below
                                scale: 2, // digits after point, 0 for integers
                                signed: false, // disallow negative
                                thousandsSeparator: '.', // any single char
                                padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
                                normalizeZeros: false, // appends or removes zeros at ends
                                radix: ',', // fractional delimiter

                                // additional number interval options (e.g.)
                                min: 0,
                                max: 9999999999999,
                              },
                            },
                          }).resolve(tooltipItem.value.toString())}`;
                        },
                      },
                    },
                    showLines: true,
                    scaleShowLabels: false,
                    legend: {
                      display: false,
                    },

                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            display: false, // this will remove only the label
                          },
                          gridLines: {
                            display: false,
                            drawBorder: false,
                            color: '#fff',
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
            {dataPieReceita && (
              <Col xs={6} className="cards p-3">
                <Pie
                  data={dataPieReceita}
                  height="200px"
                  options={{
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                    },
                    pieceLabel: [
                      {
                        render: 'label',
                        // fontColor: '#000',
                        position: 'outside',
                        // fontFamily: 'Fire Sans',
                        // fontStyle: '100',
                        arc: true,
                      },
                      {
                        render: 'percentage',
                        fontSize: 14,
                        // fontStyle: '200',
                        fontColor: '#000',
                        // fontFamily: 'Fire Sans',
                      },
                    ],
                  }}
                />
              </Col>
            )}
            {!dataPieReceita && !dataBarReceita && (
              <EmptyCardMessage>Nenhum registro encontrado</EmptyCardMessage>
            )}
          </Row>
        </Col>
      </Card>

      <Card type="income" className="card d-flex">
        <Row className="justify-content-between">
          <h5>Despesas</h5>
          <i
            className={`fas fa-${
              dataValidatedDespesa && dataValidatedDespesa.validated
                ? 'check'
                : 'exclamation'
            }`}
          />
        </Row>
        <Col>
          <Row>
            {dataKpiDespesa && (
              <Col xs={2} className="cards p-3">
                <InfoGraphic data={dataKpiDespesa} />
              </Col>
            )}
            {dataBarDespesa && (
              <Col xs={4} className="cards p-3">
                <Line
                  data={dataBarDespesa}
                  height="300px"
                  options={{
                    tooltips: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return `${IMask.createMask({
                            mask: 'R$ money',
                            blocks: {
                              money: {
                                mask: Number, // enable number mask

                                // other options are optional with defaults below
                                scale: 2, // digits after point, 0 for integers
                                signed: false, // disallow negative
                                thousandsSeparator: '.', // any single char
                                padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
                                normalizeZeros: false, // appends or removes zeros at ends
                                radix: ',', // fractional delimiter

                                // additional number interval options (e.g.)
                                min: 0,
                                max: 9999999999999,
                              },
                            },
                          }).resolve(tooltipItem.value.toString())}`;
                        },
                      },
                    },
                    showLines: true,
                    scaleShowLabels: false,
                    legend: {
                      display: false,
                    },
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            display: false, // this will remove only the label
                          },
                          gridLines: {
                            display: false,
                            drawBorder: false,
                            color: '#fff',
                          },
                        },
                      ],
                    },
                  }}
                />
              </Col>
            )}
            {dataPieDespesa && (
              <Col xs={6} className="cards p-3">
                <Pie
                  data={dataPieDespesa}
                  height="200px"
                  options={{
                    legend: {
                      display: !(dataPieDespesa.labels.length > 5),
                    },
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                    },
                    pieceLabel: [
                      {
                        render: 'label',
                        // fontColor: '#000',
                        position: 'outside',
                        // fontFamily: 'Fire Sans',
                        // fontStyle: '100',
                        arc: true,
                      },
                      {
                        render: 'percentage',
                        fontSize: 14,
                        // fontStyle: '200',
                        fontColor: '#000',
                        // fontFamily: 'Fire Sans',
                      },
                    ],
                  }}
                />
              </Col>
            )}
            {!dataPieDespesa && !dataBarDespesa && (
              <EmptyCardMessage>Nenhum registro encontrado</EmptyCardMessage>
            )}
          </Row>
        </Col>
      </Card>
    </Container>
  );
};

const EmptyCardMessage = styled.p`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 35px 0 0 0;
  text-align: center;
  width: 100%;
`;

export default CardsFinacials;
