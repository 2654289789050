import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../../../components/Section/Section';

const Moves = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Movimentações',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Nascimentos',
              //   url: `${match.url}/tipo/receitas`,
            },
            {
              title: 'Morte',
              //   url: `${match.url}/tipo/despesas`,
            },
            {
              title: 'Compras',
              //   url: `${match.url}/tipo/despesas`,
            },
            {
              title: 'Vendas',
              //   url: `${match.url}/tipo/despesas`,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <div className="moves">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Movimentações'}} render={() => <Section templateData={templateData} />} />
    </div>
  );
};
export default Moves;
