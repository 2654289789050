import React, {useContext} from 'react';
import './style.scss';
import {DataContext} from '../../utils/DataProvider';
import {useModuleNameContext} from '../../utils/Providers/ModuleNameProvider';
import {FILTER} from '../../utils/constants';

const PrimaryFilterDisplay = () => {
  const {state, dispatch} = useContext(DataContext);
  const moduleName = useModuleNameContext();

  const showPrimaryFilter = () => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  };

  return (
    <>
      {state.customer && (
        <span id="icon-sidebar-filter">
          <div className="icon-container">
            <i
              aria-label="open-filter"
              tabIndex={0}
              onClick={() => {
                showPrimaryFilter();
              }}
              onKeyPress={() => {
                showPrimaryFilter();
              }}
              role="button"
              className="fas fa-cog"
            />
          </div>
          <div className="text-container">
            {/* <p>Cliente: {state.customer ? state.customer.name : ''}</p> */}
            <p>Safra: {state.harvest ? state.harvest.title : ''}</p>
            <p>Fazenda: {state.farm ? state.farm.name : ''}</p>
            <p className="moduleName">Módulo: {moduleName}</p>
          </div>
        </span>
      )}
    </>
  );
};
export default PrimaryFilterDisplay;
