/* eslint-disable camelcase */
/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {createOrUpdateDataFull} from '../../../../utils/utils';
import {fetchFinancials, deleteFinancials, changeFinancialsbyId, createFinancials} from '../../../../services/financials';

const OtherRevenue = () => {
  const [data, setData] = useState([]);
  // const [diferences, setDiferences] = useState(null);

  const [totalItems, setTotalItems] = useState(0);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const dataFactory = {
    date: `30/06/${state?.harvest?.last_year} 00:00:00`,
    period: true,
    status: false,
    validated: true,
  };

  const columns = [
    {
      tooltip: `Da atividade pecuária: Venda de equipamentos/máquinas;\nArrendamento/aluguel de pastagens; etc.`,
      field: 'label',
      name: 'Outras Receitas',
      disableFilter: true,
      subFields: [
        {
          key: 'description',
          field: 'description',
          name: 'Descrição',
          textAlign: 'Center',
          // width: '50%',
          editType: 'textedit',
          required: true,
        },
        {
          key: 'value',
          field: 'value',
          required: true,
          name: 'Valor (R$)',
          textAlign: 'Center',
          editType: 'moneyedit',
        },
      ],
    },
  ];
  useEffect(() => {
    const getContents = async () => {
      try {
        const responseRevenue = await fetchFinancials({
          type: 'RECEITAS',
          'wherein[costCenter.id]': 122,
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(responseRevenue.data.size);
        setData(responseRevenue.data.resultList);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [update, stateFetchParams]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const res = await deleteFinancials(value.id);
          setUpdate((prev) => !prev);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      <div className="livesotckStock fixedHeader">
        <DataGrid
          onChange={async (dataGridInputs) => {
            const dataGridFactory = {
              ...dataGridInputs,
              ...dataFactory,
              cost_center: {id: 122},
              type: 'RECEITAS',
            };
            await createOrUpdateDataFull(dataGridFactory, state, createFinancials, changeFinancialsbyId, false);
            setUpdate(!update);
            return null;
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          disabledPaginate
          // width="max-content"
          editable=""
          actions={actions}
          totalItems={totalItems}
        />
      </div>
    </>
  );
};

export default OtherRevenue;
