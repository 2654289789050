import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../components/Section/Section';
import './style.scss';
import AreaGrid from './AreaGrid';
import BirthGrid from './BirthGrid';
import CardsLivestock from './CardsLivestock';
import ConfinementGrid from './ConfinementGrid';
import DeathsGrid from './DeathsGrid';
import RideGrid from './RideGrid';
import SalesGrid from './SalesGrid';
import ShoppingGrid from './ShoppingGrid';
import StockGrid from './StockGrid';
import WeaningBirthGrid from './WeaningBirthGrid';
import WeaningGrid from './WeaningGrid';

const Team = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Movimentações',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Nascimento',
              url: `${match.url}/nascimento`,
            },
            {
              title: 'Desmames',
              url: `${match.url}/desmames`,
            },
            {
              title: 'Mortes',
              url: `${match.url}/mortes`,
            },
            {
              title: 'Confinamento',
              url: `${match.url}/confinamento`,
            },
            {
              title: 'Compras e outras entradas',
              url: `${match.url}/compras-outras-entradas`,
            },
            {
              title: 'Vendas e outras saídas',
              url: `${match.url}/vendas-outras-saidas`,
            },
          ],
        },
      ],
    },
    {
      category: 'Efetivo Pecuário',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Estoque pecuário',
              url: `${match.url}/estoque-pecuario`,
            },
            {
              title: 'Área',
              url: `${match.url}/area`,
            },
          ],
        },
      ],
    },
    {
      category: 'Reprodução',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Monta/toque',
              url: `${match.url}/monta-toque`,
            },
            {
              title: 'Nascimento/desmames',
              url: `${match.url}/nascimento-desmames`,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className="livestock">
      <GuardedRoute
        exact
        path={match.url}
        meta={{moduleName: 'Pecuária'}}
        render={() => (
          <Section templateData={templateData}>
            <CardsLivestock />
          </Section>
        )}
      />
      <GuardedRoute path={`${match.url}/nascimento`} meta={{moduleName: 'Nascimento'}} component={BirthGrid} />
      <GuardedRoute path={`${match.url}/desmames`} meta={{moduleName: 'Desmames'}} component={WeaningGrid} />
      <GuardedRoute path={`${match.url}/mortes`} meta={{moduleName: 'Mortes'}} component={DeathsGrid} />
      <GuardedRoute path={`${match.url}/confinamento`} meta={{moduleName: 'Confinamento'}} component={ConfinementGrid} />
      <GuardedRoute path={`${match.url}/compras-outras-entradas`} meta={{moduleName: 'Compras e outras entradas'}} component={ShoppingGrid} />
      <GuardedRoute path={`${match.url}/vendas-outras-saidas`} meta={{moduleName: 'Vendas e outras saídas'}} component={SalesGrid} />
      <GuardedRoute path={`${match.url}/estoque-pecuario`} meta={{moduleName: 'Estoque pecuário'}} component={StockGrid} />
      <GuardedRoute path={`${match.url}/area`} meta={{moduleName: 'Área'}} component={AreaGrid} />
      <GuardedRoute path={`${match.url}/monta-toque`} meta={{moduleName: 'Monta/toque'}} component={RideGrid} />
      <GuardedRoute path={`${match.url}/nascimento-desmames`} meta={{moduleName: 'Nascimento/desmames'}} component={WeaningBirthGrid} />
      <GuardedRoute path={`${match.url}/cards`} meta={{moduleName: 'Cards'}} component={CardsLivestock} />
    </div>
  );
};
export default Team;
