import api from '../api';

const fetchProductionArea = (params = {}) => {
  return api.get(`/area_team_diagnosis`, {params: {...params}});
};
const createProductionArea = (body = {}) => {
  return api.post(`/area_team_diagnosis`, body);
};

export {createProductionArea, fetchProductionArea};
