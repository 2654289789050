import apiNew from '../apiNew';

const fetchHerdEntry = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/herd_entry`, {
    params: {environment, ...params},
  });
};
const fetchHerdEntryKPI = (params = {}) => {
  return apiNew.get(`/herd_entry/graphic_kpi`, {
    params: {
      ...params,
    },
  });
};

const fetchHerdEntrybyId = (id, params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/herd_entry/${id}`, {
    params: {environment, ...params},
  });
};
const fetchHerdEntryLine = (params = {}) => {
  return apiNew.get(`/herd_entry/graphic_line`, {
    params: {
      ...params,
    },
  });
};

export {fetchHerdEntryKPI, fetchHerdEntryLine};
const registerHerdEntry = (body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.post(`/herd_entry`, {environment, ...body});
};

const changeHerdEntrybyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.put(`/herd_entry/${id}`, {environment, ...body});
};

const deleteHerdEntry = (id) => {
  return apiNew.delete(`/herd_entry/${id}`);
};

export {fetchHerdEntry, fetchHerdEntrybyId, registerHerdEntry, changeHerdEntrybyId, deleteHerdEntry};
