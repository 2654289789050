import React from 'react';
import Indicator from '../DataPresenters/Indicator';
import {maskInput} from '../../../utils/utils';
import {MASKS} from '../../../utils/constants';

const DashboardStock = ({finalStock, initialStock}) => {
  return (
    <div>
      {finalStock !== undefined && (
        <div className="pb-2">
          <Indicator
            icon="box-open"
            iconSize={4}
            color="#000"
            height="auto"
            alignItems="center"
            iconPosition="between-title"
            iconColor="#000"
            backgroundColor="#ffff66"
            data={`R$ ${maskInput({
              type: MASKS.DOUBLE,
              value: `${Math.round(finalStock)}`,
            })}`}
            title="Estoque Final"
            subtitle="Valor"
          />
        </div>
      )}
      {initialStock !== undefined && (
        <Indicator
          icon="box-open"
          iconSize={4}
          color="#000"
          height="auto"
          alignItems="center"
          iconPosition="between-title"
          iconColor="#000"
          backgroundColor="#ffff66"
          data={`R$ ${maskInput({
            type: MASKS.DOUBLE,
            value: `${Math.round(initialStock)}`,
          })}`}
          title="Estoque inicial"
          subtitle="Valor"
        />
      )}
    </div>
  );
};

export default DashboardStock;
