import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import * as Yup from 'yup';
import './index.scss';
import {normalizeCollectionData} from '../../../utils/utils';
import {fetchFarms} from '../../../services/farms';
import {fetchCustomersForCombo, fetchCustomers} from '../../../services/customers';
import GenerateForm from '../../GenerateForm';
import {fetchUsers} from '../../../services/user';
import {useDataContext} from '../../../utils/DataProvider';
import {ROLES} from '../../../utils/constants';

const FilterDashboards = ({previous, submit, setOpenFilter}) => {
  const ref = useRef(null);
  const [slideOut] = useState(false);
  const [dataForm, setDataForm] = useState(previous);
  const [farms, setFarms] = useState([]);
  const {state} = useDataContext();

  const loadCustomersForCombo = async (idTechnical) => {
    try {
      const {data} = await fetchCustomersForCombo(idTechnical);
      // setCustomerData(data.resultList);
      return data.resultList;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const loadCustomers = async (params) => {
    try {
      const {data} = await fetchCustomers(params);
      // setCustomerData(data.resultList);
      return data.resultList;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  const loadFarms = async (params) => {
    try {
      const {data} = await fetchFarms(params);
      setFarms(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const [disableFields, setDisableFields] = useState({
    customer: true,
    farm: true,
    painel_consultas: true,
  });
  useEffect(() => {
    if (state.user.user_type === ROLES.CUSTOMER) {
      setDisableFields({
        customer: false,
      });
    } else if (previous.technician) {
      setDisableFields({
        customer: false,
        farm: false,
        painel_consultas: false,
      });
    }
  }, [dataForm]);// eslint-disable-line
  // const referrer = location.state ? location.state.referrer : null;

  useEffect(() => {
    if (dataForm && dataForm.customer) {
      loadFarms({
        'wherein[customer.id]': dataForm.customer.value,
      });
    }
  }, [dataForm.customer]);// eslint-disable-line

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    if (submit) {
      submit(values, actions);
    }
    actions.setSubmitting(false);
    setOpenFilter((prev) => !prev);
  };

  const groupFields = [
    {
      name: '',
      key: 1,
      fields: [
        {
          hideField: state.user.user_type === ROLES.CUSTOMER,
          label: 'Técnico',
          name: 'technician',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione um Técnico',
          value: previous.technician ? previous.technician : null,
          currentValue: previous.technician ? previous.technician : null,
          required: state.user.user_type !== ROLES.CUSTOMER,
          validations: {
            technician: state.user.user_type !== ROLES.CUSTOMER ? Yup.string().required('Campo obrigatório') : Yup.string(),
          },
          optionsFetch: async () => {
            try {
              const params = {
                'wherein[userType]': `'${ROLES.TECHNICAL}'`,
              };
              if (state.user.user_type === ROLES.TECHNICAL) {
                params['wherein[id]'] = state.user.id;
              }
              const response = await fetchUsers(params);
              return normalizeCollectionData(response.data.resultList, [
                {alias: 'label', key: 'name'},
                {alias: 'value', key: 'id'},
              ]);
            } catch (err) {
              console.error(err);
            }
            return [];
          },
          columns: {
            xs: 12,
          },
          onChange: (data) => {
            setDataForm((prev) => Object.assign(prev, {customer: null, farm: null}));
            if (data) {
              setDataForm((prev) => Object.assign(prev, {technician: data}));
              setDisableFields({
                customer: false,
                farm: true,
                painel_consultas: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {technician: null}));
              setDisableFields({
                customer: true,
                farm: true,
                painel_consultas: true,
              });
            }
          },
        },
        {
          label: 'Cliente',
          name: 'customer',
          type: 'autocomplete',
          hasPlaceholder: true,
          disabled: disableFields.customer || false,
          placeholder: 'Selecione o cliente',
          value: previous.customer ? previous.customer : null,
          currentValue: previous.customer ? previous.customer : null,
          required: true,
          validations: {
            customer: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            if (data && data !== '') {
              setDataForm((prev) => Object.assign(prev, {farm: null}));
              setDataForm((prev) => Object.assign(prev, {customer: data}));
              setDisableFields({
                customer: false,
                farm: false,
                painel_consultas: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {customer: null}));
              setDisableFields({
                customer: false,
                farm: true,
                painel_consultas: false,
              });
            }
          },
          optionsFetch: async () => {
            try {
              if (dataForm.technician) {
                const resp = await loadCustomersForCombo(dataForm.technician.value);
                return normalizeCollectionData(resp, [
                  {alias: 'label', key: 'name'},
                  {alias: 'value', key: 'id'},
                ]);
              }
              if (state.user.user_type === ROLES.CUSTOMER) {
                const resp = await loadCustomers({
                  'wherein[user.id]': state.user.id,
                });
                return normalizeCollectionData(resp, [
                  {alias: 'label', key: 'name'},
                  {alias: 'value', key: 'id'},
                ]);
              }
            } catch (err) {
              console.error(err);
            }
            return [];
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Fazenda',
          name: 'farm',
          disabled: disableFields.farm || false,
          type: 'autocomplete',
          value:
            farms.length === 1
              ? {label: farms[0].name, value: farms[0].id}
              : null || dataForm.farm || farms.find((item) => (previous && previous.farm ? item.id === previous.farm.value : '')),
          currentValue:
            farms.length === 1
              ? {label: farms[0].name, value: farms[0].id}
              : null || dataForm.farm || farms.find((item) => (previous && previous.farm ? item.id === previous.farm.value : '')),
          hasPlaceholder: true,
          placeholder: 'Selecione a fazenda',
          required: true,
          validations: {
            farm: Yup.string(),
          },
          onChange: (data) => {
            if (data && data !== '') {
              setDataForm((prev) => Object.assign(prev, {farm: data}));
              setDisableFields({
                customer: false,
                farm: false,
                painel_consultas: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {farm: null}));
              setDisableFields({
                customer: false,
                farm: false,
                painel_consultas: false,
              });
            }
          },
          options: normalizeCollectionData(farms, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
      ],
    },
    // {
    //   name: '',
    //   key: '2',
    //   fields: [
    //     {
    //       label: 'Painel de consultas',
    //       name: 'painel_consultas',
    //       type: 'autocomplete',
    //       disabled: disableFields.painel_consultas || false,
    //       hasPlaceholder: true,
    //       placeholder: 'Selecione o dashboard',
    //       value: previous.painel_consultas ? previous.painel_consultas : '',
    //       currentValue: previous.painel_consultas
    //         ? previous.painel_consultas
    //         : '',
    //       required: true,
    //       validations: {
    //         painel_consultas: Yup.string().required('Campo obrigatório'),
    //       },
    //       options: [
    //         {label: 'Estoque', value: 'stocks'},
    //         {label: 'Clima', value: 'climates'},
    //         {label: 'Máquinas', value: 'machines'},
    //         {label: 'Equipe', value: 'teams'},
    //         {label: 'Financeiro', value: 'financials'},
    //         {label: 'Pecuária', value: 'livestocks'},
    //         {label: '1º trimestre', value: 'FIRST_TRI'},
    //         {label: '2º trimestre', value: 'SECOND_TRI'},
    //         {label: '3º trimestre', value: 'THIRD_TRI'},
    //         {label: '4º trimestre', value: 'FOURTH_TRI'},
    //         {label: '1º semestre', value: 'FIRST_SEM'},
    //         {label: '2º semestre', value: 'SECOND_SEM'},
    //         {label: 'Fechamento de Safra', value: 'COMPLETE_CYCLE'},
    //       ],
    //       onChange: (data) => {
    //         if (data && data !== '') {
    //           setDataForm((prev) =>
    //             Object.assign(prev, {painel_consultas: data}),
    //           );
    //         }
    //         setDisableFields({
    //           customer: false,
    //           farm: false,
    //           painel_consultas: false,
    //         });
    //       },
    //       columns: {
    //         xs: 12,
    //       },
    //     },
    //   ],
    // },
  ];

  return (
    <Card className={`filter-dashboard pl-2 py-4 pr-4 ${slideOut ? 'slideOut' : ''}`} ref={ref}>
      <div className="col-12 filter-content">
        <GenerateForm onSubmit={onSubmit} groupFields={groupFields} />
      </div>
    </Card>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

export default FilterDashboards;
