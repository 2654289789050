import styled from 'styled-components';

export const InitiaFormStyled = styled.div`
  background: #fff;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding: 15px;
  position: relative;

  textarea#message.form-control.form-textarea {
    max-height: 235px;
    min-height: 235px;
  }
  .MuiFormGroup-root[role='radio'] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    * {
      font-size: 12px;
    }
  }

  .group-field .MuiCircularProgress-root {
    top: 26px;
  }
  .footer-buttons {
    display: flex;
    justify-content: space-between;
    position: relative;
    button[type='submit'],
    button[type='button'] {
      align-items: center;
      background-color: #007bff;
      margin-top: 120px;
      border: none;
      color: #fff;
      display: flex;
      transition: filter 0.2s;
      &:hover {
        filter: brightness(0.8);
        color: #fff;
      }
    }
  }
  .file-input-contain {
    position: absolute;
    bottom: 100px;
  }
  .form-group {
    align-items: center;
    display: flex;
    margin-bottom: 0rem;
    margin-top: 1rem;
    .MuiFormControl-marginNormal {
      border-radius: 4px;
      margin-bottom: 0;
    }

    .float-label {
      background-color: #fff;
      color: #007bff;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      left: 25px;
      margin-bottom: 0rem;
      margin-right: 15px;
      padding: 0 5px;
      position: absolute;
      top: 7px;
      white-space: nowrap;
      width: auto;
      z-index: 100;
    }
    .field-style {
      width: 100%;
    }
    .data-styled {
      .MuiFormControl-marginNormal {
        margin: 0;
        .MuiOutlinedInput-input {
          padding: 0.7rem 0.75rem;
        }
      }
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
    }
  }
`;
