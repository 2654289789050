import api from '../api';

// eslint-disable-next-line import/prefer-default-export
export const fetchChecklistSyntheticData = async (params = {}) => {
  return api.get('/checklist/synthetic', {params});
};

export const fetchChecklistAnalyticalData = async (
  params = {},
  responseType = {arraybuffer: 'arraybuffer'},
) => {
  return api.get('/checklist/analytical', {params, ...responseType});
};
