import React, {useContext, useRef, useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import * as Yup from 'yup';
import {DataContext} from '../../utils/DataProvider';
import {history, normalizeCollectionData, usePrevious} from '../../utils/utils';
import {FILTER, ROLES} from '../../utils/constants';
import {useInitialRenderEffect} from '../../utils/functions';
import {fetchCustomersForCombo, fetchCustomers} from '../../services/customers';
import {fetchHarvestsFilter} from '../../services/harvests';
import {fetchFarmsDetailsbyCustomerIdAndByHarvestId} from '../../services/farmsDetails';
import GenerateForm from '../../components/GenerateForm';
import Alert from '../../components/Notification';
import {CHECKLIST_TYPE} from './constants';
import {SyntheticContext} from './Synthetic/contexts';
// eslint-disable-next-line import/no-cycle
import {AnalyticalContext} from './Analytical';

const Form = ({type}) => {
  const ref = useRef(null);
  const referrer = window.location.pathname;
  const [slideOut, setSlideOut] = useState(false);
  const {state, dispatch} = useContext(DataContext);
  const {submit, isSubmitted, showForm} = useContext(type === 'ANALYTICAL' ? AnalyticalContext : SyntheticContext);
  const [customers, setCustomers] = useState([]);
  const [harvests, setHarvests] = useState([]);
  const [farms, setFarms] = useState([]);
  const [hasFarms, setHasFarms] = useState(true);

  const [dataForm, setDataForm] = useState({});
  const prevDataForm = usePrevious(dataForm);
  const [disableFields, setDisableFields] = useState({});

  const loadCustomers = async () => {
    try {
      if (state.user.user_type === ROLES.CUSTOMER) {
        const {data} = await fetchCustomers({
          'wherein[user.id]': state.user.id,
          'order[name]': 'ASC',
        });
        setCustomers(data.resultList || []);
      } else {
        const {data} = await fetchCustomersForCombo(null, {
          'order[name]': 'ASC',
        });
        setCustomers(data.resultList || []);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const loadHarvests = async (params) => {
    try {
      const {data} = await fetchHarvestsFilter(params);
      setHarvests(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadFarms = async (customerId, harvestId, params = {}) => {
    try {
      const {data} = await fetchFarmsDetailsbyCustomerIdAndByHarvestId(customerId, harvestId, params);
      const farmsDetail = data.resultList.filter((farmDetail) => farmDetail.name || farmDetail.farm.name).map((farmDetail) => farmDetail.farm);
      setFarms(farmsDetail);
      if (farmsDetail.length) {
        setHasFarms(true);
      } else {
        setHasFarms(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkDisableFields = () => {
    setDisableFields({
      ...disableFields,
      ...{
        customer: false,
        harvest: !harvests || !harvests.length || !dataForm.customer,
        farm: !farms || !farms.length || !dataForm.harvest,
      },
    });
  };

  useInitialRenderEffect(() => {
    let finalObject = {};
    if (state.customer && state.customer.id) {
      finalObject = {
        customer: {label: state.customer.name, value: state.customer.id},
      };

      if (state.harvest && state.harvest.id) {
        finalObject = {
          ...finalObject,
          ...{harvest: {label: state.harvest.title, value: state.harvest.id}},
        };

        if (state.farm && state.farm.id) {
          finalObject = {
            ...finalObject,
            ...{farm: {label: state.farm.name, value: state.farm.id}},
          };
        }
      }
    }

    setDataForm(finalObject);
    loadCustomers();
  });

  useEffect(() => {
    if (dataForm.customer) {
      if (
        (!Object.keys(prevDataForm).length && !prevDataForm.customer) ||
        (!!prevDataForm && !!prevDataForm.customer && dataForm.customer.value !== prevDataForm.customer.value)
      ) {
        loadHarvests({
          customer_id: dataForm.customer.value,
        });
      }
    } else {
      setHarvests([]);
    }
    if (dataForm.harvest) {
      if (!prevDataForm.harvest || (!!prevDataForm && !!prevDataForm.harvest && dataForm.harvest.value !== prevDataForm.harvest.value)) {
        loadFarms(dataForm.customer.value, dataForm.harvest.value);
      }
    } else {
      setFarms([]);
    }
    checkDisableFields();
  }, [dataForm]);// eslint-disable-line

  useEffect(() => {
    let newDataForm = dataForm;
    if (customers && customers.length && customers.length === 1) {
      newDataForm = {
        ...newDataForm,
        ...{customer: {label: customers[0].name, value: customers[0].id}},
      };
    }
    setDataForm(newDataForm);
    checkDisableFields();
  }, [customers]);// eslint-disable-line
  useEffect(() => {
    let newDataForm = dataForm;
    if (harvests && harvests.length && harvests.length === 1) {
      newDataForm = {
        ...newDataForm,
        ...{harvest: {label: harvests[0].title, value: harvests[0].id}},
      };
    }
    setDataForm(newDataForm);
    checkDisableFields();
  }, [harvests]);// eslint-disable-line
  useEffect(() => {
    let newDataForm = dataForm;
    if (farms && farms.length && farms.length === 1) {
      newDataForm = {
        ...newDataForm,
        ...{farm: [{label: farms[0].name, value: farms[0].id}]},
      };
    }
    setDataForm(newDataForm);
    checkDisableFields();
  }, [farms]);// eslint-disable-line

  const groupFields = () => {
    let minDate = null;
    let maxDate = null;

    if (dataForm.harvest) {
      const years = dataForm.harvest.label.split('/');
      minDate = new Date(`${years[0]}-07-01T00:00:00-03:00`);
      maxDate = new Date(`${years[1]}-06-30T00:00:00-03:00`);
    }

    if (dataForm.harvest && dataForm.harvest.label && !dataForm.initialDate && !dataForm.endDate) {
      setDataForm({
        ...dataForm,
        ...{initialDate: minDate, endDate: maxDate},
      });
    }

    let fields = [];
    const defaultFields = [
      {
        label: 'Cliente',
        name: 'customer',
        type: 'autocomplete',
        hasPlaceholder: true,
        placeholder: 'Selecione o cliente',
        value: dataForm.customer,
        currentValue: dataForm.customer,
        required: true,
        disabled: disableFields.customer,
        validations: {
          customer: Yup.string().required('Campo obrigatório'),
        },
        options: normalizeCollectionData(customers, [
          {alias: 'label', key: 'name'},
          {alias: 'value', key: 'id'},
        ]),
        onChange: (data) => {
          if (data) {
            setDataForm({
              ...dataForm,
              ...{customer: data, harvest: '', farm: ''},
            });
            setDisableFields({
              customer: false,
              harvest: false,
              farm: !dataForm.harvest,
            });
          } else {
            setDataForm({
              ...dataForm,
              ...{customer: '', harvest: '', farm: ''},
            });
            setDisableFields({
              customer: false,
              harvest: true,
              farm: !dataForm.harvest,
            });
          }
        },
        columns: {
          xs: 12,
        },
      },
      {
        label: 'Safra',
        name: 'harvest',
        type: 'autocomplete',
        hasPlaceholder: true,
        placeholder: 'Selecione a safra',
        value: dataForm.harvest,
        currentValue: dataForm.harvest,
        required: true,
        disabled: disableFields.harvest,
        validations: {
          harvest: Yup.string().required('Campo obrigatório'),
        },
        onChange: (data) => {
          if (data) {
            setDataForm({...dataForm, ...{harvest: data}});
            setDisableFields({
              customer: false,
              harvest: false,
              farm: false,
            });
          } else {
            setDataForm({...dataForm, ...{harvest: ''}});
            setDisableFields({
              customer: false,
              harvest: false,
              farm: true,
            });
          }
        },
        options: normalizeCollectionData(harvests, [
          {alias: 'label', key: 'title'},
          {alias: 'value', key: 'id'},
        ]),
        columns: {
          xs: 12,
        },
      },
      {
        hideField: !hasFarms && dataForm.harvest,
        label: 'Fazenda',
        name: 'farm',
        type: 'autocompleteMultiple',
        hasPlaceholder: true,
        placeholder: 'Selecione a fazenda',
        value: dataForm.farm,
        currentValue: dataForm.farm,
        required: hasFarms,
        disabled: !hasFarms || !dataForm.customer || !dataForm.harvest,
        validations: {
          farm: hasFarms ? Yup.string().required('Campo obrigatório') : Yup.string(),
        },
        onChange: (data) => {
          if (data) {
            setDataForm({...dataForm, ...{farm: data}});
            setDisableFields({
              customer: false,
              harvest: false,
              farm: false,
            });
          } else {
            setDataForm({...dataForm, ...{farm: ''}});
            setDisableFields({
              customer: false,
              harvest: false,
              farm: false,
            });
          }
        },

        options: normalizeCollectionData(farms, [
          {alias: 'label', key: 'name'},
          {alias: 'value', key: 'id'},
        ]),
        columns: {
          xs: 12,
        },
      },
    ];

    if (type === CHECKLIST_TYPE.SYNTHETIC) {
      fields = [
        ...defaultFields,
        ...[
          {
            label: 'Data inicial',
            name: 'initialDate',
            type: 'datepicker',
            hasPlaceholder: true,
            placeholder: 'Data inicial',
            value: dataForm.initialDate,
            currentValue: dataForm.initialDate,
            required: true,
            disabled: disableFields.farm,
            format: 'MM/yyyy',
            view: ['month'],
            initialYear: minDate,
            finalYear: dataForm.finalDate || maxDate,
            validations: {
              initialDate: Yup.string().required('Campo obrigatório'),
            },
            onChange: (data) => {
              if (data) {
                setDataForm({...dataForm, ...{initialDate: data}});
              } else {
                setDataForm({...dataForm, ...{initialDate: ''}});
              }
            },
            columns: {
              xs: 6,
            },
          },
          {
            label: 'Data final',
            name: 'endDate',
            type: 'datepicker',
            hasPlaceholder: true,
            placeholder: 'Data final',
            value: dataForm.endDate,
            currentValue: dataForm.endDate,
            required: true,
            disabled: disableFields.farm,
            format: 'MM/yyyy',
            view: ['month'],
            initialYear: dataForm.initialDate || minDate,
            finalYear: maxDate,
            validations: {
              endDate: Yup.string().required('Campo obrigatório'),
            },
            onChange: (data) => {
              if (data) {
                setDataForm({...dataForm, ...{endDate: data}});
              } else {
                setDataForm({...dataForm, ...{endDate: ''}});
              }
            },
            columns: {
              xs: 6,
            },
          },
        ],
      ];
    } else {
      fields = defaultFields;
    }

    return [{name: '', fields}];
  };

  const onSubmit = async (values, actions) => {
    Object.keys(values).map((item) => {
      if (values[item] && values[item].value) {
        return Object.assign(values, {[item]: values[item].value});
      }
      return true;
    });

    try {
      actions.setSubmitting(true);

      await submit(dataForm);

      actions.setSubmitting(false);
      setSlideOut((prev) => !prev);
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    // Timeout used so that the page change happens after the animation
    setTimeout(() => {
      showForm(!isSubmitted);
    }, 800);
  }, [slideOut]);// eslint-disable-line

  return (
    <>
      {!hasFarms && dataForm.harvest && (
        <Alert key={`${dataForm.customer}`} type="warning" message="Nenhuma fazenda caracterizada para essa safra." time={4000} />
      )}
      <Card className={`row filter-container ${slideOut ? 'slideOut' : ''}`} style={{transform: 'translateX(0)', marginLeft: '265px'}} ref={ref}>
        <div className="col-4 offset-1 filter-content">
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields()}
            showBackButton={referrer && state.filterOpened && state.filterFilled}
            onBack={() => {
              dispatch({
                type: FILTER.ACTION.CLOSE,
              });
              if (referrer) {
                history.push(referrer);
              } else {
                history.push('/');
              }
            }}
          />
        </div>
      </Card>
    </>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

export default Form;
