/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {createProductionArea, fetchProductionArea} from '../../../../services/productionArea';
import fetchSupportMaterial from '../../../../services/supportMaterial';

const ProductionArea = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const {
    state,
    // stateFetchParams,
    stateFetchParamsCustom,
    stateMessage,
  } = useContext(DataContext);

  const columns = [
    {
      tooltip: `Área utilizada para a pecuária (ha). Se houver dúvidas, consultar a planilha de apoio`,
      tooltipPosition: 'right',
      key: 'total_area',
      valueKey: 'total_area',
      field: 'titotal_areatle',
      required: false,
      // width: '100%',
      name: 'Área de produção (ha)',
      textAlign: 'Center',
      editType: 'weightedit',
    },
    {
      tooltip: `Inserir número total de funcionários permanentes da fazenda.\n\nObservações:\n1. Número pode ser decimal,
                exemplo 2,5 colaboradores. Considerar tempo trabalhado na atividade da fazenda.\n
                2. Deve considerar os trabalhadores remotos, exemplo de secretárias (os) que trabalham no escritório da cidade,
                 mesmo que em outra empresa do mesmo proprietário.\n
                3. Caso o proprietário trabalhe na fazenda como função de gerente, deve somar algum percentual (pelo tempo investido)
                ao número total de funcionários.\n`,
      tooltipPosition: 'right',
      key: 'total_team',
      valueKey: 'total_team',
      field: 'total_team',
      required: false,
      // width: '100%',
      name: 'Total de funcionários',
      textAlign: 'center',
      editType: 'weightedit',
    },
    {
      tooltip: `Quantidade de capatazes/vaqueiros, campeiros/ajudantes de vaqueiro e salgadores de cocho. \n
                Colaboradores que lidam diretamente com o rebanho.\n
                Observações:\n
                1. Número pode ser decimal, exemplo = 1,5 pessoas (considerar tempo trabalhado na atividade)\n
                2. Número sempre deverá ser menor que "Total de Funcionários"\n`,
      tooltipPosition: 'right',
      key: 'total_work_front',
      valueKey: 'total_work_front',
      field: 'total_work_front',
      required: false,
      // width: '100%',
      name: 'Equipe pecuária de campo',
      textAlign: 'Center',
      editType: 'weightedit',
    },
  ];

  useEffect(() => {
    const loadStocks = async () => {
      const response = await fetchProductionArea({...stateFetchParamsCustom});

      setData([{...response.data, id: 1}]);
    };
    loadStocks();
  }, [update]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value, cel, key) => {
        try {
          delete value.id;
          const res = await createProductionArea({
            ...value,
            [key]: null,
            created_by: {id: state.user.id},
          });
          setUpdate(!update);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      <div className="totalProduction">
        {!!data && (
          <DataGrid
            onChange={async (dataGridInputs) => {
              delete dataGridInputs.id;
              try {
                setOpenDialog(false);
                if (parseFloat(dataGridInputs.total_team) < parseFloat(dataGridInputs.total_work_front)) {
                  setOpenDialog(true);
                  return null;
                }
                const res = await createProductionArea({
                  ...dataGridInputs,
                  total_area: parseFloat(dataGridInputs.total_area),
                  total_team: parseFloat(dataGridInputs.total_team),
                  total_work_front: parseFloat(dataGridInputs.total_work_front),
                  created_by: {id: state.user.id},
                });
                setUpdate(!update);
                return res;
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            disabledPaginate
            // width="100%"
            actions={actions}
          />
        )}
        <Dialog open={!!openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <i className="fas fa-engine-warning" />
            Atenção
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Total de funcionários deve ser maior que equipe pecuária de campo.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialog(false);
              }}
              color="primary"
              autoFocus>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={async () => {
              await fetchSupportMaterial('/support_material_diagnosis/area');
            }}>
            <i className="fas fa-download mr-3" />
            Apoio - Área de Produção
          </Button>
        </DialogActions>
      </div>
    </>
  );
};

export default ProductionArea;
