import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Paginate from './Paginate';
import './styles.css';
// import { Container } from './styles';

const ToolbarFooter = ({reset, pageSettings, setPage, print}) => {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (pageSettings) {
      setPage(pageSettings.pages[currentPage % pageSettings.total]);
    }
  }, [currentPage]);// eslint-disable-line

  useEffect(() => {
    setCurrentPage(0);
  }, [reset]);

  return (
    <Toolbar className="toolbar-dashboard">
      <Paginate
        activePage={currentPage}
        itemsCountPerPage={1}
        totalItemsCount={pageSettings ? pageSettings.total : 1}
        pageRangeDisplayed={1}
        itemClass="page-item"
        linkClass="page-link"
        onChange={(page) => {
          setCurrentPage(page.pageNumber - 1);
        }}
      />

      <button
        className="btn-print file-icon-button"
        onClick={() => print()}
        type="button">
        <i className="fas fa-file-pdf file-icon" />
        <span className="file-icon-tooltip">IMPRESSÃO</span>
      </button>
    </Toolbar>
  );
};

export default ToolbarFooter;

export const Toolbar = styled.div`
  background-color: #656565;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  opacity: 0.9;
  padding: 5px 15px;
  position: absolute;
  right: 0;
  .pagination {
    margin: 0;
  }
  .btn-print {
    background-color: transparent;
    transition: ease all 400ms;
    &:hover {
      color: #007bfe;
    }
  }

  .page-link {
    align-items: center;
    background-color: #656565;
    border: none;
    border-radius: 50% !important;
    color: #fff;
    display: flex;
    font-weight: bold;
    height: 30px;
    justify-content: center;
    margin: 5px;
    transition: ease all 400ms;
    width: 30px;
    &:hover {
      background-color: #fff;
      color: #656565;
    }
  }

  .page-item.active .page-link {
    background-color: #007bfe;
  }
  .page-item.disabled .page-link {
    background-color: transparent;
  }
`;
