import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../../../components/Section/Section';

const Abstract = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Resumo',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Resumo de Custo',
              //   url: `${match.url}/tipo/receitas`,
            },
            {
              title: 'Total receitas',
              //   url: `${match.url}/tipo/despesas`,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <div className="abstract">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Resumo'}} render={() => <Section templateData={templateData} />} />
    </div>
  );
};
export default Abstract;
