import styled from 'styled-components';

export const TooltipButton = styled.span`
  i {
    color: #000;
    cursor: pointer;
    display: block;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 10px;
    width: auto;
  }
`;

export const DataGridContainer = styled.div`
  background-color: #ffff;
  height: auto;
  overflow: auto;
  width: 100%;
  .editable-grid {
    position: relative;
  }
  .fixed-column {
    .cel {
      border-right: 1px solid gray;
    }

    margin-bottom: 0px;
  }

  .MuiInputBase-input {
    padding: 4px !important;
  }

  .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    width: 96%;
    margin: 0 auto;
  }

  .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    display: flex;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0px !important;
  }
  .MuiExpansionPanelSummary-content {
    font-size: 12px;
    font-weight: 600;
  }
`;
export const DataGridContainerAdicional = styled.div`
  height: auto;
  margin: 0 15px 15px;

  width: 100%;
`;
export const Column = styled.div`
  display: grid;
  height: 100%;
  position: relative;
  width: 100%;
  & .intenalValuesGrid {
    display: grid;
    font-size: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    list-style: none;
    margin: 0;
    padding: 3px 5px;
  }

  & .intenalValuesGrid li {
    padding: 3px 0px;
  }
`;
export const Row = styled.div`
  /* height: 37px; */
  position: relative;
  &.row-grid:nth-of-type(1) {
    border-top: 1px solid #ddd;
  }
  &.row-grid {
    border-right: 1px solid #ddd;
  }
  &:nth-of-type(2n) {
    background-color: #e6e6e6;
  }
  &:hover {
    .cel {
      background-color: #f5f5f5;
    }
  }
  &.active {
    .cel {
      background-color: #0d4ca333;
    }
    .active-cel {
      border: 1px;
      border-color: #000;
      border-style: dashed;
    }
  }
`;
export const ToolbarEdit = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  .btn-section {
    background-color: #fff;
    width: 100%;
  }
  .btn-section {
    background-color: #f8f8f8;
    padding: 0px 0 0px 0px;
    width: auto;
    .btn {
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 0px;
      color: #000;
      &:disabled {
        color: #8e8e8e;
      }
      &:hover {
        background: #e6e6e6;
        border: 1px solid #adadad;
        border-color: #adadad;
        border-radius: 0px;
        color: #333;
      }
      &:focus {
        box-shadow: none;
      }
      font-size: 13px;
      font-weight: 100;
      padding: 15px 8px;
      i {
        margin-right: 5px;
      }
    }
  }
`;
export const HeaderCol = styled.div``;
export const Cel = styled.div`
  /* &:hover .tooltip-content, */
  /* &:hover .tooltipButton:after {
    opacity: 1;
    visibility: visible;
  } */
  align-items: center;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-right: 0;
  border-top: 0;
  display: flex;
  display: flex;
  font-size: 14px;
  font-weight: 100;
  justify-content: center;
  min-height: 35px;
  /* min-width: 140px; */
  overflow: hidden;
  padding: 6px 3px;
  text-align: center;

  &.bold {
    font-weight: bold;
  }
  &.numericedit {
    justify-content: flex-end;
    input {
      text-align: right;
    }
  }
  &.weightedit {
    justify-content: flex-end;
    input {
      text-align: right;
    }
  }
  &.moneyedit {
    justify-content: flex-end;
    input {
      text-align: right;
    }
  }
  .MuiInputBase-input {
    border: 1px solid rgb(151, 151, 151);
    border-radius: 4px;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }
`;
export const AccumulatorRow = styled.div`
  background-color: #eee;
  border: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
`;
export const AccumulatorCel = styled.div`
  align-items: center;
  color: #0c4ca3;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  padding: 6px 3px;
  text-align: center;
  &.moneyedit {
    justify-content: flex-end;
  }
`;
export const CelInput = styled.div`
  align-items: center;
  align-items: center;
  display: flex;
  font-size: 11px;
  height: 36px;
  overflow: hidden;
  padding: 6px 3px;
  input {
    width: 100%;
  }
`;
export const HeaderGrid = styled.div`
  align-items: center;
  background-color: #fff;
  /* border-bottom: 2px solid #dddddd; */
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  color: #000;
  display: flex;
  /* min-width: 140px; */
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: normal;
  font-weight: 600;
  padding: 3px;
  padding-bottom: 5px;
  padding-top: 5px;
  position: relative;
  text-align: left;
  & {
    .MuiIconButton-root {
      bottom: 5px;
      color: #333;
      padding: 0;
      position: absolute;
      right: 2px;
      svg {
        font-size: 1.1rem;
      }
    }
  }
`;
export const PaginationContent = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  display: flex;
  padding: 10px;
  width: 100%;
  .pagination {
    margin: 0;
    z-index: 1;
  }
  .selecPages {
    margin-left: 30px;
    div#selecPages {
      padding: 10px 43px;
    }
  }
  small {
    font-size: 12px;
    font-weight: 100;
    margin-left: 5px;
  }
`;
export const ButtonSection = styled.div`
  align-items: center;
  border: 1px solid #ddd;
  border-left: none;
  border-radius: 5px;
  border-right: none;
  display: flex;
  font-size: 12px;
  font-weight: 100;
  width: auto;
  button {
    background-color: #fff;
    border-radius: 5px;
    color: #000;
    font-weight: 100;
    padding: 10px 10px;
    transition: ease all 200ms;
    &:hover {
      background-color: #f1f1f1;
    }
    &.page {
      background-color: #317ab9;
      color: #fff;
      padding: 10px 13px;
    }
    &.next {
      border-right: 1px solid #ddd;
    }
    &.prev {
      border-left: 1px solid #ddd;
    }
  }
`;

export const ColContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

export const ValidationCheck = styled.input`
  cursor: pointer;
  left: 5px;
  position: relative;
  top: 2px;
`;
export const ValidationCheckFilter = styled.input`
  cursor: pointer;
  height: 20px;
  position: relative;
  right: 5px;
  top: 2px;
  width: 20px;
`;

export const Group = styled.div`
  .MuiExpansionPanelDetails-root {
    display: block;
    padding: 8px 0 16px;
  }
`;
export const GroupInfo = styled.div``;
