import api from '../api';

const fetchReproductionDiagnosis = (params = {}) => {
  return api.get(`/reproduction_diagnosis`, {params: {...params}});
};
const createReproductionDiagnosis = (body = {}) => {
  return api.post(`/reproduction_diagnosis`, body);
};

export {createReproductionDiagnosis, fetchReproductionDiagnosis};
