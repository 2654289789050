import React, {useRef, useState, useContext, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import './styles.scss';
import {useHistory} from 'react-router-dom';
import {DataContext} from '../../../utils/DataProvider';
import {FILTER} from '../../../utils/constants';
import GenerateForm from '../../GenerateForm';
import backgroundColorized from '../../../assets/images/image027.png';

const FilterEnvironment = () => {
  const ref = useRef(null);
  const [slideOut, setSlideOut] = useState(false);
  const {dispatch} = useContext(DataContext);
  const history = useHistory();

  useEffect(() => {
    history.push('/');
  }, []);// eslint-disable-line

  const groupFields = [
    {
      name: 'Selecione um ambiente',
      fields: [
        {
          label: '',
          type: 'radioEnvironment',
          hasPlaceholder: true,
          currentValue: 'beef',
          placeholder: ' ',
          required: true,
          disabled: false,
          hideField: false,
          name: 'environment',
          key: 'enviroment',
          options: [
            {value: 'beef', label: 'Corte'},
            {value: 'dairy', label: 'Leite'},
          ],
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const onSubmit = async (values, actions) => {
    const {environment} = values;
    dispatch({
      type: FILTER.ACTION.SAVE_DATA,
      payload: {environment},
    });
    setSlideOut((prev) => !prev);
    actions.setSubmitting(false);
  };

  return (
    <BackgroundFilter>
      <Card className={`row filter-container ${slideOut ? 'slideOut' : ''}`} style={{transform: 'translateX(0)'}} ref={ref}>
        <div className="col-4 offset-4 filter-content">
          <GenerateForm onSubmit={onSubmit} groupFields={groupFields} />
        </div>
      </Card>
    </BackgroundFilter>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

const BackgroundFilter = styled.div`
  background-image: url(${backgroundColorized});
  background-repeat: round;
  background-size: cover;
  overflow: hidden;
  width: 100%;
`;

export default FilterEnvironment;
