import React, {useContext, useEffect, useState} from 'react';
import VerticalGrid from '../../../../components/VerticalGrid/verticalGrid';
import Header from '../../../DairyPages/Releases/FarmDetail/_partial/header';
import GenerateForm from '../../../../components/GenerateForm';
import {DataContext} from '../../../../utils/DataProvider';
import {changeIgpdibyId, fetchIgpdi, registerIgpdi} from '../../../../services/igpdi';
import {fetchHarvests} from '../../../../services/harvests';

import './style.scss';

const CategoryIGPDI = () => {
  const {state} = useContext(DataContext);
  const [valuesToApi, setValuesToApi] = useState(null);
  const [dataHarvest, setDataHarvest] = useState([]);

  const DropdownHeader = ({children}) => {
    return <div className="custom-dropdown-header">{children}</div>;
  };

  const months = ['Janeiro', 'Feveiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  const monthValues = (year) => [
    {
      month: `01/01/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/02/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/03/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/04/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/05/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/06/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/07/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/08/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/09/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/10/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/11/${year} 00:00:00`,
      value: '',
    },
    {
      month: `01/12/${year} 00:00:00`,
      value: '',
    },
  ];

  const farmForm = [
    {
      // name: 'Safra',
      fields: [
        {
          label: 'Selecione um ano',
          name: 'year',
          type: 'autocomplete2',
          options: dataHarvest,
          columns: {
            xs: 6,
          },
        },
      ],
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const {data} = await fetchHarvests();
        const arr = [];
        data.resultList.forEach((el) => {
          arr.push(el.first_year);
          arr.push(el.last_year);
        });
        const arrUnique = [...new Set(arr)];
        setDataHarvest(
          arrUnique
            .sort((a, b) => {
              return b - a;
            })
            .map((item) => ({label: String(item), value: String(item)})),
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const onSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const {data} = await fetchIgpdi({
        year: values.year,
      });
      setValuesToApi(
        monthValues(values.year)?.map((item) => ({
          ...item,
          value: data?.resultList?.find((i) => i?.date === item?.month)?.index || '',
          id: data?.resultList?.find((i) => i?.date === item?.month)?.id || null,
        })),
      );
    } catch (error) {
      console.error(error);
    }
    actions.setSubmitting(false);
  };

  return (
    <div className="dairy-farm-grid">
      <div className="dairy-farm-form mb-5">
        <GenerateForm
          onSubmit={onSubmit}
          groupFields={farmForm}
          settings={{
            button: {
              text: 'Aplicar',
              textSubmitting: 'Aplicando...',
            },
          }}
        />
      </div>
      {valuesToApi && (
        <VerticalGrid
          key="ulaula"
          onSubmit={async (values) => {
            try {
              const requests = [];
              Object.keys(values).forEach((item) => {
                if (valuesToApi.find((i) => i.month === item)?.id) {
                  requests.push(
                    changeIgpdibyId(valuesToApi.find((i) => i.month === item)?.id, {
                      // index: values[item],
                      index: values[item].replace(',', '.') || values[item],
                      updated_by: state.user.id,
                      updated_at: new Date(),
                    }),
                  );
                } else if (values[item]) {
                  requests.push(
                    registerIgpdi({
                      date: item,
                      // index: values[item],
                      index: values[item].replace(',', '.') || values[item],
                      created_at: new Date(),
                      created_by: state.user.id,
                    }),
                  );
                }
              });

              Promise.all(requests);
            } catch (error) {
              console.error(error);
            }
          }}
          data={[
            {
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'header',
                  title: '',
                  component: (
                    <Header
                      saveContent={{
                        show: true,
                        enable: false,
                      }}
                    />
                  ),
                },
              ],
            },
            {
              component: (
                <DropdownHeader dropkey="general">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h5 className="w-50 text-left m-0">Mês</h5>
                    <h5 className="w-50 text-left m-0 ml-3">Índice</h5>
                  </div>
                </DropdownHeader>
              ),
              dropdownState: true,
              style: {
                width: '100%',
              },
              items: [
                ...valuesToApi.map((item, index) => ({
                  name: item.month,
                  title: months[index],
                  component: 'Number',
                  editType: 'InputMasked',
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale: '3',
                  value: item?.value || null,
                })),
              ],
            },
          ]}
        />
      )}
    </div>
  );
};

export default CategoryIGPDI;
