/* eslint-disable camelcase */
export const fixedColumn = {
  header: [
    {
      key: 'cost_center',
      valueKey: 'cost_center',
      height: 'auto',
      field: 'cost_center',
      name: 'Descrição',
      width: '100px',
      textAlign: 'Center',
      editType: 'defaultedit',
      disabledEdit: true,
      disableFilter: true,
      tooltipsCel: [
        {
          value: `Telefone, internet, energia elétrica administração e sede, condomínio, materiais de escritório, contabilidade, jurídico, consultoria`,
        },
        {
          value: `ITR, CNA, taxas de sindicato, impostos sobre a venda,  impostos sobre o lucro e outros`,
        },
        {
          value: `1. Materiais e serviços utilizados na construção e reformas na fazenda. Casas, cercas, currais, barracões, rede elétrica, rede hidráulica e outros;
            \n2. Todo o desembolso com materiais e serviços nas manutenções de casas, currais, barracões, rede elétrica, rede hidráulica e outros`,
        },
        {
          value: `Salários, encargos, prêmios, benefícios, recisões, gastos com cantina, refeitório, consumo da fazenda e outros ligados a equipe permanente da fazenda`,
        },
        {
          // eslint-disable-next-line max-len
          value: `Desembolso com tropa e outros animais, materiais de identificação, doações, jardinagem, Associação de criadoes, etc.Itens não inclusos anteriormente. Não incluir aquisição de bovinos.`,
        },
        {
          value: `1. Suplementação mineral, proteica, energética, grãos, núcleos, ureia e outros utilizados na alimentação dos animais.\n
                    2. Sêmen, protocolos de IATF, material de IA, hormônios, serviços técnicos terceirizados de reprodução.\n
                    3. Vacinas, vermífugos, antibióticos, anti-inflamatórios e outros medicamentos.`,
        },
        {
          value: `1. Valor total ou da parcela de aquisição de veiculos, tratores, caminhões, implementos e todo tipo de ferramentas. Novos e usados.\n
            2. Todas as despesas relacionadas ao parque de máquinas. Combustíveis, Lubrificantes, manutenções, seguros impostos e outros.`,
        },
        {
          value: `1. Todas ocasiões de formação e reforma de pastagens;\n
            2. Produção de fenos, silagens, pastagens anuais como milheto, aveia e áreas de integração. Formação e manutenção de cana e outras forrageiras;\n
            3. Adubações, limpezas mecânicas e químicas de pastagens. Pagamentos de arrendamentos.`,
        },
      ],
    },
  ],
  data: [],
  accumulator: [{title: 'Total'}],
};
export const fixedColumnTotal = {
  header: [
    {
      key: 'cost_center',
      valueKey: 'cost_center',
      height: 'auto',
      field: 'cost_center',
      name: 'Descrição',
      width: '100px',
      textAlign: 'Center',
      editType: 'defaultedit',
      disabledEdit: true,
      disableFilter: true,
    },
  ],
  data: [],
};

export const supportTable = (liveStockSystem) => [
  {
    key: 'initial_herd',
    valueKey: 'initial_herd',
    field: 'initial_herd',
    textAlign: 'Center',
    validationHeader: null,
    // returnFormat: valueFormatter,
    name: '',
    editType: 'numericedit',
    disableFilter: true,
    subFields: [
      {
        // eslint-disable-next-line max-len
        tooltip: `Baseado nos lançamentos anuais de despesas (células C54 a C62),\n aqui estão apresentados os valores em R$/cab/mês da fazenda.\n\n Números devem ser comparados às medias referências ao lado, e,\ncaso estejam muito diferentes devem ser conferidos/corrigidos.`,
        tooltipPosition: 'right',
        name: `R$/cab/mês`,
        key: 'calculed',
        valueKey: 'calculed',
        height: 'auto',
        field: 'calculed',
        width: '100px',
        textAlign: 'Center',
        editType: 'moneyedit',
        disabledEdit: true,
        disableFilter: true,
      },
    ],
  },

  {
    // eslint-disable-next-line max-len
    tooltip: `Referência das despesas, em R$/cab/mês,\n para cada sistema de produção.\n\nPara efeito de check, os valores devem variar em no \nmáximo 20% dessas referências. \nExemplo:\n Mão de obra permanente - Valor deve estar entre R$9,00 e R$13,00.\n`,
    key: 'initial_herd',
    valueKey: 'initial_herd',
    field: 'initial_herd',
    name: `Referência pela média (R$/cab/mês)`,
    textAlign: 'Center',
    validationHeader: null,
    // returnFormat: valueFormatter,
    editType: 'numericedit',
    disableFilter: true,
    subFields: [
      {
        key: liveStockSystem?.toLowerCase(),
        valueKey: liveStockSystem?.toLowerCase(),
        height: 'auto',
        field: liveStockSystem?.toLowerCase(),
        name: liveStockSystem,
        // width: '100px',
        textAlign: 'Center',
        editType: 'moneyedit',
        disabledEdit: true,
        disableFilter: true,
      },
      //   {
      //     key: 'recria',
      //     valueKey: 'recria',
      //     height: 'auto',
      //     field: 'recria',
      //     name: 'Ciclo Completo',
      //     // width: '100px',
      //     textAlign: 'Center',
      //     editType: 'moneyedit',
      //     disabledEdit: true,
      //     disableFilter: true,
      //   },
      //   {
      //     key: 'engorda',
      //     valueKey: 'engorda',
      //     height: 'auto',
      //     field: 'engorda',
      //     name: 'Recria-Engorda',
      //     // width: '100px',
      //     textAlign: 'Center',
      //     editType: 'moneyedit',
      //     disabledEdit: true,
      //     disableFilter: true,
      //   },
    ],
  },
];

export const columns = () => {
  return [
    {
      tooltip: `Inserir o valor anual gasto por cada despesa.`,
      key: 'value',
      field: 'value',
      required: (e) => {
        if (e?.data?.cost_center?.id === 305) {
          return false;
        }
        return true;
      },
      name: 'Valor Anual',
      disableFilter: true,
      textAlign: 'Center',
      editType: 'moneyedit',
      disabledEdit: (dataLine) => {
        if (
          (dataLine?.data?.cost_center?.id === 305 && dataLine?.data?.data?.find((item) => item?.value && item.cost_center?.id !== 305)) ||
          (dataLine?.data?.cost_center?.id !== 305 && dataLine?.data?.data?.find((item) => item?.value && item?.cost_center?.id === 305))
        ) {
          return true;
        }

        return false;
      },
      style: (dataLine) => {
        if (
          (dataLine?.cost_center?.id === 305 && dataLine.data.find((item) => item.value && item.cost_center.id !== 305)) ||
          (dataLine.cost_center.id !== 305 && dataLine.data.find((item) => item.value && item.cost_center.id === 305))
        ) {
          return {
            backgroundColor: 'rgb(177,178,177)',
          };
        }
        if (dataLine?.cost_center?.id !== 305 && dataLine?.data?.find((item) => item.value && item.cost_center !== 305) && !dataLine?.value) {
          return {
            backgroundColor: 'rgb(255,99,107)',
          };
        }
        return {
          backgroundColor: 'transparent',
        };
      },
    },
  ];
};
export const defaultData = [
  // insumos de rebanho
  {
    cria: 24,
    'ciclo-completo': 45.8,
    'recria-engorda': 81.9,
    'cria-recria-engorda': 45.8,
    'cre, com compras': 45.8,
    recria: 81.9,
    engorda: 81.9,
    cost_center: {id: 302},
  },
  // mao de obra permanente
  {
    cria: 15.8,
    'ciclo-completo': 15.8,
    'recria-engorda': 17,
    'cria-recria-engorda': 15.8,
    'cre, com compras': 15.8,
    recria: 17,
    engorda: 17,
    cost_center: {id: 24},
  },
  // administração
  {
    cria: 7.6,
    'ciclo-completo': 6.8,
    'recria-engorda': 9.4,
    'cria-recria-engorda': 6.8,
    'cre, com compras': 6.8,
    recria: 9.4,
    engorda: 9.4,
    cost_center: {id: 1},
  },
  // parque de maquinas
  {
    cria: 12.8,
    'ciclo-completo': 13.4,
    'recria-engorda': 18.3,
    'cria-recria-engorda': 13.4,
    'cre, com compras': 13.4,
    recria: 18.3,
    engorda: 18.3,
    cost_center: {id: 303},
  },
  // pastagens
  {
    cria: 13.2,
    'ciclo-completo': 13,
    'recria-engorda': 18.6,
    'cria-recria-engorda': 18.6,
    'cre, com compras': 13,
    recria: 18.6,
    engorda: 18.6,
    cost_center: {id: 304},
  },
  // manutenção e investimentos na fazeda
  {
    cria: 13.8,
    'ciclo-completo': 11.5,
    'recria-engorda': 14,
    'cria-recria-engorda': 11.5,
    'cre, com compras': 11.5,
    recria: 14,
    engorda: 14,
    cost_center: {id: 21},
  },
  // taxa, impostos e contribuições
  {
    cria: 2.6,
    'ciclo-completo': 3.7,
    'recria-engorda': 4.5,
    'cria-recria-engorda': 3.7,
    'cre, com compras': 3.7,
    recria: 4.5,
    engorda: 4.5,
    cost_center: {id: 9},
  },
  // outros
  {
    cria: 1.2,
    'ciclo-completo': 0.8,
    'recria-engorda': 1.2,
    'cria-recria-engorda': 0.8,
    'cre, com compras': 0.8,
    recria: 1.2,
    engorda: 1.2,
    cost_center: {id: 25},
  },
];
