import api from '../api';

const fetchCategoryReference = (params = {}) => {
  return api.get(`/category_reference`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryReferencebyId = (id, params = {}) => {
  return api.get(`/category_reference/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeCategoryReferencebyId = (id, body = {}) => {
  return api.put(`/category_reference/${id}`, body);
};

const registerCategoryReference = (body = {}) => {
  return api.post(`/category_reference`, body);
};

const deleteCategoryReference = (id) => {
  return api.delete(`/category_reference/${id}`);
};

export {
  fetchCategoryReference,
  fetchCategoryReferencebyId,
  changeCategoryReferencebyId,
  registerCategoryReference,
  deleteCategoryReference,
};
