import api from '../api';

const fetchCategoryMaterialType = (params = {}) => {
  return api.get(`/category_material_type`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryMaterialTypebyId = (id, params = {}) => {
  return api.get(`/category_material_type/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryMaterialType = (body = {}) => {
  return api.post(`/category_material_type`, body);
};

const changeCategoryMaterialTypebyId = (id, body = {}) => {
  return api.put(`/category_material_type/${id}`, body);
};

const deleteCategoryMaterialType = (id) => {
  return api.delete(`/category_material_type/${id}`);
};

export {
  fetchCategoryMaterialType,
  fetchCategoryMaterialTypebyId,
  registerCategoryMaterialType,
  changeCategoryMaterialTypebyId,
  deleteCategoryMaterialType,
};
