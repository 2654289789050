import apiNew from '../apiNew';

const fetchCities = (params = {}) => {
  return apiNew.get(`/cities`, {
    params: {
      ...params,
    },
  });
};

const fetchCitybyId = (id, params = {}) => {
  return apiNew.get(`/cities/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCity = (body = {}) => {
  return apiNew.post(`/cities`, body);
};

const changeCitybyId = (id, body = {}) => {
  return apiNew.put(`/cities/${id}`, body);
};

const deleteCity = (id) => {
  return apiNew.delete(`/cities/${id}`);
};

export {fetchCities, fetchCitybyId, registerCity, changeCitybyId, deleteCity};
