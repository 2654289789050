import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Alert from '../Notification';

const NotificationPush = ({actions, items}) => {
  const [alertProps, setAlertProps] = useState(null);

  useEffect(() => {
    if (Object.values(actions?.errors).length) {
      setAlertProps(null);
      const itemsArray = [];

      items.map((el) => el && itemsArray.push(...el?.items));

      const keys = Object.keys(actions?.errors);
      const listFields = [];
      itemsArray.forEach((item) => {
        if (keys.includes(item?.name)) {
          listFields.push(item.title);
        }
        if (item?.columns) {
          // eslint-disable-next-line no-unused-expressions
          item?.columns?.forEach((el) => {
            if (el) {
              if (keys.includes(el?.name)) {
                listFields.push(el.title);
              }
            }
          });
        }
      });
      setAlertProps({
        type: 'error',
        message: `Necessário preencher todos os campos obrigatórios: ${listFields.join(', ')}`,
        time: 5000,
      });

      setTimeout(() => {
        setAlertProps(null);
      }, 5100);
    }
  }, [JSON.stringify(actions)]);// eslint-disable-line

  return <>{alertProps && <Alert type={alertProps.type} message={alertProps.message} time={alertProps.time} />}</>;
};

NotificationPush.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NotificationPush;
