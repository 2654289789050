import api from '../../../../../services/api';

export const CategoryMainBulkUsedService = {
  endpoint: '/farms_details/:id/save_list_category_main_bulk_used',
  field: 'category_main_bulk_used',
  save_list: [],

  save: async (id, {summer = [], winter = []}) => {
    CategoryMainBulkUsedService.save_list = [];
    try {
      const prepareData = CategoryMainBulkUsedService.prepare({summer, winter});

      return await CategoryMainBulkUsedService.put(id, prepareData);
    } catch (_) {
      throw new Error('Principal volumoso utilizado: Não foi possível salvar');
    }
  },

  defaultBodyParams: {
    summer: false,
    winter: false,
  },

  mapper: (data, currentValue) => {
    data.forEach((item) => {
      CategoryMainBulkUsedService.save_list.push({
        [CategoryMainBulkUsedService.field]: {
          id: item.id,
        },
        ...CategoryMainBulkUsedService.defaultBodyParams,
        ...currentValue,
      });
    });
  },

  prepare: ({summer, winter}) => {
    CategoryMainBulkUsedService.mapper(summer, {summer: true});
    CategoryMainBulkUsedService.mapper(winter, {winter: true});

    return CategoryMainBulkUsedService.save_list;
  },

  put: async (id, body) => {
    return api.put(CategoryMainBulkUsedService.endpoint.replace(':id', id), body);
  },
};
