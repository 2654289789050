export const options = {
  options: ['history', 'inline', 'list', 'textAlign', 'link'],
  history: true,
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
    bold: true,
    italic: true,
    underline: true,
  },
  list: {
    inDropdown: false,
  },
  textAlign: {
    inDropdown: false,
  },
  link: {
    inDropdown: false,
  },
};

export const editorLabels = {
  // Generic
  'generic.add': 'Adicionar',
  'generic.cancel': 'Cancelar',

  // BlockType
  'components.controls.blocktype.h1': 'Título 1',
  'components.controls.blocktype.h2': 'Título 2',
  'components.controls.blocktype.h3': 'Título 3',
  'components.controls.blocktype.h4': 'Título 4',
  'components.controls.blocktype.h5': 'Título 5',
  'components.controls.blocktype.h6': 'Título 6',
  'components.controls.blocktype.normal': 'Parágrafo',

  // Link
  'components.controls.link.linkTitle': 'Texto do link',
  'components.controls.link.linkTarget': 'URL',
  'components.controls.link.linkTargetOption': 'Abrir link em nova aba',
  'components.controls.link.link': 'Link',
  'components.controls.link.unlink': 'Unlink',
};
