import {fetchCustomersForCombo} from '../services/customers';

export const getEnvironmentsTechnical = async (id_technical) => {
  const {data} = await fetchCustomersForCombo(id_technical);
  let tempState = {};
  // eslint-disable-next-line no-unused-expressions
  data?.resultList?.forEach((item) =>
    item?.profiles?.forEach((el) => {
      if (el?.value === 'DAIRY_CATTLE') {
        tempState = {...tempState, DAIRY_CATTLE: true};
      }
      if (el?.value === 'BEEF_CATTLE') {
        tempState = {...tempState, BEEF_CATTLE: true};
      }
    }),
  );
  return tempState;
};
