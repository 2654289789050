import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import NumberFormat from 'react-number-format';
import {FormInput} from '../form';

const Number = ({name, title, type, placeholder, isRequired, tabIndex, disabled = false, actions, ...el}) => {
  // eslint-disable-next-line consistent-return
  const withValueLimit = (inputObj) => {
    const {value} = inputObj;
    if (value <= el.maxValue) return inputObj;
  };

  const options = {
    ...(el.maxValue && {isAllowed: withValueLimit}),
    ...(el.inputProps && {inputProps: el.inputProps}),
    ...(el.readonly && {readonly: el.readonly}),
    ...(el.prefix && {prefix: el.prefix}),
    ...(el.suffix && {suffix: el.suffix}),
    ...(el.thousandSeparator && {thousandSeparator: el.thousandSeparator}),
    ...(el.decimalSeparator && {decimalSeparator: el.decimalSeparator}),
    ...(el.decimalScale && {decimalScale: el.decimalScale}),
    ...(el.fixedDecimalScale && {fixedDecimalScale: el.fixedDecimalScale}),
    ...(el.fixedDecimalScale && {fixedDecimalScale: el.fixedDecimalScale}),
    ...(el.allowNegative && {allowNegative: el.allowNegative}),
    ...(el.allowLeadingZeros && {allowLeadingZeros: el.allowLeadingZeros}),
    ...(el.allowLeadingZeros && {allowLeadingZeros: el.allowLeadingZeros}),
    ...(el.format && {format: el.format}),
    ...(el.mask && {mask: el.mask}),
    ...(el.isAllowed && {isAllowed: el.isAllowed}),
    ...(el.allowEmptyFormatting && {
      allowEmptyFormatting: el.allowEmptyFormatting,
    }),
    ...(el.allowedDecimalSeparators && {
      allowedDecimalSeparators: el.allowedDecimalSeparators,
    }),
  };

  const handleChange = (val) => {
    if (actions.setFieldValue) {
      actions.setFieldValue(name, val.floatValue);
    }
  };

  return (
    <Field name={name}>{({field}) => <NumberFormat {...field} {...options} customInput={FormInput} onValueChange={handleChange} disabled={disabled} />}</Field>
  );
};

Number.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
};
export default Number;
