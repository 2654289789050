import React from 'react';
import uuid from 'react-uuid';
import {
  IndicatorContainer,
  IconContainer,
  DataContainer,
  DataTitle,
  DataSubTitle,
  Data,
} from './indicatorStyled';

const MultipleIndicator = ({
  data,
  color,
  backgroundColor,
  iconPosition,
  alignItems,
  iconSide,
  height,
  iconColor,
  iconBackgroundColor,
  icon,
  iconSize,
  title,
  subtitle,
}) => {
  const iconSizeClassMaker = (currentIconSize) =>
    currentIconSize ? `fa-${currentIconSize}x` : '';

  return (
    <IndicatorContainer
      iconSide={iconSide}
      color={color}
      height={height}
      alignItems={alignItems}
      backgroundColor={backgroundColor}>
      {!iconPosition && (
        <IconContainer color={iconColor} backgroundColor={iconBackgroundColor}>
          <i
            className={`icon fas fa-${icon} ${iconSizeClassMaker(iconSize)}`}
          />
        </IconContainer>
      )}
      {data && data.length > 0 && (
        <>
          {data.map((item, index) => (
            <DataContainer key={uuid()}>
              <DataTitle>{title[index] || ''}</DataTitle>
              {iconPosition && iconPosition === 'between-title' && (
                <IconContainer
                  color={iconColor}
                  backgroundColor={iconBackgroundColor}>
                  <i
                    className={`icon fas fa-${
                      icon[index] || ''
                    } ${iconSizeClassMaker(iconSize)}`}
                  />
                </IconContainer>
              )}
              <Data>{item || ''}</Data>
              <DataSubTitle>{subtitle[index] || ''}</DataSubTitle>
            </DataContainer>
          ))}
        </>
      )}
    </IndicatorContainer>
  );
};

export default MultipleIndicator;
