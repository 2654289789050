import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateDataFull} from '../../utils/utils';
import {
  fetchOtherSectors,
  registerOtherSectors,
  changeOtherSectorsbyId,
  deleteOtherSectors,
} from '../../services/otherSectors';
import {fetchCategorySector} from '../../services/categorySector';
import PrimaryFilterDisplay from '../../components/PrimaryFilterDisplay/PrimaryFilterDisplay';
import PrimaryFilterForm from '../../components/FilterForms/FilterSettings';
import {FILTER} from '../../utils/constants';

const OtherSectors = () => {
  const {state, dispatch, stateFetchParams, filterParams} = useContext(
    DataContext,
  );
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [lastHarvest, setLastHarvest] = useState(null);

  useEffect(() => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  }, []);// eslint-disable-line

  useEffect(() => {
    const loadData = async () => {
      if (!state.harvest || !state.customer || !state.farm) {
        return;
      }
      const response = await fetchOtherSectors({
        ...stateFetchParams,
        ...filterParams,
      });
      const {harvest} = state;
      setLastHarvest(`${harvest.first_year - 1}/${harvest.last_year - 1}`);
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, state, stateFetchParams]);// eslint-disable-line

  const columns = [
    {
      key: 'category_sector',
      valueKey: 'category_sector.title',
      field: 'category_sector',
      name: 'Setor',
      width: '40%',
      required: true,
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategorySector({
            'order[title]': 'ASC',
            perPage: 50,
            page: 0,
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'revenues',
      field: 'revenues',
      name: 'Faturamento(R$)',
      textAlign: 'Center',
      editType: 'moneyedit',
    },
    {
      key: 'disbursement',
      field: 'disbursement',
      name: 'Desembolso(R$)',
      textAlign: 'Center',
      editType: 'moneyedit',
    },
    {
      key: 'area',
      field: 'area',
      name: 'Área(ha)',
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'result_previous_area',
      field: 'result_previous_area',
      name: `Resultado Op. (R$/ha) safra ${lastHarvest}`,
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteOtherSectors(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {state.filterOpened && <PrimaryFilterForm />}
      {!state.filterOpened && data && (
        <>
          <PrimaryFilterDisplay />
          <DataGrid
            onChange={async (dataGridInputs) => {
              delete dataGridInputs.updated_at;
              delete dataGridInputs.updated_by;
              delete dataGridInputs.created_at;
              delete dataGridInputs.created_by;
              delete dataGridInputs.customer;
              delete dataGridInputs.farm;
              delete dataGridInputs.harvest;
              const response = await createOrUpdateDataFull(
                dataGridInputs,
                state,
                registerOtherSectors,
                changeOtherSectorsbyId,
              );
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
          />
        </>
      )}
    </>
  );
};

export default OtherSectors;
