import React, {useState} from 'react';
import moment from 'moment';
import Alert from '../../../components/Notification';
import Form from '../Form';
import {CHECKLIST_TYPE} from '../constants';
import {SyntheticContext} from './contexts';
import {fetchChecklistSyntheticData} from '../../../services/checklist';
import SyntheticDataPresenter from './SyntheticDataPresenter';

const Synthetic = () => {
  const [submitted, setSubmitted] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [showAlert, setShowAlert] = useState(null);

  const submit = async (data) => {
    const formattedData = Object.keys(data).reduce((acc, key) => {
      if (key === 'initialDate' || key === 'endDate') {
        return {...acc, ...{[key]: moment(data[key]).format('YYYY-MM')}};
      }
      if (key === 'farm') {
        let farm = '';
        if (data && data.farm && data.farm.length > 0) {
          data.farm.forEach((item, index) => {
            farm = `${farm}${item.value}${index < data.farm.length - 1 ? '|' : ''}`;
          });
        }
        return {...acc, ...{[`wherein[${key}.id]`]: farm}};
      }

      return {...acc, ...{[`wherein[${key}.id]`]: data[key].value}};
    }, {});

    try {
      const response = await fetchChecklistSyntheticData(formattedData);
      setTableData(response.data);
      setSubmitted(data);
    } catch (error) {
      console.error(error);
      setShowAlert({
        type: 'error',
        message: 'Erro Ao buscar Checklist',
        time: 4000,
      });
    }
  };

  return (
    <SyntheticContext.Provider
      value={{
        isSubmitted: !!submitted,
        showForm: setShowForm,
        submittedData: submitted,
        submit,
      }}>
      {showForm ? (
        <>
          {showAlert && <Alert key={`${showAlert.message}`} type={showAlert.type} message={showAlert.message} time={showAlert.time} />}
          <Form type={CHECKLIST_TYPE.SYNTHETIC} />
        </>
      ) : (
        <SyntheticDataPresenter data={tableData} />
      )}
    </SyntheticContext.Provider>
  );
};

export default Synthetic;
