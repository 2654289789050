import apiNew from '../apiNew';

const fetchCategoryIlp = (params = {}) => {
  return apiNew.get(`/category_ilp`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryIlpbyId = (id, params = {}) => {
  return apiNew.get(`/category_ilp/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryIlp = (body = {}) => {
  return apiNew.post(`/category_ilp`, body);
};

const changeCategoryIlpbyId = (id, body = {}) => {
  return apiNew.put(`/category_ilp/${id}`, body);
};

const deleteCategoryIlp = (id) => {
  return apiNew.delete(`/category_ilp/${id}`);
};

export {fetchCategoryIlp, fetchCategoryIlpbyId, registerCategoryIlp, changeCategoryIlpbyId, deleteCategoryIlp};
