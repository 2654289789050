import api from '../api';

const fetchLandsImprovements = (params = {}) => {
  return api.get(`/lands_improvements`, {
    params: {
      ...params,
    },
  });
};

const fetchLandsImprovementsbyId = (id, params = {}) => {
  return api.get(`/lands_improvements/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeLandsImprovementsbyId = (id, body = {}) => {
  return api.put(`/lands_improvements/${id}`, body);
};

const registerLandsImprovements = (body = {}) => {
  return api.post(`/lands_improvements`, body);
};

const deleteLandsImprovements = (id) => {
  return api.delete(`/lands_improvements/${id}`);
};

export {
  fetchLandsImprovements,
  fetchLandsImprovementsbyId,
  changeLandsImprovementsbyId,
  registerLandsImprovements,
  deleteLandsImprovements,
};
