import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import uuid from 'react-uuid';
import styled from 'styled-components';
import './styles.scss';

export const SubItem = styled.ul`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.18);
  display: block;
  left: -100%;
  opacity: 0;
  padding: 10px 0;
  position: absolute;
  top: 0px;
  transition: ease all 300ms;
  visibility: hidden;
  width: 100%;
  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0;
    width: 100%;
  }
`;
export const IconArrow = styled.i`
  position: absolute;
`;
export const Icon = styled.i`
  width: 15px;
`;

const HeaderMenuItem = React.forwardRef(
  ({title, icon, onClick = () => {}, options, cssClass}, ref) => {
    return (
      <MenuItem
        ref={ref}
        className={`menu-item-filter position-relative ${cssClass}`}
        key={uuid()}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}>
        <Icon className={`${icon} pr-3`} />
        {title}
        {options && (
          <>
            <IconArrow className="fas fa-chevron-left " />
            <SubItem className="subitem">
              {options.map((option) => (
                <div key={uuid()}>
                  <HeaderMenuItem
                    key={option.title}
                    title={option.title}
                    icon={option.icon}
                    onClick={option.onClick || null}
                    options={option.options || null}
                  />
                </div>
              ))}
            </SubItem>
          </>
        )}
      </MenuItem>
    );
  },
);

export default HeaderMenuItem;
