import api from '../api';

const fetchCategorySector = (params = {}) => {
  return api.get(`/category_sector`, {
    params: {
      ...params,
    },
  });
};

const fetchCategorySectorbyId = (id, params = {}) => {
  return api.get(`/category_sector/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeCategorySectorbyId = (id, body = {}) => {
  return api.put(`/category_sector/${id}`, body);
};

const registerCategorySector = (body = {}) => {
  return api.post(`/category_sector`, body);
};

const deleteCategorySector = (id) => {
  return api.delete(`/category_sector/${id}`);
};

export {
  fetchCategorySector,
  fetchCategorySectorbyId,
  changeCategorySectorbyId,
  registerCategorySector,
  deleteCategorySector,
};
