/* eslint-disable camelcase */
import React, {useContext, useState, useReducer, useEffect} from 'react';
import moment from 'moment';
import GenerateForm from '../../../components/GenerateForm';
import {DataContext} from '../../../utils/DataProvider';
import {InitiaFormStyled} from './styled';
import {fetchHarvestsFilter} from '../../../services/harvests';
import sendMail from './sendMail';
import Alert from '../../../components/Notification';
import {intialRegistration, fetchCustomersForCombo, fetchCustomers} from '../../../services/customers';
import {fetchFarms} from '../../../services/farms';
import {history} from '../../../utils/utils';
// import {registerUser} from '../../services/user';

const InitialForm = () => {
  function reducer(state, action) {
    switch (action.type) {
      case 'add':
        return {...state, ...action.value};
      case 'addFarm':
        return {...state, ...action.value};

      default:
        throw new Error();
    }
  }
  const {state} = useContext(DataContext);
  const [page, setPage] = useState(0);
  const [alertProps, setAlertProps] = useState(null);
  const [localValues, dispatch] = useReducer(reducer, {
    farms_quantity_diagnosis: state?.customer?.farms_limit || 1,
  });

  useEffect(() => {
    (async () => {
      const {data} = await fetchCustomers({
        'wherein[user.id]': state.user.id,
        'order[name]': 'ASC',
      });

      const harvest = await fetchHarvestsFilter({
        customer_id: data && data?.resultList.length ? data?.resultList[0]?.id : state?.customer?.id,
      });
      const farms = await fetchFarms({
        'wherein[customer.id]': data && data?.resultList.length ? data?.resultList[0]?.id : state?.customer?.id,
      });
      if (!harvest.data.size) {
        setAlertProps(null);
        setAlertProps({
          type: 'error',
          message: 'Nenhuma Fazenda encontrada para esse Cliente',
          time: 5000,
        });
      }
      if (!farms.data.size) {
        setAlertProps(null);
        setAlertProps({
          type: 'error',
          message: 'Nenhuma Safra encontrada para esse Cliente',
          time: 5000,
        });
      }

      dispatch({
        type: 'add',
        value: {
          harvest: {
            value: harvest.data?.resultList[0]?.id,
            label: harvest.data?.resultList[0]?.title,
          },
          farms_quantity_diagnosis: farms.data.size,
        },
      });

      dispatch({
        type: 'add',
        value: {
          farm_detail: farms.data.resultList.map((item) => {
            return {farm: {id: item.id}, alias: item.name};
          }),
        },
      });
    })();
  }, []);// eslint-disable-line

  const refactorData = async (values) => {
    const customer = await fetchCustomersForCombo();

    const getObjectWithId = (farm) => {
      let tempFarm = farm;
      Object.keys(farm).map((key) => {
        if (farm[key]?.value) {
          tempFarm = Object.assign(farm, {[key]: {id: farm[key]?.value}});
        }
        return null;
      });
      return tempFarm;
    };

    // eslint-disable-next-line camelcase
    const refactorFarms = Object.values(values?.farm_detail).map((farm) => {
      return {
        ...getObjectWithId(farm),
        total_area: parseFloat(farm?.total_area?.id),
        name: state?.user?.name,
        // category_ilp_percentage: parseFloat(farm?.ilp_percentage?.value) || 0,
        is_client_dsm: !!farm?.is_client_dsm?.value === true,
        land_value: parseFloat(farm?.land_value?.id) || 0,
        only_confinement: farm?.only_confinement?.id || false,
        harvest: {id: values?.harvest?.value},
        farms_quantity: 1,
        other_company: values?.other_company || '',
        processing_formatting: {id: values.processing_formatting.value},
        environment: 'BEEF_CATTLE',
      };
    });

    return {
      ...values,
      processing_formatting: {id: values.processing_formatting.value},
      farm_details: refactorFarms,
      created_by: {id: state.user.id},
      customer: {id: customer?.data?.resultList[0]?.id},
      owner_birth_date: moment(values.owner_birth_date).format('DD/MM/YYYY'),
      farms_quantity_diagnosis: parseFloat(values.farms_quantity_diagnosis),
      harvest: {id: values?.harvest?.value},
      technical_email: values.technical_email || '',
      technical_name: values.technical_name || '',
      technicalAux: values.technicalAux || null,
      controller_email: '',
      controller_name: '',
    };
  };

  const onSubmit = async () => {
    if (sendMail(state, localValues, dispatch).length - 1 === page) {
      const data = await refactorData(localValues);
      delete data.farm_detail;
      try {
        // if (data.technicalAux === 'autocomplete') {
        //   const technical = localValues.selectTechnical;
        //   technical.technicals_customers.push({
        //     customer: data.customer,
        //   });
        //   delete technical.value;
        //   delete technical.label;
        //   await registerUser(technical);
        // }
        delete data.selectTechnical;
        delete data.technicalAux;
        const res = await intialRegistration(data);
        history.push('/filter-form');
        return res;
      } catch (error) {
        console.error(error);
        return error;
      }
    } else {
      setPage(page < sendMail(state, localValues, dispatch).length - 1 ? page + 1 : page);
    }
    return true;
  };
  // console.log(localValues);
  return (
    <div className="entries-content send-mail">
      <div className="col-10 initial-form offset-1">
        <InitiaFormStyled>
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={sendMail(state, localValues, dispatch)[page]}
            settings={{
              button: {text: 'Enviar'},
            }}
            // showBackButton
            // onBack={() => setPage(page > 0 ? page - 1 : page)}
          />
          <div className="file-input-contain">
            <p htmlFor="file-input">Anexo</p>
            <input type="file" id="file-input" name="file-input" />
          </div>
        </InitiaFormStyled>
      </div>
      {alertProps && <Alert type={alertProps.type} message={alertProps.message} time={alertProps.time} />}
    </div>
  );
};
export default InitialForm;
