import api from '../api';

const fetchOtherSectors = (params = {}) => {
  return api.get(`/other_sectors`, {
    params: {
      ...params,
    },
  });
};

const fetchOtherSectorsbyId = (id, params = {}) => {
  return api.get(`/other_sectors/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeOtherSectorsbyId = (id, body = {}) => {
  return api.put(`/other_sectors/${id}`, body);
};

const registerOtherSectors = (body = {}) => {
  return api.post(`/other_sectors`, body);
};

const deleteOtherSectors = (id) => {
  return api.delete(`/other_sectors/${id}`);
};

export {
  fetchOtherSectors,
  fetchOtherSectorsbyId,
  changeOtherSectorsbyId,
  registerOtherSectors,
  deleteOtherSectors,
};
