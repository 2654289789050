import React, {useState, useContext, useEffect} from 'react';
import moment from 'moment';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateData} from '../../utils/utils';
import DataGrid from '../../components/DataGrid';
import {fetchHarvests, deleteHarvests, registerHarvests, changeHarvestsbyId} from '../../services/harvests';
import Breadcrumbs from '../../components/Breadcrumbs';

const Harvests = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [harvestsList, setHarvestsList] = useState();
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const groupFields = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      width: '40%',
      textAlign: 'Center',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'first_year',
      field: 'first_year',
      name: 'Ano inicial safra',
      width: '30%',
      textAlign: 'Center',
      editType: 'datepicker',
      dateView: ['year'],
      dateFormat: 'yyyy',
      disableFilter: true,
      defaultValue: new Date(),
      mapper: (object, editMode) => {
        if (editMode) {
          return new Date(object?.first_year, 1, 1);
        }
        return object?.first_year;
      },
      returnFormat: (value) => {
        return value.getFullYear();
      },
    },
    {
      disableFilter: true,
      key: 'last_year',
      field: 'last_year',
      name: 'Ano final safra',
      width: '30%',
      textAlign: 'Center',
      editType: 'datepicker',
      dateView: ['year'],
      dateFormat: 'yyyy',
      dependencies: ['first_year'],
      extraParams: {
        minDate: new Date(new Date().getFullYear() + 1, 1, 1),
        maxDate: new Date(new Date().getFullYear() + 2, 1, 1),
      },
      defaultValue: new Date(new Date().getFullYear() + 1, 1, 1),
      mapper: (object, editMode) => {
        if (editMode) {
          return new Date(object?.last_year, 1, 1);
        }
        return object?.last_year;
      },
      returnFormat: (value) => {
        return value.getFullYear();
      },
      optionsFetch: async (stateGrid, dataGridState) => {
        if (dataGridState?.data && dataGridState?.data?.first_year) {
          const date = moment(dataGridState?.data?.first_year).format('yyyy');
          return new Date(parseInt(date, 10) + 1, 1, 1);
        }
        return new Date();
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteHarvests(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  useEffect(() => {
    const getContents = async () => {
      try {
        const response = await fetchHarvests({
          ...paginationParams,
          ...filterParams,
        });
        setHarvestsList(response.data.resultList);
        setTotalItems(response.data.size);
      } catch (e) {
        console.error(e);
      }
    };

    getContents();
  }, [reset, paginationParams, filterParams]);

  return (
    <div>
      <>
        <Breadcrumbs path={[{title: 'Cadastros', url: '/cadastros'}, {title: 'Safra'}]} />
        {harvestsList && harvestsList.length > 0 && (
          <DataGrid
            onChange={async (dataGridInputs) => {
              const dateTemp = new Date();
              if (!dataGridInputs.first_year) {
                dataGridInputs.first_year = dateTemp.getFullYear();
              }
              if (!dataGridInputs.last_year) {
                dataGridInputs.last_year = dataGridInputs.first_year + 1;
              }
              Object.assign(dataGridInputs, {
                title: `${dataGridInputs.first_year}/${dataGridInputs.last_year}`,
              });
              const response = await createOrUpdateData(dataGridInputs, state, registerHarvests, changeHarvestsbyId, false);
              setReset(!reset);
              return response;
            }}
            header={groupFields}
            data={harvestsList}
            keyProp="key"
            titleProp="name"
            editable=""
            totalItems={totalItems}
            actions={actions}
          />
        )}
      </>
    </div>
  );
};

export default Harvests;
