import api from '../api';
import apiNew from '../apiNew';

const fetchConfinementDetails = (params = {}) => {
  return apiNew.get(`/confinement_details`, {
    params: {
      ...params,
    },
  });
};

const fetchConfinementDetailsDatagrid = (params = {}) => {
  return api.get(`/confinement_details/datagrid`, {
    params: {
      ...params,
    },
  });
};

const fetchConfinementDetailsbyId = (id, params = {}) => {
  return api.get(`/confinement_details/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeConfinementDetailsbyId = (id, body = {}) => {
  return api.put(`/confinement_details/${id}`, body);
};

const updateConfinementDetails = (data = [], id) => {
  return api.put(
    `/confinement_details/${id}/save_list_category_dsm_technology`,
    data.map((tech) => ({
      category_dsm_technology: {
        id: tech.id,
      },
    })),
  );
};

const registerConfinementDetails = async (body = {}) => {
  // eslint-disable-next-line camelcase
  const {id, list_category_dsm_technology, ...rest} = body;

  if (id) {
    const response = await api.put(`/confinement_details/${id}`, {id, ...rest});
    await updateConfinementDetails(list_category_dsm_technology, id);
    // eslint-disable-next-line no-console
    console.log(response.data);
    return response.data;
  }
  const response = await api.post(`/confinement_details`, rest);
  await updateConfinementDetails(list_category_dsm_technology, response.data.id);
  return response.data;
};

const deleteConfinementDetails = (id) => {
  return api.delete(`/confinement_details/${id}`);
};

export {
  fetchConfinementDetails,
  fetchConfinementDetailsDatagrid,
  fetchConfinementDetailsbyId,
  changeConfinementDetailsbyId,
  registerConfinementDetails,
  deleteConfinementDetails,
};
