import React, {useContext, useEffect, useState} from 'react';
import {Spinner} from 'reactstrap';
import {Link} from 'react-router-dom';
// import {Checkbox} from '@material-ui/core';
import {DataContext} from '../../../../../utils/DataProvider';
import {ExpensesAgricultureByType} from '../services/AgricultureServices';
import '../style.scss';

const BaseTableAgriculture = ({type}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const {state} = useContext(DataContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await ExpensesAgricultureByType.list(type, {
        harvest_id: state?.harvest?.id,
        farm_id: state?.farm?.id,
      });
      setData(response?.data);
      setLoading(false);
    })();
  }, []);// eslint-disable-line

  if (loading) {
    return (
      <div style={{minHeight: '200px'}} className="d-flex w-100 h-100 align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const formatNumber = (value) => {
    return Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
  };

  return (
    <div className="BaseTableAgriculture mx-2">
      <div className="header">
        <div className="header-item">Opção</div>
        <div className="header-item">Custo total(R$)</div>
        <div className="header-item">Peso(kg)</div>
        <div className="header-item">Custo Uni./kg</div>
        {/* <div className="header-item">Validar</div> */}
        <div className="header-item">Ver</div>
      </div>
      {data?.map((item) => (
        <div className="row-table" key={item?.cost_center_description?.title}>
          <div className="row-item">{item?.cost_center_description?.title}</div>
          <div className="row-item">{formatNumber(item?.total_cost)}</div>
          <div className="row-item">{formatNumber(item?.volume)}</div>
          <div className="row-item">{formatNumber(item?.cost_per_kilo)}</div>
          {/* <div className="row-item">
            <Checkbox
              // onKeyDown={handlePressEnter}
              color="primary"
              // checked={TRUE}
              onChange={(e, newValue) => {
                // setValue(newValue);
              }}
              // onBlurCapture={(e, newValue) => {
              //   if (requiredState && newValue) {
              //     addValidationError('Este campo é obrigatório');
              //   } else if (requiredState) {
              //     removeValidationError();
              //   }
              // }}
              inputProps={{'aria-label': 'primary checkbox'}}
            />
          </div> */}
          <div className="row-item">
            <Link to={`/leite/lancamentos/agricultura-cadastro-view/${item?.id}`}>
              <i className="far fa-eye" />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
export default BaseTableAgriculture;
