import React, {useContext} from 'react';
import moment from 'moment';
import EditableRow from './EditableRow';
import EditableHeader from './EditableHeader';
import RowContainer from './RowContainer';
import {getRowValue} from '../../utils/utils';
import DataGridContext from './DataGridContext';
import {DATAGRID} from '../../utils/constants';
import {DataContext} from '../../utils/DataProvider';

const EditableGrid = ({
  dataValues,
  keyProp,
  keyValueProp,
  groupBy,
  titleProp,
  headerTemplate,
  fieldTemplate,
  columnsTemplate,
  width,
  edit,
  setCel,
  gridListener = () => {},
  validateCallback,
  setSort,
  accumulators,
}) => {
  const {state} = useContext(DataGridContext);
  const context = useContext(DataContext);
  const textFields = fieldTemplate
    .filter((_header) => !_header.subFields)
    .filter((field) => field.editType !== 'ctabutton' && field.editType !== 'passwordedit');

  const generateDate = (intialYear, finalYear) => {
    const arr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 7; i <= 12; i++) {
      arr.push({
        value: {
          value: `${intialYear}-${i < 10 ? `0${i}` : i}`,
          label: `${i < 10 ? `0${i}` : i}/${intialYear}`,
        },
      });
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 6; i++) {
      arr.push({
        value: {
          value: `${finalYear}-${i < 10 ? `0${i}` : i}`,
          label: `${i < 10 ? `0${i}` : i}/${finalYear}`,
        },
      });
    }

    return arr;
  };

  const filterByColumn = (field) =>
    dataValues?.reduce((acc, line) => {
      if (field.editType === 'datepicker') {
        return generateDate(context?.state?.harvest?.first_year, context?.state?.harvest?.last_year);
        // (field = {...field, editType: 'filter', mapper: null})
      }
      let value = getRowValue(line, field[keyProp], field[keyValueProp], true, null, field);

      if (value && typeof value === 'object' && !value.label) {
        value = null;
      }

      if (field.editType === 'filter' && value) {
        value = {
          value: moment(value.toString().replace(' 00:00:00', ''), 'D_M_YYYY').locale('pt-br').format('YYYY'),
          label: moment(value.toString().replace(' 00:00:00', ''), 'D_M_YYYY').locale('pt-br').format('YYYY'),
        };
      }

      const valueAlreadyExists = acc.find((obj) => {
        return obj.value && obj.value.value && value ? obj.value.value === value.value : obj.value === value;
      });

      if (!valueAlreadyExists && value) {
        return [...acc, {value}];
      }

      return acc;
    }, []);
  const allValues = textFields.reduce((acc, textField) => {
    return {...acc, ...{[textField.field]: filterByColumn(textField)?.filter((v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i)}};
  }, {});

  return (
    <>
      <EditableHeader
        headerHeight={headerTemplate && headerTemplate[0].height ? headerTemplate[0].height : 'auto'}
        prefetchedValues={allValues}
        headerTemplate={headerTemplate}
        columnsTemplate={columnsTemplate}
        titleProp={titleProp}
        dataValues={dataValues}
        validateCallback={validateCallback}
        setSort={setSort}
        width={width}
      />
      {state.mode === DATAGRID.TABLE.MODE.NEW_LINE && (
        <EditableRow
          // eslint-disable-next-line react/no-array-index-key
          key="-1"
          prefetchedValues={allValues}
          index="-1"
          eventListener={gridListener}
          fieldHeaders={fieldTemplate.filter((_header) => !_header.subFields)}
          columnsTemplate={columnsTemplate}
          edit={edit}
          keyProp={keyProp}
          keyValueProp={keyValueProp}
          onEvent={gridListener}
          width={width}
        />
      )}
      <RowContainer
        accumulators={accumulators}
        groupBy={groupBy}
        gridListener={gridListener}
        fieldHeaders={fieldTemplate.filter((_header) => !_header.subFields)}
        columnsTemplate={columnsTemplate}
        edit={edit}
        keyProp={keyProp}
        keyValueProp={keyValueProp}
        dataValues={dataValues}
        setCel={setCel}
        width={width}
      />
    </>
  );
};

export default EditableGrid;
