import React, {useState, useEffect, useContext} from 'react';
import {Button} from 'reactstrap';
import uuid from 'react-uuid';
import {ToolbarEdit} from './styled';
// eslint-disable-next-line import/no-cycle
import DataGridContext from './DataGridContext';
import {DATAGRID} from '../../utils/constants';

const Toolbar = ({customConfiguration, onClick = () => {}}) => {
  const {state} = useContext(DataGridContext);
  const defaultActions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: () => {
        // console.log('EXCLUIR');
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {
        // console.log('save');
      },
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  const [actions, setActions] = useState(customConfiguration || defaultActions);

  const rulesDisabled = {
    add: state.mode !== DATAGRID.TABLE.MODE.READ,
    edit: !state.currentLine || state.mode === DATAGRID.TABLE.MODE.EDIT || state.mode === DATAGRID.TABLE.MODE.NEW_LINE,
    remove: !state.currentLine || state.mode !== DATAGRID.TABLE.MODE.READ,
    save: state.mode !== DATAGRID.TABLE.MODE.EDIT && state.mode !== DATAGRID.TABLE.MODE.NEW_LINE,
    cancel: !state.currentLine && state.mode === DATAGRID.TABLE.MODE.READ,
  };

  useEffect(() => {
    if (customConfiguration && customConfiguration.length) {
      const userActions = customConfiguration.map((actionConfiguration) => {
        const customAction = customConfiguration.find((customActionConfiguration) => customActionConfiguration.action === actionConfiguration.action);
        if (customAction) {
          return {...actionConfiguration, ...customAction};
        }
        return actionConfiguration;
      });
      setActions(userActions);
    }
  }, [customConfiguration]);

  return (
    <>
      {actions && !!actions.length && (
        <ToolbarEdit>
          <div className="btn-section">
            {actions
              .filter((action) => !action.component)
              .map((btn) => {
                return !btn.hide ? (
                  <Button
                    disabled={rulesDisabled[btn.action]}
                    key={uuid()}
                    id={btn.action}
                    onClick={() => {
                      onClick(btn);
                    }}>
                    <i className={btn.icon || ''} />
                    {btn.title}
                  </Button>
                ) : (
                  <React.Fragment key={uuid()} />
                );
              })}
            {actions
              .filter((action) => action.component)
              .map(({component}) => (
                <React.Fragment key={uuid()}>{component}</React.Fragment>
              ))}
          </div>
        </ToolbarEdit>
      )}
    </>
  );
};

export default Toolbar;
