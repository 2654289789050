/* eslint-disable no-nested-ternary */
import React from 'react';
import Pagination from 'react-js-pagination';

const Paginate = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  itemClass,
  linkClass,
  onChange = () => {},
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <Pagination
        activePage={activePage + 1}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        hideFirstLastPages
        itemClass={itemClass}
        linkClass={linkClass}
        onChange={(pageNumber) => {
          onChange({pageNumber});
        }}
      />
    </div>
  );
};

export default Paginate;
