import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = ({newContent, deleteContent, saveContent, ...rest}) => {
  return (
    <CustomHeader>
      {newContent?.show && (
        <button type="button" disabled={newContent.enable} id="add" onClick={(event) => newContent.handle(event, rest.actions)}>
          <i className="fas fa-plus" />
          Criar novo
        </button>
      )}

      {saveContent?.show && (
        <button type="submit" disabled={saveContent.enable} id="save">
          <i className="fas fa-save" />
          Salvar
        </button>
      )}

      {deleteContent?.show && (
        <button type="button" disabled={!deleteContent.enable} id="remove" onClick={deleteContent.handle}>
          <i className="fas fa-trash-alt" />
          Excluir
        </button>
      )}
    </CustomHeader>
  );
};

Header.propTypes = {
  newContent: PropTypes.shape({
    handle: PropTypes.func,
    show: PropTypes.bool,
    enable: PropTypes.bool,
  }),
  deleteContent: PropTypes.shape({
    handle: PropTypes.func,
    show: PropTypes.bool,
    enable: PropTypes.bool,
  }),
  saveContent: PropTypes.shape({
    handle: PropTypes.func,
    show: PropTypes.bool,
  }),
};
export default Header;

const CustomHeader = styled.div`
  background: #f8f8f8;
  padding: 0;
  width: 100%;
  button {
    background: none;
    border: 1px solid transparent;
    border-radius: 0;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 100;
    line-height: 1.5;
    outline: none;
    padding: 15px 8px;
    text-align: center;
    vertical-align: middle;

    i {
      margin-right: 5px;
    }

    &:hover {
      background: #e6e6e6;
      border-color: #adadad;
      border-radius: 0;
      color: #333;
    }

    &:disabled {
      color: #8e8e8e;
      cursor: default;
      opacity: 0.65;
    }
  }
`;
