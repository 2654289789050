/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import IMask from 'imask';
import DataGrid from '../../components/DataGrid';
import {getValueObject, getRowValue} from '../../utils/utils';
import {DataContext} from '../../utils/DataProvider';
import columns from './StockGridDataGridModel';
import {updateLiveStockStocks, fetchLiveStockStocksGrouped} from '../../services/livestock';
import {fetchCategoryLivestock} from '../../services/categoryLivestock';

const StockGrid = () => {
  const [data, setData] = useState(null);
  const [diferences, setDiferences] = useState(null);
  const [colFixed, setColFixed] = useState({
    header: [
      {
        key: 'category',
        valueKey: 'category',
        height: 'auto',
        field: 'category',
        name: ' Categoria',
        textAlign: 'Center',
        editType: 'defaultedit',
        disabledEdit: true,
        disableFilter: true,
      },
    ],
    accumulator: [{title: 'Total'}, {title: 'Ponderado'}, {title: 'Diferença de cabeças'}],
  });

  const [totalItems] = useState(0);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParamsCustom, stateMessage, filterParams} = useContext(DataContext);

  const body = {
    created_by: {
      id: state.user.id,
    },
    harvest: {
      id: state.harvest.id,
    },
    customer: {
      id: state.customer.id,
    },
    farm: {
      id: state.farm.id,
    },
    status: false,
  };
  useEffect(() => {
    const comparar = (a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      // a deve ser igual a b
      return 0;
    };

    const getContents = async () => {
      try {
        delete stateFetchParamsCustom['order[id]'];
        delete stateFetchParamsCustom.page;
        delete stateFetchParamsCustom.perPage;
        const response = await fetchCategoryLivestock();

        // response.data.sort(comparar);

        const livestockData = await fetchLiveStockStocksGrouped({
          ...stateFetchParamsCustom,
          ...filterParams,
        });
        setDiferences(livestockData.data[[livestockData.data.length - 1]]);
        const dataFixedColumn = [];
        response.data.resultList.sort(comparar).map((element) => dataFixedColumn.push({category: element.title, id: element.id}));
        const mainArray = livestockData.data.filter((item, i) => i !== livestockData.data.length - 1);
        setData(mainArray);
        setColFixed((prev) => Object.assign(prev, {data: dataFixedColumn}));
        // setData(newObj);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [update, stateFetchParamsCustom, filterParams]);
  const getDate = (keyString) => {
    const keySplited = keyString.split('.');
    return keyString.indexOf('start_stock') > -1
      ? '10/01/1970 00:00:00'
      : `01/${parseInt(keySplited[1]) < 10 ? `0${parseInt(keySplited[1] ? keySplited[1] : '01')}` : parseInt(parseInt(keySplited[1]) ? keySplited[1] : '01')}/${
          parseInt(keySplited[0]) ? keySplited[0] : '01'
        } 00:00:00`;
  };

  const validateHeaderCallback = async (dataGridRows, field, checked, valueKey) => {
    const promises = [];
    dataGridRows.forEach((dataGridInputs) => {
      const keyValue = valueKey;
      const rowValues = getRowValue(dataGridInputs, field, valueKey, true, null, field);

      const newData = {
        category_livestock: {
          id: dataGridInputs.id,
        },
        date: getDate(keyValue),
        amount: rowValues.amount !== 'null' ? rowValues.amount : null,
        weight: rowValues.weight !== 'null' ? rowValues.weight : null,
        type: !(keyValue.indexOf('start_stock') > -1),
        [keyValue.indexOf('amount') > -1 ? 'amount' : 'weight']: parseInt(dataGridInputs[keyValue]),
        validated: checked,
      };

      promises.push(updateLiveStockStocks(Object.assign(newData, body)));
    });

    const response = await Promise.all(promises);
    setUpdate(!update);
    return response;
  };
  const actions = [
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (responseData, line, cel) => {
        try {
          const newData = {
            category_livestock: {
              id: responseData.id,
            },
            date: getDate(cel),
            type: !(cel.indexOf('start_stock') > -1),
            [cel.indexOf('amount') > -1 ? 'amount' : 'weight']: -1,
            validated: false,
          };

          const resp = await updateLiveStockStocks(Object.assign(body, newData));
          setUpdate((prev) => !prev);
          return resp;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      {data && (
        <div className="livesotckStock fixedHeader">
          <DataGrid
            onChange={async (dataGridInputs) => {
              const keyValue = Object.keys(dataGridInputs).filter((item) => item.split('.').length > 1);
              if (keyValue.length) {
                let newData = {
                  category_livestock: {
                    id: dataGridInputs.id,
                  },
                  validated: dataGridInputs.validated || false,
                };

                try {
                  keyValue.forEach(
                    (item) =>
                      (newData = {
                        ...newData,
                        date: getDate(item),
                        type: !(item.indexOf('start_stock') > -1),
                        [item.indexOf('amount') > -1 ? 'amount' : 'weight']: parseInt(dataGridInputs[item]),
                      }),
                  );
                  if (newData.hasOwnProperty('amount') && (newData.amount === null || newData.amount == 0 || newData.amount !== newData.amount)) {
                    newData = { ...newData, amount: -1 };
                  }
                  if (newData.hasOwnProperty('weight') && (newData.weight === null || newData.weight == 0 || newData.weight !== newData.weight)) {
                    newData = { ...newData, weight: -1 };
                  }
                  // console.log('++++', newData);
                  const resp = await updateLiveStockStocks(Object.assign(body, newData));
                  setUpdate(!update);
                  return resp;
                } catch (error) {
                  console.error(error);
                  return error;
                }
              }
              return null;
            }}
            header={columns(state.harvest.first_year, state.harvest.last_year)}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            fixedColumn={colFixed || null}
            actions={actions}
            // editableCelOnly
            totalItems={totalItems}
            disabledPaginate
            validateCallback={validateHeaderCallback}
            maxHeight="100%"
            accumulators={{
              Total: {
                columns: ['amount', 'weight'],
                columnType: 'nested',
                hideTitle: true,
                itemOperation: (acc, line, key) => {
                  if (key.indexOf('weight') > 0) {
                    return (acc += getValueObject(line, null, key) * getValueObject(line, null, key.replace('weight', 'amount')));
                  }
                  return (acc += getValueObject(line, null, key) !== '' ? getValueObject(line, null, key) : 0);
                },
                resultOperation: (acc, key) => {
                  if (key.indexOf('weight') > 0) {
                    return IMask.createMask({
                      mask: 'number',
                      blocks: {
                        number: {
                          mask: Number, // enable number mask

                          // other options are optional with defaults below
                          scale: 2, // digits after point, 0 for integers
                          signed: false, // disallow negative
                          thousandsSeparator: '.', // any single char
                          padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                          normalizeZeros: true, // appends or removes zeros at ends
                          radix: ',', // fractional delimiter

                          // additional number interval options (e.g.)
                          min: 0,
                          max: 999999999999,
                        },
                      },
                    }).resolve(acc.toString());
                  }

                  return acc;
                },
              },
              Ponderado: {
                columns: ['weight'],
                columnType: 'nested',
                hideTitle: true,
                itemOperation: (acc, line, key) => {
                  if (!acc) {
                    return {
                      sum: getValueObject(line, null, key) * getValueObject(line, null, key.replace('weight', 'amount')),
                      quantity: parseInt(getValueObject(line, null, key.replace('weight', 'amount')) || 0),
                    };
                  }
                  return {
                    sum: acc.sum + getValueObject(line, null, key) * getValueObject(line, null, key.replace('weight', 'amount')),
                    quantity: parseInt(acc.quantity) + parseInt(getValueObject(line, null, key.replace('weight', 'amount')) || 0),
                  };
                },
                resultOperation: (acc) => {
                  return IMask.createMask({
                    mask: 'number',
                    blocks: {
                      number: {
                        mask: Number, // enable number mask

                        // other options are optional with defaults below
                        scale: 2, // digits after point, 0 for integers
                        signed: false, // disallow negative
                        thousandsSeparator: '.', // any single char
                        padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                        normalizeZeros: true, // appends or removes zeros at ends
                        radix: ',', // fractional delimiter

                        // additional number interval options (e.g.)
                        min: 0,
                        max: 999999999999,
                      },
                    },
                  }).resolve((acc.sum / acc.quantity).toString());
                },
              },
              'Diferença de cabeças': {
                columns: ['amount'],
                columnType: 'nested',
                hideTitle: true,
                itemOperation: (acc, line, key) => {
                  const index = key.split('.')[1];
                  return index !== 'amount' ? diferences.difference[index] : ' ';
                },
                resultOperation: (acc) => {
                  return acc;
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default StockGrid;
