import styled from 'styled-components';

export const ValidationCheckFilter = styled.input`
  cursor: pointer;
  height: 20px;
  position: relative;
  right: 5px;
  top: 2px;
  width: 20px;
`;

export const CheckboxList = styled.div`
  display: flex;
  height: 175px;
  justify-content: center;
  padding: 13px;

  fieldset {
    overflow: auto;
  }
`;

export const CustomFilter = styled.div`
  max-height: 647px;
  width: 430px;
`;

export const CenterButtons = styled.div`
  display: flex;
  justify-content: center;
`;
