/* eslint-disable camelcase */
import * as Yup from 'yup';
import {fetchHarvests} from '../../services/harvests';
import {fetchCountries} from '../../services/country';
import {fetchRegions} from '../../services/regions';
import {fetchStates} from '../../services/states';
import {fetchCities} from '../../services/city';
// import {fetchUsers} from '../../services/user';
import {fetchCategoryType} from '../../services/categoryType';
import {fetchCategoryProcessingFormats} from '../../services/categoryProcessingFormats';
import {fetchCategoryIlp} from '../../services/categoryIlp';
import {fetchCategoryConfinement} from '../../services/categoryConfinement';
import {fetchCategoryILPPercentage} from '../../services/categoryIlpPercentage';
import {fetchCategoryOwnFarming} from '../../services/categoryOwnFarming';
import {fetchCategoryLivestockSystem} from '../../services/categoryLivestockSystem';
import {fetchCategoryBiomes} from '../../services/categoryBiomes';

export const updateFarmDetail = (index, valuesState, key, value) => {
  if (!valuesState?.farm_detail) {
    return {
      ...valuesState,
      farm_detail: {
        [index]: {[key]: value},
      },
    };
  }
  return {
    ...valuesState,
    farm_detail: {
      ...valuesState?.farm_detail,
      [index]: {...valuesState?.farm_detail[index], [key]: value},
    },
  };
};

const groupFields = (state, values, dispatch) => {
  const fields = [
    [
      {
        title: 'Bem vindo ao PGDSM!',
        name: ` Esse é seu primeiro acesso a nossa plataforma.
              Precisamos que todas informações sejam cadastradas para realizar
              o seu diagnóstico.
              Primeiramente, precisamos que você selecione o tipo de
              processamento e a safra.`,
        fields: [
          {
            tooltip: `Selecione o formato de processamento dos dados da sua fazenda.\n
            Individual – Os dados inseridos na plataforma são referentes a cada fazenda individualmente.\n
            Grupo de fazendas – Os dados inseridos na plataforma são referentes a duas ou mais fazendas. Dados consolidados.`,
            label: 'Formato de processamento',
            name: 'processing_formatting',
            type: 'autocomplete',
            autocomplete: 'whatever',
            hasPlaceholder: true,
            placeholder: 'Individual/Grupo',
            value: values?.processing_formatting,
            currentValue: values?.processing_formatting,
            required: true,
            disabled: false,
            validations: {
              processing_formatting: Yup.string().required('Campo obrigatório'),
            },
            optionsFetch: async () => {
              try {
                const response = await fetchCategoryProcessingFormats();
                const objectTemp = [];
                response.data.resultList.map((item) =>
                  objectTemp.push({
                    value: item.id,
                    label: item.title,
                  }),
                );
                // controlFetch = ;
                return objectTemp;
              } catch (e) {
                return [];
              }
            },
            // options: [
            //   {value: 'SINGLE', label: 'Individual'},
            //   {value: 'GROUP', label: 'Grupo'},
            // ],
            columns: {
              xs: 12,
            },
            onChange: (e) => {
              dispatch({type: 'add', value: {processing_formatting: e}});
            },
          },
          {
            // tooltip: 'Escreva a quantidade de fazendas que serão descritas',
            label: 'Quantidade de fazendas',
            name: 'farms_quantity_diagnosis',
            type: 'number',
            hasPlaceholder: true,
            placeholder: ' ',
            // hideField: values.processing_formatting?.value !== 'SINGLE',
            value: state?.customer?.farms_limit || values?.farms_quantity_diagnosis || '1',
            required: true,
            disabled: true,
            onChange: (e) => {
              dispatch({type: 'add', value: {farms_quantity_diagnosis: e}});
            },
            validations: {
              farms_quantity_diagnosis: Yup.string().required('Campo obrigatório'),
            },
            columns: {
              xs: 4,
            },
          },
          {
            // tooltip: 'Selecione a Safra',
            label: 'Safra:',
            name: 'harvest',
            type: 'autocomplete',
            autocomplete: 'whatever',
            hasPlaceholder: true,
            placeholder: 'Safras Disponíveis',
            value: values?.harvest || '',
            currentValue: values?.harvest || '',
            required: true,
            validations: {
              harvest: Yup.string().required('Campo obrigatório'),
            },
            disabled: true,
            onChange: (e) => {
              dispatch({type: 'add', value: {harvest: e}});
            },
            optionsFetch: async () => {
              try {
                const response = await fetchHarvests();
                const objectTemp = [];
                response.data.resultList.map((item) =>
                  objectTemp.push({
                    value: item.id,
                    label: item.title,
                  }),
                );
                return objectTemp;
              } catch (e) {
                return [];
              }
            },
            columns: {
              xs: 12,
            },
          },
        ],
      },
    ],
    // [
    //   {
    //     title: 'Dados do Cliente',
    //     name: `Dados do técnico`,
    //     fields: [
    //       {
    //         tooltip: ``,
    //         label: '',
    //         name: 'technicalAux',
    //         type: 'radio',
    //         hasPlaceholder: true,
    //         value: (values && values.technicalAux) || 'none',
    //         currentValue: 'none',
    //         placeholder: ' ',
    //         required: true,
    //         disabled: false,
    //         onChange: (e) => {
    //           dispatch({type: 'add', value: {technicalAux: e}});
    //         },
    //         options: [
    //           {value: 'autocomplete', label: 'Selecionar Técnico'},
    //           {value: 'describe', label: 'Descrever técnico'},
    //           {value: 'none', label: 'Não sei quem é meu técnico'},
    //         ],
    //         validations: {
    //           technicalAux: Yup.string().required('Selecione uma opção'),
    //         },
    //         columns: {
    //           xs: 12,
    //         },
    //       },
    //       {
    //         tooltip: `Selecione o técnico DSM que está responsável pelo projeto PGDSM da sua fazenda \n\n*Campo obrigatório para clientes DSM
    //       \n*Caso não tenha, informar de onde recebeu a planilha de diagnóstico PGDSM`,
    //         label: 'Técnicos:',
    //         name: 'selectTechnical',
    //         type: 'autocomplete',
    //         hasPlaceholder: true,
    //         onChange: (e) => {
    //           dispatch({type: 'add', value: {selectTechnical: e}});
    //         },
    //         optionsFetch: async () => {
    //           try {
    //             const {data} = await fetchUsers({
    //               'wherein[userType]': "'TECHNICAL'",
    //             });
    //             return data.resultList.map((item) => {
    //               return {
    //                 ...item,
    //                 value: item.id,
    //                 label: `${item.name} | Email: ${item.email}`,
    //               };
    //             });
    //           } catch (error) {
    //             console.error(error);
    //             return [];
    //           }
    //         },
    //         hideField: values?.technicalAux !== 'autocomplete',
    //         value: (values && values.selectTechnical) || '',
    //         currentValue: (values && values.selectTechnical) || '',
    //         placeholder: ' ',
    //         required: true,
    //         disabled: false,
    //         validations: {
    //           selectTechnical: Yup.string().required('Campo obrigatório'),
    //         },
    //         columns: {
    //           xs: 12,
    //         },
    //       },
    //       {
    //         tooltip: `Nome completo do técnico DSM que está responsável pelo projeto PGDSM da sua fazenda \n\n*Campo obrigatório para clientes DSM
    //       \n*Caso não tenha, informar de onde recebeu a planilha de diagnóstico PGDSM`,
    //         label: 'Nome Completo:',
    //         name: 'technical_name',
    //         value: (values && values.technical_name) || '',
    //         type: 'text',
    //         hideField: values?.technicalAux !== 'describe',
    //         hasPlaceholder: true,
    //         // value: values?.techinalEmail
    //         //   ? values?.techinalEmail
    //         //   : (values && values.technical_name) || '',
    //         // currentValue: (values && values.technical_name) || '',
    //         placeholder: ' ',
    //         required: true,
    //         disabled: false,
    //         onChange: (e) => {
    //           dispatch({type: 'add', value: {technical_name: e}});
    //         },
    //         // optionsFetch: async () => {
    //         //   try {
    //         //     const {data} = await fetchUsers({
    //         //       'wherein[userType]': "'TECHNICAL'",
    //         //     });
    //         //     return data.resultList.map((item) => {
    //         //       return {
    //         //         value: item.id,
    //         //         label: `${item.name} `,
    //         //       };
    //         //     });
    //         //   } catch (error) {
    //         //     console.error(error);
    //         //     return [];
    //         //   }
    //         // },
    //         validations: {
    //           technical_name: Yup.string().required('Campo obrigatório'),
    //         },
    //         columns: {
    //           xs: 8,
    //         },
    //       },

    //       {
    //         tooltip: `E-mail do técnico DSM que está responsável pelo projeto PGDSM da sua fazenda \n\n*Campo obrigatório para clientes DSM `,
    //         label: 'E-mail:',
    //         name: 'technical_email',
    //         type: 'text',
    //         hasPlaceholder: true,
    //         hideField: values?.technicalAux !== 'describe',
    //         value: (values && values.technical_email) || '',
    //         currentValue: values?.technical_email || '',
    //         placeholder: ' ',
    //         required: true,
    //         disabled: false,
    //         // optionsFetch: async () => {
    //         //   try {
    //         //     const {data} = await fetchUsers({
    //         //       'wherein[userType]': "'TECHNICAL'",
    //         //     });
    //         //     return data.resultList.map((item) => {
    //         //       return {
    //         //         value: item.id,
    //         //         label: `${item.email} `,
    //         //       };
    //         //     });
    //         //   } catch (error) {
    //         //     console.error(error);
    //         //     return [];
    //         //   }
    //         // },
    //         onChange: (e) => {
    //           dispatch({type: 'add', value: {technical_email: e}});
    //         },
    //         validations: {
    //           technical_email: Yup.string()
    //             .email('E-mail inválido')
    //             .required('Campo obrigatório'),
    //         },
    //         columns: {
    //           xs: 8,
    //         },
    //       },
    //     ],
    //   },
    //   {
    //     name: `Dados do controlador`,
    //     fields: [
    //       {
    //         tooltip: `Nome do responsável pelo projeto na fazenda. Pessoa que lançará os dados produtivos \ne financeiros na plataforma,
    //       ou cobrará os lançamentos \n\n*Chamado de "Líder do projeto", responsável pelo bom andamento do PGDSM `,
    //         label: 'Nome Completo:',
    //         name: 'controller_name',
    //         type: 'text',
    //         hasPlaceholder: true,
    //         value: (values && values.controller_name) || '',
    //         placeholder: ' ',
    //         onChange: (e) => {
    //           dispatch({type: 'add', value: {controller_name: e}});
    //         },
    //         required: true,
    //         disabled: false,
    //         validations: {
    //           controller_name: Yup.string().required('Campo obrigatório'),
    //         },
    //         columns: {
    //           xs: 12,
    //         },
    //       },
    //       {
    //         tooltip: `E-mail do responsável pelo projeto na fazenda.\nPessoa que lançará os dados produtivos e financeiros na plataforma,
    //       ou cobrará os lançamentos \n\n*Chamado de "Líder do projeto", responsável pelo bom andamento do PGDSM `,
    //         label: 'E-mail:',
    //         name: 'controller_email',
    //         type: 'text',
    //         hasPlaceholder: true,
    //         onChange: (e) => {
    //           dispatch({type: 'add', value: {controller_email: e}});
    //         },
    //         value: (values && values.controller_email) || '',
    //         placeholder: ' ',
    //         required: true,
    //         disabled: false,
    //         validations: {
    //           controller_email: Yup.string()
    //             .email('E-mail inválido')
    //             .required('Campo obrigatório'),
    //         },
    //         columns: {
    //           xs: 12,
    //         },
    //       },
    //     ],
    //   },
    // ],
    [
      {
        title: 'Dados do proprietário',
        name: ``,
        fields: [
          {
            tooltip: 'Inserir nome completo do proprietário da fazenda',
            label: 'Nome Completo:',
            name: 'owner_name',
            type: 'text',
            hasPlaceholder: true,
            value: (values && values.owner_name) || '',
            placeholder: ' ',
            onChange: (e) => {
              dispatch({type: 'add', value: {owner_name: e}});
            },
            required: true,
            disabled: false,
            validations: {
              owner_name: Yup.string().required('Campo obrigatório'),
            },
            columns: {
              xs: 12,
            },
          },
          {
            tooltip: 'Formato XX/XX/XXXX',
            label: 'Data de nascimento',
            name: 'owner_birth_date',
            type: 'datepicker',
            format: 'dd/MM/yyyy',
            initialYear: new Date('1900', 0),
            // view: ['day', 'month', 'year'],
            validations: {
              owner_birth_date: Yup.string().required('Campo obrigatório'),
            },
            value: (values && values.owner_birth_date) || new Date(),
            onChange: (e) => {
              dispatch({type: 'add', value: {owner_birth_date: e}});
            },
            // initialYear:'',
            // finalYear: finalYear ? new Date(finalYear, 5) : null,
            hasPlaceholder: true,
            placeholder: 'Data de nascimento',
            key: `birth-date`,
            required: true,
            columns: {
              xs: 7,
            },
          },
          {
            tooltip: 'Inserir apenas números no formato (XX) X XXXX-XXXX',
            label: 'Telefone:',
            placeholder: '(XX) X XXXX-XXXX',
            name: 'owner_phone',
            type: 'text',
            hasPlaceholder: true,
            value: (values && values.owner_phone) || '',
            currentValue: (values && values.owner_phone) || '',
            mask: true,
            maskType: 'phone',
            required: true,
            disabled: false,
            onChange: (e) => {
              dispatch({type: 'add', value: {owner_phone: e}});
            },
            validations: {
              owner_phone: Yup.string().min(20, 'Telefone inválido').required('Campo obrigatório'),
            },
            columns: {
              xs: 5,
            },
          },
          {
            tooltip: 'E-mail do propietário da fazenda',
            label: 'E-mail:',
            name: 'owner_email',
            type: 'email',
            placeholder: 'example@dsm.com',
            hasPlaceholder: true,
            value: (values && values.owner_email) || '',
            required: true,
            disabled: false,
            onChange: (e) => {
              dispatch({type: 'add', value: {owner_email: e}});
            },
            validations: {
              owner_email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
            },
            columns: {
              xs: 7,
            },
          },
        ],
      },
    ],
  ];
  const farmFields = (qtd) => {
    const farmArray = (qtdIndex) => [
      [
        {
          title: `Dados ${qtd > 1 ? `da ${qtdIndex + 1}ª` : 'da'} Fazenda`,
          name: ``,
          fields: [
            {
              tooltip: 'Preencher o nome da Fazenda',
              label: 'Nome Completo da Fazenda:',
              name: 'alias',
              type: 'text',
              hasPlaceholder: true,
              value: values?.farm_detail?.[qtdIndex]?.alias || '',
              placeholder: ' ',
              required: true,
              disabled: true,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'alias', e)},
                });
              },
              validations: {
                alias: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 12,
              },
            },
            {
              tooltip: 'Inserir E-mail da fazenda',
              label: 'E-mail da Fazenda:',
              name: 'email',
              type: 'email',
              placeholder: 'example@dsm.com',
              hasPlaceholder: true,
              value: values?.farm_detail?.[qtdIndex]?.email || '',
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'email', e)},
                });
              },
              validations: {
                email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
              },
              columns: {
                xs: 12,
              },
            },
            {
              tooltip: 'Informar país de localização da fazenda',
              label: 'Bioma:',
              name: 'category_biome',
              value: values?.farm_detail?.[qtdIndex]?.category_biome,
              currentValue: values?.farm_detail?.[qtdIndex]?.category_biome,
              type: 'autocomplete',
              autocomplete: 'whatever',
              placeholder: 'Selecione o Bioma',
              hasPlaceholder: true,
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'category_biome', e)},
                });
              },
              validations: {
                category_biome: Yup.string().required('Campo obrigatório'),
              },
              optionsFetch: async () => {
                try {
                  const {data} = await fetchCategoryBiomes();
                  return data.resultList.map((item) => {
                    return {value: item.id, label: item.title};
                  });
                } catch (error) {
                  console.error(error);
                  return [];
                }
              },
              columns: {
                xs: 12,
              },
            },
            {
              tooltip: 'Informar país de localização da fazenda',
              label: 'Pais:',
              name: 'country',
              value: values?.farm_detail?.[qtdIndex]?.country,
              currentValue: values?.farm_detail?.[qtdIndex]?.country,
              type: 'autocomplete',
              autocomplete: 'whatever',
              placeholder: 'Selecione o País',
              hasPlaceholder: true,
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'country', e)},
                });
              },
              validations: {
                country: Yup.string().required('Campo obrigatório'),
              },
              optionsFetch: async () => {
                try {
                  const {data} = await fetchCountries();
                  return data.resultList.map((item) => {
                    return {value: item.id, label: item.title};
                  });
                } catch (error) {
                  console.error(error);
                  return [];
                }
              },
              columns: {
                xs: 3,
              },
            },
            {
              tooltip: 'Informar Região de localização da fazenda',
              label: 'Região:',
              name: 'region',
              type: 'autocomplete',
              autocomplete: 'whatever',
              value: values?.farm_detail?.[qtdIndex]?.region || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.region || '',
              placeholder: 'Selecione a Região',
              hasPlaceholder: true,
              required: true,
              disabled: values && !values?.farm_detail?.[qtdIndex]?.country?.value,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'region', e)},
                });
              },
              validations: {
                region: Yup.string().required('Campo obrigatório'),
              },
              optionsFetch: async () => {
                try {
                  if (values?.farm_detail?.[qtdIndex]?.country?.value) {
                    const {data} = await fetchRegions({
                      'wherein[country.id]': values?.farm_detail?.[qtdIndex]?.country.value,
                      'order[title]': 'asc',
                    });
                    return data.resultList.map((item) => {
                      return {value: item.id, label: item.title};
                    });
                  }
                  return [];
                } catch (error) {
                  console.error(error);
                  return [];
                }
              },
              columns: {
                xs: 3,
              },
            },
            {
              tooltip: 'Informar estado de localização da fazenda',
              label: 'Estado:',
              name: 'state',
              type: 'autocomplete',
              autocomplete: 'whatever',
              value: values?.farm_detail?.[qtdIndex]?.state || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.state || '',
              hasPlaceholder: true,
              placeholder: 'Selecione o Estado',
              required: true,
              disabled: !values?.farm_detail?.[qtdIndex]?.region?.value,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'state', e)},
                });
              },
              validations: {
                state: Yup.string().required('Campo obrigatório'),
              },
              optionsFetch: async () => {
                try {
                  if (values?.farm_detail?.[qtdIndex]?.region?.value) {
                    const {data} = await fetchStates({
                      'wherein[region.id]': values?.farm_detail?.[qtdIndex]?.region.value,
                      'order[title]': 'asc',
                    });
                    return data.resultList.map((item) => {
                      return {value: item.id, label: item.title};
                    });
                  }
                  return [];
                } catch (error) {
                  console.error(error);
                  return [];
                }
              },
              columns: {
                xs: 3,
              },
            },
            {
              tooltip: 'Informar cidade de localização da fazenda',
              label: 'Cidade:',
              name: 'city',
              value: values?.farm_detail?.[qtdIndex]?.city || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.city || '',
              type: 'autocomplete',
              autocomplete: 'whatever',
              placeholder: 'Selecione a cidade',
              hasPlaceholder: true,
              required: true,
              disabled: values && !values?.farm_detail?.[qtdIndex]?.state?.value,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'city', e)},
                });
              },
              optionsFetch: async () => {
                try {
                  if (values?.farm_detail?.[qtdIndex]?.state?.value) {
                    const {data} = await fetchCities({
                      'wherein[state.id]': values?.farm_detail?.[qtdIndex]?.state.value,
                      'order[title]': 'asc',
                    });
                    return data.resultList.map((item) => {
                      return {value: item.id, label: item.title};
                    });
                  }
                  return [];
                } catch (error) {
                  console.error(error);
                  return [];
                }
              },
              validations: {
                city: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 3,
              },
            },
            {
              tooltip: 'Informar se compra suplementos nutricionais da marca Tortuga',
              label: 'É cliente da Tortuga, marca DSM?',
              name: 'is_client_dsm',
              type: 'autocomplete',
              autocomplete: 'whatever',
              value: values?.farm_detail?.[qtdIndex]?.is_client_dsm || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.is_client_dsm || '',
              hasPlaceholder: true,
              required: true,
              disabled: false,
              validations: {
                city: Yup.string().required('Campo obrigatório'),
              },
              options: [
                {value: true, label: 'Sim'},
                {value: false, label: 'Não'},
              ],
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'is_client_dsm', e),
                  },
                });
              },
              columns: {
                xs: 6,
              },
            },
            {
              tooltip: 'Inserir nome da empresa que compra suplementos nutricionais',
              label: 'É cliente de qual empresa',
              name: 'other_company',
              type: 'text',
              hasPlaceholder: true,
              value: values?.farm_detail?.[qtdIndex]?.other_company || '',
              hideField: values && !!values?.farm_detail?.[qtdIndex]?.is_client_dsm && !!values?.farm_detail?.[qtdIndex]?.is_client_dsm?.value,
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'other_company', e),
                  },
                });
              },
              validations: {
                city: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 6,
              },
            },
          ],
        },
      ],
      [
        {
          title: `Dados da ${qtdIndex + 1}ª Fazenda `,
          name: ``,
          fields: [
            // {
            //   tooltip: `Preencher com quantas fazendas o produtor fará parte do PGDSM.\n\nOBS:\n1. Para cada fazenda deve haver um Diagnóstico completo;
            //   \n2. As fazendas devem ter centro de custos separados, tanto para receitas quanto para despesas;\n3. As transferências de animais
            //   entre fazendas devem ser monetizadas a preços de mercado. Fazenda A compra/vende da Fazenda B.\n4. Caso o produtor tenha mais de uma
            //   fazenda e não consiga separar os dados produtivos/financeiros, o Diagnóstico PGDSM deve ser \npreenchido com as informações consolidadas,
            //   de todas as propriedades. Mesmo assim, deve informar a quantidade de fazendas nesta célula.`,
            //   label: 'Quantidade de fazendas:',
            //   name: 'farmLimit',
            //   type: 'number',
            //   hasPlaceholder: true,
            //   onChange: (e) => {
            //     dispatch({...values, farmLimit: e});
            //   },
            //   value: (values && values.farmLimit) || '',
            //   placeholder: ' ',
            //   required: true,
            //   disabled: false,
            //   validations: {
            //     farmLimit: Yup.string().required('Campo obrigatório'),
            //   },
            //   columns: {
            //     xs: 3,
            //   },
            // },
            {
              tooltip: `Informar qual o sistema predominante na fazenda durante a safra. \nInformação importante para benchmarking preciso.`,
              label: 'Sistema de Produção:',
              name: 'category_livestock_system',
              type: 'autocomplete',
              autocomplete: 'whatever',
              hasPlaceholder: true,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'category_livestock_system', e),
                  },
                });
              },
              optionsFetch: async () => {
                try {
                  const {data} = await fetchCategoryLivestockSystem();
                  return data.resultList
                    .filter((item) => item.title !== 'Exclusiva de confinamento')
                    .map((item) => {
                      return {value: item.id, label: item.title};
                    });
                } catch (error) {
                  console.error(error);
                  return [];
                }
              },
              value: values?.farm_detail?.[qtdIndex]?.category_livestock_system || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.category_livestock_system || '',
              placeholder: ' ',
              required: true,
              disabled: false,
              validations: {
                category_livestock_system: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 9,
              },
            },
            {
              tooltip: `Informar se a fazenda trabalha em sistemas integrados com agricultura. \nNeste caso, as áreas devem ser comuns,
           em que parte do \nano está com pastagens e na outra parte do ano com lavoura.`,
              label: 'Possui integração Lavoura Pecuária (ILP)?',
              name: 'ilp',
              type: 'autocomplete',
              autocomplete: 'whatever',
              hasPlaceholder: true,
              value: values?.farm_detail?.[qtdIndex]?.ilp || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.ilp || '',
              placeholder: ' ',
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {...updateFarmDetail(qtdIndex, values, 'ilp', e)},
                });
              },
              optionsFetch: async () => {
                try {
                  const response = await fetchCategoryIlp();
                  const objectTemp = [];
                  response.data.resultList.map((item) =>
                    objectTemp.push({
                      value: item.id,
                      label: item.title,
                    }),
                  );
                  return objectTemp;
                } catch (e) {
                  return [];
                }
              },
              // options: [
              //   {value: true, label: 'Sim'},
              //   {value: false, label: 'Não'},
              // ],
              validations: {
                ilp: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 5,
              },
            },
            {
              tooltip: `Informar qual o percentual da área de produção total é \nutilizada para Integração Lavoura Pecuária`,
              label: 'Qual o percentual de ILP na fazenda?',
              name: 'category_ilp_percentage',
              type: 'autocomplete',
              autocomplete: 'whatever',
              hasPlaceholder: true,
              hideField: values?.farm_detail?.[qtdIndex]?.ilp?.label !== 'Com ILP',
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'category_ilp_percentage', e),
                  },
                });
              },
              value: values?.farm_detail?.[qtdIndex]?.category_ilp_percentage || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.category_ilp_percentage || '',
              placeholder: ' ',
              required: true,
              // posfix: '%',
              // fixedDecimalScale: false,
              // decimalScale: 2,
              disabled: false,
              validations: {
                category_ilp_percentage: Yup.string().required('Campo obrigatório'),
              },
              optionsFetch: async () => {
                try {
                  const response = await fetchCategoryILPPercentage({
                    'order[id]': 'ASC',
                  });
                  const objectTemp = [];
                  response.data.resultList.map((item) =>
                    objectTemp.push({
                      value: item.id,
                      label: item.title,
                    }),
                  );
                  return objectTemp;
                } catch (e) {
                  return [];
                }
              },
              columns: {
                xs: 5,
              },
            },
            {
              tooltip: `Informar "SIM" ou "NÃO" se a fazenda trabalha em \nsistema de confinamento para qualquer categoria animal.`,
              label: 'Possui confinamento?',
              name: 'category_confinement',
              type: 'autocomplete',
              autocomplete: 'whatever',
              hasPlaceholder: true,
              // hideField: values?.farm_detail?.[qtdIndex]?.category_livestock_system?.label === 'Exclusiva de confinamento',
              value: values?.farm_detail?.[qtdIndex]?.category_confinement || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.category_confinement || '',
              placeholder: ' ',
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'category_confinement', e),
                  },
                });
              },
              optionsFetch: async () => {
                try {
                  const response = await fetchCategoryConfinement({
                    'order[title]': 'ASC',
                  });
                  const objectTemp = [];
                  response.data.resultList.map((item) =>
                    objectTemp.push({
                      value: item.id,
                      label: item.title,
                    }),
                  );
                  return objectTemp;
                } catch (e) {
                  return [];
                }
              },
              // options: [
              //   {value: true, label: 'Sim'},
              //   {value: false, label: 'Não'},
              // ],
              validations: {
                category_confinement: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 5,
              },
            },
            {
              tooltip: `Análise deve ser feita considerando apenas como confinamento.\n\nOBS: Neste caso a área de produção não contará para resultados.`,
              label: 'EXCLUSIVA de confinamento ?',
              name: 'only_confinement',
              type: 'autocomplete',
              autocomplete: 'whatever',
              hideField: values?.farm_detail?.[qtdIndex]?.category_confinement?.label !== 'Com confinamento',
              hasPlaceholder: true,
              value: values?.farm_detail?.[qtdIndex]?.only_confinement || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.only_confinement || '',
              placeholder: ' ',
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'only_confinement', e),
                  },
                });
              },
              options: [
                {value: true, label: 'Sim'},
                {value: false, label: 'Não'},
              ],
              validations: {
                only_confinement: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 5,
              },
            },
            {
              tooltip: `Informar se trabalha vendendo touros e fêmeas PO.`,
              label: 'Trabalha com genética (animais PO) ?',
              name: 'category_type',
              type: 'autocomplete',
              autocomplete: 'whatever',
              hasPlaceholder: true,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'category_type', e),
                  },
                });
              },
              value: values?.farm_detail?.[qtdIndex]?.category_type || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.category_type || '',
              placeholder: ' ',
              required: true,
              disabled: false,
              // options: [
              //   {value: true, label: 'Sim'},
              //   {value: false, label: 'Não'},
              // ],
              optionsFetch: async () => {
                try {
                  const response = await fetchCategoryType();
                  const objectTemp = [];
                  response.data.resultList.map((item) =>
                    objectTemp.push({
                      value: item.id,
                      label: item.title === 'Genética' ? 'Sim' : 'Não',
                    }),
                  );
                  return objectTemp;
                } catch (e) {
                  return [];
                }
              },
              validations: {
                category_type: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 5,
              },
            },
            {
              tooltip: `Informar se possui áreas exclusivas para agricultura/silvicultura,\n nas quais não há pastagens em nenhuma época do ano,\n 
          nem acesso para os animais.\n\nOBS: Não considerar neste caso, áreas para produção de volumoso \ne/ou grãos para alimentação do rebanho.`,
              label: 'Possui agricultura própria ?',
              name: 'category_own_farming',
              type: 'autocomplete',
              autocomplete: 'whatever',
              hasPlaceholder: true,
              value: values?.farm_detail?.[qtdIndex]?.category_own_farming || '',
              currentValue: values?.farm_detail?.[qtdIndex]?.category_own_farming || '',
              placeholder: ' ',
              required: true,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'category_own_farming', e),
                  },
                });
              },
              // options: [
              //   {value: true, label: 'Sim'},
              //   {value: false, label: 'Não'},
              // ],
              optionsFetch: async () => {
                try {
                  const response = await fetchCategoryOwnFarming();
                  const objectTemp = [];
                  response.data.resultList.map((item) =>
                    objectTemp.push({
                      value: item.id,
                      label: item.title,
                    }),
                  );
                  return objectTemp;
                } catch (e) {
                  return [];
                }
              },

              validations: {
                category_own_farming: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 5,
              },
            },
            {
              tooltip: `Área total da fazenda, descrita na escritura,\nconsiderando Reservas Legais, APPs e Áreas de Produção.`,
              label: 'Área total da fazenda (ha):',
              name: 'total_area',
              type: 'float',
              hasPlaceholder: true,
              value: values?.farm_detail?.[qtdIndex]?.total_area?.value,
              currentValue: values?.farm_detail?.[qtdIndex]?.total_area?.value,
              placeholder: ' ',
              required: true,
              decimalScale: 2,
              fixedDecimalScale: false,
              disabled: false,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'total_area', e),
                  },
                });
              },
              validations: {
                total_area: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 4,
              },
            },
            {
              tooltip: `Informar qual o sistema predominante na fazenda durante a safra. \nInformação importante para benchmarking preciso.`,
              label: 'Valor da terra (R$/ha):',
              name: 'land_value',
              type: 'float',
              prefix: 'R$',
              value: values?.farm_detail?.[qtdIndex]?.land_value?.value,
              currentValue: values?.farm_detail?.[qtdIndex]?.land_value?.value,
              hasPlaceholder: true,
              placeholder: ' ',
              decimalScale: 2,
              fixedDecimalScale: true,
              onChange: (e) => {
                dispatch({
                  type: 'add',
                  value: {
                    ...updateFarmDetail(qtdIndex, values, 'land_value', e),
                  },
                });
              },
              required: true,
              disabled: false,
              validations: {
                land_value: Yup.string().required('Campo obrigatório'),
              },
              columns: {
                xs: 4,
              },
            },
          ],
        },
      ],
    ];
    let finalArray = [];
    for (
      let index = 0;
      index < parseInt(qtd, 10);
      // eslint-disable-next-line no-plusplus
      index++
    ) {
      finalArray = [...finalArray, ...farmArray(index)];
    }
    return finalArray;
  };

  return [...fields, ...farmFields(values?.processing_formatting?.label === 'Individual' ? parseInt(values?.farms_quantity_diagnosis, 10) : 1)];
};

export default groupFields;
