import api from '../api';

const fetchCategoryAnimalType = (params = {}) => {
  return api.get(`/category_animal_type`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryAnimalTypebyId = (id, params = {}) => {
  return api.get(`/category_animal_type/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryAnimalType = (body = {}) => {
  return api.post(`/category_animal_type`, body);
};

const changeCategoryAnimalTypebyId = (id, body = {}) => {
  return api.put(`/category_animal_type/${id}`, body);
};

const deleteCategoryAnimalType = (id) => {
  return api.delete(`/category_animal_type/${id}`);
};

export {fetchCategoryAnimalType, fetchCategoryAnimalTypebyId, registerCategoryAnimalType, changeCategoryAnimalTypebyId, deleteCategoryAnimalType};
