import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateData} from '../../utils/utils';

import {
  fetchArrobaValuesDatagrid,
  registerArrobaValues,
  changeArrobaValuesbyId,
  deleteArrobaValues,
} from '../../services/arrobaValues';
import PrimaryFilterDisplay from '../../components/PrimaryFilterDisplay/PrimaryFilterDisplay';
import PrimaryFilterForm from '../../components/FilterForms/FilterSettings';
import {FILTER} from '../../utils/constants';

const ArrobaValues = () => {
  const {state, dispatch, stateFetchParamsCustom} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  }, []);// eslint-disable-line
  useEffect(() => {
    const loadData = async () => {
      if (!state.harvest || !state.customer || !state.farm) {
        return;
      }
      const response = await fetchArrobaValuesDatagrid(stateFetchParamsCustom);
      const temp = [];
      response.data.map((value) => {
        temp.push({
          ...value,
          arroba_value_id: value.id,
          id: value.category_livestock.id,
        });
        return null;
      });
      setData(temp);
      setTotalItems(temp.length);
    };

    loadData();
  }, [reset, state, stateFetchParamsCustom]);

  const columns = [
    {
      key: 'category_livestock',
      valueKey: 'category_livestock.title',
      field: 'category_livestock',
      name: 'Categoria',
      textAlign: 'Center',
      editType: 'defaultedit',
      disableFilter: true,
      disabledEdit: true,
    },
    {
      key: 'value_arroba_media',
      field: 'value_arroba_media',
      name: 'R$/@ médio da safra',
      disableFilter: true,
      textAlign: 'Center',
      returnFormat: (value) => {
        return parseInt(value, 10);
      },
      editType: 'moneyedit',
    },
    {
      field: 'maquina_presente_inicio_fim_safra',
      name: 'Análise com efeito de mercado',
      disableFilter: true,
      textAlign: 'Center',
      subFields: [
        {
          key: 'value_arroba_start',
          field: 'value_arroba_start',
          name: 'R$/@ início da safra',
          disableFilter: true,
          textAlign: 'Center',
          returnFormat: (value) => {
            return parseInt(value, 10);
          },
          editType: 'moneyedit',
        },
        {
          key: 'value_arroba_end',
          field: 'value_arroba_end',
          disableFilter: true,
          returnFormat: (value) => {
            return parseInt(value, 10);
          },
          name: 'R$/@ final da safra',
          textAlign: 'Center',
          editType: 'moneyedit',
        },
      ],
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteArrobaValues(value.arroba_value_id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {state.filterOpened && <PrimaryFilterForm />}
      {!state.filterOpened && data && (
        <>
          <PrimaryFilterDisplay />
          <DataGrid
            onChange={async (dataGridInputs) => {
              delete dataGridInputs.updated_at;
              delete dataGridInputs.updated_by;
              delete dataGridInputs.created_at;
              delete dataGridInputs.created_by;
              delete dataGridInputs.customer;
              delete dataGridInputs.farm;
              delete dataGridInputs.harvest;
              let temp;
              if (dataGridInputs.arroba_value_id) {
                temp = {
                  ...dataGridInputs,
                  id: dataGridInputs.arroba_value_id,
                };
              } else {
                temp = {
                  ...dataGridInputs,
                };
                delete temp.id;
              }

              delete temp.arroba_value_id;
              const response = await createOrUpdateData(
                temp,
                state,
                registerArrobaValues,
                changeArrobaValuesbyId,
              );
              setReset(!reset);
              return response;
            }}
            header={columns}
            disabledPaginate
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
            maxHeight="100%"
          />
        </>
      )}
    </>
  );
};

export default ArrobaValues;
