import apiNew from '../apiNew';

const fetchCategoryAges = (params = {}) => {
  return apiNew.get(`/category_ages`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryAgesbyId = (id, params = {}) => {
  return apiNew.get(`/category_ages/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryAges = (body = {}) => {
  return apiNew.post(`/category_ages`, body);
};

const changeCategoryAgesbyId = (id, body = {}) => {
  return apiNew.put(`/category_ages/${id}`, body);
};

const deleteCategoryAges = (id) => {
  return apiNew.delete(`/category_ages/${id}`);
};

export {fetchCategoryAges, fetchCategoryAgesbyId, registerCategoryAges, changeCategoryAgesbyId, deleteCategoryAges};
