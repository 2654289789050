/* eslint-disable no-eval */
import React, {useState, useEffect, useContext} from 'react';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import format from 'date-fns/format';
import ptBRLocale from 'date-fns/locale/pt-BR';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {Checkbox, Popover, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress, InputAdornment, IconButton} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@material-ui/lab/Autocomplete';
import uuid from 'react-uuid';
import {MoneyFormat, maskInput, maskDiscriminator, InputFormat, WeightKgFormat, useDebounce, isValidEmail} from '../../utils/utils';
import {Cel} from './styled';
import {DataContext} from '../../utils/DataProvider';
import DataGridContext from './DataGridContext';
import {
  inputChange,
  addTableActionToQueue,
  insertData,
  // removeError,
  // addError,
} from './dataGridActions';
import {DATAGRID} from '../../utils/constants';
import {useInitialRenderEffect} from '../../utils/functions';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', {locale: this.locale});
  }
}

const useStyles = makeStyles((theme) => ({
  popover: {},
  paper: {
    padding: theme.spacing(1),
  },
}));

const EditableHandle = ({
  prefetchedValues,
  defaultValue,
  editType,
  field,
  options,
  optionsFetch,
  popoverConfig,
  disabledEdit,
  dateFormat,
  dateView,
  mask,
  groupBy,
  required,
  index,
  rowIndex,
  rowData,
  dependencies,
  extraParams,
  // eventListener = () => {},
}) => {
  const {state: dataGridState, dispatch} = useContext(DataGridContext);
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, 500);
  const [popoverValue, setPopoverValue] = useState(popoverConfig.defaultValue);
  const [popoverOptions, setPopoverOptions] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [optionsValue, setOptionsValue] = useState(null);
  const [showValidation, setShowValidation] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const {state} = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const [currentDateFormat, setCurrentDateFormat] = useState(dateFormat);
  const [currentDateView, setCurrentDateView] = useState(dateView);
  const [requiredState, setRequiredState] = useState(dateView);
  const [maskState, setMaskState] = useState(mask);
  const [ediTypeState, setEditTypeState] = useState(editType);

  const handlePressEnter = (e) => {
    if (e.target.type === 'number') {
      const invalidChars = ['-', '+', 'e'];
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    }

    if (e.keyCode === 13) {
      setDisabled(true);
      let a = e.currentTarget;
      while (a) {
        if (a?.className && a.className.indexOf('datagrid-general') > -1) {
          break;
        }
        a = a.parentNode;
      }
      setTimeout(() => {
        a.parentNode.querySelector('#save').click();
      }, 500);
    }
  };
  const addValidationError = (msg) => {
    // dispatch(addError(field, type)); this cause re-reder that buttons toolbar not work
    setShowValidation(msg);
  };
  const removeValidationError = () => {
    // dispatch(removeError(field, type)); this cause re-reder that buttons toolbar not work
    setShowValidation(null);
  };

  useEffect(() => {
    if (typeof required === 'function') {
      setRequiredState(required(dataGridState));
    } else {
      setRequiredState(required);
    }
  }, [required, state, dataGridState]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (dataGridState.currentCel.editableCelOnly) {
    if (dataGridState.currentCel.cel === index) {
      disabledEdit = false;
    } else {
      disabledEdit = true;
    }
  }

  if (typeof disabledEdit === 'function') {
    disabledEdit = disabledEdit(dataGridState, rowIndex);
  }

  useEffect(() => {
    (async () => {
      if (typeof extraParams?.formatedValue === 'function') {
        const resp = await extraParams?.formatedValue(dataGridState);
        if (resp) {
          setValue(resp);
        }
      }
    })();
  }, [JSON.stringify(dataGridState?.data)]);// eslint-disable-line

  const open = Boolean(anchorEl);
  const loadOptions = async () => {
    try {
      const response = await optionsFetch(state, dataGridState);
      if (editType === 'datepicker') {
        setOptionsValue(response);
        setValue(response);
      } else {
        setOptionsValue(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const loadMask = async () => {
    try {
      if (mask && typeof mask === 'function') {
        const response = await mask(state, dataGridState);
        setMaskState(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useInitialRenderEffect(() => {
    window.document.querySelectorAll('[role=button]').forEach((item) => item.removeAttribute('tabindex'));
    // eslint-disable-next-line eqeqeq
    if (rowData && rowData.period && `${rowData.period}` == 'true') {
      setCurrentDateFormat('MM/yyyy');
      setCurrentDateView(['month']);
    }
  });

  useEffect(() => {
    if (field === 'validated') {
      setValue(defaultValue);
    }
  }, [defaultValue]);// eslint-disable-line

  useEffect(() => {
    const loadPopoverOptions = async () => {
      try {
        const response = await popoverConfig.optionsFetch();
        setPopoverOptions(response);
      } catch (e) {
        console.error(e);
      }
    };
    if (popoverConfig.optionsFetch) {
      loadPopoverOptions();
    } else {
      setPopoverOptions(popoverConfig.options);
    }
  }, [popoverConfig]);

  useEffect(() => {
    if (debouncedValue !== defaultValue) {
      if ((field === 'validated' || field === 'enter_benchmarking') && dataGridState.mode === DATAGRID.TABLE.MODE.READ) {
        dispatch(insertData(rowData));
        dispatch(inputChange(field, debouncedValue, dependencies));
        dispatch(addTableActionToQueue(DATAGRID.TABLE.ACTIONS.SAVE));
      } else {
        dispatch(inputChange(field, debouncedValue, dependencies));
      }
    }

    // eslint-disable-next-line eqeqeq
    if (popoverValue != popoverConfig.defaultValue) {
      dispatch(inputChange(popoverConfig.field, popoverValue));
    }

    if (optionsFetch !== undefined && editType !== 'datepicker') {
      setLoading(true);
      loadOptions();
      setLoading(false);
    }
  }, [debouncedValue, popoverValue]);// eslint-disable-line

  useEffect(() => {
    const updateInputFromDependency = async () => {
      if (mask !== undefined && (typeof mask === 'function' || typeof mask === 'object')) {
        setDisabled(true);
        setLoading(true);
        await loadMask();
        setLoading(false);
      }
      setDisabled(false);
    };
    updateInputFromDependency();
  }, [dataGridState]);// eslint-disable-line

  useEffect(() => {
    const updateInputFromDependency = async () => {
      if (dependencies && dependencies.length && dependencies.includes(dataGridState.changed)) {
        setValue(null);
        setDisabled(true);
        dispatch(inputChange(field, null));
        if (optionsFetch !== undefined) {
          setLoading(true);
          await loadOptions();
          setLoading(false);
        }

        setDisabled(false);
      }
    };
    const clearValidationOnEdit = () => {
      if (
        dataGridState.mode === DATAGRID.TABLE.MODE.EDIT &&
        dataGridState.lastDispatch === DATAGRID.TABLE.EVENT.MODE_CHANGED &&
        dataGridState.currentLine === rowIndex
      ) {
        dispatch(inputChange('validated', false, dependencies));
        setValue(false);
      }
    };
    if (field === 'validated') {
      clearValidationOnEdit();
    }
    updateInputFromDependency();
  }, [JSON.stringify(dataGridState)]);// eslint-disable-line

  useEffect(() => {
    if (typeof extraParams?.changeTypeField === 'function') {
      setEditTypeState(extraParams?.changeTypeField(dataGridState));
    }
  }, [dataGridState]);// eslint-disable-line

  const generateInput = () => {
    switch (ediTypeState) {
      case 'defaultedit':
      case 'sugestionedit':
        return (
          <>
            <Autocomplete
              required={requiredState}
              disabled={disabledEdit}
              options={prefetchedValues?.length && ediTypeState === 'defaultedit' ? prefetchedValues : optionsValue || options}
              disableClearable
              freeSolo
              defaultValue={{value: defaultValue}}
              groupBy={extraParams?.groupBy ? (option) => extraParams?.groupBy(option) : null}
              onChange={(e, newValue) => {
                setValue(newValue.value);
                if (requiredState && newValue.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              onKeyDown={handlePressEnter}
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              getOptionLabel={(option) => `${option.value}`}
              style={{width: '100%'}}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  required={requiredState}
                  onInput={(e) => {
                    setValue(e.target.value);
                  }}
                  InputProps={{...params.InputProps, type: 'search'}}
                />
              )}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'textedit':
        return (
          <>
            <TextField
              onKeyDown={handlePressEnter}
              disabled={disabledEdit}
              id={uuid()}
              required={requiredState}
              variant="outlined"
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              style={{width: '100%', height: '28px', padding: '0'}}
              onInput={(e) => {
                setValue(e.target.value);
              }}
              data-value={value}
              data-field={field}
              value={value}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'passwordedit':
        return (
          <>
            <TextField
              onKeyDown={handlePressEnter}
              required={requiredState || dataGridState.mode === 'NEW_LINE'}
              disabled={disabledEdit}
              type={showPassword ? 'text' : 'password'}
              id={uuid()}
              variant="outlined"
              style={{width: '100%', height: '28px', padding: '0'}}
              onBlurCapture={(e) => {
                if ((requiredState || dataGridState.mode === 'NEW_LINE') && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (e.target.value.length > 0 && e.target.value.length < 8) {
                  addValidationError('Senha deve possuir no mínimo 8 caracteres.');
                } else {
                  removeValidationError();
                }
              }}
              onChange={(e) => {
                if (e.target.value.length > 0 && e.target.value.length < 8) {
                  addValidationError('Senha deve possuir no mínimo 8 caracteres.');
                } else {
                  removeValidationError();
                }
                setValue(e.target.value);
              }}
              value={value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'emailedit':
        return (
          <>
            <TextField
              onKeyDown={handlePressEnter}
              required={requiredState}
              disabled={disabledEdit}
              id={uuid()}
              variant="outlined"
              style={{width: '100%', height: '28px', padding: '0'}}
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              onChange={(e) => {
                if (e.target.value.length === 0 || (e.target.value.length > 0 && isValidEmail(e.target.value))) {
                  removeValidationError(DATAGRID.ERRORS.INVALID_EMAIL);
                } else {
                  addValidationError('Email inválido', DATAGRID.ERRORS.INVALID_EMAIL);
                }

                setValue(e.target.value);
              }}
              value={value}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'moneyedit':
        return (
          <>
            <TextField
              onKeyDown={handlePressEnter}
              required={requiredState}
              disabled={disabledEdit}
              id={uuid()}
              variant="outlined"
              style={{width: '100%', height: '28px', padding: '0'}}
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              InputProps={{
                inputComponent: MoneyFormat,
              }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  if (parseFloat(e.target.value) > 0) {
                    if (typeof extraParams?.maxValue === 'function') {
                      setValue(extraParams?.maxValue(rowData, parseFloat(e.target.value)));
                    } else if (extraParams?.maxValue && parseFloat(e.target.value) > extraParams?.maxValue) {
                      setValue(extraParams?.maxValue);
                    } else {
                      setValue(e.target.value);
                      removeValidationError();
                    }
                  } else {
                    addValidationError('Valor inválido');
                  }
                } else {
                  setValue(e.target.value);
                  removeValidationError();
                }
              }}
              defaultValue={defaultValue}
              value={value}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'cepedit':
      case 'numericedit':
        if (maskState) {
          return (
            <>
              <TextField
                onKeyDown={handlePressEnter}
                required={requiredState}
                disabled={disabledEdit}
                id={uuid()}
                variant="outlined"
                onBlurCapture={(e) => {
                  if (requiredState && e.target.value.length <= 0) {
                    addValidationError('Este campo é obrigatório');
                  } else if (requiredState) {
                    removeValidationError();
                  }
                }}
                style={{
                  width: '100%',
                  height: '28px',
                  padding: '0',
                }}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                InputProps={{
                  inputComponent: InputFormat,
                  inputProps: {
                    min: 0,
                    mask: maskState && maskState.pattern ? maskDiscriminator({pattern: maskState.pattern}) : maskDiscriminator({type: maskState}),
                  },
                }}
                defaultValue={defaultValue}
              />
              {showValidation && <Tooltip text={showValidation} />}
            </>
          );
        }
        return (
          <>
            <TextField
              onKeyDown={handlePressEnter}
              type="number"
              disabled={disabledEdit}
              id={uuid()}
              variant="outlined"
              required={requiredState}
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              style={{width: '100%', height: '28px', padding: '0'}}
              onInput={(e) => {
                if (extraParams && extraParams.minValue) {
                  if (e.target.value.length <= 0) {
                    setValue(e.target.value);
                  } else if (e.target.value >= extraParams.minValue) {
                    setValue(e.target.value);
                  }
                } else {
                  setValue(e.target.value);
                }
              }}
              defaultValue={defaultValue}
              value={value}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );

      case 'popoveredit':
        return (
          <div>
            <TextField
              onKeyDown={handlePressEnter}
              required={requiredState}
              disabled={disabledEdit}
              id={uuid()}
              type="number"
              variant="outlined"
              style={{width: '100%', height: '28px', padding: '0'}}
              onInput={(e) => {
                setValue(e.target.value);
              }}
              defaultValue={value}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={popoverValue}
                  onChange={(e) => {
                    setPopoverValue(parseInt(e.target.value, 10));
                  }}>
                  {popoverOptions.map((option) => (
                    <FormControlLabel key={option.label} value={option.value} control={<Radio />} label={option.label} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Popover>
          </div>
        );
      case 'booleanedit':
        return (
          <>
            {!disabledEdit ? (
              <Checkbox
                onKeyDown={handlePressEnter}
                required={requiredState}
                disabled={disabledEdit}
                color="primary"
                checked={!!value}
                onChange={(e, newValue) => {
                  setValue(newValue);
                }}
                onBlurCapture={(e, newValue) => {
                  if (requiredState && newValue) {
                    addValidationError('Este campo é obrigatório');
                  } else if (requiredState) {
                    removeValidationError();
                  }
                }}
                inputProps={{'aria-label': 'primary checkbox'}}
              />
            ) : (
              <div style={{width: '42px', height: '42px'}} />
            )}
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'datepicker':
        return (
          <>
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ptBRLocale}>
              <KeyboardDatePicker
                onKeyDown={handlePressEnter}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                required={requiredState}
                format={currentDateFormat || 'dd/MM/yyyy'}
                views={currentDateView || ['date']}
                variant="inline"
                onError={(error) => {
                  if (error?.length > 0) {
                    addValidationError(error);
                  } else {
                    removeValidationError();
                  }
                }}
                disabled={disabledEdit}
                invalidDateMessage="Data inválida"
                value={value || null}
                minDateMessage={extraParams?.minDate && `A data mínima deve ser ${moment(extraParams?.minDate).format('DD-MM-YYYY')} `}
                maxDateMessage={extraParams?.maxDate && `A data máxima deve ser ${moment(extraParams?.maxDate).format('DD-MM-YYYY')} `}
                minDate={
                  // eslint-disable-next-line no-nested-ternary
                  extraParams && extraParams.minDate ? (eval(extraParams.minDate) ? eval(extraParams.minDate) : eval(defaultValue)) : new Date(1970, 1, 1)
                }
                maxDate={
                  // eslint-disable-next-line no-nested-ternary
                  extraParams && extraParams.maxDate ? (eval(extraParams.maxDate) ? eval(extraParams.maxDate) : eval(defaultValue)) : new Date()
                }
                onChange={(e) => {
                  setValue(e);
                  if (requiredState && e?.length <= 0) {
                    addValidationError('Este campo é obrigatório');
                  } else if (requiredState) {
                    removeValidationError();
                  }
                }}
                onBlurCapture={() => {
                  if (requiredState && value?.length <= 0) {
                    addValidationError('Este campo é obrigatório');
                  } else if (requiredState) {
                    removeValidationError();
                  }
                }}
              />
            </MuiPickersUtilsProvider>
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'dropdownedit':
        return (
          <>
            <Autocomplete
              className={field}
              disabled={disabledEdit || disabled}
              id={uuid()}
              groupBy={(optionsValue && optionsValue.length > 0 && groupBy) || (options && options.length > 0 && groupBy) ? (option) => groupBy(option) : null}
              options={optionsValue || options}
              getOptionLabel={(option) => option.label || ''}
              style={{width: '100%', height: '28px', padding: '0'}}
              onChange={(e, newValue) => {
                setValue(newValue);
              }}
              required={requiredState}
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              loading={loading}
              value={value}
              // eslint-disable-next-line no-shadow
              getOptionSelected={(option, {multiple, value}) => {
                if (!multiple) {
                  return option.value === value;
                }
                return false;
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  onKeyDown={handlePressEnter}
                  required={requiredState}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'multipleedit':
        return (
          <>
            <Autocomplete
              multiple
              disabled={disabledEdit || disabled}
              id={uuid()}
              groupBy={(optionsValue && optionsValue.length > 0 && groupBy) || (options && options.length > 0 && groupBy) ? (option) => groupBy(option) : null}
              options={optionsValue || options}
              getOptionLabel={(option) => option.label || ''}
              style={{width: '100%', height: '28px', padding: '0'}}
              onChange={(e, newValue) => {
                setValue(newValue);
              }}
              required={requiredState}
              onBlur={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              loading={loading}
              value={value || []}
              // eslint-disable-next-line no-shadow
              getOptionSelected={(option, {multiple, value}) => {
                if (!multiple) {
                  return option.value === value;
                }
                return false;
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  onKeyDown={handlePressEnter}
                  required={requiredState}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'usernameedit':
        return (
          <>
            <TextField
              onKeyDown={handlePressEnter}
              disabled={disabledEdit}
              id={uuid()}
              required={requiredState}
              variant="outlined"
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              style={{width: '100%', height: '28px', padding: '0'}}
              onInput={(e) => {
                setValue(maskInput({type: 'USERNAME', value: e.target.value}));
              }}
              data-value={value}
              data-field={field}
              value={value}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'weightedit':
        return (
          <>
            <TextField
              onKeyDown={handlePressEnter}
              required={requiredState}
              disabled={disabledEdit}
              id={uuid()}
              variant="outlined"
              style={{width: '100%', height: '28px', padding: '0'}}
              onBlurCapture={(e) => {
                if (requiredState && e.target.value.length <= 0) {
                  addValidationError('Este campo é obrigatório');
                } else if (requiredState) {
                  removeValidationError();
                }
              }}
              InputProps={{
                inputComponent: WeightKgFormat,
              }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  if (parseFloat(e.target.value) > 0) {
                    if (typeof extraParams?.maxValue === 'function') {
                      setValue(extraParams?.maxValue(rowData, parseFloat(e.target.value)));
                    } else if (extraParams?.maxValue && parseFloat(e.target.value) > extraParams?.maxValue) {
                      setValue(extraParams?.maxValue);
                      // setAlertProps(null);
                    } else {
                      setValue(e.target.value);
                    }
                    setShowValidation(null);
                  } else {
                    setShowValidation('Valor inválido');
                  }
                } else {
                  setValue(e.target.value);
                  setShowValidation(null);
                }
              }}
              defaultValue={defaultValue}
              value={value}
            />
            {showValidation && <Tooltip text={showValidation} />}
          </>
        );
      case 'ctabutton':
        return (
          <>
            <Button variant="contained" color="secondary">
              Secondary
            </Button>
          </>
        );
      default:
        return <Cel>{value}</Cel>;
    }
  };

  return (
    <div
      style={{
        width: '100%',
      }}>
      {
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => {
            if (popoverConfig.trigger) {
              if (e.currentTarget.querySelector(`${popoverConfig.trigger}`).contains(e.target)) {
                setAnchorEl(e.currentTarget);
              }
            } else {
              setAnchorEl(e.currentTarget);
            }
          }}>
          {generateInput()}
        </div>
      }
      {popoverOptions && popoverOptions.length ? (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={
            popoverConfig.position || {
              vertical: 'bottom',
              horizontal: 'left',
            }
          }
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}>
          <FormControl component="fieldset">
            <RadioGroup
              value={popoverValue}
              onChange={(e) => {
                setIsOpen(false);
                if (ediTypeState === 'datepicker') {
                  // eslint-disable-next-line eqeqeq
                  if (e.target.value == 'true') {
                    setCurrentDateFormat('MM/yyyy');
                    setCurrentDateView(['month']);
                  } else {
                    setCurrentDateFormat('dd/MM/yyyy');
                    setCurrentDateView(['date']);
                  }
                  setTimeout(() => setIsOpen(true), 1000);
                }
                setPopoverValue(e.target.value);
              }}>
              {popoverOptions.map((option) => (
                <FormControlLabel key={option.label} value={option.value} control={<Radio />} label={option.label} />
              ))}
            </RadioGroup>
          </FormControl>
        </Popover>
      ) : (
        <></>
      )}
    </div>
  );
};
const Tooltip = ({text}) => {
  return <div className=" inputTooltip">{text}</div>;
};
export default EditableHandle;
