import React from 'react';
import DashboardsClimate from './Templates/DashboardClimate';
import DashboardsStock from './Templates/DashboardStock';
import DashboardMachine from './Templates/DashboardMachine';
import DashboardTeam from './Templates/DashboardTeam';
import DashboardFinancials from './Templates/DashboardFinancials';
import DashboardLivestock from './Templates/DashboardLivestock';
import DashboardPeriodical from './Templates/DashboardPeriodical';

const Template = ({templateName, data, page}) => {
  const contentSwitch = () => {
    switch (templateName) {
      case 'climates':
        return data && data.precipitation && data.table ? (
          <DashboardsClimate
            precipitation={data.precipitation}
            table={data.table}
          />
        ) : (
          <>Sem lançamentos</>
        );
      case 'teams':
        return data &&
          data.bar_chart &&
          data.bar_chart.length &&
          data.pie_chart &&
          data.pie_chart.length &&
          data.total_employees !== false ? (
          <DashboardTeam
            totalEmployees={data.total_employees}
            barChart={data.bar_chart}
            pieChart={data.pie_chart}
          />
        ) : (
          <>Sem lançamentos</>
        );
      case 'stocks':
        return data &&
          data.final_stock !== false &&
          data.initial_stock !== false ? (
          <DashboardsStock
            finalStock={data.final_stock}
            initialStock={data.initial_stock}
          />
        ) : (
          <>Sem lançamentos</>
        );
      case 'machines':
        return data &&
          data.start !== false &&
          data.end !== false &&
          data.total !== false &&
          data.table !== false ? (
          <DashboardMachine
            start={data.start}
            end={data.end}
            total={data.total}
            table={data.table}
          />
        ) : (
          <>Sem lançamentos</>
        );
      case 'financials':
        return data &&
          data.table !== false &&
          data.kpi_accumulated !== false &&
          data.kpi_validated !== false &&
          data.bar_chart !== false &&
          data.pie_chart !== false ? (
          <DashboardFinancials
            page={page}
            table={data.table}
            barChart={data.bar_chart}
            pieChart={data.pie_chart}
            kpiAccumulated={data.kpi_accumulated}
            kpiValidated={data.kpi_validated}
          />
        ) : (
          <>Sem lançamentos</>
        );
      case 'livestocks':
        return data &&
          data.table !== false &&
          data.kpi_accumulated !== false &&
          data.kpi_validated !== false &&
          data.bar_chart !== false &&
          data.pie_chart !== false ? (
          <DashboardLivestock
            page={page}
            table={data.table || null}
            barChart={data.bar_chart || null}
            pieChart={data.pie_chart || null}
            monthlyBarChart={data.monthly_bar_chart || null}
            kpiAccumulated={data.kpi_accumulated || null}
            kpiValidated={data.kpi_validated || null}
            gmd={data.gmd || null}
            kpi={data.kpi || null}
          />
        ) : (
          <>Sem lançamentos</>
        );
      case 'periodical':
        return (data &&
          data.title !== false &&
          data.used_area !== false &&
          data.average_herd !== false &&
          data.total_deaths !== false &&
          data.total_arrobas !== false &&
          data.total_kilos !== false &&
          data.mortality_bar_chart !== false &&
          data.gmd_bar_chart !== false &&
          data.stocking_bar_chart !== false &&
          data.stocking_herd_bar_chart !== false &&
          data.global_production_bar_chart !== false) ||
          (data.total_revenue !== false && data.total_expenses !== false) ? (
          <DashboardPeriodical
            page={page}
            title={data.title || null}
            usedArea={data.used_area}
            averageHerd={data.average_herd}
            totalDeaths={data.total_deaths}
            totalArrobas={data.total_arrobas}
            totalKilos={data.total_kilos}
            mortalityBarChart={data.mortality_bar_chart}
            gmdBarChart={data.gmd_bar_chart}
            stockingBarChart={data.stocking_bar_chart}
            stockingHerdBarChart={data.stocking_herd_bar_chart}
            globalProductionBarChart={data.global_production_bar_chart}
            // FINANCIAL
            totalRevenue={data.total_revenue}
            totalExpenses={data.total_expenses}
            costingHeadBarChart={data.costing_head_bar_chart}
            disbursementHeadBarChart={data.disbursement_head_bar_chart}
            costingArrobasBarChart={data.costing_arrobas_bar_chart}
            averageValueBarChart={data.average_value_bar_chart}
            resultArrobasBarChart={data.result_arrobas_bar_chart}
            marginOnSaleBarChart={data.margin_on_sale_bar_chart}
            productiveResultBarChart={data.productive_result_bar_chart}
          />
        ) : (
          <>Sem lançamentos</>
        );
      default:
        return 'Nenhum dado encontrado';
    }
  };

  return <div style={{paddingBottom: '50px'}}>{contentSwitch()}</div>;
};

export default Template;
