/* eslint-disable react/no-danger */
import React, {useState} from 'react';
import {Question} from './styles';

const QuestionItem = ({question, deleteQuestion, handleUpdate, hasPermission = false, hasTag = false}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Question isOpen={isOpen}>
      {hasTag && <h3>{question.category_faq.title}</h3>}
      <div className="question-head">
        <h2>{question.question}</h2>
        <div className="button-box">
          <button type="button" onClick={() => setIsOpen((prev) => !prev)}>
            <i className="fas fa-chevron-down" />
          </button>
          {hasPermission && (
            <>
              <button type="button" onClick={() => handleUpdate(question)}>
                Editar
              </button>
              <button type="button" onClick={() => deleteQuestion(question.id)}>
                Excluir
              </button>
            </>
          )}
        </div>
      </div>
      <div className="question-body" dangerouslySetInnerHTML={{__html: question.answer}} />
    </Question>
  );
};
export default QuestionItem;
