import {getValueObject} from '../../utils/utils';

const validationFindData = (data, field, valueKey) => {
  let validated = true;
  data.forEach((row) => {
    validated =
      validated &&
      getValueObject(row, null, valueKey.replace('value', 'validated'));
  });

  return validated;
};
const columns = (climatesFirstYear, climatesLastYear) => [
  {
    field: climatesFirstYear,
    disableFilter: true,
    subFields: [
      {
        key: climatesFirstYear.toString().concat('.7.value'),
        valueKey: climatesFirstYear.toString().concat('.7.value'),
        field: '7',
        name: 'Jul/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesFirstYear.toString().concat('.8.value'),
        valueKey: climatesFirstYear.toString().concat('.8.value'),
        field: '8',
        name: 'Ago/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesFirstYear.toString().concat('.9.value'),
        valueKey: climatesFirstYear.toString().concat('.9.value'),
        field: '9',
        name: 'Set/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesFirstYear.toString().concat('.10.value'),
        valueKey: climatesFirstYear.toString().concat('.10.value'),
        field: '10',
        name: 'Out/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesFirstYear.toString().concat('.11.value'),
        valueKey: climatesFirstYear.toString().concat('.11.value'),
        field: '11',
        name: 'Nov/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesFirstYear.toString().concat('.12.value'),
        valueKey: climatesFirstYear.toString().concat('.12.value'),
        field: '12',
        name: 'Dez/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
    ],
    name: climatesFirstYear,
    textAlign: 'Center',
  },
  {
    field: climatesLastYear,
    disableFilter: true,
    subFields: [
      {
        key: climatesLastYear.toString().concat('.1.value'),
        valueKey: climatesLastYear.toString().concat('.1.value'),
        field: climatesLastYear.toString().concat('.1.value'),
        name: 'Jan/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesLastYear.toString().concat('.2.value'),
        valueKey: climatesLastYear.toString().concat('.2.value'),
        field: '2',
        name: 'Feb/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesLastYear.toString().concat('.3.value'),
        valueKey: climatesLastYear.toString().concat('.3.value'),
        field: '3',
        name: 'Mar/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesLastYear.toString().concat('.4.value'),
        valueKey: climatesLastYear.toString().concat('.4.value'),
        field: '4',
        name: 'Abr/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesLastYear.toString().concat('.5.value'),
        valueKey: climatesLastYear.toString().concat('.5.value'),
        field: '5',
        name: 'Mai/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
      {
        key: climatesLastYear.toString().concat('.6.value'),
        valueKey: climatesLastYear.toString().concat('.6.value'),
        field: '6',
        name: 'Jun/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        width: '120px',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        extraParams: {
          minValue: 1,
        },
      },
    ],
    name: climatesLastYear,
    textAlign: 'Center',
  },
];

export default columns;
