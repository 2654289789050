// eslint-disable-next-line import/prefer-default-export
export const SYNTHETIC_TABLE = {
  HEADER_TYPES: {
    YEAR: 'YEAR',
    MONTH: 'MONTH',
  },
  LINE_TYPES: {
    MODULE: 'MODULE',
    YEAR: 'YEAR',
  },
};
