import React, {useContext, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {GuardedRoute} from 'react-router-guards';
import SidebarLayout from '../SidebarLayout';
import {DataContext} from '../../utils/DataProvider';
import FilterEnvironment from '../FilterForms/FilterEnvironment';
import {getEnvironmentsTechnical} from '../../utils/helperEnv';
import {FILTER} from '../../utils/constants';
import {history} from '../../utils/utils';

const GuardedFilterRoute = (props) => {
  const {state, dispatch} = useContext(DataContext);

  useEffect(() => {
    (async () => {
      const envs = await getEnvironmentsTechnical(state?.user?.id);
      if (state?.user?.user_type === 'TECHNICAL' && Object.keys(envs).length === 1 && window.location.href === '/') {
        dispatch({
          type: FILTER.ACTION.SAVE_DATA,
          payload: {
            ...state,
            farm: null,
            customer: null,
            environment: Object.keys(envs)[0] === 'DAIRY_CATTLE' ? 'dairy' : 'beef',
          },
        });
      }
      if (
        (state?.farm?.diagnoses === true && history?.location?.pathname?.indexOf('diagnostico') === -1) ||
        (state?.farm?.diagnoses === false && history?.location?.pathname?.indexOf('diagnostico') > -1)
      ) {
        dispatch({
          type: FILTER.ACTION.SAVE_DATA,
          payload: {
            ...state,
            farm: null,
            customer: null,
            environment: Object.keys(envs)[0] === 'DAIRY_CATTLE' ? 'dairy' : 'beef',
          },
        });
      }
    })();
  }, [history?.location?.pathname]);// eslint-disable-line

  return (
    <>
      {state && !state.environment ? (
        <FilterEnvironment />
      ) : (
        <>
          <SidebarLayout>
            {!state.filterOpened ? (
              /* eslint-disable-next-line */
              <GuardedRoute {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/filter-form',
                  state: {referrer: window.location.pathname},
                }}
              />
            )}
          </SidebarLayout>
        </>
      )}
    </>
  );
};

export default GuardedFilterRoute;
