import api from '../api';

const fetchCategoryReferenceCategoryweaning = (params = {}) => {
  return api.get(`/category_reference_category_weaning`, {
    params: {
      ...params,
    },
  });
};

const changeCategoryReferenceCategoryweaning = (id, body = {}) => {
  return api.put(`/category_reference_category_weaning/${id}`, body);
};

const createCategoryReferenceCategoryweaning = (body = {}) => {
  return api.post(`/category_reference_category_weaning`, body);
};
const deleteCategoryReferenceCategoryweaning = (id) => {
  return api.delete(`/category_reference_category_weaning/${id}`);
};

export {
  fetchCategoryReferenceCategoryweaning,
  changeCategoryReferenceCategoryweaning,
  createCategoryReferenceCategoryweaning,
  deleteCategoryReferenceCategoryweaning,
};
