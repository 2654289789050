import styled from 'styled-components';

export const HeadPage = styled.div`
  align-items: center;
  background: #f2f2f2;
  display: flex;
  margin: -25px -50px 50px -25px;
  padding: 15px 25px;

  h2 {
    font-size: 20px;
    margin: 0;
  }

  button {
    align-items: center;
    background: none;
    color: #222;
    display: flex;
    font-size: 14px;
    margin: 0 20px;
    outline: none;
    text-transform: uppercase;

    i {
      font-size: 16px;
      margin-right: 10px;
    }
  }
`;

export const FaqContainer = styled.div`
  /* max-width: 1100px; */
  overflow-x: hidden;
  padding: 25px;
`;

export const Title = styled.h1`
  align-items: center;
  border-bottom: 3px solid #0470df;
  color: #333;
  display: flex;
  font-size: 18px;
  font-weight: 300;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 30px;
  padding-bottom: 7px;
  text-align: center;
  width: 68px;
`;

export const QuestionsBox = styled.div`
  padding: 20px 0;

  h4 {
    margin: 50px 0;
    text-align: center;
  }
`;

export const CategoriesBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 20px 0; */

  h4 {
    margin: 50px 0;
    text-align: center;
  }
`;

export const Question = styled.div`
  margin-bottom: 46px;
  position: relative;

  h3 {
    background: #53aa50;
    border: 1px solid #0c4ca3;
    border-radius: 8px 8px 0 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    min-width: 180px;
    overflow: hidden;
    padding: 4px 10px 4px 10px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: -26.2px;
  }

  .question-head {
    align-content: center;
    background: #0c4ca3;
    display: flex;
    justify-content: space-between;
    padding: 5px;

    h2 {
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    .button-box {
      display: flex;

      button {
        align-items: center;
        background: #fff;
        color: #0c4ca3;
        display: flex;
        margin: 0 3px;
        padding: 5px 10px;

        i {
          transform: ${({isOpen}) => (isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
          transition: ease all 200ms;
        }
      }
    }
  }

  .question-body {
    border: ${({isOpen}) => (isOpen ? '1px solid #ccc' : '0')};
    height: ${({isOpen}) => (isOpen ? '250px' : '0')};
    overflow-y: scroll;
    padding: ${({isOpen}) => (isOpen ? '10px 5px' : '0 5px')};
    transition: ease all 200ms;

    a {
      display: inline-block;
    }
  }
`;

export const Category = styled.div`
  cursor: pointer;
  margin: 20px 50px 20px 50px;

  a {
    max-width: unset;
    text-decoration: none;
  }

  .category-head {
    background: #0c4ca3;
    border-radius: 8px;
    box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    padding: 10px;
    position: relative;
    transition: background 0.2s ease-in;
    width: 90%;

    :hover {
      background: #40b648;
    }

    svg {
      color: #fff;
      font-size: 18px;
      position: absolute;
      right: 10px;
      top: 12px;
    }

    h2 {
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 18px;
      font-weight: 200;
      margin: 0;
    }
  }
`;

export const BgModal = styled.div`
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999999;
`;

export const ModalContent = styled.div`
  background: white;
  box-shadow: 3px 3px 5px #333;
  left: 50%;
  max-width: 1000px;
  padding: 25px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  z-index: 9999999999;

  .close-btn {
    align-items: center;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 50px;
    height: 30px;
    justify-content: center;
    outline: none;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 15px;
    transform: rotate(45deg);
    width: 30px;
  }

  .rdw-editor-toolbar {
    border: 1px solid #ced4da;
  }

  .rdw-editor-main {
    border: 1px solid #ced4da;
  }

  .rdw-editor-main {
    height: 200px;
  }

  .public-DraftEditor-content {
    padding: 0 10px;

    a {
      display: inline-block;
    }
  }

  .rdw-link-modal {
    height: auto;

    .rdw-link-modal-input {
      margin: 0 0 5px;
      height: 35px;
    }

    &-buttonsection {
      display: flex;

      button {
        background: #0c4ca3;
        border-radius: 4px;
        height: unset;
        margin: 0 5px;
        width: unset;
      }
    }
  }
`;
