import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateData} from '../../utils/utils';
import {fetchFarms, registerFarm, changeFarmbyId, deleteFarm} from '../../services/farms';
import {fetchCustomers} from '../../services/customers';
import Breadcrumbs from '../../components/Breadcrumbs';
import {fetchDashboardHarvest} from '../../services/dashboards';

const Farm = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';
  useEffect(() => {
    const loadData = async () => {
      const response = await fetchFarms({
        ...paginationParams,
        ...filterParams,
        'whereinmatch[customer.profile]': ENVS,
      });
      setData(
        response.data.resultList.map((item) => ({
          ...item,
          harvests_platform: item.harvests_platform.map((el) => ({value: el.id, label: el.title})),
          total_harvests: item.total_harvests.map((el) => ({value: el.id, label: el.title})),
        })),
      );
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);// eslint-disable-line
  const columns = [
    {
      key: 'name',
      field: 'name',
      name: 'Código da fazenda',
      textAlign: 'Center',
      editType: 'usernameedit',
      width: '20%',
    },
    {
      key: 'sap_cod',
      field: 'sap_cod',
      name: 'Código SAP',
      required: false,
      width: '15%',
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'customer',
      valueKey: 'customer.name',
      field: 'customer',
      name: 'Código do cliente',
      textAlign: 'Center',
      width: '15%',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCustomers();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.name,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'harvests_platform',
      field: 'harvests_platform',
      required: false,
      name: 'Acesso a lançamentos',
      width: '25%',
      textAlign: 'Center',
      editType: 'multipleedit',
      dependencies: ['customer'],
      optionsFetch: async (d, line) => {
        const lineData = line.initialData.find((item) => item.id === line.currentLine) || line.data;
        if (lineData) {
          const harvestDetail = await fetchDashboardHarvest({
            customer_id: lineData?.customer?.id || lineData?.customer?.value,
          });
          return harvestDetail?.data?.resultList?.map((item) => ({value: item.id, label: item.title})) || [];
        }
        return [];
      },
    },
    {
      key: 'total_harvests',
      field: 'total_harvests',
      required: false,
      name: 'Safras Participantes',
      width: '25%',
      textAlign: 'Center',
      editType: 'multipleedit',
      disabledEdit: true,
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteFarm(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      <Breadcrumbs path={[{title: 'Cadastros', url: '/cadastros'}, {title: 'Fazenda'}]} />
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (dataGridInputs?.harvests_platform?.length) {
              dataGridInputs.harvests_platform = dataGridInputs.harvests_platform.map((item) => ({id: item.value}));
            }
            const res = await createOrUpdateData(dataGridInputs, state, registerFarm, changeFarmbyId, false);
            setReset(!reset);
            return res;
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          width="inherit"
          actions={actions}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default Farm;
