import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import BaseTableAgriculture from './modules/BaseTableAgriculture';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import './style.scss';

const Agriculture = () => {
  const [focused, setFocused] = useState();
  const [bulky, setBulky] = useState();

  useEffect(() => {
    setFocused([]);
    setBulky([]);
  }, []);

  return (
    <div className="agriculture-index">
      <Breadcrumbs
        path={[
          {title: 'Lançamentos', url: '/leite/lancamentos'},
          {title: 'Agricultura', url: null},
        ]}
      />
      <div className="m-2">
        <Link to="/leite/lancamentos/agricultura-cadastro">
          <div className="register-button">Cadastrar</div>
        </Link>
      </div>
      <div className="d-flex">
        <div className="w-50">
          <div className="mx-2">Volumoso</div>
          <BaseTableAgriculture data={focused} type="bulky" />
        </div>
        <div className="w-50">
          <div className="mx-2">Concentrado</div>
          <BaseTableAgriculture data={bulky} type="concentrated" />
        </div>
      </div>
    </div>
  );
};
export default Agriculture;
