import apiNew from '../apiNew';

const fetchBirths = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/births`, {
    params: {
      environment,
      ...params,
    },
  });
};

const fetchBirthsbyId = (id, params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/births/${id}`, {
    params: {environment, ...params},
  });
};

const registerBirths = (body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.post(`/births`, {environment, ...body});
};

const changeBirthsbyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.put(`/births/${id}`, {...body, environment});
};

const deleteBirths = (id) => {
  return apiNew.delete(`/births/${id}`);
};

const fetchBirthsKPI = (params = {}) => {
  return apiNew.get(`/births/graphic_kpi`, {
    params: {
      ...params,
    },
  });
};

const fetchBirthsLine = (params = {}) => {
  return apiNew.get(`/births/graphic_line`, {
    params: {
      ...params,
    },
  });
};

export {fetchBirths, fetchBirthsbyId, registerBirths, changeBirthsbyId, deleteBirths, fetchBirthsKPI, fetchBirthsLine};
