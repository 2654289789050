import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {createOrUpdateData} from '../../../../utils/utils';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {CategoryServices} from '../../../../services/_dairyServices/category-services';

const CategoryBase = ({baseEndpoint, columnsExtra = null, environment = null, extraParams = {}}) => {
  CategoryServices.endpoint = baseEndpoint;
  CategoryServices.environment = environment;
  CategoryServices.extraParams = extraParams;

  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    (async () => {
      const {data: contentData = {}} = await CategoryServices.list({
        ...filterParams,
        ...paginationParams,
      });

      setData(contentData.resultList || []);
      setTotalItems(contentData.size || 0);
    })();
  }, [reset, JSON.stringify(paginationParams).toLowerCase(), JSON.stringify(filterParams).toLowerCase()]);// eslint-disable-line

  const columns = columnsExtra || [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      textAlign: 'Center',
      editType: 'textedit',
    },
  ];

  const actions = [
    {title: 'Adicionar', action: 'add', icon: 'fas fa-plus'},
    {title: 'Editar', action: 'edit', icon: 'fas fa-pencil-alt'},
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await CategoryServices.delete(value.id);
          setReset((prev) => !prev);
          return response;
        } catch (error) {
          return error;
        }
      },
    },
    {title: 'Salvar', action: 'save', icon: 'fas fa-save'},
    {title: 'Cancelar', action: 'cancel', icon: 'fas fa-times'},
  ];

  return (
    <DataGrid
      onChange={async (dataGridInputs) => {
        const response = await createOrUpdateData(dataGridInputs, state, CategoryServices.post, CategoryServices.put, false);
        setReset((prev) => !prev);
        return response;
      }}
      header={columns}
      data={data}
      keyProp="key"
      keyValueProp="valueKey"
      titleProp="name"
      editable=""
      actions={actions}
      totalItems={totalItems}
    />
  );
};

CategoryBase.propTypes = {
  baseEndpoint: PropTypes.string.isRequired,
  environment: PropTypes.string,
  extraParams: PropTypes.object,
  columnsExtra: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      textAlign: PropTypes.string.isRequired,
      editType: PropTypes.string.isRequired,
    }),
  ),
};

export default CategoryBase;
