import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import styled from 'styled-components';
import {FormControl, FormLabel} from '../form';

const Checkbox = ({
  name,
  title,
  tabIndex,
  isRequired,
  checked = false,
  ...el
}) => {
  const [check, setCheck] = useState(checked);

  const handleChange = () => {
    setCheck((prev) => !prev);
    if (el.setFieldValue) {
      if (isRequired) el.setFieldValue(name, check ? '' : true);
      else el.setFieldValue(name, !check);
    }
  };

  return (
    <FormControl style={{marginBottom: 15}}>
      {title && (
        <FormLabel htmlFor={name} onClick={handleChange}>
          <Field name={name}>
            {({field}) => (
              <input
                type="checkbox"
                {...field}
                tabIndex={tabIndex}
                checked={check}
              />
            )}
          </Field>
          {typeof title === 'object' ? (
            title
          ) : (
            <Span dangerouslySetInnerHTML={{__html: title}} />
          )}
        </FormLabel>
      )}
    </FormControl>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  tabIndex: PropTypes.number,
  isRequired: PropTypes.bool,
  checked: PropTypes.bool,
};

export default Checkbox;

const Span = styled.span`
  color: #313131 !important;
`;
