import React from 'react';
import {Field} from 'formik';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import {FormInput} from '../form';

const InputMasked = ({
  name,
  mask,
  title,
  placeholder,
  isRequired,
  tabIndex,
  ...el
}) => {
  return (
    <Field name={name}>
      {({field}) => (
        <InputMask
          {...field}
          {...el}
          placeholder={placeholder || ''}
          tabIndex={tabIndex}
          maskChar=""
          mask={mask}
          onChange={(e) => {
            if (el.onChange) {
              el.onChange({
                name,
                value: e.target.value,
              });
            }
            if (el.setFieldValue) {
              el.setFieldValue(name, e.target.value);
            }
          }}>
          {(inputProps) => <FormInput {...inputProps} />}
        </InputMask>
      )}
    </Field>
  );
};

InputMasked.propTypes = {
  mask: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default InputMasked;
