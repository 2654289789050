import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  environment: 'DAIRY_CATTLE',
};

export const DeathServices = {
  endpoint: '/deaths',

  list: async (params = {}) => ServicesHelper.list(DeathServices.endpoint, {...params, ...PARAMS}),

  post: async (body = {}) => ServicesHelper.post(DeathServices.endpoint, {...body, ...PARAMS}),

  put: async (id, body = {}) => ServicesHelper.put(DeathServices.endpoint.concat('/', id), {...body, ...PARAMS}),

  delete: async (id) => ServicesHelper.delete(DeathServices.endpoint.concat('/', id)),

  categoryAnimal: async (params = {}) => ServicesHelper.loadCategory('/category_livestock', {...params, ...PARAMS}),

  categoryDeathsReason: async (params = {}) => ServicesHelper.loadCategory('/category_deaths_reason', params),
};
