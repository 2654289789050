import React, {useContext, useState} from 'react';
import {Card} from 'reactstrap';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../components/Section/Section';
import BenchmarkDashboard from './BenchmarkDashboard';
import UpdateBenchMark from './UpdateBenchMark';
import BenchmarkScoreboard from './BenchmarkScoreboard';
import BenchmarkScoreboardInvidual from './BenchmarkScoreboardInvidual';
import DairyBenchmarking from '../DairyPages/Benchmark';
import {DataContext} from '../../utils/DataProvider';

const Benchmarking = ({match}) => {
  const {state} = useContext(DataContext);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  const [templateData] = useState([
    {
      category: 'Benchmarking',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Avaliação do indicadores do Benchmarking',
              url: `${match.url}/avaliacao-benchmarking`,
            },
            {
              title: 'Placar do Benchmarking Geral',
              url: `${match.url}/placar-benchmarking`,
            },
            {
              title: 'Placar do Benchmarking Individual',
              url: `${match.url}/placar-benchmarking-individual`,
            },
            {
              title: 'Acesse para atualização dos indicadores de uma safra',
              url: `${match.url}/update-benchmarking`,
            },
          ],
        },
      ],
    },
  ]);
  const [templateDataDairy] = useState([
    {
      category: 'Benchmarking',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Avaliação do indicadores do Benchmarking',
              url: `${match.url}/avaliacao-benchmarking`,
            },
            // {
            //   title: 'Placar do Benchmarking Geral',
            //   url: `${match.url}/placar-benchmarking`,
            // },
            {
              title: 'Placar do Benchmarking Geral / Individual',
              url: `${match.url}/placar-benchmarking-dairy`,
            },
            // {
            //   title: 'Placar do Benchmarking Individual',
            //   url: `${match.url}/placar-benchmarking-individual`,
            // },
            {
              title: 'Acesse para atualização dos indicadores de uma safra',
              url: `${match.url}/update-benchmarking`,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className="Benchmarking h-100">
      <Card className="pt-0 overflow-auto h-100">
        <GuardedRoute
          exact
          path={match.url}
          meta={{moduleName: 'Configuração'}}
          render={() => <Section templateData={ENVS === 'DAIRY_CATTLE' ? templateDataDairy : templateData} />}
        />
        <GuardedRoute path={`${match.url}/avaliacao-benchmarking`} meta={{moduleName: 'Benchmarking'}} component={BenchmarkDashboard} />
        <GuardedRoute path={`${match.url}/placar-benchmarking`} meta={{moduleName: 'Benchmarking'}} component={BenchmarkScoreboard} />
        <GuardedRoute path={`${match.url}/placar-benchmarking-dairy`} meta={{moduleName: 'Benchmarking'}} component={DairyBenchmarking} />
        <GuardedRoute path={`${match.url}/placar-benchmarking-individual`} meta={{moduleName: 'Benchmarking'}} component={BenchmarkScoreboardInvidual} />
        <GuardedRoute path={`${match.url}/update-benchmarking`} meta={{moduleName: 'Benchmarking'}} component={UpdateBenchMark} />
      </Card>
    </div>
  );
};
export default Benchmarking;
