import styled from 'styled-components';

export const TableContainer = styled.div`
  -webkit-print-color-adjust: exact;
`;
export const TableTitle = styled.div`
  font-family: sans-serif;
  font-size: 1.3em;
  font-weight: 400;
  font-weight: 600;
  margin: 25px 0 10px;
  text-align: center;
  text-align: center;
`;
export const TableRows = styled.div`
  width: auto;
`;
export const TableColumn = styled.div`
  -webkit-print-color-adjust: exact;
  text-align: center;
`;
export const TableRow = styled.div`
  -webkit-print-color-adjust: exact;
  background-color: white;
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplate ? props.gridTemplate : '1fr'};
  &:nth-child(2n) {
    background-color: #f5f5f5;
  }
  text-align: left;
  & .header {
    text-align: left;
  }
  & .cel {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 100;
    text-align: left;
  }
`;
