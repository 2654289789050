import apiNew from '../apiNew';

const fetchWeaning = (params = {}) => {
  return apiNew.get(`/weaning`, {
    params: {
      ...params,
    },
  });
};

const fetchWeaningbyId = (id, params = {}) => {
  return apiNew.get(`/weaning/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerWeaning = (body = {}) => {
  return apiNew.post(`/weaning`, body);
};

const changeWeaningbyId = (id, body = {}) => {
  return apiNew.put(`/weaning/${id}`, body);
};

const deleteWeaning = (id) => {
  return apiNew.delete(`/weaning/${id}`);
};

const fetchWeaningKPI = (params = {}) => {
  return apiNew.get(`/weaning/graphic_kpi`, {
    params: {
      ...params,
    },
  });
};
const fetchWeaningLine = (params = {}) => {
  return apiNew.get(`/weaning/graphic_line`, {
    params: {
      ...params,
    },
  });
};
export {
  fetchWeaning,
  fetchWeaningbyId,
  registerWeaning,
  changeWeaningbyId,
  deleteWeaning,
  fetchWeaningKPI,
  fetchWeaningLine,
};
