// import {ServicesHelper} from '../../services/ServicesHelper';

// const PARAMS = {};

// export const TechnicalInformationServices = {
//   endpoint: '/technical_information',

//   list: async (params = {}) => ServicesHelper.list(TechnicalInformationServices.endpoint, {...params, ...PARAMS}),

//   post: async (body = {}) => ServicesHelper.post(TechnicalInformationServices.endpoint, {...body, ...PARAMS}),

//   put: async (id, body = {}) => ServicesHelper.put(TechnicalInformationServices.endpoint.concat('/', id), {...body, ...PARAMS}),

//   delete: async (id) => ServicesHelper.delete(TechnicalInformationServices.endpoint.concat('/', id)),

//   loadCategory: async (endpoint, params = {}) => ServicesHelper.loadCategory(endpoint, params),
// };
import {DairyHelpers} from '../../../utils/dairyHelpers';
import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  // environment: 'DAIRY_CATTLE',
};

export const TechnicalInformationServices = {
  endpoint: '/technical_information',

  list: async (params = {}, {first_year, last_year}) => {
    const {data = {}} = await ServicesHelper.list(TechnicalInformationServices.endpoint, {...params, ...PARAMS});

    const categories = await TechnicalInformationServices.loadCategory('/category_zootechnical_case');

    return {
      categories,
      data: {
        resultList: TechnicalInformationServices.mapper(data?.resultList, categories, first_year, last_year),
        size: data.size,
      },
    };
  },

  prepareToSave: (body) => {
    const valuesWithKeys = [];
    Object.keys(body).forEach((key) => {
      if (key.split('.').length - 1 === 2) {
        valuesWithKeys.push(key);
      }
    });
    const data = [];
    valuesWithKeys.forEach((item) => {
      const arrKeys = item.split('.');
      const accessKey = `${arrKeys[0]}.${arrKeys[1]}`;
      const id = DairyHelpers.resolve(`${accessKey}.id`, body);
      const month = `0${arrKeys[1]}`.slice(-2);
      data.push({
        category_zootechnical_case: {id: body.id},
        customer: {id: body.customer.id},
        farm: {id: body.farm.id},
        harvest: {id: body.harvest.id},
        // eslint-disable-next-line no-restricted-globals
        quantity: Number(body[item]) != null && !isNaN(Number(body[item])) ? Number(body[item]) : null,
        date: id ? DairyHelpers.resolve(`${accessKey}.date`, body) : `01/${month}/${arrKeys[0]} 00:00:00`,
        // ...(id && {id}),
        ...(!!body.validated && {validated: body.validated}),
        created_by: {id: body.currentUser},
        updated_by: {id: body.currentUser},
      });
    });
    return data;
  },

  post: async (body = {}) => ServicesHelper.post(TechnicalInformationServices.endpoint, {...body, ...PARAMS}),

  delete: async (id) => ServicesHelper.delete(TechnicalInformationServices.endpoint.concat('/', id)),

  put: async (id, body = {}) => {
    const sanitizeBody = TechnicalInformationServices.prepareToSave(body);
    return ServicesHelper.post(TechnicalInformationServices.endpoint.concat('/batch'), sanitizeBody);
  },

  loadCategory: async (endpoint, params = {}) => ServicesHelper.loadCategory(endpoint, params),

  getValueMapper: (data, year, month) => {
    const months = ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const currentMonth = `0${month}`.slice(-2).concat('/', year);
    const label = `${months[month]}/${year}`;

    const line = data.find((item) => item.date.slice(3, 10) === currentMonth);
    return {
      label,
      ...{
        ...(line?.id && {id: line.id}),
        date: line?.date || null,
        quantity: line?.quantity != null ? line?.quantity : null,
        validated: line?.validated || null,
      },
    };
  },

  mapper: (data, categories, first_year, last_year) => {
    return categories.map((cat) => {
      const filteredData = data.filter((item) => item.category_zootechnical_case.id === cat.value);

      return {
        [first_year]: {
          7: TechnicalInformationServices.getValueMapper(filteredData, first_year, 7),
          8: TechnicalInformationServices.getValueMapper(filteredData, first_year, 8),
          9: TechnicalInformationServices.getValueMapper(filteredData, first_year, 9),
          10: TechnicalInformationServices.getValueMapper(filteredData, first_year, 10),
          11: TechnicalInformationServices.getValueMapper(filteredData, first_year, 11),
          12: TechnicalInformationServices.getValueMapper(filteredData, first_year, 12),
        },
        [last_year]: {
          1: TechnicalInformationServices.getValueMapper(filteredData, last_year, 1),
          2: TechnicalInformationServices.getValueMapper(filteredData, last_year, 2),
          3: TechnicalInformationServices.getValueMapper(filteredData, last_year, 3),
          4: TechnicalInformationServices.getValueMapper(filteredData, last_year, 4),
          5: TechnicalInformationServices.getValueMapper(filteredData, last_year, 5),
          6: TechnicalInformationServices.getValueMapper(filteredData, last_year, 6),
        },
        id: cat.value,
        title: cat.label,
      };
    });
  },
};
