import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../../../utils/DataProvider';
import {BirthsServices} from '../services/BirthsServices';
import {DatePickerHelper} from '../../../utils/datePickerHelper';
import {MASKS, ROLES} from '../../../../../utils/constants';
import {DatagridColumnsHelper} from '../../../utils/datagridColumnsHelper';
import {DatagridActionsHelper} from '../../../utils/datagridActionsHelper';
import DataGrid from '../../../../../components/DataGrid';
import {createOrUpdateDataFull, maskInput} from '../../../../../utils/utils';

const Births = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await BirthsServices.list({
          ...stateFetchParams,
          ...filterParams,
        });
        const {data: currentData} = response;

        setData(currentData.resultList);
        setTotal(currentData.size);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [reset, JSON.stringify(stateFetchParams).toLowerCase(), JSON.stringify(filterParams).toLowerCase()]);// eslint-disable-line

  const validationFindData = (temp) => {
    const validates = temp.map((item) => item.validated);
    return validates.length && validates.includes(true);
  };

  const handleValidateCallback = async (rows, field, checked) => {
    const promises = [];
    rows.forEach((row) => {
      if (row.notCreated) return;

      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, BirthsServices.post, BirthsServices.put));
    });

    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  const handleChange = async (inputs) => {
    if (!inputs.date) {
      const date = DatePickerHelper.newDate();
      Object.assign(inputs, {date});
    }

    if (inputs?.sex?.id) {
      Object.assign(inputs, {sex: inputs.sex.title});
    }

    if (inputs?.validated == null) {
      Object.assign(inputs, {validated: false});
    }

    Object.assign(inputs, {status: false});

    try {
      return await createOrUpdateDataFull(inputs, state, BirthsServices.post, BirthsServices.put);
    } catch (error) {
      return error;
    } finally {
      setReset(!reset);
    }
  };

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '30%',
      ...DatePickerHelper.dailyAndMonth(state),
    },
    {
      key: 'sex',
      valueKey: 'sex',
      field: 'sex',
      width: state.user.user_type !== ROLES.CUSTOMER ? '30%' : '40%',
      required: true,
      name: 'Sexo',
      textAlign: 'Center',
      ...DatagridColumnsHelper.gender(),
    },
    {
      key: 'amount',
      field: 'amount',
      required: true,
      name: 'Quantidade',
      width: '30%',
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      width: '10%',
      textAlign: 'Center',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = DatagridActionsHelper.actions({
    handleDelete: async (value) => {
      try {
        const response = await BirthsServices.delete(value.id);
        setReset(!reset);
        return response;
      } catch (error) {
        return error;
      }
    },
  });

  return (
    <>
      {stateMessage}
      {data && (
        <DataGrid
          onChange={handleChange}
          validateCallback={handleValidateCallback}
          header={columns}
          data={data}
          width="inherit"
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={total}
          accumulators={{
            'Total geral': {
              columns: ['amount'],
              itemOperation: (acc, line, key) => (acc += line[key]),
              resultOperation: (acc) => {
                return maskInput({value: acc, type: MASKS.DOUBLE});
              },
            },
          }}
        />
      )}
    </>
  );
};

export default Births;
