import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateDataFull} from '../../utils/utils';
import {
  fetchCashAnalysisDatagrid,
  registerCashAnalysis,
  changeCashAnalysisbyId,
  deleteCashAnalysis,
} from '../../services/cashAnalysis';
import PrimaryFilterDisplay from '../../components/PrimaryFilterDisplay/PrimaryFilterDisplay';
import PrimaryFilterForm from '../../components/FilterForms/FilterSettings';
import {FILTER} from '../../utils/constants';

const CashAnalysis = () => {
  const {state, dispatch, stateFetchParamsCustom} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [cashAnalysis, setCashAnalysis] = useState();

  useEffect(() => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  }, []);// eslint-disable-line

  useEffect(() => {
    const loadData = async () => {
      if (!state.harvest || !state.customer || !state.farm) {
        return;
      }
      const response = await fetchCashAnalysisDatagrid(stateFetchParamsCustom);

      const cashTemp = {
        membership_contribution: null,
        account_balance_applications_initial: null,
        account_balance_applications_final: null,
        harvest: state.harvest,
        customer: state.customer,
        farm: state.farm,
        created_by: state.user,
      };

      const normalizedData = response.data.map((item, i) => {
        item.id = i + 1;
        const category = {
          title: item.category,
        };
        item.category = category;

        switch (item.label) {
          case 'Aporte de sócios':
            cashTemp.membership_contribution = item.data;
            break;
          case 'Saldo em conta + aplicações - 01/07':
            cashTemp.account_balance_applications_initial = item.data;
            break;
          case 'Endividamento - 01/07':
            cashTemp.account_balance_applications_final = item.data;
            break;
          default:
        }

        return item;
      });

      setCashAnalysis(cashTemp);
      setData(normalizedData);
      setTotalItems(response.data.length);
    };

    loadData();
  }, [reset, state, stateFetchParamsCustom]);

  const columns = [
    {
      key: 'description',
      field: 'description',
      name: 'Descrição',
      disableFilter: true,
      subFields: [
        {
          key: 'category',
          valueKey: 'category.title',
          field: 'category',
          name: 'Descrição',
          textAlign: 'Center',
          editType: 'defaultedit',
          disableFilter: true,
          groupTitle: (title) => {
            return title;
          },
        },
        {
          key: 'label',
          valueKey: 'label',
          field: 'label',
          name: 'Categoria',
          textAlign: 'Center',
          editType: 'defaultedit',
          disabledEdit: true,
          disableFilter: true,
        },
      ],
    },
    {
      key: 'data',
      field: 'data',
      name: 'Total',
      textAlign: 'Center',
      editType: 'moneyedit',
      // accumulatorItem: (acc, item) => acc + item,
      disableFilter: true,
      extraParams: {
        minValue: 1,
      },
    },
  ];

  const actions = [
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          switch (value.label) {
            case 'Aporte de sócios':
              cashAnalysis.membership_contribution = -1;
              break;
            case 'Saldo em conta + aplicações - 01/07':
              cashAnalysis.account_balance_applications_initial = -1;
              break;
            case 'Endividamento - 01/07':
              cashAnalysis.account_balance_applications_final = -1;
              break;
            default:
          }
          const response = await deleteCashAnalysis(cashAnalysis);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {state.filterOpened && <PrimaryFilterForm />}
      {!state.filterOpened && data && (
        <>
          <PrimaryFilterDisplay />
          <DataGrid
            onChange={async (dataGridInputs) => {
              switch (dataGridInputs.label) {
                case 'Aporte de sócios':
                  cashAnalysis.membership_contribution = dataGridInputs.data;
                  break;
                case 'Saldo em conta + aplicações - 01/07':
                  cashAnalysis.account_balance_applications_initial =
                    dataGridInputs.data;
                  break;
                case 'Endividamento - 01/07':
                  cashAnalysis.account_balance_applications_final =
                    dataGridInputs.data;
                  break;
                default:
              }
              const response = await createOrUpdateDataFull(
                cashAnalysis,
                state,
                registerCashAnalysis,
                changeCashAnalysisbyId,
              );
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
            groupBy="category"
            disabledPaginate
            maxHeight="100%"
          />
        </>
      )}
    </>
  );
};

export default CashAnalysis;
