import api from '../api';

const fetchIgpdi = (params = {}) => {
  return api.get(`/igpdi`, {
    params: {
      ...params,
    },
  });
};

const fetchIgpdibyId = (id, params = {}) => {
  return api.get(`/igpdi/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerIgpdi = (body = {}) => {
  return api.post(`/igpdi`, body);
};

const changeIgpdibyId = (id, body = {}) => {
  return api.put(`/igpdi/${id}`, body);
};

const deleteIgpdi = (id) => {
  return api.delete(`/igpdi/${id}`);
};

export {fetchIgpdi, fetchIgpdibyId, registerIgpdi, changeIgpdibyId, deleteIgpdi};
