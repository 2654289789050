import React from 'react';
import './style.scss';
import {ButtonGroup, ButtonGroupItem, MenuContainer, ButtonsContainer, Button} from './styled';
import Menu from './Menu';

const Section = ({templateData, children}) => {
  return (
    <>
      <div className="control-pane" id="section-menu" style={{height: 'auto'}}>
        <div className="control-section">
          {templateData && templateData.length > 0 && (
            <div className="menu-section">
              <div className="menu-control" style={{width: '100%'}} />
              <ButtonGroup>
                <>
                  <ButtonGroupItem>
                    <ButtonsContainer>
                      <Button
                        className="main-btn"
                        style={{borderBottom: '3px solid #0470df'}}
                        type="button"
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        onClick={() => {}}>
                        Menu
                      </Button>
                    </ButtonsContainer>
                    <MenuContainer className="menu-container">
                      {templateData.map((menuItem) => (
                        <Menu key={menuItem.category} url={menuItem.url ? menuItem.url : null} title={menuItem.category} options={menuItem.options} />
                      ))}
                    </MenuContainer>
                  </ButtonGroupItem>
                </>
              </ButtonGroup>
            </div>
          )}
        </div>
      </div>
      {children}
    </>
  );
};

export default Section;
