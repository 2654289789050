import api from '../api';
import apiNew from '../apiNew';

const fetchCategoryLivestock = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/category_livestock`, {//TODO
    params: {environment, ...params},
  });
};

const fetchCategoryLivestockbyId = (id, params = {}, environment = 'BEEF_CATTLE') => {
  return api.get(`/category_livestock/${id}`, {
    params: {environment, ...params},
  });
};

const registerCategoryLivestock = (body = {}, environment = 'BEEF_CATTLE') => {
  return api.post(`/category_livestock`, {environment, ...body});
};

const changeCategoryLivestockbyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return api.put(`/category_livestock/${id}`, {environment, ...body});
};

const deleteCategoryLivestock = (id) => {
  return api.delete(`/category_livestock/${id}`);
};

export {fetchCategoryLivestock, fetchCategoryLivestockbyId, registerCategoryLivestock, changeCategoryLivestockbyId, deleteCategoryLivestock};
