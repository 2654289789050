import api from '../api';

const fetchCategoryFaq = (params = {}) => {
  return api.get(`/category_faq`, {
    params: {
      ...params,
      'order[ordenation]': 'ASC',
    },
  });
};

const createCategoryFaq = (body = {}) => {
  return api.post(`/category_faq`, body);
};

const updateCategoryFaq = (id, body = {}) => {
  return api.put(`/category_faq/${id}`, body);
};

const deleteCategoryFaq = (id) => {
  return api.delete(`/category_faq/${id}`);
};

export {createCategoryFaq, fetchCategoryFaq, updateCategoryFaq, deleteCategoryFaq};
