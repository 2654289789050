const validationFindData = (data) => {
  let validated = true;
  data.forEach((row) => {
    validated = validated && row.validated;
  });

  return validated;
};

const generalColumns = [
  {
    key: 'frente_de_trabalho_e_funcao',
    field: 'frente_de_trabalho_e_funcao',
    name: 'Frente de trabalho e função',
    disableFilter: true,
    subFields: [
      {
        key: 'category_work_front',
        valueKey: 'category_work_front.title',
        field: 'category_work_front',
        name: 'Frente de trabalho',
        disableFilter: true,
        textAlign: 'Center',
        editType: 'defaultedit',
        groupTitle: (data) => {
          return `Frente de trabalho: ${data}`;
        },
      },
      {
        key: 'category_function',
        valueKey: 'category_function.title',
        field: 'category_function',
        name: 'Função',
        disableFilter: true,
        textAlign: 'Center',
        editType: 'defaultedit',
        disabledEdit: true,
      },
    ],
  },
  {
    key: 'amount',
    field: 'amount',
    disableFilter: true,
    name: 'Quantidade',
    required: true,
    textAlign: 'Center',
    editType: 'numericedit',
    // extraParams: {
    //   minValue: 1,
    // },
    accumulatorItem: (acc, item) => acc + item,
  },
  {
    key: 'salario_medio_mensal',
    field: 'salario_medio_mensal',
    disableFilter: true,
    name: 'Salário médio mensal (R$)',
    subFields: [
      {
        key: 'average_salary',
        field: 'average_salary',
        name: 'Holerite',
        disableFilter: true,
        textAlign: 'Center',
        editType: 'moneyedit',
        accumulatorItem: (acc, item) => acc + item,
      },
      {
        key: 'average_salary_benefit',
        field: 'average_salary_benefit',
        name: 'Com benefícios',
        disableFilter: true,
        textAlign: 'Center',
        editType: 'moneyedit',
        accumulatorItem: (acc, item) => acc + item,
      },
    ],
  },
  {
    key: 'validated',
    field: 'validated',
    disableFilter: true,
    name: 'Validar',
    width: '75px',
    textAlign: 'Center',
    editType: 'booleanedit',
    validationHeader: {showLabel: false, validationFindData},
  },
];

export default generalColumns;
