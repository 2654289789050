import React, {useState, useEffect} from 'react';
import '../style.scss';
import FilterMonthly from './filter';
import Loading from '../../../../components/Loading/Loading';
import {fetchReportMonthlyItems, fetchReportMonthly, fetchReportMonthlyNotValidated} from '../../../../services/dairyReports';
import {dataFormatting} from '../../../../utils/formateMoney';

const MonthlyReport = () => {
  const [loading, setLoading] = useState(true);
  const [colSpanDates, setColSpanDates] = useState(0);

  const [customer, setCustomer] = useState(null);
  const [harvest, setHarvest] = useState(null);
  const [notValidated, setNotValidated] = useState(null);
  const [farmsList, setFarmsList] = useState(null);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [templateYears, setTemplateYears] = useState([]);
  const [templateDates, setTemplateDates] = useState([]);

  const [templateCategories, setTemplateCategories] = useState({
    receitas: [],
    despesas: [],
    totais: [],
  });
  const [templateData, setTemplateData] = useState(null);

  const getGridValues = (categories, data) => {
    const values = [];
    categories.forEach((cat) => {
      const line = [];
      data.forEach((item) => {
        const byId = item.values.filter((val) => val.id === cat.id);

        if (byId.length === 1) {
          line.push({
            id: byId.length ? byId[0].id : '',
            value: byId.length ? byId[0].value : '',
          });
        } else {
          line.push({
            id: '',
            value: 0,
          });
        }
      });
      values.push(line);
    });
    return values;
  };

  useEffect(() => {
    (async () => {
      try {
        if (!!customer && !!harvest && !!farmsList && !!initialDate && !!finalDate) {
          const initDate = `${initialDate.year}-${
            initialDate.month < 9 ? `0${parseInt(initialDate.month, 10) + 1}` : `${parseInt(initialDate.month, 10) + 1}`
          }`;
          const finishDate = `${finalDate.year}-${finalDate.month < 9 ? `0${parseInt(finalDate.month, 10) + 1}` : `${parseInt(finalDate.month, 10) + 1}`}`;

          const dataNotValidated = await fetchReportMonthlyNotValidated({
            customer_id: customer.id,
            harvest_id: harvest.id,
            farm_id: farmsList,
            date_start: initDate,
            date_end: finishDate,
          });
          const {data} = await fetchReportMonthly({
            customer_id: customer.id,
            harvest_id: harvest.id,
            farm_id: farmsList,
            date_start: initDate,
            date_end: finishDate,
          });
          setNotValidated(dataNotValidated.data);
          setColSpanDates(data.length);

          const resCategories = await fetchReportMonthlyItems();
          setTemplateCategories(resCategories.data);
          setTemplateCategories({
            Geral: resCategories.data.filter((cat) => cat.type === 'Geral'),
            Produtivo: resCategories.data.filter((cat) => cat.type === 'Produtivo'),
            Financeiro: resCategories.data.filter((cat) => cat.type === 'Financeiros'),
            Qualidade: resCategories.data.filter((cat) => cat.type === 'Qualidade'),
            Zootécnico: resCategories.data.filter((cat) => cat.type === 'Zootécnicos'),
          });

          const dates = [];
          const years = [];

          data.forEach((item) => {
            dates.push(item.date);
            if (item.date !== 'Total') {
              years.push(item.date.split('/')[1]);
            }
          });
          setTemplateDates(dates);

          const gridYears = [];
          years
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach((item) => {
              gridYears.push({
                colSpan: years.filter((qtd) => qtd === item).length,
                year: item,
              });
            });
          setTemplateYears(gridYears);

          if (data.length)
            setTemplateData({
              Produtivo: getGridValues(
                resCategories.data.filter((cat) => cat.type === 'Produtivo'),
                data,
              ),
              Geral: getGridValues(
                resCategories.data.filter((cat) => cat.type === 'Geral'),
                data,
              ),
              Financeiro: getGridValues(
                resCategories.data.filter((cat) => cat.type === 'Financeiros'),
                data,
              ),
              Qualidade: getGridValues(
                resCategories.data.filter((cat) => cat.type === 'Qualidade'),
                data,
              ),
              Zootécnico: getGridValues(
                resCategories.data.filter((cat) => cat.type === 'Zootécnicos'),
                data,
              ),
            });

          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [submitted]);// eslint-disable-line

  if (!submitted) {
    return (
      <FilterMonthly
        setCustomer={setCustomer}
        setHarvest={setHarvest}
        setFarmsList={setFarmsList}
        setInitialDate={setInitialDate}
        setFinalDate={setFinalDate}
        setSubmitted={setSubmitted}
      />
    );
  }

  return (
    <>
      <div className="head-page">
        <button type="button">
          <i className="fas fa-table" />
          <h2>Relatório mensal - {customer.name}</h2>
        </button>
      </div>

      {!loading ? (
        <>
          {templateData ? (
            <div className="table-container">
              <div className="cat-list">
                <table>
                  <tbody>
                    {notValidated ? (
                      <div className="phrase-not-validated">
                        <h3>Atenção! Existem dados não validados.</h3>
                      </div>
                    ) : (
                      <>
                        <tr />
                        <tr />
                      </>
                    )}
                    {Object.keys(templateCategories).map((key) => (
                      <>
                        <tr>
                          <td className="cat-monthly-title">{key}</td>
                        </tr>
                        {templateCategories[key].map((cat, i) => (
                          <tr key={i} className={key === 'totais' && 'totalLine categories'}>
                            <td>{cat.description}</td>
                          </tr>
                        ))}
                        {key !== 'Zootécnico' && <tr />}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      {templateYears.map((year, i) => (
                        <td key={i} colSpan={year.colSpan} className="bold">
                          {year.year}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {templateDates.map((date, i) => (
                        <td key={i} className="bold">
                          {date}
                        </td>
                      ))}
                    </tr>
                    {Object.keys(templateCategories).map((key) => (
                      <>
                        <tr>
                          <td colSpan={colSpanDates} className="cat-monthly-title" />
                        </tr>
                        {templateData[key].map((line, index) => (
                          <tr key={index}>
                            {line.map((cel, i) => (
                              <td key={i} className={cel.id}>
                                {dataFormatting(cel)}
                              </td>
                            ))}
                          </tr>
                        ))}
                        {key !== 'Zootécnico' && <tr />}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <h3 className="no-data">Nenhum resultado encontrado</h3>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MonthlyReport;
