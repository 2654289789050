const machineColumns = (harvestFirstYear, harvestLastYear) => [
  {
    key: 'brand',
    field: 'brand',
    name: 'Marca',
    textAlign: 'Center',
    width: '20%',
    editType: 'defaultedit',
  },
  {
    key: 'model',
    field: 'model',
    name: 'Modelo',
    textAlign: 'Center',
    width: '30%',
    editType: 'defaultedit',
  },
  {
    key: 'amount',
    field: 'amount',
    name: 'Quantidade',
    textAlign: 'Center',
    width: '10%',
    editType: 'numericedit',
    extraParams: {
      minValue: 1,
    },
  },
  {
    key: 'unitary_value',
    field: 'unitary_value',
    name: 'Valor unitário',
    textAlign: 'Center',
    width: '20%',
    editType: 'moneyedit',
  },
  {
    field: 'maquina_presente_inicio_fim_safra',
    disableFilter: true,
    subFields: [
      {
        key: 'start_harvest',
        field: 'start_harvest',
        name: `01/07/${harvestFirstYear}`,
        textAlign: 'Center',
        width: '10%',
        editType: 'booleanedit',
      },
      {
        key: 'end_harvest',
        field: 'end_harvest',
        name: `30/06/${harvestLastYear}`,
        textAlign: 'Center',
        width: '10%',
        editType: 'booleanedit',
      },
    ],
    name: 'Máquina presente início/fim safra',
    textAlign: 'Center',
  },
];

export default machineColumns;
