/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const AutoComplete2 = ({el}) => {
  const {name, options, required = false} = el;
  const extraParams = {};

  if (el.multiple) {
    Object.assign(extraParams, {
      multiple: true,
      limitTags: el.limitTags ? el.limitTags : 4,
    });
  }

  React.useEffect(() => {
    if (el.defaultValue) {
      el.setFieldValue(name, el.defaultValue?.value || '');
    }
  }, []);// eslint-disable-line

  return (
    <Autocomplete
      name={name}
      id={name}
      required={required}
      options={options}
      defaultValue={el.defaultValue || []}
      onChange={(e, value) => {
        if (el.onChange) {
          el.onChange(value);
        }
        if (el.setFieldValue) {
          el.setFieldValue(name, value?.value || '');
        }
      }}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, {multiple, value}) => {
        if (multiple) return false;
        return option.value === value;
      }}
      includeInputInList
      {...extraParams}
      renderInput={(params) => <TextField {...params} label={el.placeholder || el.label} margin="normal" variant="outlined" />}
    />
  );
};

export default AutoComplete2;
