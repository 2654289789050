import api from '../api';

const fetchCostCenters = (params = {}, environment = 'BEEF_CATTLE') => {
  return api.get(`/cost_centers`, {
    params: {environment, ...params},
  });
};

const fetchCostCentersbyId = (id, params = {}, environment = 'BEEF_CATTLE') => {
  return api.get(`/cost_centers/${id}`, {
    params: {environment, ...params},
  });
};
const registerCostCenters = (body = {}, environment = 'BEEF_CATTLE') => {
  return api.post(`/cost_centers`, {environment, ...body});
};

const changeCostCentersbyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return api.put(`/cost_centers/${id}`, {environment, ...body});
};

const deleteCostCenters = (id) => {
  return api.delete(`/cost_centers/${id}`);
};

export {fetchCostCenters, fetchCostCentersbyId, registerCostCenters, changeCostCentersbyId, deleteCostCenters};
