import styled from 'styled-components';

export const FormLabel = styled.span.attrs((props) => ({
  htmlFor: props.htmlFor,
}))`
  font-weight: bold;
  span {
    color: #313131;
  }
`;
