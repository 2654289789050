import React from 'react';
import styled, {keyframes} from 'styled-components';
import uuid from 'react-uuid';
import './style.scss';
import {slideInDown} from 'react-animations';

import logo from '../../assets/images/logo_dsm.png';
import PrimaryFilterDisplay from '../PrimaryFilterDisplay/PrimaryFilterDisplay';
import {history} from '../../utils/utils';

const Toolbar = ({templateData}) => {
  const isCurrentPage = (url) => {
    const currentUrl = history.location.pathname;
    return currentUrl.includes(url);
  };

  const MenuItem = ({data}) => (
    <div
      className={`toolbarItem ${isCurrentPage(data.url) ? 'toolbarItem-active' : ''}`}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (data.url) {
          history.push(data.url);
        }
      }}>
      <div className="e-card-header">
        <i className={data.icon} />
      </div>
      <div className="e-card-content">
        <p>{data.text}</p>
      </div>
    </div>
  );

  const MenuIcon = () => (
    <div className="sidenav-brand" style={{cursor: 'pointer'}}>
      <img
        onClick={() => {
          history.push('/');
        }}
        alt="logo"
        id="logo_comp"
        src={logo}
        style={{height: '55px'}}
      />
    </div>
  );

  return (
    <ToolbarContainer className="toolbarContainer" id="toolbar-menu">
      <MenuIcon />
      <div className="menu-section">
        {templateData.length > 0 && (
          <ToolbarComponent>
            <>
              {templateData.map((item) => (
                <MenuItem key={uuid()} data={item} />
              ))}
            </>
          </ToolbarComponent>
        )}
        <PrimaryFilterDisplay />
      </div>
    </ToolbarContainer>
  );
};
const bounceAnimation = keyframes`${slideInDown}`;
const ToolbarContainer = styled.div`
  animation: 1s ${bounceAnimation};
  background-color: #fff;
  box-shadow: 0 0 14px 0px rgba(0, 0, 0, 0.368627);
  display: flex;
  left: 0;
  max-height: 200px;
  position: absolute;
  top: 0;
  transition: background-image 0.2s ease-in-out;
  width: 100%;
  .menu-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 10px;
    padding-right: 15px;
    width: 100%;
  }
  .toolbarItem {
    background-color: inherit;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    font-size: inherit;
    justify-content: center;
    padding: 0 18px;
    &:hover {
      background-color: #00adf0;
      border-radius: 4px;
      .e-card-header,
      .e-card-content {
        i,
        p {
          color: #fff;
        }
      }
    }
    .e-card-header {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      line-height: normal;
      margin-bottom: 6px;
      min-height: 22.5px;
      padding: 0;
      width: inherit;
      i {
        color: #959595;
        font-size: 20px;
      }
    }
    .e-card-content {
      color: #959595;
      font-size: 12px;
      text-align: center;
      p {
        color: #959595;
        font-weight: 100;
        margin: 0%;
      }
    }
  }
  .toolbarItem-active {
    background-color: #00adf0;
    border-radius: 4px;
    .e-card-header,
    .e-card-content {
      i,
      p {
        color: #fff;
      }
    }
  }
`;
const ToolbarComponent = styled.div`
  display: flex;

  height: 100%;
`;

export default Toolbar;
