import React from 'react';
import PropTypes from 'prop-types';
import {GuardedRoute} from 'react-router-guards';
import Breadcrumbs from '../../components/Breadcrumbs';

const GenerateRouteCategory = ({title, defaultBreadcrumbs, component: Component, allowed, url}) => {
  return (
    <GuardedRoute
      path={url}
      meta={{
        moduleName: title,
        allowed,
      }}
      render={() => (
        <>
          <Breadcrumbs path={[...defaultBreadcrumbs, {title}]} />
          {Component && <Component />}
        </>
      )}
    />
  );
};

GenerateRouteCategory.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  allowed: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  defaultBreadcrumbs: PropTypes.array.isRequired,
};

export default GenerateRouteCategory;
