import styled from 'styled-components';

export const FilterBox = styled.div`
  align-items: center;
  background-color: #dbe4f1;
  border-radius: 8px;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
  width: 100%;
  .message-status {
    color: #e74b4b;
    font-size: 11px;
    left: 25px;
    position: absolute;
    top: 75px;
  }

  & .group-field .float-label {
    font-size: 12px;
  }
  & form .group-field .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    height: 34px !important;
  }
  & .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    padding: 7.5px 4px !important;
  }
  .col-2 {
    flex: 0 0 34%;
    max-width: 34%;
    .form-group {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      input[type='checkbox'] {
        margin-right: 5px;
        width: 20px;
      }
    }
  }
  form {
    width: 100%;
    .group-field {
      padding: 0;
      .float-label {
        color: #007bff;
        font-weight: bold;
        margin-bottom: 0;
      }
      .form-group {
        margin-bottom: 0;
      }
      .MuiInputLabel-outlined {
        font-size: 11px;
      }
      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        height: auto;
      }
    }
    .footer-buttons {
      bottom: 30px;
      position: absolute;
      right: 15px;
    }
  }

  &.filter-updated,
  &.filter-scoreboard {
    height: auto;
    margin-bottom: 0;

    .footer-buttons {
      bottom: 30px;
      margin-top: 45px;
      position: relative;
      right: unset;
    }
    p {
      left: 15px;
      position: absolute;
    }
  }

  .filter-scoreboard {
    position: relative;
  }
  .loading {
    bottom: 55px;
    left: 100px;
    position: absolute;
  }
`;

export const ExportationButton = styled.button`
  align-items: center;
  border-radius: 3px;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 65px;
`;

export const H2 = styled.div`
  color: #007bff;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const GridContainer = styled.div`
  & .datagrid-general {
    height: auto !important;
    max-height: 500px;
  }
  .totalizer {
    padding: 6px 15px;
    color: #fff;
    background-color: #0c4ca3;
    font-size: 12px;
  }
  .btn-submit {
    float: right;
    margin-top: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 75px;
    border-radius: 50%;
  }
  .editable-grid {
    max-height: 500px;
  }
  width: 100%;
`;
