import React, {useRef, useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import * as Yup from 'yup';
import '../style.scss';
import {normalizeCollectionData, getMonth} from '../../../../utils/utils';
import {useInitialRenderEffect} from '../../../../utils/functions';
import {fetchCustomers} from '../../../../services/customers';
import {fetchHarvestsFilter} from '../../../../services/harvests';
import {fetchFarmsDetailsbyCustomerId} from '../../../../services/farmsDetails';
import GenerateForm from '../../../../components/GenerateForm';
import Alert from '../../../../components/Notification';

const FilterExportation = ({setCustomer, setHarvest, setFarmsList, setInitialDate, setFinalDate, setSubmitted}) => {
  const ref = useRef(null);
  const [slideOut, setSlideOut] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [harvests, setHarvests] = useState([]);
  const [farms, setFarms] = useState([]);
  const [hasFarms, setHasFarms] = useState(true);

  const [dataForm, setDataForm] = useState({});
  const [disableFields, setDisableFields] = useState({
    customer: false,
    harvest: true,
    farm: true,
  });

  const [initialYear, setInitalYear] = useState(null);
  const [finalDateValue, setFinalDateValue] = useState(' ');
  const [finalYear, setFinalYear] = useState(null);

  const loadCustomers = async () => {
    try {
      const {data} = await fetchCustomers({
        'order[name]': 'ASC',
        'whereinmatch[profile]': 'DAIRY_CATTLE',
      });
      setCustomers(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadHarvests = async (params) => {
    try {
      const {data} = await fetchHarvestsFilter(params);
      setHarvests(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadFarms = async (id, params = {}) => {
    try {
      const {data} = await fetchFarmsDetailsbyCustomerId(id, {
        ...params,
        environment: 'DAIRY_CATTLE',
      });
      const farmsDetail = data.resultList.filter((farmDetail) => farmDetail.name || farmDetail.farm.name).map((farmDetail) => farmDetail.farm);
      setFarms(farmsDetail || []);

      if (farmsDetail.length) {
        setHasFarms(true);
      } else {
        setHasFarms(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useInitialRenderEffect(() => {
    loadCustomers();
  });

  useEffect(() => {
    if (dataForm.customer) {
      loadHarvests({
        customer_id: dataForm.customer && dataForm.customer ? dataForm.customer : '',
      });
    }
  }, [dataForm.customer]);

  useEffect(() => {
    if (dataForm.customer && dataForm.harvest) {
      loadFarms(dataForm.customer, {'wherein[harvest.id]': dataForm.harvest});
    }
  }, [dataForm.customer && dataForm.harvest]);// eslint-disable-line

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Cliente',
          name: 'customer',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione o cliente',
          value: dataForm.customer || '',
          required: true,
          disabled: disableFields.customer,
          validations: {
            customer: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(customers, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {customer: data.value}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: !dataForm.harvest,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {customer: ''}));
              setDisableFields({
                customer: false,
                harvest: true,
                farm: !dataForm.harvest,
              });
            }
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Safra',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a safra',
          value: harvests.length ? dataForm.harvest : '',
          required: true,
          disabled: disableFields.harvest,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            if (data) {
              setInitalYear(data.label.split('/')[0]);
              setFinalYear(data.label.split('/')[1]);
              setDataForm((prev) => Object.assign(prev, {harvest: data.value}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {harvest: ''}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: true,
              });
            }
          },
          options: normalizeCollectionData(harvests, [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
        {
          hideField: false,
          label: 'Fazenda',
          name: 'farm',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a fazenda',
          value: dataForm.farm,
          key: dataForm.customer || 'farm',
          required: true,
          validations: {
            farm: Yup.string().required('Campo obrigatório'),
          },
          disabled: !hasFarms || !dataForm.customer || !dataForm.harvest,
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {farm: data.value}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {farm: ''}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            }
          },

          options: normalizeCollectionData(farms, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Data inicial',
          name: 'initialDate',
          type: 'datepicker',
          format: 'MM/yyyy',
          view: ['month', 'year'],
          value: '',
          initialYear: initialYear ? new Date(initialYear, 6) : null,
          finalYear: finalYear ? new Date(finalYear, 5) : null,
          /* initialYear: new Date(initialYear, 6),
          finalYear: new Date(finalYear, 5), */
          hasPlaceholder: true,

          // extraParams: {
          //   minDate: initialYear ? new Date(initialYear, 6) : null,
          // },
          placeholder: 'Selecione a data inicial',
          key: `initialDate-${dataForm.harvest}-${initialYear}`,
          required: true,
          disabled: !dataForm.harvest || false,
          onChange: (data) => {
            if (data) {
              const dataString = `${data}`.split(' ');
              setDataForm((prev) =>
                Object.assign(prev, {
                  initialDate: {
                    month: getMonth(dataString[1]),
                    year: dataString[3],
                  },
                }),
              );
              // setInitalYear(dataString[3]);
              setInitialDate(dataForm.initialDate || {});
              setFinalDateValue(new Date(parseInt(dataString[3], 10) + 1 > parseInt(finalYear, 10) ? finalYear : parseInt(dataString[3], 10) + 1, 5));
            } else {
              setDataForm((prev) =>
                Object.assign(prev, {
                  initialDate: {month: '', year: ''},
                }),
              );
            }
          },
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Data Final',
          name: 'finalDate',
          type: 'datepicker',
          format: 'MM/yyyy',
          view: ['month', 'year'],
          validations: {
            finalDate: Yup.string().required('Campo obrigatório'),
          },
          value: finalDateValue,
          initialYear: initialYear
            ? new Date(
                dataForm && dataForm.initialDate && dataForm.initialDate.year ? parseInt(dataForm.initialDate.year, 10) : initialYear,
                dataForm && dataForm.initialDate && dataForm.initialDate.month ? parseInt(dataForm.initialDate.month, 10) + 1 : 6,
              )
            : null,
          finalYear: finalYear ? new Date(finalYear, 5) : null,
          /* initialYear:
            dataForm.initialDate &&
            dataForm.initialDate.year &&
            dataForm.initialDate.month
              ? `${new Date(
                  dataForm.initialDate.year,
                  dataForm.initialDate.month,
                )}`
              : `${new Date(initialYear, 5)}`,
          finalYear: new Date(finalYear, 6), */
          hasPlaceholder: true,
          placeholder: 'Selecione a data final',
          key: `finalDate-${dataForm.initialDate ? dataForm.initialDate.month : ''}-${dataForm.harvest}`,
          required: true,
          disabled: !dataForm.harvest || !dataForm.initialDate || false,
          onChange: (data) => {
            if (data) {
              const dataString = `${data}`.split(' ');
              setDataForm((prev) =>
                Object.assign(prev, {
                  finalDate: {
                    month: getMonth(dataString[1]),
                    year: dataString[3],
                  },
                }),
              );
              setFinalDateValue(new Date(dataString[3], getMonth(dataString[1])));
            } else {
              setDataForm((prev) =>
                Object.assign(prev, {
                  finalDate: {month: '', year: ''},
                }),
              );
            }
          },
          columns: {
            xs: 6,
          },
        },
      ],
    },
  ];

  const onSubmit = async (values, actions) => {
    Object.keys(values).map((item) => {
      if (values[item] && values[item].value) {
        return Object.assign(values, {[item]: values[item].value});
      }
      return true;
    });

    try {
      actions.setSubmitting(true);
      const customerFound = customers.filter((customer) => customer.id === values.customer) || [];
      const harvestFound = harvests.filter((harvest) => harvest.id === values.harvest) || [];

      setSlideOut((prev) => !prev);
      setCustomer(customerFound[0] || {});
      setHarvest(harvestFound[0] || {});
      setFarmsList(dataForm.farm || []);
      setInitialDate(dataForm.initialDate || {month: '6', year: initialYear});
      setFinalDate(dataForm.finalDate || {month: '5', year: finalYear});
      setSubmitted(true);
      actions.setSubmitting(false);
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      {!hasFarms && <Alert type="warning" message="Nenhuma fazenda caracterizada para essa safra." time={4000} />}
      <Card className={`row report-filter filter-container ${slideOut ? 'slideOut' : ''}`} style={{transform: 'translateX(0)'}} ref={ref}>
        <div className="col-6 offset-3 filter-content">
          <GenerateForm onSubmit={onSubmit} groupFields={groupFields} />
        </div>
      </Card>
    </>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

export default FilterExportation;
