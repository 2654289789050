import React, {useState, useEffect, useContext} from 'react';
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Group, GroupInfo} from './styled';
import {getValueObject, getRowValue} from '../../utils/utils';
import EditableRow from './EditableRow';
import Accumulators from './Accumulators';
import './styles.scss';
import DataGridContext from './DataGridContext';

const generateRows = (data, groupBy, fieldHeaders, columnsTemplate, width, keyProp, keyValueProp, groupName = null, setCel) => {
  const textFields = fieldHeaders.filter((field) => field.editType === 'defaultedit');

  const filterByColumn = (field) =>
    data.map((line) => ({
      value: getRowValue(line, field[keyProp], field[keyValueProp], true, null, field),
    }));
  const allValues = textFields.reduce((acc, textField) => {
    return {...acc, ...{[textField.field]: filterByColumn(textField)}};
  }, {});

  return data?.map((line, index) => {
    let key = line.id;
    if (!key) {
      key = index + groupName;
    }
    return (
      <EditableRow
        // eslint-disable-next-line react/no-array-index-key
        key={key}
        prefetchedValues={allValues}
        groupBy={groupBy}
        index={key}
        indexMap={index}
        object={line}
        fieldHeaders={fieldHeaders}
        columnsTemplate={columnsTemplate}
        keyProp={keyProp}
        keyValueProp={keyValueProp}
        width={width}
        setCel={setCel}
      />
    );
  });
};

const GroupedRows = ({data, groupBy, fieldHeaders, columnsTemplate, width, keyProp, keyValueProp, accumulators, setCel}) => {
  const [expanded, setExpanded] = useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    if (!newExpanded) {
      setExpanded(expanded.filter((item) => item !== panel));
      return;
    }
    setExpanded([...expanded, panel]);
  };

  useEffect(() => {
    const groupNames = Object.keys(data).map((groupName) => groupName);
    setExpanded(groupNames);
  }, [data]);

  return (
    <>
      {data ? (
        Object.keys(data).map((groupName) => {
          return (
            <Group key={groupName}>
              <ExpansionPanel square expanded={expanded.includes(groupName)} onChange={handleChange(groupName)}>
                <ExpansionPanelSummary aria-controls={`${groupName}-content`} id={`${groupName}-header`}>
                  <GroupInfo>
                    {groupName} - {data[groupName].length} itens
                  </GroupInfo>
                  <ExpandMoreIcon className={expanded.includes(groupName) ? '' : 'expandIcon-right'} />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {generateRows(data[groupName], groupBy, fieldHeaders, columnsTemplate, width, keyProp, keyValueProp, groupName, setCel)}
                  {accumulators && (
                    <Accumulators
                      groupBy={groupBy}
                      groupedData={data[groupName]}
                      accumulatorsConfig={accumulators}
                      fieldHeaders={fieldHeaders}
                      columnsTemplate={columnsTemplate}
                    />
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Group>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

const RowContainer = ({groupBy, fieldHeaders, columnsTemplate, width, keyProp, keyValueProp, dataValues, setCel, accumulators}) => {
  useEffect(() => {
    const el = document.getElementsByClassName('row-container');
    if (el.length === 2) {
      el[1].addEventListener('scroll', () => {
        el[0].scrollTo(0, el[1].scrollTop);
      });
      el[0].addEventListener('scroll', () => {
        el[1].scrollTo(1, el[0].scrollTop);
      });
    }
  }, []);
  const [groupedData, setGroupedData] = useState([]);
  useEffect(() => {
    const newGroupedData = dataValues?.reduce((groups, currentData) => {
      const fieldConfig = fieldHeaders.find((field) => field.key === groupBy);
      let data = null;

      if (!fieldConfig) {
        return groups;
      }
      data = getValueObject(currentData, null, fieldConfig.valueKey);

      if (fieldConfig.groupTitle) {
        data = fieldConfig.groupTitle(data);
      }
      if (groups[data]) {
        groups[data] = [...groups[data], currentData];
      } else {
        groups[data] = [currentData];
      }
      return groups;
    }, {});
    setGroupedData(newGroupedData);
  }, [dataValues]);// eslint-disable-line
  const {state: dataGridState} = useContext(DataGridContext);

  return (
    <div
      className="row-container"
      style={{
        overflow: groupBy ? '' : 'auto',
        height: groupBy ? `calc(100vh - ${dataGridState && dataGridState.fixedAccumulator ? '270px' : '200px'})` : 'auto',
        width: width || 'max-content',
      }}>
      {groupBy ? (
        <GroupedRows
          key={groupBy}
          groupBy={groupBy}
          fieldHeaders={fieldHeaders}
          columnsTemplate={columnsTemplate}
          width={width}
          keyProp={keyProp}
          keyValueProp={keyValueProp}
          dataValues={dataValues}
          accumulators={accumulators}
          data={groupedData}
          setCel={setCel}
        />
      ) : (
        generateRows(dataValues, groupBy, fieldHeaders, columnsTemplate, width, keyProp, keyValueProp, null, setCel)
      )}
    </div>
  );
};

export default RowContainer;
