/* eslint-disable no-nested-ternary */
import React, {useState, useEffect, useRef} from 'react';
import IMask from 'imask';
import InputMask from 'react-input-mask';
import uuid from 'react-uuid';
import NumberFormat from 'react-number-format';
import {createBrowserHistory} from 'history';
import moment from 'moment';
import secureStorage from './SecureStorage';
import {MASKS} from './constants';
// eslint-disable-next-line import/no-cycle

export const getCurrentUser = () => {
  return JSON.parse(secureStorage.getItem('user'));
};

export function isObject(val) {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
}

export const getKeyValue = (key, keyValue) => {
  if (keyValue === 'email' && window.location.href.indexOf('/cadastros/clientes') > -1) {
    return 'user.email';
  }
  if (keyValue === 'customer' && window.location.href.indexOf('/cadastros/caracteriza-safra') > -1) {
    return 'customer.name';
  }
  if (keyValue === 'customer' && window.location.href.indexOf('/cadastros/fazenda') > -1) {
    return 'customer.name';
  }
  if (keyValue === 'country' && window.location.href.indexOf('/categorias/regiao') > -1) {
    return 'country.title';
  }
  if (isObject(key)) {
    return `${key}.title`;
  }
  if (key === 'state.region' || key === 'state.region.country') {
    return `${key}.title`;
  }
  return key;
};

export const isEmpty = (input) => {
  if (Array.isArray(input)) {
    return input.length === 0;
  }

  return !input || Object.keys(input).length === 0;
};

export const maskInput = ({type = null, pattern = null, value = 0, scale = 2}) => {
  if (type) {
    switch (type) {
      case MASKS.CPF:
        return IMask.createMask({
          mask: '000.000.000-00',
        }).resolve(value.toString());
      case MASKS.TEL:
      case MASKS.CEL:
        return IMask.createMask({
          mask: '(00) 00000-0000',
        }).resolve(value.toString());
      case MASKS.NUMBER:
        return IMask.createMask({
          mask: Number,
          scale,
          signed: false,
          thousandsSeparator: '.',
          padFractionalZeros: true,
          normalizeZeros: true,
          radix: ',',
          mapToRadix: ['.'],
          min: -10000,
        }).resolve(value.toString());
      case MASKS.DOUBLE:
        return IMask.createMask({
          mask: Number, // enable number mask

          // other options are optional with defaults below
          scale, // digits after point, 0 for integers
          signed: true, // disallow negative
          thousandsSeparator: '.', // any single char
          padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
          normalizeZeros: false, // appends or removes zeros at ends
          radix: ',', // fractional delimiter

          // additional number interval options (e.g.)
          min: -99999999999999,
          max: 99999999999999,
        }).resolve(value.toString());
      case MASKS.CEP:
        return IMask.createMask({
          mask: '00000-000',
        }).resolve(value.toString());
      case MASKS.DATE:
        return IMask.createMask({
          mask: '00/00/0000',
        }).resolve(value.toString());
      case MASKS.DATETIME:
        return IMask.createMask({
          mask: '00/00/0000 00:00:00',
        }).resolve(value.toString());
      case MASKS.MONEY:
        return IMask.createMask({
          mask: 'R$ money',
          blocks: {
            money: {
              mask: Number, // enable number mask

              // other options are optional with defaults below
              scale: 2, // digits after point, 0 for integers
              signed: false, // disallow negative
              thousandsSeparator: '.', // any single char
              padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
              normalizeZeros: true, // appends or removes zeros at ends
              radix: ',', // fractional delimiter

              // additional number interval options (e.g.)
              min: 0,
            },
          },
        }).resolve(value.toString());
      case MASKS.WEIGHT:
        return IMask.createMask({
          mask: 'money',
          blocks: {
            money: {
              mask: Number, // enable number mask

              // other options are optional with defaults below
              scale: 2, // digits after point, 0 for integers
              signed: false, // disallow negative
              thousandsSeparator: '.', // any single char
              padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
              normalizeZeros: true, // appends or removes zeros at ends
              radix: ',', // fractional delimiter

              // additional number interval options (e.g.)
              min: 0,
              max: 999999999999,
            },
          },
        }).resolve(value.toString());
      case MASKS.USERNAME:
        return IMask.createMask({
          mask: /^[0-9/]*$/,
        }).resolve(value.toString());
      default:
        return value.toString();
    }
  }

  if (pattern) {
    return IMask.createMask({
      mask: pattern,
    }).resolve(value);
  }

  return value;
};

export const maskDiscriminator = ({type = null, pattern = null}) => {
  if (type) {
    switch (type) {
      case MASKS.CPF:
        return '999.999.999-99';
      case MASKS.TEL:
      case MASKS.CEL:
        return '(99) 99999-9999';
      case MASKS.CEP:
        return '99999-999';
      case MASKS.DATE:
        return '99/99/9999';
      case MASKS.DATETIME:
        return '99/99/9999 99:99:99';
      case MASKS.USERNAME:
        return /^[0-9]*$/;
      case MASKS.NUMBER:
        return /^[0-9]*$/;
      case MASKS.PERCENTAGE:
        return `${/^[0-9]*$/} %`;
      default:
        return null;
    }
  }

  return pattern;
};

export const checkUserAlreadyFilledFilterForm = () => {
  return !!secureStorage.getItem('filterFilled');
};

export const history = createBrowserHistory();

export const getValueObjectRecursion = (object, keyArr = []) => {
  if (!keyArr || !keyArr.length) {
    return null;
  }

  return keyArr.reduce((currentValue, accessor) => {
    if (currentValue !== null && currentValue !== undefined && currentValue[accessor] !== null && currentValue[accessor] !== undefined) {
      return currentValue[accessor] === 'null' ? '' : currentValue[accessor];
    }
    return '';
  }, object);
};

export const getValueObject = (object, currentRowValues = null, key) => {
  const keysArr = key.split('.');
  const valueFromRow = currentRowValues ? getValueObjectRecursion(currentRowValues, keysArr) : null;
  if (valueFromRow) {
    return valueFromRow;
  }
  return getValueObjectRecursion(object, keysArr);
};

export const getRowValue = (
  object,
  key,
  keyValue,
  editMode = false,
  currentRowValues = null,
  {dateFormat = null, editType, mapper = null, popoverMapper = null},
  isPopover,
) => {
  if (!key || !object) {
    switch (editType) {
      case 'dropdownedit':
        return null;
      case 'booleanedit':
        return false;
      default:
        return '';
    }
  }
  if (mapper && !isPopover) {
    return mapper(object, editMode);
  }
  if (popoverMapper && isPopover) {
    return popoverMapper(object, editMode);
  }

  const currentValue = getValueObject(object, currentRowValues, key);

  let currentValueFromKeyValue = null;
  if (keyValue) {
    currentValueFromKeyValue = getValueObject(object, currentRowValues, keyValue);
  }

  if (isPopover) {
    return currentValueFromKeyValue ?? currentValue;
  }

  if (key === 'validated') {
    return Boolean(currentValue);
  }
  switch (editType) {
    case 'popoveredit':
      if (editMode) {
        return currentValue.id;
      }
      return currentValueFromKeyValue ?? null;
    case 'dropdownedit':
      if (editMode) {
        if (!currentValue.value && currentValue.id) {
          return {
            value: currentValue ? currentValue.id : null,
            label: currentValue ? (currentValue.name ? currentValue.name : currentValue.title) : null,
          };
        }
        return {label: currentValue, value: currentValue}; // antes retornava somente o currentValue
      }
      return currentValueFromKeyValue ?? null;
    case 'moneyedit':
      if (!editMode) {
        return maskInput({
          type: MASKS.MONEY,
          value: `${currentValue}`,
        });
      }
      return currentValue;
    case 'weightedit':
      if (!editMode) {
        return maskInput({
          type: MASKS.WEIGHT,
          value: `${currentValue}`,
        });
      }
      return currentValue;
    case 'booleanedit':
      if (editMode) {
        return Boolean(currentValue);
      }
      return currentValue === true ? 'Sim' : 'Não';
    case 'multipleedit':
      if (editMode) {
        return currentValue;
      }
      return currentValue.map((item) => {
        return <span className="multiselect-item">{item.label}</span>;
      });
    case 'datepicker':
      // eslint-disable-next-line no-case-declarations
      if (editMode) {
        return moment(currentValue, 'D_M_YYYY').locale('pt-br').format('YYYY/MM/DD');
        // return `${d[2]}-${d[1]}-${d[0]}`;
      }
      return moment(currentValue, 'D_M_YYYY')
        .locale('pt-br')
        .format(dateFormat || 'DD/MM/YYYY');

    default:
      return currentValueFromKeyValue ?? currentValue;
  }
};

export const listValuesCel = (arrayValues, item) => {
  return (
    <ul className="intenalValuesGrid">
      {arrayValues.map((keyValue) => (
        <li key={uuid()}>
          <b>{keyValue.name}</b>:{getValueObject(item, keyValue.item)}
        </li>
      ))}
    </ul>
  );
};

export const normalizeCollectionData = (collection, mappings) => {
  return collection.map((item) => {
    const abstraction = {};

    Object.keys(item).forEach((key) => {
      const mapFound = mappings.find((map) => map.key === key);
      if (mapFound) {
        abstraction[mapFound.alias] = item[mapFound.key];
      }
    });

    return abstraction;
  });
};

export const normalizeRowDataChange = (fieldHeaders, rowValues) => {
  const dataFormated = {};
  let validate = false;
  fieldHeaders.forEach((item) => {
    if (item.key.indexOf('.') > -1 && rowValues[item.field] !== '') {
      Object.assign(dataFormated, {
        [item.field]: {id: rowValues[item.field]},
      });
    } else if (item.key.indexOf('.') === -1 && rowValues[item.field] !== '') {
      Object.assign(dataFormated, {
        [item.field]: rowValues[item.field],
      });
    } else if (typeof rowValues[item.field] === 'string' && rowValues[item.field].length === 0) {
      validate = true;
    } else if (!rowValues[item.field]) {
      validate = true;
    }
  });
  return validate ? null : dataFormated;
};
export const normalizeRowData = (data, headerTemplate, rawData) => {
  const newData = Object.keys(data).reduce((newDataCollection, index) => {
    const headerTemplateFound = headerTemplate.find((headerItem) => headerItem.field === index);
    // console.log(newDataCollection, index);

    const popoverHeaderTemplateFound = headerTemplate.find((headerItem) => headerItem.popoverField === index);
    // eslint-disable-next-line no-restricted-globals
    if (popoverHeaderTemplateFound && popoverHeaderTemplateFound.returnPopoverFormat) {
      return {
        ...newDataCollection,
        [index]: popoverHeaderTemplateFound.returnPopoverFormat(data[index], index, rawData),
      };
    }

    if (headerTemplateFound && headerTemplateFound.virtualColumn) {
      return newDataCollection;
    }

    if (headerTemplateFound && headerTemplateFound.returnFormat) {
      if (headerTemplateFound && headerTemplateFound.editType === 'datepicker' && `${data.period}` === 'true') {
        return {
          ...newDataCollection,
          period: true,
          [index]: headerTemplateFound.returnFormat(data[index], index, rawData),
        };
      }

      return {
        ...newDataCollection,
        [index]: headerTemplateFound.returnFormat(data[index], index, rawData),
      };
    }
    if (isObject(data[index]) && !data[index].id && data[index].value) {
      // if (index === 'sex') {
      //   return {...newDataCollection, [index]: data[index].value};
      // }
      return {...newDataCollection, [index]: {id: data[index].value}};
    }
    // if (headerTemplateFound && headerTemplateFound.editType === 'weightedit') {
    //   return {
    //     ...newDataCollection,
    //     [index]: parsePotentiallyGroupedFloat(data[index]),
    //   };
    // }
    return {...newDataCollection, [index]: data[index]};
  }, {});

  if (newData.id) {
    let currentItemRawData = rawData.find((item) => item.id === newData.id);
    if (currentItemRawData && currentItemRawData.date) {
      currentItemRawData = Object.assign(currentItemRawData, {
        date: moment(currentItemRawData.date, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00'),
      });
    }

    return {...currentItemRawData, ...newData};
  }

  return newData;
};

export const span = (type, number) => {
  const cssRuleName = type === 'rowspan' ? 'gridRow' : 'gridColumn';
  return {[cssRuleName]: `span ${number}`};
};

// const passToFullWidth = (widths) => {
//   // eslint-disable-next-line radix
//   const numbers = widths.map((width) => parseInt(width.split('%')[0]));

//   const sum = numbers.reduce((a, b) => a + b, 0);
//   const numberPosition = numbers.length - 1;
//   numbers[numberPosition] += 100 - sum;
//   const newWidth = numbers.map((number) => `${number}%`);
//   return newWidth;
// };

export const generateRowRule = (widths) => {
  let value = '';
  let repeatSequence = 0;
  let repeatCss = '';

  // widths = passToFullWidth(widths);

  widths.forEach((width) => {
    if (width) {
      if (repeatSequence) {
        repeatCss = `repeat(${repeatSequence}, 1fr) `;
        repeatSequence = 0;
      }
      value = `${value} ${repeatCss}${width}`;
    } else {
      repeatSequence += 1;
    }
  });
  if (repeatSequence) {
    repeatCss = `repeat(${repeatSequence}, 1fr) `;
  } else {
    repeatCss = '';
  }

  value = `${value} ${repeatCss}`;
  return value;
};

export const MoneyFormat = (props) => {
  const {name, inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
      isNumericString
      prefix="R$"
      allowEmptyFormatting={false}
      allowNegative={false}
    />
  );
};

export const WeightKgFormat = (props) => {
  const {name, inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
      isNumericString
      allowEmptyFormatting={false}
      allowNegative={false}
    />
  );
};

export const InputFormat = (props) => {
  const {inputRef, onChange, mask, ...other} = props;
  useEffect(() => {}, [mask]);

  return (
    <InputMask
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      mask={mask}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onChange={(values) => {
        onChange({
          target: {
            value: values.currentTarget.value,
          },
        });
      }}
    />
  );
};

const addSingleDataToRow = (prev, field, value) => {
  if (prev) {
    return {...prev, [field]: value};
  }
  return {[field]: value};
};

export const addDataToRow = (prev, data) => {
  return data.reduce((newRowData, {field, value}) => {
    return addSingleDataToRow(newRowData, field, value);
  }, prev);
};

export const addFilterData = async (data, state) => {
  return {
    ...data,
    ...{
      harvest: {id: state.harvest.id},
      customer: {id: state.customer.id},
      farm: {id: state.farm.id},
    },
  };
};

export const addCreatedBy = async (data) => {
  const user = getCurrentUser();
  if (user.id) {
    return {
      ...data,
      ...{
        created_by: {id: user.id},
      },
    };
  }

  return data;
};

export const addUpdatedBy = async (data) => {
  const user = getCurrentUser();

  if (user.id) {
    return {
      ...data,
      ...{
        updated_by: {id: user.id},
      },
    };
  }

  return data;
};

export const createOrUpdateData = async (data, state, postService, putService, addFilterDataProp = true) => {
  let response = null;
  try {
    let dataWithCommonRef = addFilterDataProp ? await addFilterData(data, state) : data;
    if (dataWithCommonRef.id) {
      dataWithCommonRef = await addUpdatedBy(dataWithCommonRef);
      response = await putService(dataWithCommonRef.id, dataWithCommonRef);
    } else {
      dataWithCommonRef = await addCreatedBy(dataWithCommonRef);
      response = await postService(dataWithCommonRef);
    }
  } catch (error) {
    response = error;
    console.error(error);
  }
  return response;
};

export const createOrUpdateDataFull = async (data, state, postService, putService) => {
  let response = null;
  try {
    let dataWithCommonRef = await addFilterData(data, state);
    if (dataWithCommonRef.id) {
      dataWithCommonRef = await addUpdatedBy(dataWithCommonRef);
      response = await putService(dataWithCommonRef.id, dataWithCommonRef);
    } else {
      dataWithCommonRef = await addCreatedBy(dataWithCommonRef);
      response = await postService(dataWithCommonRef);
    }
  } catch (error) {
    response = error;
    console.error(error);
  }
  return response;
};

export const dynamicColors = () => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return `rgb(${r},${g},${b})`;
};
const getHoverColor = (color) => {
  if (color.includes('rgb(')) {
    return color.replace(/[,]/g, ' ').replace(')', ' / 65%)');
  }
  return `${color}8a`;
};

export const sortArray = (data, field, ordem) => {
  const sorted = data.sort((a, b) => {
    let comparison = 0;
    if (typeof a[field] === 'object') {
      if (a[field].title) {
        if (a[field].title.toLowerCase() > b[field].title.toLowerCase()) {
          comparison = 1;
        } else if (a[field].title.toLowerCase() < b[field].title.toLowerCase()) {
          comparison = -1;
        }
      } else if (a[field].name) {
        if (a[field].name.toLowerCase() > b[field].name.toLowerCase()) {
          comparison = 1;
        } else if (a[field].name.toLowerCase() < b[field].name.toLowerCase()) {
          comparison = -1;
        }
      }
    } else if (typeof a[field] === 'string') {
      if (a[field].toLowerCase() > b[field].toLowerCase()) {
        comparison = 1;
      } else if (a[field].toLowerCase() < b[field].toLowerCase()) {
        comparison = -1;
      }
    } else if (typeof a[field] === 'number') {
      if (a[field] > b[field]) {
        comparison = 1;
      } else if (a[field] < b[field]) {
        comparison = -1;
      }
    }
    return ordem === 'desc' ? comparison * -1 : comparison;
  });
  return sorted;
};

export const getDataChart = async (req = null, params = null, processedData = null, dynamicColor, options, fixedColor, sort) => {
  const labels = [];
  const data = [];
  const backgroundColor = [];
  const hoverBackgroundColor = [];
  const pointBorderColor = [];
  const compararPie = (a, b) => {
    if (a.data < b.data) {
      return -1;
    }
    if (a.data > b.data) {
      return 1;
    }
    // a deve ser igual a b
    return 0;
  };
  if (!fixedColor) {
    fixedColor = dynamicColors();
  }
  try {
    const sourceData = req ? (sort ? (await req(params)).data.sort(compararPie) : (await req(params)).data) : processedData;
    sourceData.forEach((item, i) => {
      data.push(item.data ? item.data : 0);
      labels.push(item.label);
      if (typeof fixedColor !== 'object') {
        backgroundColor.push(dynamicColor ? dynamicColors() : fixedColor || '#95ceff');
        hoverBackgroundColor.push(getHoverColor(fixedColor) || '#95ceff');
      } else {
        backgroundColor.push(fixedColor[i] || '#95ceff');
        hoverBackgroundColor.push(getHoverColor(fixedColor[i]) || '#95ceff');
      }
      pointBorderColor.push(item.validated ? '#1f77b4' : '#a52710');
    });
    if (!options) {
      return data.length > 0
        ? {
            labels,
            datasets: [{data, backgroundColor, hoverBackgroundColor}],
          }
        : null;
    }
    Object.assign(options, {pointBorderColor});
    return data.length > 0
      ? {
          labels,
          datasets: [{data, ...options}],
        }
      : null;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const flatten = (objectOrArray, prefix = '', formatter = (k) => k) => {
  const nestedFormatter = (k) => `.${k}`;
  const nestElement = (prev, value, key) =>
    value && typeof value === 'object'
      ? {
          ...prev,
          ...flatten(value, `${prefix}${formatter(key)}`, nestedFormatter),
        }
      : {...prev, ...{[`${prefix}${formatter(key)}`]: value}};

  return Array.isArray(objectOrArray)
    ? objectOrArray.reduce(nestElement, {})
    : Object.keys(objectOrArray).reduce((prev, element) => nestElement(prev, objectOrArray[element], element), {});
};

const findSubFields = (headerItem, accumulator, level, parent = null, fields = null) => {
  if (fields) {
    fields.push(headerItem);
  }
  if (parent) {
    headerItem.parent = parent.field;
  }
  if (accumulator[level]) {
    accumulator[level] = [...accumulator[level], headerItem];
  } else {
    accumulator[level] = [headerItem];
  }
  if (!headerItem.subFields || !headerItem.subFields.length) {
    return accumulator;
  }
  const nextLevel = level + 1;

  const subItens = headerItem.subFields.reduce((subAcc, subField) => {
    if (subAcc[nextLevel]) {
      subAcc[nextLevel] = [...findSubFields(subField, accumulator, nextLevel, headerItem, fields)[nextLevel]];
    } else {
      subAcc = findSubFields(subField, accumulator, nextLevel, headerItem, fields);
    }
    return subAcc;
  }, {});
  return subItens;
};

const addColspan = (item, groupBy) => {
  Object.keys(item).forEach((level) => {
    item[level].forEach((column) => {
      const subFields = findSubFields(column, {}, 0);
      column.colSpan = subFields[Object.keys(subFields).length - 1].filter((subField) => subField.field !== groupBy).length;
    });
  });

  return item;
};

const calculateHeight = (acc, item) => {
  const currentHeight = Object.keys(item).length;

  if (currentHeight > acc) {
    acc = currentHeight;
  }

  return acc;
};

const addRowSpan = (item, height) => {
  if (!item[0][0].subFields) {
    item[0][0].rowSpan = height;
  }
  return item;
};

export const formatDateTime = (date) => {
  const insertZero = (value) => (value < 10 ? `0${value}` : value);

  const day = insertZero(date.getDate());
  const month = insertZero(date.getMonth() + 1);
  return `${day}/${month}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const generateHeader = (header, groupBy) => {
  const fields = [];
  let hierarchy = header.map((headerItem) => findSubFields(headerItem, {}, 0, null, fields)).map((headerItem) => addColspan(headerItem, groupBy));

  const height = hierarchy.reduce(calculateHeight, 0);

  hierarchy = hierarchy
    .map((headerItem) => addRowSpan(headerItem, height))
    .reduce((acc, column) => {
      Object.keys(column).forEach((level) => {
        const newItem = Array.isArray(column[level]) ? column[level] : [column[level]];
        if (acc[level]) {
          acc[level] = [...acc[level], ...newItem];
        } else {
          acc[level] = [...newItem];
        }
      });
      return acc;
    }, {});
  const fieldTemplate = Object.keys(hierarchy).reduce((acc, level) => [...acc, ...hierarchy[level]], []);
  const headerTemplate = fieldTemplate.filter((item) => item.field !== groupBy);
  const widthCollection = fields.reduce((acc, itemLevel) => {
    if (!itemLevel.subFields && itemLevel.field !== groupBy) {
      acc.push(itemLevel.width || '');
    }
    return acc;
  }, []);

  return {
    newFieldTemplate: fields,
    newHeaderTemplate: headerTemplate,
    newWidthCollection: widthCollection,
  };
};

export const refreshBlur = (index) => {
  let validationOK = true;
  if (index) {
    const row = document.getElementsByClassName(`row-datagrid-${index}`);
    const arr = Array.from(row[0]?.getElementsByTagName('input'));

    arr.forEach((input) => {
      if (input.required && input.value.length === 0 && validationOK) {
        validationOK = false;
      }
      input.focus();
      input.blur();
    });
  }
  return validationOK;
};

export const sleep = (delay = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const formatFarmsToExport = (farmsList) => {
  let farmListString = '';
  farmsList.forEach((farmId, index) => {
    if (index > 0) {
      farmListString += `|${farmId}`;
    } else {
      farmListString += `${farmId}`;
    }
  });

  return farmListString;
};

export const getMonth = (month) => {
  const months = {
    Jan: '0',
    Feb: '1',
    Mar: '2',
    Apr: '3',
    May: '4',
    Jun: '5',
    Jul: '6',
    Aug: '7',
    Sep: '8',
    Oct: '9',
    Nov: '10',
    Dec: '11',
  };
  return months[month] || '';
};

export const getMonthName = (index) => {
  const months = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez',
  };
  return months[index] || '';
};

export const isValidEmail = (value) => {
  if (value && /(^\w.*@\w+\.\w)/.test(value)) {
    return true;
  }

  return false;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const formatNumber = (number, hasSimbol = true) => {
  if (number) {
    const formatedNumber = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(number);
    return hasSimbol ? formatedNumber : formatedNumber.split('R$')[1].trim();
  }

  return number;
};

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const getFilterType = (type) => {
  const numericTypes = ['moneyedit', 'numericedit', 'datepicker'];
  const dateTypes = ['datepicker'];
  const textTypes = ['defaultedit', 'emailedit', 'dropdownedit', 'multipleedit', 'cepedit'];
  if (dateTypes.includes(type)) {
    return 'date';
  }
  if (numericTypes.includes(type)) {
    return 'numeric';
  }
  if (textTypes.includes(type)) {
    return 'text';
  }

  return '';
};

export const isPartialDate = (date) => {
  if (date.length === 6) {
    return true;
  }
  if (date.length === 8) {
    return false;
  }
  throw new Error('Invalid date format');
};

export const formatToEditType = (value, editType, operator = null) => {
  if (editType === 'text' && operator === 'contains') {
    return `${value}`;
  }
  if (editType === 'text') {
    return `'${value}'`;
  }
  if (editType === 'date') {
    const splittedValue = value.split('');
    if (isPartialDate(value)) {
      return `${splittedValue[2]}${splittedValue[3]}${splittedValue[4]}${splittedValue[5]}-${splittedValue[0]}${splittedValue[1]}`;
    }
    return `${splittedValue[4]}${splittedValue[5]}${splittedValue[6]}${splittedValue[7]}-${splittedValue[2]}${splittedValue[3]}-${splittedValue[0]}${splittedValue[1]}`;
  }
  return value;
};

export const colors = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
export const comparar = (a, b) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  // a deve ser igual a b
  return 0;
};
