import api from '../api';
import apiNew from '../apiNew';

const fetchDashboard = (type, params = {}) => {
  return api.get(`/dashboards/${type}`, {
    params: {
      ...params,
    },
  });
};

const fetchDashboardHarvest = (params = {}) => {
  return apiNew.get(`/harvests/filter`, {
    params: {
      ...params,
    },
  });
};

const fetchFilterLivestock = (params = {}) => {
  return api.get(`/category_livestock_system/dashboard_filter`, {
    params: {
      ...params,
    },
  });
};

const fetchDashboardClimates = (params = {}) => {
  return api.get(`/dashboards/climates`, {
    params: {
      ...params,
    },
  });
};
const fetchDashboardTeams = (params = {}) => {
  return api.get(`/dashboards/teams`, {
    params: {
      ...params,
    },
  });
};
const fetchDashboardFinancials = (params = {}) => {
  return api.get(`/dashboards/financials`, {
    params: {
      ...params,
    },
  });
};

export {fetchDashboardClimates, fetchDashboardTeams, fetchDashboard, fetchDashboardHarvest, fetchDashboardFinancials, fetchFilterLivestock};
