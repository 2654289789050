import secureStorage from '../../../utils/SecureStorage';

export class DairyHelpers {
  static resolve(path, obj) {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj || window.self);
  }

  static NumberSanitize(value) {
    if (!value) return null;
    if (typeof value === 'number') return value;

    return Number(value.replace(/\D/g, ''));
  }

  static uniqueById(arr) {
    // eslint-disable-next-line no-sequences
    return Array.from(arr.reduce((acc, item) => (item && item.id && acc.set(item.id, item), acc), new Map()).values());
  }

  static defaultValues() {
    const customer = JSON.parse(secureStorage.getItem('customer'));
    const harvest = JSON.parse(secureStorage.getItem('harvest'));
    const farm = JSON.parse(secureStorage.getItem('farm'));

    return {
      customer: {
        id: customer?.id,
      },
      harvest: {
        id: harvest?.id,
      },
      farm: {
        id: farm?.id,
      },
    };
  }

  static defaultParamsOnList() {
    const values = this.defaultValues();
    return {
      'wherein[customer.id]': values.customer.id,
      'wherein[harvest.id]': values.harvest.id,
      'wherein[farm.id]': values.farm.id,
    };
  }

  static autoCompleteYesNo() {
    return [
      {
        title: 'Sim',
        id: true,
      },
      {
        title: 'Não',
        id: false,
      },
    ];
  }

  static getValueYesNo(value) {
    if (value == null) return null;

    return {
      id: !!value,
      title: value ? 'Sim' : 'Não',
    };
  }

  static onOptionsAndValueByCatAndData(category, data, name, parameter, isMultiple = false) {
    const options = category[name].filter((cat) => cat[parameter]);
    let value = isMultiple ? [] : null;

    if (data?.[`list_${name}`]) {
      if (isMultiple) {
        value = data[`list_${name}`].filter((item) => item[parameter]);
      } else {
        value = data[`list_${name}`].find((item) => item[parameter]);
      }
    }

    return {
      options,
      value,
    };
  }
}
