import axios from 'axios';
import secureStorage from '../utils/SecureStorage';
// eslint-disable-next-line import/no-cycle
import {history} from '../utils/utils';

const apiUrl = process.env.REACT_APP_API_URL_NEW;

// const apiNewUrl = 'http://localhost:3004';

const params = {
  baseURL: apiUrl,
  headers: {},
};

const apiNew = axios.create(params);

apiNew.interceptors.request.use(
  async (config) => {
    if (!config.headers.Authorization) config.headers.Authorization = secureStorage.getItem('token') ?? '';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiNew.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.config.url === '/users/token') {
      return error.response;
    }

    const status = error.response ? error.response.status : error;

    switch (status) {
      case 500:
        console.error('ERROR 500', error.response.data.detail);
        if (error.response.data.detail.includes('Expired JWT') || error.response.data.detail.includes('JWT rejected')) {
          secureStorage.clear();
          history.push('/login');
        }
        break;
      case 401:
        console.error('ERROR 401', error.response.data.detail);
        secureStorage.clear();
        history.push('/login');
        break;
      case 403:
        console.error('ERROR 403');
        break;
      case 404:
        if (error.response.config.url.indexOf('/pages/slug') !== -1) {
          window.location = `${window.location.origin}/404`;
        }
        console.error(`Error ${status}`);
        break;
      case 405:
      case 406:
      case 422:
      default:
        console.error(`Error ${status}`);
    }

    return Promise.reject(error);
  },
);

export default apiNew;
