import React from 'react';
import PropTypes from 'prop-types';
import {Form, Formik} from 'formik';
import CallInputs from './call_inputs';
import {FormikHelper} from './utils/util';
import {Heading} from './ui/components/heading';
import './style.scss';
import NotificationPush from './NotificationsPush';

const VerticalGrid = ({data, onSubmit, children}) => {
  const options = {
    enableReinitialize: true,
    ...FormikHelper.init(data).build(),
    onSubmit,
  };

  const hasDropdownState = (dropdownState) => {
    if (dropdownState == null) return '';

    return dropdownState === true ? ' vertical-grid-dropdown in' : ' vertical-grid-dropdown out';
  };

  return (
    <Formik {...options}>
      {(actions) => (
        <Form>
          <div className="d-flex mx-n2 flex-wrap vertical-grid">
            {Object.values(actions?.errors)?.length > 0 && <NotificationPush actions={actions} items={data} />}
            {children ||
              data.map(({name, component: Component, dropdownState, items = [], style}, idx) => (
                <div key={idx} style={style} className="px-2 mb-3">
                  {name && !Component && <Heading>{name}</Heading>}
                  {name && Component && <Component>{name}</Component>}
                  {!name && Component && Component}
                  <div className={`_row${hasDropdownState(dropdownState)}`}>
                    {items
                      .filter((item) => item.component || item.columns)
                      .map((item, index) => (
                        <div className={`_col ${index % 2 === 0 ? 'even' : 'odd'}`} key={`${idx}-${index}`}>
                          <CallInputs {...item} actions={actions} />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const itemsProps = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      col: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      placeholder: PropTypes.string,
      isRequired: PropTypes.bool,
      tabIndex: PropTypes.number,
      mask: PropTypes.string,
      submittingText: PropTypes.string,
      validations: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          params: PropTypes.array,
        }),
      ),
      value: PropTypes.any,
      type: PropTypes.string,
      withDisable: PropTypes.bool,
      columns: PropTypes.array,
    }),
  ]),
).isRequired;

export const FormFactoryParams = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
    style: PropTypes.object,
    items: itemsProps,
  }),
).isRequired;

VerticalGrid.propTypes = {
  data: FormFactoryParams,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default VerticalGrid;
