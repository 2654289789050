import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {TextArea} from '../form';

const Textarea = ({
  name,
  title,
  placeholder,
  isRequired,
  tabIndex,
  disabled,
  ...el
}) => {
  return (
    <Field
      name={name}
      placeholder={placeholder || ''}
      tabIndex={tabIndex}
      disabled={!!disabled}
      as={TextArea}
      onChange={(val) => {
        if (el.setFieldValue) {
          el.setFieldValue(name, val.target.value);
        }
      }}
    />
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
};

export default Textarea;
