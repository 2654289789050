import api from '../api';

const fetchCategoryFunctions = (params = {}) => {
  return api.get(`/category_functions`, {
    params: {
      ...params,
    },
  });
};

const fetchCategorFunctionsbyId = (id, params = {}) => {
  return api.get(`/category_functions/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryFunctions = (body = {}) => {
  return api.post(`/category_functions`, body);
};

const changeCategoryFunctionsbyId = (id, body = {}) => {
  return api.put(`/category_functions/${id}`, body);
};

const deleteCategoryFunctions = (id) => {
  return api.delete(`/category_functions/${id}`);
};

export {
  fetchCategoryFunctions,
  fetchCategorFunctionsbyId,
  registerCategoryFunctions,
  changeCategoryFunctionsbyId,
  deleteCategoryFunctions,
};
