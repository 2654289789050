import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {Bar, Pie} from 'react-chartjs-2';
import Indicator from '../DataPresenters/Indicator';
import {getDataChart, maskInput, colors} from '../../../utils/utils';
import Table from '../DataPresenters/Table';
import {MASKS} from '../../../utils/constants';
import 'chartjs-plugin-datalabels';

const DashboardLivestock = ({
  table,
  barChart,
  pieChart,
  kpiAccumulated,
  kpiValidated,
  monthlyBarChart,
  gmd,
  kpi,
  page,
}) => {
  const pageSettings = {
    births: {
      mainColor: '#d6d700',
      secondaryColor: '#000',
      labelKpiValidated: {
        icon: 'plus-square',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelKpiAccumulated: {
        title: 'Quantidade de Cabeças',
        subtitle: 'Acumulado Safra',
        icon: 'plus-square',
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelBarChart: {
        title: 'Mensal',
        prefix: false,
      },
      labelPieChart: {
        title: 'Sexo',
        prefix: ' ',
        colors: ['blue', '#ff00ff'],
      },
      labelTable: {
        title: 'Despesa',
      },
    },
    deaths: {
      mainColor: '#ff0000',
      secondaryColor: '#fff',
      labelKpiValidated: {
        icon: 'times',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelKpiAccumulated: {
        title: 'Quantidade de cabeças',
        subtitle: 'Acumulado Safra',
        icon: 'times',
      },
      labelBarChart: {
        title: 'Mensal',
      },
      labelPieChart: {
        title: 'Motivos das mortes',
        prefix: ' ',
      },
      labelTable: {
        title: 'Despesa',
      },
    },
    weanings: {
      mainColor: '#ff8001',
      secondaryColor: '#fff',
      labelKpiValidated: {
        icon: 'check-circle',
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelKpiAccumulated: {
        title: 'Quantidade de cabeças',
        subtitle: 'Acumulado Safra',
        icon: 'check',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelBarChart: {
        title: 'Mensal',
      },
      labelPieChart: {
        title: 'Sexo',
        prefix: ' ',
        colors: ['blue', '#ff00ff'],
      },
      labelTable: {
        title: 'Despesa',
      },
    },
    confinements: {
      mainColor: '#0000f5',
      secondaryColor: '#fff',
      labelKpiValidated: {
        icon: 'arrow-up',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelKpiAccumulated: {
        title: 'Quantidade de Cabeças',
        subtitle: 'Acumulado da Safra',
        icon: 'arrow-circle-up',
      },
      labelBarChart: {
        title: 'Mensal',
      },
      labelPieChart: {
        title: '% Terminado por Sexo',
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelTable: {
        title: 'Médias',
        columns: [
          {key: 'label', name: 'Tipo de Média'},
          {key: 'data_one', name: 'Macho'},
          {key: 'data_two', name: 'Fêmea'},
        ],
        round: true,
      },
    },
    herd_entry: {
      mainColor: '#00a3a3',
      secondaryColor: '#fff',
      labelKpiValidated: {
        icon: 'inbox',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelKpiAccumulated: {
        title: 'Acumulado',
        subtitle: 'Despesa',
        icon: 'inbox',
      },
      labelBarChart: {
        title: 'Quantidade por Tipo',
        stepSize: 10,
      },
      labelPieChart: {
        title: 'Valores por Tipo',
        prefix: 'R$',
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelTable: {
        title: 'Médias',
        columns: [
          {key: 'label', name: 'Entrada'},
          {key: 'data_one', name: 'kg'},
          {key: 'data_two', name: '@'},
        ],
        round: true,
      },
    },
    herd_departure: {
      mainColor: '#5200a3',
      secondaryColor: '#fff',
      labelKpiValidated: {
        icon: 'sign-out',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelKpiAccumulated: {
        title: 'Acumulado',
        subtitle: 'Despesa',
        icon: 'sign-out',
      },
      labelBarChart: {
        title: 'Quantidade por Tipo',
      },
      labelPieChart: {
        title: 'Valores por Tipo',
        prefix: 'R$',
        round: true,
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelTable: {
        title: 'Saída por Tipo - Médias',
        columns: [
          {key: 'label', name: 'Entrada'},
          {key: 'data_one', name: 'kg'},
          {key: 'data_two', name: '@'},
        ],
        round: true,
      },
    },
    stocks: {
      mainColor: '#002e5c',
      secondaryColor: '#fff',
      labelKpiValidated: {
        title: 'Quantidade do último mês',
        icon: 'plus-square',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelKpiAccumulated: {
        title: 'Média Acumulada',
        subtitle: '',
        icon: 'plus',
      },
      labelBarChart: {
        title: 'Estoque Pecuário Mensal',
        // stepSize: 1,
      },
      labelPieChart: {
        title: 'Valores por Tipo',
        prefix: 'value',
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelTable: {
        title: 'Saída por Tipo - Médias',
        columns: [
          {key: 'label', name: 'Entrada'},
          {key: 'data_one', name: 'kg'},
          {key: 'data_two', name: '@'},
        ],
      },
    },
    reproduction: {
      mainColor: '#d6d600',
      secondaryColor: '#000',
      labelKpiValidated: {
        title: 'Fertilidade',
        icon: 'venus-mars',
      },
      gmd: {
        icon: 'balance-scale',
      },
      labelKpiAccumulated: {
        title: 'Acumulado',
        subtitle: 'Despesa',
        icon: 'venus-mars',
      },
      labelBarChart: {
        title: 'Estoque Pecuário Mensal',
      },
      labelPieChart: {
        title: 'Categoria de Matrizes da Fazenda',
        prefix: ' ',
      },
      labelBarChartMonthly: {
        title: 'Quantidade Mensal',
      },
      labelTable: {
        title: 'Saída por Tipo - Médias',
      },
    },
  };

  const [normalizedBarChartData, setNormalizedBarChartData] = useState(null);
  const [normalizedPieChartData, setNormalizedPieChartData] = useState(null);
  const [
    normalizedBarChartDataMonthly,
    setNormalizedBarChartDataMonthly,
  ] = useState(null);
  useEffect(() => {
    const loadData = async () => {
      if (barChart && barChart.length) {
        const dataBarChart = await getDataChart(
          null,
          null,
          barChart,
          false,
          null,
          pageSettings[page].mainColor,
        );
        const formatedDataBarChart = dataBarChart.datasets[0].data.map(
          (number) => number,
        );
        dataBarChart.datasets[0].data = formatedDataBarChart;

        setNormalizedBarChartData(dataBarChart);
      }
      if (pieChart) {
        const dataPieChart = await getDataChart(
          null,
          null,
          pieChart,
          true,
          null,
          pageSettings[page].labelPieChart &&
            pageSettings[page].labelPieChart.colors
            ? pageSettings[page].labelPieChart.colors
            : colors,
        );

        setNormalizedPieChartData(dataPieChart);
      }
      if (monthlyBarChart) {
        const dataMonthlyBarChart = await getDataChart(
          null,
          null,
          monthlyBarChart,
          false,
          null,
          pageSettings[page].mainColor,
        );
        setNormalizedBarChartDataMonthly(dataMonthlyBarChart);
      }
    };
    loadData();
  }, [barChart, pieChart, monthlyBarChart]);// eslint-disable-line

  return (
    <div>
      <div className="page-break">
        {kpiValidated && typeof kpiValidated === 'object' && (
          <div className="pb-2">
            <Indicator
              multiple
              icon={pageSettings[page].labelKpiValidated.icon}
              iconLabel={kpiValidated.label ? 'Mês/Ano' : ''}
              iconValue={kpiValidated.label || ''}
              iconSize={4}
              subtitlePosition="top"
              color={pageSettings[page].secondaryColor}
              iconSide="right"
              iconColor={pageSettings[page].secondaryColor}
              iconBackgroundColor={pageSettings[page].mainColor}
              backgroundColor={pageSettings[page].mainColor}
              data={
                pageSettings[page].labelKpiValidated.data || {
                  'Qtde Validada': maskInput({
                    type: MASKS.DOUBLE,
                    value: `${kpiValidated.validated}`,
                  }),
                  'Qtde não Validada': maskInput({
                    type: MASKS.DOUBLE,
                    value: `${kpiValidated.invalidated}`,
                  }),
                }
              }
            />
          </div>
        )}
        {kpi && typeof kpi === 'object' && (
          <div className="pb-2">
            <Indicator
              multiple
              icon={pageSettings[page].labelKpiValidated.icon}
              iconLabel={kpi.fertility ? 'Fertilidade geral' : ''}
              iconValue={`${
                maskInput({
                  type: MASKS.DOUBLE,
                  value: `${kpi.fertility}`,
                }) || ''
              } %`}
              iconSize={4}
              subtitlePosition="top"
              color={pageSettings[page].secondaryColor}
              iconSide="right"
              iconColor={pageSettings[page].secondaryColor}
              iconBackgroundColor={pageSettings[page].mainColor}
              backgroundColor={pageSettings[page].mainColor}
              data={
                pageSettings[page].labelKpiValidated.data || {
                  'Fêmeas em Cobertura': maskInput({
                    type: MASKS.DOUBLE,
                    value: `${kpi.mount}`,
                  }),
                  'Fêmeas Prenhes': maskInput({
                    type: MASKS.DOUBLE,
                    value: `${kpi.herding}`,
                  }),
                }
              }
            />
          </div>
        )}

        {kpiValidated && typeof kpiValidated === 'number' && (
          <div className="pb-2">
            <Indicator
              icon={pageSettings[page].labelKpiValidated.icon}
              iconSize={4}
              subtitlePosition="top"
              color={pageSettings[page].secondaryColor}
              iconColor={pageSettings[page].secondaryColor}
              iconBackgroundColor={pageSettings[page].mainColor}
              backgroundColor={pageSettings[page].mainColor}
              data={maskInput({type: MASKS.DOUBLE, value: `${kpiValidated}`})}
              title={pageSettings[page].labelKpiValidated.title}
              subtitle={pageSettings[page].labelKpiValidated.subtitle}
            />
          </div>
        )}
        {kpiAccumulated && (
          <div className="pb-2">
            <Indicator
              icon={pageSettings[page].labelKpiAccumulated.icon}
              iconSize={4}
              iconSide="left"
              color={pageSettings[page].secondaryColor}
              iconColor={pageSettings[page].secondaryColor}
              iconBackgroundColor={pageSettings[page].mainColor}
              backgroundColor={pageSettings[page].mainColor}
              data={maskInput({
                type: MASKS.DOUBLE,
                value: `${
                  page === 'stocks'
                    ? Math.round(kpiAccumulated)
                    : kpiAccumulated
                }`,
              })}
              title={pageSettings[page].labelKpiAccumulated.title}
              subtitle={pageSettings[page].labelKpiAccumulated.subtitle}
            />
          </div>
        )}
        {gmd && typeof gmd === 'object' && (
          <div className="pt-2 ">
            <Indicator
              multiple
              icon={pageSettings[page].gmd.icon}
              iconLabel={gmd.female ? 'GMD Fêmea (kg)' : ''}
              iconValue={
                maskInput({
                  type: MASKS.NUMBER,
                  value: `${gmd.female}`,
                  scale: 3,
                }) || ''
              }
              iconSize={4}
              subtitlePosition="top"
              color={pageSettings[page].secondaryColor}
              iconSide="right"
              iconColor={pageSettings[page].secondaryColor}
              iconBackgroundColor={pageSettings[page].mainColor}
              backgroundColor={pageSettings[page].mainColor}
              data={{
                'GMD Confinamento (kg)': `${maskInput({
                  type: MASKS.NUMBER,
                  value: `${gmd.general}`,
                  scale: 3,
                })} kg`,
                'GMD Macho (kg)': `${maskInput({
                  type: MASKS.NUMBER,
                  value: `${gmd.male}`,
                  scale: 3,
                })} kg`,
              }}
            />
          </div>
        )}
      </div>
      {normalizedBarChartData && (
        <Row className="d-flex justify-content-center py-5 page-break">
          <Col xs={10} className="cards p-0">
            <h5 className="d-flex flex-column align-items-center">
              {pageSettings[page].labelBarChart.title}
            </h5>
            <Bar
              responsive
              label={pageSettings[page].labelBarChart.title}
              data={normalizedBarChartData}
              options={{
                legend: {
                  display: false,
                },
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 40,
                    bottom: 0,
                  },
                },
                plugins: {
                  datalabels: {
                    labels: {
                      title: {
                        font: {
                          size: 16,
                        },
                      },
                    },
                    anchor: (val) => {
                      if (val.dataset.data[val.dataIndex] >= 0) {
                        return 'end';
                      }
                      return 'start';
                    },
                    align: (val) => {
                      if (val.dataset.data[val.dataIndex] >= 0) {
                        return 'top';
                      }
                      return 'bottom';
                    },
                    formatter: (value) => {
                      return maskInput({
                        type: MASKS.DOUBLE,
                        value: `${value}`,
                      });
                    },
                  },
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        fontSize: 15,
                      },
                      gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                      },
                      ticks: {
                        display: false,
                        // Create scientific notation labels
                        callback: (value) => {
                          return maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          });
                        },
                        beginAtZero: true,
                        stepSize: pageSettings[page].labelBarChart.stepSize
                          ? pageSettings[page].labelBarChart.stepSize
                          : null,
                      },
                    },
                  ],
                },
              }}
            />
          </Col>
        </Row>
      )}

      {normalizedPieChartData && (
        <Row className="d-flex justify-content-center py-5 page-break">
          <Col xs={10}>
            <h5 className="d-flex flex-column align-items-center">
              {pageSettings[page].labelPieChart.title}
            </h5>
            <Pie
              responsive
              data={normalizedPieChartData}
              width={window.innerWidth < 767 ? '100%' : null}
              options={{
                legend: {
                  labels: {
                    fontSize: 16,
                  },
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                tooltips: {
                  callbacks: {
                    label: (tooltipItem, data) => {
                      return `${
                        pageSettings[page].labelPieChart.prefix || ' '
                      } ${maskInput({
                        type: MASKS.DOUBLE,
                        value: `${
                          pageSettings[page].labelPieChart.round
                            ? Math.round(
                                data.datasets[0].data[tooltipItem.index],
                              )
                            : data.datasets[0].data[tooltipItem.index]
                        }`,
                      })}`;
                    },
                  },
                },
                pieceLabel: [
                  {
                    render: 'label',
                    fontColor: '#000',
                    position: 'outside',
                    arc: false,
                    fontSize: 16,
                  },
                  {
                    render: pageSettings[page].labelPieChart.prefix
                      ? (args) => {
                          return `${
                            pageSettings[page].labelPieChart.prefix
                          } ${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${
                              pageSettings[page].labelPieChart.round
                                ? Math.round(args.value)
                                : args.value
                            }`,
                          })}`;
                        }
                      : 'percentage',
                    // pageSettings[page].labelPieChart.prefix || 'percentage',
                    fontSize: 16,
                    fontColor: '#000',
                  },
                ],
              }}
            />
          </Col>{' '}
        </Row>
      )}

      {monthlyBarChart && normalizedBarChartDataMonthly && (
        <Row className="d-flex justify-content-center py-5 page-break">
          <Col xs={10} className="cards p-0">
            <h5 className="d-flex flex-column align-items-center">
              {pageSettings[page].labelBarChartMonthly.title}
            </h5>
            <Bar
              responsive
              label={pageSettings[page].labelBarChartMonthly.title}
              data={normalizedBarChartDataMonthly}
              options={{
                legend: {
                  display: false,
                },
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 40,
                    bottom: 0,
                  },
                },
                plugins: {
                  datalabels: {
                    labels: {
                      title: {
                        font: {
                          size: 16,
                        },
                      },
                    },
                    anchor: (val) => {
                      if (val.dataset.data[val.dataIndex] >= 0) {
                        return 'end';
                      }
                      return 'start';
                    },
                    align: (val) => {
                      if (val.dataset.data[val.dataIndex] >= 0) {
                        return 'top';
                      }
                      return 'bottom';
                    },
                    formatter: (value) => {
                      return maskInput({
                        type: MASKS.DOUBLE,
                        value: `${value}`,
                      });
                    },
                  },
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                        display: false,
                      },
                      ticks: {
                        fontSize: 15,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                      },
                      ticks: {
                        fontSize: 15,
                        display: false,
                        // Create scientific notation labels
                        callback: (value) => {
                          return maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          });
                        },
                      },
                    },
                  ],
                },
              }}
            />
          </Col>
        </Row>
      )}

      {table && (
        <Table
          title={`${pageSettings[page].labelTable.title}`}
          columns={pageSettings[page].labelTable.columns}
          formatter={(value, column) => {
            if (column.indexOf('data') > -1) {
              if (pageSettings[page].labelTable.round) {
                return maskInput({
                  type: MASKS.DOUBLE,
                  value: `${Math.round(value)}`,
                });
              }
              return maskInput({type: MASKS.DOUBLE, value: `${value}`});
            }

            return value;
          }}
          rows={table}
          gridTemplate="2fr 1fr 1fr"
        />
      )}
    </div>
  );
};

export default DashboardLivestock;
