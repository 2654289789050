import React from 'react';
import {GuardedRoute} from 'react-router-guards';
import FarmsDetails from '../FarmsDetails/FarmsDetails';
import Livestock from '../Livestock/Livestock';
import Team from '../Team/Team';
import Customers from '../Customer/Customer';
import Harvests from '../Harvests/Harvests';
import './style.scss';
import Machine from '../Machine/Machine';
import Stock from '../Stock/Stock';
import Climates from '../Climates/Climates';
import Financials from '../Financials/Financials';
import HarvestsDetails from '../HarvestsDetails/HarvestsDetails';
import Farm from '../Farm/Farm';
import Dashboards from '../Dashboards';
import Settings from '../Settings/Settings';
import logoLancamentos from '../../assets/images/logo_lancamentos.png';
import Exportation from '../Exportation';
import Processing from '../Processing';
import Technical from '../Users/Technical/Technical';
import Admin from '../Users/Admin/Admin';
import Checklist from '../Checklist/Checklist';
import Herd from '../Diagnosis/Herd/Herd';
import Administrative from '../Diagnosis/Administrative/Administrative';
import EnvironmentCategories from '../Categories/EnvironmentCategories';
import Benchmark from '../Benchmark';
import SyntheticWithData from '../Checklist/Synthetic/SyntheticWithData';
import Communication from '../Communication';
import Faq from '../Communication/QuestionFaq/faq';
import QuestionFaq from '../Communication/QuestionFaq/questionFaq';
import Import from '../Import';

const logoMap = {
  '/lancamentos': logoLancamentos,
  '/diagnostico': logoLancamentos,
};

const Entries = ({match}) => {
  return (
    <>
      <div className={`entries-content ${match.url.replace('/corte/' || '/leite/', '')}`}>
        <div className="control-pane" style={{height: '100%', overflow: 'auto'}}>
          <div className="control-section">
            <div className="col-md-12 p-0 h-100">
              {logoMap[window.location.pathname] && (
                <div className="entries-logo-container">
                  <img className="entries-logo" src={logoMap[window.location.pathname]} alt="" />
                </div>
              )}
              <GuardedRoute path={`${match.url}/administrativo`} meta={{moduleName: 'Administrativo'}} component={Administrative} />
              <GuardedRoute path={`${match.url}/rebanho`} meta={{moduleName: 'Rebanho'}} component={Herd} />
              <GuardedRoute path={`${match.url}/caracteriza-fazenda`} meta={{moduleName: 'Caracteriza fazenda'}} component={FarmsDetails} />
              <GuardedRoute path={`${match.url}/detalhes-fazenda`} meta={{moduleName: 'Caracteriza fazenda'}} component={FarmsDetails} />
              <GuardedRoute path={`${match.url}/equipe`} meta={{moduleName: 'Equipe'}} component={Team} />
              <GuardedRoute path={`${match.url}/pecuaria`} meta={{moduleName: 'Pecuária'}} component={Livestock} />
              <GuardedRoute path={`${match.url}/financeiro`} meta={{moduleName: 'Financeiro'}} component={Financials} />
              <GuardedRoute path={`${match.url}/maquinas`} meta={{moduleName: 'Máquinas'}} component={Machine} />
              <GuardedRoute path={`${match.url}/clientes`} meta={{moduleName: 'Clientes'}} component={Customers} />
              <GuardedRoute path={`${match.url}/safra`} meta={{moduleName: 'Safra'}} component={Harvests} />
              <GuardedRoute path={`${match.url}/caracteriza-safra`} meta={{moduleName: 'Caracteriza Safra'}} component={HarvestsDetails} />
              <GuardedRoute path={`${match.url}/tecnico`} meta={{moduleName: 'Técnicos'}} component={Technical} />
              <GuardedRoute path={`${match.url}/admin`} meta={{moduleName: 'Administradores'}} component={Admin} />
              <GuardedRoute path={`${match.url}/estoque`} meta={{moduleName: 'Estoque'}} component={Stock} />
              <GuardedRoute path={`${match.url}/fazenda`} meta={{moduleName: 'Fazenda'}} component={Farm} />
              <GuardedRoute path={`${match.url}/categorias`} meta={{moduleName: 'Categorias'}} component={EnvironmentCategories} />
              <GuardedRoute path={`${match.url}/clima`} meta={{moduleName: 'Clima'}} component={Climates} />
              <GuardedRoute path={`${match.url}/confira-lancamentos`} meta={{moduleName: 'Lançamentos'}} component={SyntheticWithData} />
              
              <GuardedRoute path="/corte/dashboards" meta={{moduleName: 'Dashboards'}} component={Dashboards} />
              <GuardedRoute path="/corte/configuracao" meta={{moduleName: 'Configurações'}} component={Settings} />
              <GuardedRoute path="/corte/checklist" meta={{moduleName: 'Checklist'}} component={Checklist} />
              <GuardedRoute path="/corte/exportacao" meta={{moduleName: 'Exportação'}} component={Exportation} />
              <GuardedRoute path="/corte/import" meta={{moduleName: 'Importação'}} component={Import} />
              <GuardedRoute path="/corte/processamento" meta={{moduleName: 'Processamento'}} component={Processing} />
              <GuardedRoute path="/corte/benchmark" meta={{moduleName: 'Benchmark'}} component={Benchmark} />
              <GuardedRoute path="/corte/comunicacao" meta={{moduleName: 'Comunicação'}} component={Communication} />
              <GuardedRoute path="/corte/faq" meta={{moduleName: 'Faq'}} component={Faq} />
              <GuardedRoute path="/corte/faq-categoria/:id" meta={{moduleName: 'Faq'}} component={QuestionFaq} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Entries;
