import api from '../api';

const fetchCategoryFatteningHerdSupplementation = (params = {}) => {
  return api.get(`/category_fattening_herd_supplementation`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryFatteningHerdSupplementationbyId = (id, params = {}) => {
  return api.get(`/category_fattening_herd_supplementation/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryFatteningHerdSupplementation = (body = {}) => {
  return api.post(`/category_fattening_herd_supplementation`, body);
};

const changeCategoryFatteningHerdSupplementationbyId = (id, body = {}) => {
  return api.put(`/category_fattening_herd_supplementation/${id}`, body);
};

const deleteCategoryFatteningHerdSupplementation = (id) => {
  return api.delete(`/category_fattening_herd_supplementation/${id}`);
};

export {
  fetchCategoryFatteningHerdSupplementation,
  fetchCategoryFatteningHerdSupplementationbyId,
  registerCategoryFatteningHerdSupplementation,
  changeCategoryFatteningHerdSupplementationbyId,
  deleteCategoryFatteningHerdSupplementation,
};
