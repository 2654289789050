import api from '../api';
import apiNew from '../apiNew';

const fetchLiveStockStocks = (params = {}, environment = 'BEEF_CATTLE') => {
  return api.get(`/livestock_stocks`, {
    params: {environment, ...params},
  });
};

const fetchLiveStockStocksGrouped = (params = {}) => {
  return apiNew.get(`/livestock_stocks/livestock_datagrid`, {
    params: {...params},
  });
};
const updateLiveStockStocks = (body = {}, environment = 'BEEF_CATTLE') => {
  return api.post(`/livestock_stocks`, {environment, ...body});
};
const updateLiveStockAreas = (body = {}) => {
  return api.post(`/areas`, body);
};
const deleteLiveStockAreas = (id) => {
  return api.delete(`/areas/${id}`);
};
const fetchLiveStockStocksnyId = (params = {}, environment = 'BEEF_CATTLE') => {
  return api.get(`/livestock_stocks`, {
    params: {environment, ...params},
  });
};
const createLiveStockreproductionMountHerding = (body = {}) => {
  return api.post(`/reproduction_mount_herding`, body);
};

const updateLiveStockreproductionMountHerding = (id, body = {}) => {
  return api.put(`/reproduction_mount_herding/${id}`, body);
};

const fetchLiveStockAreas = (params = {}) => {
  return apiNew.get(`/areas/livestock_datagrid`, {
    params: {
      ...params,
    },
  });
};

const fetchLiveReproductionMountHerding = (params = {}) => {
  return apiNew.get(`/reproduction_mount_herding/reproduction_datagrid`, {
    params: {
      ...params,
    },
  });
};
const fetchLiveReproductionWeaning = (params = {}) => {
  return api.get(`/reproduction_weaning`, {
    params: {
      ...params,
    },
  });
};
const fetchLiveReproductionBirth = (params = {}) => {
  return api.get(`/reproduction_birth`, {
    params: {
      ...params,
    },
  });
};
const fetchBirthWeaning = (params = {}) => {
  return apiNew.get(`/reproduction_birth/reproduction_datagrid`, {
    params: {
      ...params,
    },
  });
};
const updateLiveReproductionBirth = (body = {}) => {
  delete body.customer_id;
  return api.post(`/reproduction_birth`, body);
};

const updateReproductionWeaning = (body = {}) => {
  delete body.customer_id;
  return api.post(`/reproduction_weaning`, body);
};

export {
  fetchLiveStockStocks,
  fetchLiveStockStocksnyId,
  fetchLiveStockAreas,
  fetchLiveReproductionMountHerding,
  updateLiveStockStocks,
  updateLiveStockAreas,
  updateLiveStockreproductionMountHerding,
  createLiveStockreproductionMountHerding,
  fetchLiveReproductionWeaning,
  updateLiveReproductionBirth,
  updateReproductionWeaning,
  fetchLiveReproductionBirth,
  deleteLiveStockAreas,
  fetchLiveStockStocksGrouped,
  fetchBirthWeaning,
};
