export class DatagridActionsHelper {
  static actions({handleDelete = null}, removeField) {
    return [
      {title: 'Adicionar', action: 'add', icon: 'fas fa-plus', hide: removeField?.add},
      {title: 'Editar', action: 'edit', icon: 'fas fa-pencil-alt', hide: removeField?.edit},
      {
        title: 'Excluir',
        action: 'remove',
        icon: 'fas fa-trash-alt',
        onClick: handleDelete,
        hide: removeField?.remove,
      },
      {title: 'Salvar', action: 'save', icon: 'fas fa-save', hide: removeField?.save},
      {title: 'Cancelar', action: 'cancel', icon: 'fas fa-times', hide: removeField?.cancel},
    ];
  }
}
