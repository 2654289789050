import React, {useState, useRef} from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {updatePassword, forgotPassword} from '../../services/user';

import LoginContainer from './LoginContainer';

import './UpdatePassword.scss';

const Login = () => {
  const {username} = useParams();
  const inputConfirmationCode = useRef(null);
  const inputNewPassword = useRef(null);
  const [msg, setMsg] = useState('');

  const history = useHistory();

  const handleUpdatePassword = async () => {
    const confirmationCode = inputConfirmationCode.current.value;

    if (confirmationCode.trim().length === 0) {
      setMsg('Informe o código de confirmação que foi enviado para o seu e-mail');
      return;
    }

    const newPassword = inputNewPassword.current.value;

    if (newPassword.trim().length === 0) {
      setMsg('Informe a nova senha');
      return;
    }

    try {
      await updatePassword(username, newPassword, confirmationCode);
      history.push('/login');
    } catch (e) {
      if (e.response.status === 404) {
        setMsg('Usuário não encontrado');
      } else if (e.response.status === 422) {
        if (e.response.data.message === 'Invalid code') {
          setMsg('O código informado é inválido. Verifique no seu e-mail se esse é o ultimo código enviado.');
        } else if (e.response.data.message === 'Code expired') {
          setMsg('O código informado expirou. Solicite o envio de um novo código.');
        } else if (e.response.data.message === 'Invalid password') {
          setMsg(
            'A senha deve conter no mínimo 8 caracteres. Esses caracteres devem incluir ao menos uma letra maiúscula, um número e um caractere especial. Ex: Pgdsm@2023',
          );
        } else {
          setMsg(`Informações inválidas. ${e.response.data.message}`);
        }
      } else {
        setMsg('Falha ao solicitar a troca da senha');
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      await forgotPassword(username);
      setMsg('Pronto! Um novo código foi enviado para o seu e-mail, aguarde alguns minutos.');
    } catch (e) {
      if (e.response.status === 400) {
        setMsg('O serviço de autenticação não tem permissão para usar o e-mail do usuário');
      } else if (e.response.status === 404) {
        setMsg('e-mail não encontrado');
      } else {
        setMsg('Falha ao solicitar a troca da senha');
      }
    }
  };

  return (
    <LoginContainer>
      <div className="form-update-password">
        <Link to="/login" className="linkGoBackLogin">
          <ArrowBackIcon />
          Voltar para login
        </Link>

        <div className="title">Nova senha</div>
        <div className="subtitle-update-password">
          Enviamos um e-mail com as instruções para redefinir a sua senha. Caso não receba o e-mail imediatamente, por favor,{' '}
          <strong>verifique sua caixa de spam.</strong>
        </div>
        <div className="subtitle-update-password">{msg}</div>
        <input placeholder="Código de confirmação" type="text" ref={inputConfirmationCode} autoComplete="one-time-code" />
        <input placeholder="Nova senha" type="password" ref={inputNewPassword} autoComplete="new-password" />
        <small id="passwordHelpBlock" className="form-text text-muted">
          A senha deve conter no mínimo 8 caracteres. Esses caracteres devem incluir ao menos uma letra maiúscula, um número e um caractere especial. Ex:
          Pgdsm@2023
        </small>

        <button type="button" onClick={handleUpdatePassword} className="btnLogin">
          Salvar nova senha
        </button>

        <button type="button" onClick={handleForgotPassword} className="btn btn-link">
          Reenviar código
        </button>
      </div>
    </LoginContainer>
  );
};

export default Login;
