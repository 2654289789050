// eslint-disable-next-line import/no-cycle
import api from '../api';

const signUp = (username, password) => {
  return api.post(`/users/token`, {username, password});
};

const forgotPassword = (username) => {
  return api.post(`/users/forgot-password`, {username});
};

const updatePassword = (username, password, confirmationCode) => {
  return api.put(`/users/password`, {username, password, confirmationCode});
};

const fetchUsers = (params = {}) => {
  return api.get(`/users`, {
    params: {
      ...params,
    },
  });
};
const fetchUserMe = ({params = {}, token}) => {
  return api.get(`/users/me`, {
    params: {
      ...params,
    },
    headers: {
      Authorization: token,
    },
  });
};

const fetchUserbyId = (id, params = {}) => {
  return api.get(`/users/${id}`, {
    params: {
      ...params,
    },
  });
};

const fetchUserbyEmail = (email, token) => {
  return api.get(`/users/me`, {
    params: {
      'wherein[email]': email,
    },
    headers: {
      Authorization: token,
    },
  });
};

const changeUsersbyId = (id, body = {}) => {
  return api.put(`/users/${id}`, body);
};

const registerUser = (body = {}) => {
  return api.post(`/users`, body);
};

const deleteUser = (id) => {
  return api.delete(`/users/${id}`);
};

export {signUp, forgotPassword, updatePassword, fetchUserMe, fetchUsers, fetchUserbyId, fetchUserbyEmail, changeUsersbyId, registerUser, deleteUser};
