import api from '../../../../services/api';

export const ServicesHelper = {
  list: async (endpoint, params = {}) => {
    return api.get(endpoint, {params});
  },

  post: async (endpoint, body = {}) => {
    return api.post(endpoint, body);
  },

  put: async (endpoint, body = {}) => {
    return api.put(endpoint, body);
  },

  delete: async (endpoint) => {
    return api.delete(endpoint);
  },

  loadCategory: async (endpoint, params = {}) => {
    try {
      const {data} = await api.get(endpoint, {
        params: {
          ...params,
        },
      });

      return data.resultList
        .map((item) => ({
          value: item.id,
          label: item.title,
        }))
        .sort((a, b) => -b.label.localeCompare(a.label));
    } catch (e) {
      return [];
    }
  },
};
