import api from '../api';

const fetchAreaDistribution = (params = {}) => {
  return api.get(`/area_distribution`, {
    params: {
      ...params,
    },
  });
};

const fetchAreaDistributionDatagrid = (params = {}) => {
  return api.get(`/area_distribution/datagrid`, {
    params: {
      ...params,
    },
  });
};

const fetchAreaDistributionbyId = (id, params = {}) => {
  return api.get(`/area_distribution/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeAreaDistributionbyId = (id, body = {}) => {
  return api.put(`/area_distribution/${id}`, body);
};

const registerAreaDistribution = (body = {}) => {
  return api.post(`/area_distribution`, body);
};

const deleteAreaDistribution = (id) => {
  return api.delete(`/area_distribution/${id}`);
};

export {
  fetchAreaDistribution,
  fetchAreaDistributionDatagrid,
  fetchAreaDistributionbyId,
  changeAreaDistributionbyId,
  registerAreaDistribution,
  deleteAreaDistribution,
};
