import React, {useState, useRef} from 'react';
import {useHistory, Link} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {forgotPassword} from '../../services/user';

import LoginContainer from './LoginContainer';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  const inputUsername = useRef(null);
  const [msg, setMsg] = useState('');

  const history = useHistory();

  const handleForgotPassword = async () => {
    const username = inputUsername.current.value;

    if (username.trim().length === 0) {
      setMsg('Informe o login');
      return;
    }

    try {
      await forgotPassword(username);
      history.push(`/update-password/${username}`);
    } catch (e) {
      if (e.response.status === 400) {
        setMsg('O serviço de autenticação não tem permissão para usar o e-mail do usuário');
      } else if (e.response.status === 404) {
        setMsg('e-mail não encontrado');
      } else {
        setMsg('Falha ao solicitar a troca da senha');
      }
    }
  };

  return (
    <LoginContainer>
      <div className="form-forgot-password">
        <Link to="/login" className="linkGoBackLogin">
          <ArrowBackIcon />
          Voltar para login
        </Link>

        <div className="title">Esqueceu sua senha?</div>
        <div className="subtitle-forgot-password">
          Não se preocupe, basta informar o e-mail cadastrado no campo abaixo e você receberá instruções para redefinir a sua senha. Caso não receba o e-mail
          imediatamente, por favor, <strong>verifique sua caixa de spam.</strong>
        </div>
        <div className="subtitle-forgot-password">{msg}</div>
        <input placeholder="Login" type="text" name="username" ref={inputUsername} autoComplete="username" />
        <button type="button" onClick={handleForgotPassword}>
          Enviar
        </button>
      </div>
    </LoginContainer>
  );
};

export default ForgotPassword;
