/* eslint-disable camelcase */
/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {maskInput, createOrUpdateDataFull} from '../../../../utils/utils';
import {fetchFinancials, deleteFinancials, changeFinancialsbyId, createFinancials} from '../../../../services/financials';
import {MASKS} from '../../../../utils/constants';

const Financing = () => {
  // const [diferences, setDiferences] = useState(null);
  const [revenue, setRevenue] = useState([]);
  const [payments, setPayments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsPayments, setTotalItemsPayments] = useState(0);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const dataFactory = {
    date: `30/06/${state?.harvest?.last_year} 00:00:00`,
    period: true,
    status: false,
    validated: true,
  };

  const columns = (title) => [
    {
      tooltip: `Específico para ${title} provenientes de financiamentos,\npúblicos os privados.`,
      field: 'label',
      name: title,
      disableFilter: true,
      subFields: [
        {
          key: 'description',
          field: 'description',
          name: 'Descrição',
          textAlign: 'Center',
          // width: '50%',
          editType: 'textedit',
          required: true,
        },
        {
          key: 'value',
          field: 'value',
          required: true,
          name: 'Valor (R$)',
          textAlign: 'Center',
          editType: 'moneyedit',
        },
      ],
    },
  ];
  useEffect(() => {
    const getContents = async () => {
      try {
        const responseRevenue = await fetchFinancials({
          type: 'OUTROS_CREDITOS',
          'wherein[costCenter.id]': 300,
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItems(responseRevenue.data.size);
        setRevenue(responseRevenue.data.resultList);
        const responsePayments = await fetchFinancials({
          type: 'DESPESAS',
          'wherein[costCenter.id]': 11,
          ...stateFetchParams,
          ...filterParams,
        });
        setTotalItemsPayments(responsePayments.data.size);
        setPayments(responsePayments.data.resultList);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [update, stateFetchParams]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const res = await deleteFinancials(value.id);
          setUpdate((prev) => !prev);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      <div className="livesotckStock fixedHeader financing">
        <DataGrid
          onChange={async (dataGridInputs) => {
            try {
              const dataGridFactory = {
                ...dataGridInputs,
                ...dataFactory,
                cost_center: {id: 300},
                type: 'OUTROS_CREDITOS',
              };
              const res = await createOrUpdateDataFull(dataGridFactory, state, createFinancials, changeFinancialsbyId, false);
              setUpdate(!update);
              return res;
            } catch (error) {
              console.error(error);
              return error;
            }
          }}
          header={columns('Receitas')}
          data={revenue}
          disabledPaginate
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          // width="max-content"
          editable=""
          actions={actions}
          totalItems={totalItems}
          accumulators={{
            Total: {
              columns: ['value'],
              itemOperation: (acc, line, key) => {
                return (acc += line[key]);
              },
              resultOperation: (acc) => {
                return maskInput({value: acc, type: MASKS.MONEY});
              },
            },
          }}
        />
      </div>
      <div className="livesotckStock fixedHeader mt-2">
        <DataGrid
          onChange={async (dataGridInputs) => {
            const dataGridFactory = {
              ...dataGridInputs,
              ...dataFactory,
              cost_center: {id: 11},
              type: 'DESPESAS',
            };
            await createOrUpdateDataFull(dataGridFactory, state, createFinancials, changeFinancialsbyId, false);
            setUpdate(!update);
            return null;
          }}
          header={columns('Pagamentos')}
          data={payments}
          disabledPaginate
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItemsPayments}
          accumulators={{
            Total: {
              columns: ['value'],
              itemOperation: (acc, line, key) => {
                return (acc += line[key]);
              },
              resultOperation: (acc) => {
                return maskInput({value: acc, type: MASKS.MONEY});
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default Financing;
