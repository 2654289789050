import apiNew from '../apiNew';

const fetchCategoryFatteningSupplementationLevel = (params = {}) => {
  return apiNew.get(`/category_fattening_supplementation_level`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryFatteningSupplementationLevelbyId = (id, params = {}) => {
  return apiNew.get(`/category_fattening_supplementation_level/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryFatteningSupplementationLevel = (body = {}) => {
  return apiNew.post(`/category_fattening_supplementation_level`, body);
};

const changeCategoryFatteningSupplementationLevelbyId = (id, body = {}) => {
  return apiNew.put(`/category_fattening_supplementation_level/${id}`, body);
};

const deleteCategoryFatteningSupplementationLevel = (id) => {
  return apiNew.delete(`/category_fattening_supplementation_level/${id}`);
};

export {
  fetchCategoryFatteningSupplementationLevel,
  fetchCategoryFatteningSupplementationLevelbyId,
  registerCategoryFatteningSupplementationLevel,
  changeCategoryFatteningSupplementationLevelbyId,
  deleteCategoryFatteningSupplementationLevel,
};
