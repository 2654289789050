import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {FormSelect} from '../form';

const SelectOption = ({
  name,
  title,
  isRequired,
  options = [],
  tabIndex,
  hidden,
  ...el
}) => {
  const handleChange = (event) => {
    el.setFieldValue(name, event.currentTarget.value);
  };

  if (hidden && options.length === 1) {
    return <Field type="hidden" name={name} value={options[0].value} />;
  }

  return (
    <Field
      as={FormSelect}
      name={name}
      tabIndex={tabIndex}
      onChange={handleChange}>
      {options.map((option, index) => (
        <option key={`${name}-${index}`} value={option.value}>
          {option.text}
        </option>
      ))}
    </Field>
  );
};

SelectOption.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tabIndex: PropTypes.number,
  hidden: PropTypes.bool,
};

export default SelectOption;
