/* eslint-disable no-shadow */
import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import {createOrUpdateData} from '../../../utils/utils';
import {fetchCities, registerCity, changeCitybyId, deleteCity} from '../../../services/city';
import {fetchCountries, fetchCountrybyId} from '../../../services/country';
import {fetchRegions} from '../../../services/regions';
import {fetchStates} from '../../../services/states';

const City = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const removeKeys = (dataGridInputs) => {
    if (dataGridInputs.data) {
      const resp = dataGridInputs.data;
      Object.keys(resp).forEach((key) => {
        const arr = key.split('.');

        if (arr.length > 1) {
          Object.assign(resp, {
            [`${arr[1]}`]: resp[key],
          });
        }
      });
      return resp;
    }
    return null;
  };
  useEffect(() => {
    if (paginationParams && !paginationParams['order[title]']) {
      Object.assign(paginationParams, {'order[title]': 'asc'});
    }
    const loadData = async () => {
      const response = await fetchCities({
        ...paginationParams,
        ...filterParams,
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);

  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      required: true,
      textAlign: 'Center',
      width: '30%',
      editType: 'defaultedit',
    },
    {
      key: 'state.region.country',
      valueKey: 'state.region.country.title',
      field: 'state.region.country',
      name: 'País',
      required: true,
      width: '20%',
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCountries();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'zip_code',
      field: 'zip_code',
      valueKey: 'zip_code',
      name: 'CEP',
      textAlign: 'Center',
      dependencies: ['country'],
      width: '20%',
      editType: 'cepedit',
      mask: async (state, dataGridState) => {
        try {
          let id = null;
          if (dataGridState && dataGridState.data && dataGridState.data['state.region.country']) {
            id = dataGridState.data['state.region.country'].value;
          } else if (
            dataGridState &&
            dataGridState.data &&
            dataGridState.data.state &&
            dataGridState.data.state.region &&
            dataGridState.data.state.region.country &&
            dataGridState.data.state.region.country.id
          ) {
            id = dataGridState.data.state.region.country.id;
          }
          if (id) {
            const response = await fetchCountrybyId(id);
            return {pattern: response.data.zip_pattern};
          }
        } catch (e) {
          return '99999-999';
        }
        return '99999-999';
      },
    },
    {
      key: 'state.region',
      valueKey: 'state.region.title',
      field: 'state.region',
      name: 'Região',
      width: '15%',
      required: true,
      dependencies: ['state.region.country'],
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async (state, dataGridState) => {
        const data = removeKeys(dataGridState);

        let countryId = null;
        if (data && data['state.region.country']) {
          countryId = data['state.region.country'].id || data['state.region.country'].value;
        }

        if (!countryId) {
          return [];
        }
        try {
          const response = await fetchRegions({
            'wherein[country.id]': countryId,
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'state',
      valueKey: 'state.title',
      field: 'state',
      name: 'Estado',
      required: true,
      width: '15%',
      textAlign: 'Center',
      dependencies: ['state.region'],
      editType: 'dropdownedit',
      optionsFetch: async (state, dataGridState) => {
        const data = removeKeys(dataGridState);
        let regionId = null;
        if (data && data['state.region']) {
          regionId = data['state.region'].id || data['state.region'].value;
        }

        if (!regionId) {
          return [];
        }

        try {
          const response = await fetchStates({
            'wherein[region.id]': regionId,
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteCity(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {data && (
        <>
          <DataGrid
            onChange={async (dataGridInputs) => {
              // dataGridInputs = removeKeys(dataGridInputs);
              delete dataGridInputs['state.region.country'];
              delete dataGridInputs['state.region'];
              delete dataGridInputs.region;

              const response = await createOrUpdateData(dataGridInputs, state, registerCity, changeCitybyId, false);
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            width="100%"
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
          />
          <p className="p-3 m-0">
            <small>
              Para aplicar uma nova máscara a um <b>CEP</b> é necessário editá-lo selecionando o correspondente país e alterando no mínimo um caractere, para
              que o sistema identifique que é necessário alterar o valor do campo no banco de dados. Se o Páis correspondente já estiver selecionando e a
              máscara não estiver aplicada basta editar no mínimo um caractere para que o sistema identifique a mesma necessidade citada anteriormente.
            </small>
          </p>
        </>
      )}
    </>
  );
};

export default City;
