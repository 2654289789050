import apiNew from '../apiNew';

const fetchCategoryProcessingFormats = (params = {}) => {
  return apiNew.get(`/category_processing_formats`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryProcessingFormatsbyId = (id, params = {}) => {
  return apiNew.get(`/category_processing_formats/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryProcessingFormats = (body = {}) => {
  return apiNew.post(`/category_processing_formats`, body);
};

const changeCategoryProcessingFormatsbyId = (id, body = {}) => {
  return apiNew.put(`/category_processing_formats/${id}`, body);
};

const deleteCategoryProcessingFormats = (id) => {
  return apiNew.delete(`/category_processing_formats/${id}`);
};

export {
  fetchCategoryProcessingFormats,
  fetchCategoryProcessingFormatsbyId,
  registerCategoryProcessingFormats,
  changeCategoryProcessingFormatsbyId,
  deleteCategoryProcessingFormats,
};
