import api from '../api';

const fetchProcessingXLS = (params = {}, responseType = '', headers = {}) => {
  return api.get(`/processing/xls`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};
const fetchProcessingPDF = (params = {}, responseType = '', headers = {}) => {
  return api.get(`/processing/pdf`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};

export {fetchProcessingXLS, fetchProcessingPDF};
