import {fetchCategoryDsmProduct} from '../../services/categoryDsmProduct';
import {fetchCategoryMaterialType} from '../../services/categoryMaterialType';
import {fetchCategoryUnitMeasurement} from '../../services/categoryUnitMeasurement';
import {ROLES} from '../../utils/constants';

const dateHarvestOptions = (harvestFirstYear, harvestLastYear) => [
  {
    value: false,
    label: `01/07/${harvestFirstYear}`,
  },
  {
    value: true,
    label: `30/06/${harvestLastYear}`,
  },
];

const validationFindData = (data) => {
  let validated = true;
  data.forEach((row) => {
    validated = validated && row.validated;
  });

  return validated;
};

const stockColumns = (harvestFirstYear, harvestLastYear, user) => [
  {
    key: 'date_harvest',
    field: 'date_harvest',
    name: 'Início/Fim Safra',
    textAlign: 'Center',
    width: '15%',
    editType: 'dropdownedit',
    dataType: 'value',
    options: dateHarvestOptions(harvestFirstYear, harvestLastYear),
    returnFormat: (value) => {
      return value ? value.value : false;
    },
    mapper: (object, editMode) => {
      const optionSelected = dateHarvestOptions(harvestFirstYear, harvestLastYear).find((dateHarvestOption) => dateHarvestOption.value === object.date_harvest);

      if (!optionSelected) {
        return null;
      }

      if (!editMode) {
        return optionSelected.label;
      }
      return optionSelected;
    },
  },
  {
    key: 'category_material_type',
    valueKey: 'category_material_type.title',
    field: 'category_material_type',
    name: 'Tipo de insumo',
    width: '25%',
    textAlign: 'Center',
    editType: 'dropdownedit',
    optionsFetch: async () => {
      try {
        const response = await fetchCategoryMaterialType();
        const objectTemp = [];
        response.data.resultList.map((item) =>
          objectTemp.push({
            value: item.id,
            label: item.title,
          }),
        );
        return objectTemp;
      } catch (e) {
        return [];
      }
    },
  },
  {
    key: 'category_dsm_product',
    valueKey: 'category_dsm_product.title',
    field: 'category_dsm_product',
    name: 'Insumo',
    width: '20%',
    textAlign: 'Center',
    editType: 'defaultedit',
    dependencies: ['category_material_type'],
    extraParams: {
      changeTypeField: (dataGridState) => {
        if (dataGridState?.data?.category_material_type?.label === 'Insumo DSM' || dataGridState?.data?.category_material_type?.title === 'Insumo DSM') {
          return 'dropdownedit';
        }
        return 'defaultedit';
      },
    },
    optionsFetch: async () => {
      try {
        const response = await fetchCategoryDsmProduct();
        const objectTemp = [];
        response.data.resultList
          .filter((item) => item.category_material_type.title === 'Insumo DSM')
          .map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
        return objectTemp;
      } catch (e) {
        return [];
      }
    },
  },
  // {
  //   key: 'material',
  //   field: 'material',
  //   name: 'Insumo',
  //   width: '25%',
  //   textAlign: 'Center',
  //   editType: 'defaultedit',
  // },
  {
    key: 'amount',
    field: 'amount',
    name: 'Quantidade',
    textAlign: 'Center',
    width: '15%',
    editType: 'numericedit',
    popoverTitle: 'Unidades de medidas',
    popoverKey: 'category_unit_measurement',
    popoverField: 'category_unit_measurement',
    extraParams: {
      minValue: 1,
    },
    popoverOptionsFetch: async () => {
      try {
        const response = await fetchCategoryUnitMeasurement();
        const objectTemp = [];
        response.data.resultList.map((item) =>
          objectTemp.push({
            value: `${item.id}`,
            label: item.title,
          }),
        );
        return objectTemp;
      } catch (e) {
        return [];
      }
    },
    returnPopoverFormat: (value) => {
      return {id: parseInt(value, 10)};
    },
    popoverMapper: (line) => {
      return `${line.category_unit_measurement.id}`;
    },
    mapper: (object, editMode) => {
      if (!editMode) {
        return `${object.amount} ${object.category_unit_measurement.title}`;
      }
      return object.amount;
    },
  },
  {
    key: 'value',
    field: 'value',
    name: 'Valor unitário',
    textAlign: 'Center',
    width: user.user_type !== ROLES.CUSTOMER ? '15%' : '25%',
    editType: 'moneyedit',
  },
  {
    key: 'validated',
    field: 'validated',
    name: 'Validar',
    textAlign: 'Center',
    width: '10%',
    editType: 'booleanedit',
    validationHeader: {showLabel: false, validationFindData},
  },
];

export default stockColumns;
