import styled from 'styled-components';

export const ButtonGroup = styled.div`
  /* border-bottom: 1px solid #dddd; */
  display: flex;
  height: 100%;
  padding: 0 15px;
`;

export const ButtonGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 0;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 0 15px;
  & .menu-container:hover .main-btn {
    border-bottom: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin: 0 0 20px 0;
`;

export const Button = styled.button`
  background-color: transparent;
  border-bottom: 3px solid transparent;
  color: #333;
  display: flex;

  font-weight: 300;
  height: 100%;
  padding: 7px 15px;
  transition: ease all 300ms;
  width: auto;
  &:hover {
    border-bottom: 3px solid #c6c4c4;
  }
  :focus {
    border: none;
  }
`;
