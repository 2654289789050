import React, {useEffect, useState, useContext} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Breadcrumbs from '../../../components/Breadcrumbs';
import {FaqContainer, Title, QuestionsBox} from './styles';
import {fetchFaq} from '../../../services/faq';
import Loading from '../../../components/Loading/Loading';
import {DataContext} from '../../../utils/DataProvider';
import QuestionItem from './questionItem';
import {fetchCategoryFaq} from '../../../services/categoryFaq';

const QuestionFaq = ({match}) => {
  const [questions, setQuestions] = useState([]);
  const [categoryFaq, setCategoryFaq] = useState(null);
  const [loading, setLoading] = useState(true);
  const {state} = useContext(DataContext);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  useEffect(() => {
    (async () => {
      try {
        const {data} = await fetchFaq({
          environment: ENVS,
        });
        const dataToCategoryFaq = await fetchCategoryFaq({environment: ENVS});
        setCategoryFaq(dataToCategoryFaq.data.resultList);
        setQuestions(data.resultList.filter((item) => item.category_faq.id === +match.params.id));
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);// eslint-disable-line

  const css = `
  .control-section .entries-content{
    padding:0px !important;
    }
  `;

  const matchTitle = categoryFaq?.find((item) => item.id === +match.params.id && item.title);

  return (
    <>
      <FaqContainer>
        <Title>{matchTitle?.title}</Title>
        <GuardedRoute
          render={() => (
            <>
              <Breadcrumbs path={[{title: 'FAQ', url: '/corte/faq'}, {title: matchTitle?.title}]} />
            </>
          )}
        />
        {!loading ? (
          <QuestionsBox>
            {!questions.length && <h4>Nenhuma pergunta cadastrada.</h4>}
            {!!questions.length && questions.map((ques) => <QuestionItem key={ques.id} question={ques} />)}
          </QuestionsBox>
        ) : (
          <Loading />
        )}
      </FaqContainer>
      <style>{css}</style>
    </>
  );
};
export default QuestionFaq;
