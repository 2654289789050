import api from '../api';

const fetchCategoryHerdDepartureType = (params = {}) => {
  return api.get(`/category_herd_departure_type`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryHerdDepartureTypebyId = (id, params = {}) => {
  return api.get(`/category_herd_departure_type/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryHerdDepartureType = (body = {}) => {
  return api.post(`/category_herd_departure_type`, body);
};

const changeCategoryHerdDepartureTypebyId = (id, body = {}) => {
  return api.put(`/category_herd_departure_type/${id}`, body);
};

const deleteCategoryHerdDepartureType = (id) => {
  return api.delete(`/category_herd_departure_type/${id}`);
};

export {
  fetchCategoryHerdDepartureType,
  fetchCategoryHerdDepartureTypebyId,
  registerCategoryHerdDepartureType,
  changeCategoryHerdDepartureTypebyId,
  deleteCategoryHerdDepartureType,
};
