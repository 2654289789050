import api from '../../../../../services/api';

export const CategoryCommercialFeedService = {
  endpoint: '/farms_details/:id/save_list_category_commercial_feed',
  field: 'category_commercial_feed',

  save: async (id, data = []) => {
    try {
      const prepareData = CategoryCommercialFeedService.prepare(data);

      return await CategoryCommercialFeedService.put(id, prepareData);
    } catch (_) {
      throw new Error('Ração comercial: Não foi possível salvar');
    }
  },

  prepare: (data) => {
    return data.map((item) => ({
      [CategoryCommercialFeedService.field]: {
        id: item.id,
      },
    }));
  },

  put: async (id, body) => {
    return api.put(CategoryCommercialFeedService.endpoint.replace(':id', id), body);
  },
};
