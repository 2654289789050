import CryptoJS from 'crypto-js';

class SecureStorage {
  SECRET_KEY = `${process.env.REACT_APP_LOCAL_STORAGE_SECRET_KEY}`;

  hash(key) {
    const encriptedKey = CryptoJS.SHA256(key, this.SECRET_KEY);
    return encriptedKey.toString();
  }

  encrypt(data) {
    const encriptedData = CryptoJS.AES.encrypt(data, this.SECRET_KEY);

    return encriptedData.toString();
  }

  decrypt(data) {
    const encriptedData = CryptoJS.AES.decrypt(data, this.SECRET_KEY);

    return encriptedData.toString(CryptoJS.enc.Utf8);
  }

  setItem(key, data) {
    localStorage.setItem(key, this.encrypt(data));
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  getItem(key) {
    if (!key) {
      return null;
    }
    return this.decrypt(localStorage.getItem(key) || '');
  }

  clear() {
    localStorage.clear();
  }
}

const secureStorage = new SecureStorage();

export default secureStorage;
