import api from '../../../../../services/api';

export const CategoryProductiveManagement = {
  endpoint: '/farms_details/:id/save_list_category_productive_management',
  field: 'category_productive_management',

  save: async (id, data = []) => {
    try {
      const prepareData = CategoryProductiveManagement.prepare(data);

      return await CategoryProductiveManagement.put(id, prepareData);
    } catch (_) {
      throw new Error('Manejo Produtivo: Não foi possível salvar');
    }
  },

  prepare: (data) => {
    return data.map((item) => ({
      [CategoryProductiveManagement.field]: {
        id: item.id,
      },
    }));
  },

  put: async (id, body) => {
    return api.put(CategoryProductiveManagement.endpoint.replace(':id', id), body);
  },
};
