import React, {useEffect} from 'react';
import './App.css';

import {useClearCache} from 'react-clear-cache';
import DataProvider from './utils/DataProvider';
import Routes from './routes';

function App() {
  const {isLatestVersion, emptyCacheStorage} = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {
      // eslint-disable-next-line no-alert
      alert('Uma nova versão está disponível, clique em ok para atualizar');
      emptyCacheStorage();
    }
  }, [isLatestVersion]);// eslint-disable-line
  return (
    <DataProvider>
      <Routes />
    </DataProvider>
  );
}

export default App;
