import React from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../../../components/Section/Section';
import TemplateRouteData from '../../components/template-route-data';

import {Births, Death, Purchases, Sales} from './Movements';
import {MilkQuality, TechnicalInformation} from './ZootechnicalData';
import HerdEvolutionDataGrid from './General/HerdEvolutionDataGrid';

const Livestock = ({match}) => {
  const templateData = [
    {
      category: 'Movimentações',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Nascimentos',
              url: `${match.url}/movimentacoes/nascimentos`,
              component: Births,
            },
            {
              title: 'Morte',
              url: `${match.url}/movimentacoes/morte`,
              component: Death,
            },
            {
              title: 'Compras',
              url: `${match.url}/movimentacoes/compras`,
              component: Purchases,
            },
            {
              title: 'Vendas',
              url: `${match.url}/movimentacoes/vendas`,
              component: Sales,
            },
          ],
        },
      ],
    },
    {
      category: 'Evolução de Rebanho',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Geral',
              url: `${match.url}/tipo/evolucao-rebanho`,
              component: HerdEvolutionDataGrid,
            },
          ],
        },
      ],
    },
    {
      category: 'Dados Zootécnicos',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Informações técnicas',
              url: `${match.url}/dados-zootecnicos/informacoes-tecnicas`,
              component: TechnicalInformation,
            },
            {
              title: 'Qualidade do leite',
              url: `${match.url}/dados-zootecnicos/qualidade-do-leite`,
              component: MilkQuality,
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className="zoodata">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Dados Zootécnicos'}} render={() => <Section templateData={templateData} />} />

      <TemplateRouteData data={templateData} />
    </div>
  );
};
export default Livestock;
