import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../../../utils/DataProvider';
import {ExpenseServices} from '../services/ExpenseServices';
import {createOrUpdateDataFull} from '../../../../../utils/utils';
import {DatagridActionsHelper} from '../../../utils/datagridActionsHelper';
import DataGrid from '../../../../../components/DataGrid';
import {DatePickerHelper} from '../../../utils/datePickerHelper';
import {ServicesHelper} from '../../services/ServicesHelper';
import {ExpensesAgriculture} from '../../Agriculture/services/AgricultureServices';

const Expenses = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [reset, setReset] = useState(false);
  const [costCenterList, setCostCentersList] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await ExpenseServices.list({
          ...stateFetchParams,
          ...filterParams,
          type: 'DESPESAS',
        });

        const {data: currentData} = response;
        setData(
          currentData.resultList?.map((item) => {
            if (item?.cost_center_description) {
              return {...item, description: item?.cost_center_description?.title};
            }
            return item;
          }),
        );
        setTotal(currentData.size);

        const costCenterDat = await ServicesHelper.list('/cost_centers', {
          environment: 'DAIRY_CATTLE',
          type: 'DESPESAS',
        });

        setCostCentersList(costCenterDat.data.resultList.filter(
          it => it.title &&
                !['compra de rebanho', 'venda de animais'].includes(it.title.toLowerCase())
        ))

      } catch (e) {
        console.error(e);
      }
    })();
  }, [JSON.stringify(stateFetchParams).toLowerCase(), filterParams, reset]);// eslint-disable-line

  const validationFindData = (temp) => {
    const validates = temp.map((item) => item.validated);
    return validates.length && validates.includes(true);
  };

  const handleValidateCallback = async (rows, field, checked) => {
    const promises = [];
    rows.forEach((row) => {
      if (row.notCreated) return;

      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, ExpenseServices.post, ExpenseServices.put));
    });

    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  const actions = DatagridActionsHelper.actions({
    handleDelete: async (value) => {
      try {
        const response = await ExpenseServices.delete(value.id);
        setReset(!reset);
        return response;
      } catch (error) {
        return error;
      }
    },
  });

/*  const disableLine = (dataCallback, id) => {
    const row = dataCallback.find((item) => item.id === id);
    return !!row?.financial_related || !!row?.financial_related; //TODO demanda PGDSM-59
  };*/

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '15%',
      dateView: ['month'],
      dateFormat: 'MM/yyyy',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      ...DatePickerHelper.dailyAndMonth(state),
     // disabledDoubleClick: disableLine,
      disabledEdit: (col) => {
        const title = col?.data?.cost_center?.title?.toLowerCase();
        if (title === 'compra de rebanho' || title === 'venda de animais') {
          return true;
        }
        if (col?.data?.team_related) {// || col?.data?.financial_related) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'cost_center',
      valueKey: 'cost_center.title',
      field: 'cost_center',
      required: true,
      name: 'Centro de custo',
      textAlign: 'center',
      width: '25%',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await ServicesHelper.loadCategory('/cost_centers', {
            environment: 'DAIRY_CATTLE',
            type: 'DESPESAS',
          });
          if (Array.isArray(response)) {
            const filteredList = response.filter(
              it => {
                return it.label &&
                       !['compra de rebanho', 'venda de animais'].includes(it.label.toLowerCase());
              }
            );
            return filteredList;
          }
          return [];
        } catch (error) {
          console.error('Error fetching cost centers:', error);
          return [];
        }
      },
      //disabledDoubleClick: disableLine,
      disabledEdit: (col) => {console.log(col)
        const title = col?.data?.cost_center?.title?.toLowerCase();
        if (title === 'compra de rebanho' || title === 'venda de animais') {
          return true;
        }

        if (col?.data?.team_related) {//  || col?.data?.financial_related) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'description',
      valueKey: 'description',
      field: 'description',
      required: true,
      name: 'Descrição',
      textAlign: 'center',
      width: state?.user?.user_type === 'CUSTOMER' ? '30%' : '20%',
      editType: 'textedit',
      dependencies: ['cost_center'],
   //   disabledDoubleClick: disableLine,
      disabledEdit: (col) => {
        const title = col?.data?.cost_center?.title?.toLowerCase();
        if (title === 'compra de rebanho' || title === 'venda de animais') {
          return true;
        }
        if (col?.data?.team_related) {//  || col?.data?.financial_related) {
          return true;
        }
        return false;
      },
      groupBy: (option) => {
        return option?.from_agriculture;
      },
      extraParams: {
        changeTypeField: (dataGridState) => {
          const cost_center = costCenterList?.find((item) => {
            return item.id === dataGridState?.data?.cost_center?.value || item.id === dataGridState?.data?.cost_center?.id;
          });
          if (cost_center?.bulky || cost_center?.concentrated) {
            return 'dropdownedit';
          }
          return 'sugestionedit';
        },
      },
      optionsFetch: async (_state, dataGridState) => {
        let cost_center = dataGridState?.data?.cost_center?.value || dataGridState?.data?.cost_center?.id;

        if (!cost_center) {
          cost_center = dataGridState?.initialData.find((item) => item.id === dataGridState?.currentLine)?.cost_center?.id;
        }

        if (cost_center) {
          try {
            const options = await ExpenseServices.listCostCenterDescription({
              'wherecustom[costCenter.id:NULL]': `${cost_center};=`,
            });
            if (dataGridState?.data?.cost_center?.label !== 'Volumosos' && dataGridState?.data?.cost_center?.label !== 'Concentrados') {
              return options?.data?.resultList?.map((item) => ({
                value: item.title,
                // label: item.id,
                // from_agriculture: item?.from_agriculture ? 'Agricultura' : 'Pré-definidos',
              }));
            }
            return options?.data?.resultList?.map((item) => ({
              value: item.id,
              label: item.title,
              from_agriculture: item?.from_agriculture ? 'Agricultura' : 'Pré-definidos',
            }));
          } catch (e) {
            return [];
          }
        }
        return [];
      },
    },
    {
      key: 'quantity',
      field: 'quantity',
      name: 'Quantidade',
      width: '10%',
      textAlign: 'Center',
      required: true,
      editType: 'numericedit',
     // disabledDoubleClick: disableLine,
      extraParams: {
        minValue: 1,
      },
      disabledEdit: (col) => {
        const title = col?.data?.cost_center?.title?.toLowerCase();
        if (title === 'compra de rebanho' || title === 'venda de animais') {
          return true;
        }
        if (col?.data?.team_related) {//  || col?.data?.financial_related) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'value',
      field: 'value',
      name: 'Valor Total',
      width: '10%',
      textAlign: 'Center',
      editType: 'moneyedit',
     // disabledDoubleClick: disableLine,
      // dependencies: ['unit_value'],
      extraParams: {
        minValue: 1,
        formatedValue: (col) => {
          if (
            costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
            (col?.mode === 'NEW_LINE' && col.data.aux === 'unit_value') ||
            (col.mode === 'EDIT' && col?.data?.status)
          ) {
            return parseFloat(col?.data?.unit_value) * parseFloat(col?.data?.quantity) || null;
          }
          return null;
        },
      },
      required: (col) => {
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'unit_value') ||
          (col.mode === 'EDIT' && col?.data?.status)
        ) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        const title = col?.data?.cost_center?.title?.toLowerCase();
        if (title === 'compra de rebanho' || title === 'venda de animais') {
          return true;
        }
        if (col?.data?.team_related) {//  || col?.data?.financial_related) {
          return true;
        }
        if (col?.data?.value?.length > 0 && !col.data.aux) {
          col.data.aux = 'value';
        }
        if (col?.data?.value?.length === 0 && col.data.aux === 'value') {
          col.data.aux = null;
        }
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value || item.id === col?.data?.cost_center?.id)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'unit_value') ||
          (col.mode === 'EDIT' && col?.data?.status)
        ) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'unit_value',
      field: 'unit_value',
      name: 'Valor Unitário',
      width: '10%',
      textAlign: 'Center',
      //disabledDoubleClick: disableLine,
      editType: 'moneyedit',
      // dependencies: ['value'],
      extraParams: {
        minValue: 1,
        formatedValue: async (col) => {
          if (col?.data?.description?.from_agriculture === 'Agricultura') {
            const response = await ExpensesAgriculture.getCostPerKilo({
              harvest_id: state?.harvest?.id,
              farm_id: state?.farm?.id,
              cost_center_description_id: col?.data?.description?.value,
            });
            col.data.aux = 'unit_value';
            return response.data;
          }

          if (
            costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
            (col?.mode === 'NEW_LINE' && col.data.aux === 'value') ||
            (col.mode === 'EDIT' && !col?.data?.status)
          ) {
            return parseFloat(col?.data?.value) / parseFloat(col?.data?.quantity) || null;
          }
          return null;
        },
      },
      required: (col) => {
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'value') ||
          (col.mode === 'EDIT' && !col?.data?.status)
        ) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        const title = col?.data?.cost_center?.title?.toLowerCase();
        if (title === 'compra de rebanho' || title === 'venda de animais') {
          return true;
        }
        if (col?.data?.team_related) {//  || col?.data?.financial_related) {
          return true;
        }
        if (col?.data?.unit_value?.length > 0 && !col.data.aux) {
          col.data.aux = 'unit_value';
        }
        if (col?.data?.unit_value?.length === 0 && col.data.aux === 'unit_value') {
          col.data.aux = null;
        }
        if (
          costCenterList?.find((item) => item.id === col?.data?.cost_center?.value || item.id === col?.data?.cost_center?.id)?.only_quantity ||
          (col?.mode === 'NEW_LINE' && col?.data?.aux === 'value') ||
          (col.mode === 'EDIT' && !col?.data?.status)
        ) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      width: '10%',
      textAlign: 'Center',
      editType: 'booleanedit',
     // disabledDoubleClick: disableLine,
      validationHeader: {showLabel: false, validationFindData},
      disabledEdit: (col, index) => {
        const title = col?.data?.cost_center?.title?.toLowerCase();
        if (title === 'compra de rebanho' || title === 'venda de animais') {
          return true;
        }
        const row = col?.initialData?.find((item) => item.id === index);

        if (row?.team_related) {//  || row?.financial_related) {
          return true;
        }

        return false;
      },
    },
  ];

  return (
    <>
      {stateMessage}
      {data && costCenterList && (
        <DataGrid
          onChange={async (inputs) => {
            if (!inputs.date) {
              const date = DatePickerHelper.newDate();
              Object.assign(inputs, {date});
            }

            if (inputs?.validated == null) Object.assign(inputs, {validated: false});

            Object.assign(inputs, {status: false});
            inputs = {
              ...inputs,
              ...(inputs.quantity && {quantity: parseFloat(inputs.quantity)}),
              ...(inputs.value && {value: parseFloat(inputs.value)}),
              ...(inputs.unit_value && {unit_value: parseFloat(inputs.unit_value)}),
              type: 'DESPESAS',
            };

            if (inputs.value) {
              inputs.unit_value = inputs.value / inputs.quantity || 1;
              inputs.status = false;
            } else if (inputs.unit_value) {
              inputs.value = inputs.unit_value * inputs.quantity || 1;
              inputs.status = true;
            }

            if (inputs.aux === 'unit_value') {
              inputs.status = true;
            } else {
              inputs.status = false;
            }
            delete inputs.aux;

            const cost_center = costCenterList?.find((item) => {
              return item.id === inputs?.cost_center?.id;
            });
            if (cost_center?.bulky || cost_center?.concentrated) {
              inputs.cost_center_description = {id: inputs?.description?.id};
              inputs.description = cost_center?.title;
            }

            try {
              const res = await createOrUpdateDataFull(inputs, state, ExpenseServices.post, ExpenseServices.put);
              setReset(!reset);
              return res;
            } catch (error) {
              return error;
            }
          }}
          validateCallback={handleValidateCallback}
          header={columns}
          data={data}
          width="inherit"
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={total}
        />
      )}
    </>
  );
};

export default Expenses;
