const financialModuleMap = (slug) => {
  switch (slug) {
    case 'receitas':
      return 'Receitas';
    case 'despesas':
      return 'Despesas';
    case 'outros_debitos':
      return 'Outros débitos';
    case 'outros_creditos':
      return 'Outros créditos';
    default:
      return '';
  }
};

export default financialModuleMap;
