import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../../components/Section/Section';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Financing from './TeamFinancials/Financing';
import LivestockExpenses from './TeamFinancials/LivestockExpenses';
import OtherRevenue from './TeamFinancials/OtherRevenue';
import ProductionArea from './AreaProduction/ProductionArea';
import InputControl from './InputControl';

const Resources = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Área e Equipe',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Geral',
              url: `${match.url}/area-reproducao`,
            },
          ],
        },
      ],
    },
    {
      category: 'Finanças',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Financiamentos',
              url: `${match.url}/financiamentos`,
            },
            {
              title: 'Outras receitas',
              url: `${match.url}/outras-receitas`,
            },
            {
              title: 'Despesas pecuária',
              url: `${match.url}/despesas-pecuaria`,
            },
          ],
        },
      ],
    },
    {
      category: 'Estoque',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Controle de insumos',
              url: `${match.url}/controle-insumos`,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <div className="zoodata">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Administrativo'}} render={() => <Section templateData={templateData} />} />
      <GuardedRoute
        path={`${match.url}/controle-insumos`}
        meta={{moduleName: 'Rebanho'}}
        render={() => (
          <>
            <Breadcrumbs
              path={[
                {title: 'Diagnóstico', url: '/diagnostico'},
                {title: 'Administrativo', url: '/diagnostico/administrativo'},
                {title: 'Controle de Insumos'},
              ]}
            />
            <InputControl />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/financiamentos`}
        meta={{moduleName: 'Administrativo'}}
        render={() => (
          <>
            <Breadcrumbs
              path={[{title: 'Diagnóstico', url: '/diagnostico'}, {title: 'Administrativo', url: '/diagnostico/administrativo'}, {title: 'Financiamentos'}]}
            />
            <Financing />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/despesas-pecuaria`}
        meta={{moduleName: 'Administrativo'}}
        render={() => (
          <>
            <Breadcrumbs
              path={[{title: 'Diagnóstico', url: '/diagnostico'}, {title: 'Administrativo', url: '/diagnostico/administrativo'}, {title: 'Despesas Pecuária'}]}
            />
            <LivestockExpenses />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/outras-receitas`}
        meta={{moduleName: 'Administrativo'}}
        render={() => (
          <>
            <Breadcrumbs
              path={[{title: 'Diagnóstico', url: '/diagnostico'}, {title: 'Administrativo', url: '/diagnostico/outras-receitas'}, {title: 'Outras Receitas'}]}
            />
            <OtherRevenue />
          </>
        )}
      />
      <GuardedRoute
        path={`${match.url}/area-reproducao`}
        meta={{moduleName: 'Administrativo'}}
        render={() => (
          <>
            <Breadcrumbs
              path={[
                {title: 'Diagnóstico', url: '/diagnostico'},
                {
                  title: 'Administrativo',
                  url: '/diagnostico/administrativo',
                },
                {title: 'Área e Equipe'},
              ]}
            />
            <ProductionArea />
          </>
        )}
      />
    </div>
  );
};
export default Resources;
