import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import {FarmDetailService} from './services/FarmDetailService';
import VerticalGrid from '../../components/VerticalGrid/verticalGrid';
import {BeefHelpers} from './utils/beefHelpers';
import Loading from '../../components/Loading/Loading';
import Header from './_partial/header';
import Notify from './_partial/alert';
import {FarmDetailHelper} from './utils/farmDetailHelper';
import {DataContext} from '../../utils/DataProvider';
import {history} from '../../utils/utils';

const FarmDetail = () => {
  const {state, dispatch} = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  // const [data, setData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [controlFields, setControlFields] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [dropdownArrow, setDropdownArrow] = useState({
    general: true,
    geograph: true,
    nutri: false,
    sutentability: false,
  });

  const defaultValuesGrid = {
    styleLabel: {
      width: '370px',
    },
  };

  const [alertError, setAlertError] = useState({
    message: '',
    type: 'success',
  });

  const [open, setOpen] = useState(false);

  const onRefresh = () => {
    setRefresh((prev) => !prev);
  };

  const handleDropdown = (dropkey) => {
    setDropdownArrow((prev) => ({
      ...prev,
      [dropkey]: !prev[dropkey],
    }));
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await FarmDetailService.list();
        const cats = await FarmDetailService.categories({custometId: state?.customer?.id});
        const curData = result?.total === 1 ? result.list[0] : null;

        // setData(result);

        if (curData?.state) {
          const cities = await FarmDetailService.categoriesParams('cities', {
            'wherein[state.id]': curData?.state?.id,
          });
          setCategories({...cats, city: cities});
        }
        setCategories({...cats, category_livestock_system: cats.category_livestock_system?.filter((item) => item.title !== 'Exclusiva de confinamento')});
        if (curData) {
          setCurrentData(curData);
        }
        setLoading(false);
      } catch (e) {
        alert('Ocorreu um erro ao os dados, tente novamente mais tarde');
      }
    })();
  }, [refresh]);// eslint-disable-line

  // const handleSelectFarmDetail = (value) => {
  //   const id = value?.farmDetailFormSelect?.id;
  //   if (!id) return;

  //   setCurrentData(data.list.find((item) => item.id === id));
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //     clearTimeout(timer);
  //   }, 500);
  // };

  const DropdownHeader = ({children, dropkey}) => {
    return (
      <div className="custom-dropdown-header" onClick={() => handleDropdown(dropkey)}>
        {children}
        <i className={`fas fa-chevron-${dropdownArrow[dropkey] ? 'down' : 'right'}`} />
      </div>
    );
  };

  const handleNew = () => {
    setLoading(true);
    setCurrentData(null);
    const timer = setTimeout(() => {
      setLoading(false);
      clearTimeout(timer);
    }, 500);
  };

  // const handleDelete = async () => {
  //   const a = window.confirm('Tem certeza que deseja excluir?');

  //   if (!a) return;

  //   setLoading(true);

  //   try {
  //     await FarmDetailService.delete(currentData.id);
  //     setAlertError({
  //       type: 'success',
  //       message: 'Deletado com sucesso',
  //     });
  //     setCurrentData(null);
  //     onRefresh();
  //   } catch (e) {
  //     setAlertError({
  //       type: 'error',
  //       message: 'Ocorreu um erro ao excluir a fazenda',
  //     });
  //   } finally {
  //     setOpen(true);
  //     setLoading(false);
  //   }
  // };

  if (loading) return <Loading />;

  return (
    <>
      <div className="dairy-farm-form mt-4">
        <VerticalGrid
          onSubmit={() => {}}
          data={[
            {
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'header',
                  title: '',
                  component: (
                    <Header
                      newContent={{
                        show: true,
                        handle: handleNew,
                        enable: false,
                      }}
                    />
                  ),
                },
              ],
            },
            // {
            //   items: [
            //     {
            //       name: 'farmDetailFormSelect',
            //       title: 'Selecione a caracterização da fazenda',
            //       component: 'Autocomplete',
            //       options: FarmDetailHelper.selectFarmDetailList(data.list),
            //       value: currentData?.farm && {id: currentData.id, title: currentData.farm.name},
            //       onChange: handleSelectFarmDetail,
            //       styleLabel: {
            //         width: '370px',
            //       },
            //     },
            //   ],
            // },
          ]}
        />
      </div>

      <div className="dairy-farm-grid mt-3">
        <VerticalGrid
          onSubmit={async (values) => {
            setLoading(true);
            try {
              await FarmDetailService.save(values, state, dispatch, history);
              // setData({list, total});
              // setCurrentData(current);
              onRefresh();
              setAlertError({
                type: 'success',
                message: 'Salvo com sucesso',
              });
            } catch (e) {
              setAlertError({
                type: 'error',
                message: e.response.data.message,
              });
            } finally {
              setOpen(true);
              setLoading(false);
            }
          }}
          data={[
            {
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'header',
                  title: '',
                  component: (
                    <Header
                      // deleteContent={{
                      //   show: true,
                      //   handle: handleDelete,
                      //   enable: !!currentData,
                      // }}
                      saveContent={{
                        show: true,
                        enable: false,
                      }}
                    />
                  ),
                },
              ],
            },
            {
              component: <DropdownHeader dropkey="general">Geral</DropdownHeader>,
              dropdownState: dropdownArrow.general,
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'id',
                  value: currentData?.id || '',
                },
                {
                  name: 'farm',
                  title: 'Fazenda',
                  component: 'Autocomplete',
                  options: FarmDetailHelper.getFarms(!!categories?.farms?.length && categories?.farms) || [],
                  value: FarmDetailHelper.getFarm(currentData?.farm ? currentData?.farm : null),
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_processing_format',
                  title: 'Formato de processamento',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_processing_format,
                  value: currentData?.category_processing_format || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'farms_quantity',
                  title: 'Qtde de fazenda(s)',
                  component: 'Number',
                  maxValue: 99,
                  format: '##',
                  disabled: state?.customer?.farms_limit === 1,
                  value: state?.customer?.farms_limit === 1 ? 1 : currentData?.farms_quantity || null,
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_own_farming',
                  title: 'Agricultura própria',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_own_farming,
                  value: currentData?.category_own_farming || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_livestock_system',
                  title: 'Sistema pecuária',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_livestock_system,
                  value: currentData?.category_livestock_system || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                  onChange: async (e) => {
                    setControlFields({...controlFields, category_livestock_system: e.category_livestock_system});
                  },
                },
                {
                  name: 'category_type',
                  title: 'Tipo',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_type,
                  value: currentData?.category_type || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_confinement',
                  title: 'Pasto/confinamento',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_confinement,
                  value: currentData?.category_confinement || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  onChange: async (e) => {
                    setControlFields({...controlFields, category_confinement: e.category_confinement});
                  },
                  ...defaultValuesGrid,
                },
                {
                  name: 'only_confinement',
                  title: 'Exclusiva de Confinamento ?',
                  component: 'Autocomplete',
                  options: BeefHelpers.autoCompleteYesNo(),
                  value: BeefHelpers.getValueYesNo(currentData?.only_confinement),
                  validations:
                    controlFields?.category_confinement?.title === 'Com confinamento'
                      ? [
                          {
                            type: 'required',
                            params: ['Campo obrigatório'],
                          },
                        ]
                      : null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'total_area',
                  title: 'Área total (ha)',
                  component: 'Number',
                  thousandSeparator: '.',
                  decimalSeparator: ',',
                  value: currentData?.total_area,
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'land_value',
                  title: 'Valor da terra (valor/ha)',
                  component: 'Number',
                  value: currentData?.land_value,
                  thousandSeparator: '.',
                  decimalSeparator: ',',
                  maxValue: 99999999999,
                  prefix: 'R$ ',
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
              ],
            },
            {
              component: <DropdownHeader dropkey="geograph">Geografia</DropdownHeader>,
              dropdownState: dropdownArrow.geograph,
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'id',
                  value: currentData?.id || '',
                },
                {
                  name: 'category_biome',
                  title: 'Bioma',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_biome,
                  value: currentData?.category_biome || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'country',
                  title: 'País',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.country,
                  value: currentData?.country || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                  onChange: async (e) => {
                    const regions = await FarmDetailService.categoriesParams('regions', {
                      'wherein[country.id]': e?.country?.id,
                    });
                    setCurrentData((prev) => ({...prev, region: null, state: null, city: null}));
                    setCategories((prev) => ({...prev, region: regions}));
                  },
                },
                {
                  name: 'region',
                  title: 'Região',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.region,
                  value: currentData?.region || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                  onChange: async (e) => {
                    const regions = await FarmDetailService.categoriesParams('states', {
                      'wherein[region.id]': e?.region?.id,
                    });
                    setCurrentData((prev) => ({...prev, state: null, city: null}));
                    setCategories((prev) => ({...prev, state: regions}));
                  },
                },
                {
                  name: 'state',
                  title: 'Estado',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.state,
                  value: currentData?.state || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                  onChange: async (e) => {
                    const regions = await FarmDetailService.categoriesParams('cities', {
                      'wherein[state.id]': e?.state?.id,
                    });
                    setCurrentData((prev) => ({...prev, city: null}));
                    setCategories((prev) => ({...prev, city: regions}));
                  },
                },
                {
                  name: 'city',
                  title: 'Município',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories?.city || [],
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  value: currentData?.city || [],
                  ...defaultValuesGrid,
                },
              ],
            },
            ...[
              (state?.user?.user_type === 'TECHNICAL' || state?.user?.user_type === 'SUPER_ADMIN' || state?.user?.user_type === 'ADMIN') && {
                component: <DropdownHeader dropkey="nutri">Nutrição</DropdownHeader>,
                dropdownState: dropdownArrow.nutri,
                style: {
                  width: '100%',
                },
                items: [
                  {
                    name: 'id',
                    value: currentData?.id || '',
                  },
                  {
                    name: 'use_tm_tortuga',
                    title: 'Utiliza linha TM (linha Boi Verde) no sistema de produção?',
                    component: 'Autocomplete',
                    disabled: state?.user?.user_type === 'customer',
                    options: BeefHelpers.autoCompleteYesNo(),
                    value: BeefHelpers.getValueYesNo(currentData?.use_tm_tortuga),
                    ...defaultValuesGrid,
                  },
                  {
                    name: 'use_supplementation_tortuga',
                    title: 'Utiliza linha de suplementação estratégica?',
                    component: 'Autocomplete',
                    disabled: state?.user?.user_type === 'customer',
                    options: BeefHelpers.autoCompleteYesNo(),
                    value: BeefHelpers.getValueYesNo(currentData?.use_supplementation_tortuga),
                    ...defaultValuesGrid,
                  },
                  // {
                  //   name: 'only_confinement',
                  //   title: 'Propriedade era de cria ou ciclo completo?',
                  //   component: 'Autocomplete',
                  //   options: BeefHelpers.autoCompleteYesNo(),
                  //   value: BeefHelpers.getValueYesNo(currentData?.use_bst),
                  //   ...defaultValuesGrid,
                  // },
                  {
                    name: 'use_fobosvi_reproduction',
                    title: 'Utiliza Fobosvi Reprodução?',
                    disabled: state?.user?.user_type === 'customer',
                    component:
                      controlFields?.category_livestock_system?.title === 'Cria' ||
                      controlFields?.category_livestock_system?.title === 'Cria-recria-engorda' ||
                      currentData?.category_livestock_system?.title === 'Cria' ||
                      currentData?.category_livestock_system?.title === 'Cria-recria-engorda'
                        ? 'Autocomplete'
                        : null,
                    options: BeefHelpers.autoCompleteYesNo(),
                    value: BeefHelpers.getValueYesNo(currentData?.use_fobosvi_reproduction),
                    ...defaultValuesGrid,
                  },
                  {
                    name: 'use_creep_feeding',
                    title: 'Utiliza Creep Feeding?',
                    disabled: state?.user?.user_type === 'customer',
                    component:
                      controlFields?.category_livestock_system?.title === 'Cria' ||
                      controlFields?.category_livestock_system?.title === 'Cria-recria-engorda' ||
                      currentData?.category_livestock_system?.title === 'Cria' ||
                      currentData?.category_livestock_system?.title === 'Cria-recria-engorda'
                        ? 'Autocomplete'
                        : null,
                    options: BeefHelpers.autoCompleteYesNo(),
                    value: BeefHelpers.getValueYesNo(currentData?.use_creep_feeding),
                    ...defaultValuesGrid,
                  },
                ],
              },
            ],
            {
              component: <DropdownHeader dropkey="sutentability">Sustentabilidade</DropdownHeader>,
              dropdownState: dropdownArrow.sutentability,
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'id',
                  value: currentData?.id || '',
                },
                {
                  name: 'legal_reserve',
                  title: '% Reserva Legal + App',
                  component: 'Number',
                  maxValue: 100,
                  format: '###%',
                  value: currentData?.legal_reserve || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'fire_brigade',
                  title: 'Brigada de incêndio',
                  component: 'Autocomplete',
                  options: BeefHelpers.autoCompleteYesNo(),
                  value: BeefHelpers.getValueYesNo(currentData?.fire_brigade),
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_ilp_percentage',
                  title: '% ILP/ILPF',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_ilp_percentage,
                  value: currentData?.category_ilp_percentage || [],
                  ...defaultValuesGrid,
                },
                {
                  name: 'use_nutrition_technology',
                  title: 'Uso de tecnologias na nutrição (TM, Monensina, Crina, Rumistar)',
                  component: 'Autocomplete',
                  options: BeefHelpers.autoCompleteYesNo(),
                  value: BeefHelpers.getValueYesNo(currentData?.use_nutrition_technology),
                  ...defaultValuesGrid,
                },
                {
                  name: 'environmental_protocols',
                  title: 'Treinamento da equipe para protocolos ambientais',
                  component: 'Autocomplete',
                  options: BeefHelpers.autoCompleteYesNo(),
                  value: BeefHelpers.getValueYesNo(currentData?.environmental_protocols),
                  ...defaultValuesGrid,
                },
                {
                  name: 'native_cultivated_pasture',
                  title: '% pasto nativo / % de pastagens cultivadas',
                  component: 'Number',
                  maxValue: 100,
                  format: '###%',
                  value: currentData?.native_cultivated_pasture || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'gips',
                  title: 'Preencheu o GIPS?',
                  component: 'Autocomplete',
                  options: BeefHelpers.autoCompleteYesNo(),
                  value: BeefHelpers.getValueYesNo(currentData?.gips),
                  ...defaultValuesGrid,
                },
              ],
            },
          ]}
        />
      </div>
      <Notify setOpen={setOpen} message={alertError?.message} open={open} type={alertError?.type} />
    </>
  );
};

export default FarmDetail;
