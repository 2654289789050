import React, {useState, useContext, useEffect} from 'react';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import {createOrUpdateData} from '../../../utils/utils';
import {
  changeCategoryDsmProductbyId,
  deleteCategoryDsmProduct,
  fetchCategoryDsmProduct,
  registerCategoryDsmProduct,
} from '../../../services/categoryDsmProduct';
import {fetchCategoryMaterialType} from '../../../services/categoryMaterialType';

const CategoryProductsDsm = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [dataToMaterialType, setDataToMaterialType] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchCategoryDsmProduct({
        ...paginationParams,
        ...filterParams,
      });
      const responseMateriralType = await fetchCategoryMaterialType();
      setData(response.data.resultList);
      setDataToMaterialType(responseMateriralType.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);

  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Produtos',
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'category_material_type',
      valueKey: 'category_material_type.title',
      field: 'category_material_type',
      name: 'Tipo de insumo',
      textAlign: 'Center',
      editType: 'dropdownedit',
      defaultValue: {
        label: 'Insumo DSM',
      },

      optionsFetch: async () => {
        try {
          const response = await fetchCategoryMaterialType();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteCategoryDsmProduct(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {data && (
        <>
          <DataGrid
            onChange={async (dataGridInputs) => {
              if (!dataGridInputs.category_material_type) {
                dataGridInputs.category_material_type = dataToMaterialType?.find((item) => item.title === 'Insumo DSM');
              }
              const response = await createOrUpdateData(dataGridInputs, state, registerCategoryDsmProduct, changeCategoryDsmProductbyId, false);
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
          />
        </>
      )}
    </>
  );
};

export default CategoryProductsDsm;
