import React, {useState, useEffect} from 'react';
import {AccumulatorRow, AccumulatorCel} from './styled';
import {flatten} from '../../utils/utils';

const Accumulators = ({groupedData, accumulatorsConfig, fieldHeaders, columnsTemplate, width, groupBy, fixed = false, general = null, customClass}) => {
  const [accumulatorsChildren, setAccumulatorsChildren] = useState([]);
  let counter = 0;

  const calculateAccumulatorValues = (data) => {
    const accumulatorData = data.reduce((acc, line) => {
      const flattenLine = flatten(line, '.');
      Object.keys(flattenLine).forEach((key) => {
        Object.keys(accumulatorsConfig).forEach((accumulatorKey) => {
          let keyFormatted = key.split('.');
          if (keyFormatted.length > 1) {
            keyFormatted = keyFormatted[keyFormatted.length - 1];
          } else {
            keyFormatted = key;
          }

          if (accumulatorsConfig[accumulatorKey].columns.includes(keyFormatted)) {
            if (!acc[accumulatorKey]) {
              acc[accumulatorKey] = {};
            }
            if (accumulatorsConfig[accumulatorKey].columnType && accumulatorsConfig[accumulatorKey].columnType === 'nested') {
              keyFormatted = key.substr(1);
            }
            acc[accumulatorKey][keyFormatted] =
              accumulatorsConfig[accumulatorKey].itemOperation(acc[accumulatorKey][keyFormatted] || null, line, keyFormatted) || 0;
          }
        });
      });
      return acc;
    }, {});
    return accumulatorData;
  };

  useEffect(() => {
    const accumulatorsCalculated = calculateAccumulatorValues(groupedData);
    setAccumulatorsChildren(accumulatorsCalculated);
  }, [groupedData, accumulatorsConfig]);// eslint-disable-line

  return accumulatorsConfig ? (
    <div className={`${customClass || ''} accumulator ${fixed ? 'accumulator-fixed' : ''}`}>
      {Object.keys(accumulatorsConfig).map((key) => {
        return (
          <div className="totalizator" key={key}>
            {!accumulatorsConfig[key].generalOnly || general ? (
              <AccumulatorRow
                style={{
                  display: 'grid',
                  gridTemplateColumns: columnsTemplate,
                  width: width || 'auto',
                }}>
                {fieldHeaders
                  .filter((itemHeader) => itemHeader.key !== groupBy)
                  .map((field) => {
                    if (accumulatorsChildren[key] && accumulatorsChildren[key][field.key] !== undefined) {
                      if (!counter) {
                        counter = field.key;
                      }

                      return (
                        <AccumulatorCel className={`accumulatorCel ${field.editType}`} style={{justifyContent: 'flex-end'}} key={field.key}>
                          {counter === field.key && !accumulatorsConfig[key].hideTitle && (
                            <div
                              style={{
                                marginRight: '5px',
                                justifyContent: 'flex-end',
                              }}>
                              {key}:{' '}
                            </div>
                          )}
                          {accumulatorsConfig[key] && accumulatorsConfig[key].resultOperation
                            ? accumulatorsConfig[key].resultOperation(accumulatorsChildren[key][field.key] || 0, field.key || 0)
                            : accumulatorsChildren[key][field.key] || 0}
                        </AccumulatorCel>
                      );
                    }
                    return (
                      <AccumulatorCel style={{justifyContent: 'flex-end'}} key={field.key}>
                        {counter === field.key && !accumulatorsConfig[key].hideTitle && <div>{key}:</div>}
                      </AccumulatorCel>
                    );
                  })}
              </AccumulatorRow>
            ) : (
              <> </>
            )}
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export default Accumulators;
