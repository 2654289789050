import React, {useState} from 'react';
import {Autocomplete as AC} from '@material-ui/lab';
import {TextField} from '@material-ui/core';

const Autocomplete = (props) => {
  const {name, value = '', options, actions, optionLabel = 'title', multiple = false, limit, disabled} = props;
  const [inputValue, setInputValue] = useState(value[optionLabel] || '');
  const [inputValueState, setInputValueState] = useState(value);

  const parameters = {};
  if (limit) {
    Object.assign(parameters, {
      getOptionDisabled: () => inputValueState.length >= limit,
    });
  }

  return (
    <AC
      {...parameters}
      disabled={disabled || false}
      openOnFocus
      value={actions.values[name]}
      clearOnEscape
      id={name}
      name={name}
      multiple={multiple}
      limitTags={20}
      options={value ? options : ['', ...options]}
      filterOptions={() => options}
      getOptionLabel={(option) => {
        if (!option || (Array.isArray(option) && !option.length)) return '';
        return option ? option[optionLabel] : '';
      }}
      defaultValue={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionSelected={(event, current) => {
        if (Array.isArray(current)) return '';
        return event.id === current?.id || '';
      }}
      onChange={(e, currentValue) => {
        if (props.onChange) {
          props.onChange({
            [name]: currentValue,
          });
        }
        if (actions.setFieldValue) {
          actions.setFieldValue(name, currentValue);
          setInputValueState(currentValue);
        }
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" margin="none" />}
    />
  );
};

export default Autocomplete;
