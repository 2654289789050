import React, {useState, useEffect, useContext} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {fetchCustomersTotal, registerCustomers, deleteCustomers, changePassword} from '../../services/customers';
import Breadcrumbs from '../../components/Breadcrumbs';

const Customers = () => {
  const [customerList, setCustomersList] = useState();
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';
  const groupFields = [
    {
      key: 'name',
      field: 'name',
      name: 'Código do cliente',
      required: true,
      width: '400px',
      textAlign: 'Center',
      editType: 'usernameedit',
    },

    {
      key: 'email',
      field: 'email',
      name: 'Email',
      required: true,
      // disabledEdit: (dataLine) => {
      //   if (!dataLine) {
      //     return false;
      //   }
      //   return true;
      // },
      width: '300px',
      textAlign: 'Center',
      editType: 'emailedit',
    },
    {
      key: 'password',
      field: 'password',
      name: 'Senha',
      width: '200px',
      disableFilter: true,
      textAlign: 'Center',
      editType: 'passwordedit',
      mapper: (object, editMode) => {
        if (!editMode) {
          return '**********';
        }

        return '';
      },
    },
    {
      key: 'description',
      field: 'description',
      name: 'Descrição',
      width: '300px',
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'profiles',
      field: 'profiles',
      required: false,
      name: 'Ambiente',
      width: '400px',
      textAlign: 'Center',
      editType: 'multipleedit',
      defaultValue: [
        {
          value: 'BEEF_CATTLE',
          label: 'Gado de Corte',
        },
      ],
      optionsFetch: async () => {
        return [
          {
            value: 'BEEF_CATTLE',
            label: 'Gado de Corte',
          },
          {
            value: 'DAIRY_CATTLE',
            label: 'Gado Leiteiro',
          },
        ];
      },
    },
    // {
    //   key: 'only_diagnosis',
    //   field: 'only_diagnosis',
    //   name: 'Diagnótico',
    //   width: '300px',
    //   textAlign: 'Center',
    //   editType: 'booleanedit',
    // },

    {
      key: 'farms_limit',
      field: 'farms_limit',
      required: true,
      name: 'Limite de fazendas',
      width: '100px',
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'list_technical',
      field: 'list_technical',
      required: false,
      disabledEdit: true,
      name: 'Técnicos associados',
      width: '400px',
      textAlign: 'Center',
      editType: 'textedit',
      extraParams: {
        minValue: 1,
      },
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchCustomersTotal({
        ...paginationParams,
        ...filterParams,
        'whereinmatch[profile]': ENVS,
      });
      setCustomersList(
        response.data.resultList.map((item) => ({
          ...item,
          list_technical:
            !!Object.values(item.list_technical).length &&
            Object.values(item.list_technical)
              ?.map((val) => {
                return val.name;
              })
              .join(', '),
        })),
      );
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteCustomers(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      <Breadcrumbs path={[{title: 'Cadastros', url: '/cadastros'}, {title: 'Clientes'}]} />
      {customerList && customerList.length > 0 && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (!dataGridInputs.description) {
              dataGridInputs = Object.assign(dataGridInputs, {
                description: '',
              });
            }

            if (!dataGridInputs.id) {
              dataGridInputs.created_by = {id: state.user.id};
            }
            delete dataGridInputs.list_technical;
            if ((dataGridInputs.profiles && dataGridInputs.profiles.length === 0) || !dataGridInputs.profiles) {
              dataGridInputs.profiles = [
                {
                  value: 'BEEF_CATTLE',
                  label: 'Gado de Corte',
                },
              ];
            }

            delete dataGridInputs.technical_name;
            if (!dataGridInputs?.only_diagnosis) {
              dataGridInputs.only_diagnosis = false;
            }
            let response;
            try {
              response = await registerCustomers(dataGridInputs);
              if (dataGridInputs.id && dataGridInputs.password) {
                const {id} = dataGridInputs.user;
                delete dataGridInputs.id;
                response = await changePassword(id, {
                  password: dataGridInputs.password,
                });
              } else if (dataGridInputs.id) {
                delete dataGridInputs.password;
                // delete dataGridInputs.email;
              }

              setReset(!reset);
              return response;
            } catch (error) {
              console.error(error);
              return error;
            }
          }}
          header={groupFields}
          data={customerList}
          keyProp="key"
          width="max-content"
          titleProp="name"
          editable=""
          totalItems={totalItems}
          actions={actions}
        />
      )}
    </>
  );
};

export default Customers;
