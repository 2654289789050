import React, {useState, useEffect, useContext} from 'react';
import DataGrid from '../../components/DataGrid';
import PrimaryFilterForm from '../../components/FilterForms/FilterSettings';
import {createOrUpdateData} from '../../utils/utils';
import {FILTER} from '../../utils/constants';
import {DataContext} from '../../utils/DataProvider';
import {
  fetchAreaDistributionDatagrid,
  registerAreaDistribution,
  changeAreaDistributionbyId,
  deleteAreaDistribution,
} from '../../services/areaDistribution';
import PrimaryFilterDisplay from '../../components/PrimaryFilterDisplay/PrimaryFilterDisplay';

const AreaDistribution = () => {
  const {state, dispatch, stateFetchParamsCustom} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  }, []);// eslint-disable-line
  useEffect(() => {
    const loadData = async () => {
      if (
        !state.harvest ||
        !state.customer ||
        !state.farm ||
        stateFetchParamsCustom.farm_id === -1
      ) {
        setReset((prev) => !prev);
        return;
      }
      const response = await fetchAreaDistributionDatagrid({
        ...stateFetchParamsCustom,
      });
      const temp = [];
      response.data.map((value) => {
        switch (value.type_area_distribution) {
          case 'RESERVA_LEGAL_APP':
            return temp.push({
              ...value,
              id: value.id || -2,
              type_area_distribution: {
                id: value.type_area_distribution,
                title: 'Reserva legal e APP',
              },
            });
          case 'BENFEITORIAS_INFRA_ESTRUTURA':
            return temp.push({
              ...value,
              id: value.id || -3,
              type_area_distribution: {
                id: value.type_area_distribution,
                title: 'Benfeitorias e infra-estrutura',
              },
            });
          default:
        }
        return null;
      });
      setData(temp);
      setTotalItems(temp.length);
    };
    if (state.harvest && state.farm && state.customer) {
      loadData();
    }
  }, [reset, state, stateFetchParamsCustom]);

  const columns = [
    {
      key: 'type_area_distribution',
      valueKey: 'type_area_distribution.title',
      field: 'type_area_distribution',
      disableFilter: true,
      name: 'Distribuição de área',
      width: '70%',
      textAlign: 'Center',
      editType: 'dropdownedit',
      disabledEdit: true,
      optionsFetch: async () => {
        const objectTemp = [
          {
            value: 'RESERVA_LEGAL_APP',
            label: 'Reserva legal e APP',
          },
          {
            value: 'BENFEITORIAS_INFRA_ESTRUTURA',
            label: 'Benfeitorias e infra-estrutura',
          },
        ];
        return objectTemp;
      },
    },
    {
      key: 'value',
      field: 'value',
      name: 'Área',
      disableFilter: true,
      required: true,
      textAlign: 'Center',
      editType: 'defaultedit',
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteAreaDistribution(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {state.filterOpened && <PrimaryFilterForm />}
      {!state.filterOpened && data && (
        <>
          <PrimaryFilterDisplay />
          <DataGrid
            onChange={async (dataGridInputs) => {
              delete dataGridInputs.created_at;
              delete dataGridInputs.updated_at;
              delete dataGridInputs.updated_by;
              delete dataGridInputs.created_by;
              if (dataGridInputs.id < 0) {
                delete dataGridInputs.id;
              }
              dataGridInputs.type_area_distribution =
                dataGridInputs.type_area_distribution.id;
              const response = await createOrUpdateData(
                dataGridInputs,
                state,
                registerAreaDistribution,
                changeAreaDistributionbyId,
                false,
              );
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            disabledPaginate
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
          />
        </>
      )}
    </>
  );
};

export default AreaDistribution;
