import {DATAGRID} from '../../utils/constants';

const dataGridReducer = (state, action) => {
  switch (action.type) {
    case DATAGRID.ROW.EVENT.INSERT_DATA:
      return {
        ...state,
        ...{
          data: action.payload,
          lastDispatch: DATAGRID.ROW.EVENT.INSERT_DATA,
        },
      };
    case DATAGRID.ROW.EVENT.DATA_CHANGED: {
      const newData = {
        ...state.data,
        ...{[action.payload.field]: action.payload.data},
      };

      return {
        ...state,
        ...{
          changed: action.payload.field,
          data: newData,
          lastDispatch: DATAGRID.ROW.EVENT.DATA_CHANGED,
        },
      };
    }
    case DATAGRID.ROW.EVENT.ADD_ERROR: {
      const currentInputErrors = state.errors[action.payload.inputName]
        ? state.errors[action.payload.inputName]
        : [];

      const newInputErrors = [
        ...new Set([...currentInputErrors, action.payload.error]),
      ];

      const newErrors = {
        ...state.errors,
        ...{[action.payload.inputName]: newInputErrors},
      };

      return {
        ...state,
        ...{errors: newErrors},
      };
    }
    case DATAGRID.ROW.EVENT.REMOVE_ERROR: {
      const {
        [action.payload.inputName]: currentInputErrors,
        ...withoutCurrentInput
      } = state.errors;

      const newInputErrors = currentInputErrors
        ? currentInputErrors.filter((err) => err !== action.payload.error)
        : null;

      const newErrors =
        newInputErrors && newInputErrors.length
          ? {
              ...withoutCurrentInput,
              ...{[action.payload.inputName]: newInputErrors},
            }
          : withoutCurrentInput;

      return {
        ...state,
        ...{errors: newErrors},
      };
    }
    case DATAGRID.ROW.EVENT.CLEAR_DATA: {
      return {
        ...state,
        ...{data: {}, lastDispatch: DATAGRID.ROW.EVENT.CLEAR_DATA},
      };
    }
    case DATAGRID.TABLE.EVENT.CURRENT_LINE_CHANGED: {
      const newLine = {
        currentLine: action.payload,
        lastDispatch: DATAGRID.TABLE.EVENT.CURRENT_LINE_CHANGED,
      };
      if (!action.payload) {
        newLine.data = {};
      }
      return {...state, ...newLine};
    }
    case DATAGRID.TABLE.EVENT.CURRENT_CEL_CHANGED: {
      const newLine = {
        currentCel: action.payload,
        lastDispatch: DATAGRID.TABLE.EVENT.CURRENT_CEL_CHANGED,
      };
      if (!action.payload) {
        newLine.data = {};
      }
      return {...state, ...newLine};
    }
    case DATAGRID.TABLE.EVENT.MODE_CHANGED:
      return {
        ...state,
        ...{
          mode: action.payload,
          lastDispatch: DATAGRID.TABLE.EVENT.MODE_CHANGED,
        },
      };
    case DATAGRID.TABLE.EVENT.ADD_TABLE_ACTION_TO_QUEUE: {
      const actionQueue = [...state.actionQueue, action.payload];
      return {
        ...state,
        ...{
          actionQueue,
          lastDispatch: DATAGRID.TABLE.EVENT.ADD_TABLE_ACTION_TO_QUEUE,
        },
      };
    }
    case DATAGRID.TABLE.EVENT.POP_TABLE_ACTION_TO_QUEUE: {
      const {actionQueue} = state;
      actionQueue.pop();
      return Object.assign(state, {
        actionQueue,
        lastDispatch: DATAGRID.TABLE.EVENT.POP_TABLE_ACTION_TO_QUEUE,
      });
    }
    case DATAGRID.TABLE.EVENT.CHANGE_FILTER: {
      const newFilters = {
        ...state.filters,
        ...{[action.payload.field]: action.payload.filters},
      };
      return {
        ...state,
        ...{filters: newFilters},
      };
    }
    case DATAGRID.TABLE.EVENT.CHANGE_FILTER_CHECKBOX: {
      const fieldExists = state.filters[action.payload.field];
      let newCheckboxList = {
        [action.payload.field]: {checkboxList: action.payload.checkboxList},
      };
      if (fieldExists) {
        newCheckboxList = {
          [action.payload.field]: {
            ...fieldExists,
            ...{checkboxList: action.payload.checkboxList},
          },
        };
      }

      const newFilters = {
        ...state.filters,
        ...newCheckboxList,
      };
      return {
        ...state,
        ...{filters: newFilters},
      };
    }
    case DATAGRID.TABLE.EVENT.CLEAR_FILTER: {
      const {
        [action.payload.field]: removedFilter,
        ...newFilters
      } = state.filters;
      return {
        ...state,
        ...{filters: newFilters || {}},
      };
    }
    default:
      return state;
  }
};

export default dataGridReducer;
