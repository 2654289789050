/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useContext} from 'react';
import * as Yup from 'yup';
import fileDownload from 'js-file-download';
import {CircularProgress} from '@material-ui/core';
import GenerateForm from '../../components/GenerateForm';
import {fetchBenchmarkingPDF, fetchstatusHarvest} from '../../services/benchmark';
import {fetchCategoryLivestockSystem} from '../../services/categoryLivestockSystem';
import {fetchFarmsDetails} from '../../services/farmsDetails';
import {fetchHarvests} from '../../services/harvests';
import {DataContext} from '../../utils/DataProvider';
import {normalizeCollectionData} from '../../utils/utils';
import {FilterBox, H2} from './styled';

const BenchmarkScoreboard = () => {
  const PDF = 'PDF';
  const {state} = useContext(DataContext);
  const [filter, setFilter] = useState(0);
  const [optionsFilter, setOptionsFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  useEffect(() => {
    (async () => {
      const harvests = await fetchHarvests();
      const categoryLivestock = await fetchCategoryLivestockSystem();

      setOptionsFilter((prev) => ({
        ...prev,
        harvests: harvests?.data?.resultList || [],
        category_livestock_system: categoryLivestock?.data?.resultList || [],
      }));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const farmsData = await fetchFarmsDetails({
        'wherein[harvest.id]': filter?.harvest?.value,
        'wherein[categoryLivestockSystem.id]': filter?.category_livestock_system?.value,
      });
      setOptionsFilter((prev) => ({
        ...prev,
        farms: farmsData?.data?.resultList.map((item) => ({...item?.farm})) || [],
      }));
    })();
  }, [filter?.harvest?.value, filter?.category_livestock_system?.value]);// eslint-disable-line

  const fetchData = async (_, fileType) => {
    setLoading(true);
    try {
      let endpoint;
      let extension;
      switch (fileType) {
        case PDF:
          endpoint = fetchBenchmarkingPDF;
          extension = 'pdf';
          break;

        default:
          return;
      }

      const response = await endpoint(
        {
          harvest_id: filter?.harvest?.value,
          environment: ENVS,
          category_livestock_system_id: filter?.category_livestock_system?.value,
        },
        'arraybuffer', // responseType,
        {
          Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        },
      );

      if (extension === 'pdf') {
        let nameFile = response.headers['content-disposition'].split('filename=')[1];
        nameFile = nameFile?.replace(/"/g, '');
        fileDownload(response.data, `${nameFile}`);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Safra*',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione uma Safra',
          value: filter.harvest,
          required: true,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(optionsFilter?.harvests || [], [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: async (newValue) => {
            setFilter((prev) => ({...prev, harvest: newValue || ''}));
            const resp = await fetchstatusHarvest({
              harvest_id: newValue?.value,
            });
          },
          columns: {
            xs: 6,
          },
        },
        {
          label: 'Sistema Pecuário',
          name: 'category_livestock_system',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione um Sistema Pecuário',
          value: filter.category_livestock_system,
          // required: true,
          // validations: {
          //   category_livestock_system: Yup.string().required('Campo obrigatório'),
          // },
          options: normalizeCollectionData(optionsFilter?.category_livestock_system || [], [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, category_livestock_system: newValue || ''}));
          },
          columns: {
            xs: 6,
          },
        },
      ],
    },
  ];

  return (
    <div className="p-3">
      <H2>Placar do Benchmarking Geral</H2>

      <div className="d-flex flex-column ">
        <FilterBox className="filter-scoreboard">
          <GenerateForm
            onSubmit={(e, actions) => {
              fetchData(e, PDF);

              actions.setSubmitting(false);
            }}
            groupFields={groupFields}
            settings={{
              button: {
                text: 'Gerar',
                textSubmitting: 'Gerando....',
              },
            }}
          />
          {loading ? <CircularProgress className="loading" color="inherit" size={20} /> : null}
        </FilterBox>
      </div>
    </div>
  );
};

export default BenchmarkScoreboard;
