import React from 'react';
import styled from 'styled-components';
import IMask from 'imask';

const InfoGraphic = ({data, mask}) => {
  // eslint-disable-next-line react/destructuring-assignment
  return (
    <Kpi className="card d-flex">
      <p>{data.label}</p>
      <p>{`${IMask.createMask({
        mask: mask || 'R$ money',
        blocks: {
          money: {
            mask: Number, // enable number mask

            // other options are optional with defaults below
            scale: 2, // digits after point, 0 for integers
            signed: false, // disallow negative
            thousandsSeparator: '.', // any single char
            padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
            normalizeZeros: true, // appends or removes zeros at ends
            radix: ',', // fractional delimiter

            // additional number interval options (e.g.)
            min: 0,
            max: 999999999999,
          },
        },
      }).resolve(data.data.toString())}`}</p>
      <p>
        <b>
          {data.percentage}%
          {data.percentage > 0 ? (
            <i className="fas fa-sort-up" />
          ) : (
            <i className="fas fa-sort-down" />
          )}
        </b>
      </p>
    </Kpi>
  );
};

const Kpi = styled.div`
  border: none;
  font-size: 1em;
  font-weight: 100;
  text-align: center;
  p {
    margin: 0;
    white-space: nowrap;
    .fas {
      bottom: -1px;
      color: #00f;
      position: absolute;
      right: -6px;
    }
  }
`;
export default InfoGraphic;
