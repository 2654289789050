import React from 'react';
import CategoryBase from '../Component/CategoryBase';

const CategoryCoreUsed = () => {
  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'batch_post_birth',
      field: 'batch_post_birth',
      name: 'Pós-parto',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
    {
      key: 'batch_high_production_cow',
      field: 'batch_high_production_cow',
      name: 'Vacas de alta produção',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
    {
      key: 'batch_medium_production_cow',
      field: 'batch_medium_production_cow',
      name: 'Vacas de média produção',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
    {
      key: 'batch_low_production_cow',
      field: 'batch_low_production_cow',
      name: 'Vacas de baixa produção',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
    {
      key: 'batch_pre_birth',
      field: 'batch_pre_birth',
      textAlign: 'Center',
      name: 'Pré-parto',
      editType: 'booleanedit',
    },
  ];

  return <CategoryBase baseEndpoint="category_core_used" columnsExtra={columns} />;
};

export default CategoryCoreUsed;
