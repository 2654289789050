import apiNew from '../apiNew';

const fetchCategoryFatteningSystem = (params = {}) => {
  return apiNew.get(`/category_fattening_system`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryFatteningSystembyId = (id, params = {}) => {
  return apiNew.get(`/category_fattening_system/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryFatteningSystem = (body = {}) => {
  return apiNew.post(`/category_fattening_system`, body);
};

const changeCategoryFatteningSystembyId = (id, body = {}) => {
  return apiNew.put(`/category_fattening_system/${id}`, body);
};

const deleteCategoryFatteningSystem = (id) => {
  return apiNew.delete(`/category_fattening_system/${id}`);
};

export {
  fetchCategoryFatteningSystem,
  fetchCategoryFatteningSystembyId,
  registerCategoryFatteningSystem,
  changeCategoryFatteningSystembyId,
  deleteCategoryFatteningSystem,
};
