import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../../../utils/DataProvider';
import {TechnicalInformationServices} from '../services/TechnicalInformationServices';
import {createOrUpdateDataFull, getRowValue} from '../../../../../utils/utils';
import {DatagridActionsHelper} from '../../../utils/datagridActionsHelper';
import DataGrid from '../../../../../components/DataGrid';
import {TechnicalInformationHelper} from '../utils/TechnicalInformationHelper';

import '../css/technical-information.scss';
import {ServicesHelper} from '../../services/ServicesHelper';

const TechnicalInformation = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [reset, setReset] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await TechnicalInformationServices.list(
          {
            'wherecustom[date$partialdate:and]': `${state.harvest.first_year}-07;>=,${state.harvest.last_year}-06;<=`,
            ...stateFetchParams,
            ...filterParams,
          },
          {
            first_year: state.harvest.first_year,
            last_year: state.harvest.last_year,
          },
        );
        const {data: currentData, categories: cats} = response;

        setCategories(cats?.map((item) => ({...item, order: item?.label === 'Partos' ? 99 : item.value})));
        setData(currentData.resultList?.map((item) => ({...item, order: item?.title === 'Partos' ? 99 : item.id})));
        setTotal(currentData.size);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [reset, JSON.stringify(stateFetchParams).toLowerCase(), JSON.stringify(state).toLowerCase()]);// eslint-disable-line

  const handleChange = async (inputs) => {
    inputs.currentUser = state.user.id;
    try {
      return await createOrUpdateDataFull(inputs, state, TechnicalInformationServices.post, TechnicalInformationServices.put);
    } catch (error) {
      return error;
    } finally {
      setReset(!reset);
    }
  };

  const validateHeaderCallback = async (dataGridRows, field, checked, valueKey) => {
    const promisses = [];
    dataGridRows.forEach(async (line) => {
      if (getRowValue(line, field, valueKey, true, null, field) >= 0) {
        const sanitizeLine = TechnicalInformationServices.prepareToSave({
          ...{...state, ...line, currentUser: state.user.id, validated: checked},
          [valueKey]:
            Number(getRowValue(line, field, valueKey, true, null, field)) >= 0 ? parseFloat(getRowValue(line, field, valueKey, true, null, field)) : null,
        });
        const tempReq = ServicesHelper.post(TechnicalInformationServices.endpoint.concat('/batch'), [...sanitizeLine]);
        promisses.push(tempReq);
      }
    });
    const response = await Promise.all(promisses);
    setReset(!reset);
    return response;
  };

  const actions = DatagridActionsHelper.actions(
    {
      handleDelete: async (value, cel, key) => {
        try {
          const sanitizeLine = TechnicalInformationServices.prepareToSave({
            ...{...state, ...value, currentUser: state.user.id, [key]: -1},
          });
          const response = ServicesHelper.post(TechnicalInformationServices.endpoint.concat('/batch'), [...sanitizeLine]);
          setReset(!reset);
          return response;
        } catch (error) {
          return error;
        }
      },
    },
    {add: true},
  );
  const sortFix = (a, b) => {
    if (a.order > b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    // a must be equal to b
    return 0;
  };

  return (
    <>
      {stateMessage}
      {data && (
        <div className="livesotckStock fixedHeader technical-information">
          <DataGrid
            onChange={handleChange}
            header={[
              TechnicalInformationHelper.columns(state.harvest.first_year, [7, 8, 9, 10, 11, 12], 'quantity'),
              TechnicalInformationHelper.columns(state.harvest.last_year, [1, 2, 3, 4, 5, 6], 'quantity'),
            ]}
            fixedColumn={
              {
                header: [
                  {
                    key: 'id',
                    valueKey: 'label',
                    width: '390px',
                    height: 'auto',
                    field: 'label',
                    name: 'Categoria',
                    textAlign: 'Center',
                    editType: 'defaultedit',
                    disabledEdit: true,
                    disableFilter: true,
                  },
                ],
                data: categories.map((cat) => ({...cat, id: cat.value})).sort(sortFix),
              } || null
            }
            validateCallback={validateHeaderCallback}
            data={data?.sort(sortFix)}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={total}
            disabledPaginate
            maxHeight="100%"
          />
        </div>
      )}
    </>
  );
};

export default TechnicalInformation;
