import React, {useState, useContext, useEffect} from 'react';
import machineColumns from './MachineDatagridModel';
import DataGrid from '../../components/DataGrid';
import {fetchMachines, changeMachinesbyId, registerMachine, deleteMachine} from '../../services/machines';
import {DataContext} from '../../utils/DataProvider';
import {maskInput, createOrUpdateDataFull} from '../../utils/utils';
import {MASKS} from '../../utils/constants';

const Machine = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadMachines = async () => {
      const response = await fetchMachines({
        ...stateFetchParams,
        ...filterParams,
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadMachines();
  }, [reset, stateFetchParams]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteMachine(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {
        // console.log('save');
      },
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            Object.assign(dataGridInputs, {
              harvest: {
                id: state.harvest.id,
              },
              customer: {
                id: state.customer.id,
              },
              farm: {
                id: state.farm.id,
              },
              created_by: {
                id: state.user.id,
              },
            });
            const response = await createOrUpdateDataFull(dataGridInputs, state, registerMachine, changeMachinesbyId);
            setReset(!reset);
            return response;
          }}
          header={machineColumns(state.harvest.first_year, state.harvest.last_year)}
          data={data}
          keyProp="key"
          maxHeight="auto"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
          accumulators={{
            'Total geral': {
              columns: ['amount', 'unitary_value'],
              itemOperation: (acc, line, key) => {
                if (key === 'unitary_value') {
                  return (acc += line.unitary_value * line.amount);
                }

                return (acc += line[key]);
              },
              resultOperation: (acc, key) => {
                if (key !== 'amount') {
                  return maskInput({value: acc, type: MASKS.MONEY});
                }

                return acc;
              },
            },
            Ponderado: {
              columns: ['unitary_value'],
              itemOperation: (acc, line, key) => {
                if (!acc) {
                  return {sum: line[key] * line.amount, quantity: line.amount};
                }

                return {
                  sum: acc.sum + line[key] * line.amount,
                  quantity: (acc.quantity += line.amount),
                };
              },
              resultOperation: (acc) => {
                return maskInput({
                  value: acc.sum / acc.quantity,
                  type: MASKS.MONEY,
                });
              },
            },
          }}
        />
      )}
    </>
  );
};

export default Machine;
