import React, {useState, useContext, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {fetchStocks, changeStocksbyId, registerStock, deleteStock} from '../../services/stocks';
import {MASKS} from '../../utils/constants';
import stockColumns from './StockDatagridModel';
import {maskInput, createOrUpdateDataFull} from '../../utils/utils';
import {fetchCategoryMaterialType} from '../../services/categoryMaterialType';

const Stock = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadStocks = async () => {
      const response = await fetchStocks({
        ...stateFetchParams,
        ...filterParams,
        ...(filterParams['wherein[dateHarvest]'] && {
          'wherein[dateHarvest]': filterParams['wherein[dateHarvest]'] === "'true'",
        }),
      });
      setData(
        response.data.resultList.map((item) => ({
          ...item,
          category_dsm_product: item.category_material_type.title === 'Insumo DSM' ? item.category_dsm_product : {title: item.material, id: item.material},
        })),
      );
      setTotalItems(response.data.size);
    };

    loadStocks();
  }, [reset, stateFetchParams]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteStock(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    dataGridRows.forEach((row) => {
      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, registerStock, changeStocksbyId));
    });
    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  return (
    <>
      {stateMessage}
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (dataGridInputs && dataGridInputs.category_unit_measurement && !dataGridInputs.category_unit_measurement.id) {
              // eslint-disable-next-line camelcase
              delete dataGridInputs.category_unit_measurement;
            }
            const responseMaterialType = await fetchCategoryMaterialType();
            if (responseMaterialType.data.resultList.find((item) => item.id === dataGridInputs.category_material_type.id).title !== 'Insumo DSM') {
              dataGridInputs.material = dataGridInputs.category_dsm_product.title || dataGridInputs.category_dsm_product;
              dataGridInputs.category_dsm_product = null;
            } else {
              dataGridInputs.material = String(dataGridInputs?.category_dsm_product?.id);
            }
            const response = await createOrUpdateDataFull(dataGridInputs, state, registerStock, changeStocksbyId);
            setReset(!reset);
            return response;
          }}
          header={stockColumns(state.harvest.first_year, state.harvest.last_year, state.user)}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          accumulators={{
            'Total geral': {
              columns: ['value'],
              itemOperation: (acc, line, key) => (acc += line[key] * line.amount),
              resultOperation: (acc) => {
                return maskInput({value: acc, type: MASKS.MONEY});
              },
            },
          }}
          totalItems={totalItems}
          validateCallback={validateHeaderCallback}
        />
      )}
    </>
  );
};

export default Stock;
