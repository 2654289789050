import apiNew from '../apiNew';

const fetchCountries = (params = {}) => {
  return apiNew.get(`/countries`, {
    params: {
      ...params,
    },
  });
};

const fetchCountrybyId = (id, params = {}) => {
  return apiNew.get(`/countries/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCountry = (body = {}) => {
  return apiNew.post(`/countries`, body);
};

const changeCountrybyId = (id, body = {}) => {
  return apiNew.put(`/countries/${id}`, body);
};

const deleteCountry = (id) => {
  return apiNew.delete(`/countries/${id}`);
};

export {fetchCountries, fetchCountrybyId, registerCountry, changeCountrybyId, deleteCountry};
