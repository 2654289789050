import React, {useState, useEffect} from 'react';
import {Line} from 'react-chartjs-2';
import '../style.scss';
import legendBlue from '../../../../assets/images/cash_flow_legend_blue.png';
import legendYellow from '../../../../assets/images/cash_flow_legend_yellow.png';
import FilterCashFLow from './filter';
import Loading from '../../../../components/Loading/Loading';
import {fetchReportCashFlowItems, fetchReportCashFlow, fetchReportCashFlowNotValidated} from '../../../../services/dairyReports';
import {formatMoney} from '../../../../utils/formateMoney';
import {JsonModel} from './model';

const CashFlow = () => {
  const [loading, setLoading] = useState(true);

  const [customer, setCustomer] = useState(null);
  const [harvest, setHarvest] = useState(null);
  const [notValidated, setNotValidated] = useState(null);
  const [farmsList, setFarmsList] = useState(null);

  const [templateYears, setTemplateYears] = useState([]);

  const [templateCategories, setTemplateCategories] = useState({
    headReceitas: [{description: 'RECEITAS'}],
    receitas: [],
    headDespesas: [{description: 'DESPESAS'}],
    despesas: [],
    totais: [],
  });
  const [templateData, setTemplateData] = useState(null);
  const [graphLabels, setGraphLabels] = useState([]);
  const [graphValues, setGraphValues] = useState({
    investment: null,
    noInvestment: null,
  });

  const dataGraphic = {
    labels: graphLabels,
    datasets: [
      {
        data: graphValues.investment,
        fill: false,
        tension: 0,
        borderColor: '#4472c4',
        borderWidth: 7,
        datalabels: {
          color: '#fff',
          backgroundColor: '#4472c4',
          borderRadius: 5,
          formatter: (value) => {
            return `${(value / 1000).toFixed(1)}k`;
          },
          font: {
            size: 14,
            weight: 'bold',
          },
          align: 'top',
          offset: 10,
          padding: {
            top: 2,
            bottom: 0,
          },
        },
      },
      {
        data: graphValues.noInvestment,
        fill: false,
        tension: 0,
        borderColor: '#f2c84d',
        borderWidth: 7,
        datalabels: {
          color: '#fff',
          backgroundColor: '#f2c84d',
          borderRadius: 5,
          formatter: (value) => {
            return `${(value / 1000).toFixed(1)}k`;
          },
          font: {
            size: 14,
            weight: 'bold',
          },
          align: 'top',
          offset: 10,
          padding: {
            top: 2,
            bottom: 0,
          },
        },
      },
    ],
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getGridValues = (categories, data) => {
    const values = [];
    categories.forEach((cat) => {
      const line = [];
      data.forEach((item) => {
        const byId = item.values.filter((val) => val.id === cat.id);
        const byType = item.values.filter((val) => val.type === cat.type && !val.id);

        if (byId.length === 1) {
          line.push({
            type: byId.length ? byId[0].type : '',
            value: byId.length ? byId[0].value : '',
          });
        } else if (byType.length === 1) {
          line.push({
            type: byType.length ? byType[0].type : '',
            value: byType.length ? byType[0].value : '',
          });
        } else {
          line.push({
            type: '',
            value: 0,
          });
        }
      });
      values.push(line);
    });

    return values;
  };

  useEffect(() => {
    (async () => {
      try {
        if (customer && harvest && farmsList) {
          const dataNotValidated = await fetchReportCashFlowNotValidated({
            customer_id: customer.id,
            harvest_id: harvest.id,
            farm_id: farmsList,
          });
          setNotValidated(dataNotValidated.data);

          const res = await fetchReportCashFlow({
            customer_id: customer.id,
            harvest_id: harvest.id,
            farm_id: farmsList,
          });
          const data = res?.data?.length > 0 ? res.data : JsonModel.generateResponse(harvest?.first_year, harvest?.last_year);

          const resCategories = await fetchReportCashFlowItems();
          const categories = {
            totais: resCategories.data.filter((item) => item.type !== 'RECEITAS' && item.type !== 'DESPESAS'),
            headReceitas: [{description: 'RECEITAS'}],
            receitas: [...resCategories.data.filter((item) => item.type === 'RECEITAS'), {id: 1000, description: 'TOTAL', type: 'RECEITAS'}],
            headDespesas: [{description: 'DESPESAS'}],
            despesas: [...resCategories.data.filter((item) => item.type === 'DESPESAS'), {id: 1001, description: 'TOTAL', type: 'RECEITAS'}],
          };
          setTemplateCategories(categories);
          // console.log(categories);
          const datesLine = [];

          const years = [];
          const labelsToGraph = [];
          const investment = [];
          const noInvestment = [];
          data.forEach((item) => {
            datesLine.push({
              type: 'date',
              value: item.date,
            });
            if (item.date !== 'Total') {
              years.push(item.date.split('/')[1]);

              item.values.forEach((val) => {
                if ((val.type === 'FLUXO_CAIXA_COM_INVESTIMENTO' && val.value) || (val.type === 'FLUXO_CAIXA_SEM_INVESTIMENTO' && val.value)) {
                  labelsToGraph.push(item.date);
                }

                if (val.type === 'FLUXO_CAIXA_COM_INVESTIMENTO' && val.value) {
                  const tempInv = val.value;
                  investment.push(`${tempInv.toFixed(1)}`);
                } else if (val.type === 'FLUXO_CAIXA_SEM_INVESTIMENTO' && val.value) {
                  const tempNoInv = val.value;
                  noInvestment.push(`${tempNoInv.toFixed(1)}`);
                }
              });
            }
          });
          setGraphValues({investment, noInvestment});
          setGraphLabels(labelsToGraph.filter(onlyUnique));

          const gridYears = [];
          years
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach((item) => {
              gridYears.push({
                colSpan: years.filter((qtd) => qtd === item).length,
                year: item,
              });
            });
          setTemplateYears(gridYears);

          let totalizer = {};
          getGridValues(categories.receitas, data).forEach((element) => {
            element.forEach((el, i) => {
              totalizer = {...totalizer, [i]: el?.value + (totalizer[i] || 0)};
            });
          });

          let totalizerDesp = {};
          getGridValues(categories.despesas, data).forEach((element) => {
            element.forEach((el, i) => {
              totalizerDesp = {...totalizerDesp, [i]: el?.value + (totalizerDesp[i] || 0)};
            });
          });
          const totalizerReceitas = Object.values(totalizer).map((el) => ({type: 'TOTAL', value: el}));
          const totalizerDespesas = Object.values(totalizerDesp).map((el) => ({type: 'TOTAL', value: el}));
          //
          if (data.length) {
            setTemplateData({
              totais: getGridValues(categories.totais, data),
              headReceitas: [datesLine],
              receitas: [...getGridValues(categories.receitas, data).slice(0, -1), totalizerReceitas],
              // totalReceitas: [totalizerReceitas],
              headDespesas: [datesLine],
              despesas: [...getGridValues(categories.despesas, data).slice(0, -1), totalizerDespesas],
            });
          }

          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [customer, harvest, farmsList]);

  if (!customer && !harvest && !farmsList) {
    return <FilterCashFLow setCustomer={setCustomer} setHarvest={setHarvest} setFarmsList={setFarmsList} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <>
        <div className="" />
        <div className="graphic-container">
          <div className="graphic-legend">
            <div className="graphic-legend-line">
              <img src={legendBlue} alt="Legenda de gráfico" />
              <h3>Fluxo de caixa (R$)</h3>
            </div>
            <div className="graphic-legend-line">
              <img src={legendYellow} alt="Legenda de gráfico" />
              <h3>Fluxo de caixa sem investimento (R$)</h3>
            </div>
          </div>

          <Line
            data={dataGraphic}
            options={{
              legend: {display: false},
              layout: {padding: 30},
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback(label) {
                        return `${label / 1000}k`;
                      },
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </>

      <div className="head-page" />

      {templateData ? (
        <div className="table-container">
          <div className="cat-list">
            <table>
              <tbody>
                {notValidated ? (
                  <div className="cash-flow-not-validated">
                    <h3>Atenção! Existem dados não validados.</h3>
                  </div>
                ) : (
                  <tr />
                )}
                {Object.keys(templateCategories).map((key) => (
                  <>
                    {templateCategories[key].map((cat, i) => (
                      <tr key={i} className={key === 'totais' && 'totalLine categories'}>
                        <td className={`${cat.description} bolder`}>{cat.description}</td>
                      </tr>
                    ))}
                    {(key === 'receitas' || key === 'despesas' || 'totais') && <tr className={key} />}
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="table">
            <table>
              <tbody>
                <tr>
                  {templateYears.map((year, i) => (
                    <td key={i} colSpan={year.colSpan} className="bold">
                      {year.year}
                    </td>
                  ))}
                </tr>

                {Object.keys(templateData).map((key) => (
                  <>
                    {templateData[key].map((line, index) => (
                      <tr key={index} className={key === 'totais' && 'totalLine'}>
                        {line.map((cel, i) => (
                          <td key={i} className={cel.type}>
                            {cel.type === 'date' ? cel.value : formatMoney(cel.value)}
                          </td>
                        ))}
                      </tr>
                    ))}
                    {(key === 'receitas' || key === 'despesas' || 'totais') && <tr className={key} />}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <h3 className="no-data">Nenhum resultado encontrado</h3>
      )}
    </>
  );
};

export default CashFlow;
