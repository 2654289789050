import api from '../../../../../services/api';

export const CategoryCoreUsedService = {
  endpoint: '/farms_details/:id/save_list_category_core_used',
  field: 'category_core_used',
  save_list: [],

  save: async (id, {batch_post_birth, batch_high_production_cow, batch_medium_production_cow, batch_low_production_cow, batch_pre_birth}) => {
    CategoryCoreUsedService.save_list = [];
    try {
      const prepareData = CategoryCoreUsedService.prepare({
        batch_post_birth,
        batch_high_production_cow,
        batch_medium_production_cow,
        batch_low_production_cow,
        batch_pre_birth,
      });

      return await CategoryCoreUsedService.put(id, prepareData);
    } catch (_) {
      throw new Error('Núcleo utilizados: Não foi possível salvar');
    }
  },

  defaultBodyParams: {
    batch_post_birth: false,
    batch_high_production_cow: false,
    batch_medium_production_cow: false,
    batch_low_production_cow: false,
    batch_pre_birth: false,
  },

  mapper: (data, currentValue) => {
    if (data) {
      CategoryCoreUsedService.save_list.push({
        [CategoryCoreUsedService.field]: {
          id: data.id,
        },
        ...CategoryCoreUsedService.defaultBodyParams,
        ...currentValue,
      });
    }
  },

  prepare: ({batch_post_birth, batch_high_production_cow, batch_medium_production_cow, batch_low_production_cow, batch_pre_birth}) => {
    CategoryCoreUsedService.mapper(batch_post_birth, {batch_post_birth: true});
    CategoryCoreUsedService.mapper(batch_high_production_cow, {batch_high_production_cow: true});
    CategoryCoreUsedService.mapper(batch_medium_production_cow, {batch_medium_production_cow: true});
    CategoryCoreUsedService.mapper(batch_low_production_cow, {batch_low_production_cow: true});
    CategoryCoreUsedService.mapper(batch_pre_birth, {batch_pre_birth: true});

    return CategoryCoreUsedService.save_list;
  },

  put: async (id, body) => {
    return api.put(CategoryCoreUsedService.endpoint.replace(':id', id), body);
  },
};
