import api from '../../../../../services/api';

export const TeamServices = {
  endpoint: '/teams',

  defaultParams: {
    environment: 'DAIRY_CATTLE',
  },

  list: async (params = {}) => {
    return api.get(TeamServices.endpoint, {
      params: {
        ...params,
        ...TeamServices.defaultParams,
      },
    });
  },

  mapper: ({days_worked, number_employees, salary, ...rest}) => ({
    days_worked: Number(days_worked),
    number_employees: Number(number_employees),
    salary: Number(salary),
    ...rest,
  }),

  put: async (id, body = {}) => {
    return api.put(TeamServices.endpoint.concat('/', id), {
      ...TeamServices.mapper(body),
      ...TeamServices.defaultParams,
    });
  },

  post: async (body = {}) => {
    return api.post(TeamServices.endpoint, {
      ...TeamServices.mapper(body),
      ...TeamServices.defaultParams,
    });
  },

  delete: async (id) => {
    return api.delete(TeamServices.endpoint.concat('/', id));
  },
};
