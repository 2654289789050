import React, {useState} from 'react';
import {Card} from 'reactstrap';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../../components/Section/Section';
import '../style.scss';
import GroupsHarvests from './GroupsHarvests';
import CategoryProcessingFormat from './CategoryProcessingFormat';
import CategoryOwnFarming from './CategoryOwnFarming';
import CategoryLivestockSystem from './CategoryLivestockSystem';
import CategoryType from './CategoryType';
import CategoryConfinement from './CategoryConfinement';
import CategoryILP from './CategoryILP';
import CategoryRearingSystem from './CategoryRearingSystem';
import CategoryRearingSupplementationLevel from './CategoryRearingSupplementationLevel';
import CategoryILPPercentage from './CategoryILPPercentage';
import CategoryRearingHerdSupplementation from './CategoryRearingHerdSupplementation';
import CategoryFatteningSystem from './CategoryFatteningSystem';
import CategoryFatteningSupplementationLevel from './CategoryFatteningSupplementationLevel';
import CategoryFatteningHerdSupplementation from './CategoryFatteningHerdSupplementation';
import CategoryBiomes from './CategoryBiomes';
import Country from './Country';
import Regions from './Regions';
import States from './States';
import City from './City';
import CategoryAges from './CategoryAges';
import CategoryLivestock from './CategoryLivestock';
import CategoryDeathsReason from './CategoryDeathsReason';
import CategoryHerdEntryType from './CategoryHerdEntryType';
import CategoryHerdDepartureType from './CategoryHerdDepartureType';
import CategoryArea from './CategoryArea';
import CategoryDsmTechnology from './CategoryDsmTechnology';
import CategoryRacialGroup from './CategoryRacialGroup';
import CategorySupplyType from './CategorySupplyType';
import CategoryWagonType from './CategoryWagonType';
import CategoryAnimalType from './CategoryAnimalType';
import CategoryWorkFronts from './CategoryWorkFronts';
import CategoryFunctions from './CategoryFunctions';
import CategoryMaterialType from './CategoryMaterialType';
import CategoryUnitMeasurement from './CategoryUnitMeasurement';
import CategoryProductsDsm from './CategoryProductsDsm';
import CategoryReference from './CategoryReference';
import CategoryReferenceCategoryWeaning from './CategoryReferenceCategoryWeaning';
import CategoryReproduction from './CategoryReproduction';
import CategoryWeaning from './CategoryWeaning';
import CostCenters from './CostCenters';
import CategorySector from './CategorySector';
import {ROLES} from '../../../utils/constants';
import Breadcrumbs from '../../../components/Breadcrumbs';

const Categories = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Caracteriza fazenda',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Formato de processamento',
              url: `${match.url}/formato-processamento`,
            },
            {
              title: 'Agricultura própria',
              url: `${match.url}/agricultura-propria`,
            },
            {
              title: 'Sistema pecuária',
              url: `${match.url}/sistema-pecuaria`,
            },
            {
              title: 'Tipo',
              url: `${match.url}/tipo`,
            },
            {
              title: 'Tipo de insumo',
              url: `${match.url}/tipo-insumo`,
            },
            {
              title: 'Produtos DSM',
              url: `${match.url}/produtos-dsm`,
            },
            {
              title: 'Pasto confinamento',
              url: `${match.url}/pasto-confinamento`,
            },
            {
              title: 'ILP',
              url: `${match.url}/ilp`,
            },
            {
              title: 'Sistema recria',
              url: `${match.url}/sistema-recria`,
            },
            {
              title: 'Nível suplementaçao recria',
              url: `${match.url}/nivel-suplementacao-recria`,
            },
            {
              title: '% Rebanho suplementação recria',
              url: `${match.url}/rebanho-suplementacao-recria`,
            },
            {
              title: 'Percentual de ILP',
              url: `${match.url}/percentual-ilp`,
            },
            {
              title: 'Sistema engorda',
              url: `${match.url}/sistema-engorda`,
            },
            {
              title: 'Nível suplementação engorda',
              url: `${match.url}/nivel-suplementacao-engorda`,
            },
            {
              title: '% Rebanho suplementação engorda',
              url: `${match.url}/rebanho-suplementacao-engorda`,
            },
            {
              title: 'Bioma',
              url: `${match.url}/bioma`,
            },
            {
              title: 'País',
              url: `${match.url}/pais`,
            },
            {
              title: 'Região',
              url: `${match.url}/regiao`,
            },
            {
              title: 'Estado',
              url: `${match.url}/estado`,
            },
            {
              title: 'Cidade',
              url: `${match.url}/cidade`,
            },
          ],
        },
      ],
    },
    {
      category: 'Safra',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Grupo de safra',
              url: `${match.url}/grupo-safra`,
            },
          ],
        },
      ],
    },
    {
      category: 'Pecuária',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Idades',
              url: `${match.url}/idades`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Efetivo pecuário',
              url: `${match.url}/efetivo_pecuario`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Motivos para mortes',
              url: `${match.url}/motivo_para_mortes`,
            },
            {
              title: 'Tipo de entrada do rebanho',
              url: `${match.url}/tipo_entrada_rebanho`,
            },
            {
              title: 'Tipo de saída do rebanho',
              url: `${match.url}/tipo_saida_rebanho`,
            },
            {
              title: 'Áreas',
              url: `${match.url}/areas`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Referências',
              url: `${match.url}/referencias`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Referências para desmames',
              url: `${match.url}/referencias_desmames`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Reprodução',
              url: `${match.url}/reproducao`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Categoria desmame',
              url: `${match.url}/categoria_desmame`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Grupo racial',
              url: `${match.url}/grupo_racial`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Tipo Animal',
              url: `${match.url}/tipo_animal`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Tipo de vagão',
              url: `${match.url}/tipo_vagao`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Tipo de fornecimento',
              url: `${match.url}/tipo_fornecimento`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Tecnologia DSM',
              url: `${match.url}/tecnologia_dsm`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
          ],
        },
      ],
    },
    {
      category: 'Equipe',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Frentes de trabalho',
              url: `${match.url}/frentes-trabalho`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
            },
            {
              title: 'Funções',
              url: `${match.url}/funcoes`,
            },
          ],
        },
      ],
    },

    {
      category: 'Financeiro',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Tipos de centros de custo',
              url: `${match.url}/tipos-centros-custo`,
            },
          ],
        },
      ],
    },
    {
      category: 'Estoque',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Tipo insumo',
              url: `${match.url}/tipo-insumo`,
            },
            {
              title: 'Unidades',
              url: `${match.url}/unidades`,
            },
            {
              title: 'Produtos DSM',
              url: `${match.url}/produtos-dsm`,
            },
          ],
        },
      ],
    },
    {
      category: 'Configuração',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Setor',
              url: `${match.url}/setor`,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div>
      <Card>
        <GuardedRoute exact path={match.url} meta={{moduleName: 'Categorias'}} render={() => <Section templateData={templateData} />} />
        <GuardedRoute path={`${match.url}/grupo-safra`} meta={{moduleName: 'Grupo de safra'}} component={GroupsHarvests} />
        <GuardedRoute
          path={`${match.url}/formato-processamento`}
          meta={{moduleName: 'Formato de processamento'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Formato de processamento'},
                ]}
              />
              <CategoryProcessingFormat />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/agricultura-propria`}
          meta={{moduleName: 'Agricultura própria'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Agricultura própria'},
                ]}
              />
              <CategoryOwnFarming />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/sistema-pecuaria`}
          meta={{moduleName: 'Sistema pecuária'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Sistema pecuária'},
                ]}
              />
              <CategoryLivestockSystem />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipo`}
          meta={{moduleName: 'Tipo'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipo'},
                ]}
              />
              <CategoryType />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipos-centros-custo`}
          meta={{moduleName: 'Tipos de centros de custo'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipos de centros de custo'},
                ]}
              />
              <CostCenters />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipo-insumo`}
          meta={{moduleName: 'Tipo de insumo'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipo de insumo'},
                ]}
              />
              <CategoryMaterialType />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/produtos-dsm`}
          meta={{moduleName: 'Produtos DSM'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Produtos DSM'},
                ]}
              />
              <CategoryProductsDsm />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/pasto-confinamento`}
          meta={{moduleName: 'Pasto confinamento'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Pasto confinamento'},
                ]}
              />
              <CategoryConfinement />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/ilp`}
          meta={{moduleName: 'ILP'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'ILP'},
                ]}
              />
              <CategoryILP />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/sistema-recria`}
          meta={{moduleName: 'Sistema recria'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Sistema recria'},
                ]}
              />
              <CategoryRearingSystem />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/nivel-suplementacao-recria`}
          meta={{moduleName: 'Nível suplementaçao recria'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Nível suplementaçao recria'},
                ]}
              />
              <CategoryRearingSupplementationLevel />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/rebanho-suplementacao-recria`}
          meta={{moduleName: '% Rebanho suplementação recria'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: '% Rebanho suplementação recria'},
                ]}
              />
              <CategoryRearingHerdSupplementation />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/percentual-ilp`}
          meta={{moduleName: 'Percentual de ILP'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[{title: 'Cadastros', url: '/cadastros'}, {title: 'Categorias', url: '/cadastros/categorias'}, {title: 'Percentual de ILP'}]}
              />
              <CategoryILPPercentage />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/sistema-engorda`}
          meta={{moduleName: 'Sistema engorda'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Sistema engorda'},
                ]}
              />
              <CategoryFatteningSystem />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/nivel-suplementacao-engorda`}
          meta={{moduleName: 'Nível suplementação engorda'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Nível suplementação engorda'},
                ]}
              />
              <CategoryFatteningSupplementationLevel />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/rebanho-suplementacao-engorda`}
          meta={{moduleName: '% Rebanho suplementação engorda'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: '% Rebanho suplementação engorda'},
                ]}
              />
              <CategoryFatteningHerdSupplementation />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/bioma`}
          meta={{moduleName: 'Bioma'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Bioma'},
                ]}
              />
              <CategoryBiomes />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/pais`}
          meta={{moduleName: 'País'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'País'},
                ]}
              />
              <Country />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/regiao`}
          meta={{moduleName: 'Região'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Região'},
                ]}
              />
              <Regions />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/estado`}
          meta={{moduleName: 'Estado'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Estado'},
                ]}
              />
              <States />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/cidade`}
          meta={{moduleName: 'Cidade'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Cidade'},
                ]}
              />
              <City />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/idades`}
          meta={{moduleName: 'Idades', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Idades'},
                ]}
              />
              <CategoryAges />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/efetivo_pecuario`}
          meta={{moduleName: 'Efetivo pecuário', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Efetivo pecuário'},
                ]}
              />
              <CategoryLivestock />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/motivo_para_mortes`}
          meta={{moduleName: 'Motivos para mortes'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Motivos para mortes'},
                ]}
              />
              <CategoryDeathsReason />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipo_entrada_rebanho`}
          meta={{moduleName: 'Tipo de entrada do rebanho'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipo de entrada do rebanho'},
                ]}
              />
              <CategoryHerdEntryType />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipo_saida_rebanho`}
          meta={{moduleName: 'Tipo de saída do rebanho'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipo de saída do rebanho'},
                ]}
              />
              <CategoryHerdDepartureType />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/areas`}
          meta={{moduleName: 'Áreas', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Áreas'},
                ]}
              />
              <CategoryArea />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/referencias`}
          meta={{moduleName: 'Referências', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Referências'},
                ]}
              />
              <CategoryReference />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/referencias_desmames`}
          meta={{
            moduleName: 'Referências para desmames',
            allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN],
          }}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Referências para desmames'},
                ]}
              />
              <CategoryReferenceCategoryWeaning />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/reproducao`}
          meta={{moduleName: 'Reprodução', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Reprodução'},
                ]}
              />
              <CategoryReproduction />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/categoria_desmame`}
          meta={{moduleName: 'Categoria desmame', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Categoria desmame'},
                ]}
              />
              <CategoryWeaning />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/grupo_racial`}
          meta={{moduleName: 'Grupo Racial', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Grupo Racial'},
                ]}
              />
              <CategoryRacialGroup />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipo_animal`}
          meta={{moduleName: 'Tipo Animal', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipo Animal'},
                ]}
              />
              <CategoryAnimalType />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipo_vagao`}
          meta={{moduleName: 'Tipo de Vagão', allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN]}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipo de Vagão'},
                ]}
              />
              <CategoryWagonType />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tipo_fornecimento`}
          meta={{
            moduleName: 'Tipo de Fornecimento',
            allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN],
          }}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tipo de Fornecimento'},
                ]}
              />
              <CategorySupplyType />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/tecnologia_dsm`}
          meta={{
            moduleName: 'Tecnologia DSM',
            allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN],
          }}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Tecnologia DSM'},
                ]}
              />
              <CategoryDsmTechnology />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/frentes-trabalho`}
          meta={{
            moduleName: 'Frentes de trabalho',
            allowed: [[ROLES, ROLES.ADMIN].SUPER_ADMIN],
          }}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Frentes de trabalho'},
                ]}
              />
              <CategoryWorkFronts />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/funcoes`}
          meta={{moduleName: 'Funções'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Funções'},
                ]}
              />
              <CategoryFunctions />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/unidades`}
          meta={{moduleName: 'Unidades'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Unidades'},
                ]}
              />
              <CategoryUnitMeasurement />
            </>
          )}
        />
        <GuardedRoute
          path={`${match.url}/setor`}
          meta={{moduleName: 'Setor'}}
          render={() => (
            <>
              <Breadcrumbs
                path={[
                  {title: 'Cadastros', url: '/cadastros'},
                  {title: 'Categorias', url: '/cadastros/categorias'},
                  {title: 'Corte', url: '/cadastros/categorias/corte'},
                  {title: 'Setor'},
                ]}
              />
              <CategorySector />
            </>
          )}
        />
      </Card>
    </div>
  );
};

export default Categories;
