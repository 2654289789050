import apiNew from '../apiNew';

const fetchCategoryDeathsReason = (params = {}) => {
  return apiNew.get(`/category_deaths_reason`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryDeathsReasonbyId = (id, params = {}) => {
  return apiNew.get(`/category_deaths_reason/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryDeathsReason = (body = {}) => {
  return apiNew.post(`/category_deaths_reason`, body);
};

const changeCategoryDeathsReasonbyId = (id, body = {}) => {
  return apiNew.put(`/category_deaths_reason/${id}`, body);
};

const deleteCategoryDeathsReason = (id) => {
  return apiNew.delete(`/category_deaths_reason/${id}`);
};

export {fetchCategoryDeathsReason, fetchCategoryDeathsReasonbyId, registerCategoryDeathsReason, changeCategoryDeathsReasonbyId, deleteCategoryDeathsReason};
