import React, {useContext, useEffect, useState} from 'react';
import './style.scss';
import {FarmDetailService} from './services/FarmDetailService';
import VerticalGrid from '../../../../components/VerticalGrid/verticalGrid';
import {DairyHelpers} from '../../utils/dairyHelpers';
import Loading from '../../../../components/Loading/Loading';
import Header from './_partial/header';
import Notify from './_partial/alert';
import {FarmDetailHelper} from './utils/farmDetailHelper';
import {DataContext} from '../../../../utils/DataProvider';
import {history} from '../../../../utils/utils';

const FarmDetail = () => {
  const [loading, setLoading] = useState(true);
  // const [refresh, setRefresh] = useState(false);
  // const [data, setData] = useState(null);
  const {state, dispatch} = useContext(DataContext);
  const [categories, setCategories] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [dropdownArrow, setDropdownArrow] = useState({
    general: true,
    feedingSystem: true,
    zootechnical: true,
    sutentability: true,
  });

  const defaultValuesGrid = {
    styleLabel: {
      width: '370px',
    },
  };

  const [alertError, setAlertError] = useState({
    message: '',
    type: 'success',
  });

  const [open, setOpen] = useState(false);

  // const onRefresh = () => {
  //   setRefresh((prev) => !prev);
  // };

  const handleDropdown = (dropkey) => {
    setDropdownArrow((prev) => ({
      ...prev,
      [dropkey]: !prev[dropkey],
    }));
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await FarmDetailService.list();
        const cats = await FarmDetailService.categories();
        const curData = result?.total === 1 ? result.list[0] : null;

        // setData(result);
        setCategories(cats);
        if (curData) {
          setCurrentData(curData);
        }
        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('Ocorreu um erro ao os dados, tente novamente mais tarde');
      }
    })();
  }, []);

  // const handleSelectFarmDetail = (value) => {
  //   const id = value?.farmDetailFormSelect?.id;
  //   if (!id) return;

  //   setCurrentData(data.list.find((item) => item.id === id));
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //     clearTimeout(timer);
  //   }, 500);
  // };

  const DropdownHeader = ({children, dropkey}) => {
    return (
      <div className="custom-dropdown-header" onClick={() => handleDropdown(dropkey)}>
        {children}
        <i className={`fas fa-chevron-${dropdownArrow[dropkey] ? 'down' : 'right'}`} />
      </div>
    );
  };

  // const handleNew = () => {
  //   setLoading(true);
  //   setCurrentData(null);
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //     clearTimeout(timer);
  //   }, 500);
  // };

  // const handleDelete = async () => {
  //   const a = window.confirm('Tem certeza que deseja excluir?');

  //   if (!a) return;

  //   setLoading(true);

  //   try {
  //     await FarmDetailService.delete(currentData.id);
  //     setAlertError({
  //       type: 'success',
  //       message: 'Deletado com sucesso',
  //     });
  //     setCurrentData(null);
  //     onRefresh();
  //   } catch (e) {
  //     setAlertError({
  //       type: 'error',
  //       message: 'Ocorreu um erro ao excluir a fazenda',
  //     });
  //   } finally {
  //     setOpen(true);
  //     setLoading(false);
  //   }
  // };

  if (loading) return <Loading />;

  return (
    <>
      <div className="dairy-farm-form mt-4">
        <VerticalGrid
          onSubmit={() => {}}
          data={
            [
              // {
              //   style: {
              //     width: '100%',
              //   },
              //   items: [
              //     {
              //       name: 'header',
              //       title: '',
              //       component: (
              //         <Header
              //           newContent={{
              //             show: true,
              //             handle: handleNew,
              //             enable: false,
              //           }}
              //         />
              //       ),
              //     },
              //   ],
              // },
              // {
              //   items: [
              //     {
              //       name: 'farmDetailFormSelect',
              //       title: 'Selecione a caracterização da fazenda',
              //       component: 'Autocomplete',
              //       options: FarmDetailHelper.selectFarmDetailList(data.list),
              //       value: FarmDetailHelper.getFarmDetail(currentData),
              //       onChange: handleSelectFarmDetail,
              //       styleLabel: {
              //         width: '370px',
              //       },
              //     },
              //   ],
              // },
            ]
          }
        />
      </div>

      <div className="dairy-farm-grid">
        <VerticalGrid
          onSubmit={async (values) => {
            setLoading(true);
            try {
              const {current} = await FarmDetailService.save(values, state, dispatch, history);

              // setData({list, total});
              setCurrentData(current);
              setAlertError({
                type: 'success',
                message: 'Salvo com sucesso',
              });
            } catch (e) {
              setAlertError({
                type: 'error',
                message: e.response.data.message,
              });
            } finally {
              setOpen(true);
              setLoading(false);
            }
          }}
          data={[
            {
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'header',
                  title: '',
                  component: (
                    <Header
                      // deleteContent={{
                      //   show: true,
                      //   handle: handleDelete,
                      //   enable: !!currentData,
                      // }}
                      saveContent={{
                        show: true,
                        enable: false,
                      }}
                    />
                  ),
                },
              ],
            },
            {
              component: <DropdownHeader dropkey="general">Geral</DropdownHeader>,
              dropdownState: dropdownArrow.general,
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'id',
                  value: currentData?.id || '',
                },
                {
                  name: 'farm',
                  title: 'Fazenda',
                  component: 'Autocomplete',
                  options: FarmDetailHelper.getFarms(categories?.farms) || [],
                  value: FarmDetailHelper.getFarm(currentData?.farm || null),
                  ...defaultValuesGrid,
                },
                {
                  name: 'name',
                  title: 'Cadastro',
                  component: 'Input',
                  value: currentData?.name || '',
                  validations: [
                    {
                      type: 'required',
                      params: ['Campo obrigatório'],
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'total_area',
                  title: 'Área para atividade leiteira (ha)',
                  component: 'Number',
                  maxValue: 99999999999999999999,
                  format: '##################',
                  value: currentData?.total_area || '',
                  ...defaultValuesGrid,
                },
                {
                  name: 'state',
                  title: 'Estado',
                  component: 'Autocomplete',
                  options: categories.states,
                  value: currentData?.state || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_tortuga_customer',
                  title: 'Cliente Tortuga',
                  component: 'Autocomplete',
                  options: categories?.category_tortuga_customer || [],
                  value: currentData?.category_tortuga_customer || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_rearing',
                  title: 'Categoria',
                  component: 'Autocomplete',
                  options: categories?.category_rearing || [],
                  value: currentData?.category_rearing || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_predominant_race',
                  title: 'Raça predominante',
                  component: 'Autocomplete',
                  options: categories?.category_predominant_race || [],
                  value: currentData?.category_predominant_race || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_milking',
                  title: 'Ordenha',
                  component: 'Autocomplete',
                  options: categories?.category_milking || [],
                  value: currentData?.category_milking || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'use_bst',
                  title: 'Uso de BST',
                  component: 'Autocomplete',
                  options: DairyHelpers.autoCompleteYesNo(),
                  value: DairyHelpers.getValueYesNo(currentData?.use_bst),
                  ...defaultValuesGrid,
                },
                {
                  name: 'use_oxytocin_milking',
                  title: 'Uso de ocitocina na ordenha',
                  component: 'Autocomplete',
                  options: DairyHelpers.autoCompleteYesNo(),
                  value: DairyHelpers.getValueYesNo(currentData?.use_oxytocin_milking),
                  ...defaultValuesGrid,
                },
              ],
            },
            {
              component: <DropdownHeader dropkey="feedingSystem">Sistema de Alimentação</DropdownHeader>,
              dropdownState: dropdownArrow.feedingSystem,
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'category_bulky',
                  title: 'Volumoso',
                  component: 'Autocomplete',
                  options: categories?.category_bulky,
                  value: currentData?.category_bulky || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_commercial_feed',
                  title: 'Ração comercial',
                  component: 'Autocomplete',
                  multiple: true,
                  options: categories.category_commercial_feed,
                  value: currentData?.list_category_commercial_feed || [],
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_main_bulk_used',
                  title: 'Principal volumoso utilizado',
                  columns: [
                    {
                      name: 'category_main_bulk_used_winter',
                      title: 'Inverno',
                      styleLabel: {
                        width: '100px',
                      },
                      component: 'Autocomplete',
                      multiple: true,
                      limit: 2,
                      ...DairyHelpers.onOptionsAndValueByCatAndData(categories, currentData, 'category_main_bulk_used', 'winter', true),
                      validations: [
                        {
                          type: 'required',
                          params: ['Campo obrigatório'],
                        },
                      ],
                    },
                    {
                      name: 'category_main_bulk_used_summer',
                      title: 'Verão',
                      styleLabel: {
                        width: '100px',
                      },
                      component: 'Autocomplete',
                      multiple: true,
                      limit: 2,
                      ...DairyHelpers.onOptionsAndValueByCatAndData(categories, currentData, 'category_main_bulk_used', 'summer', true),
                    },
                  ],
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_bulky_production',
                  title: 'Produção de volumoso',
                  component: 'Autocomplete',
                  options: categories?.category_bulky_production || [],
                  value: currentData?.category_bulky_production || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_feeding',
                  title: 'Alimentação',
                  component: 'Autocomplete',
                  options: categories?.category_feeding || [],
                  value: currentData?.category_feeding || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_core_used',
                  title: 'Núcleo utilizados',
                  ...defaultValuesGrid,
                  columns: [
                    {
                      name: 'category_core_used_batch_post_birth',
                      title: 'Lote pós parto',
                      styleLabel: {
                        width: '300px',
                      },
                      component: 'Autocomplete',
                      ...DairyHelpers.onOptionsAndValueByCatAndData(categories, currentData, 'category_core_used', 'batch_post_birth'),
                    },
                    {
                      name: 'category_core_used_batch_high_production_cow',
                      title: 'Lote de vacas de alta produção',
                      styleLabel: {
                        width: '300px',
                      },
                      component: 'Autocomplete',
                      ...DairyHelpers.onOptionsAndValueByCatAndData(categories, currentData, 'category_core_used', 'batch_high_production_cow'),
                    },
                    {
                      name: 'category_core_used_batch_medium_production_cow',
                      title: 'Lote de vacas de média produção',
                      styleLabel: {
                        width: '300px',
                      },
                      component: 'Autocomplete',
                      ...DairyHelpers.onOptionsAndValueByCatAndData(categories, currentData, 'category_core_used', 'batch_medium_production_cow'),
                    },
                    {
                      name: 'category_core_used_batch_low_production_cow',
                      title: 'Lote de vacas de baixa produção',
                      styleLabel: {
                        width: '300px',
                      },
                      component: 'Autocomplete',
                      ...DairyHelpers.onOptionsAndValueByCatAndData(categories, currentData, 'category_core_used', 'batch_low_production_cow'),
                    },
                    {
                      name: 'category_core_used_batch_pre_birth',
                      title: 'Lote pré parto',
                      styleLabel: {
                        width: '300px',
                      },
                      component: 'Autocomplete',
                      ...DairyHelpers.onOptionsAndValueByCatAndData(categories, currentData, 'category_core_used', 'batch_pre_birth'),
                    },
                  ],
                },
              ],
            },
            {
              component: <DropdownHeader dropkey="zootechnical">Zootécnico</DropdownHeader>,
              dropdownState: dropdownArrow.zootechnical,
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'first_birth_age',
                  title: 'Idade ao primeiro parto (meses)',
                  component: 'Number',
                  maxValue: 99,
                  format: '##',
                  value: currentData?.first_birth_age || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'birth_interval',
                  title: 'Intervalo de parto (meses)',
                  component: 'Number',
                  maxValue: 99,
                  format: '##',
                  value: currentData?.birth_interval || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'weaning_age',
                  title: 'Idade de desaleitamento (dias)',
                  component: 'Number',
                  maxValue: 9999,
                  format: '####',
                  value: currentData?.weaning_age || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'weaning_weight',
                  title: 'Peso ao desaleitamento (kg)',
                  component: 'Number',
                  maxValue: 9999999,
                  suffix: ' kg',
                  value: currentData?.weaning_weight || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_birth_ecc',
                  title: 'ECC (Escore de Condição Corporal) ao parto',
                  component: 'Autocomplete',
                  options: categories?.category_birth_ecc || [],
                  value: currentData?.category_birth_ecc || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_productive_management',
                  title: 'Manejo Reprodutivo',
                  component: 'Autocomplete',
                  multiple: true,
                  options: categories.category_productive_management,
                  value: currentData?.list_category_productive_management || [],
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_production_system',
                  title: 'Sistema de Produção',
                  component: 'Autocomplete',
                  options: categories?.category_production_system,
                  value: currentData?.category_production_system || null,
                  ...defaultValuesGrid,
                },
              ],
            },
            {
              component: <DropdownHeader dropkey="sutentability">Sustentabilidade</DropdownHeader>,
              dropdownState: dropdownArrow.sutentability,
              style: {
                width: '100%',
              },
              items: [
                {
                  name: 'id',
                  value: currentData?.id || '',
                },
                {
                  name: 'legal_reserve',
                  title: '% Reserva Legal + App',
                  component: 'Number',
                  maxValue: 100,
                  format: '###%',
                  value: currentData?.legal_reserve || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'fire_brigade',
                  title: 'Brigada de incêndio',
                  component: 'Autocomplete',
                  options: DairyHelpers.autoCompleteYesNo(),
                  value: DairyHelpers.getValueYesNo(currentData?.fire_brigade),
                  ...defaultValuesGrid,
                },
                {
                  name: 'category_ilp_percentage',
                  title: '% ILP/ILPF',
                  component: 'Autocomplete',
                  multiple: false,
                  options: categories.category_ilp_percentage,
                  value: currentData?.category_ilp_percentage || [],
                  ...defaultValuesGrid,
                },
                {
                  name: 'use_nutrition_technology',
                  title: 'Uso de tecnologias na nutrição (TM, Monensina, Crina, Rumistar)',
                  component: 'Autocomplete',
                  options: DairyHelpers.autoCompleteYesNo(),
                  value: DairyHelpers.getValueYesNo(currentData?.use_nutrition_technology),
                  ...defaultValuesGrid,
                },
                {
                  name: 'environmental_protocols',
                  title: 'Treinamento da equipe para protocolos ambientais',
                  component: 'Autocomplete',
                  options: DairyHelpers.autoCompleteYesNo(),
                  value: DairyHelpers.getValueYesNo(currentData?.environmental_protocols),
                  ...defaultValuesGrid,
                },
                {
                  name: 'native_cultivated_pasture',
                  title: '% pasto nativo / % de pastagens cultivadas',
                  component: 'Number',
                  maxValue: 100,
                  format: '###%',
                  value: currentData?.native_cultivated_pasture || null,
                  ...defaultValuesGrid,
                },
                {
                  name: 'gips',
                  title: 'Preencheu o GIPS?',
                  component: 'Autocomplete',
                  options: DairyHelpers.autoCompleteYesNo(),
                  value: DairyHelpers.getValueYesNo(currentData?.gips),
                  ...defaultValuesGrid,
                },
              ],
            },
          ]}
        />
      </div>
      <Notify setOpen={setOpen} message={alertError?.message} open={open} type={alertError?.type} />
    </>
  );
};

export default FarmDetail;
