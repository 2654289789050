import secureStorage from './SecureStorage';
import {AUTH, FILTER, MODULE} from './constants';

export const dataReducer = (state, action) => {
  switch (action.type) {
    case AUTH.ACTION.SET_TOKEN:
      secureStorage.setItem('token', action.token);
      return {
        ...state,
        token: action.token,
      };
    case AUTH.ACTION.LOGIN:
      secureStorage.setItem('user', JSON.stringify(action.user));
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
      };
    case AUTH.ACTION.LOGOUT:
      secureStorage.clear();
      return {};
    case FILTER.ACTION.OPEN:
      return {
        ...state,
        filterOpened: true,
      };
    case FILTER.ACTION.CLOSE:
      return {
        ...state,
        filterOpened: false,
      };
    case FILTER.ACTION.SAVE_DATA:
      secureStorage.setItem('customer', JSON.stringify(action.payload.customer));
      secureStorage.setItem('harvest', JSON.stringify(action.payload.harvest));
      secureStorage.setItem('environment', JSON.stringify(action.payload.environment));
      secureStorage.setItem('farm', JSON.stringify(action.payload.farm));
      secureStorage.setItem('filterFilled', 'true');
      return {
        ...state,
        customer: action.payload.customer,
        harvest: action.payload.harvest,
        farm: action.payload.farm,
        environment: action.payload.environment,
        filterFilled: true,
        filterOpened: !(action.payload.customer && action.payload.harvest),
      };
    case FILTER.ACTION.CLEAR_DATA:
      secureStorage.removeItem('customer');
      secureStorage.removeItem('harvest');
      secureStorage.removeItem('farm');
      secureStorage.removeItem('environment');
      secureStorage.removeItem('filterFilled');
      return {
        ...state,
        customer: null,
        harvest: null,
        filterFilled: false,
        filterOpened: true,
      };
    case MODULE.ACTION.SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
