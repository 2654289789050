import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin: 0 0 50px 0;
  &:hover {
    cursor: pointer;
    & .main-btn {
      border-bottom: 3px solid #0470df;
    }
  }
`;
export const Button = styled.button`
  background-color: transparent;
  border-bottom: 3px solid transparent;
  color: #333;
  display: flex;
  font-weight: 300;
  height: 100%;
  padding: 7px 15px;
  transition: ease all 300ms;
  width: auto;
  &:hover {
    border-bottom: 3px solid #0470df;
  }
  :focus {
    border: none;
  }
`;

export const DropdownContainer = styled.div`
  padding: 10px 15px 0 0;
`;

export const StyledMenu = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 11px 1px #ddd;
  min-width: 200px;
  padding: 20px 0px;
  transition: ease all 300ms;
`;

export const MenuGroup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  & label {
    color: #7c7c7c;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0 15px;
    text-align: left;
    width: 100%;
  }
  &:after {
    border-bottom: 1px solid #ddd;
    bottom: -1px;
    content: '';
    display: flex;
    height: 1px;
    /* background-color: #ddd; */
    margin: 0 auto;
    padding: 0 15px;
    width: calc(100% - 30px);
  }
`;

export const MenuItem = styled.button`
  align-items: center;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  color: #7c7c7c;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  height: 100%;
  padding: 7px 15px;
  text-align: left;
  transition: ease all 300ms;
  width: 100%;
  &:hover {
    background-color: #0470df;
    color: #fff;
  }
`;
