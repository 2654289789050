import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../../../utils/DataProvider';
import {PurchaseServices} from '../services/PurchaseServices';
import {createOrUpdateDataFull, maskInput} from '../../../../../utils/utils';
import {DatePickerHelper} from '../../../utils/datePickerHelper';
import {MASKS} from '../../../../../utils/constants';
import {DatagridActionsHelper} from '../../../utils/datagridActionsHelper';
import DataGrid from '../../../../../components/DataGrid';
import '../css/purchase.scss';

const Purchases = () => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await PurchaseServices.list({
          ...stateFetchParams,
          ...filterParams,
        });
        const {data: currentData} = response;

        setData(currentData.resultList?.map((item) => ({...item, value: item?.amount * item?.value_head})));
        setTotal(currentData.size);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [reset, JSON.stringify(stateFetchParams).toLowerCase(), JSON.stringify(filterParams).toLowerCase()]);// eslint-disable-line

  const validationFindData = (temp) => {
    const validates = temp.map((item) => item.validated);
    return validates.length && validates.includes(true);
  };

  const handleValidateCallback = async (rows, field, checked) => {
    const promises = [];
    rows.forEach((row) => {
      if (row.notCreated) return;

      promises.push(createOrUpdateDataFull({id: row.id, validated: checked}, state, PurchaseServices.post, PurchaseServices.put));
    });

    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  const handleChange = async (inputs) => {
    if (!inputs.date) {
      const date = DatePickerHelper.newDate();
      Object.assign(inputs, {date});
    }

    if (inputs?.validated == null) {
      Object.assign(inputs, {validated: false});
    }
    if (inputs.value_head) {
      inputs.status = true;
    }
    if (inputs.value) {
      inputs.status = false;
      inputs.value_head = inputs.value / inputs.amount;
    }
    delete inputs.value;

    // Object.assign(inputs, {status: false});

    try {
      return await createOrUpdateDataFull(inputs, state, PurchaseServices.post, PurchaseServices.put);
    } catch (error) {
      return error;
    } finally {
      setReset(!reset);
    }
  };

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '20%',
      ...DatePickerHelper.dailyAndMonth(state),
    },
    {
      key: 'category_livestock',
      valueKey: 'category_livestock.title',
      field: 'category_livestock',
      name: 'Categoria',
      textAlign: 'Center',
      width: '20%',
      required: true,
      editType: 'dropdownedit',
      groupBy: (option) => option.category_livestock,
      optionsFetch: async () => PurchaseServices.categoryAnimal(),
    },
    {
      key: 'description',
      field: 'description',
      name: 'Descrição',
      width: state?.user?.user_type === 'CUSTOMER' ? '30%' : '20%',
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'amount',
      field: 'amount',
      required: true,
      name: 'Quantidade',
      width: '10%',
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'value',
      field: 'value',
      name: 'Valor Total',
      width: '10%',
      textAlign: 'Center',
      editType: 'moneyedit',
      dependencies: ['value_head'],
      extraParams: {
        minValue: 1,
      },
      required: (col) => {
        if ((col?.mode === 'NEW_LINE' && col?.data?.value_head && col?.data?.value_head.toString().length > 0) || (col.mode === 'EDIT' && col?.data?.status)) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        if ((col?.mode === 'NEW_LINE' && col?.data?.value_head && col?.data?.value_head.toString().length > 0) || (col?.mode === 'EDIT' && col?.data?.status)) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'value_head',
      field: 'value_head',
      name: 'Valor Unitário',
      width: '10%',
      textAlign: 'Center',
      editType: 'moneyedit',
      extraParams: {
        minValue: 1,
      },
      required: (col) => {
        if ((col?.mode === 'NEW_LINE' && col?.data?.value && col?.data?.value.toString().length > 0) || (col.mode === 'EDIT' && !col?.data?.status)) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        if ((col?.mode === 'NEW_LINE' && col?.data?.value && col?.data?.value.toString().length > 0) || (col?.mode === 'EDIT' && !col?.data?.status)) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      width: '10%',
      textAlign: 'Center',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = DatagridActionsHelper.actions({
    handleDelete: async (value) => {
      try {
        const response = await PurchaseServices.delete(value.id);
        setReset(!reset);
        return response;
      } catch (error) {
        return error;
      }
    },
  });

  return (
    <>
      {stateMessage}
      {data && (
        <div className="livestock-purchase">
          <DataGrid
            onChange={handleChange}
            validateCallback={handleValidateCallback}
            header={columns}
            data={data}
            width="100%"
            keyProp="key"
            maxHeight="540px"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={total}
            accumulators={{
              'Total geral': {
                columns: ['amount'],
                itemOperation: (acc, line, key) => (acc += line[key]),
                resultOperation: (acc) => {
                  return maskInput({value: acc, type: MASKS.DOUBLE});
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default Purchases;
