import React, {useState, useEffect, useContext} from 'react';
import DataGrid from '../../../components/DataGrid';
import {} from '../../../services/categoryReproduction';
import {fetchCategoryWeaning} from '../../../services/categoryWeaning';
import {
  fetchCategoryReferenceCategoryweaning,
  changeCategoryReferenceCategoryweaning,
  createCategoryReferenceCategoryweaning,
  deleteCategoryReferenceCategoryweaning,
} from '../../../services/categoryReferenceCategoryWeaning';
import {fetchCategoryReference} from '../../../services/categoryReference';
import {DataContext} from '../../../utils/DataProvider';

const CategoryReferenceCategoryweaning = () => {
  const {paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchCategoryReferenceCategoryweaning({
        ...paginationParams,
        ...filterParams,
      });

      const parseResponse = [];
      response.data.resultList.filter((item) => {
        if (item.type) {
          item.type = Object.assign(item.type, {
            id: item.type,
            title: item.type === 'BIRTH' ? 'Nascimento' : 'Desmame',
          });
        }
        parseResponse.push(item);
        return item;
      });

      setData(parseResponse);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);
  const columns = [
    {
      key: 'category_weaning',
      valueKey: 'category_weaning.title',
      field: 'category_weaning',
      name: 'Descrição',
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryWeaning();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'category_reference',
      valueKey: 'category_reference.title',
      field: 'category_reference',
      name: 'Referência',
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCategoryReference();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'type',
      valueKey: 'type.title',
      field: 'type',
      name: 'Tipo',
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = [
            {id: 'BIRTH', title: 'Nascimentos'},
            {id: 'WEANING', title: 'Desmames'},
          ];
          const objectTemp = [];
          response.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteCategoryReferenceCategoryweaning(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (dataGridInputs && dataGridInputs.type) {
              dataGridInputs.type = dataGridInputs.type.id;
            }
            let response = null;
            try {
              if (dataGridInputs.id) {
                response = await changeCategoryReferenceCategoryweaning(dataGridInputs.id, dataGridInputs);
                setReset(!reset);
                return response;
              }
              response = await createCategoryReferenceCategoryweaning(dataGridInputs);
              setReset(!reset);
              return response;
            } catch (error) {
              response = error;
              console.error(error);
              setReset(!reset);
              return response;
            }
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default CategoryReferenceCategoryweaning;
