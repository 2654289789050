import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  environment: 'DAIRY_CATTLE',
};

export const IncomeServices = {
  endpoint: '/financials',

  list: async (params = {}) => ServicesHelper.list(IncomeServices.endpoint, {...params, ...PARAMS}),

  post: async (body = {}) => ServicesHelper.post(IncomeServices.endpoint, {...body, ...PARAMS}),

  put: async (id, body = {}) => ServicesHelper.put(IncomeServices.endpoint.concat('/', id), {...body, ...PARAMS}),

  delete: async (id) => ServicesHelper.delete(IncomeServices.endpoint.concat('/', id)),
};
