import fileDownload from 'js-file-download';
import api from '../api';

const fetchSupportMaterial = async (endpoint) => {
  const response = await api.get(endpoint, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    },
  });

  return fileDownload(response.data, `${response.headers['content-disposition'].split('filename=')[1]}`);
};

export default fetchSupportMaterial;
