import React, {useState, useEffect, useContext} from 'react';
import {Pie, Bar} from 'react-chartjs-2';
import styled from 'styled-components';
import {Col, Row, Container} from 'reactstrap';
import IMask from 'imask';
import {
  fetchCardTeamFunctionXSalary,
  fetchCardTeamFunctionXPercentSalary,
  fetchCardTeamValidated,
} from '../../services/teams';
import {DataContext} from '../../utils/DataProvider';
import {getDataChart, colors, maskInput} from '../../utils/utils';
import {MASKS} from '../../utils/constants';

const Cards = () => {
  const [dataPie, setDataPie] = useState(null);
  const [dataBar, setDataBar] = useState(null);
  const [validated, setValidated] = useState(null);
  const {state, stateFetchParamsCustom, stateMessage} = useContext(DataContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setValidated(await fetchCardTeamValidated(stateFetchParamsCustom));
        setDataBar(
          await getDataChart(
            fetchCardTeamFunctionXSalary,
            stateFetchParamsCustom,
            null,
            false,
          ),
        );
        setDataPie(
          await getDataChart(
            fetchCardTeamFunctionXPercentSalary,
            {
              harvest_id: state.harvest.id,
              customer_id: state.customer.id,
              farm_id: state.farm.id,
            },
            null,
            true,
            null,
            colors,
            true,
          ),
        );
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);// eslint-disable-line

  return (
    <Container>
      {stateMessage}
      <Card type="income" className="card d-flex ">
        <Row className="w-100 pt-3">
          <Col className="d-flex justify-content-end" xs={12}>
            <i
              className={`fas fa-${
                validated && validated.data.validated ? 'check' : 'exclamation'
              }`}
            />
          </Col>
        </Row>
        <Row>
          {dataBar && (
            <Col xs={6} className="cards p-3">
              <h5 className="d-flex flex-column align-items-center">
                Função<p>Salário Médio Mensal(Holerite)</p>
              </h5>
              <Bar
                label="Função X Salário mensal"
                data={dataBar}
                // width={100}
                height={200}
                options={{
                  layout: {
                    padding: {
                      left: 0,
                      right: 0,
                      top: 40,
                      bottom: 0,
                    },
                  },
                  plugins: {
                    datalabels: {
                      anchor: (val) => {
                        if (val.dataset.data[val.dataIndex] >= 0) {
                          return 'end';
                        }
                        return 'start';
                      },
                      align: (val) => {
                        if (val.dataset.data[val.dataIndex] >= 0) {
                          return 'top';
                        }
                        return 'bottom';
                      },
                      formatter: (value) => {
                        return `R$ ${maskInput({
                          type: MASKS.DOUBLE,
                          value: `${value}`,
                        })}`;
                      },
                    },
                  },
                  tooltips: {
                    callbacks: {
                      label: (tooltipItem) => {
                        return `${IMask.createMask({
                          mask: 'R$ money',
                          blocks: {
                            money: {
                              mask: Number, // enable number mask

                              // other options are optional with defaults below
                              scale: 2, // digits after point, 0 for integers
                              signed: false, // disallow negative
                              thousandsSeparator: '.', // any single char
                              padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
                              normalizeZeros: false, // appends or removes zeros at ends
                              radix: ',', // fractional delimiter

                              // additional number interval options (e.g.)
                              min: 0,
                              max: 9999999999999,
                            },
                          },
                        }).resolve(tooltipItem.value.toString())}`;
                      },
                    },
                  },
                  legend: {
                    display: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          color: 'rgba(0, 0, 0, 0)',
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          color: 'rgba(0, 0, 0, 0)',
                        },
                        ticks: {
                          display: false,
                          // Create scientific notation labels
                          callback: (value) => {
                            return `${IMask.createMask({
                              mask: 'R$ money',
                              blocks: {
                                money: {
                                  mask: Number, // enable number mask

                                  // other options are optional with defaults below
                                  scale: 2, // digits after point, 0 for integers
                                  signed: false, // disallow negative
                                  thousandsSeparator: '.', // any single char
                                  padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
                                  normalizeZeros: false, // appends or removes zeros at ends
                                  radix: ',', // fractional delimiter

                                  // additional number interval options (e.g.)
                                  min: 0,
                                  max: 9999999999999,
                                },
                              },
                            }).resolve(value.toString())}`;
                          },
                        },
                      },
                    ],
                  },
                }}
              />
            </Col>
          )}
          {dataPie && (
            <Col xs={6} className="cards p-3">
              <Pie
                height={200}
                data={dataPie}
                options={{
                  plugins: {
                    datalabels: {
                      display: false,
                    },
                  },
                  pieceLabel: [
                    {
                      render: 'label',
                      // fontColor: '#000',
                      position: 'outside',
                      // fontFamily: 'Fire Sans',
                      // fontStyle: '100',
                      arc: false,
                    },
                    {
                      render: 'percentage',
                      fontSize: 14,
                      // fontStyle: '200',
                      fontColor: '#000',
                      // fontFamily: 'Fire Sans',
                    },
                  ],
                }}
              />
            </Col>
          )}
        </Row>
        {!dataPie && !dataBar && (
          <EmptyCardMessage>Nenhum registro encontrado</EmptyCardMessage>
        )}
      </Card>
    </Container>
  );
};

const Card = styled.div`
  background-clip: border-box;
  background-color: #fff;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-top: 2px solid rgb(218, 0, 0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0 4px 8px 0 rgb(200, 200, 200), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
  min-height: 12em;
  position: relative;
`;

const EmptyCardMessage = styled.p`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 35px 0 0 0;
  text-align: center;
  width: 100%;
`;

export default Cards;
