import React from 'react';
import {Label} from 'reactstrap';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {HeaderCol, Column, HeaderGrid, ColContainer, ValidationCheck} from './styled';
import {span} from '../../utils/utils';
import MenuFilter from './Filter/MenuFilter';
import {useDataContext} from '../../utils/DataProvider';
import {ROLES} from '../../utils/constants';

const EditableHeader = ({headerTemplate, columnsTemplate, titleProp, dataValues, validateCallback, setSort, headerHeight, width, prefetchedValues}) => {
  const {state} = useDataContext();
  // const [validationLoading, setValidationLoading] = useState(false);

  const spanDiscriminator = (headerItem) => {
    if (headerItem.rowSpan) {
      return span('rowspan', headerItem.rowSpan);
    }
    if (headerItem.colSpan) {
      return span('colspan', headerItem.colSpan);
    }
    return {};
  };

  const findValidationData = (data, field, validationObject, fieldValuekey) => {
    if (validationObject && validationObject.validationFindData) {
      return validationObject.validationFindData(data, field, fieldValuekey);
    }

    return false;
  };

  const validationTrigger = async (data, field, checked, valueKey) => {
    if (validateCallback) {
      return validateCallback(data, field, checked, valueKey);
    }

    return false;
  };

  const TooltipCustom = ({element}) => {
    return (
      <>
        {element[titleProp] && element.tooltip ? (
          <Label className="float-label m-0">
            <button className="button-tooltip" type="button">
              {element[titleProp]}
              <Tooltip
                arrow
                style={{height: '100%'}}
                classes={makeStyles((theme) => ({
                  arrow: {
                    color: theme.palette.common.black,
                  },
                  tooltip: {
                    backgroundColor: theme.palette.common.black,
                    fontSize: '12px',
                  },
                }))()}
                title={<p>{element.tooltip}</p>}>
                <i className="fas fa-question-circle ml-2" />
              </Tooltip>
            </button>
          </Label>
        ) : (
          <Label className="float-label m-0">{element[titleProp]}</Label>
        )}
      </>
    );
  };

  return (
    <HeaderCol
      style={{
        display: 'grid',
        gridTemplateColumns: columnsTemplate,
        width: width || 'max-content',
        borderBottom: '2px solid #dddddd',
        height: headerHeight || 'auto',
        position: 'sticky',
        top: 0,
        zIndex: 9,
      }}>
      {headerTemplate.length &&
        headerTemplate.map((headerItem, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${headerItem[titleProp]}-${headerItem.parent ? headerItem.parent : 'parent'}${i}`}
            style={{...spanDiscriminator(headerItem)}}>
            <Column>
              {typeof headerItem[titleProp] === 'object' ? (
                <>
                  {headerItem[titleProp].map((item) => (
                    <HeaderGrid className="cel" key={item}>
                      {item}
                    </HeaderGrid>
                  ))}
                </>
              ) : (
                <HeaderGrid className="cel" key={`${headerItem[titleProp]}-${headerItem.parent ? headerItem.parent : 'parent'}`}>
                  <ColContainer>
                    <TooltipCustom element={headerItem} />
                  </ColContainer>
                  {headerItem.validationHeader && state.user.user_type !== ROLES.CUSTOMER && state.user.user_type !== ROLES.CUSTOMER_DIAGNOSIS && (
                    <ColContainer>
                      <label htmlFor="validation">
                        {headerItem.validationHeader.showLabel === undefined || headerItem.validationHeader.showLabel ? 'Validar' : ''}
                      </label>

                      <ValidationCheck
                        type="checkbox"
                        id="validation"
                        name="validation"
                        checked={!!findValidationData(dataValues, headerItem.field, headerItem.validationHeader, headerItem.valueKey || null)}
                        onChange={async (event) => {
                          // setValidationLoading(true);
                          await validationTrigger(dataValues, headerItem.field, event.target.checked, headerItem.valueKey || null);
                          // setValidationLoading(false);
                        }}
                      />
                    </ColContainer>
                  )}
                  {!headerItem.validationHeader && !headerItem.disableFilter && (
                    <MenuFilter data={dataValues} setSort={setSort} headerItem={headerItem} prefetchedValues={prefetchedValues || {}} />
                  )}
                </HeaderGrid>
              )}
            </Column>
          </div>
        ))}
    </HeaderCol>
  );
};

export default EditableHeader;
