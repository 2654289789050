import {DairyHelpers} from '../../../utils/dairyHelpers';
import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  // environment: 'DAIRY_CATTLE',
};

export const MilkQualityServices = {
  endpoint: '/milk_quality',

  list: async (params = {}, {first_year, last_year}) => {
    const {data = {}} = await ServicesHelper.list(MilkQualityServices.endpoint, {...params, ...PARAMS});

    const categories = await MilkQualityServices.loadCategory('category_milk_quality_type');

    return {
      categories,
      data: {
        resultList: MilkQualityServices.mapper(data?.resultList, categories, first_year, last_year),
        size: data.size,
      },
    };
  },

  prepareToSave: (body) => {
    const valuesWithKeys = [];
    Object.keys(body).forEach((key) => {
      if (key.split('.').length - 1 === 2) {
        valuesWithKeys.push(key);
      }
    });

    const data = [];
    valuesWithKeys.forEach((item) => {
      const arrKeys = item.split('.');
      const accessKey = `${arrKeys[0]}.${arrKeys[1]}`;
      const id = DairyHelpers.resolve(`${accessKey}.id`, body);
      const month = `0${arrKeys[1]}`.slice(-2);
      data.push({
        category_milk_quality_type: {id: body.id},
        customer: {id: body.customer.id},
        farm: {id: body.farm.id},
        harvest: {id: body.harvest.id},
        quantity: Number(body[item]) != null ? Number(body[item]) : null,
        date: id ? DairyHelpers.resolve(`${accessKey}.date`, body) : `01/${month}/${arrKeys[0]} 00:00:00`,
        // ...(id && {id}),
        ...(!!body.validated && {validated: body.validated}),
        created_by: {id: body.currentUser},
        updated_by: {id: body.currentUser},
      });
    });

    return data;
  },

  post: async (body = {}) => ServicesHelper.post(MilkQualityServices.endpoint, {...body, ...PARAMS}),

  delete: async (id) => ServicesHelper.delete(MilkQualityServices.endpoint.concat('/', id)),

  put: async (id, body = {}) => {
    const sanitizeBody = MilkQualityServices.prepareToSave(body);
    return ServicesHelper.post(MilkQualityServices.endpoint.concat('/batch'), sanitizeBody);
  },

  loadCategory: async (endpoint, params = {}) => ServicesHelper.loadCategory(endpoint, params),

  getValueMapper: (data, year, month) => {
    const months = ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const currentMonth = `0${month}`.slice(-2).concat('/', year);
    const label = `${months[month]}/${year}`;

    const line = data.find((item) => item.date.slice(3, 10) === currentMonth);
    return {
      label,
      ...{
        ...(line?.id && {id: line.id}),
        date: line?.date || null,
        quantity: line?.quantity != null ? line?.quantity : null,
        validated: line?.validated || null,
      },
    };
  },

  mapper: (data, categories, first_year, last_year) => {
    return categories.map((cat) => {
      const filteredData = data.filter((item) => item.category_milk_quality_type.id === cat.value);

      return {
        [first_year]: {
          7: MilkQualityServices.getValueMapper(filteredData, first_year, 7),
          8: MilkQualityServices.getValueMapper(filteredData, first_year, 8),
          9: MilkQualityServices.getValueMapper(filteredData, first_year, 9),
          10: MilkQualityServices.getValueMapper(filteredData, first_year, 10),
          11: MilkQualityServices.getValueMapper(filteredData, first_year, 11),
          12: MilkQualityServices.getValueMapper(filteredData, first_year, 12),
        },
        [last_year]: {
          1: MilkQualityServices.getValueMapper(filteredData, last_year, 1),
          2: MilkQualityServices.getValueMapper(filteredData, last_year, 2),
          3: MilkQualityServices.getValueMapper(filteredData, last_year, 3),
          4: MilkQualityServices.getValueMapper(filteredData, last_year, 4),
          5: MilkQualityServices.getValueMapper(filteredData, last_year, 5),
          6: MilkQualityServices.getValueMapper(filteredData, last_year, 6),
        },
        id: cat.value,
        title: cat.label,
      };
    });
  },
};
