import React, {useContext, useEffect, useState} from 'react';
import {fetchChecklistSyntheticData} from '../../../services/checklist';
import SyntheticDataPresenter from './SyntheticDataPresenter';
import {DataContext} from '../../../utils/DataProvider';
import Loading from '../../../components/Loading/Loading';

const SyntheticWithData = () => {
  const {state} = useContext(DataContext);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await fetchChecklistSyntheticData({
        'wherein[customer.id]': state.customer.id,
        'wherein[harvest.id]': state.harvest.id,
        'wherein[farm.id]': state.farm.id,
        initialDate: `${state.harvest.first_year}-07`,
        endDate: `${state.harvest.last_year}-06`,
      });
      setTableData(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        loadData();
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);// eslint-disable-line

  if (loading) {
    return <Loading style={{marginRight: '6rem', marginTop: '1rem'}} />;
  }

  if (tableData) {
    return <SyntheticDataPresenter data={tableData} />;
  }

  return <div />;
};

export default SyntheticWithData;
