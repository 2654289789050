import {getValueObject} from '../../../../../utils/utils';

const validationFindData = (data, field, valueKey) => {
  let validated = true;
  data.forEach((row) => {
    validated = validated && getValueObject(row, null, valueKey).validated;
  });

  return validated;
};

const columns = (climatesFirstYear, climatesLastYear) => [
  {
    key: 'start_stock',
    valueKey: 'start_stock',
    field: 'start_stock',
    name: 'Estoque de partida',
    textAlign: 'Center',
    // returnFormat: valueFormatter,
    validationHeader: {validationFindData},
    editType: 'numericedit',
    subFields: [
      {
        key: 'content',
        valueKey: 'start_stock',
        field: 'content',
        name: '',
        textAlign: 'Center',
        disableFilter: true,
        editType: 'numericedit',
        subFields: [
          {
            key: 'start_stock.amount',
            valueKey: 'start_stock.amount',
            field: 'amount',
            customKey: 'valueKey',
            width: '90px',
            name: 'Qtde',
            textAlign: 'Center',
            editType: 'numericedit',
            disableFilter: true,
            disabledEdit: (col) => {
              if (col?.currentCel?.cel <= 1) {
                return false;
              }
              return true;
            },
            // extraParams: {
            //   minValue: 0,
            // },
          },
          {
            key: 'start_stock.weight',
            disableFilter: true,
            valueKey: 'start_stock.weight',
            field: 'weight',
            name: 'Preço(R$)',
            width: '90px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel <= 1) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    ],
  },
  {
    field: climatesFirstYear,
    disableFilter: true,
    subFields: [
      {
        key: '7',
        valueKey: climatesFirstYear.toString().concat('.7'),
        field: '7',
        name: 'Jul/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesFirstYear.toString().concat('.7.amount'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.7.amount'),
            field: '7',
            width: '90px',
            name: 'Qtde',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 2 && col?.currentCel?.cel < 4) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesFirstYear.toString().concat('.7.weight'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.7.weight'),
            field: '7',
            name: 'Preço(R$)',
            width: '90px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 2 && col?.currentCel?.cel < 4) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '8',
        valueKey: climatesFirstYear.toString().concat('.8'),
        field: '8',
        name: 'Ago/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesFirstYear.toString().concat('.8.amount'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.8.amount'),
            field: '8',
            name: 'Qtde',
            width: '90px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 4 && col?.currentCel?.cel < 6) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesFirstYear.toString().concat('.8.weight'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.8.weight'),
            field: '8',
            name: 'Preço(R$)',
            width: '90px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 4 && col?.currentCel?.cel < 6) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '9',
        valueKey: climatesFirstYear.toString().concat('.9'),
        field: '9',
        name: 'Set/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesFirstYear.toString().concat('.9.amount'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.9.amount'),
            field: '9',
            name: 'Qtde',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 6 && col?.currentCel?.cel < 8) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesFirstYear.toString().concat('.9.weight'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.9.weight'),
            field: '9',
            name: 'Preço(R$)',
            width: '90px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 6 && col?.currentCel?.cel < 8) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '10',
        valueKey: climatesFirstYear.toString().concat('.10'),
        field: '10',
        name: 'Out/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesFirstYear.toString().concat('.10.amount'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.10.amount'),
            field: '10',
            name: 'Qtde',
            width: '90px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 8 && col?.currentCel?.cel < 10) {
                return false;
              }
              return true;
            },
          },

          {
            key: climatesFirstYear.toString().concat('.10.weight'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.10.weight'),
            field: '10',
            name: 'Preço(R$)',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 8 && col?.currentCel?.cel < 10) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '11',
        valueKey: climatesFirstYear.toString().concat('.11'),
        field: '11',
        name: 'Nov/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesFirstYear.toString().concat('.11.amount'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.11.amount'),
            field: '11',
            name: 'Qtde',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 10 && col?.currentCel?.cel < 12) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesFirstYear.toString().concat('.11.weight'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.11.weight'),
            field: '11',
            width: '90px',
            name: 'Preço(R$)',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 10 && col?.currentCel?.cel < 12) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '12',
        valueKey: climatesFirstYear.toString().concat('.12'),
        field: '12',
        name: 'Dez/'.concat(climatesFirstYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesFirstYear.toString().concat('.12.amount'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.12.amount'),
            field: '12',
            name: 'Qtde',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 12 && col?.currentCel?.cel < 14) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesFirstYear.toString().concat('.12.weight'),
            disableFilter: true,
            valueKey: climatesFirstYear.toString().concat('.12.weight'),
            field: '12',
            name: 'Preço(R$)',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 12 && col?.currentCel?.cel < 14) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    ],
    name: climatesFirstYear,
    textAlign: 'Center',
  },
  {
    field: climatesLastYear,
    disableFilter: true,
    subFields: [
      {
        key: '1',
        valueKey: climatesLastYear.toString().concat('.1'),
        field: '1',
        name: 'Jan/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesLastYear.toString().concat('.1.amount'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.1.amount'),
            field: '1',
            name: 'Qtde',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 14 && col?.currentCel?.cel < 16) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesLastYear.toString().concat('.1.weight'),
            disableFilter: true,

            valueKey: climatesLastYear.toString().concat('.1.weight'),
            field: '1',
            name: 'Preço(R$)',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 14 && col?.currentCel?.cel < 16) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '2',
        valueKey: climatesLastYear.toString().concat('.2'),
        field: '2',
        name: 'Fev/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesLastYear.toString().concat('.2.amount'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.2.amount'),
            field: '2',
            name: 'Qtde',
            textAlign: 'Center',
            width: '90px',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 16 && col?.currentCel?.cel < 18) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesLastYear.toString().concat('.2.weight'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.2.weight'),
            field: '2',
            name: 'Preço(R$)',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 16 && col?.currentCel?.cel < 18) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '3',
        valueKey: climatesLastYear.toString().concat('.3'),
        field: '3',
        name: 'Mar/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesLastYear.toString().concat('.3.amount'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.3.amount'),
            field: '3',
            name: 'Qtde',
            textAlign: 'Center',
            width: '90px',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 18 && col?.currentCel?.cel < 20) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesLastYear.toString().concat('.3.weight'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.3.weight'),
            field: '3',
            name: 'Preço(R$)',
            textAlign: 'Center',
            width: '90px',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 18 && col?.currentCel?.cel < 20) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '4',
        valueKey: climatesLastYear.toString().concat('.4'),
        field: '4',
        name: 'Abr/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesLastYear.toString().concat('.4.amount'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.4.amount'),
            field: '4',
            name: 'Qtde',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 20 && col?.currentCel?.cel < 22) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesLastYear.toString().concat('.4.weight'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.4.weight'),
            field: '4',
            name: 'Preço(R$)',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 20 && col?.currentCel?.cel < 22) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '',
        valueKey: climatesLastYear.toString().concat('.5'),
        field: '5',
        name: 'Mai/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesLastYear.toString().concat('.5.amount'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.5.amount'),
            field: '5',
            name: 'Qtde',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 22 && col?.currentCel?.cel < 24) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesLastYear.toString().concat('.5.weight'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.5.weight'),
            field: '5',
            name: 'Preço(R$)',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 22 && col?.currentCel?.cel < 24) {
                return false;
              }
              return true;
            },
          },
        ],
      },
      {
        key: '6',
        valueKey: climatesLastYear.toString().concat('.6'),
        field: '6',
        name: 'Jun/'.concat(climatesLastYear.toString().substring(2, 4)),
        textAlign: 'Center',
        // returnFormat: valueFormatter,
        validationHeader: {validationFindData},
        editType: 'numericedit',
        subFields: [
          {
            key: climatesLastYear.toString().concat('.6.amount'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.6.amount'),
            field: '6',
            name: 'Qtde',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'numericedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 24 && col?.currentCel?.cel < 26) {
                return false;
              }
              return true;
            },
          },
          {
            key: climatesLastYear.toString().concat('.6.weight'),
            disableFilter: true,
            valueKey: climatesLastYear.toString().concat('.6.weight'),
            field: '6',
            name: 'Preço(R$)',
            width: '90px',
            textAlign: 'Center',
            customKey: 'valueKey',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 24 && col?.currentCel?.cel < 26) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    ],
    name: climatesLastYear,
    textAlign: 'Center',
  },
];

export default columns;
