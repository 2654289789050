import React, {useContext, useRef, useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import * as Yup from 'yup';
import {DataContext} from '../../../utils/DataProvider';
import './index.scss';
import {history, normalizeCollectionData, usePrevious} from '../../../utils/utils';
import {FILTER, ROLES} from '../../../utils/constants';
import {useInitialRenderEffect} from '../../../utils/functions';
import {fetchCustomersForCombo, fetchCustomers} from '../../../services/customers';
import {fetchHarvestsFilter} from '../../../services/harvests';
import {fetchFarmsDetailsbyCustomerIdAndByHarvestId} from '../../../services/farmsDetails';
import GenerateForm from '../../GenerateForm';
import Alert from '../../Notification';

const PrimaryFilterForm = ({location}) => {
  const ref = useRef(null);
  const referrer = location.state ? location.state.referrer : null;
  const [slideOut, setSlideOut] = useState(false);
  const {state, dispatch} = useContext(DataContext);
  const [customers, setCustomers] = useState([]);
  const [harvests, setHarvests] = useState([]);
  const [farms, setFarms] = useState([]);
  const [hasFarms, setHasFarms] = useState(true);
  const [dataForm, setDataForm] = useState({});
  const prevDataForm = usePrevious(dataForm);
  const [disableFields, setDisableFields] = useState({});
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  const loadCustomers = async () => {
    try {
      if (state.user.user_type === ROLES.CUSTOMER) {
        const {data} = await fetchCustomers({
          'wherein[user.id]': state.user.id,
          'order[name]': 'ASC',
          'whereinmatch[profile]': ENVS,
        });
        setCustomers(data.resultList || []);
      } else {
        const {data} = await fetchCustomersForCombo(null, {
          'order[name]': 'ASC',
          ...(ENVS === 'BEEF_CATTLE' && {has_diagnosis: history?.location?.state?.referrer?.indexOf('diagnostico') > -1}),
          'whereinmatch[profile]': ENVS,
        });

        const result = data.resultList;

        setCustomers(result || []);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const loadHarvests = async (params) => {
    try {
      const {data} = await fetchHarvestsFilter(params);
      setHarvests(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadFarms = async (customerId, harvestId, params = {}) => {
    try {
      params = {...params, combo: true};
      const {data} = await fetchFarmsDetailsbyCustomerIdAndByHarvestId(customerId, harvestId, {
        environment: ENVS,
        ...params,
        ...(ENVS === 'BEEF_CATTLE' &&
          state?.user?.user_type !== 'CUSTOMER' && {
            'wherecustom[onlyDiagnosis:null]': `${history?.location?.state?.referrer?.indexOf('diagnostico') > -1 || dataForm.customer.has_diagnosis}`,
          }),
      });
      const farmsDetail = data.resultList
        .filter((farmDetail) => farmDetail.name || farmDetail.farm.name)
        .map((farmDetail) => ({...farmDetail.farm, only_diagnosis: farmDetail.only_diagnosis}));

      setFarms(farmsDetail);

      if (!farmsDetail.length && state.user.user_type === ROLES.CUSTOMER_DIAGNOSIS) {
        history.push('/initial-form');
      }
      if (farmsDetail.length) {
        setHasFarms(true);
      } else {
        setHasFarms(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkDisableFields = () => {
    setDisableFields({
      ...disableFields,
      ...{
        customer: false,
        harvest: !harvests || !harvests.length || !dataForm.customer,
        farm: !farms || !farms.length || !dataForm.harvest,
      },
    });
  };

  useInitialRenderEffect(() => {
    let finalObject = {};
    if (state.customer && state.customer.id) {
      finalObject = {
        customer: {label: state.customer.name, value: state.customer.id},
      };

      if (state.harvest && state.harvest.id) {
        finalObject = {
          ...finalObject,
          ...{harvest: {label: state.harvest.title, value: state.harvest.id}},
        };

        if (state.farm && state.farm.id) {
          finalObject = {
            ...finalObject,
            ...{farm: {label: state.farm.name, value: state.farm.id}},
          };
        }
      }
    }

    setDataForm(finalObject);
    loadCustomers();
  });

  useEffect(() => {
    if (dataForm.customer) {
      if (
        (!Object.keys(prevDataForm).length && !prevDataForm.customer) ||
        (!!prevDataForm && !!prevDataForm.customer && dataForm.customer.value !== prevDataForm.customer.value)
      ) {
        loadHarvests({
          customer_id: dataForm.customer.value,
          ...(ENVS === 'BEEF_CATTLE' &&
            state?.user?.user_type !== 'CUSTOMER' && {
              only_diagnosis: history?.location?.state?.referrer?.indexOf('diagnostico') > -1 || dataForm.customer.has_diagnosis,
            }),
        });
      }
    } else {
      setHarvests([]);
    }
    if (dataForm.harvest) {
      if (!prevDataForm.harvest || (!!prevDataForm && !!prevDataForm.harvest && dataForm.harvest.value !== prevDataForm.harvest.value)) {
        loadFarms(dataForm.customer.value, dataForm.harvest.value);
      }
    } else {
      setFarms([]);
    }
    checkDisableFields();
  }, [JSON.stringify(dataForm)]);// eslint-disable-line

  useEffect(() => {
    let newDataForm = dataForm;
    if (customers && customers.length && customers.length === 1) {
      newDataForm = {
        ...newDataForm,
        ...{customer: {label: customers[0].name, value: customers[0].id, has_diagnosis: customers[0].has_diagnosis}},
      };
    }
    setDataForm(newDataForm);
    checkDisableFields();
  }, [JSON.stringify(customers)]);// eslint-disable-line

  useEffect(() => {
    let newDataForm = dataForm;
    if (harvests && harvests.length && harvests.length === 1) {
      newDataForm = {
        ...newDataForm,
        ...{harvest: {label: harvests[0].title, value: harvests[0].id}},
      };
    }
    setDataForm(newDataForm);
    checkDisableFields();
  }, [JSON.stringify(harvests)]);// eslint-disable-line
  useEffect(() => {
    let newDataForm = dataForm;
    if (farms && farms.length && farms.length === 1) {
      newDataForm = {
        ...newDataForm,
        ...{farm: {label: farms[0].name, value: farms[0].id, only_diagnosis: farms[0]?.only_diagnosis}},
      };
    }
    setDataForm(newDataForm);
    checkDisableFields();
  }, [JSON.stringify(farms)]);// eslint-disable-line

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Cliente',
          name: 'customer',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione o cliente',
          value: dataForm.customer,
          currentValue: dataForm.customer,
          required: true,
          disabled: disableFields.customer,
          validations: {
            customer: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(customers, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (data) => {
            if (data) {
              setDataForm({
                ...dataForm,
                ...{customer: {...data, has_diagnosis: customers?.find((item) => item?.id === data?.value)?.has_diagnosis}, harvest: '', farm: ''},
              });
              setDisableFields({
                customer: false,
                harvest: false,
                farm: !dataForm.harvest,
              });
            } else {
              setDataForm({
                ...dataForm,
                ...{customer: {...data, has_diagnosis: customers?.find((item) => item?.id === data?.value)?.has_diagnosis}, harvest: '', farm: ''},
              });
              setDisableFields({
                customer: false,
                harvest: true,
                farm: !dataForm.harvest,
              });
            }
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Safra',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a safra',
          value: dataForm.harvest,
          currentValue: dataForm.harvest,
          required: true,
          disabled: disableFields.harvest,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            if (data) {
              setDataForm({...dataForm, ...{harvest: data}});
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm({...dataForm, ...{harvest: ''}});
              setDisableFields({
                customer: false,
                harvest: false,
                farm: true,
              });
            }
          },
          options: normalizeCollectionData(harvests, [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
        {
          hideField: !hasFarms && dataForm.harvest,
          label: 'Fazenda',
          name: 'farm',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a fazenda',
          value: dataForm.farm,
          currentValue: dataForm.farm,
          required: hasFarms,
          disabled: !hasFarms || !dataForm.customer || !dataForm.harvest,
          validations: {
            farm: hasFarms ? Yup.string().required('Campo obrigatório') : Yup.string(),
          },
          onChange: (data) => {
            if (data) {
              setDataForm({...dataForm, ...{farm: data}});
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm({...dataForm, ...{farm: ''}});
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            }
          },

          options: normalizeCollectionData(farms, [
            {alias: 'only_diagnosis', key: 'only_diagnosis'},
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const onSubmit = async (values, actions) => {
    Object.keys(values).map((item) => {
      if (values[item] && values[item].value) {
        return Object.assign(values, {[item]: values[item].value});
      }
      return true;
    });

    try {
      actions.setSubmitting(true);
      const customerFound = customers.filter((customer) => customer.id === values.customer) || [];
      const harvestFound = harvests.filter((harvest) => harvest.id === values.harvest) || [];
      const farmFound =
        farms.find((farm) => (farm.id === dataForm && dataForm.farm && dataForm.farm.value ? dataForm.farm.value : null)) ||
        farms.filter((farm) => farm.id === values.farm) ||
        [];

      actions.setSubmitting(false);
      setSlideOut((prev) => !prev);
      dispatch({
        type: FILTER.ACTION.SAVE_DATA,
        payload: {
          ...state,
          customer: customerFound[0] || {},
          harvest: harvestFound[0] || {},
          farm: {
            ...farmFound[0],
            ...(ENVS === 'BEEF_CATTLE' && {
              diagnoses: history?.location?.state?.referrer?.indexOf('diagnostico') > -1,
              only_diagnosis: dataForm.farm.only_diagnosis,
            }),
          } || {
            name: 'Nenhuma fazenda caracterizada para essa safra.',
            id: null,
            only_diagnosis: dataForm.farm.only_diagnosis,
          },
        },
      });
      if (referrer) {
        history.push(referrer);
      } else {
        history.push('/');
      }
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      {!hasFarms && dataForm.harvest && (
        <Alert key={`${dataForm.customer}`} type="warning" message="Nenhuma fazenda caracterizada para essa safra." time={4000} />
      )}

      <Card className={`row filter-container ${slideOut ? 'slideOut' : ''}`} style={{transform: 'translateX(0)', marginLeft: '265px'}} ref={ref}>
        <div className="col-4 offset-3 filter-content">
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields}
            showBackButton={referrer && state.filterOpened && state.filterFilled}
            onBack={() => {
              dispatch({
                type: FILTER.ACTION.CLOSE,
              });
              if (referrer) {
                history.push(referrer);
              } else {
                history.push('/');
              }
            }}
          />
        </div>
      </Card>
    </>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

export default PrimaryFilterForm;
