import React from 'react';
import styled from 'styled-components';

import {CircularProgress} from '@material-ui/core';

const Loading = (props) => {
  return (
    <Box>
      <CircularProgress color="inherit" size={30} {...props} />
    </Box>
  );
};

export default Loading;

const Box = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
