import api from '../../../../../services/api';
import {CategoryServices} from '../../../../../services/_dairyServices/category-services';
import {DairyHelpers} from '../../../utils/dairyHelpers';
import {CategoryCommercialFeedService} from './CategoryCommercialFeedService';
import {CategoryMainBulkUsedService} from './CategoryMainBulkUsedService';
import {CategoryCoreUsedService} from './CategoryCoreUsedService';
import {CategoryProductiveManagement} from './CategoryProductiveManagement';
import {FILTER} from '../../../../../utils/constants';

const PARAMS = {
  environment: 'DAIRY_CATTLE',
};

export const FarmDetailService = {
  // TODO voltar
  endpoint: '/farms_details',

  list: async (params = {}) => {
    try {
      const {data} = await api.get(FarmDetailService.endpoint, {
        params: {
          ...params,
          ...PARAMS,
          ...DairyHelpers.defaultParamsOnList(),
          perPage: 100,
          page: 0,
        },
      });
      return {
        list: data?.resultList || [],
        total: data?.size || 0,
      };
    } catch (_) {
      return {
        list: [],
        total: 0,
      };
    }
  },

  listById: async (id) => {
    const farms = await FarmDetailService.list();
    const currentFarm = farms.list.find((farm) => farm.id === id);

    return {
      list: farms.list,
      total: farms.total,
      current: currentFarm,
    };
  },

  put: async (id, body = {}) => {
    return api.put(FarmDetailService.endpoint.concat('/', id), body);
  },

  post: async (body = {}) => {
    return api.post(FarmDetailService.endpoint, body);
  },

  save: async (body = {}, state, dispatch, history) => {
    const {
      category_commercial_feed,
      category_main_bulk_used_winter,
      category_main_bulk_used_summer,
      category_core_used_batch_post_birth,
      category_core_used_batch_high_production_cow,
      category_core_used_batch_medium_production_cow,
      category_core_used_batch_low_production_cow,
      category_core_used_batch_pre_birth,
      category_productive_management,
      category_ilp_percentage,
      ...rest
    } = body;
    let {id} = rest;

    const data = FarmDetailService.prepare(rest);

    if (id) {
      await FarmDetailService.put(id, data);
    } else {
      const response = await FarmDetailService.post(data);
      id = response.data.id;
      dispatch({
        type: FILTER.ACTION.SAVE_DATA,
        payload: {
          ...state,
          farm: {
            ...response?.data?.farm,
            name: body?.farm?.title,
            ...(state.environment !== 'dairy' && {diagnoses: history?.location?.pathname?.indexOf('diagnostico') > -1}),
          } || {
            name: 'Nenhuma fazenda caracterizada para essa safra.',
            id: null,
          },
        },
      });
    }

    return FarmDetailService.saveCategories(id, {
      category_commercial_feed,
      category_main_bulk_used_winter,
      category_main_bulk_used_summer,
      category_core_used_batch_post_birth,
      category_core_used_batch_high_production_cow,
      category_core_used_batch_medium_production_cow,
      category_core_used_batch_low_production_cow,
      category_core_used_batch_pre_birth,
      category_productive_management,
    });
  },

  delete: async (id) => {
    return api.delete(FarmDetailService.endpoint.concat('/', id));
  },

  toObjId: (data) => {
    return data ? {id: data.id} : null;
  },

  toTrueOrFalse: (data) => {
    if (!data) return null;
    const {id = null} = data;
    return id;
  },

  prepare: (body) => {
    const data = {
      ...PARAMS,
      farm: FarmDetailService.toObjId(body.farm),
      name: body.name,
      total_area: DairyHelpers.NumberSanitize(body.total_area),
      first_birth_age: DairyHelpers.NumberSanitize(body.first_birth_age),
      birth_interval: DairyHelpers.NumberSanitize(body.birth_interval),
      weaning_age: DairyHelpers.NumberSanitize(body.weaning_age),
      weaning_weight: DairyHelpers.NumberSanitize(body.weaning_weight),

      use_bst: FarmDetailService.toTrueOrFalse(body.use_bst),
      use_oxytocin_milking: FarmDetailService.toTrueOrFalse(body.use_oxytocin_milking),

      state: FarmDetailService.toObjId(body.state),
      category_tortuga_customer: FarmDetailService.toObjId(body.category_tortuga_customer),
      category_rearing: FarmDetailService.toObjId(body.category_rearing),
      category_predominant_race: FarmDetailService.toObjId(body.category_predominant_race),
      category_milking: FarmDetailService.toObjId(body.category_milking),
      category_bulky: FarmDetailService.toObjId(body.category_bulky),
      category_bulky_production: FarmDetailService.toObjId(body.category_bulky_production),
      category_feeding: FarmDetailService.toObjId(body.category_feeding),
      category_birth_ecc: FarmDetailService.toObjId(body.category_birth_ecc),
      category_production_system: FarmDetailService.toObjId(body.category_production_system),

      gips: FarmDetailService.toTrueOrFalse(body.gips || null),
      native_cultivated_pasture: DairyHelpers.NumberSanitize(body.native_cultivated_pasture),
      environmental_protocols: FarmDetailService.toTrueOrFalse(body.environmental_protocols || null),
      use_nutrition_technology: FarmDetailService.toTrueOrFalse(body.use_nutrition_technology || null),
      category_ilp_percentage: FarmDetailService.toObjId(body.category_ilp_percentage),
      fire_brigade: FarmDetailService.toTrueOrFalse(body.fire_brigade || null),
      legal_reserve: DairyHelpers.NumberSanitize(body.legal_reserve),
    };

    return {
      ...DairyHelpers.defaultValues(),
      ...data,
    };
  },

  saveCategories: async (id, body) => {
    if (!id) return {};

    const {
      category_commercial_feed,

      category_main_bulk_used_winter,
      category_main_bulk_used_summer,

      category_core_used_batch_post_birth,
      category_core_used_batch_high_production_cow,
      category_core_used_batch_medium_production_cow,
      category_core_used_batch_low_production_cow,
      category_core_used_batch_pre_birth,

      category_productive_management,
    } = body;

    await CategoryCommercialFeedService.save(id, category_commercial_feed);

    await CategoryProductiveManagement.save(id, category_productive_management);

    await CategoryMainBulkUsedService.save(id, {
      summer: category_main_bulk_used_summer,
      winter: category_main_bulk_used_winter,
    });

    await CategoryCoreUsedService.save(id, {
      batch_post_birth: category_core_used_batch_post_birth,
      batch_high_production_cow: category_core_used_batch_high_production_cow,
      batch_medium_production_cow: category_core_used_batch_medium_production_cow,
      batch_low_production_cow: category_core_used_batch_low_production_cow,
      batch_pre_birth: category_core_used_batch_pre_birth,
    });

    return FarmDetailService.listById(id);
  },

  categories: async () => {
    const urls = {
      farms: `/farms?wherein[customer.id]=${DairyHelpers.defaultValues().customer.id}`,
      states: '/states?wherein[region.id]=1|2|3|4|5&order[title]=asc',
      category_commercial_feed: '/category_commercial_feed',
      category_production_system: '/category_production_system',
      category_bulky: '/category_bulky',
      category_tortuga_customer: '/category_tortuga_customer',
      category_bulky_production: '/category_bulky_production',
      category_feeding: '/category_feeding',
      category_birth_ecc: '/category_birth_ecc',
      category_predominant_race: '/category_predominant_race',
      category_productive_management: '/category_productive_management',
      category_milking: '/category_milking',
      category_main_bulk_used: '/category_main_bulk_used',
      category_core_used: '/category_core_used',
      category_rearing: '/category_rearing',
      category_team_type: '/category_team_type',
      category_ilp_percentage: `/category_ilp_percentage?order[id]=DESC`,
    };

    const cats = await Promise.allSettled(
      Object.keys(urls).map(async (url) => {
        CategoryServices.endpoint = urls[url];
        return CategoryServices.list();
      }),
    );

    const results = await cats?.map((cat) => (cat.status === 'fulfilled' ? cat.value?.data?.resultList || [] : []));

    const categories = {};
    Object.keys(urls).forEach((key, index) => {
      Object.assign(categories, {
        [key]: results[index],
      });
    });

    return categories;
  },
};
