import React, {useEffect} from 'react';
import colorLib from '@kurkle/color';

const QuadrantChart = ({xAxe, yAxe, selectedFarm, xAxisAverage, yAxisAverage, quadrantData}) => {
  let xAxeLegend = '';
  let yAxeLegend = '';

  switch (yAxe) {
    case 0:
      yAxeLegend = 'Vacas em Lactação / Rebanho (%)';
      break;
    case 1:
      yAxeLegend = 'Gasto com volumoso na atividade / RB da atividade (%)';
      break;
    case 2:
      yAxeLegend = 'Gasto com concentrado na atividade / RB da atividade (%)';
      break;
    case 3:
      yAxeLegend = 'RMCA';
      break;
    case 4:
      yAxeLegend = 'DMCA';
      break;
    default:
      yAxeLegend = '';
  }

  switch (xAxe) {
    case 0:
      xAxeLegend = 'Margem Bruta / Área (R$/ha)';
      break;
    case 1:
      xAxeLegend = 'Margem Bruta / Vacas em lactação (R$/Cab)';
      break;
    case 2:
      xAxeLegend = 'Margem Bruta unitária (R$/L)';
      break;
    default:
      xAxeLegend = '';
  }

  const CHART_COLORS = {
    red: 'rgb(255,69,0)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(25,25,112)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',
  };

  function transparentize(value, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
  }

  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: {left, top, right, bottom},
      } = chart;

      // essas constantes serão baseadas no valor que irá ser busscado do back
      const xAxeAverage = xAxisAverage; // MEDIA x
      const yAxeAverage = yAxisAverage; // MEDIA y
      const rangeX = chart.boxes.find((el) => el.id === 'x-axis-1');
      const rangeY = chart.boxes.find((el) => el.id === 'y-axis-1');
      const widthLine = 3;

      const midX = rangeX._startPixel + (rangeX._length / (rangeX.max - rangeX.min)) * xAxeAverage;
      const midY = rangeY._endPixel - (rangeY._length / (rangeY.max - rangeY.min)) * yAxeAverage;
      ctx.save();
      ctx.fillStyle = options.topLeft;
      // X, Y, Width, height
      ctx.fillRect(left, midY, midX - left, widthLine);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(midX, top, widthLine, midY - top);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, widthLine);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(midX, midY, widthLine, bottom - midY);
      ctx.restore();
    },
  };

  const data = {
    datasets: [
      {
        // others farms
        label: 'FAZENDAS DA MESMA SAFRA',
        data: quadrantData,
        backgroundColor: quadrantData.map((value) =>
          value.farm_id === selectedFarm.farm_id ? transparentize(CHART_COLORS.red, 0) : transparentize(CHART_COLORS.blue, 0),
        ),
        // backgroundColor: transparentize(CHART_COLORS.blue, 0),
        pointRadius: 7,
        datalabels: {
          display: false,
          // align: 'end',
          // anchor: 'start',
          // color(context) {
          //   return context.dataset.backgroundColor;
          // },
          // font(context) {
          //   const w = context.chart.width;
          //   return {
          //     size: w < 512 ? 12 : 14,
          //     weight: 'bold',
          //   };
          // },
          // formatter(value, context) {
          //   console.log(value);
          //   return value.farm_name;
          // },
        },
      },

      // {
      //   // selected farm￼
      //   label: 'Fazenda selecionada',
      //   data: [{
      //     x: selectedFarm.x,
      //     y: selectedFarm.y
      //    }],
      //   backgroundColor: transparentize(CHART_COLORS.red, 0),
      //   pointRadius: 7,
      //   datalabels: {
      //     display: false,
      //   },
      // },
    ],
  };

  const config = {
    type: 'scatter',
    data,
    options: {
      scales: {
        yAxes: [
          {
            scaleLabel: {
              fontSize: 15,
              display: true,
              labelString: yAxeLegend,
            },
            display: true,
            ticks: {
              suggestedMin: 0, // minimum will be 0, unless there is a lower value.
              // OR //
              beginAtZero: true, // minimum value will be 0.
              callback: (value) => {
                return `${value * 100} %`;
              },
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              fontSize: 15,
              display: true,
              labelString: xAxeLegend,
            },
            display: true,
            ticks: {
              suggestedMin: 0, // minimum will be 0, unless there is a lower value.
              // OR //
              beginAtZero: true, // minimum value will be 0.
            },
          },
        ],
      },

      tooltips: {
        callbacks: {
          title: (tooltipItem, callback) => {
            const row = callback.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0]?.index];
            return `Fazenda: ${row?.farm_name}`;
          },
          // label(tooltipItem, callback) {
          //   const row = data.datasets[tooltipItem.datasetIndex].data[tooltipItem?.index];
          //   console.log(tooltipItem, callback);
          //   return `Fazenda:${row?.farm_name}: ${row.x}:${row.y}`;
          // },
        },
      },
      plugins: {
        quadrants: {
          topLeft: CHART_COLORS.blue,
          topRight: CHART_COLORS.blue,
          bottomRight: CHART_COLORS.blue,
          bottomLeft: CHART_COLORS.blue,
        },
      },
    },
    plugins: [quadrants],
  };
  useEffect(() => {
    const ctx = document.getElementById('myChart').getContext('2d');
    /* eslint-disable no-unused-vars */
    const myChart = new window.Chart(ctx, config);
  }, []);// eslint-disable-line

  return (
    <div style={{padding: 40}}>
      <canvas id="myChart" />
    </div>
  );
};
export default QuadrantChart;
