import apiNew from '../apiNew';

const fetchCategoryBiomes = (params = {}) => {
  return apiNew.get(`/category_biomes`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryBiomesbyId = (id, params = {}) => {
  return apiNew.get(`/category_biomes/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryBiomes = (body = {}) => {
  return apiNew.post(`/category_biomes`, body);
};

const changeCategoryBiomesbyId = (id, body = {}) => {
  return apiNew.put(`/category_biomes/${id}`, body);
};

const deleteCategoryBiomes = (id) => {
  return apiNew.delete(`/category_biomes/${id}`);
};

export {fetchCategoryBiomes, fetchCategoryBiomesbyId, registerCategoryBiomes, changeCategoryBiomesbyId, deleteCategoryBiomes};
