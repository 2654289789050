import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateData} from '../../utils/utils';
import {
  fetchHarvestsDetails,
  registerHarvestsDetails,
  changeHarvestsDetailsbyId,
  deleteHarvestsDetails,
} from '../../services/harvestsDetails';
import {fetchHarvests, fetchHarvestsbyId} from '../../services/harvests';
import {fetchGroupsHarvests} from '../../services/groupsHarvests';
import {fetchCustomers, fetchCustomersbyId} from '../../services/customers';
import Breadcrumbs from '../../components/Breadcrumbs';

const HarvestsDetails = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const farmsLimitDefault = '0';
  const startMonthDefault = 7;
  const endMonthDefault = 6;

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchHarvestsDetails({
        ...paginationParams,
        ...filterParams,
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);

  const columns = [
    {
      key: 'harvest',
      valueKey: 'harvest.title',
      field: 'harvest',
      name: 'Safra',
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchHarvests();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'customer',
      valueKey: 'customer.name',
      field: 'customer',
      name: 'Código do cliente',
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCustomers();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.name,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'farms_quantity',
      field: 'farms_quantity',
      name: 'Quantidade de fazendas',
      textAlign: 'Center',
      disabledEdit: true,
      defaultValue: farmsLimitDefault,
      editType: 'numericedit',
    },
    {
      key: 'start_month',
      field: 'start_month',
      name: 'Início do mês da safra',
      textAlign: 'Center',
      disabledEdit: true,
      defaultValue: startMonthDefault,
      editType: 'numericedit',
    },
    {
      key: 'end_month',
      field: 'end_month',
      name: 'Fim do mês da safra',
      textAlign: 'Center',
      disabledEdit: true,
      defaultValue: endMonthDefault,
      editType: 'numericedit',
    },
    {
      key: 'group_harvest',
      valueKey: 'group_harvest.title',
      field: 'group_harvest',
      name: 'Grupo de safra',
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchGroupsHarvests();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteHarvestsDetails(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  const saveData = async (dataGridInputs) => {
    try {
      const currentCustomer = await fetchCustomersbyId(
        dataGridInputs.customer.id,
      );

      const harvestTitle = await fetchHarvestsbyId(dataGridInputs.harvest.id);

      Object.assign(dataGridInputs, {
        title: harvestTitle.data.title,
        farms_quantity: currentCustomer.data.farms_limit,
        start_month: startMonthDefault,
        end_month: endMonthDefault,
      });

      const response = await createOrUpdateData(
        dataGridInputs,
        state,
        registerHarvestsDetails,
        changeHarvestsDetailsbyId,
        false,
      );
      setReset(!reset);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <>
      <Breadcrumbs
        path={[
          {title: 'Cadastros', url: '/cadastros'},
          {title: 'Caracteriza Safra'},
        ]}
      />
      {data && (
        <DataGrid
          onChange={saveData}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default HarvestsDetails;
