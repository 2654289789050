import React from 'react';
import Indicator from '../DataPresenters/Indicator';
import Table from '../DataPresenters/Table';
import {maskInput} from '../../../utils/utils';
import {MASKS} from '../../../utils/constants';

const DashboardClimate = ({precipitation, table}) => {
  return (
    <div>
      {precipitation && (
        <Indicator
          icon="cloud"
          iconSize={4}
          color="#00005c"
          iconColor="#fff"
          iconBackgroundColor="#00005c"
          data={`${maskInput({
            type: MASKS.DOUBLE,
            value: `${Math.round(precipitation)}`,
          })}`}
          title="Precipitação"
          subtitle="Acumulado da Safra"
        />
      )}
      {table && (
        <Table
          title="Precipitação - Mês"
          columns={[
            {key: 'label', name: 'Mês/Ano'},
            {key: 'data', name: 'Precipitação'},
          ]}
          rows={table}
          gridTemplate="repeat(2, 1fr)"
        />
      )}
    </div>
  );
};

export default DashboardClimate;
