import api from '../api';

const fetchCashAnalysis = (params = {}) => {
  return api.get(`/cash_analysis`, {
    params: {
      ...params,
    },
  });
};

const fetchCashAnalysisDatagrid = (params = {}) => {
  return api.get(`/cash_analysis/datagrid`, {
    params: {
      ...params,
    },
  });
};

const fetchCashAnalysisbyId = (id, params = {}) => {
  return api.get(`/cash_analysis/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeCashAnalysisbyId = (body = {}) => {
  return api.put(`/cash_analysis`, body);
};

const registerCashAnalysis = (body = {}) => {
  return api.post(`/cash_analysis`, body);
};

const deleteCashAnalysis = (body = {}) => {
  return api.post(`/cash_analysis`, body);
};

export {
  fetchCashAnalysis,
  fetchCashAnalysisDatagrid,
  fetchCashAnalysisbyId,
  changeCashAnalysisbyId,
  registerCashAnalysis,
  deleteCashAnalysis,
};
