import apiNew from '../../../services/apiNew';
import {CategoryServices} from '../../../services/_dairyServices/category-services';
import {FILTER} from '../../../utils/constants';
import {BeefHelpers} from '../utils/beefHelpers';

const PARAMS = {
  environment: 'BEEF_CATTLE',
};

export const FarmDetailService = {
  endpoint: '/farms_details',

  list: async (params = {}) => {
    try {
      const {data} = await apiNew.get(FarmDetailService.endpoint, {
        params: {
          ...params,
          ...PARAMS,
          ...BeefHelpers.defaultParamsOnList(),
          perPage: 100,
          page: 0,
        },
      });

      return {
        list: data?.resultList || [],
        total: data?.size || 0,
      };
    } catch (_) {
      return {
        list: [],
        total: 0,
      };
    }
  },

  listById: async (id) => {
    const farms = await FarmDetailService.list();
    const currentFarm = farms.list.find((farm) => farm.id === id);

    return {
      list: farms.list,
      total: farms.total,
      current: currentFarm,
    };
  },

  put: async (id, body = {}) => {
    return apiNew.put(FarmDetailService.endpoint.concat('/', id), body);
  },

  post: async (body = {}) => {
    return apiNew.post(FarmDetailService.endpoint, body);
  },

  save: async (body = {}, state, dispatch, history) => {
    // let {id} = rest;
    const data = FarmDetailService.prepare(body);

    if (body.id) {
      await FarmDetailService.put(body.id, data);
    } else {
      const response = await FarmDetailService.post({...data, only_diagnosis: history?.location?.pathname?.indexOf('diagnostico') > -1});
      dispatch({
        type: FILTER.ACTION.SAVE_DATA,
        payload: {
          ...state,
          farm: {
            ...response?.data?.farm,
            name: body?.farm?.title,
            ...(state.environment !== 'dairy' && {diagnoses: history?.location?.pathname?.indexOf('diagnostico') > -1}),
          } || {
            name: 'Nenhuma fazenda caracterizada para essa safra.',
            id: null,
          },
        },
      });
    }
  },

  delete: async (id) => {
    return apiNew.delete(FarmDetailService.endpoint.concat('/', id));
  },

  toObjId: (data) => {
    return data.id ? {id: data.id} : null;
  },

  toTrueOrFalse: (data) => {
    if (!data) return null;
    const {id = null} = data;
    return id;
  },

  prepare: (body) => {
    try {
      const data = {
        ...PARAMS,
        native_cultivated_pasture: BeefHelpers.NumberSanitize(body.native_cultivated_pasture),
        legal_reserve: BeefHelpers.NumberSanitize(body.legal_reserve),
        gips: FarmDetailService.toTrueOrFalse(body.gips || null),
        fire_brigade: FarmDetailService.toTrueOrFalse(body.fire_brigade || null),
        use_creep_feeding: FarmDetailService.toTrueOrFalse(body.use_creep_feeding || null),
        use_fobosvi_reproduction: FarmDetailService.toTrueOrFalse(body.use_fobosvi_reproduction || null),
        use_nutrition_technology: FarmDetailService.toTrueOrFalse(body.use_nutrition_technology || null),
        use_supplementation_tortuga: FarmDetailService.toTrueOrFalse(body.use_supplementation_tortuga || null),
        use_tm_tortuga: FarmDetailService.toTrueOrFalse(body.use_tm_tortuga || null),
        only_confinement: FarmDetailService.toTrueOrFalse(body.only_confinement || null),
        farms_quantity: BeefHelpers.NumberSanitize(body.farms_quantity),
        environmental_protocols: FarmDetailService.toTrueOrFalse(body.environmental_protocols || null),
        state: FarmDetailService.toObjId(body.state),
        farm: FarmDetailService.toObjId(body.farm),
        name: body?.farm?.title || '',
        region: FarmDetailService.toObjId(body.region),
        category_ilp_percentage: FarmDetailService.toObjId(body.category_ilp_percentage),
        country: FarmDetailService.toObjId(body.country),
        city: FarmDetailService.toObjId(body.city),
        category_type: FarmDetailService.toObjId(body.category_type),
        category_processing_format: FarmDetailService.toObjId(body.category_processing_format),
        category_own_farming: FarmDetailService.toObjId(body.category_own_farming),
        category_livestock_system: FarmDetailService.toObjId(body.category_livestock_system),
        category_confinement: FarmDetailService.toObjId(body.category_confinement),
        category_biome: FarmDetailService.toObjId(body.category_biome),
        land_value: parseFloat(`${body.land_value}`.replace('R$ ', '').replace(/\./g, '').replace(',', '.')),
        total_area: parseFloat(`${body.total_area}`.replace(/\./g, '').replace(',', '.')),
      };

      return {
        ...BeefHelpers.defaultValues(),
        ...data,
      };
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  categoriesParams: async (endpoint, params) => {
    const {data} = await apiNew.get(endpoint, {
      params: {
        ...params,
      },
    });
    return data.resultList;
  },

  categories: async ({custometId}) => {
    const urls = {
      states: '/states?wherein[region.id]=1|2|3|4|5&order[title]=asc',
      category_processing_format: '/category_processing_formats',
      category_own_farming: '/category_own_farming',
      category_livestock_system: '/category_livestock_system',
      category_type: '/category_type',
      category_confinement: '/category_confinement',
      category_ilp: '/category_ilp',
      category_biome: '/category_biomes',
      country: '/countries',
      region: '/regions',
      state: '/states',
      farms: `/farms?wherein[customer.id]=${custometId}`,
      category_ilp_percentage: `/category_ilp_percentage?order[id]=DESC`,
    };

    const cats = await Promise.allSettled(
      Object.keys(urls).map(async (url) => {
        CategoryServices.endpoint = urls[url];
        return CategoryServices.list();
      }),
    );

    const results = await cats?.map((cat) => (cat.status === 'fulfilled' ? cat.value?.data?.resultList || [] : []));

    const categories = {};
    Object.keys(urls).forEach((key, index) => {
      Object.assign(categories, {
        [key]: results[index],
      });
    });

    return categories;
  },
};
