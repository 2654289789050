import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {getValueObject, createOrUpdateData} from '../../utils/utils';
import {
  fetchIndicatorsPlannedDatagrid,
  changeIndicatorsPlannedbyId,
  registerIndicatorsPlanned,
  deleteIndicatorsPlanned,
} from '../../services/indicatorsPlanned';
import PrimaryFilterDisplay from '../../components/PrimaryFilterDisplay/PrimaryFilterDisplay';
import PrimaryFilterForm from '../../components/FilterForms/FilterSettings';
import {FILTER} from '../../utils/constants';

const IndicatorsPlanned = () => {
  const {state, dispatch, stateFetchParamsCustom} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentHarvest, setCurrentHarvest] = useState('');
  const [lastHarvest, setLastHarvest] = useState('');

  useEffect(() => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  }, []);// eslint-disable-line

  useEffect(() => {
    const loadData = async () => {
      if (!state.harvest || !state.customer || !state.farm) {
        return;
      }
      const response = await fetchIndicatorsPlannedDatagrid(
        stateFetchParamsCustom,
      );

      const normalizedData = response.data.map((item, i) => {
        item.temp_id = item.id;
        item.id = i + 1;

        const valuePlanned = {
          value: item.value_planned !== null ? item.value_planned : '',
          readonly: item.value_planned_edit || false,
        };
        item.value_planned = valuePlanned;

        const valuePreviousHarvest = {
          value:
            item.value_previous_harvest !== null
              ? item.value_previous_harvest
              : '',
          readonly: item.value_previous_harvest_edit || false,
        };
        item.value_previous_harvest = valuePreviousHarvest;

        return item;
      });
      setCurrentHarvest(state.harvest.title);
      setLastHarvest(
        `${state.harvest.first_year - 1}/${state.harvest.last_year - 1}`,
      );

      setData(normalizedData);
      setTotalItems(response.data.length);
    };

    loadData();
  }, [reset, state, stateFetchParamsCustom]);

  const columns = [
    {
      key: 'description',
      field: 'description',
      name: 'Descrição',
      disableFilter: true,
      subFields: [
        {
          key: 'group_indicator',
          valueKey: 'group_indicator.title',
          field: 'group_indicator',
          name: 'Indicador',
          textAlign: 'Center',
          editType: 'defaultedit',
          disableFilter: true,
          groupTitle: (title) => {
            return title;
          },
        },
        {
          key: 'category_indicator',
          valueKey: 'category_indicator.title',
          field: 'category_indicator',
          name: 'Categoria',
          textAlign: 'Center',
          editType: 'defaultedit',
          disabledEdit: true,
          disableFilter: true,
        },
      ],
    },
    {
      key: 'value_planned',
      valueKey: 'value_planned.value',
      field: 'value_planned',
      name: `Planejado ${currentHarvest}`,
      textAlign: 'Center',
      editType: 'numericedit',
      disabledEdit: (dataLine) => {
        return getValueObject(dataLine.data, null, 'value_planned.readonly');
      },
      disableFilter: true,
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'value_previous_harvest',
      valueKey: 'value_previous_harvest.value',
      field: 'value_previous_harvest',
      name: `Safra ${lastHarvest}`,
      textAlign: 'Center',
      editType: 'numericedit',
      disabledEdit: (dataLine) => {
        return getValueObject(
          dataLine.data,
          null,
          'value_previous_harvest.readonly',
        );
      },
      disableFilter: true,
      extraParams: {
        minValue: 1,
      },
    },
  ];

  const actions = [
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteIndicatorsPlanned(value.temp_id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {state.filterOpened && <PrimaryFilterForm />}
      {!state.filterOpened && data && (
        <>
          <PrimaryFilterDisplay />
          <DataGrid
            onChange={async (dataGridInputs) => {
              const objectData = {
                id: dataGridInputs.temp_id,
                created_by: dataGridInputs.created_by,
                category_indicator: dataGridInputs.category_indicator,
                customer: dataGridInputs.customer,
                value_planned:
                  dataGridInputs.value_planned &&
                  dataGridInputs.value_planned.id !== undefined
                    ? parseFloat(dataGridInputs.value_planned.id) // value change to id
                    : parseFloat(dataGridInputs.value_planned),
                value_previous_harvest:
                  dataGridInputs.value_previous_harvest &&
                  dataGridInputs.value_previous_harvest.id !== undefined
                    ? parseFloat(dataGridInputs.value_previous_harvest.id) // value change to id
                    : parseFloat(dataGridInputs.value_previous_harvest),
                farm: state.farm,
                harvest: state.harvest,
              };
              const response = await createOrUpdateData(
                objectData,
                state,
                registerIndicatorsPlanned,
                changeIndicatorsPlannedbyId,
              );
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
            groupBy="group_indicator"
            disabledPaginate
            maxHeight="100%"
          />
        </>
      )}
    </>
  );
};

export default IndicatorsPlanned;
