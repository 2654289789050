import api from '../api';

const fetchFarms = (params = {}) => {
  return api.get(`/farms`, {
    params: {
      ...params,
    },
  });
};

const fetchFarmbyId = (id, params = {}) => {
  return api.get(`/farms/${id}`, {
    params: {
      ...params,
    },
  });
};

const fetchFarmFilter = (params = {}) => {
  return api.get(`/farms/filter`, {
    params: {
      ...params,
    },
  });
};

const registerFarm = (body = {}) => {
  return api.post(`/farms`, body);
};

const changeFarmbyId = (id, body = {}) => {
  return api.put(`/farms/${id}`, body);
};

const deleteFarm = (id) => {
  return api.delete(`/farms/${id}`);
};

export {
  fetchFarms,
  fetchFarmbyId,
  fetchFarmFilter,
  registerFarm,
  changeFarmbyId,
  deleteFarm,
};
