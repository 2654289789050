import apiNew from '../apiNew';

const fetchCategoryRearingHerdSupplementation = (params = {}) => {
  return apiNew.get(`/category_rearing_herd_supplementation`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryRearingHerdSupplementationbyId = (id, params = {}) => {
  return apiNew.get(`/category_rearing_herd_supplementation/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryRearingHerdSupplementation = (body = {}) => {
  return apiNew.post(`/category_rearing_herd_supplementation`, body);
};

const changeCategoryRearingHerdSupplementationbyId = (id, body = {}) => {
  return apiNew.put(`/category_rearing_herd_supplementation/${id}`, body);
};

const deleteCategoryRearingHerdSupplementation = (id) => {
  return apiNew.delete(`/category_rearing_herd_supplementation/${id}`);
};

export {
  fetchCategoryRearingHerdSupplementation,
  fetchCategoryRearingHerdSupplementationbyId,
  registerCategoryRearingHerdSupplementation,
  changeCategoryRearingHerdSupplementationbyId,
  deleteCategoryRearingHerdSupplementation,
};
