import styled from 'styled-components';

export const IndicatorContainer = styled.div`
  -webkit-print-color-adjust: exact;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fff'};
  color: ${(props) => (props.color ? props.color : '#000')};
  ${(props) =>
    props.iconSide && props.iconSide === 'right'
      ? 'flex-direction: row-reverse; justify-content: space-between'
      : ''};
  ${(props) =>
    props.alignItems
      ? ` justify-content: center; text-align:${props.alignItems}`
      : ''};
  ${(props) => (props.height ? ` height:${props.height}` : 'height:auto')};
  display: flex;
  position: relative;
  width: 100%;
  .updateAt {
    bottom: 0;
    color: ${(props) => (props.color ? props.color : '#000')};
    font-size: 10px;
    font-weight: 100;
    position: absolute;
    right: 10px;
  }
`;
export const IconContainer = styled.div`
  -webkit-print-color-adjust: exact;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  color: ${(props) => (props.color ? props.color : '#000')};

  display: flex;
  justify-content: center;
  .icon {
    padding: 15px;
    padding-top: 30px;
  }
  min-width: 100px;
`;
export const DataContainer = styled.div`
  -webkit-print-color-adjust: exact;
  margin: 10px 15px;
`;
export const DataTitle = styled.div`
  -webkit-print-color-adjust: exact;
  font-size: 18px;
  font-weight: 100;
  text-transform: none;
`;
export const Data = styled.div`
  -webkit-print-color-adjust: exact;
  font-size: 2em;
  font-weight: bold;
`;
export const DataSubTitle = styled.div`
  -webkit-print-color-adjust: exact;
  font-size: 18px;
  font-weight: 100;
  text-transform: none;
`;
