import React, {useContext, useEffect, useState} from 'react';
import Switch from '@material-ui/core/Switch';
import {useHistory} from 'react-router-dom';
import {DataContext} from '../../utils/DataProvider';
import {FILTER} from '../../utils/constants';
import {getEnvironmentsTechnical} from '../../utils/helperEnv';
// import { Container } from './styles';

const SwitchEnvironment = () => {
  const {state, dispatch} = useContext(DataContext);
  const [techEnvs, setTechEnvs] = useState();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const envs = await getEnvironmentsTechnical(state?.user?.id);
      setTechEnvs(envs);
    })();
  }, []);// eslint-disable-line
  const showSwitch =
    state?.user?.profiles?.length > 1 ||
    // eslint-disable-next-line camelcase
    state?.user?.user_type === 'SUPER_ADMIN' ||
    state?.user?.user_type === 'ADMIN' ||
    (state?.user?.user_type === 'TECHNICAL' && techEnvs?.DAIRY_CATTLE && techEnvs?.BEEF_CATTLE);

  return (
    <>
      {showSwitch ? (
        <div className="switch-environment mt-3 w-100 d-flex align-items-center justify-content-center">
          <label>Corte</label>{' '}
          <Switch
            checked={state.environment === 'dairy'}
            color="default"
            onChange={(e) => {
              dispatch({
                type: FILTER.ACTION.SAVE_DATA,
                payload: {
                  ...state,
                  farm: null,
                  customer: null,
                  environment: e.target.checked ? 'dairy' : 'beef',
                },
              });
              history.push('/');
            }}
          />
          <label>Leite</label>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SwitchEnvironment;
