import api from '../api';

const fetchCategoryRacialGroup = (params = {}) => {
  return api.get(`/category_racial_group`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryRacialGroupbyId = (id, params = {}) => {
  return api.get(`/category_racial_group/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryRacialGroup = (body = {}) => {
  return api.post(`/category_racial_group`, body);
};

const changeCategoryRacialGroupbyId = (id, body = {}) => {
  return api.put(`/category_racial_group/${id}`, body);
};

const deleteCategoryRacialGroup = (id) => {
  return api.delete(`/category_racial_group/${id}`);
};

export {fetchCategoryRacialGroup, fetchCategoryRacialGroupbyId, registerCategoryRacialGroup, changeCategoryRacialGroupbyId, deleteCategoryRacialGroup};
