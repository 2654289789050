import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {createOrUpdateDataFull} from '../../../../utils/utils';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {TeamServices} from './services/TeamsService';
import {CategoryServices} from '../../../../services/_dairyServices/category-services';

const Teams = () => {
  const {state, stateFetchParams, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    (async () => {
      const {data: contentData = {}} = await TeamServices.list({
        ...stateFetchParams,
        ...paginationParams,
        ...filterParams,
      });

      setData(contentData.resultList?.map((item) => ({...item, period: item?.status === 'true'})) || []);
      setTotalItems(contentData.size || 0);
    })();
  }, [reset, JSON.stringify(paginationParams), JSON.stringify(filterParams)]);// eslint-disable-line

  const validationFindData = (dataTemp) => {
    let validated = true;
    dataTemp.forEach((row) => {
      validated = validated && row.validated;
    });

    return dataTemp.length ? validated : false;
  };

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    CategoryServices.endpoint = 'teams';
    dataGridRows.forEach((row) => {
      promises.push(createOrUpdateDataFull({...row, id: row.id, validated: checked}, state, CategoryServices.post, CategoryServices.put, false));
    });
    const response = await Promise.all(promises);
    setReset(!reset);
    return response;
  };

  const columns = [
    {
      key: 'dt_team',
      field: 'dt_team',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '15%',
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        if (line?.dt_team) {
          const [date] = line?.dt_team?.split(' ');
          const [day, month, year] = date.split('/');

          if (!editMode) {
            // eslint-disable-next-line eqeqeq
            if (line.period === true) {
              return moment(new Date(year, month - 1, day))
                .locale('pt-br')
                .format('MM/YYYY');
            }

            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('DD/MM/YYYY');
          }

          return moment(new Date(year, month - 1, day));
        }
        return null;
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'category_team_type',
      valueKey: 'category_team_type.title',
      field: 'category_team_type',
      name: 'Tipo',
      required: true,
      textAlign: 'Center',
      width: '240px',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          CategoryServices.endpoint = 'category_team_type';
          const response = await CategoryServices.list();
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
    {
      key: 'number_employees',
      field: 'number_employees',
      name: 'Número de funcionários',
      required: true,
      textAlign: 'Center',
      editType: 'numericedit',
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'salary',
      required: true,
      field: 'salary',
      name: 'Salário por pessoa (R$)',
      textAlign: 'Center',
      editType: 'moneyedit',
    },
    {
      key: 'days_worked',
      field: 'days_worked',
      name: 'Dias trabalhados (dias/mês)',
      required: true,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      textAlign: 'Center',
      width: '160px',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  const actions = [
    {title: 'Adicionar', action: 'add', icon: 'fas fa-plus'},
    {title: 'Editar', action: 'edit', icon: 'fas fa-pencil-alt'},
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await TeamServices.delete(value.id);
          setReset((prev) => !prev);
          return response;
        } catch (error) {
          return error;
        }
      },
    },
    {title: 'Salvar', action: 'save', icon: 'fas fa-save'},
    {title: 'Cancelar', action: 'cancel', icon: 'fas fa-times'},
  ];

  return (
    <DataGrid
      onChange={async (dataGridInputs) => {
        dataGridInputs.status = dataGridInputs.period;
        delete dataGridInputs.period;
        try {
          if (typeof dataGridInputs.dt_team === 'object') {
            dataGridInputs.dt_team = moment(dataGridInputs.dt_team).format('DD/MM/YYYY 00:00:00');
          }
          const response = await createOrUpdateDataFull(dataGridInputs, state, TeamServices.post, TeamServices.put, false);
          setReset(!reset);
          return response;
        } catch (error) {
          return error;
        }
      }}
      header={columns}
      data={data}
      keyProp="key"
      keyValueProp="valueKey"
      validateCallback={validateHeaderCallback}
      titleProp="name"
      editable=""
      actions={actions}
      totalItems={totalItems}
    />
  );
};

export default Teams;
