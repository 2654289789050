import apiNew from '../apiNew';

const fetchStates = (params = {}) => {
  return apiNew.get(`/states`, {
    params: {
      ...params,
    },
  });
};

const fetchStatesbyId = (id, params = {}) => {
  return apiNew.get(`/states/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerState = (body = {}) => {
  return apiNew.post(`/states`, body);
};

const changeStatebyId = (id, body = {}) => {
  return apiNew.put(`/states/${id}`, body);
};

const deleteState = (id) => {
  return apiNew.delete(`/states/${id}`);
};

export {fetchStates, fetchStatesbyId, registerState, changeStatebyId, deleteState};
