import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import TransitionsModal from '../../../components/Modal';

import {fetchUsers, fetchUserbyId, registerUser, deleteUser} from '../../../services/user';
import {fetchCustomers, changePassword} from '../../../services/customers';
import Breadcrumbs from '../../../components/Breadcrumbs';

const Technical = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [resetCustomers, setResetCustomers] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [technicalsCustomers, setTechnicalsCustomer] = useState(null);
  const [selectedTechinical, setSelectedTechinicadl] = useState(null);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';
  useEffect(() => {
    const loadData = async () => {
      const response = await fetchUsers({
        ...paginationParams,
        ...filterParams,
        ...{'wherein[userType]': "'TECHNICAL'"},
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);

  const constumerColumns = [
    {
      key: 'customer',
      valueKey: 'customer.name',
      field: 'customer',
      name: 'Nome',
      width: '100%',
      disabledFilter: true,
      disabledEdit: (dataLine) => {
        if (dataLine.data) {
          return false;
        }
        return true;
      },
      textAlign: 'Center',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCustomers({
            'whereinmatch[profile]': ENVS,
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.name,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
  ];

  const columns = [
    {
      key: 'name',
      field: 'name',
      name: 'Nome',
      width: '20%',
      textAlign: 'Center',
      editType: 'defaultedit',
    },
    {
      key: 'email',
      field: 'email',
      name: 'Email',
      width: '20%',
      textAlign: 'Center',
      editType: 'emailedit',
    },
    {
      key: 'password',
      field: 'password',
      disableFilter: true,
      name: 'Senha',
      width: '20%',
      textAlign: 'Center',
      editType: 'passwordedit',
      mapper: (object, editMode) => {
        if (!editMode) {
          return '**********';
        }

        return '';
      },
    },
    {
      key: 'global_access',
      field: 'globalAccess',
      name: 'Acesso Global?',
      width: '20%',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
    {
      key: 'technicals_customers',
      field: 'technicalsCustomers',
      name: 'Clientes',
      disableFilter: true,
      width: '20%',
      textAlign: 'Center',
      editType: 'ctabutton',
      ctabutton: {
        title: 'Clientes',
        action: (e, line) => {
          setOpen(true);
          setSelectedTechinicadl(line);
        },
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteUser(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  const actionsCustomer = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        selectedTechinical.technicals_customers = selectedTechinical.technicals_customers.filter((item) => item.customer.id !== value.customer.id);
        try {
          const response = await registerUser(selectedTechinical);
          setResetCustomers(!resetCustomers);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchUserbyId(selectedTechinical.id);
      setTechnicalsCustomer(response.data.technicals_customers);
    };
    if (selectedTechinical) {
      loadData();
    }
  }, [resetCustomers, selectedTechinical]);

  return (
    <>
      <Breadcrumbs path={[{title: 'Cadastros', url: '/cadastros'}, {title: 'Técnico'}]} />
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (dataGridInputs && !dataGridInputs.user_type) {
              dataGridInputs.user_type = 'TECHNICAL';
            }
            try {
              const res = await registerUser(dataGridInputs);

              try {
                if (dataGridInputs.id && dataGridInputs.password) {
                  const {id} = dataGridInputs;
                  delete dataGridInputs.id;
                  await changePassword(id, {
                    password: dataGridInputs.password,
                  });
                } else if (dataGridInputs.id) {
                  delete dataGridInputs.password;
                  // delete dataGridInputs.email;
                }
              } catch (error) {
                console.error('Error on save password techinical', error);
                return error;
              }
              setReset(!reset);
              return res;
            } catch (error) {
              return error;
            }
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
        />
      )}

      <TransitionsModal open={open} setOpen={setOpen}>
        <DataGrid
          onChange={async (dataGridInputs) => {
            selectedTechinical.technicals_customers = selectedTechinical.technicals_customers.filter((item) => item.customer.id !== dataGridInputs.customer.id);
            selectedTechinical.technicals_customers.push(dataGridInputs);
            const res = await registerUser(selectedTechinical);
            setResetCustomers(!resetCustomers);
            return res;
          }}
          header={constumerColumns}
          data={technicalsCustomers || []}
          keyProp="key"
          width="100%"
          maxHeight="400px"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actionsCustomer}
          totalItems={technicalsCustomers && technicalsCustomers.length ? technicalsCustomers.length : 0}
        />
      </TransitionsModal>
    </>
  );
};

export default Technical;
