import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  environment: 'DAIRY_CATTLE',
};

export const BirthsServices = {
  endpoint: '/births',

  list: async (params = {}) => ServicesHelper.list(BirthsServices.endpoint, {...params, ...PARAMS}),

  post: async (body = {}) => ServicesHelper.post(BirthsServices.endpoint, {...body, ...PARAMS}),

  delete: async (id) => ServicesHelper.delete(BirthsServices.endpoint.concat('/', id)),

  put: async (id, body = {}) => {
    const {sexString, ...rest} = body;
    return ServicesHelper.put(BirthsServices.endpoint.concat('/', id), {...rest, ...PARAMS});
  },
};
