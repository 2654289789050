import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../components/Section/Section';
import Analytical from './Analytical';
import Synthetic from './Synthetic/Synthetic';
import './style.scss';

const Checklist = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Checklist',
      options: [
        {
          group: '  ',
          options: [
            {
              title: 'Analítico',
              url: `${match.url}/analitico`,
            },
            {
              title: 'Sintético',
              url: `${match.url}/sintetico`,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <div className="checklist">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Checklist'}} render={() => <Section templateData={templateData} />} />

      <GuardedRoute path={`${match.url}/analitico`} meta={{moduleName: 'Analítico'}} component={Analytical} />
      <GuardedRoute path={`${match.url}/sintetico`} meta={{moduleName: 'Sintético'}} component={Synthetic} />
    </div>
  );
};
export default Checklist;
