import apiNew from '../apiNew';

const fetchCategoryDsmTechnology = (params = {}) => {
  return apiNew.get(`/category_dsm_technology`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryDsmTechnologybyId = (id, params = {}) => {
  return apiNew.get(`/category_dsm_technology/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryDsmTechnology = (body = {}) => {
  return apiNew.post(`/category_dsm_technology`, body);
};

const changeCategoryDsmTechnologybyId = (id, body = {}) => {
  return apiNew.put(`/category_dsm_technology/${id}`, body);
};

const deleteCategoryDsmTechnology = (id) => {
  return apiNew.delete(`/category_dsm_technology/${id}`);
};

export {
  fetchCategoryDsmTechnology,
  fetchCategoryDsmTechnologybyId,
  registerCategoryDsmTechnology,
  changeCategoryDsmTechnologybyId,
  deleteCategoryDsmTechnology,
};
