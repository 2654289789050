import React, {useState, useCallback, useEffect, useContext} from 'react';
import {DataContext} from '../../utils/DataProvider';
import DataGrid from '../../components/DataGrid';
import climatesColumns from './ClimatesDatagridModel';
import {
  fetchClimates,
  registerClimates,
  changeClimatebyId,
  deleteClimate,
} from '../../services/climates';
import {createOrUpdateDataFull} from '../../utils/utils';

const Climates = () => {
  const {state, stateFetchParams, stateMessage} = useContext(DataContext);
  const [climatesList, setClimatesList] = useState();
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const getContents = useCallback(async () => {
    try {
      const response = await fetchClimates(stateFetchParams);

      const months = climatesColumns(
        state.harvest.first_year,
        state.harvest.last_year,
      )[0]
        .subFields.filter((item) => item.subFields && item.subFields.length)
        .reduce((acc, year) => {
          return [...acc, ...year.subFields];
        }, []);

      const climateRow = response.data.resultList.reduce((newValues, item) => {
        return {
          ...newValues,
          [item.month]: {
            ...item,
          },
        };
      }, {});

      months.forEach((monthData, month) => {
        const foundMonth = Object.keys(climateRow).find(
          (climateMonth) => climateRow[climateMonth].month === month + 1,
        );

        if (!foundMonth) {
          climateRow[month + 1] = {
            id: month + 1,
            notCreated: true,
            month: month + 1,
          };
        }
      });

      climateRow.id = -999;
      setClimatesList([climateRow]);
      setTotalItems(1);
    } catch (error) {
      console.error(error);
    }
  }, [stateFetchParams, reset]);// eslint-disable-line
  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (responseData, line, cel) => {
        const month = cel.split('.')[0];

        const targetData = responseData[month];
        if (targetData.notCreated) {
          return {status: 200};
        }

        try {
          const resp = await deleteClimate(targetData.id);
          setReset(!reset);
          return resp;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  useEffect(() => {
    getContents();
  }, [getContents]);

  const validateHeaderCallback = async (data, field, checked) => {
    const dataFound = data[0][field] ? data[0][field] : false;
    if (dataFound) {
      dataFound.validated = checked;
      const response = await createOrUpdateDataFull(
        dataFound,
        state,
        registerClimates,
        changeClimatebyId,
      );
      setReset(!reset);
      return response;
    }
    return false;
  };
  return (
    <div>
      <>
        {stateMessage}
        {climatesList && climatesList.length > 0 && (
          <DataGrid
            onChange={async (dataGridInputs, line, cel) => {
              const month = cel.split('.')[0];

              if (dataGridInputs[month].notCreated) {
                delete dataGridInputs[month].id;
              }
              delete dataGridInputs[month].notCreated;

              const resp = await createOrUpdateDataFull(
                dataGridInputs[month],
                state,
                registerClimates,
                changeClimatebyId,
              );
              setReset(!reset);
              return resp;
            }}
            header={climatesColumns(
              state.harvest.first_year,
              state.harvest.last_year,
            )}
            editableCelOnly
            validateCallback={validateHeaderCallback}
            data={climatesList}
            keyProp="key"
            titleProp="name"
            disabledPaginate
            keyValueProp="valueKey"
            editable=""
            totalItems={totalItems}
            actions={actions}
          />
        )}
      </>
    </div>
  );
};

export default Climates;
