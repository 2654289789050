/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useCallback, useLayoutEffect} from 'react';
import MaskedInput from 'react-input-mask';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {Field} from 'formik';
import {FormGroup, Label} from 'reactstrap';
import {CircularProgress} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {makeMask} from '../../utils/makeMask';
import DatePicker from './CustomFields/DatePicker.js';
import AutoComplete2 from './CustomFields/AutoComplete2/AutoComplete2';

export const FieldStyled = styled.div`
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    background-color: #fff;
    height: 38px;
    padding: 0 15px 0 15px;
    .MuiAutocomplete-clearIndicator {
      position: absolute;
      right: 30px;
    }
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
  .MuiInputBase-input {
    color: #495057;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-weight: 400;
    line-height: 1.5;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
  .PrivateNotchedOutline-legendNotched-4 {
    display: none;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiFormControl-marginNormal {
    border: 1px solid #ced4da;
    margin-top: 0px;
  }
  /* .PrivateNotchedOutline-legendLabelled-3 {
    display: none;
  } */
`;

export const TooltipButton = styled.button`
    align-items:center;
    background: transparent;
    color: #007bff;
    display: flex;
    padding: 0;
  &.bottom {
    /* &:after {
        left: unset;
        right: -4px;
        top: 6px;
        transform: rotate(180deg);
        z-index: 1;
    } */
    .tooltip-content {
      bottom: unset;
      left: unset;
      right: -12px;
      text-align: left;
      top: 26px;
      z-index: 1;
    }
    
  }
  &.right{
    .tooltip-content {
      bottom: unset;
      left: calc(100% - 25px);
      right: unset;
      top: 26px;
      z-index: 1;
    }      
  }

  i{
    margin-left:5px;
  }
  /* &:hover .tooltip-content,&:hover:after{
    opacity:1;
    visibility:visible;
  } */

  & .tooltip-content {
    background-color: #000000de;
    border-radius: 8px;
        bottom: calc(100% + 10px);
    color: #fff;
    content: '${(props) => props.content}';
    font-size: 11px;
        left: calc(100% - 40px);
    /* max-width: 350px; */
    opacity:0;
    padding: 8px 12px;
    position: absolute;
    transition: cubic-bezier(0.65, 0.05, 0.36, 1) all 400ms;
    visibility:hidden;
    white-space: pre-line;
    width: max-content;
    /* white-space: normal; */
    /* width: 100%; */
  }
  &:after{
    -webkit-transition: cubic-bezier(0.65,0.05,0.36,1) all 400ms;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #000000de;
    bottom: calc(100% - 10px);
    content: '';
    display: block;
    height: 20px;
    left: calc(100% - 21px);
    opacity: 0;
    position: absolute;
    transition: cubic-bezier(0.65,0.05,0.36,1) all 400ms;
    visibility: hidden;
    width: 20px;
  }
`;

const DataStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MakeField = ({type, ...el}) => {
  const [mainValue, setMainValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getOptions = useCallback(async () => {
    setLoading(true);
    const resp = await el.optionsFetch();

    setOptions(resp);

    setLoading(!resp);
  }, [el.currentValue, el.optionsFetch]);// eslint-disable-line

  useLayoutEffect(() => {
    setMainValue(el.currentValue || null);
  }, [el.currentValue]);

  useLayoutEffect(() => {
    if (el.optionsFetch) {
      getOptions();
    } else {
      setOptions(el && el.options && el.options.length > 0 ? el.options : []);
    }
  }, [el.options, el.optionsFetch]);// eslint-disable-line

  const filter = createFilterOptions();
  const LabelTooltip = ({element}) => {
    return (
      <>
        {element.label && !element.tooltip && (
          <Label className="float-label" for="exampleSelect">
            {element.label}
          </Label>
        )}
        {element.label && element.tooltip && (
          <>
            <Label className="float-label" for="exampleSelect">
              <Tooltip
                arrow
                placement="top-end"
                classes={makeStyles((theme) => ({
                  arrow: {
                    color: theme.palette.common.black,
                  },
                  tooltip: {
                    backgroundColor: theme.palette.common.black,
                    fontSize: '12px',
                  },
                }))()}
                title={<p>{element?.tooltip}</p>}>
                <TooltipButton>
                  <>
                    <p className="m-0">{element?.label}</p>
                    <i className="fas fa-question-circle" />
                  </>
                </TooltipButton>
              </Tooltip>
            </Label>
            {/* <Label className="float-label" for="exampleSelect">
              <TooltipButton type="button">
                <div className="tooltip-content">{element.tooltip}</div>
                {element.label}
                <i className="fas fa-question-circle" />
              </TooltipButton>
            </Label> */}
          </>
        )}
      </>
    );
  };

  switch (type) {
    case 'autocomplete2':
      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <FieldStyled className="field-style">
            <AutoComplete2 el={el} />
          </FieldStyled>
        </FormGroup>
      );
    case 'autocomplete':
      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <FieldStyled className="field-style">
            <Autocomplete
              disabled={loading || el.disabled || false}
              required={el.required}
              options={options}
              groupBy={el?.groupBy || null}
              getOptionLabel={(option) => option.label}
              name={el.name}
              id={el.name}
              value={mainValue}
              onChange={(event, newValue) => {
                if (el.onChange) {
                  el.onChange(newValue);
                }
                setMainValue(newValue);
              }}
              getOptionSelected={(option, {multiple, value}) => {
                if (!multiple) {
                  return option.value === value;
                }
                return false;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={el.placeholder || el.label}
                  margin="normal"
                  variant="outlined"
                  onFocus={(event) => {
                    if (event.target.autocomplete) {
                      event.target.autocomplete = el?.autocomplete || 'off';
                    }
                  }}
                />
              )}
            />

            {loading ? <CircularProgress color="inherit" size={20} /> : null}
          </FieldStyled>
        </FormGroup>
      );
    case 'defaultEdit':
      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <FieldStyled className="field-style">
            <Autocomplete
              disabled={loading || el.disabled || false}
              required={el.required}
              options={options}
              freeSolo
              getOptionLabel={(option) => option.label}
              name={el.name}
              id={el.name}
              value={mainValue}
              onChange={(event, newValue) => {
                if (el.onChange) {
                  el.onChange(newValue);
                }
                setMainValue(newValue);
              }}
              getOptionSelected={(option, {multiple, value}) => {
                if (!multiple) {
                  return option.value === value;
                }
                return false;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={el.placeholder || el.label}
                  margin="normal"
                  variant="outlined"
                  onFocus={(event) => {
                    if (event.target.autocomplete) {
                      event.target.autocomplete = 'whatever';
                    }
                  }}
                />
              )}
            />

            {loading ? <CircularProgress color="inherit" size={20} /> : null}
          </FieldStyled>
        </FormGroup>
      );
    case 'sugestionedit':
      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <FieldStyled className="field-style">
            <Autocomplete
              disabled={loading || el.disabled || false}
              required={el.required}
              autocomplete="off"
              options={options}
              onBlurCapture={(val) => {
                el.onChange({
                  inputValue: val.target.value,
                  label: val.target.value,
                });
              }}
              freeSolo
              // getOptionLabel={(option) => {
              //   return option.label;
              // }}
              name={el.name}
              id={el.name}
              value={mainValue}
              onChange={(event, newValue) => {
                if (el.onChange) {
                  el.onChange(newValue);
                }

                if (typeof newValue === 'string') {
                  setMainValue({
                    label: newValue,
                  });
                } else if (newValue && newValue?.inputValue) {
                  // Create a new value from the user input
                  setMainValue({
                    label: newValue.inputValue,
                  });
                } else {
                  setMainValue(newValue);
                }
              }}
              filterOptions={(optionsValues, params) => {
                const filtered = filter(options, params);

                const {inputValue} = params;
                // Suggest the creation of a new value
                const isExisting = optionsValues.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Adicionar: "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.label;
              }}
              renderOption={(props) => {
                return <li {...props}>{props.label}</li>;
              }}
              getOptionSelected={(option, {multiple, value}) => {
                if (!multiple) {
                  return option.value === value;
                }
                return false;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={el.placeholder || el.label}
                  margin="normal"
                  variant="outlined"
                  onFocus={(event) => {
                    if (event.target.autocomplete) {
                      event.target.autocomplete = 'whatever';
                    }
                  }}
                />
              )}
            />
            {/* <Autocomplete
              id="free-solo-demo"
              freeSolo
              // value={mainValue}
              onChange={(event, newValue) => {
                console.log(newValue);
                if (el.onChange) {
                  el.onChange(newValue);
                }
                // setMainValue(newValue);
              }}
              options={options.map((option) => option.label)}
              renderInput={(params) => <TextField {...params} label="freeSolo" />}
            /> */}

            {loading ? <CircularProgress color="inherit" size={20} /> : null}
          </FieldStyled>
        </FormGroup>
      );

    case 'radioEnvironment':
      return (
        <FormGroup>
          {el.label && <Label for="exampleSelect">{el.label}</Label>}
          <FieldStyled>
            <div role="group" aria-labelledby="my-radio-group">
              {el &&
                el.options &&
                el.options.map((option) => (
                  <label className="radio-style">
                    <Field
                      key={option.value}
                      type="radio"
                      name={el.name}
                      required={el.required}
                      value={option.value}
                      // onChange={(e) => setMainValue(e.target.value)}
                      // checked={el.defaultValue === mainValue}
                    />
                    {option.label}
                  </label>
                ))}
            </div>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
          </FieldStyled>
        </FormGroup>
      );
    case 'autocompleteMultiple':
      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <FieldStyled className="field-style">
            <Autocomplete
              multiple
              disabled={loading || el.disabled || false}
              options={options}
              getOptionLabel={(option) => option.label}
              name={el.name}
              id={el.name}
              value={mainValue || []}
              onChange={(event, newValue) => {
                if (el.onChange) {
                  el.onChange(newValue);
                }
                setMainValue(newValue);
              }}
              getOptionSelected={(option, {multiple, value}) => {
                if (!multiple) {
                  return option.value === value;
                }
                return false;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={el.placeholder || el.label}
                  margin="normal"
                  variant="outlined"
                  onFocus={(event) => {
                    if (event.target.autocomplete) {
                      event.target.autocomplete = 'whatever';
                    }
                  }}
                />
              )}
            />

            {loading ? <CircularProgress color="inherit" size={20} /> : null}
          </FieldStyled>
        </FormGroup>
      );
    case 'select':
      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <Field
            disabled={loading || el.disabled || false}
            as="select"
            className="form-control form-select"
            name={el.name}
            id={el.name}
            value={mainValue}
            onChange={(event) => {
              if (el.onChange) {
                el.onChange(event.target.value);
              }
              setMainValue(event.target.value);
            }}
            // onInput={(data) => {
            //   if (el.onChange) {
            //     el.onChange(data.target.value);
            //   }
            //   setMainValue(data.target.value);
            // }}
            placeholder={el.hasPlaceholder ? el.label : ''}>
            {el.hasPlaceholder && (
              <option key={`option-${el.placeholder}`} value="">
                {el.placeholder || el.label}
              </option>
            )}
            {options.map((option) => (
              <option key={`option-${option.value}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
        </FormGroup>
      );
    case 'textarea':
      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <Field
            as="textarea"
            value={mainValue}
            required={el.required}
            onInput={(data) => {
              if (el.onChange) {
                el.onChange(data.target.value);
              }
              setMainValue(data.target.value);
            }}
            className="form-control form-textarea"
            name={el.name}
            id={el.name}
            placeholder={el.hasPlaceholder ? el.placeholder : ''}
          />
        </FormGroup>
      );
    case 'datepicker':
      return (
        <FormGroup>
          <DataStyled className="data-styled">
            <LabelTooltip element={el} />
            <DatePicker el={el} setMainValue={setMainValue} />
          </DataStyled>
        </FormGroup>
      );
    case 'float':
      return (
        <FormGroup className="float-input">
          <LabelTooltip element={el} />

          <NumberFormat
            // eslint-disable-next-line react/jsx-props-no-spreading
            onValueChange={(values) => {
              if (el.onChange) {
                el.onChange({
                  value: values.value,
                });
              }
              setMainValue(values.value);
            }}
            required={el.required}
            disabled={loading || el.disabled || false}
            value={mainValue}
            thousandSeparator="."
            decimalSeparator=","
            fixedDecimalScale={el.fixedDecimalScale != null ? el.fixedDecimalScale : true}
            decimalScale={el.decimalScale != null ? el.decimalScale : 2}
            isNumericString
            prefix={el.prefix}
            suffix={el.posfix}
            allowEmptyFormatting={false}
            allowNegative={false}
          />
        </FormGroup>
      );
    case 'radio':
      return (
        <FormGroup className="float-input">
          <LabelTooltip element={el} />

          <RadioGroup
            aria-label={el.name}
            name={el.name}
            value={mainValue}
            onChange={(e) => {
              setMainValue(e.target.value);
              if (el.onChange) {
                el.onChange(e.target.value);
              }
            }}>
            <>
              {el.options.map((item) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    name={el.name}
                    control={<Radio disabled={loading || el.disabled || false} required={el.required} />}
                    label={item.label}
                  />
                );
              })}
            </>
          </RadioGroup>
        </FormGroup>
      );
    default:
      if (el.mask) {
        return (
          <FormGroup>
            <LabelTooltip element={el} />
            <Field name={el.name} element={el}>
              {({field}) => {
                return (
                  <MaskedInput
                    {...field}
                    value={mainValue}
                    id={el.name}
                    onInput={(data) => {
                      if (el.onChange) {
                        el.onChange(data.target.value);
                      }

                      setMainValue(data.target.value);
                    }}
                    autocomplete="off"
                    className="form-control"
                    placeholder={el.hasPlaceholder ? el.placeholder : ''}
                    maskChar=""
                    mask={makeMask(field.value, el.mask, el.maskType)}
                  />
                );
              }}
            </Field>
          </FormGroup>
        );
      }

      return (
        <FormGroup>
          <LabelTooltip element={el} />
          <Field
            disabled={loading || el.disabled || false}
            type={type || 'text'}
            className="form-control"
            name={el.name}
            onBlur={(data) => {
              if (el.onChange) {
                el.onChange(data.target.value);
              }
              setMainValue(data.target.value);
            }}
            id={el.name}
            autocomplete="new-password"
            placeholder={el.hasPlaceholder ? el.placeholder || el.label : ''}
          />
        </FormGroup>
      );
  }
};

export default MakeField;
