import React, {useState, useEffect} from 'react';
import {CircularProgress} from '@material-ui/core';
import FilterHarvest from './FilterHarvest';
import FilterDashboards from '../../components/FilterForms/FilterDashboards';
import Template from './Template';
import './print.scss';
import FilterDisplayDashboard from '../../components/FilterForms/FilterDashboards/FilterDiplayDashboard';
import GenerateForm from '../../components/GenerateForm';
import {
  fetchDashboard,
  fetchDashboardHarvest,
  fetchFilterLivestock,
} from '../../services/dashboards';
import ToolbarFooter from './ToolbarFooter';

function Dashboards() {
  const [openFilter, setOpenFilter] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [previous, setPrevious] = useState({});
  const [data, setData] = useState('Dashboard');
  const [listHarvest, setListHarvest] = useState([]);
  const [selectedHarvest, setSelectedHarvest] = useState(null);
  const [filterLivestock, setFilterLivestock] = useState(null);
  const [selectedfilterLivestock, setSelectedFilterLivestock] = useState(null);
  const [page, setPage] = useState(false);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);

  const endpoints = {
    stocks: 'stocks',
    climates: 'climates',
    machines: 'machines',
    teams: 'teams',
    financials: 'financials',
    livestocks: 'livestocks',
    FIRST_TRI: 'periodical',
    SECOND_TRI: 'periodical',
    THIRD_TRI: 'periodical',
    FOURTH_TRI: 'periodical',
    FIRST_SEM: 'periodical',
    SECOND_SEM: 'periodical',
    COMPLETE_CYCLE: 'periodical',
  };
  const groupFields = [
    {
      name: '',
      key: '2',
      fields: [
        {
          label: 'Painel de consultas',
          name: 'painel_consultas',
          type: 'select',
          disabled: false,
          hasPlaceholder: true,
          placeholder: 'Selecione o dashboard',
          required: true,
          options: [
            {label: 'Estoque', value: 'stocks'},
            {label: 'Clima', value: 'climates'},
            {label: 'Máquinas', value: 'machines'},
            {label: 'Equipe', value: 'teams'},
            {label: 'Financeiro', value: 'financials'},
            {label: 'Pecuária', value: 'livestocks'},
            {label: '1º trimestre', value: 'FIRST_TRI'},
            {label: '2º trimestre', value: 'SECOND_TRI'},
            {label: '3º trimestre', value: 'THIRD_TRI'},
            {label: '4º trimestre', value: 'FOURTH_TRI'},
            {label: '1º semestre', value: 'FIRST_SEM'},
            {label: '2º semestre', value: 'SECOND_SEM'},
            {label: 'Fechamento de Safra', value: 'COMPLETE_CYCLE'},
          ],
          onChange: (dataValue) => {
            if (dataValue !== '') {
              // Configuração Pagina Inicial
              if (dataValue === 'financials') {
                setPage('RECEITAS');
              } else if (dataValue === 'livestocks') {
                setPage('births');
              } else if (endpoints[dataValue] === 'periodical') {
                setPage('PRODUCTIVE');
              }
              setReset((prev) => !prev);
              setFilterData({
                ...filterData,
                painel_consultas: dataValue,
              });
            }
          },
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const submit = (values) => {
    setPrevious(values);
    const temp = {};
    Object.keys(values).map((item) => {
      return item && values[item].value
        ? Object.assign(temp, {[item]: values[item].value})
        : null;
    });
    setFilterData(temp);
  };
  const localDict = {
    climates: 'Clima',
    teams: 'Equipe',
    stocks:
      filterData.painel_consultas === 'livestock'
        ? 'Estoque Pecuário'
        : 'Estoque',
    machines: 'Máquinas',
    financials: 'Financeiro',
    livestocks: 'Pecuária',
    births: 'Nascimentos',
    deaths: 'Mortes',
    weanings: 'Desmames',
    confinements: 'Confinamento',
    herd_entry: 'Movimentação de Entrada',
    herd_departure: 'Movimentação de Saída ',
    reproduction: 'Reprodução',
    periodical: 'Produtivo',
    PRODUCTIVE: 'Produtivo',
    FINANCIAL: 'Financeiro',
    RECEITAS: 'Receitas',
    DESPESAS: 'Despesas',
  };
  const pageSettings = {
    financials: {total: 2, pages: ['RECEITAS', 'DESPESAS']},
    livestocks: {
      total: 8,
      pages: [
        'births',
        'deaths',
        'weanings',
        'confinements',
        'herd_entry',
        'herd_departure',
        'stocks',
        'reproduction',
      ],
    },
    periodical: {total: 2, pages: ['PRODUCTIVE', 'FINANCIAL']},
  };

  useEffect(() => {
    if (
      page &&
      pageSettings[endpoints[filterData.painel_consultas]] &&
      pageSettings[endpoints[filterData.painel_consultas]].pages.indexOf(
        page,
      ) === -1
    ) {
      setPage(pageSettings[endpoints[filterData.painel_consultas]].pages[0]);
    }
    const getDashboard = async () => {
      try {
        setLoading(true);
        setData(null);
        let params = {
          harvest_id: selectedHarvest,
          customer_id:
            filterData && filterData.customer ? filterData.customer : '',
          farm_id: filterData && filterData.farm ? filterData.farm : '',
        };

        if (
          page &&
          pageSettings[endpoints[filterData.painel_consultas]] &&
          pageSettings[endpoints[filterData.painel_consultas]].pages.indexOf(
            page,
          ) > -1
        ) {
          Object.assign(params, {page});
        }
        if (endpoints[filterData.painel_consultas] === 'periodical') {
          params = Object.assign(params, {
            period: filterData.painel_consultas,
            page,
            category_livestock_system_id:
              selectedfilterLivestock === 0 ? '' : selectedfilterLivestock,
          });
        }

        const resp = await fetchDashboard(
          endpoints[filterData.painel_consultas],
          params,
        );
        setData(resp.data);
        setLoading(!resp);
        return resp;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    if (filterData && filterData.painel_consultas && selectedHarvest) {
      getDashboard();
    }
  }, [selectedfilterLivestock, filterData, selectedHarvest, page]);// eslint-disable-line

  useEffect(() => {
    const getFilterLivestock = async () => {
      try {
        const result = await fetchFilterLivestock();

        const options = await result.data.resultList;
        options.unshift({id: 0, title: 'GERAL'});
        setFilterLivestock(options);
        return result;
      } catch (error) {
        console.error(error);
        return false;
      }
    };
    const getHarvest = async () => {
      try {
        const resp = await fetchDashboardHarvest({
          customer_id:
            filterData && filterData.customer ? filterData.customer : null,
          farm_id: filterData && filterData.farm ? filterData.farm : '',
        });
        setSelectedHarvest(resp.data.resultList[0].id);
        setListHarvest(resp.data.resultList);
        return resp;
      } catch (error) {
        console.error(error);
        return false;
      }
    };
    if (filterData && filterData.painel_consultas) {
      getHarvest();
    }
    if (endpoints[filterData.painel_consultas] === 'periodical') {
      getFilterLivestock();
    } else {
      setFilterLivestock(null);
    }
  }, [filterData]);// eslint-disable-line

  const generateTitle = () => {
    return localDict[
      // eslint-disable-next-line no-nested-ternary
      filterData && filterData.painel_consultas
        ? endpoints[filterData.painel_consultas] === 'livestocks' ||
          endpoints[filterData.painel_consultas] === 'periodical' ||
          endpoints[filterData.painel_consultas] === 'financials'
          ? page
          : filterData.painel_consultas
        : null
    ];
  };

  const print = () => {
    // // setCustomClass('print');
    // setTimeout(() => {
    //   // const contentDOM = document.getElementBy('dashboard-content');
    //   html2canvas(window.document.body).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     // eslint-disable-next-line new-cap
    //     const pdf = new jsPDF({
    //       orientation: 'p',
    //       unit: 'mm',
    //       format: [210, 5000],
    //       putOnlyUsedFonts: true,
    //       floatPrecision: 16, // or "smart", default is 16
    //     });
    //     pdf.addImage(imgData, 'PNG', 5, 5);

    //     window.open(pdf.output('bloburl'));
    //     // setCustomClass('no-print');
    //   });
    // }, 1000);
    window.print();
  };

  return (
    <>
      <div className="loading h-100  justify-content-center flex-column align-items-center w-100">
        <CircularProgress color="inherit" size={20} />
        <h2>Processando Dados</h2>
      </div>
      <div id="dashboard-content" className="dashboard-content">
        {openFilter && (
          <FilterDashboards
            previous={previous}
            setOpenFilter={setOpenFilter}
            submit={submit}
          />
        )}
        {!openFilter && (
          <>
            <FilterDisplayDashboard
              setOpenFilter={setOpenFilter}
              data={filterData}
            />
            <div className="pr-3">
              <div className="no-print">
                <GenerateForm onSubmit={null} groupFields={groupFields} />
              </div>
              <h1>{generateTitle()}</h1>{' '}
              <FilterHarvest
                disabled={loading}
                selectedHarvest={setSelectedHarvest}
                filterLivestock={filterLivestock}
                selectedLiveStock={setSelectedFilterLivestock}
                list={listHarvest}
              />
            </div>

            <div id="dashboard-container" className="dashboard-container">
              {!loading && (
                <>
                  <Template
                    templateName={
                      filterData && filterData.painel_consultas
                        ? endpoints[filterData.painel_consultas]
                        : null
                    }
                    page={page}
                    data={data}
                  />
                </>
              )}
              {loading ? (
                <div className="h-100 d-flex justify-content-center align-items-center w-100">
                  <CircularProgress color="inherit" size={20} />
                </div>
              ) : null}
            </div>
          </>
        )}
        {!openFilter && (
          <ToolbarFooter
            reset={reset}
            print={print}
            pageSettings={
              filterData && filterData.painel_consultas
                ? pageSettings[endpoints[filterData.painel_consultas]]
                : null
            }
            setPage={(newPage) => {
              if (!loading) {
                setPage(newPage);
              }
            }}
          />
        )}
      </div>
    </>
  );
}

export default Dashboards;
