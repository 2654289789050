import apiNew from '../apiNew';

const fetchCategoryLivestockSystem = (params = {}) => {
  return apiNew.get(`/category_livestock_system`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryLivestockSystembyId = (id, params = {}) => {
  return apiNew.get(`/category_livestock_system/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryLivestockSystem = (body = {}) => {
  return apiNew.post(`/category_livestock_system`, body);
};

const changeCategoryLivestockSystembyId = (id, body = {}) => {
  return apiNew.put(`/category_livestock_system/${id}`, body);
};

const deleteCategoryLivestockSystem = (id) => {
  return apiNew.delete(`/category_livestock_system/${id}`);
};

export {
  fetchCategoryLivestockSystem,
  fetchCategoryLivestockSystembyId,
  registerCategoryLivestockSystem,
  changeCategoryLivestockSystembyId,
  deleteCategoryLivestockSystem,
};
