import {DairyHelpers} from '../../../utils/dairyHelpers';

export const TechnicalInformationHelper = {
  monthsLabel: ['', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

  validationFindData: (data, field, fieldValuekey) => {
    // console.log('++++++++++', data, field, fieldValuekey);
    const colNotEmpty = data.filter((row) => {
      if (DairyHelpers.resolve(fieldValuekey, row) != null) {
        return true;
      }
      return null;
    });
    let validated = !!colNotEmpty.length;

    colNotEmpty.forEach((row) => {
      validated = validated && DairyHelpers.resolve(fieldValuekey.replace('quantity', 'validated'), row);
    });

    return validated;
  },
  columns: (year, months, field) => ({
    field: year,
    name: year,
    disableFilter: true,
    textAlign: 'Center',
    subFields: TechnicalInformationHelper.columnsSub(year, months, field),
  }),

  columnsSub: (year, months, field) => {
    return months.map((month) => ({
      key: `${year}.${month}.${field}`,
      disabledFilter: true,
      valueKey: `${year}.${month}.${field}`,
      field: month.toString(),
      width: '90px',
      name: `${TechnicalInformationHelper.monthsLabel[month]}/${year}`,
      customKey: 'valueKey',
      textAlign: 'Center',
      editType: 'numericedit',
      validationHeader: {validationFindData: TechnicalInformationHelper.validationFindData},
      // subFields: TechnicalInformationHelper.js.subFields(year, month, field),
    }));
  },

  subFields: (year, month, field) => {
    if (Array.isArray(field)) {
      return field.map((fieldItem) => TechnicalInformationHelper.getField(year, month, fieldItem));
    }
    return [TechnicalInformationHelper.js.getField(year, month, field)];
  },

  getField: (year, month, field) => ({
    key: `${year}.${month}.${field}`,
    disabledFilter: true,
    valueKey: `${year}.${month}.${field}`,
    field: month.toString(),
    width: '90px',
    name: 'Qtde',
    customKey: 'valueKey',
    textAlign: 'Center',
    editType: 'numericedit',
  }),
};
