import React, {memo} from 'react';

const Provider = ({providers = []}) => {
  const CurrentProvider = providers[0];
  const nextProviders = providers.slice(1);

  if (!nextProviders.length) return CurrentProvider;

  return (
    <CurrentProvider>
      <Provider providers={nextProviders} />
    </CurrentProvider>
  );
};

const Providers = ({children}) => {
  return <Provider providers={children} />;
};

export default memo(Providers);
