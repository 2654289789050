import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  .switch-environment {
    background-color: #0c4ca3;
    color: #fff;
    label {
      display: none;
      margin: 0;
    }
  }
  .open-sidebar .switch-environment label {
    display: block;
  }
`;
export const Sidebar = styled.div`
  align-items: flex-end;
  background-color: #0c4ca3;
  bottom: 0;
  box-shadow: 0 0 14px 0px rgba(0, 0, 0, 0.368627);
  display: flex;
  flex-direction: column;
  left: 0%;
  max-width: 85px;
  position: absolute;
  /* transform: translate(-170px, 0); */
  top: 0;
  transition: ease all 300ms;
  width: 100%;
  & .sidenav-brand {
    background-color: #fff;
    display: flex;
    justify-content: center;
    max-width: 95px;
    padding: 10px;
    min-width: 100%;
    img {
      transition: ease all 300ms;
    }
  }
  &.open-sidebar {
    /* transform: translate(0px, 0); */
    max-width: 265px;
    .sidenav-brand {
      align-items: flex-start;
      background-color: #ffffff;
      display: flex;
      min-width: 100%;
      img {
        transition: ease all 300ms;
      }
    }
  }
`;
export const Menu = styled.div`
  & button[disabled] {
    opacity: 0.5;
    position: relative;
    i.fas.fa-lock {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }

  &.open-sidebar {
    max-width: 265px;
    ul {
      padding: 10px 0 0 40px;
      .sidebar-item {
        padding: 4px 15px;
        &.Menu {
          padding: 6px 15px;
          button {
            align-items: center;
            flex-direction: row;
            font-size: 14px;
            font-weight: 100;
            justify-content: center;
            position: relative;

            i {
              font-size: 14px;
              margin: 0;
              padding-right: 5px;
            }
            i.fa-chevron-left {
              display: block;
              font-weight: 600;
              position: absolute;
              right: 30px;
              top: 10px;
            }
          }
        }
        button {
          background-color: transparent;
          border: none;
          border-radius: 4px;
          color: #fff;
          display: block;
          flex-direction: row;
          font-size: 14px;
          font-weight: 200;
          outline: none;
          padding: 6px 15px;
          text-align: left;
          text-transform: capitalize;
          width: 100%;
          &[disabled] {
            i.fas.fa-lock {
              position: absolute;
              right: 0;
              top: 10px;
            }
          }
          i {
            align-items: center;
            font-size: 14px;
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }
  max-width: 95px;
  width: 100%;
  ul {
    padding: 0;
    li {
      color: #fff;
      list-style: none;
    }

    .sidebar-item {
      &:hover {
        background-color: #40b648;
        transition: ease all 300ms;
      }
      display: flex;
      justify-content: center;
      padding: 10px 0;
      &.Menu {
        padding: 25px 0;
        button {
          align-items: center;
          flex-direction: row;
          font-size: 14px;
          font-weight: 100;
          justify-content: center;
          i {
            font-size: 14px;
            margin: 0 0 0 0;
            padding-right: 5px;
          }
          i.fa-chevron-left {
            display: none;
          }
        }
      }
      & button {
        align-items: center;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        font-size: 11px;
        font-weight: 200;
        justify-content: center;
        padding: 0;
        width: 80%;
        & i {
          font-size: 25px;
          margin-bottom: 5px;
          margin-right: 0px;
        }
        & i.fas.fa-lock {
          font-size: 12px;
          right: 5px;
          top: 1px;
        }
      }
    }
  }
`;
