import api from '../api';

const fetchCategoryWeaning = (params = {}) => {
  return api.get(`/category_weaning`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryWeaningbyId = (id, params = {}) => {
  return api.get(`/category_weaning/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeCategoryWeaningbyId = (id, body = {}) => {
  return api.put(`/category_weaning/${id}`, body);
};

const registerCategoryWeaning = (body = {}) => {
  return api.post(`/category_weaning`, body);
};

const deleteCategoryWeaning = (id) => {
  return api.delete(`/category_weaning/${id}`);
};

export {
  fetchCategoryWeaning,
  fetchCategoryWeaningbyId,
  changeCategoryWeaningbyId,
  registerCategoryWeaning,
  deleteCategoryWeaning,
};
