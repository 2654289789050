import apiNew from '../apiNew';

const fetchCategoryRearingSupplementationLevel = (params = {}) => {
  return apiNew.get(`/category_rearing_supplementation_level`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryRearingSupplementationLevelbyId = (id, params = {}) => {
  return apiNew.get(`/category_rearing_supplementation_level/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryRearingSupplementationLevel = (body = {}) => {
  return apiNew.post(`/category_rearing_supplementation_level`, body);
};

const changeCategoryRearingSupplementationLevelbyId = (id, body = {}) => {
  return apiNew.put(`/category_rearing_supplementation_level/${id}`, body);
};

const deleteCategoryRearingSupplementationLevel = (id) => {
  return apiNew.delete(`/category_rearing_supplementation_level/${id}`);
};

export {
  fetchCategoryRearingSupplementationLevel,
  fetchCategoryRearingSupplementationLevelbyId,
  registerCategoryRearingSupplementationLevel,
  changeCategoryRearingSupplementationLevelbyId,
  deleteCategoryRearingSupplementationLevel,
};
