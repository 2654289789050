import api from '../api';
import apiNew from '../apiNew';

export const CategoryServices = {
  endpoint: null,
  environment: null,
  extraParams: {},

  list: async (params = {}) => {
    return apiNew.get(CategoryServices.endpoint, {
      params: {
        ...params,
        ...CategoryServices.extraParams,
        ...(CategoryServices.environment && {environment: CategoryServices.environment}),
      },
    });
  },

  put: async (id, body = {}) => {
    return api.put(`${CategoryServices.endpoint}/${id}`, {
      ...body,
      ...CategoryServices.extraParams,
      ...(CategoryServices.environment && {environment: CategoryServices.environment}),
    });
  },

  post: async (body = {}) => {
    return api.post(CategoryServices.endpoint, {
      ...body,
      ...CategoryServices.extraParams,
      ...(CategoryServices.environment && {environment: CategoryServices.environment}),
    });
  },

  save: async (body = {}, id = null) => {
    if (id) {
      return CategoryServices.put(id, body);
    }

    return CategoryServices.post(body);
  },

  delete: async (id) => {
    return api.delete(CategoryServices.endpoint.concat('/', id));
  },
};
