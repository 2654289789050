import apiNew from '../apiNew';

const fetchGraphicBar = (params = {}) => {
  return apiNew.get(`/financials/graphic_line`, {
    params: {
      ...params,
    },
  });
};

const fetchValidated = (params = {}) => {
  return apiNew.get(`/financials/isValidated`, {
    params: {
      ...params,
    },
  });
};

const fetchGraphicPie = (params = {}) => {
  return apiNew.get(`/financials/graphic_pizza`, {
    params: {
      ...params,
    },
  });
};
const fetchGraphicKpi = (params = {}) => {
  return apiNew.get(`/financials/graphic_kpi`, {
    params: {
      ...params,
    },
  });
};
export {fetchGraphicBar, fetchGraphicPie, fetchGraphicKpi, fetchValidated};
