import React from 'react';
import CategoryBase from '../Component/CategoryBase';

const CategoryCostCenter = () => {
  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'type',
      valueKey: 'type',
      field: 'type',
      name: 'Tipo',
      textAlign: 'Center',
      editType: 'dropdownedit',
      returnFormat: (value) => value.value,
      optionsFetch: async () => [
        {
          value: 'RECEITAS',
          label: 'RECEITAS',
        },
        {
          value: 'DESPESAS',
          label: 'DESPESAS',
        },
      ],
    },
  ];

  return <CategoryBase columnsExtra={columns} baseEndpoint="cost_centers" environment="DAIRY_CATTLE" />;
};

export default CategoryCostCenter;
