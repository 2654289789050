import React, {useRef, useState, useEffect, useContext} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import * as Yup from 'yup';
import '../style.scss';
import fileDownload from 'js-file-download';
import {normalizeCollectionData} from '../../../../utils/utils';
import {DataContext} from '../../../../utils/DataProvider';
import {useInitialRenderEffect} from '../../../../utils/functions';
import {fetchHarvests} from '../../../../services/harvests';
import {fetchFarmsDetails} from '../../../../services/farmsDetails';
import {fetchBenchmarkingPDF} from '../../../../services/benchmark';
import Alert from '../../../../components/Notification';
import GenerateForm from '../../../../components/GenerateForm';

const IndividualReport = () => {
  const {state} = useContext(DataContext);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';
  const ref = useRef(null);
  const [harvests, setHarvests] = useState([]);
  const [farms, setFarms] = useState([]);
  const [hasFarms, setHasFarms] = useState(true);

  const [dataForm, setDataForm] = useState({});

  const loadHarvests = async () => {
    try {
      const {data} = await fetchHarvests();
      setHarvests(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadFarms = async (params = {}) => {
    try {
      const {data} = await fetchFarmsDetails({
        ...params,
        environment: 'DAIRY_CATTLE',
      });
      const farmsDetail = data.resultList.filter((farmDetail) => farmDetail.name || farmDetail.farm.name).map((farmDetail) => farmDetail.farm);
      setFarms(farmsDetail || []);

      if (farmsDetail.length) {
        setHasFarms(true);
      } else {
        setHasFarms(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useInitialRenderEffect(() => {
    loadHarvests();
  });

  useEffect(() => {
    if (dataForm.harvest) {
      loadFarms({'wherein[harvest.id]': dataForm.harvest});
    }
  }, [dataForm.harvest]);

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Safra',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a safra',
          value: harvests.length ? dataForm.harvest : '',
          required: true,
          // disabled: disableFields.harvest,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            setDataForm((prev) => ({...prev, harvest: data?.value}));
          },
          options: normalizeCollectionData(harvests, [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Fazenda',
          name: 'farm',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a fazenda',
          value: harvests.length ? dataForm.farm : '',
          required: false,
          disabled: dataForm.region,
          validations: {
            farm: !dataForm?.region && Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            setDataForm((prev) => ({...prev, farm: data?.value}));
          },
          options: normalizeCollectionData(farms, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const fetchData = async (values) => {
    try {
      const endpoint = fetchBenchmarkingPDF;
      const extension = 'pdf';

      const response = await endpoint(
        {
          environment: ENVS,
          ...(values?.farm && {farm_ids: values?.farm}),
          harvest_id: values?.harvest,
          individual: true,
        },
        'arraybuffer', // responseType,
        // {
        //   Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,/;q=0.8,application/signed-exchange;v=b3;q=0.9',
        // },
      );

      if (extension === 'pdf') {
        let nameFile = response.headers['content-disposition'].split('filename=')[1];
        nameFile = nameFile?.replace(/"/g, '');
        fileDownload(response.data, `${nameFile}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (values, actions) => {
    Object.keys(values).map((item) => {
      if (values[item] && values[item].value) {
        return Object.assign(values, {[item]: values[item].value});
      }
      return true;
    });

    try {
      await fetchData(values);
      actions.setSubmitting(false);
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      {!hasFarms && <Alert type="warning" message="Nenhuma fazenda caracterizada para essa safra." time={4000} />}
      <Card className={`row report-filter filter-container `} style={{transform: 'translateX(0)'}} ref={ref}>
        <div className="col-6 offset-3 filter-content">
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields}
            settings={{
              button: {
                text: 'Baixar',
                textSubmitting: 'Baixando....',
              },
            }}
          />
        </div>
      </Card>
    </>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

export default IndividualReport;
