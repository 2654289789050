import styled from 'styled-components';

export const ProgressStyled = styled.div`
  .progress {
    height: 6px;
    position: absolute;
    width: calc(100%);
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
  .progress-bar {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 123, 255, 1) 55%, rgba(0, 212, 255, 1) 100%);
  }
`;
export const InitiaFormStyled = styled.div`
  background: #fff;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding: 15px;
  position: relative;
  .MuiFormGroup-root[role='radiogroup'] {
    display: flex;
    flex-direction: row;
    * {
      font-size: 12px;
    }
  }

  .group-field .MuiCircularProgress-root {
    top: 26px;
  }
  .footer-buttons {
    display: flex;
    justify-content: space-between;
    button[type='submit']:after {
      background-color: #007bff;
      border-radius: 4px;
      color: #fff;
      content: '\f054';
      font-family: 'Font Awesome 5 Pro';
      font-size: 20px;
      margin-left: 13px;
      padding: 4px 15px;
    }
    button[type='button']:before {
      background-color: #007bff;
      border-radius: 4px;
      color: #fff;
      content: '\f053';
      font-family: 'Font Awesome 5 Pro';
      font-size: 20px;
      margin-right: 13px;
      padding: 4px 15px;
    }

    button[type='submit'],
    button[type='button'] {
      align-items: center;
      background-color: transparent;
      border: none;
      color: #000;
      display: flex;
      &:hover {
        background-color: transparent;
        color: #000;
        opacity: 0.6;
      }
    }
  }
  .form-group {
    align-items: center;
    display: flex;
    margin-bottom: 0rem;
    margin-top: 1rem;
    .MuiFormControl-marginNormal {
      border-radius: 4px;
      margin-bottom: 0;
    }

    .float-label {
      background-color: #fff;
      color: #007bff;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      left: 25px;
      margin-bottom: 0rem;
      margin-right: 15px;
      padding: 0 5px;
      position: absolute;
      top: 7px;
      white-space: nowrap;
      width: auto;
      z-index: 100;
    }
    .field-style {
      width: 100%;
    }
    .data-styled {
      .MuiFormControl-marginNormal {
        margin: 0;
        .MuiOutlinedInput-input {
          padding: 0.7rem 0.75rem;
        }
      }
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
    }
  }
`;
export const H3 = styled.div`
  color: #007bff;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
`;
export const ControlButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`;
export const PrevButton = styled.div`
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  i {
    background-color: #007bff;
    border-radius: 4px;
    color: #fff;
    margin-right: 10px;
    padding: 10px 15px;
  }
`;
export const NextButton = styled.div`
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  i {
    background-color: #007bff;
    border-radius: 4px;
    color: #fff;
    margin-left: 10px;
    padding: 10px 15px;
  }
`;
