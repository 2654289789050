import React, {useContext, useState, useRef} from 'react';
import {useHistory, Link} from 'react-router-dom';

import {signUp, fetchUserbyEmail} from '../../services/user';
import {DataContext} from '../../utils/DataProvider';
import {AUTH} from '../../utils/constants';

import LoginContainer from './LoginContainer';

import './Login.scss';

const Login = () => {
  const {dispatch} = useContext(DataContext);

  const inputUsername = useRef(null);
  const inputPassword = useRef(null);
  const [error, setError] = useState('');

  const history = useHistory();

  const handleLogin = async () => {
    const username = inputUsername.current.value;

    if (username.trim().length === 0) {
      setError('Informe o login');
      return;
    }

    const password = inputPassword.current.value;

    if (password.trim().length === 0) {
      setError('Informe a senha');
      return;
    }

    try {
      const response = await signUp(username, password);

      await dispatch({
        type: AUTH.ACTION.SET_TOKEN,
        token: response.data.idToken,
      });

      const user = await fetchUserbyEmail();

      await dispatch({
        type: AUTH.ACTION.LOGIN,
        user: user.data,
      });

      history.push('/');
    } catch (e) {
      setError('Usuário ou senha inválido');
    }
  };

  return (
    <LoginContainer>
      <div className="form-login">
        <div className="title">Acessar</div>
        <div className="subtitle-login">{error}</div>
        <input placeholder="Login" type="text" name="username" ref={inputUsername} autoComplete="username" />
        <input placeholder="Senha" type="password" name="password" ref={inputPassword} autoComplete="current-password" />

        <Link to="/forgot-password" className="linkForgotPassword">
          Esqueceu sua senha?
        </Link>
        <div className="divLogin">
          <button type="button" onClick={handleLogin} className="btnLogin">
            Entrar
          </button>
        </div>
      </div>
    </LoginContainer>
  );
};

export default Login;
