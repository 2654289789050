import api from '../api';

const fetchCategoryType = (params = {}) => {
  return api.get(`/category_type`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryTypebyId = (id, params = {}) => {
  return api.get(`/category_type/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryType = (body = {}) => {
  return api.post(`/category_type`, body);
};

const changeCategoryTypebyId = (id, body = {}) => {
  return api.put(`/category_type/${id}`, body);
};

const deleteCategoryType = (id) => {
  return api.delete(`/category_type/${id}`);
};

export {
  fetchCategoryType,
  fetchCategoryTypebyId,
  registerCategoryType,
  changeCategoryTypebyId,
  deleteCategoryType,
};
