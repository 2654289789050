import api from '../api';

const fetchClimates = (params = {}) => {
  return api.get(`/climates`, {
    params: {
      ...params,
    },
  });
};

const fetchClimatesbyId = (id, params = {}) => {
  return api.get(`/climates/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeClimatebyId = (id, body = {}) => {
  return api.put(`/climates/${id}`, body);
};

const registerClimates = (body = {}) => {
  return api.post(`/climates`, body);
};

const deleteClimate = (id) => {
  return api.delete(`/climates/${id}`);
};

export {
  fetchClimates,
  fetchClimatesbyId,
  changeClimatebyId,
  registerClimates,
  deleteClimate,
};
