import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import rideColumns from './RideDataGridModel';
import {fetchLiveReproductionMountHerding, createLiveStockreproductionMountHerding} from '../../services/livestock';

const RideGrid = () => {
  const [data, setData] = useState(null);
  const [totalItems] = useState(0);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParamsCustom, stateMessage, filterParams} = useContext(DataContext);

  const body = {
    created_by: {
      id: state.user.id,
    },
    harvest: {
      id: state.harvest.id,
    },
    customer: {
      id: state.customer.id,
    },
  };

  useEffect(() => {
    if (state.farm && state.farm.id) {
      body.farm = {id: state.farm.id};
    }
  }, [state.farm]);// eslint-disable-line

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    dataGridRows = dataGridRows.filter((item) => {
      return item.category_reproduction.title !== 'Fêmeas em cobertura' && item.category_reproduction.title !== 'Fêmeas prenhes';
    });
    dataGridRows
      .filter((item) => item.amount_harvest_current || item.amount_harvest_next)
      .forEach((row) => {
        row = Object.assign(row, {validated: checked});
        delete row.readonly;
        delete row.bold;
        promises.push(createLiveStockreproductionMountHerding(Object.assign(row, body)));
      });
    const response = await Promise.all(promises);
    setUpdate(!update);
    return response;
  };
  useEffect(() => {
    const loadReproduction = async () => {
      const response = await fetchLiveReproductionMountHerding({
        ...stateFetchParamsCustom,
        ...filterParams,
      });
      response.data = response.data.filter((item) => item.category_reference.show === 'Monta/toque');
      response.data = response.data.filter((item, i) => {
        item.id = i + 1;

        if (item.category_reproduction.title === 'Fêmeas em cobertura' || item.category_reproduction.title === 'Fêmeas prenhes') {
          return (item = Object.assign(item, {
            readonly: true,
            bold: true,
          }));
        }

        return Object.assign(item, {
          readonly: (item && item.category_reproduction && item.category_reproduction.readonly) || false,
          bold: (item && item.category_reproduction && item.category_reproduction.readonly) || false,
        });
      });
      setData(
        response.data.map((item) => {
          if (!item.amount_harvest_current && !item.amount_harvest_next) {
            return {...item, validated: false};
          }
          return item;
        }),
      );
    };
    loadReproduction();
  }, [update, stateFetchParamsCustom, filterParams]);
  const actions = [
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (dataGridInputs, selectedline, cel) => {
        delete dataGridInputs.readonly;
        delete dataGridInputs.period;
        delete dataGridInputs.bold;
        try {
          Object.assign(dataGridInputs, {
            status: false,
            validated: false,
            [cel]: -1,
          });

          const response = await createLiveStockreproductionMountHerding(Object.assign(dataGridInputs, body));
          setUpdate((prev) => !prev);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      {data && (
        <div className="ridegrid">
          <DataGrid
            onChange={async (dataGridInputs) => {
              try {
                if (dataGridInputs.bold) {
                  return null;
                }
                dataGridInputs.farm = dataGridInputs.farm_id
                delete dataGridInputs.farm_id
                if (
                  (dataGridInputs && dataGridInputs.validated === undefined && !dataGridInputs.readonly) ||
                  (dataGridInputs && dataGridInputs.validated === null && !dataGridInputs.readonly)
                ) {
                  Object.assign(dataGridInputs, {validated: false});
                }
                delete dataGridInputs.readonly;
                delete dataGridInputs.bold;
                Object.assign(dataGridInputs, {status: false});

                const response = await createLiveStockreproductionMountHerding(Object.assign(dataGridInputs, body));
                setUpdate(!update);
                return response;
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={rideColumns(state.harvest)}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            maxHeight="100%"
            disabledPaginate
            actions={actions}
            totalItems={totalItems}
            groupBy="category_reference"
            validateCallback={validateHeaderCallback}
          />
        </div>
      )}
    </>
  );
};

export default RideGrid;
