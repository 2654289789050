import styled from 'styled-components';

export const FormErrorMessage = styled.div`
  background: #990000;
  bottom: -10px;
  color: #fff;
  font-size: 12px;
  margin-top: 3px;
  padding: 2px 7px;
  position: absolute;
  right: 0;
  z-index: 2;
  &:before {
    border-bottom: 10px solid #900;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: -6px;
  }
`;
