import React, {useState, useEffect} from 'react';
import './style.scss';
import {fetchFarmbyId} from '../../../services/farms';

const FilterDisplayDashboard = ({setOpenFilter, data}) => {
  const [farm, setFarm] = useState(null);

  useEffect(() => {
    const getFarmbyId = async (id) => {
      try {
        const response = await fetchFarmbyId(id);

        setFarm(response.data.name);
        return response;
      } catch (error) {
        return error;
      }
    };
    if (data && data.farm) {
      getFarmbyId(data.farm);
    }

    return () => {
      setFarm(null);
    };
  }, [data]);

  return (
    <>
      {true && (
        <span id="icon-sidebar-filter-dashboard">
          <div className="icon-container">
            <i
              aria-label="open-filter"
              tabIndex={0}
              onClick={() => {
                setOpenFilter((prev) => !prev);
              }}
              onKeyPress={() => {
                setOpenFilter((prev) => !prev);
              }}
              role="button"
              className="fas fa-cog"
            />
          </div>
          <div className="text-container">
            <p>Modulo: Dashboards</p>
            {farm && <p>Fazenda: {farm}</p>}
          </div>
        </span>
      )}
    </>
  );
};
export default FilterDisplayDashboard;
