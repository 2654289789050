import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import EditableHandle from './EditableHandle';
import {Row, Cel, Column, TooltipButton} from './styled';
import {getRowValue, maskInput} from '../../utils/utils';
import {setCurrentLine, setCurrentCel, setMode, insertData} from './dataGridActions';
import DataGridContext from './DataGridContext';
import {DATAGRID, MASKS} from '../../utils/constants';

const EditableRow = ({prefetchedValues, fieldHeaders, columnsTemplate, width, groupBy, object, keyProp, keyValueProp, index, indexMap, setCel}) => {
  const {state, dispatch} = useContext(DataGridContext);
  const rowRef = useRef();
  const [selectedLine, setSelectedLine] = useState(state.currentLine === index);
  const [edit, setEdit] = useState(false);
  const [selectedCel, setSelectedCel] = useState(false);
  const [celID, setCelID] = useState(null);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (state.currentLine === index) {
      setSelectedLine(true);
    } else {
      setSelectedLine(false);
    }
  }, [state.currentLine]);// eslint-disable-line

  useEffect(() => {
    if (state.currentLine === index && state.mode === DATAGRID.TABLE.MODE.EDIT) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [state.mode]);// eslint-disable-line
  const getStyle = (itemHeader) => {
    return itemHeader?.style
      ? {
          ...{
            justifyContent: itemHeader.textAlign || 'right',
          },
          ...(itemHeader?.style(object) || null),
        }
      : {
          justifyContent: itemHeader.textAlign || 'right',
        };
  };

  useCallback(() => {
    getStyle();
  }, [state.mode]);// eslint-disable-line

  useEffect(() => {
    if (selectedLine) {
      dispatch(insertData(object));
    }
  }, [selectedLine]);// eslint-disable-line

  useEffect(() => {
    dispatch(setCurrentCel(Object.assign(state.currentCel, {cel: celID})));
  }, [celID]);// eslint-disable-line

  const maskPresentation = (value, itemHeader, line) => {
    let temp = value;
    if (itemHeader.editType === 'numericedit') {
      temp = maskInput({
        type: MASKS.DOUBLE,
        value: `${temp}`,
      });
    }
    if (itemHeader.posFix && temp) {
      temp = `${temp} ${itemHeader.posFix(line)}`;
    }
    return temp;
  };

  const TooltipMaterial = ({element, indexRow, children}) => {
    return (
      <>
        {element?.tooltipsCel && element?.tooltipsCel[indexRow] ? (
          <React.Fragment key={indexRow}>
            <TooltipButton style={{position: 'absolute', top: 0, right: 0}}>
              <Tooltip
                arrow
                classes={makeStyles((theme) => ({
                  arrow: {
                    color: theme.palette.common.black,
                  },
                  tooltip: {
                    backgroundColor: theme.palette.common.black,
                    fontSize: '12px',
                  },
                }))()}
                title={<p>{element?.tooltipsCel[indexRow].value}</p>}>
                {/* <div className="w-100"> */}
                <i className="fas fa-question-circle ml-2" />
                {/* </div> */}
              </Tooltip>
            </TooltipButton>
            {children}
          </React.Fragment>
        ) : (
          <>{children}</>
        )}
      </>
    );
  };

  return (
    <Row
      key={index}
      ref={rowRef}
      rowFields={fieldHeaders}
      rowValues={object}
      style={{
        display: 'grid',
        gridTemplateColumns: columnsTemplate,
        width: width || 'max-content',
      }}
      className={`${selectedLine && edit === false ? 'active' : ''} row-grid  row-datagrid-${index}`}>
      {fieldHeaders
        .filter((itemHeader) => itemHeader.key !== groupBy)
        .map((itemHeader, i) => (
          <Column className="item-col" key={`${itemHeader[keyProp] || itemHeader.key}`}>
            {itemHeader.editType === 'ctabutton' ? (
              <>
                <Cel className={`cel ${itemHeader.editType}`}>
                  <Button variant="contained" onClick={(e) => itemHeader.ctabutton.action(e, object)} color="secondary">
                    {itemHeader.ctabutton.title || 'title'}
                  </Button>
                </Cel>
              </>
            ) : (
              <>
                {(edit === true && selectedLine) || !object || itemHeader.key === 'validated' || itemHeader.key === 'enter_benchmarking' ? (
                  <Cel style={{justifyContent: itemHeader.textAlign || 'end'}} className={`cel ${itemHeader.editType} ${object?.readonly && 'bold'}`}>
                    <EditableHandle
                      prefetchedValues={prefetchedValues ? prefetchedValues[itemHeader.field] : []}
                      field={itemHeader.customKey ? itemHeader[itemHeader.customKey] : itemHeader.key}
                      resetRow={() => setReset(!reset)}
                      dependencies={itemHeader.dependencies}
                      editType={itemHeader.editType}
                      groupBy={itemHeader.groupBy}
                      options={itemHeader.options || []}
                      optionsFetch={itemHeader.optionsFetch}
                      popoverConfig={{
                        title: itemHeader.popoverTitle,
                        key: itemHeader.popoverKey,
                        field: itemHeader.popoverField,
                        trigger: itemHeader.popoverClassTrigger,
                        optionsFetch: itemHeader.popoverOptionsFetch,
                        position: itemHeader.popoverPosition,
                        options: itemHeader.popoverOptions ? itemHeader.popoverOptions : [],
                        defaultValue: itemHeader.popoverKey ? getRowValue(object, itemHeader.popoverKey, null, true, null, itemHeader, true) : null,
                      }}
                      defaultValue={
                        itemHeader.defaultValue && !getRowValue(object, itemHeader[keyProp], itemHeader[keyValueProp], true, null, itemHeader)
                          ? itemHeader.defaultValue
                          : getRowValue(object, itemHeader[keyProp], itemHeader[keyValueProp], true, null, itemHeader)
                      }
                      extraParams={itemHeader.extraParams || null}
                      index={i}
                      rowIndex={index}
                      rowData={object}
                      disabledEdit={
                        // eslint-disable-next-line no-nested-ternary
                        object && object.readonly
                          ? true
                          : itemHeader.defaultValue
                          ? itemHeader.disabledEdit
                          : itemHeader.defaultValue !== undefined || itemHeader.disabledEdit
                      }
                      dateView={itemHeader.dateView || null}
                      dateFormat={itemHeader.dateFormat || null}
                      mask={itemHeader.mask || null}
                      required={itemHeader.required || null}
                    />
                  </Cel>
                ) : (
                  <Cel
                    style={getStyle(itemHeader)}
                    className={`${itemHeader.disabledEdit ? 'disabled-field' : ''}  ${object && object.bold ? 'bold' : ''}  cel ${itemHeader.editType} ${
                      selectedCel === `${index}-${i}` ? 'active-cel' : ''
                    } ${index}-${i} ${
                      typeof itemHeader?.disabledDoubleClick === 'function' && itemHeader?.disabledDoubleClick(state?.initialData, index) ? 'no-pointer' : ''
                    }`}
                    onDoubleClick={() => {
                      if (typeof itemHeader?.disabledDoubleClick === 'function' && itemHeader?.disabledDoubleClick(state?.initialData, index)) {
                        return;
                      }
                      if (state.mode !== DATAGRID.TABLE.MODE.READ && state.mode !== DATAGRID.TABLE.MODE.EDIT) {
                        return;
                      }
                      dispatch(setCurrentLine(index));
                      dispatch(setMode(DATAGRID.TABLE.MODE.EDIT));
                    }}
                    onClick={(e) => {
                      setSelectedCel(`${index}-${i}`);
                      setCelID(i);
                      setCel(itemHeader[keyValueProp] || itemHeader[keyProp]);
                      if (state.mode === 'EDIT' || (state.mode === 'NEW_LINE' && index !== state.currentLine)) {
                        if (state.errors && Object.keys(state.errors).length) {
                          return;
                        }
                        let a = e.currentTarget;
                        while (a) {
                          if (a?.className && a.className.indexOf('datagrid-general') > -1) {
                            break;
                          }
                          a = a.parentNode;
                        }
                        a.parentNode.querySelector('#save').click();
                      }
                      if (state.mode !== DATAGRID.TABLE.MODE.READ) {
                        if (state.currentLine && state.currentLine !== index) {
                          dispatch(setMode(DATAGRID.TABLE.MODE.READ));
                        } else {
                          return;
                        }
                      }
                      if (selectedLine) {
                        dispatch(setCurrentLine(null));
                      } else if (!(typeof itemHeader?.disabledDoubleClick === 'function' && itemHeader?.disabledDoubleClick(state?.initialData, index))) {
                        dispatch(setCurrentLine(index));
                      }
                    }}>
                    <TooltipMaterial element={itemHeader} indexRow={indexMap}>
                      {maskPresentation(getRowValue(object, itemHeader[keyProp], itemHeader[keyValueProp], false, null, itemHeader), itemHeader, object)}
                    </TooltipMaterial>
                  </Cel>
                )}
              </>
            )}
          </Column>
        ))}
    </Row>
  );
};

export default EditableRow;
