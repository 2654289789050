import apiNew from '../apiNew';

const fetchCategoryRearingSystem = (params = {}) => {
  return apiNew.get(`/category_rearing_system`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryRearingSystembyId = (id, params = {}) => {
  return apiNew.get(`/category_rearing_system/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryRearingSystem = (body = {}) => {
  return apiNew.post(`/category_rearing_system`, body);
};

const changeCategoryRearingSystembyId = (id, body = {}) => {
  return apiNew.put(`/category_rearing_system/${id}`, body);
};

const deleteCategoryRearingSystem = (id) => {
  return apiNew.delete(`/category_rearing_system/${id}`);
};

export {
  fetchCategoryRearingSystem,
  fetchCategoryRearingSystembyId,
  registerCategoryRearingSystem,
  changeCategoryRearingSystembyId,
  deleteCategoryRearingSystem,
};
