// eslint-disable-next-line import/prefer-default-export
export const mapInitialValues = (groupsFields) => {
  const values = {};

  groupsFields.forEach((group) => {
    if (!Array.isArray(group)) {
      group.fields.forEach((field) => {
        if (!field.hideField) {
          Object.assign(values, {
            [field.name]: field.value,
          });
        }
      });
    } else {
      group.forEach((groupField) => {
        groupField.fields.forEach((field) => {
          if (!field.hideField) {
            Object.assign(values, {
              [field.name]: field.value,
            });
          }
        });
      });
    }
  });

  return values;
};
