import React from 'react';
import CategoryBase from '../Component/CategoryBase';

const CategoryMainBulkUsed = () => {
  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'summer',
      field: 'summer',
      name: 'Verão',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
    {
      key: 'winter',
      field: 'winter',
      name: 'Inverno',
      textAlign: 'Center',
      editType: 'booleanedit',
    },
  ];

  return <CategoryBase baseEndpoint="category_main_bulk_used" columnsExtra={columns} />;
};

export default CategoryMainBulkUsed;
