import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../components/Section/Section';
import './style.scss';
import CardsFinacials from './CardsFinacials';
import FinancialsGrid from './FinancialsGrid';

const Financials = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Financeiro',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Receitas',
              url: `${match.url}/tipo/receitas`,
            },
            {
              title: 'Despesas',
              url: `${match.url}/tipo/despesas`,
            },
            {
              title: 'Outros débitos',
              url: `${match.url}/tipo/outros_debitos`,
            },
            {
              title: 'Outros créditos',
              url: `${match.url}/tipo/outros_creditos`,
            },
          ],
        },
      ],
    },
    // {
    //   category: 'Cards',
    //   url: `${match.url}/cards`,
    // },
  ]);
  return (
    <div className="financials">
      <GuardedRoute
        exact
        path={match.url}
        meta={{moduleName: 'Financeiro'}}
        render={() => (
          <Section templateData={templateData}>
            <CardsFinacials />
          </Section>
        )}
      />

      <GuardedRoute
        path={`${match.url}/tipo/:slug`}
        component={FinancialsGrid}
      />
      <GuardedRoute
        path={`${match.url}/cards`}
        meta={{moduleName: 'Cards'}}
        component={CardsFinacials}
      />
    </div>
  );
};
export default Financials;
