import api from '../api';

const fetchCategoryReproduction = (params = {}) => {
  return api.get(`/category_reproduction`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryReproductionbyId = (id, params = {}) => {
  return api.get(`/category_reproduction/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeCategoryReproductionbyId = (id, body = {}) => {
  return api.put(`/category_reproduction/${id}`, body);
};

const registerCategoryReproduction = (body = {}) => {
  return api.post(`/category_reproduction`, body);
};

const deleteCategoryReproduction = (id) => {
  return api.delete(`/category_reproduction/${id}`);
};

export {
  fetchCategoryReproduction,
  fetchCategoryReproductionbyId,
  changeCategoryReproductionbyId,
  registerCategoryReproduction,
  deleteCategoryReproduction,
};
