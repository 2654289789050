import React from 'react';
import {AgricultureCategories} from '../services/AgricultureServices';

export const fieldForm = (values, setValues, dropdown, setDropdown, disableFields) => {
  const DropDown = ({children}) => {
    return (
      <div className="custom-dropdown-header" onClick={() => setDropdown((prev) => !prev)}>
        {children}
        <i className={`fas fa-chevron-${true ? 'down' : 'right'}`} />
      </div>
    );
  };
  const Subtitle = ({children}) => {
    return (
      <div className="custom-dropdown-header-subtitle" onClick={() => setDropdown((prev) => !prev)}>
        {children}
        {/* <i className={`fas fa-chevron-${true ? 'down' : 'right'}`} /> */}
      </div>
    );
  };
  return [
    {
      name: 'Cadastro de descrição de agricultura',
      component: DropDown,
      dropdownState: dropdown,
      fields: [
        {
          label: 'Tipo de Cadastro',
          required: true,
          name: 'agriculture_expense_type',
          type: 'radio',
          hasPlaceholder: true,
          options: [
            {value: 'BULKY', label: 'Volumoso'},
            {value: 'CONCENTRATED', label: 'Concentrado'},
          ],

          currentValue: values?.agriculture_expense_type,
          placeholder: ' ',
          disabled: false || disableFields,
          onChange: async (e) => {
            setValues((prev) => ({...prev, agriculture_expense_type: e}));
          },
          columns: {
            xs: 7,
          },
        },
        {
          label: 'Nome',
          name: 'cost_center_description',
          type: 'sugestionedit',
          hasPlaceholder: true,
          currentValue: values?.cost_center_description,
          placeholder: ' ',
          hideField: !values?.agriculture_expense_type,
          required: true,
          disabled: false || disableFields,
          onChange: async (e) => {
            setValues((prev) => ({...prev, cost_center_description: e?.label ? e : {label: e}}));
          },
          optionsFetch: async () => {
            const options = await AgricultureCategories.listCostCategoriesExpenses({
              [values?.agriculture_expense_type?.toLowerCase()]: true,
            });
            const optionsDescription = await AgricultureCategories.listCostCategoriesDescriptionExpenses({
              'wherecustom[costCenter.id:NULL]': `${options?.data?.resultList[0].id};=`,
            });
            return optionsDescription?.data?.resultList?.map((item) => ({
              value: item.id,
              label: item.title,
            }));
          },
          columns: {
            xs: 7,
          },
        },
      ],
    },
    {
      name: !!values?.cost_center_description && dropdown && `Descrição de ${values?.agriculture_expense_type === 'BULKY' ? 'Volumoso' : 'Concentrado'}`,
      // eslint-disable-next-line no-nested-ternary
      component: dropdown && Subtitle,
      dropdownState: !values?.id ? !!values?.cost_center_description : dropdown,
      fields: [
        {
          label: 'Área Plantada',
          name: 'planted_area',
          type: 'float',
          hasPlaceholder: true,
          currentValue: values?.planted_area,
          placeholder: ' ',
          hideField: !values?.agriculture_expense_type,
          required: true,
          disabled: false || disableFields,
          onChange: async (e) => {
            if (values?.aux === 'annual_productivity') {
              setValues((prev) => ({
                ...prev,
                planted_area: e.value,
                production: parseFloat(values?.annual_productivity) * parseFloat(e?.value) || null,
              }));
            } else if (values?.aux === 'production') {
              setValues((prev) => ({...prev, planted_area: e.value, annual_productivity: parseFloat(values?.production) / parseFloat(e?.value) || null}));
            } else {
              setValues((prev) => ({...prev, planted_area: e.value}));
            }
          },
          columns: {
            xs: 3,
          },
        },

        {
          label: values?.agriculture_expense_type === 'BULKY' ? 'Produtividade Anual(ton/ha)' : 'Produtividade Anual(MN) Saco/ha',
          name: 'annual_productivity',
          type: 'float',
          hasPlaceholder: true,
          currentValue: values?.annual_productivity,
          placeholder: ' ',
          required: true,
          hideField: !values?.agriculture_expense_type,
          disabled: (values?.aux && values?.aux === 'production') || disableFields,
          onChange: async (e) => {
            if (values?.aux !== 'production') {
              if (e?.value) {
                setValues((prev) => ({
                  ...prev,
                  aux: 'annual_productivity',
                  annual_productivity: e.value,
                  production: parseFloat(e?.value) * parseFloat(prev?.planted_area) || null,
                }));
              } else {
                setValues((prev) => ({...prev, aux: null, production: null}));
              }
            }
          },
          columns: {
            xs: 3,
          },
        },
        {
          label: values?.agriculture_expense_type === 'BULKY' ? 'Produção(ton)' : 'Produção(sacos)',
          name: 'production',
          type: 'float',
          hasPlaceholder: true,
          hideField: !values?.agriculture_expense_type,
          currentValue: values?.production,
          placeholder: ' ',
          required: true,
          disabled: (values?.aux && values?.aux === 'annual_productivity') || disableFields,
          onChange: async (e) => {
            if (values?.aux !== 'annual_productivity') {
              if (e?.value) {
                setValues((prev) => ({
                  ...prev,
                  aux: 'production',
                  production: e.value,
                  annual_productivity: parseFloat(e?.value) / parseFloat(prev?.planted_area) || null,
                }));
              } else {
                setValues((prev) => ({...prev, aux: null, annual_productivity: null}));
              }
            }
          },
          columns: {
            xs: 3,
          },
        },
        {
          label: 'Peso de saco (Kg/Saco)',
          name: 'bag_weight',
          type: 'float',
          hasPlaceholder: true,
          currentValue: values?.bag_weight,
          hideField: values?.agriculture_expense_type !== 'CONCENTRATED',
          placeholder: '',
          required: true,
          disabled: false || disableFields,
          onChange: async (e) => {
            setValues((prev) => ({...prev, bag_weight: e.value}));
          },
          columns: {
            xs: 3,
          },
        },
        {
          label: '% de perda',
          name: 'loss',
          type: 'float',
          hideField: !values?.agriculture_expense_type,
          hasPlaceholder: true,
          currentValue: values?.loss,
          placeholder: ' ',
          required: true,
          disabled: false || disableFields,
          onChange: async (e) => {
            setValues((prev) => ({...prev, loss: e.value}));
          },
          columns: {
            xs: 3,
          },
        },
        {
          label: '% de MS (DA COLHEITA)',
          name: 'initial_dry_matter',
          type: 'float',
          hasPlaceholder: true,
          currentValue: values?.initial_dry_matter,
          placeholder: ' ',
          hideField: false,
          required: true,
          disabled: false || disableFields,
          onChange: async (e) => {
            setValues((prev) => ({...prev, initial_dry_matter: e.value}));
          },
          columns: {
            xs: 3,
          },
        },
        {
          label: '% de MS (Após correção de umidade)',
          name: 'final_dry_matter',
          type: 'float',
          hasPlaceholder: true,
          currentValue: values?.final_dry_matter,
          placeholder: ' ',
          hideField: values?.agriculture_expense_type !== 'CONCENTRATED',
          required: true,
          disabled: false || disableFields,
          onChange: async (e) => {
            setValues((prev) => ({...prev, final_dry_matter: e.value}));
          },
          columns: {
            xs: 3,
          },
        },
      ],
    },
  ];
};
