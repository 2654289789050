import api from '../api';

export const fetchFaq = (params = {}) => {
  return api.get('/faq', {
    params: {
      ...params,
      'order[ordenation]': 'ASC',
    },
  });
};

export const createFaq = (body = {}) => {
  return api.post('/faq', body);
};

export const updateFaq = (id, body = {}) => {
  return api.put(`/faq/${id}`, body);
};

export const deleteFaq = (id) => {
  return api.delete(`/faq/${id}`);
};
