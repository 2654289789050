import React, {createContext, useReducer, useContext, useState, useEffect} from 'react';
import {dataReducer} from './reducers';
import secureStorage from './SecureStorage';
import Providers from './Providers';
import ModuleNameProvider from './Providers/ModuleNameProvider';
import Alert from '../components/Notification';
import {usePrevious} from './utils';

export const DataContext = createContext();

export const useDataContext = () => {
  return useContext(DataContext);
};

const DataProvider = ({children}) => {
  const user = JSON.parse(secureStorage.getItem('user') || null);

  const customer = JSON.parse(secureStorage.getItem('customer') || null);
  const harvest = JSON.parse(secureStorage.getItem('harvest') || null);
  const farm = JSON.parse(secureStorage.getItem('farm') || null);

  const environment = JSON.parse(secureStorage.getItem('environment') || null);

  const filterFilled = !!(customer && harvest && farm);
  const filterOpened = !(customer && harvest && farm);

  const initialState = {
    isAuthenticated: !!user,
    user,
    customer,
    harvest,
    farm,
    filterFilled,
    filterOpened,
    filterDashboard: {},
    environment,
  };

  const [state, dispatch] = useReducer(dataReducer, initialState);
  const [stateFetchParams, setStateFetchParams] = useState({});
  const [stateFetchParamsCustom, setStateFetchParamsCustom] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const prevFilterParams = usePrevious(filterParams);
  const [paginationParams, setPaginationParams] = useState({
    'order[id]': 'DESC',
    perPage: 50,
    page: 0,
  });
  const [stateMessage, setStateMessage] = useState(null);

  useEffect(() => {
    if (customer && customer.id) {
      setStateFetchParams((obj) =>
        Object.assign(obj, {
          'wherein[customer.id]': customer.id,
        }),
      );

      setStateFetchParamsCustom((obj) =>
        Object.assign(obj, {
          customer_id: customer.id,
        }),
      );
    }

    if (harvest && harvest.id) {
      setStateFetchParams((obj) =>
        Object.assign(obj, {
          'wherein[harvest.id]': harvest.id,
        }),
      );

      setStateFetchParamsCustom((obj) =>
        Object.assign(obj, {
          harvest_id: harvest.id,
        }),
      );
    }

    if (farm && farm.id) {
      setStateFetchParams((obj) =>
        Object.assign(obj, {
          'wherein[farm.id]': farm.id,
        }),
      );

      setStateFetchParamsCustom((obj) =>
        Object.assign(obj, {
          farm_id: farm.id,
        }),
      );

      setStateMessage(null);
    } else {
      setStateFetchParamsCustom((obj) =>
        Object.assign(obj, {
          farm_id: -1,
        }),
      );

      const customMessage = <Alert type="warning" message="Nenhuma fazenda caracterizada para essa safra" time={3000} />;
      setStateMessage(customMessage);
    }
  }, [state.customer, state.harvest, state.farm]);// eslint-disable-line

  useEffect(() => {
    if (prevFilterParams) {
      Object.keys(prevFilterParams).forEach((key) => {
        if (!filterParams[key]) {
          delete stateFetchParams[key];
          delete stateFetchParamsCustom[key];
        }
      });
    }

    setStateFetchParams({
      ...stateFetchParams,
      ...paginationParams,
      ...filterParams,
    });
    setStateFetchParamsCustom({
      ...stateFetchParamsCustom,
      ...paginationParams,
      ...filterParams,
    });
  }, [paginationParams, filterParams]);// eslint-disable-line

  return (
    <DataContext.Provider
      value={{
        state,
        dispatch,
        stateFetchParams,
        stateFetchParamsCustom,
        stateMessage,
        paginationParams,
        setPaginationParams,
        filterParams,
        setFilterParams,
      }}>
      <Providers>
        {ModuleNameProvider}
        {children}
      </Providers>
    </DataContext.Provider>
  );
};

export default DataProvider;
