import api from '../api';
import apiNew from '../apiNew';

const fetchCategoryArea = (params = {}) => {
  return apiNew.get(`/category_area`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryAreabyId = (id, params = {}) => {
  return api.get(`/category_area/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryArea = (body = {}) => {
  return api.post(`/category_area`, body);
};

const changeCategoryAreabyId = (id, body = {}) => {
  return api.put(`/category_area/${id}`, body);
};

const deleteCategoryArea = (id) => {
  return api.delete(`/category_area/${id}`);
};

export {
  fetchCategoryArea,
  fetchCategoryAreabyId,
  registerCategoryArea,
  changeCategoryAreabyId,
  deleteCategoryArea,
};
