import api from '../api';

const fetchCategoryUnitMeasurement = (params = {}) => {
  return api.get(`/category_unit_measurement`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryUnitMeasurementbyId = (id, params = {}) => {
  return api.get(`/category_unit_measurement/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryUnitMeasurement = (body = {}) => {
  return api.post(`/category_unit_measurement`, body);
};

const changeCategoryUnitMeasurementbyId = (id, body = {}) => {
  return api.put(`/category_unit_measurement/${id}`, body);
};

const deleteCategoryUnitMeasurement = (id) => {
  return api.delete(`/category_unit_measurement/${id}`);
};

export {
  fetchCategoryUnitMeasurement,
  fetchCategoryUnitMeasurementbyId,
  registerCategoryUnitMeasurement,
  changeCategoryUnitMeasurementbyId,
  deleteCategoryUnitMeasurement,
};
