const AUTH = {
  ACTION: {
    SET_TOKEN: 'SET_TOKEN',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
  },
};

const MODULE = {
  ACTION: {
    SET: 'SET_MODULE',
    CLEAR: 'CLEAR_MODULE',
  },
};

const FILTER = {
  ACTION: {
    OPEN: 'OPEN_FILTER_FORM',
    CLOSE: 'CLOSE_FILTER_FORM',
    SAVE_DATA: 'SAVE_FILTER_DATA',
    CLEAR_DATA: 'CLEAR_FILTER_DATA',
  },
  TYPE: {
    PRIMARY: 'PRIMARY_FILTER',
    ENVIRONMENT: 'ENVIRONMENT_FILTER',
  },
};

const DATAGRID = {
  ROW: {
    EVENT: {
      INSERT_DATA: 'INSERT_DATA',
      DATA_CHANGED: 'DATA_CHANGED',
      ADD_ERROR: 'ADD_ERROR',
      REMOVE_ERROR: 'REMOVE_ERROR',
      CLEAR_DATA: 'CLEAR_DATA',
    },
  },
  TABLE: {
    EVENT: {
      ADD_TABLE_ACTION_TO_QUEUE: 'ADD_TABLE_ACTION_TO_QUEUE',
      POP_TABLE_ACTION_TO_QUEUE: 'POP_TABLE_ACTION_TO_QUEUE',
      MODE_CHANGED: 'MODE_CHANGED',
      CURRENT_LINE_CHANGED: 'CURRENT_LINE_CHANGED',
      CURRENT_CEL_CHANGED: 'CURRENT_CEL_CHANGED',
      CHANGE_FILTER: 'CHANGE_FILTER',
      CHANGE_FILTER_CHECKBOX: 'CHANGE_FILTER_CHECKBOX',
      CLEAR_FILTER: 'CLEAR_FILTER',
    },
    MODE: {
      EDIT: 'EDIT',
      READ: 'READ',
      NEW_LINE: 'NEW_LINE',
      SET_CEL: 'NEW_LINE',
    },
    ACTIONS: {
      SAVE: 'save',
      EDIT: 'edit',
      CANCEL: 'cancel',
      DELETE: 'delete',
    },
  },
  ERRORS: {
    REQUIRED: 'REQUIRED',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INVALID_PASSWORD: 'INVALID_PASSWORD',
  },
};

const DATAGRID_FILTER = {
  EVENT: {
    TOOGLE_CONFIGURATOR: 'TOOGLE_CONFIGURATOR',
  },
};

const DROPDOWN_KEYS = {
  LABEL: 'LABEL',
  VALUE: 'VALUE',
};

const MASKS = {
  CPF: 'CPF',
  CEL: 'CEL',
  CEP: 'CEP',
  NUMBER: 'NUMBER',
  DOUBLE: 'DOUBLE',
  TEL: 'TEL',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  MONEY: 'MONEY',
  WEIGHT: 'WEIGHT',
  USERNAME: 'USERNAME',
  PERCENTAGE: 'PERCENTAGE',
};

const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  TECHNICAL: 'TECHNICAL',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_DIAGNOSIS: 'CUSTOMER_DIAGNOSIS',
};

export {AUTH, FILTER, MODULE, DROPDOWN_KEYS, MASKS, DATAGRID, DATAGRID_FILTER, ROLES};
