import api from '../api';

const fetchCategorySupplyType = (params = {}) => {
  return api.get(`/category_supply_type`, {
    params: {
      ...params,
    },
  });
};

const fetchCategorySupplyTypebyId = (id, params = {}) => {
  return api.get(`/category_supply_type/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategorySupplyType = (body = {}) => {
  return api.post(`/category_supply_type`, body);
};

const changeCategorySupplyTypebyId = (id, body = {}) => {
  return api.put(`/category_supply_type/${id}`, body);
};

const deleteCategorySupplyType = (id) => {
  return api.delete(`/category_supply_type/${id}`);
};

export {fetchCategorySupplyType, fetchCategorySupplyTypebyId, registerCategorySupplyType, changeCategorySupplyTypebyId, deleteCategorySupplyType};
