import React from 'react';
import {GuardedRoute} from 'react-router-guards';
import {Card} from 'reactstrap';
import {ROLES} from '../../../utils/constants';
import Section from '../../../components/Section/Section';
import GenerateRouteFactory from '../GenerateRouteFactory';

// Categories
import {
  CategoryBirthEcc,
  CategoryBulky,
  CategoryBulkyProduction,
  CategoryCommercialFeed,
  CategoryCoreUsed,
  CategoryFeeding,
  CategoryMainBulkUsed,
  CategoryMilking,
  CategoryPredominantRace,
  CategoryProductionSystem,
  CategoryProductiveManagement,
  CategoryRearing,
  CategoryTortugaCustomer,
} from './FarmDetailCategories';
import {CategoryTeamType} from './TeamCategories';
import {CategoryAnimal, CategoryDeathReason, CategoryMilkQualityType, CategoryZootechnicalCase, CategoryIGPDI} from './LivestockCategories';
import {CategoryCostCenters, CategoryDescriptionCostCenters} from './FinancialsCategories';

const DairyCattleCategories = ({match}) => {
  const defaultBreadcrumbs = [
    {title: 'Cadastros', url: '/cadastros'},
    {title: 'Categorias', url: '/cadastros/categorias'},
    {title: 'Leite', url: '/cadastros/categorias/leite'},
  ];

  const templateData = [
    {
      category: 'Caracteriza Fazenda',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Alimentação',
              url: `${match.url}/alimentacao`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryFeeding,
            },
            {
              title: 'Categoria',
              url: `${match.url}/categoria`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryRearing,
            },
            {
              title: 'Cliente Tortuga',
              url: `${match.url}/cliente-tortuga`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryTortugaCustomer,
            },
            {
              title: 'ECC (Escore de Condição Corporal) ao parto',
              url: `${match.url}/ecc-ao-parto`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryBirthEcc,
            },
            {
              title: 'Manejo Produtivo',
              url: `${match.url}/manejo-produtivo`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryProductiveManagement,
            },
            {
              title: 'Núcleo Utilizado',
              url: `${match.url}/nucleo-utilizado`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryCoreUsed,
            },
            {
              title: 'Ordenha',
              url: `${match.url}/ordenha`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryMilking,
            },
            {
              title: 'Principal Volumoso Utilizado',
              url: `${match.url}/principal-volumoso-utilizado`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryMainBulkUsed,
            },
            {
              title: 'Produção de Volumoso',
              url: `${match.url}/producao-de-volumoso`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryBulkyProduction,
            },
            {
              title: 'Raça Predominante',
              url: `${match.url}/raca-predominante`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryPredominantRace,
            },
            {
              title: 'Ração Comercial',
              url: `${match.url}/racao-comercial`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryCommercialFeed,
            },
            {
              title: 'Sistema de Produção',
              url: `${match.url}/sistema-de-producao`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryProductionSystem,
            },
            {
              title: 'Volumoso',
              url: `${match.url}/volumoso`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryBulky,
            },
          ],
        },
      ],
    },
    {
      category: 'Equipe',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Tipo de Equipe',
              url: `${match.url}/tipo-de-equipe`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryTeamType,
            },
          ],
        },
      ],
    },
    {
      category: 'Financeiro',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Centros de custo',
              url: `${match.url}/centros-de-custo`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryCostCenters,
            },
            {
              title: 'Descrição de centros de custo',
              url: `${match.url}/descricao-de-centros-de-custo`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryDescriptionCostCenters,
            },
          ],
        },
      ],
    },
    {
      category: 'Pecuária',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'IGPDI',
              url: `${match.url}/igpdi`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryIGPDI,
            },
            {
              title: 'Animal',
              url: `${match.url}/animal`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryAnimal,
            },
            {
              title: 'Motivo da morte',
              url: `${match.url}/motivo-da-morte`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryDeathReason,
            },
            {
              title: 'Casos Zootécnicos',
              url: `${match.url}/casos-zootecnicos`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryZootechnicalCase,
            },
            {
              title: 'Tipo da qualidade do leite',
              url: `${match.url}/tipo-da-qualidade-do-leite`,
              allowed: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
              component: CategoryMilkQualityType,
            },
          ],
        },
      ],
    },
  ];

  return (
    <Card style={{border: 'none'}}>
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Categorias'}} render={() => <Section templateData={templateData} />} />

      <GenerateRouteFactory defaultBreadcrumbs={defaultBreadcrumbs} data={templateData} />
    </Card>
  );
};

export default DairyCattleCategories;
