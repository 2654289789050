import api from '../api';

const fetchCategoryHerdEntryType = (params = {}) => {
  return api.get(`/category_herd_entry_type`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryHerdEntryTypebyId = (id, params = {}) => {
  return api.get(`/category_herd_entry_type/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryHerdEntryType = (body = {}) => {
  return api.post(`/category_herd_entry_type`, body);
};

const changeCategoryHerdEntryTypebyId = (id, body = {}) => {
  return api.put(`/category_herd_entry_type/${id}`, body);
};

const deleteCategoryHerdEntryType = (id) => {
  return api.delete(`/category_herd_entry_type/${id}`);
};

export {
  fetchCategoryHerdEntryType,
  fetchCategoryHerdEntryTypebyId,
  registerCategoryHerdEntryType,
  changeCategoryHerdEntryTypebyId,
  deleteCategoryHerdEntryType,
};
