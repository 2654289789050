import apiNew from '../apiNew';

const fetchDeaths = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/deaths`, {
    params: {environment, ...params},
  });
};

const fetchDeathsbyId = (id, params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/deaths/${id}`, {
    params: {environment, ...params},
  });
};

const registerDeaths = (body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.post(`/deaths`, {environment, ...body});
};

const changeDeathsbyId = (id, body = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.put(`/deaths/${id}`, {...body, environment});
};

const deleteDeaths = (id) => {
  return apiNew.delete(`/deaths/${id}`);
};

const fetchDeathsKPI = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/deaths/graphic_kpi`, {
    params: {environment, ...params},
  });
};

const fetchDeathsLine = (params = {}, environment = 'BEEF_CATTLE') => {
  return apiNew.get(`/deaths/graphic_line`, {
    params: {environment, ...params},
  });
};

export {fetchDeaths, fetchDeathsbyId, registerDeaths, changeDeathsbyId, deleteDeaths, fetchDeathsKPI, fetchDeathsLine};
