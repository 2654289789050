/* eslint-disable react/destructuring-assignment */
import React, {useState, useContext, useEffect} from 'react';
import uuid from 'react-uuid';
import beefMenu from './menu.json';
import dairyMenu from './dairy_menu.json';
import {history} from '../../utils/utils';
import logo from '../../assets/images/logo_dsm.png';
import logomarca from '../../assets/images/logomarca.png';
import backgroundColorized from '../../assets/images/image027.png';
import backgroundGreyImg from '../../assets/images/fundo_grey.png';
import Toolbar from '../Toolbar/Toolbar';
import secureStorage from '../../utils/SecureStorage';
import {DataContext} from '../../utils/DataProvider';
import {FILTER} from '../../utils/constants';
import SwitchEnvironment from './SwitchEnvironment';
import {belongsTo, toolbarMenu} from './utils';
import {Menu, Sidebar, Wrapper} from './styled';

const SidebarLayout = (props) => {
  const {state, dispatch, stateFetchParams} = useContext(DataContext);
  const [openDock, setOpenDock] = useState(true);
  const menu = {beef: beefMenu, dairy: dairyMenu};
  const historyState = history;

  useEffect(() => {
    const filter = Object.keys(stateFetchParams).filter((item) => item.indexOf('wherecustom') > -1 || item.indexOf('wherenotin') > -1);
    if (filter.length) {
      history.go();
    }

    // eslint-disable-next-line camelcase
    if (state?.user?.only_diagnosis && history?.location?.pathname === '/') {
      history.push('diagnostico');
    }
  }, [history.location]);// eslint-disable-line

  const backgroundFilter = {
    backgroundImage: `url(${backgroundColorized})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'round',
    overflow: 'hidden',
  };
  const backgroundGrey = {
    backgroundImage: `url(${backgroundGreyImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'round',
    overflow: 'hidden',
  };
  const backgroundWhite = {
    backgroundColor: 'white',
  };

  useEffect(() => {
    if (historyState?.location?.pathname === '/' && state?.user?.user_type === 'CUSTOMER' && !state?.farm) {
      historyState.push({pathname: '/filter-form', state: {referrer: window.location.pathname}});
    }
  }, [historyState?.location?.pathname]);// eslint-disable-line

  const handleAction = (action) => {
    switch (action) {
      case 'signOut':
        secureStorage.clear();
        dispatch({
          type: FILTER.ACTION.CLEAR_DATA,
        });
        history.push('/login');
        break;
      case 'toggleClick':
        setOpenDock((prev) => !prev);
        break;
      case 'diagnostico':
        historyState.push('/diagnostico');
        break;
      case 'lancamentos':
        historyState.push('/lancamentos');
        break;
      case 'checklist':
        historyState.push('/checklist');
        break;
      case 'exportacao':
        historyState.push('/exportacao');
        break;
      case 'configuracao':
        historyState.push('/configuracao');
        break;
      case 'cadastros':
        historyState.push('/cadastros');
        break;
      case 'processamento':
        historyState.push('/processamento');
        break;
      case 'dashboards':
        historyState.push('/dashboards');
        break;
      default:
        historyState.push(`/${action}`);
        break;
    }
  };

  const backgroundDiscriminator = () => {
    let background = {};
    if (window.location.pathname === '/filter-form' || window.location.pathname === '/initial-form' || window.location.pathname === '/') {
      background = backgroundFilter;
    } else if (
      window.location.pathname === '/checklist/analitico' ||
      window.location.pathname === '/checklist/sintetico' ||
      window.location.pathname === '/diagnostico' ||
      window.location.pathname === '/lancamentos' ||
      window.location.pathname === '/cadastros' ||
      window.location.pathname === '/configuracao' ||
      window.location.pathname === '/corte/checklist/analitico' ||
      window.location.pathname === '/corte/checklist/sintetico' ||
      window.location.pathname === '/corte/lancamentos' ||
      window.location.pathname === '/corte/comunicacao' ||
      window.location.pathname === '/corte/cadastros' ||
      window.location.pathname === '/corte/configuracao'
    ) {
      background = backgroundGrey;
    } else {
      background = backgroundWhite;
    }

    return background;
  };

  const verifyLock = (item) => {
    if (state.user.user_type !== 'CUSTOMER') return false;

    if (!state?.farm && item.lock) return true;

    const farmType = state?.farm?.only_diagnosis ? 'CUSTOMER_DIAGNOSIS' : 'CUSTOMER';

    return item?.lock && item.lock.includes(farmType);
  };

  return (
    <div className={`${openDock ? 'open-sidebar' : ''} control-section`} style={backgroundDiscriminator()}>
      <Wrapper>
        <div className="col-lg-12 col-sm-12 col-md-12 p-0" id="sidebar-section">
          <Sidebar className={`dock ${openDock ? 'open-sidebar' : ''}`}>
            <div className="sidenav-brand flex-column align-items-center" style={{cursor: 'pointer'}}>
              <img alt="logo" id="logo_comp" src={openDock ? logo : logomarca} style={{height: '55px'}} />
            </div>
            <SwitchEnvironment />
            <Menu className={`${openDock ? 'open-sidebar' : ''}`}>
              <ul>
                {menu[state.environment].map((item) => {
                  return !item.allowed || item.allowed.includes(state && state.user && state.user.user_type ? state.user.user_type : '') ? (
                    <li
                      key={uuid()}
                      className={`sidebar-item ${item.title} ${verifyLock(item) ? 'disabled' : ''}`}
                      onClick={() => (verifyLock(item) ? null : handleAction(item.action))}>
                      <button type="button" disabled={verifyLock(item)} className="e-text" title={item.title}>
                        <i className={item.icon} />
                        {item.title}
                        {verifyLock(item) && <i className="fas fa-lock" />}
                        {item.right_icon ? <i className={item.right_icon} /> : <></>}
                      </button>
                    </li>
                  ) : (
                    <React.Fragment key={uuid()} />
                  );
                })}
              </ul>
            </Menu>
          </Sidebar>
          {props.children}
          {belongsTo('/leite/lancamentos') && <Toolbar templateData={toolbarMenu.releasesDairy} />}
          {belongsTo('/leite/relatorios') && (
            <Toolbar
              templateData={toolbarMenu.reportsDairy.filter((el) => {
                if (!el?.allowed?.find((item) => item === state?.user?.user_type)) {
                  return false;
                }
                return el;
              })}
            />
          )}
          {belongsTo('diagnostico') && (
            <Toolbar
              templateData={[
                {
                  text: 'Caracteriza fazenda',
                  icon: 'fas fa-house-signal',
                  url: `/diagnostico/detalhes-fazenda`,
                },
                {
                  text: 'Rebanho',
                  icon: 'fal fa-cow',
                  url: `/diagnostico/rebanho`,
                },
                {
                  text: 'Administrativo',
                  icon: 'fal fa-briefcase',
                  url: `/diagnostico/administrativo`,
                },
              ]}
            />
          )}
          {belongsTo('/corte/lancamentos') && <Toolbar templateData={toolbarMenu.releasesBeef} />}
          {belongsTo('/corte/comunicacao') && <Toolbar templateData={toolbarMenu.communicationBeef} />}
          {belongsTo('/leite/comunicacao') && <Toolbar templateData={toolbarMenu.communicationDairy} />}
          {belongsTo('/cadastros') && <Toolbar templateData={toolbarMenu.registers(state.environment)} />}
        </div>
      </Wrapper>
    </div>
  );
};

export default SidebarLayout;
