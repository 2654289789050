import React from 'react';
import Indicator from '../DataPresenters/Indicator';
import MultipleIndicator from '../DataPresenters/MultipleIndicator';
import Table from '../DataPresenters/Table';
import {maskInput} from '../../../utils/utils';
import {MASKS} from '../../../utils/constants';

const DashboardMachine = ({table, start, end, total}) => {
  const totalizer = (t) => {
    if (t.length) {
      const totalTable = t
        .map((item) => item.data)
        .reduce((acc, value) => {
          return acc + value;
        });
      const totalTableTwo = t
        .map((item) => item.data_two)
        .reduce((acc, value) => {
          return acc + value;
        });

      table.push({
        data: totalTable,
        data_two: totalTableTwo,
        label: 'Total',
        validated: null,
      });
      return table;
    }
    return table;
  };
  return (
    <div>
      {start !== undefined && end !== undefined && (
        <div className="pb-2">
          <MultipleIndicator
            icon={['hourglass-start', 'hourglass-end']}
            iconSize={4}
            color="#fff"
            height="auto"
            alignItems="center"
            iconPosition="between-title"
            iconColor="#fff"
            backgroundColor="#006cd6"
            data={[
              `R$ ${maskInput({
                type: MASKS.DOUBLE,
                value: `${start ? `${Math.round(start)}` : '0'}`,
              })}`,
              `R$ ${maskInput({
                type: MASKS.DOUBLE,
                value: `${end ? `${Math.round(end)}` : '0'}`,
              })}`,
            ]}
            title={[
              'Parque de Máquinas no início da Safra',
              'Parque de Máquinas no fim da Safra',
            ]}
            subtitle={['Valor Acumulado', 'Valor Acumulado']}
          />
        </div>
      )}
      {total !== undefined && (
        <div className="pb-2 page-break">
          <Indicator
            icon="cogs"
            iconSize={4}
            color="#fff"
            height="auto"
            alignItems="center"
            iconPosition="between-title"
            iconColor="#fff"
            backgroundColor="#006cd6"
            data={`R$ ${maskInput({
              type: MASKS.DOUBLE,
              value: `${total ? total.toString() : '0'}`,
            })}`}
            title="Parque de Máquinas"
            subtitle="Valor Acumulado"
          />
        </div>
      )}

      {table && table.length > 0 && (
        <div className="maquinas-table ">
          <Table
            title="Levantamento de Máquinas"
            columns={[
              {key: 'label', name: 'Marca - Modelo'},
              {key: 'data', name: 'Quantidade'},
              {key: 'data_two', name: 'Valor unitário'},
            ]}
            rows={totalizer(table)}
            gridTemplate="repeat(3, 1fr)"
            formatter={(value, column) => {
              if (column.indexOf('data') > -1) {
                if (column === 'data_two') {
                  return `R$ ${maskInput({
                    type: MASKS.DOUBLE,
                    value: `${Math.round(value)}`,
                  })}`;
                }
                return `${maskInput({
                  type: MASKS.DOUBLE,
                  value: `${Math.round(value)}`,
                })}`;
              }
              return value;
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardMachine;
