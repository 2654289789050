import React, {createContext, useState} from 'react';
// eslint-disable-next-line import/no-cycle
import Form from './Form';
import Alert from '../../components/Notification';
import {fetchChecklistAnalyticalData} from '../../services/checklist';
import {CHECKLIST_TYPE} from './constants';

export const AnalyticalContext = createContext({});

const Analytical = () => {
  const [submitted, setSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [dataForm, setDataForm] = useState(null);
  const [showAlert, setShowAlert] = useState(null);

  const submit = async (data) => {
    try {
      setSubmitted(true);
      let farm = '';
      if (data && data.farm && data.farm.length > 0) {
        data.farm.forEach((item, index) => {
          farm = `${farm}${item.value}${
            index < data.farm.length - 1 ? '|' : ''
          }`;
        });
      }
      const resp = await fetchChecklistAnalyticalData(
        {
          'wherein[harvest.id]': data.harvest.value,
          'wherein[customer.id]': data.customer.value,
          'wherein[farm.id]': farm,
        },
        {responseType: 'arraybuffer'}, // responseType,
      );

      const url = window.URL.createObjectURL(
        new Blob([resp.data], {type: 'application/pdf'}),
      );
      setDataForm({data: resp, url});
    } catch (error) {
      setShowAlert({
        type: 'error',
        message: 'Erro ao buscar Checklist',
        time: 4000,
      });
    }
  };

  return (
    <AnalyticalContext.Provider
      value={{
        isSubmitted: !!submitted,
        showForm: setShowForm,
        submittedData: submitted,
        submit,
      }}>
      <AnalyticalContext.Consumer>
        {() =>
          !showForm ? (
            <>
              {!!showAlert && (
                <Alert
                  key={`${showAlert.message}`}
                  type={showAlert.type}
                  message={showAlert.message}
                  time={showAlert.time}
                />
              )}
              {dataForm && (
                <iframe
                  title={dataForm.data.headers['content-disposition']
                    .split('filename="')[1]
                    .replace(/"/g, '')}
                  className="pdf-view"
                  src={dataForm.url}
                />
              )}
            </>
          ) : (
            <Form type={CHECKLIST_TYPE.ANALYTICAL} />
          )
        }
      </AnalyticalContext.Consumer>
    </AnalyticalContext.Provider>
  );
};

export default Analytical;
