import React, {useContext, useEffect, useRef, useState} from 'react';
import {slideInRight, slideOutRight} from 'react-animations';
import styled, {keyframes} from 'styled-components';
import * as Yup from 'yup';
import GenerateForm from '../../../../components/GenerateForm';
import api from '../../../../services/api';
import {fetchCategoryLivestockSystem} from '../../../../services/categoryLivestockSystem';
import {fetchFarmsDetails} from '../../../../services/farmsDetails';
import {fetchHarvests} from '../../../../services/harvests';
import {DataContext} from '../../../../utils/DataProvider';
import {normalizeCollectionData} from '../../../../utils/utils';
import QuadrantChart from './quadrant';

const FilterExportationQuadrant = () => {
  const [dataForm, setDataForm] = useState({});
  const {state} = useContext(DataContext);
  const [xAxie, setXaxie] = useState();
  const [yAxie, setYaxie] = useState();
  const [xAxisAverage, setXAxisAverage] = useState();
  const [yAxisAverage, setYAxisAverage] = useState();
  const [quadrantData, setQuadrantData] = useState();
  const [selectedFarm, setSelectedFarm] = useState([]);
  const [isSelectedFarm, setIsSelectedFarm] = useState(false);
  const [slideOut, setSlideOut] = useState(false);
  const ref = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [optionsFilter, setOptionsFilter] = useState(null);
  const [filter, setFilter] = useState(0);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  const yAxeValues = [
    {
      id: 0,
      name: 'Vacas em Lactação / Rebanho (%)',
    },
    {
      id: 1,
      name: 'Gasto com volumoso na atividade / RB da atividade (%)',
    },
    {
      id: 2,
      name: 'Gasto com concentrado na atividade / RB da atividade (%)',
    },
    {
      id: 3,
      name: 'RMCA',
    },
    {
      id: 4,
      name: 'DMCA',
    },
  ];

  const xAxeValues = [
    {
      id: 0,
      name: 'Margem Bruta / Área (R$/ha)',
    },
    {
      id: 1,
      name: 'Margem Bruta / Vacas em lactação (R$/Cab)',
    },
    {
      id: 2,
      name: 'Margem Bruta unitária (R$/L)',
    },
  ];

  useEffect(() => {
    (async () => {
      const harvests = await fetchHarvests();
      const categoryLivestock = await fetchCategoryLivestockSystem();

      setOptionsFilter((prev) => ({
        ...prev,
        harvests: harvests?.data?.resultList || [],
        category_livestock_system: categoryLivestock?.data?.resultList || [],
      }));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (filter?.harvest_id) {
        const farmsData = await fetchFarmsDetails({
          'wherein[harvest.id]': filter?.harvest_id,
          environment: ENVS,
        });
        setOptionsFilter((prev) => ({
          ...prev,
          farms: farmsData?.data?.resultList.map((item) => ({...item?.farm})) || [],
        }));
      }
    })();
  }, [filter?.harvest_id]);// eslint-disable-line

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Safra*',
          name: 'harvest_id',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione uma Safra',
          value: filter.harvest_id,
          // required: true,
          validations: {
            // harvest: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(optionsFilter?.harvests || [], [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: async (newValue) => {
            setFilter((prev) => ({...prev, harvest_id: newValue.value || ''}));
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Fazenda*',
          name: 'farm_id',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione uma Fazenda',
          value: filter.farm_id,
          validations: {
            // farm: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(optionsFilter?.farms || [], [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, farm_id: newValue.value || ''}));
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Eixo x',
          name: 'indicator_x',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione o eixo X',
          value: dataForm.indicator_x,
          validations: {
            indicator_x: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => ({...prev, indicator_x: data.value}));
            } else {
              setDataForm((prev) => ({...prev, indicator_x: ''}));
            }
          },
          options: normalizeCollectionData(xAxeValues, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
        {
          hideField: false,
          label: 'Eixo y',
          name: 'indicator_y',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione o eixo Y',
          value: dataForm.indicator_y,
          validations: {
            indicator_y: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => ({...prev, indicator_y: data.value}));
            } else {
              setDataForm((prev) => ({...prev, indicator_y: ''}));
            }
          },

          options: normalizeCollectionData(yAxeValues, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const onSubmit = async (values) => {
    let indicator_x;
    let indicator_y;

    if (values.indicator_x === 0) {
      indicator_x = 'MARGEM_BRUTA_AREA';
    } else if (values.indicator_x === 1) {
      indicator_x = 'MARGEM_BRUTA_VACAS_LACTACAO';
    } else {
      indicator_x = 'MARGEM_BRUTA_UNITARIA';
    }

    if (values.indicator_y === 0) {
      indicator_y = 'VACAS_LACTACAO_REBANHO';
    } else if (values.indicator_y === 1) {
      indicator_y = 'GASTO_VOLUMOSO_RB';
    } else if (values.indicator_y === 2) {
      indicator_y = 'GASTO_CONCENTRADO_RB';
    } else if (values.indicator_y === 3) {
      indicator_y = 'RMCA';
    } else {
      indicator_y = 'DMCA';
    }

    const data = {
      harvest_id: values.harvest_id,
      farm_id: values.farm_id,
      indicator_x,
      indicator_y,
    };

    setXaxie(values.indicator_x);
    setYaxie(values.indicator_y);

    const response = await api.get('/quadrant', {
      params: {...data},
    });

    setXAxisAverage(response.data.xAxisAverage);
    setYAxisAverage(response.data.yAxisAverage);
    setQuadrantData(response.data.list);

    response.data.list.map((value) => {
      if (values.farm_id === value.farm_id) {
        setIsSelectedFarm(true);
        const selecteedFarm = {
          farm_id: values.farm_id,
          x: value.x,
          y: value.y,
        };
        setSelectedFarm(selecteedFarm);
      }
      return false;
    });
    setSubmitted(true);
    setSlideOut((prev) => !prev);
  };

  if (submitted) {
    // call the Quadrant component and pass to it via props it's data
    return (
      <QuadrantChart
        xAxe={xAxie}
        yAxe={yAxie}
        selectedFarm={selectedFarm}
        xAxisAverage={xAxisAverage}
        yAxisAverage={yAxisAverage}
        quadrantData={quadrantData}
        isSelectedFarm={isSelectedFarm}
      />
    );
  }

  return (
    <>
      <Card className={`row report-filter filter-container ${slideOut ? 'slideOut' : ''}`} style={{transform: 'translateX(0)'}} ref={ref}>
        <div className="col-6 offset-3 filter-content">
          <GenerateForm onSubmit={onSubmit} groupFields={groupFields} />
        </div>
      </Card>
    </>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;
export default FilterExportationQuadrant;
