export class FarmDetailHelper {
  static selectFarmDetailList(data) {
    if (!data) return [];

    return data.map((item) => ({
      id: item.id,
      title: item.farm.name,
    }));
  }

  static getFarm(farm) {
    return FarmDetailHelper.getFarmDetail(farm);
  }

  static getFarms(farms) {
    return farms.map((farm) => FarmDetailHelper.getFarm(farm));
  }

  static getFarmDetail(data) {
    if (!data) return null;

    return {
      id: data.id,
      title: data.name,
    };
  }
}
