import styled from 'styled-components';
import icoEye from '../../assets/ico-eye.png';

export const FormInput = styled.input`
  background: none;
  border: 0;
  font-size: 12px;
  outline: none;
`;

export const FormSelect = styled(FormInput).attrs({as: 'select'})``;

export const TextArea = styled(FormInput).attrs({as: 'textarea'})`
  height: 80px;
  resize: none;
`;

export const IcoView = styled.div`
  background: url('${icoEye}') no-repeat top center;
  cursor: pointer;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 8px;
  width: 25px;
  &.slash {
    background-position: bottom center;
  }
`;
