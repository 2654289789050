/* eslint-disable camelcase */
import * as Yup from 'yup';

// import {fetchUsers} from '../../services/user';
import {fetchCategoryProcessingFormats} from '../../../services/categoryProcessingFormats';

const SendMail = (state, values, dispatch) => {
  const fields = [
    [
      {
        fields: [
          {
            name: 'all',
            type: 'radio',
            value: values?.processing_formatting,
            currentValue: values?.processing_formatting,
            required: true,
            disabled: false,
            validations: {
              processing_formatting: Yup.string().required('Campo obrigatório'),
            },
            optionsFetch: async () => {
              try {
                const response = await fetchCategoryProcessingFormats();
                const objectTemp = [];
                response.data.resultList.map((item) =>
                  objectTemp.push({
                    value: item.id,
                    label: item.title,
                  }),
                );
                // controlFetch = ;
                return objectTemp;
              } catch (e) {
                return [];
              }
            },
            options: [{value: 'all', label: 'Todos'}],
            columns: {
              xs: 2,
            },
            onChange: (e) => {
              dispatch({type: 'add', value: {processing_formatting: e}});
            },
          },
          {
            name: 'beef',
            type: 'radio',
            value: values?.processing_formatting,
            currentValue: values?.processing_formatting,
            required: true,
            disabled: false,
            validations: {
              processing_formatting: Yup.string().required('Campo obrigatório'),
            },
            optionsFetch: async () => {
              try {
                const response = await fetchCategoryProcessingFormats();
                const objectTemp = [];
                response.data.resultList.map((item) =>
                  objectTemp.push({
                    value: item.id,
                    label: item.title,
                  }),
                );
                // controlFetch = ;
                return objectTemp;
              } catch (e) {
                return [];
              }
            },
            options: [{value: 'beef', label: 'Corte'}],
            columns: {
              xs: 3,
            },
            onChange: (e) => {
              dispatch({type: 'add', value: {processing_formatting: e}});
            },
          },
          {
            name: 'dairy',
            type: 'radio',
            value: values?.processing_formatting,
            currentValue: values?.processing_formatting,
            required: true,
            disabled: false,
            validations: {
              processing_formatting: Yup.string().required('Campo obrigatório'),
            },
            optionsFetch: async () => {
              try {
                const response = await fetchCategoryProcessingFormats();
                const objectTemp = [];
                response.data.resultList.map((item) =>
                  objectTemp.push({
                    value: item.id,
                    label: item.title,
                  }),
                );
                // controlFetch = ;
                return objectTemp;
              } catch (e) {
                return [];
              }
            },
            options: [{value: 'dairy', label: 'Leite'}],
            columns: {
              xs: 3,
            },
            onChange: (e) => {
              dispatch({type: 'add', value: {processing_formatting: e}});
            },
          },
          {
            name: 'single',
            type: 'radio',
            value: values?.processing_formatting,
            currentValue: values?.processing_formatting,
            required: true,
            disabled: false,
            validations: {
              processing_formatting: Yup.string().required('Campo obrigatório'),
            },
            optionsFetch: async () => {
              try {
                const response = await fetchCategoryProcessingFormats();
                const objectTemp = [];
                response.data.resultList.map((item) =>
                  objectTemp.push({
                    value: item.id,
                    label: item.title,
                  }),
                );
                // controlFetch = ;
                return objectTemp;
              } catch (e) {
                return [];
              }
            },
            options: [{value: 'single', label: 'Individual'}],
            columns: {
              xs: 2,
            },
            onChange: (e) => {
              dispatch({type: 'add', value: {processing_formatting: e}});
            },
          },
          {
            // tooltip: `Selecione o formato de processamento dos dados da sua fazenda.\n
            // Individual – Os dados inseridos na plataforma são referentes a cada fazenda individualmente.\n
            // Grupo de fazendas – Os dados inseridos na plataforma são referentes a duas ou mais fazendas. Dados consolidados.`,
            label: 'Destinatário:',
            name: 'sender',
            type: 'text',
            autocomplete: 'whatever',
            hasPlaceholder: true,
            placeholder: ' ',
            value: values?.processing_formatting,
            currentValue: values?.processing_formatting,
            required: true,
            disabled: false,
            validations: {
              processing_formatting: Yup.string().required('Campo obrigatório'),
            },
            optionsFetch: async () => {
              try {
                const response = await fetchCategoryProcessingFormats();
                const objectTemp = [];
                response.data.resultList.map((item) =>
                  objectTemp.push({
                    value: item.id,
                    label: item.title,
                  }),
                );
                // controlFetch = ;
                return objectTemp;
              } catch (e) {
                return [];
              }
            },
            // options: [
            //   {value: 'SINGLE', label: 'Individual'},
            //   {value: 'GROUP', label: 'Grupo'},
            // ],
            columns: {
              xs: 10,
            },
            onChange: (e) => {
              dispatch({type: 'add', value: {processing_formatting: e}});
            },
          },
          {
            // tooltip: 'Escreva a quantidade de fazendas que serão descritas',
            label: 'Assunto:',
            name: 'topic',
            type: 'text',
            hasPlaceholder: true,
            placeholder: ' ',
            // hideField: values.processing_formatting?.value !== 'SINGLE',
            value: state?.customer?.farms_limit,
            required: true,
            disabled: false,
            onChange: (e) => {
              dispatch({type: 'add', value: {farms_quantity_diagnosis: e}});
            },
            validations: {
              farms_quantity_diagnosis: Yup.string().required('Campo obrigatório'),
            },
            columns: {
              xs: 10,
            },
          },
          {
            // tooltip: 'Selecione a Safra',
            label: 'Mensagem:',
            name: 'message',
            type: 'textarea',
            // autocomplete: 'whatever',
            hasPlaceholder: true,
            placeholder: ' ',
            value: values?.harvest || '',
            currentValue: values?.harvest || '',
            required: true,
            validations: {
              harvest: Yup.string().required('Campo obrigatório'),
            },
            disabled: false,
            onChange: (e) => {
              dispatch({type: 'add', value: {harvest: e}});
            },
            // optionsFetch: async () => {
            //   try {
            //     const response = await fetchHarvests();
            //     const objectTemp = [];
            //     response.data.resultList.map((item) =>
            //       objectTemp.push({
            //         value: item.id,
            //         label: item.title,
            //       }),
            //     );
            //     return objectTemp;
            //   } catch (e) {
            //     return [];
            //   }
            // },
            columns: {
              xs: 10,
            },
          },
        ],
      },
    ],
  ];

  return [...fields];
};

export default SendMail;
