import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import {fetchUsers, registerUser, deleteUser} from '../../../services/user';
import Breadcrumbs from '../../../components/Breadcrumbs';

const Admin = () => {
  const {paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchUsers({
        ...paginationParams,
        ...filterParams,
        ...{'wherein[userType]': "'ADMIN'"},
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);
  const columns = [
    {
      key: 'name',
      field: 'name',
      name: 'Nome',
      textAlign: 'Center',
      editType: 'defaultedit',
    },
    {
      key: 'email',
      field: 'email',
      name: 'Email',
      textAlign: 'Center',
      editType: 'emailedit',
    },
    {
      key: 'password',
      field: 'password',
      name: 'Senha',
      disableFilter: true,
      textAlign: 'Center',
      editType: 'passwordedit',
      mapper: (object, editMode) => {
        if (!editMode) {
          return '**********';
        }

        return '';
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteUser(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      <Breadcrumbs
        path={[
          {title: 'Cadastros', url: '/cadastros'},
          {title: 'Administrador'},
        ]}
      />
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (dataGridInputs && !dataGridInputs.user_type) {
              dataGridInputs.user_type = 'ADMIN';
            }
            try {
              const res = await registerUser(dataGridInputs);
              setReset(!reset);
              return res;
            } catch (error) {
              return error;
            }
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default Admin;
