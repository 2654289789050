import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import {createOrUpdateData} from '../../../utils/utils';
import {fetchCostCenters, registerCostCenters, changeCostCentersbyId, deleteCostCenters} from '../../../services/costCenters';

const CostCenters = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchCostCenters({
        ...paginationParams,
        ...filterParams,
      });
      const temp = response.data.resultList.map((item) => {
        let itemType = '';
        switch (item.type) {
          case 'RECEITAS':
            itemType = {
              id: 'RECEITAS',
              name: 'Receitas',
            };
            break;
          case 'DESPESAS':
            itemType = {
              id: 'DESPESAS',
              name: 'Despesas',
            };
            break;
          case 'OUTROS_CREDITOS':
            itemType = {
              id: 'OUTROS_CREDITOS',
              name: 'Outros créditos',
            };
            break;
          case 'OUTROS_DEBITOS':
            itemType = {
              id: 'OUTROS_DEBITOS',
              name: 'Outros débitos',
            };
            break;
          default:
        }
        return {
          id: item.id,
          title: item.title,
          type: itemType,
          created_by: item.created_by,
          updated_by: item.updated_by,
          created_at: item.created_at,
          updated_at: item.updated_at,
        };
      });
      setData(temp);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);

  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      textAlign: 'Center',
      editType: 'defaultedit',
    },
    {
      key: 'type',
      valueKey: 'type.name',
      field: 'type',
      name: 'Tipo',
      textAlign: 'Center',
      editType: 'dropdownedit',
      returnFormat: (value) => {
        return value.value;
      },
      optionsFetch: async () => {
        try {
          const response = [
            {
              id: 'RECEITAS',
              name: 'Receitas',
            },
            {
              id: 'DESPESAS',
              name: 'Despesas',
            },
            {
              id: 'OUTROS_CREDITOS',
              name: 'Outros créditos',
            },
            {
              id: 'OUTROS_DEBITOS',
              name: 'Outros débitos',
            },
          ];
          const objectTemp = [];
          response.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.name,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteCostCenters(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            if (dataGridInputs && dataGridInputs.type && dataGridInputs.type.id) {
              dataGridInputs.type = dataGridInputs.type.id;
            }
            const response = await createOrUpdateData(dataGridInputs, state, registerCostCenters, changeCostCentersbyId, false);
            setReset(!reset);
            return response;
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default CostCenters;
