import {useEffect} from 'react';

export const hasProperty = (source, param) => {
  if (!source) return false;
  return Object.prototype.hasOwnProperty.call(source, param);
};

export const priceFormat = (number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
};

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const useInitialRenderEffect = (fn) => useEffect(fn, []);
