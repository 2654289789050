import React from 'react';
import styled from 'styled-components';

const Card = ({type, children}) => {
  // eslint-disable-next-line react/destructuring-assignment
  return (
    <CardStyled type={type} className="card d-flex">
      {children}
    </CardStyled>
  );
};

const CardStyled = styled.div`
  background-clip: border-box;
  background-color: #fff;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-top: 2px solid
    ${(props) =>
      props.type && props.type === 'income'
        ? 'rgb(0, 0, 202)'
        : 'rgb(218, 0, 0)'};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0 4px 8px 0 rgb(200, 200, 200), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
  min-height: 15em;
  padding: 15px 30px;
  position: relative;
  position: relative;
  z-index: 0;
  .no-content {
    align-items: center;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 0 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    h6 {
      background-color: #f6f6f6;
      border-radius: 4px;
      padding: 20px 30px;
      text-align: center;
      width: 100%;
    }
  }
`;
export default Card;
