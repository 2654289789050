import React, {useContext} from 'react';
import {ButtonContainer, Button, DropdownContainer, StyledMenu, MenuGroup, MenuItem} from './styled';
import {DataContext} from '../../../utils/DataProvider';
import {history} from '../../../utils/utils';

const Menu = ({title, options, url}) => {
  const {state} = useContext(DataContext);

  return (
    <div>
      {title && (
        <ButtonContainer className="menu-container">
          <Button
            className={` main-btn`}
            type="button"
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={() => {
              if (url) {
                history.push(url);
              }
            }}>
            {title}
          </Button>
          {options && options.length > 0 && (
            <DropdownContainer>
              <StyledMenu>
                <>
                  {options.map((option) => (
                    <div key={option.group}>
                      {option && option.group && option.options.length && (
                        <MenuGroup>
                          <label style={{marginBottom: '-5px'}}>{option.group}</label>
                          {option.options.map((opt, i) => {
                            return !opt.allowed || opt.allowed.includes(state.user.user_type) ? (
                              <MenuItem
                                key={opt.title}
                                type="button"
                                onClick={() => {
                                  if (opt.url) {
                                    history.push(opt.url);
                                  }
                                }}>
                                {opt.title} <i className="fas fa-chevron-right ml-2" />
                              </MenuItem>
                            ) : (
                              <> </>
                            );
                          })}
                        </MenuGroup>
                      )}
                    </div>
                  ))}
                </>
              </StyledMenu>
            </DropdownContainer>
          )}
        </ButtonContainer>
      )}
    </div>
  );
};

export default Menu;
