import api from '../api';

const fetchMachines = (params = {}) => {
  return api.get(`/machines`, {
    params: {
      ...params,
    },
  });
};

const fetchMachinebyId = (id, params = {}) => {
  return api.get(`/machines/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeMachinesbyId = (id, body = {}) => {
  return api.put(`/machines/${id}`, body);
};

const registerMachine = (body = {}) => {
  return api.post(`/machines`, body);
};

const deleteMachine = (id) => {
  return api.delete(`/machines/${id}`);
};

export {
  fetchMachines,
  fetchMachinebyId,
  changeMachinesbyId,
  registerMachine,
  deleteMachine,
};
