import React from 'react';
import {Link} from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Container, BreadCrumbersItem, NoUrl} from './styled';

const Breadcrumbs = ({path}) => {
  return (
    <Container>
      {path &&
        path.length &&
        path.map((item) =>
          item.url ? (
            <BreadCrumbersItem key={`${item.title}`}>
              <Link to={item.url}>{item.title}</Link>
              <ArrowForwardIosIcon style={{fontSize: '12px', color: 'inheritz', margin: '0 10px'}} />
            </BreadCrumbersItem>
          ) : (
            <NoUrl key={`${item.title}`}>{item.title}</NoUrl>
          ),
        )}
    </Container>
  );
};

export default Breadcrumbs;
