const releasesBeef = [
  {
    text: 'Caracteriza fazenda',
    icon: 'fas fa-house-signal',
    url: `/corte/lancamentos/caracteriza-fazenda`,
  },
  {
    text: 'Equipe',
    icon: 'fal fa-users',
    url: `/corte/lancamentos/equipe`,
  },
  {
    text: 'Financeiro',
    icon: 'fal fa-usd-circle',
    url: `/corte/lancamentos/financeiro`,
  },
  {
    text: 'Pecuária',
    icon: 'fal fa-cow',
    url: `/corte/lancamentos/pecuaria`,
  },
  {
    text: 'Máquinas',
    icon: 'fal fa-tractor',
    url: `/corte/lancamentos/maquinas`,
  },
  {
    text: 'Estoque',
    icon: 'fal fa-box-open',
    url: `/corte/lancamentos/estoque`,
  },
  {
    text: 'Clima',
    icon: 'fal fa-cloud-rain',
    url: `/corte/lancamentos/clima`,
  },
  {
    text: 'Confira os lançamentos',
    icon: 'fas fa-align-center',
    url: `/corte/lancamentos/confira-lancamentos`,
  },
];

const communicationBeef = [
  // {
  //   text: 'Enviar email',
  //   icon: 'far fa-envelope',
  //   url: `/corte/comunicacao/enviar-email`,
  // },
  {
    text: 'Categorias FAQ',
    icon: 'fas fa-list-ul',
    url: `/corte/comunicacao/categorias-faq`,
  },
  {
    text: 'Questão FAQ',
    icon: 'far fa-question-circle',
    url: `/corte/comunicacao/questao-faq`,
  },
];

const communicationDairy = [
  // {
  //   text: 'Enviar email',
  //   icon: 'far fa-envelope',
  //   url: `/leite/comunicacao/enviar-email`,
  // },
  {
    text: 'Categorias FAQ',
    icon: 'fas fa-list-ul',
    url: `/leite/comunicacao/categorias-faq`,
  },
  {
    text: 'Questão FAQ',
    icon: 'far fa-question-circle',
    url: `/leite/comunicacao/questao-faq`,
  },
];

const releasesDairy = [
  {
    text: 'Caracteriza fazenda',
    icon: 'fas fa-house-signal',
    url: `/leite/lancamentos/caracteriza-fazenda`,
  },
  {
    text: 'Equipe',
    icon: 'fal fa-users',
    url: `/leite/lancamentos/equipe`,
  },
  {
    text: 'Financeiro',
    icon: 'fal fa-usd-circle',
    url: `/leite/lancamentos/financeiro`,
  },
  {
    text: 'Pecuária',
    icon: 'fal fa-cow',
    url: `/leite/lancamentos/pecuaria`,
  },
  {
    text: 'Agricultura',
    icon: 'fal fa-corn',
    url: `/leite/lancamentos/agricultura`,
  },
];
const reportsDairy = [
  {
    text: 'Fluxo de caixa',
    icon: 'fal fa-cow',
    url: `/leite/relatorios/fluxo-caixa`,
    allowed: ['SUPER_ADMIN', 'ADMIN', 'CUSTOMER', 'TECHNICAL'],
  },
  {
    text: 'Relatório mensal',
    icon: 'fal fa-chart-line',
    url: `/leite/relatorios/mensal`,
    allowed: ['SUPER_ADMIN', 'ADMIN', 'CUSTOMER', 'TECHNICAL'],
  },
  {
    text: 'Quadrante',
    icon: 'fal fa-chart-scatter',
    url: `/leite/relatorios/filtro-quadrante`,
    allowed: ['SUPER_ADMIN', 'ADMIN'],
  },
  {
    //   text: 'Relatório individual',
    //   icon: 'fal fa-people-carry',
    //   url: `/leite/relatorios/individual`,
    // },
    text: 'Relatório individual',
    icon: 'fal fa-file-pdf',
    url: `/leite/relatorios/individual`,
    allowed: ['SUPER_ADMIN', 'ADMIN'],
  },
  // {
  //   text: 'Benchmarking',
  //   icon: 'fal fa-usd-circle',
  //   url: `/leite/relatorios/resumo`,
  // },
];

const registers = (environment) => [
  {
    text: 'Safra',
    icon: 'fas fa-calendar-check',
    url: `/cadastros/safra`,
  },
  {
    text: 'Cliente',
    icon: 'far fa-poll-people',
    url: '/cadastros/clientes',
  },
  {
    text: 'Caracteriza safra',
    icon: 'fas fa-calendar-check',
    url: '/cadastros/caracteriza-safra',
  },
  {
    text: 'Fazenda',
    icon: 'fas fa-house-signal',
    url: '/cadastros/fazenda',
  },
  {
    text: 'Categorias'.concat(environment === 'dairy' ? ' Leite' : ' Corte'),
    icon: 'fas fa-chalkboard-teacher',
    url: '/cadastros/categorias'.concat(environment === 'dairy' ? '/leite' : '/corte'),
  },
  {
    text: 'Técnico',
    icon: 'fas fa-user-hard-hat',
    url: `/cadastros/tecnico`,
  },
  {
    text: 'Administrador',
    icon: 'fas fa-user-cog',
    url: `/cadastros/admin`,
  }
];

export const toolbarMenu = {
  releasesBeef,
  registers,
  releasesDairy,
  reportsDairy,
  communicationBeef,
  communicationDairy,
};

export const belongsTo = (path) => {
  return window.location.pathname.indexOf(path) > -1;
};
