import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage} from 'formik';
import {FormControl, FormErrorMessage, FormLabel} from '../form';

const TplGroup = ({
  name,
  title,
  children,
  styleLabel = {},
  styleContent = {},
  isRequired,
}) => {
  return (
    <FormControl className="tpl-group-control">
      <div className="tpl-group-label" style={styleLabel}>
        {title && (
          <FormLabel>
            {title}
            {isRequired && <span>*</span>}
          </FormLabel>
        )}
      </div>
      <div className="tpl-group-content" style={styleContent}>
        {children}
        <ErrorMessage name={name} component={FormErrorMessage} />
      </div>
    </FormControl>
  );
};

TplGroup.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  styleContent: PropTypes.object,
  styleLabel: PropTypes.object,
};

export default TplGroup;
