import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  environment: 'DAIRY_CATTLE',
};

export const ExpensesAgricultureByType = {
  endpoint: '/agriculture_expenses',

  list: async (type, params = {}) => ServicesHelper.list(`${ExpensesAgricultureByType.endpoint}/${type}`, {...params, ...PARAMS}),
};
export const ExpensesItemAgriculture = {
  endpoint: '/agriculture_expense_items',

  list: async (params = {}) => ServicesHelper.list(ExpensesItemAgriculture.endpoint, {...params, ...PARAMS}),

  post: async (body, params = {}) => ServicesHelper.post(`${ExpensesItemAgriculture.endpoint}/batch`, body, {...params, ...PARAMS}),
};

export const AgricultureCategories = {
  listCostCategoriesExpenses: async (params = {}) => ServicesHelper.list('cost_centers', {...params, ...PARAMS}),
  listCostCategoriesDescriptionExpenses: async (params = {}) => ServicesHelper.list('cost_centers_description', {...params, ...PARAMS}),
  listCostCategories: async (params = {}) => ServicesHelper.list('agriculture_cost_categories', {...params}),
  listSubCostCategories: async (params = {}) => ServicesHelper.list('agriculture_cost_subcategories', {...params}),
};

export const ExpensesAgriculture = {
  endpoint: '/agriculture_expenses',

  find: async (id, params = {}) => ServicesHelper.list(`${ExpensesAgriculture.endpoint}/${id}`, {...params, ...PARAMS}),

  post: async (body) => ServicesHelper.post(ExpensesAgriculture.endpoint, body),

  getCostPerKilo: async (params) => ServicesHelper.list(`${ExpensesAgriculture.endpoint}/cost_per_kilo`, {...params, ...PARAMS}),
};
