import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import {createOrUpdateData} from '../../../utils/utils';
import {fetchCountries, registerCountry, changeCountrybyId, deleteCountry} from '../../../services/country';

const Country = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchCountries({
        ...paginationParams,
        ...filterParams,
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);

  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      required: true,
      textAlign: 'Center',
      editType: 'textedit',
    },
    {
      key: 'zip_pattern',
      field: 'zip_pattern',
      name: 'Padrão CEP',
      required: true,
      textAlign: 'Center',
      editType: 'defaultedit',
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteCountry(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {data && (
        <>
          <DataGrid
            onChange={async (dataGridInputs) => {
              const response = await createOrUpdateData(dataGridInputs, state, registerCountry, changeCountrybyId, false);
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
          />
          <p className="p-3 m-0">
            <p> Para descrever o padrão de CEP de cada país, considere os seguintes caracteres:</p>
            <p>
              <b>9</b> para descrever um caractere do tipo numero entre <b>0</b> - <b>9</b>
            </p>
            <p>
              <b>a</b> para descrever um caractere do tipo letra entre <b>a</b> -<b>z</b>, <b>A</b> - <b>Z</b>
            </p>
            <p>
              <b>*</b> para descrever qualquer tipo de caractere <b>0</b> - <b>9</b>, <b>a</b> - <b>z</b>, <b>A</b> - <b>Z</b>
            </p>
          </p>
        </>
      )}
    </>
  );
};

export default Country;
