/* eslint-disable camelcase */
/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import {Col, Row} from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {createOrUpdateDataFull, maskInput} from '../../../../utils/utils';
import {fetchFinancials, deleteFinancials, changeFinancialsbyId, createFinancials} from '../../../../services/financials';
import {fetchAverageHerd} from '../../../../services/averageHerd';
import {fetchCostCenters} from '../../../../services/costCenters';
import {MASKS} from '../../../../utils/constants';
import InputDistribution from './InputDistribution';
import fetchSupportMaterial from '../../../../services/supportMaterial';
import {fixedColumn, supportTable, defaultData, columns} from './livestockExpensesModel';
import {fetchFarmsDetails} from '../../../../services/farmsDetails';

const LivestockExpenses = () => {
  const [data, setData] = useState([]);
  // const [dataTotalExpenses, setDataTotalExpenses] = useState([]);
  // const [fixedColumTotalExpenses, setFixedColumTotalExpenses] = useState(
  //   fixedColumnTotal,
  // );
  const [farmDetail, setFarmDetail] = useState(null);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParams, stateMessage, filterParams, stateFetchParamsCustom} = useContext(DataContext);
  const [dataSupport, setDataSupport] = useState(defaultData);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const dataFactory = {
    // eslint-disable-next-line camelcase
    date: `30/06/${state?.harvest?.last_year} 00:00:00`,
    period: true,
    status: false,
    validated: true,
  };
  const [colFixed, setColFixed] = useState(fixedColumn);

  const getTitleById = (element) => {
    switch (element.id) {
      case 1:
        return 'Administração';
      case 21:
        return 'Manutenções e investimentos na fazenda';
      case 9:
        return 'Taxas e Impostos';
      case 25:
        return 'Outros';
      case 305:
        return 'Despesa Total Agrupada (Se não for possível distribuir acima)';
      default:
        return element.title;
    }
  };

  useEffect(() => {
    (async () => {
      const averageFetch = await fetchAverageHerd({
        ...stateFetchParamsCustom,
      });
      const respCostCenters = await fetchCostCenters({
        'wherein[id]': '302,24,1,303,304,9,21,25,305',
      });
      const dataFixedColumn = [];
      respCostCenters.data.resultList
        .sort((a, b) => {
          if (a?.id > b?.id) {
            return 1;
          }
          if (a?.id < b?.id) {
            return -1;
          }
          return 0;
        })
        .map((element) =>
          dataFixedColumn.push({
            cost_center: getTitleById(element),
            cost: element,
          }),
        );
      setColFixed((prev) =>
        Object.assign(prev, {
          data: [...dataFixedColumn],
        }),
      );
      const response = await fetchFinancials({
        'wherein[costCenter.id]': '302,24,1,303,304,9,21,25,305',
        // eslint-disable-next-line camelcase
        date: `30/06/${state?.harvest?.last_year} 00:00:00`,
        description: 'LIVESTOCK_EXPENSES',
        ...stateFetchParams,
        ...filterParams,
      });
      const newData = colFixed.data.map((row) => {
        const findData = response.data.resultList.find((item) => item.cost_center.id === row.cost.id);
        if (findData) {
          return findData;
        }
        return {value: null, id: null, cost_center: {...row.cost}};
      });

      // const respCostCentersTotalExpenses = await fetchCostCenters({
      //   'wherein[id]': '305',
      // });

      // setFixedColumTotalExpenses((prev) =>
      //   Object.assign(prev, {
      //     data: [
      //       {
      //         cost_center: getTitleById(
      //           respCostCentersTotalExpenses?.data?.resultList[0],
      //         ),
      //         cost: respCostCentersTotalExpenses?.data?.resultList[0],
      //       },
      //     ],
      //   }),
      // );
      // const newDataExpenses = fixedColumTotalExpenses.data.map((row) => {
      //   const findData = response.data.resultList.find(
      //     (item) => item.cost_center.id === row.cost.id,
      //   );

      //   if (findData) {
      //     return findData;
      //   }
      //   return {
      //     value: null,
      //     id: null,
      //     cost_center: {...row.cost},
      //     externalTable: newData,
      //   };
      // });
      // setDataTotalExpenses(newDataExpenses);
      setData(
        newData
          .sort((a, b) => {
            if (a?.cost_center.id > b?.cost_center.id) {
              return 1;
            }
            if (a?.cost_center.id < b?.cost_center.id) {
              return -1;
            }
            return 0;
          })
          .map((item) => {
            return {...item, data: newData};
          }),
      );

      const farmdetails = await fetchFarmsDetails({
        ...stateFetchParams,
        ...filterParams,
      });
      setFarmDetail(farmdetails.data.resultList[0]);

      const finalCalc = defaultData
        .sort((a, b) => {
          if (a?.cost_center.id > b?.cost_center.id) {
            return 1;
          }
          if (a?.cost_center.id < b?.cost_center.id) {
            return -1;
          }
          return 0;
        })
        .map((item) => {
          const findCostValue = newData.find((element) => item?.cost_center?.id === element?.cost_center?.id);
          if (findCostValue && findCostValue.value) {
            return {
              ...item,
              calculed: Number(Math.abs(findCostValue.value / averageFetch.data.average / 12).toFixed(2)),
            };
          }
          return item;
        });
      setDataSupport(finalCalc);
    })();
  }, [update]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const res = await deleteFinancials(value.id);
          setUpdate((prev) => !prev);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  const getLivestockSystem = (label) => {
    switch (label) {
      // case 'CRE, com compras':
      //   return 'Cria-recria-engorda';
      case 'Recria':
        return 'Recria-engorda';
      case 'Engorda':
        return 'Recria-engorda';

      default:
        return label;
    }
  };

  const accumulatorsFlag = [];

  return (
    <>
      {stateMessage}

      <Row className="w-100">
        <Col xs={6}>
          <div className="inputDistribution livestockExepenses livesotckStock fixedHeader">
            {!!data?.length && columns() && (
              <>
                <div className="livestockExpensesGrid">
                  <DataGrid
                    onChange={async (dataGridInputs) => {
                      try {
                        if (parseFloat(dataGridInputs.value) > 100) {
                          const dataGridFactory = {
                            ...dataFactory,
                            value: parseFloat(dataGridInputs.value),
                            cost_center: {id: dataGridInputs.cost_center.id},
                            type: dataGridInputs.cost_center.type,
                            description: 'LIVESTOCK_EXPENSES',
                            id: dataGridInputs?.id || null,
                          };

                          if (dataGridInputs.value) {
                            const res = await createOrUpdateDataFull(dataGridFactory, state, createFinancials, changeFinancialsbyId, false);
                            setUpdate(!update);
                            return res;
                          }
                        } else {
                          setOpen('Inserir valor total de despesa, por centro de custo, de toda a safra. Valor mínimo 100');
                        }

                        return null;
                      } catch (error) {
                        console.error(error);
                        return error;
                      }
                    }}
                    header={columns()}
                    data={data}
                    keyProp="key"
                    keyValueProp="valueKey"
                    titleProp="name"
                    fixedColumn={colFixed || null}
                    editable=""
                    disabledPaginate
                    width="100%"
                    actions={actions}
                    accumulators={{
                      '': {
                        hideTitle: true,
                        columns: ['value'],
                        itemOperation: (acc, line, key) => {
                          if (line.id && !accumulatorsFlag.includes(line.id)) {
                            accumulatorsFlag.push(line.id);
                            return (acc += line[key]);
                          }
                          return acc;
                        },
                        resultOperation: (acc) => {
                          if (acc < 0) {
                            return '----';
                          }
                          return maskInput({value: acc, type: MASKS.MONEY});
                        },
                      },
                    }}
                  />
                </div>
                <div className="mt-4">
                  <h5 className="text-center title">DISTRIBUIÇÃO INSUMOS REBANHO</h5>
                  <InputDistribution />
                </div>
                {/* <div className="total-expenses mt-4">
                  <DataGrid
                    onChange={async (dataGridInputs) => {
                      try {
                        const dataGridFactory = {
                          ...dataFactory,
                          value: parseFloat(dataGridInputs.value),
                          cost_center: {id: dataGridInputs.cost_center.id},
                          type: dataGridInputs.cost_center.type,
                          description: 'LIVESTOCK_EXPENSES',
                          id: dataGridInputs?.id || null,
                        };
                        const res = await createOrUpdateDataFull(
                          dataGridFactory,
                          state,
                          createFinancials,
                          changeFinancialsbyId,
                          false,
                        );
                        setUpdate(!update);
                        return res;
                      } catch (error) {
                        console.error(error);
                        return error;
                      }
                    }}
                    header={columns(data, dataTotalExpenses)}
                    data={dataTotalExpenses}
                    keyProp="key"
                    keyValueProp="valueKey"
                    titleProp="name"
                    fixedColumn={fixedColumTotalExpenses || null}
                    editable=""
                    disabledPaginate
                    width="100%"
                    actions={actions}
                  /> */}
                {/* </div> */}
              </>
            )}
          </div>
        </Col>
        <Col xs={6}>
          {farmDetail && (
            <>
              {!farmDetail?.category_livestock_system?.title && (
                <small className="msg-error-livestock">
                  Necessário definir Sistema pecuária na tela <Link to="/diagnostico/detalhes-fazenda">Caracteriza Fazenda</Link>
                </small>
              )}
              <div className="mt-4 supportExpenses">
                <DataGrid
                  onChange={async () => {}}
                  header={supportTable(getLivestockSystem(farmDetail?.category_livestock_system?.title || ''))}
                  data={dataSupport}
                  keyProp="key"
                  keyValueProp="valueKey"
                  titleProp="name"
                  editable=""
                  disabledPaginate
                  width="100%"
                  actions={[]}
                  accumulators={{
                    Total: {
                      hideTitle: false,
                      columns: ['calculed', 'cria', 'recria-engorda', 'cria-recria-engorda'],
                      itemOperation: (acc, line, key) => {
                        return (acc += line[key]);
                      },
                      resultOperation: (acc) => {
                        return maskInput({value: acc, type: MASKS.MONEY});
                      },
                    },
                  }}
                />
              </div>

              <DialogActions>
                <Button
                  color="primary"
                  autoFocus
                  onClick={async () => {
                    await fetchSupportMaterial('/support_material_diagnosis/cost_menu');
                  }}>
                  Apoio - Menu de Despesas
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  onClick={async () => {
                    await fetchSupportMaterial('/support_material_diagnosis/cost');
                  }}>
                  <i className="fas fa-download mr-3" />
                  Apoio - Despesas
                </Button>
              </DialogActions>
            </>
          )}
        </Col>
        <Dialog open={!!open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Erro</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{open}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Row>
    </>
  );
};

export default LivestockExpenses;
