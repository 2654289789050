import IMask from 'imask';

const validationFindData = (data) => {
  let validated = false;
  data.forEach((row) => {
    validated = validated && row.validated;
  });

  return validated;
};

const columns = [
  {
    key: 'reference_col',
    field: 'reference_col',
    name: 'Referências',
    disableFilter: true,
    subFields: [
      {
        key: 'category_reference',
        disableFilter: true,
        valueKey: 'category_reference.title',
        field: 'category_reference',
        name: 'Nome',
        textAlign: 'Center',
        groupTitle: (data) => {
          return `Referência: ${data}`;
        },
      },
      {
        key: 'category_weaning',
        disableFilter: true,
        valueKey: 'category_weaning.title',
        field: 'category_weaning',
        name: 'Categorias',
        textAlign: 'Center',
        disabledEdit: true,
      },
    ],
  },
  {
    key: 'amount',
    field: 'amount',
    disableFilter: true,
    name: 'Quantidade',
    textAlign: 'Center',
    editType: 'numericedit',
    accumulatorItem: (acc, item) => acc + item,
    extraParams: {
      minValue: 1,
    },
  },
  {
    key: 'weight',
    field: 'weight',
    disableFilter: true,
    name: 'Peso',
    // required: true,
    textAlign: 'Center',
    editType: 'weightedit',
    accumulatorItem: {
      Total: {
        columns: ['amount', 'weight'],
        itemOperation: (acc, line, key) => (acc += line[key] || 0),
        resultOperation: (acc, key) => {
          return key === 'weight'
            ? IMask.createMask({
                mask: 'number',
                blocks: {
                  number: {
                    mask: Number, // enable number mask

                    // other options are optional with defaults below
                    scale: 2, // digits after point, 0 for integers
                    signed: false, // disallow negative
                    thousandsSeparator: '.', // any single char
                    padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                    normalizeZeros: true, // appends or removes zeros at ends
                    radix: ',', // fractional delimiter

                    // additional number interval options (e.g.)
                    min: 0,
                    max: 999999999999,
                  },
                },
              }).resolve(acc.toString())
            : acc;
        },
      },
      Ponderado: {
        generalOnly: true,
        columns: ['amount', 'weight'],
        itemOperation: (acc, line, key) => {
          if (!acc) {
            return {sum: line[key], quantity: 1};
          }

          return {
            sum: acc.sum + line[key],
            quantity: (acc.quantity += 1),
          };
        },
        resultOperation: (acc) => {
          const value = IMask.createMask({
            mask: 'number',
            blocks: {
              number: {
                mask: Number, // enable number mask

                // other options are optional with defaults below
                scale: 2, // digits after point, 0 for integers
                signed: false, // disallow negative
                thousandsSeparator: '.', // any single char
                padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                normalizeZeros: true, // appends or removes zeros at ends
                radix: ',', // fractional delimiter

                // additional number interval options (e.g.)
                min: 0,
                max: 999999999999,
              },
            },
          }).resolve((acc.sum / acc.quantity).toString());
          return value;
        },
      },
    },
  },
  {
    key: 'validated',
    field: 'validated',
    disableFilter: true,
    name: 'Validar',
    textAlign: 'Center',
    editType: 'booleanedit',
    validationHeader: {showLabel: false, validationFindData},
  },
];

export default columns;
