import React, {useState, useContext, useRef} from 'react';
import {ButtonGroup, Button} from '@material-ui/core';
import HeaderMenu from '../HeaderMenu';
import HeaderMenuItem from '../HeaderMenuItem';
import CheckboxFilter from './CheckboxFilter';
import {numericButtons, textButtons} from './filterButtonTypes';
import FilterConfigurator from './FilterConfigurator';
import DataGridContext from '../DataGridContext';
import {clearFilters, changeFiltersCheckbox} from '../dataGridActions';
import {CenterButtons} from './styled';
import {toCamel, getFilterType} from '../../../utils/utils';

const MenuFilter = ({setSort, headerItem, prefetchedValues}) => {
  const [showConfigurator, setShowConfigurator] = useState(false);
  const [handleClose, setHandleClose] = useState(false);
  const {state, dispatch} = useContext(DataGridContext);
  const checkboxRef = useRef(null);
  // console.log(prefetchedValues);
  const initialPrefetchedValues = React.useMemo(() => {
    // if (getFilterType(headerItem.editType) !== 'date') {
    //   return prefetchedValues;
    // }
    // console.log(prefetchedValues);
    return prefetchedValues;
  }, [prefetchedValues]);

  const generateButtons = () => {
    const hasClassActive =
      state.filters && state.filters[toCamel(headerItem.field)] && Object.keys(state.filters[toCamel(headerItem.field)]).length ? 'filter-active' : '';
    const buttons = [
      {
        title: 'Ordem crescente',
        icon: 'fas fa-long-arrow-alt-up',
        onClick: () => {
          setSort({field: toCamel(headerItem.field), order: 'asc'});
        },
      },
      {
        title: 'Ordem decrescente',
        icon: 'fas fa-long-arrow-alt-down',
        onClick: () => {
          setSort({field: toCamel(headerItem.field), order: 'desc'});
        },
      },
      {
        title: 'Filtrar',
        class: hasClassActive,
        options: [
          {
            title: 'Limpar Filtro',
            class: hasClassActive,
            icon: 'fas fa-times-circle',
            onClick: () => {
              dispatch(clearFilters(toCamel(headerItem.field), {}));
            },
          },
        ],
      },
    ];

    if (getFilterType(headerItem.editType) === 'numeric') {
      buttons[2].options.push(
        numericButtons((label) =>
          setShowConfigurator((prev) => {
            if (!prev) {
              return label || true;
            }

            return !prev;
          }),
        ),
      );
    } else if (getFilterType(headerItem.editType) === 'text') {
      buttons[2].options.push(
        textButtons((label) =>
          setShowConfigurator((prev) => {
            if (!prev) {
              return label || true;
            }

            return !prev;
          }),
        ),
      );
    }

    return buttons;
  };
  const sortOptions = (a, b) => {
    if (typeof a.value === 'object') {
      if (a.value.label > b.value.label) {
        return 1;
      }
      if (a.value.label < b.value.label) {
        return -1;
      }
    } else {
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
    }

    // a must be equal to b
    return 0;
  };

  return (
    <div>
      <HeaderMenu handleCloseAction={handleClose}>
        {generateButtons().map((btn) => (
          <HeaderMenuItem key={btn.title} title={btn.title} cssClass={btn.class} icon={btn.icon} onClick={btn.onClick || null} options={btn.options || null} />
        ))}

        {initialPrefetchedValues && initialPrefetchedValues[headerItem.field] && (
          <CheckboxFilter
            ref={checkboxRef}
            initialPrefetchedValues={initialPrefetchedValues[headerItem.field].sort(sortOptions)}
            prefetchedValues={prefetchedValues[headerItem.field].sort(sortOptions)}
          />
        )}
        <CenterButtons>
          <ButtonGroup
            variant="contained"
            style={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'flex-end',
              boxShadow: 'none',
            }}>
            <Button
              color="primary"
              onClick={() => {
                let uncheckedCheckboxes;
                if (headerItem?.editType === 'datepicker') {
                  uncheckedCheckboxes = checkboxRef.current.querySelectorAll('input[type=checkbox]:not(:checked)');
                } else {
                  uncheckedCheckboxes = checkboxRef.current.querySelectorAll('input[type=checkbox]:checked');
                }

                const uncheckedCheckboxNames = Object.keys(uncheckedCheckboxes).map((key) => {
                  return uncheckedCheckboxes[key].name;
                });
                // console.log(uncheckedCheckboxNames);
                dispatch(changeFiltersCheckbox(toCamel(headerItem.field), uncheckedCheckboxNames));
                setHandleClose((prev) => !prev);
              }}>
              Ok
            </Button>
            <Button
              onClick={() => {
                setHandleClose((prev) => !prev);
              }}>
              Cancelar
            </Button>
          </ButtonGroup>
        </CenterButtons>
      </HeaderMenu>
      <FilterConfigurator
        openFromMenu={showConfigurator}
        changeOpenMenu={setShowConfigurator}
        headerItem={headerItem}
        filterType={getFilterType(headerItem.editType)}
      />
    </div>
  );
};

export default MenuFilter;
