import apiNew from '../apiNew';

const fetchCategoryConfinement = (params = {}) => {
  return apiNew.get(`/category_confinement`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryConfinementbyId = (id, params = {}) => {
  return apiNew.get(`/category_confinement/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryConfinement = (body = {}) => {
  return apiNew.post(`/category_confinement`, body);
};

const changeCategoryConfinementbyId = (id, body = {}) => {
  return apiNew.put(`/category_confinement/${id}`, body);
};

const deleteCategoryConfinement = (id) => {
  return apiNew.delete(`/category_confinement/${id}`);
};

export {fetchCategoryConfinement, fetchCategoryConfinementbyId, registerCategoryConfinement, changeCategoryConfinementbyId, deleteCategoryConfinement};
