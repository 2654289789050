/* eslint-disable radix */
import React, {useState} from 'react';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FilterHarvest = ({
  disabled,
  list,
  filterLivestock,
  selectedHarvest,
  selectedLiveStock,
}) => {
  const [value, setValue] = useState(null);
  const [valueLiveStock, setValueLivestock] = useState(null);
  const handleChange = (event) => {
    selectedHarvest(parseInt(event.target.value));
    setValue(parseInt(event.target.value));
  };

  const handleChangeLivestock = (event) => {
    selectedLiveStock(parseInt(event.target.value));
    setValueLivestock(parseInt(event.target.value));
  };

  return (
    <FilterHarvestContainer>
      {list && list.length > 0 && (
        <div>
          <span className="labelfilter">Seleciona a safra</span>
          <RadioGroup
            aria-label="harvest"
            value={value || list[0].id}
            name="harvest"
            onChange={handleChange}>
            {list.map((item, i) => (
              <FormControlLabel
                key={item.id}
                className={`${
                  item.id === value ||
                  (item.id === list[0].id && value === null)
                    ? 'active'
                    : ''
                }  ${i === list.length - 1 ? 'border-end' : ''} ${
                  i === 0 ? 'border-start' : ''
                }`}
                value={item.id}
                control={<Radio disabled={disabled} />}
                label={item.title}
              />
            ))}
          </RadioGroup>
        </div>
      )}
      {filterLivestock && filterLivestock.length > 0 && (
        <div>
          <span className="labelfilter">Sistema Pecuário</span>
          <RadioGroup
            aria-label="harvest"
            value={valueLiveStock || filterLivestock[0].id}
            name="harvest"
            onChange={handleChangeLivestock}>
            {filterLivestock.map((item, i) => (
              <FormControlLabel
                key={item.id}
                className={`${
                  item.id === valueLiveStock ||
                  (item.id === filterLivestock[0].id && valueLiveStock === null)
                    ? 'active'
                    : ''
                }  ${i === filterLivestock.length - 1 ? 'border-end' : ''} ${
                  i === 0 ? 'border-start' : ''
                }`}
                value={item.id}
                control={<Radio disabled={disabled} />}
                label={item.title}
              />
            ))}
          </RadioGroup>
        </div>
      )}
    </FilterHarvestContainer>
  );
};

const FilterHarvestContainer = styled.div`
  -webkit-print-color-adjust: exact;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  .labelfilter {
    -webkit-print-color-adjust: exact;
    color: #1eb7ad;
    font-size: 12px;
    font-weight: 400;
  }
  .MuiFormGroup-root {
    flex-direction: row;
  }
  .MuiFormControlLabel-root {
    -webkit-print-color-adjust: exact;
    background-color: #dddd;
    margin: 0;
    padding: 5px 10px;
    text-transform: uppercase;
    &.active {
      -webkit-print-color-adjust: exact;
      background-color: #1fb7ad;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      transition: all 120ms ease-out;
    }
    &.border-start {
      border-radius: 3px 0 0 3px;
    }
    &.border-end {
      border-radius: 0 3px 3px 0;
    }
  }
  .MuiFormControlLabel-root .MuiRadio-root {
    padding: 0px;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #ffffff;
  }

  .MuiRadio-colorSecondary.Mui-checked + span {
    color: #fff;
  }
`;

export default FilterHarvest;
