import {DeathServices} from './DeathServices';
import {ServicesHelper} from '../../services/ServicesHelper';

const PARAMS = {
  environment: 'DAIRY_CATTLE',
};

export const SalesServices = {
  endpoint: '/herd_departure',

  list: async (params = {}) => ServicesHelper.list(SalesServices.endpoint, {...params, ...PARAMS}),

  post: async (body = {}) => ServicesHelper.post(SalesServices.endpoint, {...body, ...PARAMS}),

  put: async (id, body = {}) => ServicesHelper.put(SalesServices.endpoint.concat('/', id), {...body, ...PARAMS}),

  delete: async (id) => ServicesHelper.delete(SalesServices.endpoint.concat('/', id)),

  categoryAnimal: async (params = {}) => DeathServices.categoryAnimal(params),
};
