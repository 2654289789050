import React, {useContext, useRef, useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {slideOutRight, slideInRight} from 'react-animations';
import * as Yup from 'yup';
import {DataContext} from '../../../utils/DataProvider';
import './index.scss';
import {normalizeCollectionData} from '../../../utils/utils';
import {FILTER} from '../../../utils/constants';
import {useInitialRenderEffect} from '../../../utils/functions';
import {fetchCustomers} from '../../../services/customers';
import {fetchHarvestsFilter} from '../../../services/harvests';
import {fetchFarmsDetailsbyCustomerIdAndByHarvestId} from '../../../services/farmsDetails';
import GenerateForm from '../../GenerateForm';
import Alert from '../../Notification';

const PrimaryFilterForm = () => {
  const ref = useRef(null);
  // const referrer = location.state ? location.state.referrer : null;
  const [slideOut, setSlideOut] = useState(false);
  const {state, dispatch} = useContext(DataContext);

  const [currentCustomerId, setCurrentCustomerId] = useState('');
  const [currentHarvestId, setCurrentHarvestId] = useState('');
  const [currentFarmId, setCurrentFarmId] = useState('');

  const [customers, setCustomers] = useState([]);
  const [harvests, setHarvests] = useState([]);
  const [farms, setFarms] = useState([]);
  const [hasFarms, setHasFarms] = useState(true);

  const [dataForm, setDataForm] = useState({});
  const [disableFields, setDisableFields] = useState({
    customer: false,
    harvest: !currentHarvestId,
    farm: !currentFarmId,
  });

  const loadCustomers = async () => {
    try {
      const {data} = await fetchCustomers({'order[name]': 'ASC'});
      setCustomers(data.resultList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const loadHarvests = async (params) => {
    try {
      const {data} = await fetchHarvestsFilter(params);
      setHarvests(data.resultList || []);
      // setDisableFields(Object.assign(disableFields, {harvest: false}));
      if (!data.resultList.length) {
        setCurrentHarvestId('');
      }
      setDataForm((prev) => {
        return {...prev, ...{harvest: currentHarvestId || null}};
      });
    } catch (e) {
      console.error(e);
    }
  };
  const loadFarms = async (customerId, harvestId, params = {}) => {
    try {
      const {data} = await fetchFarmsDetailsbyCustomerIdAndByHarvestId(customerId, harvestId && harvestId.value ? harvestId.value : harvestId, params);
      const farmsDetail = data.resultList.filter((farmDetail) => farmDetail.name || farmDetail.farm.name).map((farmDetail) => farmDetail.farm);
      setFarms(farmsDetail);
      if (farmsDetail.length) {
        setHasFarms(true);
      } else {
        setHasFarms(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useInitialRenderEffect(() => {
    setCurrentCustomerId(state.customer && state.customer.id ? {label: state.customer.name, value: state.customer.id} : '');
    setCurrentHarvestId(state.harvest && state.harvest.id ? {label: state.harvest.title, value: state.harvest.id} : '');
    setCurrentFarmId(state.farm && state.farm.id ? {label: state.farm.name, value: state.farm.id} : '');

    loadCustomers();
  });

  useEffect(() => {
    if (dataForm.customer || currentCustomerId) {
      loadHarvests({
        customer_id: dataForm.customer && dataForm.customer ? dataForm.customer : currentCustomerId.value,
      });
    }
  }, [dataForm.customer, currentCustomerId]);// eslint-disable-line

  useEffect(() => {
    if (dataForm.harvest || currentCustomerId) {
      loadFarms(
        dataForm && dataForm.customer ? dataForm.customer : currentCustomerId.value,
        dataForm && dataForm.harvest ? dataForm.harvest : currentHarvestId.value,
      );
    }
  }, [dataForm.harvest, currentHarvestId]);// eslint-disable-line

  // console.log(currentFarmId, dataForm);
  const getOption = (list, value) => {
    let temp = null;
    list.forEach((item) => {
      if (item.id === value.value) {
        temp = value;
      }
    });
    return temp;
  };
  useEffect(() => {
    setDisableFields({
      customer: false,
      harvest: harvests && harvests.length ? false : !currentHarvestId,
      farm: !currentFarmId,
    });
  }, [currentCustomerId, currentHarvestId, currentFarmId]);// eslint-disable-line

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Cliente',
          name: 'customer',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione o cliente',
          value: dataForm.customer || currentCustomerId,
          currentValue: currentCustomerId,
          required: true,
          disabled: disableFields.customer,
          validations: {
            customer: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(customers, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {farm: null}));
              setDataForm((prev) => Object.assign(prev, {customer: data.value}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: !dataForm.harvest,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {customer: ''}));
              setDisableFields({
                customer: false,
                harvest: true,
                farm: !dataForm.harvest,
              });
            }
          },
          columns: {
            xs: 12,
          },
        },
        {
          label: 'Safra',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a safra',
          value: harvests.length ? dataForm.harvest || currentHarvestId : '',
          currentValue: currentHarvestId,
          required: true,
          disabled: disableFields.harvest,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {harvest: data.value}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {harvest: ''}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: true,
              });
            }
          },
          options: normalizeCollectionData(harvests, [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
        {
          hideField: !hasFarms,
          label: 'Fazenda',
          name: 'farm',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione a fazenda',
          value: farms.length === 1 ? {label: farms[0].name, value: farms[0].id} : dataForm.farm || getOption(farms, currentFarmId) || null,
          currentValue: farms.length === 1 ? {label: farms[0].name, value: farms[0].id} : getOption(farms, currentFarmId) || null,
          required: hasFarms,
          disabled: !hasFarms || !dataForm.customer || !dataForm.harvest,
          validations: {
            farm: hasFarms ? Yup.string('Somente letras').required('Campo obrigatório') : Yup.string('Somente letras'),
          },
          onChange: (data) => {
            if (data) {
              setDataForm((prev) => Object.assign(prev, {farm: data}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            } else {
              setDataForm((prev) => Object.assign(prev, {farm: ''}));
              setDisableFields({
                customer: false,
                harvest: false,
                farm: false,
              });
            }
          },

          options: normalizeCollectionData(farms, [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          columns: {
            xs: 12,
          },
        },
      ],
    },
  ];

  const onSubmit = async (values, actions) => {
    Object.keys(values).map((item) => {
      if (values[item] && values[item].value) {
        return Object.assign(values, {[item]: values[item].value});
      }
      return true;
    });

    try {
      actions.setSubmitting(true);
      const customerFound = customers.filter((customer) => customer.id === values.customer) || [];
      const harvestFound = harvests.filter((harvest) => harvest.id === values.harvest) || [];
      const farmFound =
        farms.find((farm) => (farm.id === dataForm && dataForm.farm && dataForm.farm.value ? dataForm.farm.value : null)) ||
        farms.filter((farm) => farm.id === values.farm) ||
        [];

      actions.setSubmitting(false);

      setSlideOut((prev) => !prev);
      dispatch({
        type: FILTER.ACTION.SAVE_DATA,
        payload: {
          ...state,
          customer: customerFound[0] || {},
          harvest: harvestFound[0] || {},
          farm: farmFound[0] || {
            name: 'Nenhuma fazenda caracterizada para essa safra.',
          },
        },
      });
      // if (referrer) {
      //   history.push(referrer);
      // } else {
      //   history.push('/');
      // }
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      {!hasFarms && <Alert type="warning" message="Nenhuma fazenda caracterizada para essa safra." time={4000} />}
      <Card className={`row filter-container ${slideOut ? 'slideOut' : ''}`} style={{transform: 'translateX(0)'}} ref={ref}>
        <div className="col-4 offset-4 filter-content">
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields}
            onBack={() => {
              dispatch({
                type: FILTER.ACTION.CLOSE,
              });
            }}
          />
        </div>
      </Card>
    </>
  );
};

const SlideOut = keyframes`${slideOutRight}`;
const SlideIn = keyframes`${slideInRight}`;
const Card = styled.div`
  div {
    font-size: 12px;
    font-weight: 200;
  }
  label {
    font-size: 13px;
  }
  button {
    font-size: 14px;
  }
  animation: 1s ${SlideIn};
  &.slideOut {
    animation: 1s ${SlideOut};
  }
`;

export default PrimaryFilterForm;
