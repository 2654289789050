import api from '../api';

const fetchInitialAnalysisArguments = (params = {}) => {
  return api.get(`/initial_analysis_arguments`, {
    params: {
      ...params,
    },
  });
};

const fetchInitialAnalysisArgumentsbyId = (id, params = {}) => {
  return api.get(`/initial_analysis_arguments/${id}`, {
    params: {
      ...params,
    },
  });
};

const changeInitialAnalysisArgumentsbyId = (id, body = {}) => {
  return api.put(`/initial_analysis_arguments/${id}`, body);
};

const registerInitialAnalysisArguments = (body = {}) => {
  return api.post(`/initial_analysis_arguments`, body);
};

const deleteInitialAnalysisArguments = (id) => {
  return api.delete(`/initial_analysis_arguments/${id}`);
};

export {
  fetchInitialAnalysisArguments,
  fetchInitialAnalysisArgumentsbyId,
  changeInitialAnalysisArgumentsbyId,
  registerInitialAnalysisArguments,
  deleteInitialAnalysisArguments,
};
