import React, {useState, useEffect, useContext} from 'react';
import {FormControl, FormGroup, FormLabel, TextField, CircularProgress, Radio, FormControlLabel, RadioGroup, ButtonGroup, Button} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {CustomFilter} from './styled';
import TransitionsModal from '../../Modal';
import {changeFilters} from '../dataGridActions';
import DataGridContext from '../DataGridContext';
import {toCamel} from '../../../utils/utils';

const FilterConfigurator = ({openFromMenu, changeOpenMenu, headerItem, filterType}) => {
  // const [dataForm, setDataForm] = useState({});
  const [loading] = useState(false);
  const {state, dispatch} = useContext(DataGridContext);
  const [open, setOpen] = useState(openFromMenu);
  const [dataForm, setDataForm] = useState(() => {
    if (state && state.filters && state.filters[headerItem.field]) {
      return state.filters[headerItem.field];
    }

    return {joinOperation: {label: 'E', value: 'and'}};
  });

  useEffect(() => {
    if (!dataForm.opration1) {
      setDataForm({...dataForm, ...{operation1: openFromMenu}});
    }
    setOpen(!!openFromMenu);
  }, [openFromMenu]);// eslint-disable-line
  useEffect(() => {
    if (!!openFromMenu !== open) {
      changeOpenMenu(open);
    }
  }, [open]);// eslint-disable-line

  const optionDiscriminator = () => {
    if (filterType === 'numeric' || filterType === 'date') {
      return [
        {label: 'Igual', value: '='},
        {label: 'Não Igual', value: '<>'},
        {label: 'Menor Que', value: '<'},
        {label: 'Menor ou igual', value: '<='},
        {label: 'Maior que', value: '>'},
        {label: 'Maior ou igual', value: '>='},
      ];
    }
    return [
      {label: 'Igual', value: '='},
      {label: 'Não Igual', value: '<>'},
      {label: 'Contém', value: 'contains'},
    ];
  };

  return (
    <TransitionsModal open={!!openFromMenu} setOpen={setOpen}>
      <CustomFilter>
        <FormLabel component="legend">Mostrar Linhas onde:</FormLabel>
        <FormControl component="fieldset" style={{width: '100%'}}>
          <FormLabel component="legend">{headerItem.name}</FormLabel>
          <FormGroup
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Autocomplete
              style={{
                width: '42%',
              }}
              id="#first-operation"
              name="operation1"
              options={optionDiscriminator()}
              getOptionLabel={(option) => option.label || ''}
              onChange={(e, newValue) => {
                setDataForm({...dataForm, ...{operation1: newValue}});
              }}
              loading={loading}
              defaultValue={openFromMenu}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <TextField
              type={filterType === 'text' ? 'text' : 'number'}
              onChange={(e) => {
                setDataForm({...dataForm, ...{value1: e.currentTarget.value}});
              }}
              placeholder="Digite o valor"
              name="value1"
              variant="outlined"
            />
          </FormGroup>
          <RadioGroup
            row
            defaultValue="and"
            onChange={(e, newValue) => {
              setDataForm({...dataForm, ...{joinOperation: {value: newValue}}});
            }}>
            <FormControlLabel value="and" control={<Radio color="primary" />} label="E" />
            <FormControlLabel value="or" control={<Radio color="primary" />} label="Ou" />
          </RadioGroup>
          <FormGroup
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Autocomplete
              style={{
                width: '42%',
              }}
              id="#second-operation"
              name="operation2"
              options={optionDiscriminator()}
              getOptionLabel={(option) => option.label || ''}
              onChange={(e, newValue) => {
                setDataForm({...dataForm, ...{operation2: newValue}});
              }}
              loading={loading}
              getOptionSelected={(option, {multiple, value}) => {
                if (!multiple) {
                  return option.value === value;
                }
                return false;
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <TextField
              type={filterType === 'text' ? 'text' : 'number'}
              onChange={(e) => {
                setDataForm({...dataForm, ...{value2: e.currentTarget.value}});
              }}
              name="value2"
              placeholder="Digite o valor"
              variant="outlined"
            />
          </FormGroup>
          <ButtonGroup
            variant="contained"
            style={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'flex-end',
              boxShadow: 'none',
            }}>
            <Button
              color="primary"
              onClick={() => {
                setDataForm(Object.assign(dataForm, {originalKey: headerItem.field}));
                dispatch(
                  changeFilters(toCamel(headerItem.field), {
                    ...dataForm,
                    ...{type: filterType},
                  }),
                );
                setOpen(false);
              }}>
              Ok
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}>
              Cancelar
            </Button>
          </ButtonGroup>
        </FormControl>
      </CustomFilter>
    </TransitionsModal>
  );
};

export default FilterConfigurator;
