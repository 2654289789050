/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage, Form, Formik} from 'formik';
import {Col, Row} from 'reactstrap';
import {mapInitialValues} from '../../utils/mapInitialValues';
import {mapValidations} from '../../utils/mapValidations';
import MakeField from './MakeField';
import {hasProperty} from '../../utils/functions';
import './styles.scss';

const GenerateForm = ({groupFields, onSubmit, settings, showBackButton = false, onBack = () => {}}) => {
  const getSettings = (type) => {
    switch (type) {
      case 'button.text':
        if (hasProperty(settings, 'button') && hasProperty(settings.button, 'text')) return settings.button.text;
        return 'Aplicar';
      case 'button.textSubmitting':
        if (hasProperty(settings, 'button') && hasProperty(settings.button, 'textSubmitting')) return settings.button.textSubmitting;
        return 'Gerando...';
      case 'back_button.text':
        if (hasProperty(settings, 'back_button') && hasProperty(settings.back_button, 'text')) return settings.back_button.text;
        return 'Voltar';
      default:
        return '';
    }
  };

  const isDropdownState = (dropdownState) => {
    if (dropdownState == null) return '';

    return dropdownState === true ? 'form-group-dropdown in' : 'form-group-dropdown out';
  };

  return (
    <Formik
      enableReinitialize
      initialValues={mapInitialValues(groupFields)}
      validationSchema={mapValidations(groupFields)}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}>
      {({isSubmitting, ...actions}) => (
        <Form>
          {groupFields.map(({name, component: Component, ...group}, index) => (
            <div key={`group-${group.key ? group.key : group.name}-${index}`} className="group-field" id={`group-${index}`}>
              {name !== '' && !Component && (
                <label className="mb-3" htmlFor={`group-${index}`}>
                  <b>{group.key ? group.key : name}</b>
                </label>
              )}

              {name && Component && (
                <Component>
                  <label className="mb-3" htmlFor={`group-${index}`}>
                    <b>{group.key ? group.key : name}</b>
                  </label>
                </Component>
              )}
              <Row className={isDropdownState(group.dropdownState)}>
                {group.fields.map((field) =>
                  !Array.isArray(field) ? (
                    <React.Fragment key={`field-${field.name}-col`}>
                      {!field.hideField && (
                        <Col xs={hasProperty(field.columns) ? field.columns.xs : 12} {...field.columns}>
                          <div className="element-field">
                            {field.hasPlaceholder === false && (
                              <label className="label-title" htmlFor={field.name}>
                                {field.label}
                              </label>
                            )}
                            <MakeField {...field} {...actions} />
                            <ErrorMessage className="error-msg" component="span" name={field.name} />
                          </div>
                        </Col>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={`field-${field[0].name}-col`}>
                      {!field[0].hideField && (
                        <Col xs={hasProperty(field[0].columns) ? field[0].columns.xs : 12} {...field[0].columns}>
                          <div className="element-field element-field-multiple">
                            {field[0].hasPlaceholder === false && (
                              <label className="label-title" htmlFor={field[0].name}>
                                {field[0].label}
                              </label>
                            )}
                            {field.map((fieldArray, i) => (
                              <MakeField {...fieldArray} key={`${field[0].key}-${i}` || `${fieldArray.name}-${i}`} />
                            ))}
                            <ErrorMessage className="error-msg" component="span" name={field[0].name} />
                          </div>
                        </Col>
                      )}
                    </React.Fragment>
                  ),
                )}
              </Row>
            </div>
          ))}
          <div className="footer-buttons">
            {!isSubmitting && showBackButton && (
              <button type="button" onClick={onBack} className="btn btn-primary">
                {getSettings('back_button.text')}
              </button>
            )}
            {onSubmit && (
              <button type="submit" className={`btn btn-primary ${isSubmitting ? 'submitting' : ''}`}>
                {isSubmitting ? getSettings('button.textSubmitting') : getSettings('button.text')}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

GenerateForm.propTypes = {
  groupFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default GenerateForm;
