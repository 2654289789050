import React, {useState, useEffect} from 'react';
import {Bar, Pie} from 'react-chartjs-2';
import {Col, Row} from 'reactstrap';
import Indicator from '../DataPresenters/Indicator';
import {getDataChart, maskInput, colors} from '../../../utils/utils';
import {MASKS} from '../../../utils/constants';

const DashboardTeam = ({totalEmployees, pieChart, barChart}) => {
  // const [normalizedBarChartData, setNormalizedBarChartData] = useState(null);
  const [normalizedBarChartData, setNormalizedBarChartData] = useState(null);
  const [normalizedPieChartData, setNormalizedPieChartData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      // const dataBarChart = await getDataChart(null, null, barChart, false);
      const dataBarChart = await getDataChart(
        null,
        null,
        barChart,
        false,
        null,
        '#b2b2b2',
      );
      setNormalizedBarChartData(dataBarChart);
      const dataPieChart = await getDataChart(
        null,
        null,
        pieChart,
        true,
        null,
        colors,
      );

      setNormalizedPieChartData(dataPieChart);
    };

    loadData();
  }, [barChart]);// eslint-disable-line

  return (
    <div>
      {totalEmployees && (
        <Indicator
          icon="users"
          iconSize={4}
          color="#000"
          iconSide="right"
          iconColor="#000"
          iconBackgroundColor="#b2b2b2"
          backgroundColor="#b2b2b2"
          data={`${maskInput({
            type: MASKS.DOUBLE,
            value: `${totalEmployees}`,
          })}`}
          title="Total de Funcionários"
          subtitle="Quantidade"
        />
      )}
      <Row className="d-flex justify-content-center py-5">
        {normalizedBarChartData && (
          <Col xs={10} className="cards p-0">
            <h5 className="d-flex flex-column align-items-center">
              Funcionários por Frente de trabalho
            </h5>
            <Bar
              responsive
              label="Funcionários por Frente de trabalho"
              data={normalizedBarChartData}
              options={{
                legend: {
                  display: false,
                },
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 40,
                    bottom: 0,
                  },
                },
                plugins: {
                  datalabels: {
                    labels: {
                      title: {
                        font: {
                          size: 16,
                        },
                      },
                    },
                    anchor: (val) => {
                      if (val.dataset.data[val.dataIndex] >= 0) {
                        return 'end';
                      }
                      return 'start';
                    },
                    align: (val) => {
                      if (val.dataset.data[val.dataIndex] >= 0) {
                        return 'top';
                      }
                      return 'bottom';
                    },
                    formatter: (value) => {
                      return `${maskInput({
                        type: MASKS.DOUBLE,
                        value: `${value}`,
                      })}`;
                    },
                  },
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        fontSize: 15,
                      },
                      gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                      },
                      ticks: {
                        display: false,
                        stepSize: 1,
                        beginAtZero: true,
                        // Create scientific notation labels
                        callback: (value) => {
                          return `${maskInput({
                            type: MASKS.DOUBLE,
                            value: `${value}`,
                          })}`;
                        },
                      },
                    },
                  ],
                },
              }}
            />
          </Col>
        )}
      </Row>
      <Row className="d-flex justify-content-center py-5">
        {normalizedPieChartData && (
          <Col xs={10}>
            <h5 className="d-flex flex-column align-items-center">
              Média de salário por função
            </h5>
            <Pie
              responsive
              data={normalizedPieChartData}
              width={window.innerWidth < 767 ? '100%' : null}
              options={{
                legend: {
                  labels: {
                    fontSize: 16,
                  },
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                tooltips: {
                  callbacks: {
                    label: (tooltipItem, data) => {
                      return `R$ ${maskInput({
                        type: MASKS.DOUBLE,
                        value: `${data.datasets[0].data[tooltipItem.index]}`,
                      })}`;
                    },
                  },
                },
                pieceLabel: [
                  {
                    render: 'label',
                    fontColor: '#000',
                    position: 'outside',
                    fontSize: 16,
                    arc: false,
                  },
                  {
                    render: 'percentage',
                    fontSize: 16,
                    fontColor: '#000',
                  },
                ],
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DashboardTeam;
