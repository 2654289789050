import * as Yup from 'yup';

export class FormikHelper {
  #data;

  #initialValues;

  #fieldsWithValidations;

  constructor(data) {
    this.#data = data;
    this.#initialValues = {};
    this.#fieldsWithValidations = [];
  }

  static init(data) {
    return new FormikHelper(data);
  }

  createYupSchema(schema, data) {
    const {name, validations, validationType = 'string'} = data;
    if (!Yup[validationType]) return schema;

    let validator = Yup[validationType]();
    validations.forEach((validation) => {
      const {params, type} = validation;
      if (!validator[type]) return;
      validator = validator[type](...params);
    });
    schema[name] = validator;
    return schema;
  }

  validateSchema() {
    const yupSchema = this.#fieldsWithValidations.reduce(this.createYupSchema, {});
    return Yup.object().shape(yupSchema);
  }

  mapper() {
    this.#data.forEach((data) => {
      if (data.items) {
        data.items.forEach((item) => {
          if (item.columns) {
            item.columns.forEach((col) => {
              Object.assign(this.#initialValues, {
                [col.name]: col.value || '',
              });

              if (col.validations) {
                this.#fieldsWithValidations.push(col);
              }
            });
          } else {
            Object.assign(this.#initialValues, {
              [item.name]: item.value || '',
            });

            if (item.validations) {
              this.#fieldsWithValidations.push(item);
            }
          }
        });
      }
    });

    return {
      initialValues: this.#initialValues,
      validationSchema: this.validateSchema(),
    };
  }

  build() {
    return this.mapper();
  }
}
