import moment from 'moment';
import {fetchCostCenters} from '../../services/costCenters';
import {ROLES} from '../../utils/constants';

const validationFindData = (data) => {
  let validated = true;
  data.forEach((row) => {
    validated = validated && row.validated;
  });

  return validated;
};

const financialsColumns = ({costCenter}, {user}, state) => {
  const fieldTemp = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      required: true,
      textAlign: 'Center',
      width: '15%',
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        const [date] = line.date.split(' ');
        const [day, month, year] = date.split('/');

        if (!editMode) {
          // eslint-disable-next-line eqeqeq
          if (line.period === true) {
            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('MM/YYYY');
          }

          return moment(new Date(year, month - 1, day))
            .locale('pt-br')
            .format('DD/MM/YYYY');
        }

        return moment(new Date(year, month - 1, day));
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'description',
      field: 'description',
      required: true,
      name: 'Descrição do produto/serviço',
      textAlign: 'center',
      width: costCenter === 'receitas' ? '25%' : '30%',
      editType: 'defaultedit',
    },
    {
      key: 'cost_center',
      valueKey: 'cost_center.title',
      field: 'cost_center',
      required: true,
      name: 'Centro de custo',
      textAlign: 'center',
      width: costCenter === 'receitas' ? '25%' : '30%',
      editType: 'dropdownedit',
      optionsFetch: async () => {
        try {
          const response = await fetchCostCenters({
            type: costCenter.toUpperCase(),
            'order[title]': 'ASC',
          });
          const objectTemp = [];
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.title,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },

    {
      key: 'value',
      field: 'value',
      required: true,
      name: 'Valor (R$)',
      textAlign: 'Center',
      width: user.user_type !== ROLES.CUSTOMER ? '15%' : '25%',
      editType: 'moneyedit',
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Validar',
      textAlign: 'Center',
      width: '10%',
      editType: 'booleanedit',
      validationHeader: {showLabel: false, validationFindData},
    },
  ];

  if (costCenter === 'receitas') {
    return [
      ...fieldTemp.slice(0, 3),
      {
        key: 'from_confinement',
        field: 'from_confinement',
        valueKey: 'from_confinement',
        name: 'Confinamento ?',
        width: '10%',
        textAlign: 'Center',
        editType: 'booleanedit',
      },
      ...fieldTemp.slice(3, 5),
    ];
  }
  return fieldTemp;
};

export default financialsColumns;
