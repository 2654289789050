import apiNew from '../apiNew';

const fetchCategoryILPPercentage = (params = {}) => {
  return apiNew.get(`/category_ilp_percentage`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryILPPercentagebyId = (id, params = {}) => {
  return apiNew.get(`/category_ilp_percentage/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryILPPercentage = (body = {}) => {
  return apiNew.post(`/category_ilp_percentage`, body);
};

const changeCategoryILPPercentagebyId = (id, body = {}) => {
  return apiNew.put(`/category_ilp_percentage/${id}`, body);
};

const deleteCategoryILPPercentage = (id) => {
  return apiNew.delete(`/category_ilp_percentage/${id}`);
};

export {
  fetchCategoryILPPercentage,
  fetchCategoryILPPercentagebyId,
  registerCategoryILPPercentage,
  changeCategoryILPPercentagebyId,
  deleteCategoryILPPercentage,
};
