import React, {useEffect, useState} from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ptBRLocale from 'date-fns/locale/pt-BR';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'MMM yyyy', {locale: this.locale});
  }
}

const DatePicker = ({el, setMainValue}) => {
  const [selectedDate, setSelectedDate] = useState(el.initialYear);

  useEffect(() => {
    setMainValue(selectedDate);
  }, [selectedDate]);// eslint-disable-line

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ptBRLocale}>
      <KeyboardDatePicker
        margin="normal"
        inputVariant="outlined"
        name={el.name}
        id={el.name}
        key={el.key || el.name}
        format={el.format || 'dd/MM/yyyy'}
        views={el.view || ['date']}
        value={el.value || selectedDate}
        required={el.required || false}
        disabled={el.disabled || false}
        placeholder={el.placeholder || 'Selecione a data'}
        minDate={el.initialYear}
        maxDate={el.finalYear}
        okLabel="Ok"
        cancelLabel="Cancelar"
        onChange={(date) => {
          if (el.onChange) {
            el.onChange(date);
          }
          setSelectedDate(date);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
