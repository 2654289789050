/* eslint-disable camelcase */
// const validationFindData = (data, field) => {
//   let validated = true;
//   const colEmpty = data.filter((row) => {
//     if (field === 'initial_herd' && !row[field]?.amount && !row[field]?.weight) {
//       return true;
//     }
//     if (field === 'final_herd' && !row[field]?.amount && !row[field]?.weight) {
//       return true;
//     }
//     if (field === 'herd_entry' && !row[field]?.amount && !row[field]?.weight_death && !row[field]?.weight_alive && !row[field]?.arroba_value) {
//       return true;
//     }
//     if (field === 'herd_departure' && !row[field]?.amount && !row[field]?.weight_alive && !row[field]?.arroba_value) {
//       return true;
//     }
//     if (field === 'death' && !row[field]?.amount) {
//       return true;
//     }
//     if (field === 'birth' && !row[field]?.amount) {
//       return true;
//     }

//     return null;
//   });

//   data.forEach((row) => {
//     validated = validated && row[field].validated && colEmpty.length < 13;
//   });

//   return validated;
// };

const columns = (firstYear, lastYear, openModal) => [
  {
    key: 'initial_herd',
    valueKey: 'initial_herd',
    field: 'initial_herd',
    name: `Início da Safra - 01/07/${firstYear}`,
    textAlign: 'Center',
    // returnFormat: valueFormatter,
    // validationHeader: {validationFindData},
    editType: 'numericedit',
    subFields: [
      {
        tooltip: `Estoque Pecuário no dia 01/07/${firstYear}`,
        tooltipPosition: 'right',
        key: 'content',
        valueKey: 'initial_herd',
        field: 'content',
        name: 'Rebanho Inicial',
        textAlign: 'Center',
        disableFilter: true,
        editType: 'numericedit',
        subFields: [
          {
            tooltip: `Inserir total de cabeças, por categoria, no dia 01 de julho de ${firstYear}`,
            tooltipPosition: 'right',
            key: 'initial_herd.amount',
            valueKey: 'initial_herd.amount',
            field: 'amount',
            customKey: 'valueKey',
            width: '120px',
            name: 'Cabeças (qtd.)',
            textAlign: 'Center',
            editType: 'numericedit',
            disableFilter: true,
            disabledEdit: (col) => {
              if (col?.currentCel?.cel <= 3) {
                return false;
              }
              return true;
            },
            // extraParams: {
            //   minValue: 0,
            // },
          },
          {
            tooltip: `Inserir peso medio por animal dentro da categoria.\n\nO peso deve ser em kg vivos.`,
            tooltipPosition: 'right',
            key: 'initial_herd.weight',
            disableFilter: true,
            valueKey: 'initial_herd.weight',
            field: 'weight',
            name: 'Peso(kg)',
            width: '120px',
            extraParams: {
              maxValue: (dataRow, value) => {
                if (dataRow?.category_livestock?.title === 'Touros') {
                  if (value <= 1200) {
                    return value;
                  }
                  return 1200;
                }
                if (value <= 700) {
                  return value;
                }
                return 700;
              },
            },
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel <= 3) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    ],
  },
  {
    key: 'final_herd',
    valueKey: 'final_herd',
    field: 'final_herd',
    name: `Final da Safra - 30/06/${lastYear}`,
    textAlign: 'Center',
    // returnFormat: valueFormatter,
    // validationHeader: {validationFindData},
    editType: 'numericedit',
    subFields: [
      {
        tooltip: `Estoque Pecuário no dia 30/06/${lastYear}`,
        tooltipPosition: 'right',
        key: 'content',
        valueKey: 'final_herd',
        field: 'content',
        name: 'Rebanho Final',
        textAlign: 'Center',
        disableFilter: true,
        editType: 'numericedit',
        subFields: [
          {
            tooltip: `Inserir total de cabeças, por categoria, no dia 30 de junho de ${lastYear}`,
            tooltipPosition: 'right',
            key: 'final_herd.amount',
            valueKey: 'final_herd.amount',
            field: 'amount',
            customKey: 'valueKey',
            width: '120px',
            name: 'Cabeças (qtd.)',
            textAlign: 'Center',
            editType: 'numericedit',
            disableFilter: true,
            disabledEdit: (col) => {
              if (col?.currentCel?.cel <= 3) {
                return false;
              }
              return true;
            },
            // extraParams: {
            //   minValue: 0,
            // },
          },
          {
            tooltip: `Inserir peso medio por animal dentro da categoria.\n\nO peso deve ser em kg vivos.`,
            tooltipPosition: 'right',
            key: 'final_herd.weight',
            disableFilter: true,
            valueKey: 'final_herd.weight',
            field: 'weight',
            name: 'Peso(kg)',
            extraParams: {
              maxValue: (dataRow, value) => {
                if (dataRow?.category_livestock?.title === 'Touros') {
                  if (value <= 1200) {
                    return value;
                  }
                  return 1200;
                }
                if (value <= 700) {
                  return value;
                }
                return 700;
              },
            },
            width: '120px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel <= 3) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    ],
  },
  {
    key: 'herd_entry',
    valueKey: 'herd_entry',
    field: 'herd_entry',
    name: '',
    textAlign: 'Center',
    // returnFormat: valueFormatter,
    // validationHeader: {validationFindData},
    editType: 'numericedit',
    subFields: [
      {
        tooltip: `Todos os animais que entraram na fazenda, através de compras e transferências de entrada, 
        entre 01/07/${firstYear} e 30/06/${lastYear}.\n\nIncluir valor de comissões e fretes dos animais`,
        key: 'content',
        valueKey: 'herd_entry',
        field: 'content',
        name: 'ENTRADAS - Compras e transferências',
        textAlign: 'Center',
        disableFilter: true,
        editType: 'numericedit',
        subFields: [
          {
            tooltip: `1. Inserir total de cabeças, por categoria, que entraram na fazenda entre 01 de jul e 30 de jun;\n\n
            2. As entradas podem ser por compra ou por transferência de outra fazenda;\n\n
            3. Animais de transferência devem ser precificados a preço de mercado de acordo com a categoria.`,
            key: 'herd_entry.amount',
            valueKey: 'herd_entry.amount',
            field: 'amount',
            customKey: 'valueKey',
            width: '120px',
            name: 'Cabeças (qtd.)',
            textAlign: 'Center',
            editType: 'numericedit',
            disableFilter: true,
            extraParams: {
              minValue: 0,
            },
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 4 && col?.currentCel?.cel <= 6) {
                return false;
              }
              return true;
            },
          },
          {
            tooltip: `Inserir peso medio por animal dentro da categoria.\n\n
            O peso deve ser em kg vivos.`,
            key: 'herd_entry.weight_alive',
            disableFilter: true,
            valueKey: 'herd_entry.weight_alive',
            field: 'weight_alive',
            name: 'Peso Vivo(kg)',
            extraParams: {
              maxValue: (dataRow, value) => {
                if (dataRow?.category_livestock?.title === 'Touros') {
                  if (value <= 1200) {
                    return value;
                  }
                  return 1200;
                }
                if (value <= 700) {
                  return value;
                }
                return 777;
              },
              minValue: 0,
            },
            width: '120px',
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 4 && col?.currentCel?.cel <= 6) {
                return false;
              }
              return true;
            },
          },
          {
            tooltip: `Inserir valor medio, por arroba, dentro de cada categoria negociada.`,
            key: 'herd_entry.arroba_value',
            disableFilter: true,
            valueKey: 'herd_entry.arroba_value',
            field: 'arroba_value',
            name: 'Valor da @',
            width: '120px',
            customKey: 'valueKey',
            extraParams: {
              maxValue: (dataRow, value) => {
                if (dataRow?.category_livestock?.title === 'Touros' || dataRow?.category_livestock?.title === 'Matrizes em reprodução') {
                  if (value <= 2000) {
                    return value;
                  }
                  return 2000;
                }
                if (value <= 999) {
                  return value;
                }
                return 999;
              },
              minValue: 0,
            },
            textAlign: 'Center',
            editType: 'moneyedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 4 && col?.currentCel?.cel <= 6) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    ],
  },
  {
    key: 'herd_departure',
    valueKey: 'herd_departure',
    field: 'herd_departure',
    tooltip: `Inserir valor medio, por arroba, dentro de cada categoria negociada.`,
    name: '',
    textAlign: 'Center',
    // returnFormat: valueFormatter,
    // validationHeader: {validationFindData},
    editType: 'numericedit',
    subFields: [
      {
        tooltip: `Todos os animais que saíram da fazenda, entre 01/07/${firstYear} e 30/06/${lastYear}, 
        através de vendas em pé, abates, transferências, doações e consumo.`,
        key: 'content',
        valueKey: 'herd_departure',
        field: 'content',
        name: 'SAÍDAS - Abates, Vendas, Transferências, doações e Consumo',
        textAlign: 'Center',
        disableFilter: true,
        editType: 'numericedit',
        subFields: [
          {
            tooltip: `1. Inserir total de cabeças, por categoria, que entraram na fazenda entre 01 de jul e 30 de jun;\n\n
            2. As entradas podem ser por compra ou por transferência de outra fazenda;\n\n
            3. Animais de transferência devem ser precificados a preço de mercado de acordo com a categoria.`,
            key: 'herd_departure.amount',
            valueKey: 'herd_departure.amount',
            field: 'amount',
            customKey: 'valueKey',
            width: '120px',
            name: 'Cabeças (qtd.)',
            textAlign: 'Center',
            editType: 'numericedit',
            disableFilter: true,
            extraParams: {
              minValue: 0,
            },
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 7 && col?.currentCel?.cel <= 10) {
                document.getElementById('cancel').click();
                openModal(true);
                return false;
              }
              return true;
            },
          },
          {
            tooltip: `Inserir peso medio por animal dentro da categoria.\n\n
            O peso deve ser em kg vivos.`,
            key: 'herd_departure.weight_alive',
            disableFilter: true,
            valueKey: 'herd_departure.weight_alive',
            field: 'weight_alive',
            name: 'Peso Vivo(kg)',
            width: '120px',
            customKey: 'valueKey',
            textAlign: 'Center',
            extraParams: {
              maxValue: (dataRow, value) => {
                if (dataRow?.category_livestock?.title === 'Touros') {
                  if (value <= 1200) {
                    return value;
                  }
                  return 1200;
                }
                if (value <= 700) {
                  return value;
                }
                return 700;
              },
              minValue: 0,
            },
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 7 && col?.currentCel?.cel <= 10) {
                return false;
              }
              return true;
            },
          },
          {
            tooltip: `Inserir peso medio por animal dentro da categoria.\n\nO peso deve ser em kg de carcaça quente (morto).`,
            key: 'herd_departure.weight_death',
            disableFilter: true,
            valueKey: 'herd_departure.weight_death',
            field: 'weight_death',
            name: 'Peso Morto(kg)',
            width: '120px',
            customKey: 'valueKey',
            extraParams: {
              maxValue: (dataRow, value) => {
                if (dataRow?.category_livestock?.title === 'Touros') {
                  if (value <= 1200) {
                    return value;
                  }
                  return 1200;
                }
                if (value <= 400) {
                  return value;
                }
                return 400;
              },
              minValue: 0,
            },
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 7 && col?.currentCel?.cel <= 10) {
                return false;
              }
              return true;
            },
          },
          {
            tooltip: `Inserir valor medio, por arroba, dentro de cada categoria negociada`,
            key: 'herd_departure.arroba_value',
            disableFilter: true,
            valueKey: 'herd_departure.arroba_value',
            field: 'arroba_value',
            name: 'Valor da @',
            width: '120px',
            extraParams: {
              maxValue: (dataRow, value) => {
                if (dataRow?.category_livestock?.title === 'Touros' || dataRow?.category_livestock?.title === 'Matrizes em reprodução') {
                  if (value <= 2000) {
                    return value;
                  }
                  return 2000;
                }
                if (value <= 999) {
                  return value;
                }
                return 999;
              },
              minValue: 0,
            },
            customKey: 'valueKey',
            textAlign: 'Center',
            editType: 'weightedit',
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 7 && col?.currentCel?.cel <= 10) {
                return false;
              }
              return true;
            },
          },
        ],
      },
    ],
  },
  {
    key: 'death',
    valueKey: 'death',
    field: 'death',
    name: '',
    textAlign: 'Center',
    // returnFormat: valueFormatter,
    // validationHeader: {validationFindData},
    editType: 'numericedit',
    subFields: [
      {
        tooltip: `Todas as mortes, por categorias, entre 01/07/${firstYear} e 30/06/${lastYear}\n\n
        Caso a fazenda não tenha anotações precisas de mortes,\ndeve utilizar mortalidades medias com base nas referências em cada categoria.\n\n
        Vide notas!`,
        key: 'content',
        valueKey: 'death',
        field: 'content',
        name: 'Mortes',
        textAlign: 'Center',
        disableFilter: true,
        editType: 'numericedit',
        subFields: [
          {
            key: 'death.amount',
            valueKey: 'death.amount',
            field: 'amount',
            customKey: 'valueKey',
            width: '120px',
            name: 'Cabeças',
            textAlign: 'Center',
            extraParams: {
              minValue: 0,
            },
            editType: 'numericedit',
            disableFilter: true,
            disabledEdit: (col) => {
              if (col?.currentCel?.cel >= 11 && col?.currentCel?.cel <= 12) {
                return false;
              }
              return true;
            },
            tooltipsCel: [
              {
                value: 'Referência mortalidade média - 1,4%',
                position: 'left',
              }, // campo validar
              {value: 'Referência mortalidade média - 1,4%', position: 'left'},
              {value: 'Referência mortalidade média - 1,4%', position: 'left'},
              {value: 'Referência mortalidade média - 3,6%', position: 'left'},
              {value: 'Referência mortalidade média - 1,5%', position: 'left'},
              {value: 'Referência mortalidade média - 1,5%', position: 'left'},
              {value: 'Referência mortalidade média - 1,5%', position: 'left'},
              {value: 'Referência mortalidade média - 1,5%', position: 'left'},
              {value: 'Referência mortalidade média - 1,5%', position: 'left'},
              {value: 'Referência mortalidade média - 1,4%', position: 'left'},
              {value: 'Referência mortalidade média - 1,4%', position: 'left'},
              {value: 'Referência mortalidade média - 1,4%', position: 'left'},
              {
                value: 'Referência mortalidade média - 1,4%',
                position: 'top-left',
              },
              {
                value: 'Referência mortalidade média - 1,4%',
                position: 'top-left',
              },
            ],
            // extraParams: {
            //   minValue: 0,
            // },
          },
        ],
      },
    ],
  },
  {
    key: 'birth',
    valueKey: 'birth',
    field: 'birth',
    name: '',
    textAlign: 'Center',
    // returnFormat: valueFormatter,
    // validationHeader: {validationFindData},
    editType: 'numericedit',
    subFields: [
      {
        tooltip: `Todos os nascimentos ocorridos entre 01/07/${firstYear} e 30/06/${lastYear}`,
        key: 'content',
        valueKey: 'birth',
        field: 'content',
        name: 'Nascimentos',
        textAlign: 'Center',
        disableFilter: true,
        editType: 'numericedit',
        subFields: [
          {
            key: 'birth.amount',
            valueKey: 'birth.amount',
            field: 'birth.amount',
            // customKey: 'valueKey',
            width: '120px',
            name: 'Cabeças',
            textAlign: 'Center',
            editType: 'numericedit',
            disableFilter: true,
            extraParams: {
              minValue: 1,
            },
            disabledEdit: (col) => {
              if (col?.data?.category_livestock?.id !== 3) {
                return true;
              }
              if (col?.currentCel?.cel >= 11 && col?.currentCel?.cel <= 12) {
                return false;
              }
              return true;
            },
            style: (dataLine) => {
              if (dataLine?.category_livestock?.id === 3) {
                return {
                  backgroundColor: 'rgb(219,237,208)',
                };
              }
              return {
                pointerEvents: 'none',
                border: 'none',
                backgroundColor: '#fff',
              };
            },

            // extraParams: {
            //   minValue: 0,
            // },
          },
        ],
      },
    ],
  },
];

export default columns;
