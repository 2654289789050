import React, {useContext, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {Col, Row, Container} from 'reactstrap';
import IMask from 'imask';
import Card from '../../components/Card';
import InfoGraphic from '../../components/Card/InfoGraphic';
import 'chart.piecelabel.js';
import {useInitialRenderEffect} from '../../utils/functions';
import {getDataChart} from '../../utils/utils';
import {fetchBirthsKPI, fetchBirthsLine} from '../../services/births';
import {fetchDeathsKPI, fetchDeathsLine} from '../../services/deaths';
import {fetchWeaningKPI, fetchWeaningLine} from '../../services/weaning';
import {
  fetchConfinementsKPI,
  fetchConfinementsLine,
} from '../../services/confinements';
import {fetchHerdEntryKPI, fetchHerdEntryLine} from '../../services/herdEntry';
import {
  fetchHerdDepartureKPI,
  fetchHerdDepartureLine,
  fetchHerdDepartureValidated,
} from '../../services/herdDeparture';
import {DataContext} from '../../utils/DataProvider';

const CardsLivestock = () => {
  const {stateFetchParamsCustom, stateMessage} = useContext(DataContext);
  const [kpis, setKpis] = useState(null);
  const [lines, setLines] = useState(null);
  const [validated, setValidateds] = useState(null);

  const graphs = [
    {
      title: 'Nascimentos',
      type: 'income',
      name: 'births',
      reqs: {
        kpi: fetchBirthsKPI,
        line: fetchBirthsLine,
        validated: fetchHerdDepartureValidated,
      },
    },
    {
      title: 'Mortes',
      type: 'income',
      name: 'deaths',
      reqs: {
        kpi: fetchDeathsKPI,
        line: fetchDeathsLine,
        validated: fetchHerdDepartureValidated,
      },
    },
    {
      title: 'Desmames',
      type: 'income',
      name: 'weaning',
      reqs: {
        kpi: fetchWeaningKPI,
        line: fetchWeaningLine,
        validated: fetchHerdDepartureValidated,
      },
    },
    {
      title: 'Confinamento',
      type: 'income',
      name: 'confinements',
      reqs: {
        kpi: fetchConfinementsKPI,
        line: fetchConfinementsLine,
        validated: fetchHerdDepartureValidated,
      },
    },
    {
      title: 'Compras e outras entradas',
      type: 'income',
      name: 'herd_entry',
      reqs: {
        kpi: fetchHerdEntryKPI,
        line: fetchHerdEntryLine,
        validated: fetchHerdDepartureValidated,
      },
    },
    {
      title: 'Vendas e outras saídas',
      type: 'income',
      name: 'herd_departure',
      reqs: {
        kpi: fetchHerdDepartureKPI,
        line: fetchHerdDepartureLine,
        validated: fetchHerdDepartureValidated,
      },
    },
  ];
  const optionsBarGraphic = {
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: '#1f77b4',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#1f77b4',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 5,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };

  const getLine = async (fn) => {
    try {
      return await getDataChart(
        fn,
        stateFetchParamsCustom,
        null,
        false,
        optionsBarGraphic,
      );
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const getKpi = async (fn) => {
    try {
      const response = await fn(stateFetchParamsCustom);
      return response.data;
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  useInitialRenderEffect(() => {
    const loadData = async () => {
      let localKpis = {};
      let localLines = {};
      let localValidateds = {};
      graphs.forEach(async (graph) => {
        Object.keys(graph.reqs).forEach(async (req) => {
          if (req === 'kpi') {
            const newKpi = await getKpi(graph.reqs[req]);
            localKpis = {
              ...localKpis,
              [graph.name]: newKpi,
            };
            setKpis(localKpis);
          } else if (req === 'line') {
            const newLine = await getLine(graph.reqs[req]);
            localLines = {
              ...localLines,
              [graph.name]: newLine,
            };
            setLines(localLines);
          } else if (req === 'validated') {
            const {data} = await graph.reqs[req](
              graph.name,
              stateFetchParamsCustom,
            );
            localValidateds = {
              ...localValidateds,
              [graph.name]: data,
            };
            setValidateds(localValidateds);
          }
        });
      });
    };

    loadData();
  });

  return (
    <Container fluid>
      {stateMessage}
      <Row>
        {kpis &&
          Object.keys(kpis).length &&
          lines &&
          Object.keys(lines).length &&
          graphs.map((graph) => {
            return (
              <Col xs={6} className="p-3" key={graph.title}>
                <Card type={graph.type} className="card d-flex">
                  <Row className="justify-content-between">
                    <h5>{graph.title}</h5>
                    {validated && validated[graph.name] && (
                      <i
                        className={`fas fa-${
                          validated[graph.name].validated
                            ? 'check'
                            : 'exclamation'
                        }`}
                      />
                    )}
                  </Row>

                  <Row>
                    {kpis[graph.name] && (
                      <Col xs={3} className="cards p-3">
                        <InfoGraphic
                          mask="00000000000"
                          data={kpis[graph.name]}
                        />
                      </Col>
                    )}
                    {lines[graph.name] ? (
                      <Col xs={9} className="cards p-3">
                        <Line
                          data={lines[graph.name]}
                          options={{
                            plugins: {
                              datalabels: {
                                display: false,
                              },
                            },
                            tooltips: {
                              callbacks: {
                                label: (tooltipItem) => {
                                  return `${IMask.createMask({
                                    mask: 'money',
                                    blocks: {
                                      money: {
                                        mask: Number, // enable number mask

                                        // other options are optional with defaults below
                                        scale: 2, // digits after point, 0 for integers
                                        signed: false, // disallow negative
                                        thousandsSeparator: '.', // any single char
                                        padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
                                        normalizeZeros: false, // appends or removes zeros at ends
                                        radix: ',', // fractional delimiter

                                        // additional number interval options (e.g.)
                                        min: 0,
                                        max: 9999999999999,
                                      },
                                    },
                                  }).resolve(tooltipItem.value.toString())}`;
                                },
                              },
                            },
                            showLines: true,
                            scaleShowLabels: false,
                            legend: {
                              display: false,
                            },

                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    display: false,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  ticks: {
                                    display: false, // this will remove only the label
                                  },
                                  gridLines: {
                                    display: false,
                                    drawBorder: false,
                                    color: '#fff',
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </Col>
                    ) : (
                      <div className="no-content">
                        <h6>Clique em lançar para adicionar registros</h6>
                      </div>
                    )}
                  </Row>
                </Card>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

export default CardsLivestock;
