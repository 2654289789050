import api from '../api';

export const fetchReportCashFlowItems = (params = {}) => {
  return api.get(`cash_flow/items`, {
    params: {
      ...params,
    },
  });
};

export const fetchReportCashFlow = (params = {}) => {
  return api.get(`cash_flow`, {
    params: {
      ...params,
    },
  });
};

export const fetchReportCashFlowNotValidated = (params = {}) => {
  return api.get(`cash_flow/existsNotValidated`, {
    params: {
      ...params,
    },
  });
};

export const fetchReportMonthlyItems = (params = {}) => {
  return api.get(`dairy_monthly/items`, {
    params: {
      ...params,
    },
  });
};

export const fetchReportMonthly = (params = {}) => {
  return api.get(`dairy_monthly`, {
    params: {
      ...params,
    },
  });
};

export const fetchReportMonthlyNotValidated = (params = {}) => {
  return api.get(`dairy_monthly/existsNotValidated`, {
    params: {
      ...params,
    },
  });
};
