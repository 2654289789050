/* eslint-disable camelcase */
/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {createOrUpdateDataFull} from '../../../../utils/utils';
import {fetchAverageHerd, createAverageHerd} from '../../../../services/averageHerd';

const FlockMedia = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParamsCustom, stateMessage} = useContext(DataContext);

  const colFixed = {
    header: [
      {
        key: 'harvest',
        valueKey: 'harvest',
        height: 'auto',
        field: 'harvest',
        name: 'Média Rebanho',
        width: '100px',
        disableFilter: true,
        textAlign: 'Center',
        editType: 'defaultedit',
        disabledEdit: true,
        tooltipPosition: 'right',
        tooltip: 'Inserir total de cabeças no último dia de cada mês na fazenda',
      },
    ],
    data: [
      {
        // eslint-disable-next-line camelcase
        harvest: `Total de cabeças`,
        date_harvest: false,
      },
    ],
  };
  const columns = (initial_year, final_year) => [
    {
      key: '7',
      field: '7.value',
      valueKey: '7.value',
      width: '100px',
      disableFilter: true,
      name: `JUL/${initial_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '8',
      field: '8.value',
      valueKey: '8.value',
      width: '100px',
      disableFilter: true,
      name: `AGO/${initial_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '9',
      field: '9.value',
      valueKey: '9.value',
      width: '100px',
      disableFilter: true,
      name: `SET/${initial_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '10',
      field: '10.value',
      valueKey: '10.value',
      width: '100px',
      disableFilter: true,
      name: `OUT/${initial_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '11',
      field: '11.value',
      valueKey: '11.value',
      width: '100px',
      disableFilter: true,
      name: `NOV/${initial_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '12',
      field: '12.value',
      valueKey: '12.value',
      width: '100px',
      disableFilter: true,
      name: `DEZ/${initial_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '1',
      field: '1.value',
      valueKey: '1.value',
      width: '100px',
      disableFilter: true,
      name: `JAN/${final_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '2',
      field: '2.value',
      valueKey: '2.value',
      width: '100px',
      disableFilter: true,
      name: `FEV/${final_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '3',
      field: '3.value',
      valueKey: '3.value',
      width: '100px',
      disableFilter: true,
      name: `MAR/${final_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '4',
      field: '4.value',
      valueKey: '4.value',
      width: '100px',
      disableFilter: true,
      name: `ABR/${final_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '5',
      field: '5.value',
      valueKey: '5.value',
      width: '100px',
      disableFilter: true,
      name: `MAI/${final_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: '6',
      field: '6.value',
      valueKey: '6.value',
      width: '100px',
      disableFilter: true,
      name: `JUN/${final_year}`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
    {
      key: 'average',
      field: 'average',
      valueKey: 'average',
      width: '100px',
      disableFilter: true,
      disabledEdit: true,
      name: `MÉDIA`,
      textAlign: 'Center',
      editType: 'numericedit',
    },
  ];

  useEffect(() => {
    const loadStocks = async () => {
      const response = await fetchAverageHerd({
        ...stateFetchParamsCustom,
      });

      let newData = {};
      columns(state?.harvest?.first_year.toString().substr(2, 4), state?.harvest?.last_year.toString().substr(2, 4)).forEach((item) => {
        const findValue = response.data.list_average_herd.find((element) => `${element.month}` === item.key);
        if (findValue) {
          newData = {
            ...newData,
            ...{
              [item.key]: {month: item.key, value: findValue.value},
            },
          };
        } else {
          newData = {
            ...newData,
            ...{
              [item.key]: {
                month: item.key,
                value: null,
              },
            },
          };
        }
      });

      setData([{...newData, id: 1, average: response.data.average}]);
    };
    loadStocks();
  }, [update]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value, cel, key) => {
        try {
          const res = await createOrUpdateDataFull(
            {
              month: key.split('.')[0],
              value: null,
            },
            state,
            createAverageHerd,
            createAverageHerd,
            false,
          );
          setUpdate((prev) => !prev);
          return res;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      <div className="flock-media livesotckStock fixedHeader">
        <div className="container">
          <div className="tip-title">
            Caso os doze meses não sejam preenchidos, a média será calculada de acordo com os dados inseridos na tabela AVALIAÇÃO DE ESTOQUE.
          </div>
        </div>

        {!!data.length && (
          <DataGrid
            onChange={async (dataGridInputs) => {
              const findValue = Object.keys(dataGridInputs).filter((item) => typeof dataGridInputs[item] === 'string');

              try {
                const reqs = findValue.map((key) => {
                  return createOrUpdateDataFull(
                    {
                      month: key,
                      value: parseFloat(dataGridInputs[key]),
                    },
                    state,
                    createAverageHerd,
                    createAverageHerd,
                    false,
                  );
                });
                await Promise.all(reqs);
                setUpdate(!update);
                return null;
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={columns(state?.harvest?.first_year.toString().substr(2, 4), state?.harvest?.last_year.toString().substr(2, 4))}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            fixedColumn={colFixed || null}
            editable=""
            disabledPaginate
            width="100%"
            actions={actions}
          />
        )}
      </div>
    </>
  );
};

export default FlockMedia;
