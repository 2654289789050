import api from '../api';

const fetchCategoryWagonType = (params = {}) => {
  return api.get(`/category_wagon_type`, {
    params: {
      ...params,
    },
  });
};

const fetchCategoryWagonTypebyId = (id, params = {}) => {
  return api.get(`/category_wagon_type/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerCategoryWagonType = (body = {}) => {
  return api.post(`/category_wagon_type`, body);
};

const changeCategoryWagonTypebyId = (id, body = {}) => {
  return api.put(`/category_wagon_type/${id}`, body);
};

const deleteCategoryWagonType = (id) => {
  return api.delete(`/category_wagon_type/${id}`);
};

export {fetchCategoryWagonType, fetchCategoryWagonTypebyId, registerCategoryWagonType, changeCategoryWagonTypebyId, deleteCategoryWagonType};
