import React, {useState} from 'react';
import {DateContext, ModuleContext} from './contexts';
import {SYNTHETIC_TABLE} from './constants';

const TableProvider = ({totalYears, months, children}) => {
  const [visibleDates, setVisibleDates] = useState([]);
  const [visibleModules, setVisibleModules] = useState([]);

  const isVisible = (state) => (label) => {
    return !!state.find((visibleField) => visibleField.label === label);
  };

  const showField = (state, setter) => (field) => {
    if (!isVisible(state)(field.label)) {
      setter([...state, field]);
    }
  };

  const hideField = (state, setter) => (field) => {
    const visibleFieldsWithoutChoosenField = state.filter((visibleField) => visibleField.label !== field.label && visibleField.parent !== field.label);

    setter([...visibleFieldsWithoutChoosenField]);
  };

  const toggleField = (state, setter) => (field) => {
    if (isVisible(state)(field.label)) {
      hideField(state, setter)(field);
    } else {
      showField(state, setter)(field);
    }
  };

  const findByType = (state) => (type) => {
    return state.filter((field) => field.type === type);
  };

  const countByType = (state) => (type) => {
    return findByType(state)(type).length;
  };

  const headerLength = (state) => () => {
    const yearsToggledCount = countByType(state)(SYNTHETIC_TABLE.HEADER_TYPES.YEAR);
    const yearsToggled = findByType(state)(SYNTHETIC_TABLE.HEADER_TYPES.YEAR);
    const monthsToggled = countByType(state)(SYNTHETIC_TABLE.HEADER_TYPES.MONTH);
    const totalMonths = yearsToggled.reduce((acc, year) => acc + months[year.label].length, 0);
    let length = 0;

    length = totalYears;

    if (yearsToggledCount) {
      length = totalMonths + (totalYears - yearsToggledCount);
    }

    if (monthsToggled) {
      length += monthsToggled * 2 - monthsToggled;
    }

    return length + 1;
  };

  return (
    <DateContext.Provider
      value={{
        visibleFields: visibleDates,
        isVisible: isVisible(visibleDates),
        toggleField: toggleField(visibleDates, setVisibleDates),
        findByType: findByType(visibleDates),
        countByType: countByType(visibleDates),
        headerLength: headerLength(visibleDates)(),
      }}>
      <ModuleContext.Provider
        value={{
          visibleFields: visibleModules,
          isVisible: isVisible(visibleModules),
          toggleField: toggleField(visibleModules, setVisibleModules),
          findByType: findByType(visibleModules),
          countByType: countByType(visibleModules),
        }}>
        {children}
      </ModuleContext.Provider>
    </DateContext.Provider>
  );
};

export default TableProvider;
