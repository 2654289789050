import api from '../api';

const fetchExportsXLS = (params = {}, responseType = '', headers = {}) => {
  return api.get(`/export/xls`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};

const fetchExportsCSV = (params = {}, responseType = '', headers = {}) => {
  return api.get(`/export/csv`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};

export {fetchExportsXLS, fetchExportsCSV};
