/* eslint-disable camelcase */
/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import IMask from 'imask';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TransitionsModal from '../../../../components/Modal';
import columns from './InventoryValuationModel';
import {DataContext} from '../../../../utils/DataProvider';
import {getValueObject, maskInput} from '../../../../utils/utils';
import DataGrid from '../../../../components/DataGrid';
import {updateSctockValuation, fetchSctockValuation} from '../../../../services/stockValuation';
import {fetchCategoryLivestock} from '../../../../services/categoryLivestock';
// import HerdSwing from './HerdSwing';
import '../../styled.scss';
import SalesGrid from '../../../Livestock/SalesGrid';
import ShoppingGrid from '../../../Livestock/ShoppingGrid';
// import dataGridReducer from '../../../../components/DataGrid/dataGridReducer';
import Alert from '../../../../components/Notification';
import {fetchHerdDeparture} from '../../../../services/herdDeparture';
import {MASKS} from '../../../../utils/constants';

const InventoryValuation = () => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [totalizers, setTotalizers] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertProps, setAlertProps] = useState(null);
  const [totalValueArroba, setTotalValueArroba] = useState(0);
  // const [diferences, setDiferences] = useState(null);
  const [colFixed, setColFixed] = useState({
    header: [
      {
        key: 'category',
        valueKey: 'category',
        height: 'auto',
        field: 'category',
        name: ' Categorias',
        textAlign: 'Center',
        editType: 'defaultedit',
        disabledEdit: true,
        disableFilter: true,
      },
    ],
    accumulator: [{title: 'Total'}],
  });
  const [totalItems] = useState(0);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParamsCustom, stateMessage, filterParams, stateFetchParams} = useContext(DataContext);

  useEffect(() => {
    (async () => {
      const responseHerd = await fetchHerdDeparture({
        ...stateFetchParams,
        ...filterParams,
      });

      let acc = 0;
      responseHerd.data.resultList.forEach((line) => {
        acc += Number(Number(line?.value_head?.toFixed(2)) * line?.amount);
      });
      setTotalValueArroba(acc.toFixed(2));
    })();
  }, [update]);// eslint-disable-line

  const body = {
    created_by: state.user.id,
    harvest: {
      id: state.harvest.id,
    },
    customer: {
      id: state.customer.id,
    },
    farm: {
      id: state.farm.id,
    },
  };
  useEffect(() => {
    const comparar = (a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      // a deve ser igual a b
      return 0;
    };

    const getContents = async () => {
      try {
        delete stateFetchParamsCustom['order[id]'];
        delete stateFetchParamsCustom.page;
        delete stateFetchParamsCustom.perPage;
        const response = await fetchCategoryLivestock();

        const livestockData = await fetchSctockValuation({
          ...stateFetchParamsCustom,
          ...filterParams,
        });
        // setDiferences(livestockData.data[[livestockData.data.length - 1]]);
        const dataFixedColumn = [];
        response.data.resultList.sort(comparar).map((element) => dataFixedColumn.push({category: element.title, id: element.id}));
        let accumulatorsTemp = {};
        livestockData.data.list_category.forEach((item) => {
          Object.keys(item).forEach((el) => {
            if (item[el]?.amount) {
              accumulatorsTemp = {
                ...accumulatorsTemp,
                [el]: accumulatorsTemp[el] ? parseFloat(accumulatorsTemp[el]) + parseFloat(item[el]?.amount) : parseFloat(item[el]?.amount),
              };
            }
          });
        });
        const {birth = 0, death = 0, final_herd = 0, herd_departure = 0, herd_entry = 0, initial_herd = 0} = accumulatorsTemp;

        setTotalizers(final_herd - (initial_herd + herd_entry + birth - herd_departure - death));

        setData(
          livestockData.data.list_category.map((item, i) => {
            return {...item, id: i + 1};
          }),
        );
        setColFixed((prev) => Object.assign(prev, {data: dataFixedColumn}));
        // setData(newObj);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [update, stateFetchParamsCustom, filterParams]);

  useEffect(() => {
    if (totalizers && totalizers !== 0) {
      setAlertProps(null);
      setTimeout(() => {
        setAlertProps({
          type: 'error',
          message: 'Verifique os dados inseridos, o estoque calculado deve ser igual a 0.',
          time: 5000,
        });
      }, [5000]);
    }
  }, [totalizers]);

  const validateHeaderCallback = async (dataGridRows, field, checked) => {
    const promises = [];
    dataGridRows.forEach((dataGridInputs) => {
      if (
        Object.keys(dataGridInputs[field]).filter((item) => {
          if (item !== 'from_stock_valuation' && item !== 'persist' && item !== 'validated' && !!dataGridInputs[field][item]) {
            return item;
          }
          return null;
        }).length
      ) {
        let newData = {};
        if (field === 'herd_departure' || field === 'herd_entry' || field === 'birth') {
          newData = {
            ...dataGridInputs,
            [field]: {
              ...dataGridInputs[field],
              validated: checked,
              persist: true,
            },
          };
        } else {
          newData = {
            ...dataGridInputs,
            [field]: {
              ...dataGridInputs[field],
              validated: checked,
            },
          };
        }
        delete newData.id;
        promises.push(updateSctockValuation(Object.assign(newData, body)));
      }
    });

    const response = await Promise.all(promises);
    setUpdate(!update);
    return response;
  };

  const actions = [
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (responseData, line, cel) => {
        const splitedKey = cel?.split('.');
        if (splitedKey.length > 1) {
          try {
            let newData;

            if (splitedKey[0] === 'birth' || splitedKey[0] === 'herd_entry' || splitedKey[0] === 'herd_departure') {
              newData = {
                [splitedKey[0]]: {
                  ...responseData[splitedKey[0]],
                  [splitedKey[1]]: null,
                  validated: false,
                  persist: true,
                },
              };
            } else {
              newData = {
                [splitedKey[0]]: {
                  ...responseData[splitedKey[0]],
                  [splitedKey[1]]: null,
                  validated: false,
                },
              };
            }
            delete responseData[cel];
            delete responseData.id;

            const resp = await updateSctockValuation({
              ...responseData,
              ...newData,
              ...body,
            });
            setUpdate(!update);
            return resp;
          } catch (error) {
            console.error(error);
            return error;
          }
        }
        return null;
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
    {
      title: 'Entrada - Apoio',
      action: 'default',
      icon: 'fas fa-sign-in-alt',
      onCLick: async () => {
        setOpen('input');
      },
    },
    {
      title: 'Saída - Apoio',
      action: 'default1',
      icon: 'fas fa-sign-out-alt',
      onCLick: async () => {
        setOpen('output');
      },
    },
    {
      component: (
        <div
          style={{
            width: 250,
            display: 'inline-block',
            float: 'right',
            marginTop: 10,
          }}>
          <div className={`stockTotal ${totalizers ? 'red' : 'green'} `}>
            <div className="p-2">Estoque Calculado</div>
            <div>{totalizers}</div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      {stateMessage}
      {data && (
        <div className="inventoryValuation livesotckStock fixedHeader">
          <DataGrid
            onChange={async (dataGridInputs, l) => {
              let newData = data.find((item) => item.id === l);
              let controller = false;
              const ArrayKeyValue = Object.keys(dataGridInputs).filter((item) => item.split('.').length > 1);

              ArrayKeyValue.forEach((el) => {
                const keySplited = el.split('.');
                newData = {
                  ...newData,
                  ...{
                    [keySplited[0]]: {
                      ...newData[keySplited[0]],
                      [keySplited[1]]: parseFloat(dataGridInputs[el]),
                      validated: false,
                    },
                  },
                };
                if (
                  (keySplited[0] === 'herd_entry' || keySplited[0] === 'herd_departure') &&
                  // eslint-disable-next-line camelcase
                  dataGridInputs[keySplited[0]]?.from_stock_valuation === false
                ) {
                  controller = true;
                }
                // eslint-disable-next-line no-prototype-builtins
                if (newData[keySplited[0]].hasOwnProperty('persist')) {
                  newData[keySplited[0]].persist = true;
                }
              });
              delete newData.id;
              try {
                if (controller) {
                  return setOpenDialog(newData);
                }
                const resp = await updateSctockValuation(newData);
                setUpdate(!update);
                return resp;
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={columns(state.harvest.first_year, state.harvest.last_year, setOpen)}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            fixedColumn={colFixed || null}
            actions={actions}
            // editableCelOnly
            totalItems={totalItems}
            disabledPaginate
            validateCallback={validateHeaderCallback}
            maxHeight="100%"
            accumulators={{
              Total: {
                columns: ['amount', 'weight_alive', 'weight', 'arroba_value', 'weight_death'],
                columnType: 'nested',
                hideTitle: true,
                itemOperation: (acc, line, key) => {
                  if (key.indexOf('weight') > 0) {
                    return (acc += getValueObject(line, null, key) * getValueObject(line, null, key.replace(key.split('.')[1], 'amount')));
                  }
                  if (key.indexOf('herd_entry.arroba_value') > -1) {
                    return (acc +=
                      getValueObject(line, null, key.replace(key.split('.')[1], 'amount')) *
                      (getValueObject(line, null, key.replace(key.split('.')[1], 'weight_alive')) / 30) *
                      getValueObject(line, null, key));
                  }
                  if (key.indexOf('herd_departure.weight_death') > -1) {
                    return (acc += getValueObject(line, null, key.replace(key.split('.')[1], 'amount')) * getValueObject(line, null, key));
                  }
                  if (key.indexOf('herd_departure.arroba_value') > -1) {
                    let value_head = 0;
                    if (parseFloat(getValueObject(line, null, key.replace(key.split('.')[1], 'weight_death'))) > 0) {
                      value_head =
                        parseFloat(getValueObject(line, null, key.replace(key.split('.')[1], 'arroba_value'))) *
                        (parseFloat(getValueObject(line, null, key.replace(key.split('.')[1], 'weight_death'))) / 15);
                    } else if (parseFloat(getValueObject(line, null, key.replace(key.split('.')[1], 'weight_alive'))) > 0) {
                      value_head =
                        parseFloat(getValueObject(line, null, key.replace(key.split('.')[1], 'arroba_value'))) *
                        (parseFloat(getValueObject(line, null, key.replace(key.split('.')[1], 'weight_alive'))) / 30);
                    }

                    return (acc += getValueObject(line, null, key.replace(key.split('.')[1], 'amount')) * value_head.toFixed(2));
                  }

                  return (acc += getValueObject(line, null, key) !== '' ? getValueObject(line, null, key) : 0);
                },
                resultOperation: (acc, key) => {
                  if (key.indexOf('herd_departure.arroba_value') > -1) {
                    return maskInput({value: totalValueArroba, type: MASKS.MONEY});
                  }
                  if (key.indexOf('weight') > 0 || key.indexOf('herd_entry.arroba_value') > -1) {
                    return IMask.createMask({
                      mask: 'number',
                      blocks: {
                        number: {
                          mask: Number, // enable number mask

                          // other options are optional with defaults below
                          scale: 2, // digits after point, 0 for integers
                          signed: false, // disallow negative
                          thousandsSeparator: '.', // any single char
                          padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                          normalizeZeros: true, // appends or removes zeros at ends
                          radix: ',', // fractional delimiter

                          // additional number interval options (e.g.)
                          min: 0,
                          max: 999999999999,
                        },
                      },
                    }).resolve(acc.toString());
                  }
                  // if (key.indexOf('herd_departure.arroba_value') > -1) {
                  //   return '';
                  // }

                  return acc;
                },
              },
            }}
          />

          <Dialog open={!!openDialog} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <i className="fas fa-engine-warning" />
              Atenção
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ao manipulador dados de Entrada ou Saída da tabela principal, os dados da tabela de Apoio serão substituidos e excluidos. Deseja Realmente
                realizar essa operação?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  const resp = await updateSctockValuation(openDialog);
                  setUpdate(!update);
                  setOpenDialog(false);
                  return resp;
                }}
                color="primary"
                autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <TransitionsModal
            style={{width: '90%'}}
            open={!!open}
            setOpen={() => {
              setOpen(false);
              setUpdate((prev) => !prev);
            }}>
            <div className="grid-modal">
              <div className="d-flex justify-content-between align-items-start">
                <h2>{open === 'input' ? 'Compras e outras entradas' : 'Vendas e outras saídas'}</h2>
                <button type="button" className="back-button" onClick={() => setOpen(false)}>
                  Voltar
                </button>
              </div>

              {open === 'input' ? <ShoppingGrid origin="DIAGNOSIS" /> : <SalesGrid origin="DIAGNOSIS" />}
            </div>
          </TransitionsModal>
        </div>
      )}
      {alertProps && <Alert type={alertProps.type} message={alertProps.message} time={alertProps.time} />}
    </>
  );
};

export default InventoryValuation;
