/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, {useEffect, useState, useReducer, useContext} from 'react';
import {Spinner} from 'reactstrap';
import {useLocation} from 'react-router-dom';
import {DataGridContainer, AccumulatorCel, AccumulatorRow} from './styled';
import Toolbar from './Toolbar';
import EditableGrid from './EditableGrid';
import PaginationFooter from './PaginationFooter';
import {normalizeRowData, generateRowRule, generateHeader, refreshBlur, isObject, getKeyValue, isPartialDate, formatToEditType} from '../../utils/utils';
import {useInitialRenderEffect, toCamel} from '../../utils/functions';
import Alert from '../Notification';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import Accumulators from './Accumulators';
import dataGridReducer from './dataGridReducer';
import {DATAGRID, ROLES} from '../../utils/constants';
import DataGridContext from './DataGridContext';
import {DataContext} from '../../utils/DataProvider';
import {setMode, setCurrentLine, setCurrentCel, popTableActionQueue} from './dataGridActions';
import secureStorage from '../../utils/SecureStorage';

const DataGrid = ({
  header,
  data,
  groupBy,
  keyProp,
  keyValueProp,
  titleProp,
  maxHeight,
  onChange = () => {},
  fixedColumn,
  width,
  fixedAccumulator = false,
  totalItems,
  editableCelOnly = false,
  actions = null,
  validateCallback = () => {},
  accumulators = null,
  disabledPaginate,
}) => {
  const [state, dispatch] = useReducer(dataGridReducer, {
    mode: DATAGRID.TABLE.MODE.READ,
    currentLine: null,
    filters: [],
    initialData: data,
    actionQueue: [],
    fixedAccumulator,
    errors: {},
  });

  const location = useLocation();
  const perPageStorage = () => {
    const perPageSession = JSON.parse(window.localStorage.getItem('perPageStorage')) || {};
    if (perPageSession[location.pathname]) {
      return perPageSession[location.pathname];
    }
    return 50;
  };

  const {setPaginationParams, setFilterParams} = useContext(DataContext);
  const [alertProps, setAlertProps] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [itemsPerpage, setItemsPerpage] = useState(perPageStorage());
  const [currentPage, setCurrentPage] = useState(0);
  const [editAction, setEditAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [headerTemplate, setHeaderTemplate] = useState([]);
  const [fieldTemplate, setFieldTemplate] = useState([]);
  const [columnsTemplate, setColumnTemplate] = useState('');
  const [sort, setSort] = useState({field: 'id', order: 'desc'});
  const [cel, setCel] = useState(null);
  const ALLOWED_ROLES = [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.TECHNICAL];

  const translateErros = (error) => {
    if (error.indexOf('temporaryPassword') > 0) {
      return 'Senha inválida: mínimo de 8 caracteres';
    }
    if (error.indexOf('email already exists') > 0) {
      return 'Já existe um usuário com este email';
    }
    if (!error) {
      return 'Erro ao salvar dados!';
    }
    if (!error.indexOf('Não foi possível remover users') > 0) {
      return error;
    }
    if (error.indexOf('Não foi possível remover usuário. Existe(m) registro(s) vinculado(s) em cliente.') > 0) {
      return error;
    }
    if (error) return error;
    return 'Preecha todos os campos';
  };

  const onChangeSubmit = async (functionChange, responseData, msg) => {
    setLoading(true);
    const resp = await functionChange(responseData, state.currentLine, cel);

    setAlertProps(null);
    if (resp && (resp.status === 200 || resp.status === 201)) {
      setAlertProps({
        type: 'success',
        message: msg,
        time: 5000,
      });

      setLoading(false);
    } else if (resp === null) {
      // setAlertProps({
      //   type: 'error',
      //   message: `Erro!`,
      //   time: 5000,
      // });
      setLoading(false);
    } else if (resp && resp.response && resp.response.data && resp.response.data.message ? resp.response.data : resp) {
      if (state.mode === DATAGRID.TABLE.MODE.NEW_LINE) {
        setAlertProps({
          type: 'error',
          message:
            resp.response.data && resp.response.data.message && resp.response.data.message.indexOf('error') > 0
              ? translateErros(resp.response.data.message)
              : translateErros(resp.response.data.message),
          time: 5000,
        });
      } else {
        setAlertProps({
          type: 'error',
          message: translateErros(resp.response.data.message),
          time: 5000,
        });
      }
      setLoading(false);
    }
  };

  const deleteRow = async () => {
    const dataToRemove = data.find((item) => item.id === state.currentLine);
    await onChangeSubmit(confirmDialog.onClick, dataToRemove, 'Excluído com sucesso');
    dispatch(setMode(DATAGRID.TABLE.MODE.READ));
    dispatch(setCurrentLine(null));
  };

  useInitialRenderEffect(() => {
    const removeRestrictedFields = () => {
      const user = JSON.parse(secureStorage.getItem('user'));
      const hasAccess = ALLOWED_ROLES.includes(user.user_type);

      if (!hasAccess) {
        header = header.filter((item) => item.field !== 'validated');
      }
    };

    const makeTemplateHeader = () => {
      const {newFieldTemplate, newHeaderTemplate, newWidthCollection} = generateHeader(header, groupBy);
      setFieldTemplate(newFieldTemplate);
      setHeaderTemplate(newHeaderTemplate);
      const value = generateRowRule(newWidthCollection);
      setColumnTemplate(value);
    };

    removeRestrictedFields();
    makeTemplateHeader();
  }, []);

  useEffect(() => {
    let sortField = sort.field;
    const fieldFound = fieldTemplate.find((item) => item.field === sortField);
    if (fieldFound && fieldFound.editType === 'dropdownedit') {
      sortField = toCamel(sort.field);
    }

    if (fieldFound && fieldFound.orderKey) {
      sortField = fieldFound.orderKey;
    }

    setPaginationParams({
      perPage: itemsPerpage,
      page: currentPage,
      [`order[${sortField}]`]: sort.order,
    });
    dispatch(setCurrentCel(state.currentCel ? Object.assign(state.currentCel, editableCelOnly) : {editableCelOnly}));
  }, [itemsPerpage, currentPage, sort, fieldTemplate]);// eslint-disable-line

  const actionTrigger = async (btn) => {
    switch (btn.action) {
      case 'add':
        dispatch(setMode(DATAGRID.TABLE.MODE.NEW_LINE));
        dispatch(setCurrentLine(null));
        break;
      case 'edit':
        dispatch(setMode(DATAGRID.TABLE.MODE.EDIT));
        break;
      case 'cancel':
        dispatch(setMode(DATAGRID.TABLE.MODE.READ));
        dispatch(setCurrentLine(null));
        break;
      case 'save':
        // eslint-disable-next-line no-lone-blocks
        {
          if (state.errors && Object.keys(state.errors).length) {
            return;
          }
          // if (btn.validate) {
          const validation = refreshBlur(state.mode === 'NEW_LINE' ? -1 : state.currentLine);

          if (validation) {
            const responseData = normalizeRowData(state.data, headerTemplate, data);

            delete responseData.changed;
            await onChangeSubmit(
              onChange,
              responseData,
              state.mode === DATAGRID.TABLE.MODE.NEW_LINE ? 'Sucesso ao inserir novos dados' : 'Editado com sucesso!',
            );
            dispatch(setMode(DATAGRID.TABLE.MODE.READ));
            dispatch(setCurrentLine(null));
          }
          // }
        }
        break;
      case 'remove':
        setConfirmDialog(btn);
        break;
      default:
        btn.onCLick(btn);
        break;
    }
  };

  useEffect(() => {
    state.actionQueue.forEach((action) => {
      actionTrigger({action, validate: true});
      dispatch(popTableActionQueue());
    });
  }, [state.actionQueue]);// eslint-disable-line

  useEffect(() => {
    const generateFilter = () => {
      if (!Object.keys(state.filters) || !Object.keys(state.filters).length) {
        return {};
      }
      return Object.keys(state.filters).reduce((acc, key) => {
        const fieldFilterParams = state.filters[key];
        const partialdate = fieldFilterParams.type === 'date' ? isPartialDate(fieldFilterParams.value1 || fieldFilterParams.value2) : '';
        const hasFirstOperatorFilled = !!fieldFilterParams.operation1;
        const hasSecondOperatorFilled = !!fieldFilterParams.operation2;
        let filterReturn = {};
        if ((hasFirstOperatorFilled && !hasSecondOperatorFilled) || (!hasFirstOperatorFilled && hasSecondOperatorFilled)) {
          const operator = hasFirstOperatorFilled ? fieldFilterParams.operation1.value : fieldFilterParams.operation2.value;
          const value = hasFirstOperatorFilled ? fieldFilterParams.value1 : fieldFilterParams.value2;

          filterReturn = {
            ...acc,
            ...{
              [`wherecustom[${getKeyValue(key, fieldFilterParams.originalKey)}${partialdate ? '$partialdate' : ''}:null]`]: `${formatToEditType(
                value,
                fieldFilterParams.type,
                operator,
              )};${operator}`,
            },
          };
        } else if (hasFirstOperatorFilled && hasSecondOperatorFilled) {
          const operator1 = fieldFilterParams.operation1.value;
          const {value1} = fieldFilterParams;
          const joinOperator = fieldFilterParams.joinOperation.value;
          const operator2 = fieldFilterParams.operation2.value;
          const {value2} = fieldFilterParams;

          filterReturn = {
            ...acc,
            ...{
              [`wherecustom[${key}${isObject(state.initalData[0][key])}${partialdate ? '$partialdate' : ''}:${joinOperator}]`]: `${formatToEditType(
                value1,
                fieldFilterParams.type,
                operator1,
              )};${operator1},${formatToEditType(value2, fieldFilterParams.type, operator2)};${operator2}`,
            },
          };
        }
        if (fieldFilterParams.checkboxList && fieldFilterParams.checkboxList.length) {
          const checkboxStrings = fieldFilterParams.checkboxList.reduce((checkboxStringAcc, checkbox) => {
            if (checkboxStringAcc.length) {
              return `${checkboxStringAcc}, '${checkbox}'`;
            }
            return `'${checkbox}'`;
          }, '');
          if (
            headerTemplate.find((item) => item.key === key) &&
            headerTemplate.find((item) => item.key === key).editType &&
            headerTemplate.find((item) => item.key === key).editType === 'datepicker'
          ) {
            filterReturn = {
              ...filterReturn,
              ...{[`wherenotin[${key}$partialdate]`]: checkboxStrings},
            };
          } else {
            filterReturn = {
              ...filterReturn,
              ...{[`wherein[${key}]`]: checkboxStrings},
            };
          }
        }
        return {
          ...acc,
          ...filterReturn,
        };
      }, {});
    };

    setFilterParams(generateFilter());
  }, [state.filters]);// eslint-disable-line

  useEffect(() => {
    document.addEventListener('keyup', (event) => {
      const keyName = event.code;

      if (keyName === 'Delete') {
        setTimeout(() => {
          document.getElementById('remove').click();
        }, 300);
      }
    });
  }, []);

  return (
    <DataGridContext.Provider value={{state, dispatch}}>
      <Toolbar editAction={editAction} onClick={actionTrigger} customConfiguration={actions} />
      <DataGridContainer className="datagrid-general" style={{minHeight: '200px', maxHeight: maxHeight || '350px'}}>
        {loading || !fieldTemplate || !fieldTemplate.length ? (
          <div style={{minHeight: '200px', maxHeight: maxHeight || '350px'}} className="d-flex w-100 h-100 align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="content-grid d-flex full-screen" style={{minHeight: '200px', maxHeight: 'max-content'}}>
            {fixedColumn && (
              <div className="fixed-column col-3 p-0 d-flex flex-column justify-content-end">
                <EditableGrid
                  width={width || 'auto'}
                  accumulators={accumulators}
                  validateCallback={validateCallback}
                  groupBy={groupBy}
                  headerTemplate={fixedColumn.header}
                  fieldTemplate={fixedColumn.header}
                  columnsTemplate="repeat(1, 7fr)"
                  keyProp={keyProp}
                  keyValueProp={keyValueProp}
                  titleProp={titleProp}
                  setCel={setCel}
                  setSort={setSort}
                  dataValues={fixedColumn.data}
                  gridListener={(dataFromRow) => {
                    if (dataFromRow && dataFromRow.changed) {
                      setEditAction(true);
                    }
                  }}
                />
                {fixedColumn.accumulator && fixedColumn.accumulator.length && (
                  <>
                    {fixedColumn.accumulator.map((item) => (
                      <AccumulatorRow key={item.title}>
                        <AccumulatorCel>
                          <div style={{marginRight: '5px'}}>{item.title}:</div>
                        </AccumulatorCel>
                      </AccumulatorRow>
                    ))}
                  </>
                )}
              </div>
            )}
            <div className={`editable-grid ${fixedColumn ? 'col-9 p-0 overflow-auto' : 'w-100'}`}>
              <div className="content-grid-scroll">
                <EditableGrid
                  width={`${fixedColumn ? 'max-content' : `${groupBy ? 'auto' : width}`}`}
                  accumulators={accumulators}
                  validateCallback={validateCallback}
                  groupBy={groupBy}
                  headerTemplate={headerTemplate}
                  fieldTemplate={fieldTemplate}
                  columnsTemplate={columnsTemplate}
                  keyProp={keyProp}
                  keyValueProp={keyValueProp}
                  titleProp={titleProp}
                  setSort={setSort}
                  dataValues={data}
                  setCel={setCel}
                />
                {accumulators && fixedColumn && (
                  <Accumulators
                    fixed={fixedAccumulator}
                    groupBy={groupBy}
                    groupedData={data}
                    accumulatorsConfig={accumulators}
                    fieldHeaders={fieldTemplate.filter((_header) => !_header.subFields)}
                    columnsTemplate={columnsTemplate}
                    width={fixedColumn ? 'max-content' : width}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {accumulators && !fixedColumn && (
          <Accumulators
            customClass="sticky"
            fixed={fixedAccumulator}
            groupBy={groupBy}
            groupedData={data}
            general
            accumulatorsConfig={accumulators}
            fieldHeaders={fieldTemplate.filter((_header) => !_header.subFields)}
            columnsTemplate={columnsTemplate}
            width={fixedColumn ? 'max-content' : width}
          />
        )}
      </DataGridContainer>

      {disabledPaginate === false ||
        (disabledPaginate === undefined && (
          <PaginationFooter
            activePage={currentPage}
            itemsCountPerPage={itemsPerpage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={1}
            itemClass="page-item"
            linkClass="page-link"
            onChange={(paginationData) => {
              if (paginationData && paginationData.pageNumber) {
                setCurrentPage(paginationData.pageNumber - 1);
              } else if (paginationData && paginationData.itemsPerPage) {
                window.localStorage.setItem(
                  'perPageStorage',
                  JSON.stringify({
                    ...(JSON.parse(window.localStorage.getItem('perPageStorage')) || {}),
                    [location.pathname]: paginationData.itemsPerPage,
                  }),
                );
                setItemsPerpage(paginationData.itemsPerPage);
              }
            }}
          />
        ))}
      {alertProps && <Alert type={alertProps.type} message={alertProps.message} time={alertProps.time} />}
      {confirmDialog && (
        <ConfirmDialog functionAction={deleteRow} setConfirmDialog={setConfirmDialog} message="Tem certeza que deseja excluir esse registro?" />
      )}
    </DataGridContext.Provider>
  );
};

export default DataGrid;
