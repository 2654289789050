import api from '../api';

const fetchHarvestsDetails = (params = {}) => {
  return api.get(`/harvests_details`, {
    params: {
      ...params,
    },
  });
};

const fetchHarvestsDetailssbyId = (id, params = {}) => {
  return api.get(`/harvests_details/${id}`, {
    params: {
      ...params,
    },
  });
};

const registerHarvestsDetails = (body = {}) => {
  return api.post(`/harvests_details`, body);
};

const changeHarvestsDetailsbyId = (id, body = {}) => {
  return api.put(`/harvests_details/${id}`, body);
};

const deleteHarvestsDetails = (id) => {
  return api.delete(`/harvests_details/${id}`);
};

export {
  fetchHarvestsDetails,
  fetchHarvestsDetailssbyId,
  registerHarvestsDetails,
  changeHarvestsDetailsbyId,
  deleteHarvestsDetails,
};
