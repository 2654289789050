import api from '../api';

export const fetchSctockValuation = (params = {}) => {
  return api.get(`/stock_valuation_diagnosis`, {
    params: {
      ...params,
    },
  });
};

export const updateSctockValuation = (body = {}) => {
  return api.post(`/stock_valuation_diagnosis`, body);
};
