import React from 'react';
import PropTypes from 'prop-types';
import ComponentsList from './components_list';
import TplGroup from './ui/components/formik/tpl_group';

const CallInputs = ({
  component,
  value,
  name,
  title = '',
  isRequired = false,
  hasTplGroup = true,
  columns = null,
  styleLabel = {},
  styleContent = {},
  ...rest
}) => {
  if (component && typeof component === 'object')
    return {
      ...component,
      props: {
        ...component.props,
        ...rest,
      },
    };

  const Component = ComponentsList[component];

  if (!Component && columns) {
    return (
      <TplGroup {...{name, title, isRequired, styleLabel, styleContent}}>
        <div className="_roww">
          {columns.map((column, index) => (
            <div key={`column-${index}`} className={`_coll ${index % 2 === 0 ? 'even' : 'odd'}`}>
              <CallInputs {...column} {...rest} />
            </div>
          ))}
        </div>
      </TplGroup>
    );
  }

  if (!Component && !columns) return null;

  if (!hasTplGroup) return <Component {...{name, title, isRequired, ...rest}} value={value || ''} />;

  return (
    <TplGroup {...{name, title, isRequired, styleLabel, styleContent}}>
      <Component {...{name, title, isRequired, ...rest}} value={value || ''} />
    </TplGroup>
  );
};

CallInputs.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  columns: PropTypes.array,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  styleContent: PropTypes.object,
  styleLabel: PropTypes.object,
};

export default CallInputs;
