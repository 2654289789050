import React, {useContext, useState, useEffect} from 'react';
import moment from 'moment';
import DataGrid from '../../components/DataGrid';
import {DataContext} from '../../utils/DataProvider';
import {createOrUpdateData, formatDateTime} from '../../utils/utils';
import {
  fetchInitialAnalysisArguments,
  registerInitialAnalysisArguments,
  changeInitialAnalysisArgumentsbyId,
  deleteInitialAnalysisArguments,
} from '../../services/initialAnalysisArguments';
import {fetchUsers} from '../../services/user';
import PrimaryFilterDisplay from '../../components/PrimaryFilterDisplay/PrimaryFilterDisplay';
import PrimaryFilterForm from '../../components/FilterForms/FilterSettings';
import {FILTER} from '../../utils/constants';

const InitialAnalysisArguments = () => {
  const {state, dispatch, stateFetchParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    dispatch({
      type: FILTER.ACTION.OPEN,
    });
  }, []);// eslint-disable-line

  useEffect(() => {
    const loadData = async () => {
      if (!state.harvest || !state.customer || !state.farm) {
        return;
      }
      const response = await fetchInitialAnalysisArguments(stateFetchParams);
      const userTemp = state.user;
      setUser({
        value: userTemp.id,
        label: userTemp.name,
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, state, stateFetchParams]);

  const columns = [
    {
      key: 'date',
      field: 'date',
      name: 'Data',
      textAlign: 'Center',
      width: '20%',
      editType: 'datepicker',
      popoverTitle: 'Periodo',
      popoverKey: 'period',
      popoverField: 'period',
      extraParams: {
        minDate: new Date(state?.harvest?.first_year, 6, 1),
        maxDate: new Date(state?.harvest?.last_year, 5, 30),
      },
      popoverPosition: {
        vertical: 'top',
        horizontal: 'right',
      },
      popoverClassTrigger: '.MuiButtonBase-root.MuiIconButton-root',
      popoverOptions: [
        {value: 'false', label: 'Diária'},
        {value: 'true', label: 'Mensal'},
      ],
      returnPopoverFormat: (value) => {
        // eslint-disable-next-line eqeqeq
        return value === 'true' || value;
      },
      popoverMapper: (line) => {
        return `${line.period}`;
      },
      mapper: (line, editMode) => {
        const [date] = line.date.split(' ');
        const [day, month, year] = date.split('/');

        if (!editMode) {
          // eslint-disable-next-line eqeqeq
          if (line.period === true) {
            return moment(new Date(year, month - 1, day))
              .locale('pt-br')
              .format('MM/YYYY');
          }

          return moment(new Date(year, month - 1, day))
            .locale('pt-br')
            .format('DD/MM/YYYY');
        }

        return moment(new Date(year, month - 1, day));
      },
      returnFormat: (value) => {
        if (moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00') === 'Invalid date') {
          return value;
        }
        return moment(value, 'D_M_YYYY').locale('pt-br').format('DD/MM/YYYY 00:00:00');
      },
    },
    {
      key: 'description',
      field: 'description',
      name: 'Descrição',
      required: true,
      textAlign: 'Center',
      width: '50%',
      editType: 'textedit',
    },
    {
      key: 'user',
      valueKey: 'user.name',
      field: 'user',
      name: 'Usuário',
      textAlign: 'Center',
      editType: 'dropdownedit',
      disabledEdit: true,
      defaultValue: user,
      optionsFetch: async () => {
        try {
          const objectTemp = [];
          const response = await fetchUsers();
          response.data.resultList.map((item) =>
            objectTemp.push({
              value: item.id,
              label: item.name,
            }),
          );
          return objectTemp;
        } catch (e) {
          return [];
        }
      },
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteInitialAnalysisArguments(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {state.filterOpened && <PrimaryFilterForm />}
      {!state.filterOpened && data && (
        <>
          <PrimaryFilterDisplay />
          <DataGrid
            onChange={async (dataGridInputs) => {
              delete dataGridInputs.updated_at;
              delete dataGridInputs.updated_by;
              delete dataGridInputs.created_at;
              delete dataGridInputs.created_by;
              delete dataGridInputs.customer;
              delete dataGridInputs.farm;
              delete dataGridInputs.harvest;
              delete dataGridInputs.date;
              if (!dataGridInputs.id) {
                const date = formatDateTime(new Date());
                Object.assign(dataGridInputs, {
                  date,
                  user: {
                    id: user.value,
                  },
                });
              }
              const response = await createOrUpdateData(dataGridInputs, state, registerInitialAnalysisArguments, changeInitialAnalysisArgumentsbyId);
              setReset(!reset);
              return response;
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            actions={actions}
            totalItems={totalItems}
          />
        </>
      )}
    </>
  );
};

export default InitialAnalysisArguments;
