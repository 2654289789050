/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import './style.scss';
import IMask from 'imask';
import DataGrid from '../../components/DataGrid';
import {getValueObject, getRowValue} from '../../utils/utils';
import columns from './AreaDataGridModel';
import {DataContext} from '../../utils/DataProvider';
import {fetchLiveStockAreas, updateLiveStockAreas} from '../../services/livestock';
import {fetchCategoryArea} from '../../services/categoryArea';

const AreaGrid = () => {
  const [data, setData] = useState(null);
  const [totalItems] = useState(0);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParamsCustom, stateMessage} = useContext(DataContext);

  const body = {
    created_by: {
      id: state.user.id,
    },
    harvest: {
      id: state.harvest.id,
    },
    customer: {
      id: state.customer.id,
    },
    status: false,
    farm: {id: state.farm.id},
  };

  const [colFixed, setColFixed] = useState({
    header: [
      {
        key: 'category',
        valueKey: 'category',
        height: '40%',
        field: 'category',
        name: 'Categorias',
        textAlign: 'Center',
        editType: 'defaultEdit',
        disabledEdit: true,
        disableFilter: true,
      },
    ],
    accumulator: [{title: 'Total'}],
  });
  useEffect(() => {
    const getContents = async () => {
      try {
        const response = await fetchCategoryArea({'wherenotin[id]': 4});

        const areaData = await fetchLiveStockAreas(stateFetchParamsCustom);
        const dataFixedColumn = [];
        response.data.resultList.map((element) => {
          return dataFixedColumn.push({category: element.title});
        });
        setColFixed((prev) => Object.assign(prev, {data: dataFixedColumn}));
        setData(areaData.data);
      } catch (error) {
        console.error(error);
      }
    };
    getContents();
  }, [update, stateFetchParamsCustom]);
  const getDate = (keyString) => {
    const keySplited = keyString.split('.');
    return keyString.indexOf('start_stock') > -1
      ? '01/01/1970 00:00:00'
      : `01/${parseInt(keySplited[1]) < 10 ? `0${parseInt(keySplited[1] ? keySplited[1] : '01')}` : parseInt(keySplited[1] ? keySplited[1] : '01')}/${
          keySplited[0] ? keySplited[0] : '01'
        } 00:00:00`;
  };
  const actions = [
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value, line, cel) => {
        const keyValue = cel;
        try {
          const newData = {
            category_area: {
              id: value.id,
            },
            date: getDate(keyValue),
            value: -1,
            validated: false,
          };

          const resp = await updateLiveStockAreas(Object.assign(body, newData));
          setUpdate(!update);
          return resp;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];
  const validateHeaderCallback = async (dataGridRows, field, checked, valueKey) => {
    const promises = [];
    dataGridRows.forEach((dataGridInputs) => {
      const keyValue = valueKey;
      const newData = {
        category_area: {
          id: dataGridInputs.id,
        },
        date: getDate(keyValue),
        value: getRowValue(dataGridInputs, field, valueKey, true, null, field) || null,
        validated: checked,
      };
      promises.push(updateLiveStockAreas(Object.assign(newData, body)));
    });

    const response = await Promise.all(promises);
    setUpdate(!update);
    return response;
  };

  return (
    <>
      {stateMessage}
      {data && (
        <div className="livesotckStock">
          <DataGrid
            onChange={async (dataGridInputs) => {
              try {
                const keyValue = Object.keys(dataGridInputs).filter((item) => item.split('.').length > 1)[0];
                if (keyValue) {
                  const newData = {
                    category_area: {
                      id: dataGridInputs.id,
                    },
                    date: getDate(keyValue),
                    value: parseFloat(dataGridInputs[keyValue]),
                    validated: dataGridInputs.validated || false,
                  };

                  const resp = await updateLiveStockAreas(Object.assign(body, newData));
                  setUpdate(!update);
                  return resp;
                }
                return {status: 201};
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={columns(state.harvest.first_year, state.harvest.last_year)}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            editable=""
            editableCelOnly
            fixedColumn={colFixed || null}
            actions={actions}
            setParams={stateFetchParamsCustom}
            totalItems={totalItems}
            disabledPaginate
            validateCallback={validateHeaderCallback}
            maxHeight="100%"
            accumulators={{
              Total: {
                columns: ['value'],
                columnType: 'nested',
                hideTitle: true,
                itemOperation: (acc, line, key) => {
                  acc += getValueObject(line, null, key) !== '' ? getValueObject(line, null, key) : 0;
                  return acc;
                },
                resultOperation: (acc) => {
                  return IMask.createMask({
                    mask: 'number',
                    blocks: {
                      number: {
                        mask: Number, // enable number mask

                        // other options are optional with defaults below
                        scale: 2, // digits after point, 0 for integers
                        signed: false, // disallow negative
                        thousandsSeparator: '.', // any single char
                        padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
                        normalizeZeros: true, // appends or removes zeros at ends
                        radix: ',', // fractional delimiter

                        // additional number interval options (e.g.)
                        min: 0,
                        max: 999999999999,
                      },
                    },
                  }).resolve(acc.toString());
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default AreaGrid;
