import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Heading = styled.h2.attrs((props) => ({
  as: props.attr,
}))`
  position: relative;
  margin-bottom: 20px;
  color: #313131;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

Heading.defaultProps = {
  attr: 'h2',
};

Heading.propTypes = {
  attr: PropTypes.oneOf(['h1', 'h2', 'h3']).isRequired,
};
