import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const mapValidations = (groupFields) => {
  const validations = {};

  groupFields.forEach((group) => {
    if (!Array.isArray(group)) {
      group.fields.forEach((field) => {
        if (field.validations && !field.hideField) {
          Object.assign(validations, {...field.validations});
        }
      });
    } else {
      group.forEach((groudField) => {
        groudField.fields.forEach((field) => {
          if (field.validations && !field.hideField) {
            Object.assign(validations, {...field.validations});
          }
        });
      });
    }
  });

  return yup.object().shape(validations);
};
