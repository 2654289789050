import {isObject} from '../../utils/utils';

const valueFormatter = (value, field, data) => {
  const dataToReturn = data[0][field];
  if (dataToReturn && isObject(value)) {
    dataToReturn.precipitation = parseInt(value.precipitation || '0', 10);
    return dataToReturn;
  }

  if (dataToReturn && value) {
    dataToReturn.precipitation = parseInt(value, 10);
    return dataToReturn;
  }

  dataToReturn.precipitation = null;
  return dataToReturn;
};

const totalMapper = (line) => {
  const total = Object.keys(line).reduce((acc, index) => {
    acc += line[index].precipitation
      ? parseInt(line[index].precipitation, 10)
      : 0;
    return acc;
  }, 0);

  return total;
};

const validationFindData = (data, field) => {
  const dataFound = data[0][field] ? data[0][field] : false;
  return (dataFound && dataFound.validated) || false;
};

const climatesColumns = (climatesFirstYear = '', climatesLastYear = '') => [
  {
    field: 'label',
    name: 'Acumulado Pluviométrico Mensal',
    disableFilter: true,
    subFields: [
      {
        field: climatesFirstYear,
        disableFilter: true,
        subFields: [
          {
            key: '7',
            valueKey: '7.precipitation',
            field: '7',
            name: 'Jul/'.concat(climatesFirstYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '8',
            valueKey: '8.precipitation',
            field: '8',
            name: 'Ago/'.concat(climatesFirstYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '9',
            valueKey: '9.precipitation',
            field: '9',
            name: 'Set/'.concat(climatesFirstYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '10',
            valueKey: '10.precipitation',
            field: '10',
            name: 'Out/'.concat(climatesFirstYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '11',
            valueKey: '11.precipitation',
            field: '11',
            name: 'Nov/'.concat(climatesFirstYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '12',
            valueKey: '12.precipitation',
            field: '12',
            name: 'Dez/'.concat(climatesFirstYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
        ],
        name: climatesFirstYear,
        textAlign: 'Center',
      },
      {
        field: climatesLastYear,
        disableFilter: true,
        subFields: [
          {
            key: '1',
            valueKey: '1.precipitation',
            field: '1',
            name: 'Jan/'.concat(climatesLastYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '2',
            valueKey: '2.precipitation',
            field: '2',
            name: 'Fev/'.concat(climatesLastYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '3',
            valueKey: '3.precipitation',
            field: '3',
            name: 'Mar/'.concat(climatesLastYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '4',
            valueKey: '4.precipitation',
            field: '4',
            name: 'Abr/'.concat(climatesLastYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '5',
            valueKey: '5.precipitation',
            field: '5',
            name: 'Mai/'.concat(climatesLastYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
          {
            key: '6',
            valueKey: '6.precipitation',
            field: '6',
            name: 'Jun/'.concat(climatesLastYear.toString().substring(2, 4)),
            textAlign: 'Center',
            returnFormat: valueFormatter,
            validationHeader: {validationFindData},
            editType: 'numericedit',
          },
        ],
        name: climatesLastYear,
        textAlign: 'Center',
      },
      {
        field: 'Total',
        disableFilter: true,
        subFields: [
          {
            disabled: true,
            key: 'total',
            disableFilter: true,
            valueKey: 'total',
            field: 'total',
            name: 'Total',
            textAlign: 'Center',
            editType: 'defaultedit',
            mapper: totalMapper,
            disabledEdit: true,
            virtualColumn: true,
          },
        ],
      },
    ],
  },
];

export default climatesColumns;
