import React, {createContext, useContext, useState} from 'react';

const ModuleNameContext = createContext();
const ModuleNameContextUpdate = createContext();

export const useModuleNameContext = () => {
  return useContext(ModuleNameContext);
};

export const useModuleNameContextUpdate = () => {
  return useContext(ModuleNameContextUpdate);
};

const ModuleNameProvider = ({children}) => {
  const [moduleName, setModuleName] = useState('');

  const updateModuleName = (value) => {
    if (value) {
      setModuleName(value);
    }
  };

  return (
    <ModuleNameContext.Provider value={moduleName}>
      <ModuleNameContextUpdate.Provider value={updateModuleName}>
        {children}
      </ModuleNameContextUpdate.Provider>
    </ModuleNameContext.Provider>
  );
};

export default ModuleNameProvider;
