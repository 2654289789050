import React from 'react';
import PropTypes from 'prop-types';
import {GuardedRoute} from 'react-router-guards';

const TemplateRouteData = ({data}) => {
  const routes = [];

  data.forEach(({options}) => {
    options.forEach(({options: opts}) => {
      opts
        .filter((item) => item.component && item.url)
        .forEach((item) => {
          routes.push(item);
        });
    });
  });

  return (
    <>
      {routes.map(({url, title, component: Component}, index) => (
        <GuardedRoute key={index} path={url} meta={{moduleName: title}} component={Component} />
      ))}
    </>
  );
};

TemplateRouteData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          options: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string.isRequired,
              url: PropTypes.string,
              component: PropTypes.func,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ),
};
export default TemplateRouteData;
