/* eslint-disable camelcase */
/* eslint-disable radix */
import React, {useEffect, useState, useContext} from 'react';
import DataGrid from '../../../../components/DataGrid';
import {DataContext} from '../../../../utils/DataProvider';
import {createOrUpdateDataFull} from '../../../../utils/utils';
import {createReproductionDiagnosis, fetchReproductionDiagnosis} from '../../../../services/reproductionDiagnosis';

const Reproduction = () => {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const {state, stateFetchParamsCustom, stateMessage} = useContext(DataContext);

  const colFixed = {
    header: [
      {
        key: 'harvest',
        valueKey: 'harvest',
        height: 'auto',
        field: 'harvest',
        name: ' ',
        width: '100px',
        disableFilter: true,
        textAlign: 'Center',
        editType: 'defaultedit',
        disabledEdit: true,
        tooltipsCel: [
          {
            value: `Referente aos bezerros nascidos entre jul ${state?.harvest?.first_year - 1} e jun ${state?.harvest?.last_year - 1}`,
          },
          {
            value: `Referente aos bezerros nascidos entre jul ${state?.harvest?.first_year} e jun ${state?.harvest?.last_year}`,
          },
        ],
      },
    ],
    data: [
      {
        harvest: `SAFRA ${state?.harvest?.first_year - 1}/${state?.harvest?.last_year - 1}`,
        date_harvest: false,
      },
      {
        harvest: `SAFRA ${state?.harvest?.first_year}/${state?.harvest?.last_year}`,
        date_harvest: false,
      },
    ],
  };
  const columns = [
    {
      key: 'matrizes_monta',
      field: 'matrizes_monta',
      required: true,
      disableFilter: true,
      name: 'MATRIZES EM MONTA',
      textAlign: 'Center',
      editType: 'numericedit',
      tooltip: `Total de matrizes em monta no período`,
    },
    {
      key: 'matrizes_prenhes',
      field: 'matrizes_prenhes',
      required: true,
      disableFilter: true,
      name: 'MATRIZES PRENHES',
      textAlign: 'Center',
      editType: 'numericedit',
      tooltip: `Matrizes Prenhes no Toque no período`,
    },
    {
      key: 'bezerros_nascidos',
      field: 'bezerros_nascidos',
      required: true,
      disableFilter: true,
      name: 'BEZERROS NASCIDOS',
      textAlign: 'Center',
      editType: 'numericedit',
      disabledEdit: (dataState) => {
        if (dataState.data.harvest === 'current_harvest') {
          return true;
        }
        return false;
      },
      style: (line) => {
        if (line.harvest === 'current_harvest') {
          return {
            backgroundColor: 'rgb(219,237,208)',
          };
        }
        return {
          backgroundColor: 'transparent',
        };
      },
      tooltip: `Total de Bezerros nascidos entre julho do ano inicial da safra e junho do ano final da safra.
       Número deve ser igual ao preenchido na planilha de Balanço do Rebanho.\n\n Não alterar esta célula!`,
    },
    {
      key: 'bezerros_desmame',
      field: 'bezerros_desmame',
      required: true,
      disableFilter: true,
      name: 'BEZERROS DESM.',
      textAlign: 'Center',
      editType: 'numericedit',
      tooltip: `Total de Bezerros Desmamados no período`,
    },
    {
      key: 'peso_desmame',
      field: 'peso_desmame',
      required: true,
      disableFilter: true,
      name: 'PESO AO DESMAME',
      textAlign: 'Center',
      editType: 'numericedit',
      tooltip: `Média de pesos de todos os bezerros desmamados no período (machos e fêmeas)`,
    },
  ];

  useEffect(() => {
    const loadStocks = async () => {
      const response = await fetchReproductionDiagnosis({
        customer_id: stateFetchParamsCustom.customer_id,
        harvest_id: stateFetchParamsCustom.harvest_id,
        farm_id: stateFetchParamsCustom.farm_id,
      });

      setData([
        {
          ...response.data.previous_harvest,
          harvest: 'previous_harvest',
          id: 2,
        } || {
          bezerros_desmame: null,
          bezerros_nascidos: null,
          matrizes_monta: null,
          matrizes_prenhes: null,
          peso_desmame: null,
          harvest: 'previous_harvest',
          id: 2,
        },
        {...response.data.current_harvest, harvest: 'current_harvest', id: 1},
      ]);
    };
    loadStocks();
  }, [update]);// eslint-disable-line

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
      hide: true,
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value, line, key) => {
        const {harvest} = value;
        const other_harvest = harvest === 'current_harvest' ? data[0] : data[1];
        delete value.harvest;
        delete value.id;
        delete other_harvest.harvest;
        delete other_harvest.id;
        const obj = {
          [harvest === 'current_harvest' ? 'current_harvest' : 'previous_harvest']: {
            ...value,
            [key]: null,
          },
          [harvest === 'current_harvest' ? 'previous_harvest' : 'current_harvest']: {...other_harvest},
        };
        try {
          await createOrUpdateDataFull({...obj}, state, createReproductionDiagnosis, createReproductionDiagnosis, false);
          setUpdate(!update);
          return null;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
      onClick: () => {},
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {stateMessage}
      <div className="inputDistribution livesotckStock fixedHeader">
        {!!data?.length && (
          <DataGrid
            onChange={async (dataGridInputs) => {
              const {harvest} = dataGridInputs;
              const other_harvest = harvest === 'current_harvest' ? data[0] : data[1];
              delete dataGridInputs.harvest;
              delete dataGridInputs.id;
              delete other_harvest.harvest;
              delete other_harvest.id;
              const obj = {
                [harvest === 'current_harvest' ? 'current_harvest' : 'previous_harvest']: {
                  ...dataGridInputs,
                },
                [harvest === 'current_harvest' ? 'previous_harvest' : 'current_harvest']: {...other_harvest},
              };
              try {
                const res = await createOrUpdateDataFull({...obj}, state, createReproductionDiagnosis, createReproductionDiagnosis, false);
                setUpdate(!update);
                return res;
              } catch (error) {
                console.error(error);
                return error;
              }
            }}
            header={columns}
            data={data}
            keyProp="key"
            keyValueProp="valueKey"
            titleProp="name"
            fixedColumn={colFixed || null}
            editable=""
            disabledPaginate
            width="100%"
            actions={actions}
          />
        )}
      </div>
    </>
  );
};

export default Reproduction;
