import React, {useContext, useState, useEffect} from 'react';
import DataGrid from '../../../components/DataGrid';
import {DataContext} from '../../../utils/DataProvider';
import {createOrUpdateData} from '../../../utils/utils';
import {fetchCategoryArea, registerCategoryArea, changeCategoryAreabyId, deleteCategoryArea} from '../../../services/categoryArea';

const CategoryArea = () => {
  const {state, paginationParams, filterParams} = useContext(DataContext);
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchCategoryArea({
        ...paginationParams,
        ...filterParams,
      });
      setData(response.data.resultList);
      setTotalItems(response.data.size);
    };

    loadData();
  }, [reset, paginationParams, filterParams]);

  const columns = [
    {
      key: 'title',
      field: 'title',
      name: 'Título',
      textAlign: 'Center',
      editType: 'textedit',
    },
  ];

  const actions = [
    {
      title: 'Adicionar',
      action: 'add',
      icon: 'fas fa-plus',
    },
    {
      title: 'Editar',
      action: 'edit',
      icon: 'fas fa-pencil-alt',
    },
    {
      title: 'Excluir',
      action: 'remove',
      icon: 'fas fa-trash-alt',
      onClick: async (value) => {
        try {
          const response = await deleteCategoryArea(value.id);
          setReset(!reset);
          return response;
        } catch (error) {
          console.error(error);
          return error;
        }
      },
    },
    {
      title: 'Salvar',
      action: 'save',
      icon: 'fas fa-save',
    },
    {
      title: 'Cancelar',
      action: 'cancel',
      icon: 'fas fa-times',
    },
  ];

  return (
    <>
      {data && (
        <DataGrid
          onChange={async (dataGridInputs) => {
            const response = await createOrUpdateData(dataGridInputs, state, registerCategoryArea, changeCategoryAreabyId, false);
            setReset(!reset);
            return response;
          }}
          header={columns}
          data={data}
          keyProp="key"
          keyValueProp="valueKey"
          titleProp="name"
          editable=""
          actions={actions}
          totalItems={totalItems}
        />
      )}
    </>
  );
};

export default CategoryArea;
