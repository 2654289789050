import React, {useState} from 'react';
import {GuardedRoute} from 'react-router-guards';
import Section from '../../../../components/Section/Section';
import './style.scss';
import TemplateRouteData from '../../components/template-route-data';
import Income from './modules/Income';
import Expenses from './modules/Expenses';

const Financials = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Financeiro',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Receitas',
              url: `${match.url}/financeiro/receitas`,
              component: Income,
            },
            {
              title: 'Despesas',
              url: `${match.url}/financeiro/despesas`,
              component: Expenses,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <div className="financials">
      <GuardedRoute exact path={match.url} meta={{moduleName: 'Financeiro'}} render={() => <Section templateData={templateData} />} />

      <TemplateRouteData data={templateData} />
    </div>
  );
};
export default Financials;
