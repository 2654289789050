import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {FormInput} from '../form';

const Input = ({
  name,
  title,
  type,
  placeholder,
  isRequired,
  tabIndex,
  disabled,
  actions,
  ...el
}) => {
  const options = {
    ...(el.max && {max: el.max}),
    ...(el.maxLength && {max: el.maxLength}),
    ...(el.readonly && {readonly: el.readonly}),
  };

  const handleChange = (event) => {
    if (actions.setFieldValue) {
      actions.setFieldValue(name, event.target.value);
    }
  };

  return (
    <Field
      type={type || 'text'}
      name={name}
      placeholder={placeholder || ''}
      tabIndex={tabIndex}
      disabled={!!disabled}
      {...options}
      onChange={handleChange}
      as={FormInput}
    />
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
};
export default Input;
