import '../style.scss';
import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../../../utils/DataProvider';
import {AgricultureCategories, ExpensesAgriculture, ExpensesItemAgriculture} from '../services/AgricultureServices';
import {DatagridActionsHelper} from '../../../utils/datagridActionsHelper';
import DataGrid from '../../../../../components/DataGrid';
import GenerateForm from '../../../../../components/GenerateForm';
import {fieldForm} from '../helper/AgricultureExpenseFormModel';
import Alert from '../../../../../components/Notification';
import Breadcrumbs from '../../../../../components/Breadcrumbs';

const RegisterAgriculture = ({match}) => {
  const {state, stateFetchParams, stateMessage, filterParams} = useContext(DataContext);
  const [data, setData] = useState([]);
  const [reset, setReset] = useState(false);
  const [values, setValues] = useState(null);
  const [dropdown, setDropdown] = useState(true);
  const [alertProps, setAlertProps] = useState(null);
  const [costCenterTemp, setCostCenterTemp] = useState(null);

  useEffect(() => {
    (async () => {
      if (match?.params?.id || values?.id) {
        try {
          const response = await ExpensesAgriculture.find(match?.params?.id || values?.id);
          const responseItems = await ExpensesItemAgriculture.list({
            'wherein[agricultureExpense.id]': response?.data?.id,
          });

          setValues({...response?.data, cost_center_description: {label: response?.data?.cost_center_description?.title}});
          setData(
            responseItems?.data?.resultList?.map((item) => ({
              ...item,
              agriculture_cost_category: item?.agriculture_cost_subcategory?.agriculture_cost_category,
            })),
          );
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [JSON.stringify(stateFetchParams).toLowerCase(), JSON.stringify(filterParams).toLowerCase(), reset]);// eslint-disable-line

  const actions = DatagridActionsHelper.actions(
    {
      handleDelete: async (value) => {
        setData((prev) => prev?.filter((item) => item?.id !== value?.id));
        return {status: 201};
      },
    },
    {
      edit: match?.params?.id || values?.id,
      remove: match?.params?.id || values?.id,
      save: match?.params?.id || values?.id,
      cancel: match?.params?.id || values?.id,
      add: match?.params?.id || values?.id,
    },
  );
  const disableLine = () => {
    return match?.params?.id || values?.id;
  };

  const columns = [
    {
      key: 'agriculture_cost_category',
      valueKey: 'agriculture_cost_category.title',
      field: 'agriculture_cost_category',
      required: true,
      name: 'Centro de custo(Volumoso/Concentrado)',
      textAlign: 'center',
      width: '25%',
      editType: 'dropdownedit',
      disabledDoubleClick: disableLine,
      extraParams: {
        formatedValue: (col) => {
          if (col?.mode === 'NEW_LINE') {
            setCostCenterTemp((prev) => ({
              ...prev,
              agriculture_cost_category: {title: col?.data?.agriculture_cost_category?.label, id: col?.data?.agriculture_cost_category?.value},
            }));
          }
          return null;
        },
      },
      optionsFetch: async () => {
        const options = await AgricultureCategories.listCostCategories({
          expense_type: values?.agriculture_expense_type,
        });
        return options?.data?.resultList?.map((item) => ({
          value: item.id,
          label: item.title,
        }));
      },
    },
    {
      key: 'agriculture_cost_subcategory',
      valueKey: 'agriculture_cost_subcategory.title',
      field: 'agriculture_cost_subcategory',
      required: true,
      name: 'Descrição',
      textAlign: 'center',
      width: '30%',
      editType: 'dropdownedit',
      disabledDoubleClick: disableLine,
      dependencies: ['agriculture_cost_category'],
      extraParams: {
        formatedValue: (col) => {
          if (col?.mode === 'NEW_LINE') {
            setCostCenterTemp((prev) => ({
              ...prev,
              agriculture_cost_subcategory: {title: col?.data?.agriculture_cost_subcategory?.label, id: col?.data?.agriculture_cost_subcategory?.value},
            }));
          }
          return null;
        },
      },
      optionsFetch: async (_col, dataGridState) => {
        const options = await AgricultureCategories.listSubCostCategories({
          'wherein[agricultureCostCategory.id]': dataGridState?.data?.agriculture_cost_category?.value,
        });
        return options?.data?.resultList?.map((item) => ({
          value: item.id,
          label: item.title,
        }));
      },
    },
    {
      key: 'volume',
      field: 'volume',
      name: 'Quantidade',
      width: '15%',
      textAlign: 'Center',
      required: true,
      editType: 'numericedit',
      disabledDoubleClick: disableLine,
      extraParams: {
        minValue: 1,
      },
    },
    {
      key: 'total_cost',
      field: 'total_cost',
      name: 'Valor Total',
      width: '15%',
      textAlign: 'Center',
      editType: 'moneyedit',
      disabledDoubleClick: disableLine,
      // dependencies: ['unit_value'],
      extraParams: {
        minValue: 1,
        formatedValue: (col) => {
          if ((col?.mode === 'NEW_LINE' && col.data.aux === 'unit_cost') || (col.mode === 'EDIT' && col?.data?.status)) {
            return parseFloat(col?.data?.unit_cost) * parseFloat(col?.data?.volume) || null;
          }
          return null;
        },
      },
      required: (col) => {
        if ((col?.mode === 'NEW_LINE' && col?.data?.aux === 'unit_cost') || (col.mode === 'EDIT' && col?.data?.status)) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        if (col?.data?.total_cost?.length > 0 && !col.data.aux) {
          col.data.aux = 'total_cost';
        }
        if (col?.data?.total_cost?.length === 0 && col.data.aux === 'total_cost') {
          col.data.aux = null;
        }
        if ((col?.mode === 'NEW_LINE' && col?.data?.aux === 'unit_cost') || (col.mode === 'EDIT' && col?.data?.status)) {
          return true;
        }
        return false;
      },
    },
    {
      key: 'unit_cost',
      field: 'unit_cost',
      name: 'Valor Unitário',
      width: '15%',
      textAlign: 'Center',
      disabledDoubleClick: disableLine,
      editType: 'moneyedit',
      // dependencies: ['value'],
      extraParams: {
        minValue: 1,
        formatedValue: (col) => {
          if ((col?.mode === 'NEW_LINE' && col.data.aux === 'total_cost') || (col.mode === 'EDIT' && !col?.data?.status)) {
            return parseFloat(col?.data?.total_cost) / parseFloat(col?.data?.volume) || null;
          }
          return null;
        },
      },
      required: (col) => {
        if ((col?.mode === 'NEW_LINE' && col?.data?.aux === 'total_cost') || (col.mode === 'EDIT' && !col?.data?.status)) {
          return false;
        }
        return true;
      },
      disabledEdit: (col) => {
        if (col?.data?.unit_cost?.length > 0 && !col.data.aux) {
          col.data.aux = 'unit_cost';
        }
        if (col?.data?.unit_cost?.length === 0 && col.data.aux === 'unit_cost') {
          col.data.aux = null;
        }
        if ((col?.mode === 'NEW_LINE' && col?.data?.aux === 'total_cost') || (col.mode === 'EDIT' && !col?.data?.status)) {
          return true;
        }
        return false;
      },
    },
  ];

  const submitExpenseAgriculture = async (inputs, actionsSubmit) => {
    try {
      const body = {
        harvest: {id: state?.harvest?.id},
        farm: {id: state?.farm?.id},
        cost_center_description: {
          ...(inputs?.cost_center_description && {title: inputs?.cost_center_description?.inputValue || inputs?.cost_center_description?.label}),
        },
        agriculture_expense_type: inputs?.agriculture_expense_type,
        planted_area: parseFloat(inputs?.planted_area) || 0,
        ...(parseFloat(inputs?.production) && {production: parseFloat(inputs?.production) || 0}),
        ...(parseFloat(inputs?.annual_productivity) && {annual_productivity: parseFloat(inputs?.annual_productivity) || 0}),
        loss: parseFloat(inputs?.loss) || 0,
        initial_dry_matter: parseFloat(inputs?.initial_dry_matter) || 0,
        final_dry_matter: parseFloat(inputs?.final_dry_matter) || 0,

        ...(inputs?.bag_weight && {bag_weight: parseFloat(inputs?.bag_weight) || 0}),

        created_by: {id: state?.user?.id},
      };
      const resp = await ExpensesAgriculture.post(body);
      const promisses = data?.map((item) => ({
        agriculture_expense: {id: resp?.data?.id},
        agriculture_cost_subcategory: {id: item?.agriculture_cost_subcategory?.id},
        total_cost: item?.total_cost,
        volume: item?.volume,
        unit_cost: item?.unit_cost,
        created_by: item?.created_by,
      }));

      ExpensesItemAgriculture.post(promisses);

      // await Promise.all(promisses);
      setReset(!reset);
      setValues({...resp.data, cost_center_description: {label: resp.data?.cost_center_description?.title}});
      actionsSubmit.setSubmitting(false);
      setAlertProps({
        type: 'success',
        message: 'Novo Registro Salvo com Sucesso!',
        time: 5000,
      });
    } catch (error) {
      setAlertProps(null);
      actionsSubmit.setSubmitting(false);
      setAlertProps({
        type: 'error',
        message: error?.response?.data?.message,
        time: 5000,
      });
      console.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Breadcrumbs
        path={[
          // {title: 'Leite', url: null},
          {title: 'Lançamentos', url: '/leite/lancamentos'},
          {title: 'Agricultura', url: '/leite/lancamentos/agricultura'},
          {title: 'Cadastro', url: null},
        ]}
      />
      {stateMessage}
      {
        <div className="agriculuture-expense-page">
          <div className="p-4  agriculuture-expense">
            <GenerateForm
              onSubmit={
                data?.length && !values?.id
                  ? async (_values, actionsSubmit) => {
                      await submitExpenseAgriculture(values, actionsSubmit);
                    }
                  : null
              }
              groupFields={fieldForm(values, setValues, dropdown, setDropdown, !!values?.id || !!match?.params?.id)}
              settings={{
                button: {text: '', textSubmitting: ''},
                back_button: {text: 'Voltar'},
                // hide: true,
              }}
              // showBackButton
              // onBack={() => setPage(page > 0 ? page - 1 : page)}
            />
          </div>
          {values?.cost_center_description && (
            <div className="p-4 grid-box">
              <DataGrid
                onChange={async (inputs) => {
                  inputs = {
                    ...inputs,
                    ...(inputs.volume && {volume: parseFloat(inputs.volume)}),
                    ...(inputs.total_cost && {total_cost: parseFloat(inputs.total_cost)}),
                    ...(inputs.unit_cost && {unit_cost: parseFloat(inputs.unit_cost)}),
                  };

                  if (inputs.total_cost) {
                    inputs.unit_cost = inputs.total_cost / inputs.volume || 1;
                  } else if (inputs.unit_cost) {
                    inputs.total_cost = inputs.unit_cost * inputs.volume || 1;
                  }

                  // delete inputs.agriculture_cost_category;
                  delete inputs.aux;
                  inputs.agriculture_expense = {id: values?.id};
                  inputs.created_by = {id: state?.user?.id};
                  try {
                    // const res = await ExpensesItemAgriculture.post(inputs);
                    setData((prev) => [{...inputs, ...costCenterTemp, id: prev?.length + 1}, ...prev]);
                    // setReset(!reset);
                    return {status: 201};
                  } catch (error) {
                    return error;
                  }
                }}
                // validateCallback={handleValidateCallback}
                header={columns}
                data={data}
                width="inherit"
                keyProp="key"
                keyValueProp="valueKey"
                titleProp="name"
                editable=""
                actions={actions}
                totalItems={data?.length}
              />
            </div>
          )}
          {alertProps && <Alert type={alertProps.type} message={alertProps.message} time={alertProps.time} />}
        </div>
      }
    </>
  );
};
export default RegisterAgriculture;
