/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useContext} from 'react';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DataGrid from '../../components/DataGrid';
import GenerateForm from '../../components/GenerateForm';
import TableView from '../../components/TableView';
import {fetchBenchmark, fetchIndicator, fetchLastUpdated, fetchstatusHarvest, saveData} from '../../services/benchmark';
import {fetchCategoryLivestockSystem} from '../../services/categoryLivestockSystem';
import {fetchFarmsDetails} from '../../services/farmsDetails';
import {fetchHarvests} from '../../services/harvests';
import {DataContext} from '../../utils/DataProvider';
import {normalizeCollectionData} from '../../utils/utils';
import {FilterBox, H2, GridContainer} from './styled';

const BenchmarkDashboard = () => {
  const {state} = useContext(DataContext);
  const [status, setStatus] = useState(null);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(0);
  const [optionsFilter, setOptionsFilter] = useState(null);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalizer, setTotalizer] = useState(0);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';

  useEffect(() => {
    (async () => {
      const harvests = await fetchHarvests();
      const categoryLivestock = await fetchCategoryLivestockSystem();
      const indicatorData = await fetchIndicator({environment: ENVS});

      setOptionsFilter((prev) => ({
        ...prev,
        harvests: harvests?.data?.resultList || [],
        category_livestock_system: categoryLivestock?.data?.resultList || [],
        indicator: indicatorData?.data?.resultList || [],
      }));
    })();
  }, []);// eslint-disable-line

  useEffect(() => {
    (async () => {
      const farmsData = await fetchFarmsDetails({
        'wherein[harvest.id]': filter?.harvest?.value,
        // 'wherein[categoryLivestockSystem.id]': filter?.category_livestock_system?.value,
        environment: ENVS,
      });
      setOptionsFilter((prev) => ({
        ...prev,
        farms: farmsData?.data?.resultList.map((item) => ({...item?.farm})) || [],
      }));
    })();
  }, [filter?.harvest?.value, filter?.category_livestock_system?.value]);// eslint-disable-line

  const submit = async (_values, actions) => {
    const benchmark = await fetchBenchmark({
      harvest_id: filter?.harvest?.value,
      indicator_id: filter?.indicator?.value,
      category_livestock_system_ids: filter?.category_livestock_system?.map((item) => item?.value).join(','),
      ...(filter?.farm_type?.value && {from_diagnosis: filter?.farm_type?.value === 'diagnosis'}),
      ...(_values?.enter_benchmarking && {enter_benchmarking: _values?.enter_benchmarking === 'true'}),
      ...(_values?.validated && {validated: _values?.validated === 'true'}),
      farm_id: filter?.farm?.value,
      environment: ENVS,
    });
    setData(
      benchmark?.data?.resultList?.map((item) => ({
        ...item,
        category_livestock_title: item?.category_livestock_system?.title,
        indicator_string: item?.indicator_benchmarking?.title,
        farm_string: item?.farm?.name,
        updated: `${item?.updated_by?.name || ''} ${item?.updated_at ? `(${item?.updated_at})` : ''}`,
        average_herd_string: Intl.NumberFormat().format(Math.round(parseFloat(item?.average_herd))),
        disbursement_head_string: `R$ ${parseFloat(item?.disbursement_head)?.toFixed(2)}`,
        environment: ENVS,
      })),
    );
    setTotalizer(benchmark?.data?.resultList?.filter((item) => item?.enter_benchmarking)?.length);

    if (actions) {
      actions.setSubmitting(false);
    }
  };
  const updateRows = async () => {
    setLoading(true);
    await saveData(
      updatedRows?.map((item) => {
        delete item?.value_str;
        delete item?.diagnosis_or_platform;
        delete item?.indicator_string;
        delete item?.updated;
        delete item?.farm_string;
        delete item?.category_livestock_title;
        delete item?.disbursement_head;
        delete item?.updated_at;
        delete item?.disbursement_head_string;
        delete item?.average_herd_string;
        delete item?.confinement;

        return {
          ...item,
          value: parseFloat(item?.value),
          updated_by: {id: state?.user?.id},
        };
      }),
    );
    await submit();
    setUpdatedRows([]);
    setLoading(false);
  };

  const columns = [
    {
      key: 'category_livestock_title',
      field: 'category_livestock_title',
      name: 'Sistema',
      textAlign: 'Center',
      width: '10%',
      editType: 'textedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'diagnosis_or_platform',
      field: 'diagnosis_or_platform',
      name: 'Modalidade',
      textAlign: 'Center',
      width: '10%',
      editType: 'textedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'farm_string',
      field: 'farm_string',
      name: 'Fazenda',
      textAlign: 'Center',
      width: '8%',
      editType: 'textedit',
      tooltipKey: 'confinement',
      extraParams: {
        minValue: 1,
      },
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'average_herd_string',
      field: 'average_herd_string',
      name: 'Rebanho Médio',
      textAlign: 'Center',
      width: '10%',
      editType: 'numericedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'disbursement_head_string',
      field: 'disbursement_head_string',
      name: 'Desembolso Cab./mês',
      textAlign: 'Center',
      width: '10%',
      editType: 'numericedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'indicator_string',
      field: 'indicator_string',
      name: 'Indicador',
      textAlign: 'Center',
      width: '10%',
      editType: 'textedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'value_str',
      field: 'value_str',
      name: 'Número',
      textAlign: 'Center',
      width: '8%',
      editType: 'textedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'enter_benchmarking',
      field: 'enter_benchmarking',
      name: 'Participa?',
      width: '8%',
      textAlign: 'Center',
      editType: 'booleanedit',
      disableFilter: true,
      checkAll: (
        <input
          type="checkbox"
          className="ml-2"
          checked={data?.length && !data?.find((item) => !item?.enter_benchmarking)}
          onChange={(element) => {
            const newData = data?.map((item) => ({...item, enter_benchmarking: element.target.checked}));
            setData(newData);
            setUpdatedRows(newData);
          }}
        />
      ),
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Avaliado',
      width: '8%',
      textAlign: 'Center',
      editType: 'booleanedit',
      disableFilter: true,
      checkAll: (
        <input
          type="checkbox"
          className="ml-2"
          checked={data?.length && !data?.find((item) => !item?.validated)}
          onChange={(element) => {
            const newData = data?.map((item) => ({...item, validated: element.target.checked}));
            setData(newData);
            setUpdatedRows(newData);
          }}
        />
      ),
    },
    {
      key: 'updated',
      field: 'updated',
      name: 'Validado por',
      width: '18%',
      textAlign: 'Center',
      editType: 'textedit',
      disableFilter: true,
    },
  ];

  const dairyColumns = [
    {
      key: 'farm_string',
      field: 'farm_string',
      name: 'Fazenda',
      textAlign: 'Center',
      width: '12%',
      editType: 'textedit',
      tooltipKey: 'confinement',
      extraParams: {
        minValue: 1,
      },
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'average_herd_string',
      field: 'average_herd_string',
      name: 'Rebanho Médio',
      textAlign: 'Center',
      width: '14%',
      editType: 'numericedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'indicator_string',
      field: 'indicator_string',
      name: 'Indicador',
      textAlign: 'Center',
      width: '14%',
      editType: 'textedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'value_str',
      field: 'value_str',
      name: 'Número',
      textAlign: 'Center',
      width: '12%',
      editType: 'textedit',
      disabledEdit: true,
      disableFilter: true,
    },
    {
      key: 'enter_benchmarking',
      field: 'enter_benchmarking',
      name: 'Participa?',
      width: '12%',
      textAlign: 'Center',
      editType: 'booleanedit',
      disableFilter: true,
      checkAll: (
        <input
          type="checkbox"
          className="ml-2"
          checked={data?.length && !data?.find((item) => !item?.enter_benchmarking)}
          onChange={(element) => {
            const newData = data?.map((item) => ({...item, enter_benchmarking: element.target.checked}));
            setData(newData);
            setUpdatedRows(newData);
          }}
        />
      ),
    },
    {
      key: 'validated',
      field: 'validated',
      name: 'Avaliado',
      width: '12%',
      textAlign: 'Center',
      editType: 'booleanedit',
      disableFilter: true,
      checkAll: (
        <input
          type="checkbox"
          className="ml-2"
          checked={data?.length && !data?.find((item) => !item?.validated)}
          onChange={(element) => {
            const newData = data?.map((item) => ({...item, validated: element.target.checked}));
            setData(newData);
            setUpdatedRows(newData);
          }}
        />
      ),
    },
    {
      key: 'updated',
      field: 'updated',
      name: 'Validado por',
      width: '24%',
      textAlign: 'Center',
      editType: 'textedit',
      disableFilter: true,
    },
  ];

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Safra*',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione uma Safra',
          value: filter.harvest,
          required: true,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(optionsFilter?.harvests || [], [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: async (newValue) => {
            setFilter((prev) => ({...prev, harvest: newValue || ''}));
            const resp = await fetchstatusHarvest({
              harvest_id: newValue?.value || 0,
              environment: ENVS,
            });
            setStatus(resp?.data);
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Tipo de fazenda',
          name: 'farm_type',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione um tipo de fazenda',
          value: filter.farm_type,
          required: false,
          validations: {
            farm_type: Yup.string(),
          },
          options: [
            {label: 'Diagnóstico', value: 'diagnosis'},
            {label: 'Plataforma', value: 'platform'},
          ],
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, farm_type: newValue || ''}));
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Sistema Pecuário',
          name: 'category_livestock_system',
          type: 'autocompleteMultiple',
          hasPlaceholder: true,
          placeholder: 'Selecione um Sistema Pecuário',
          value: filter.category_livestock_system,
          required: false,
          validations: {
            category_livestock_system: Yup.string(),
          },
          options: normalizeCollectionData(optionsFilter?.category_livestock_system || [], [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, category_livestock_system: newValue || ''}));
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Indicador',
          name: 'indicator',
          type: 'autocomplete',
          hasPlaceholder: true,
          groupBy: (option) => {
            return option?.group_indicator_benchmarking?.title;
          },
          placeholder: 'Selecione um indicador',
          value: filter.indicator,
          required: !filter?.farm?.value,
          validations: {
            indicator: !filter?.farm?.value ? Yup.string().required('Campo obrigatório') : Yup.object(),
          },
          options: optionsFilter?.indicator?.map((item) => ({
            label: item?.title,
            value: item?.id,
            group_indicator_benchmarking: item?.group_indicator_benchmarking,
          })),
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, indicator: newValue || ''}));
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Fazenda',
          name: 'farm',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione uma Fazenda',
          value: filter.farm,
          required: !filter?.indicator,
          validations: {
            farm: !filter?.indicator?.value ? Yup.string().required('Campo obrigatório') : Yup.string(),
          },
          options: normalizeCollectionData(optionsFilter?.farms || [], [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, farm: newValue || ''}));
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Filtrar por fazendas participantes',
          name: 'enter_benchmarking',
          type: 'checkbox',
          hasPlaceholder: true,
          value: filter.enter_benchmarking,
          validations: {
            enter_benchmarking: Yup.boolean(),
          },
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, enter_benchmarking: newValue || ''}));
          },
          columns: {
            xs: 2,
          },
        },
        {
          label: 'Filtrar por registros Validadas',
          name: 'validated',
          type: 'checkbox',
          hasPlaceholder: true,
          value: filter.validated,
          validations: {
            validated: Yup.boolean(),
          },
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, validated: newValue || ''}));
          },
          columns: {
            xs: 2,
          },
        },
      ],
    },
  ];

  const dairyGroupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Safra*',
          name: 'harvest',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione uma Safra',
          value: filter.harvest,
          required: true,
          validations: {
            harvest: Yup.string().required('Campo obrigatório'),
          },
          options: normalizeCollectionData(optionsFilter?.harvests || [], [
            {alias: 'label', key: 'title'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: async (newValue) => {
            setFilter((prev) => ({...prev, harvest: newValue || ''}));
            const resp = await fetchstatusHarvest({
              harvest_id: newValue?.value || 0,
              environment: ENVS,
            });
            setStatus(resp?.data);
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Indicador',
          name: 'indicator',
          type: 'autocomplete',
          hasPlaceholder: true,
          groupBy: (option) => {
            return option?.group_indicator_benchmarking?.title;
          },
          placeholder: 'Selecione um indicador',
          value: filter.indicator,
          required: !filter?.farm?.value,
          validations: {
            indicator: !filter?.farm?.value ? Yup.string().required('Campo obrigatório') : Yup.object(),
          },
          options: optionsFilter?.indicator?.map((item) => ({
            label: item?.title,
            value: item?.id,
            group_indicator_benchmarking: item?.group_indicator_benchmarking,
          })),
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, indicator: newValue || ''}));
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Fazenda',
          name: 'farm',
          type: 'autocomplete',
          hasPlaceholder: true,
          placeholder: 'Selecione uma Fazenda',
          value: filter.farm,
          required: !filter?.indicator,
          validations: {
            farm: !filter?.indicator?.value ? Yup.string().required('Campo obrigatório') : Yup.string(),
          },
          options: normalizeCollectionData(optionsFilter?.farms || [], [
            {alias: 'label', key: 'name'},
            {alias: 'value', key: 'id'},
          ]),
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, farm: newValue || ''}));
          },
          columns: {
            xs: 4,
          },
        },
        {
          label: 'Filtrar por fazendas participantes',
          name: 'enter_benchmarking',
          type: 'checkbox',
          hasPlaceholder: true,
          value: filter.enter_benchmarking,
          validations: {
            enter_benchmarking: Yup.boolean(),
          },
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, enter_benchmarking: newValue || ''}));
          },
          columns: {
            xs: 2,
          },
        },
        {
          label: 'Filtrar por registros Validadas',
          name: 'validated',
          type: 'checkbox',
          hasPlaceholder: true,
          value: filter.validated,
          validations: {
            validated: Yup.boolean(),
          },
          onChange: (newValue) => {
            setFilter((prev) => ({...prev, validated: newValue || ''}));
          },
          columns: {
            xs: 2,
          },
        },
      ],
    },
  ];

  return (
    <div className="p-3">
      <H2>Avaliação do indicadores do Benchmarking</H2>

      <div className="d-flex flex-column ">
        <FilterBox className="filter">
          <GenerateForm
            onSubmit={(values, actions) => {
              if (updatedRows?.length) {
                setOpenDialog(actions);
              } else if (status !== 'IN_PROGRESS') {
                submit(values, actions);
              } else {
                actions.setSubmitting(false);
              }
            }}
            groupFields={ENVS === 'DAIRY_CATTLE' ? dairyGroupFields : groupFields}
            settings={{
              button: {
                text: 'Filtrar',
                textSubmitting: 'Filtrando....',
              },
            }}
          />
          {status === 'IN_PROGRESS' && <p className="message-status">Existe uma atualização em andamento para esta Safra...</p>}
        </FilterBox>
        <GridContainer className="pl-2 grid-container">
          {data?.length > 0 && <div className="totalizer">Quantidade de items que entram no Benchmarking: {totalizer}</div>}

          {data && (
            <TableView
              columns={ENVS === 'DAIRY_CATTLE' ? dairyColumns : columns}
              data={data?.sort((a, b) => {
                return a.id - b.id;
              })}
              style={{maxHeight: '500px', overflow: 'auto'}}
              onChange={async (element, el, key) => {
                const value = element.target.checked;
                if (updatedRows?.find((item) => item?.id === el?.id)) {
                  setUpdatedRows((prev) => [
                    ...prev.map((item) => {
                      if (item?.id === el?.id) {
                        return {...item, [key]: value};
                      }
                      return item;
                    }),
                  ]);
                } else {
                  setUpdatedRows((prev) => [...prev, {...el, [key]: value}]);
                }
                setData((prev) => [
                  ...prev.map((item) => {
                    if (item?.id === el?.id) {
                      return {...item, [key]: value};
                    }
                    return item;
                  }),
                ]);
              }}
            />
          )}
          {updatedRows?.length > 0 && (
            <button type="button" onClick={updateRows} className="btn-submit" disabled={updatedRows?.length === 0 || loading}>
              {loading ? 'Salvando...' : 'Salvar'}
            </button>
          )}
        </GridContainer>
      </div>
      <Dialog open={!!openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <i className="fas fa-engine-warning" />
          Atenção
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Existem Dados que foram modificados mas não foram salvos. Deseja realmente filtrar novos dados sem salvar os atuais?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              setData([]);
              await submit(openDialog?.values, openDialog);
              setUpdatedRows([]);
              setOpenDialog(false);
            }}
            color="primary"
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BenchmarkDashboard;
