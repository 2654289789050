import {DATAGRID} from '../../utils/constants';

export const insertData = (data) => ({
  type: DATAGRID.ROW.EVENT.INSERT_DATA,
  payload: data,
});

export const inputChange = (field, data) => ({
  type: DATAGRID.ROW.EVENT.DATA_CHANGED,
  payload: {
    field,
    data,
  },
});

export const clearInputData = () => ({
  type: DATAGRID.ROW.EVENT.CLEAR_DATA,
});

export const modeChanged = (mode) => ({
  type: DATAGRID.TABLE.EVENT.MODE_CHANGED,
  payload: mode,
});

export const setCurrentLine = (line) => ({
  type: DATAGRID.TABLE.EVENT.CURRENT_LINE_CHANGED,
  payload: line,
});

export const setCurrentCel = (line) => ({
  type: DATAGRID.TABLE.EVENT.CURRENT_CEL_CHANGED,
  payload: line,
});

export const setMode = (mode) => ({
  type: DATAGRID.TABLE.EVENT.MODE_CHANGED,
  payload: mode,
});

export const addTableActionToQueue = (actionName) => ({
  type: DATAGRID.TABLE.EVENT.ADD_TABLE_ACTION_TO_QUEUE,
  payload: actionName,
});

export const popTableActionQueue = () => ({
  type: DATAGRID.TABLE.EVENT.POP_TABLE_ACTION_TO_QUEUE,
});

export const addError = (inputName, error) => ({
  type: DATAGRID.ROW.EVENT.ADD_ERROR,
  payload: {
    inputName,
    error,
  },
});

export const removeError = (inputName, error) => ({
  type: DATAGRID.ROW.EVENT.REMOVE_ERROR,
  payload: {
    inputName,
    error,
  },
});

export const changeFilters = (field, filters) => ({
  type: DATAGRID.TABLE.EVENT.CHANGE_FILTER,
  payload: {
    field,
    filters,
  },
});

export const changeFiltersCheckbox = (field, checkboxList) => ({
  type: DATAGRID.TABLE.EVENT.CHANGE_FILTER_CHECKBOX,
  payload: {
    field,
    checkboxList,
  },
});
export const clearFilters = (field) => ({
  type: DATAGRID.TABLE.EVENT.CLEAR_FILTER,
  payload: {
    field,
  },
});
