/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import fileDownload from 'js-file-download';
import {CircularProgress} from '@material-ui/core';
import {fetchExportsXLS, fetchExportsCSV} from '../../../services/exports';
import {formatFarmsToExport} from '../../../utils/utils';
import {ExportationContainer, ButtonsContainer, ExportationButton} from './styled';
import FilterBenchmarkingDairy from '../../../components/FilterForms/filterBenchmarkingDairy';

const DairyBenchmarking = () => {
  const XLSX = 'XLS';
  const CSV = 'CSV';
  const [openFilter, setOpenFilter] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [harvest, setHarvest] = useState({});
  const [via, setVia] = useState({});
  const [farmsList, setFarmsList] = useState([]);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  useEffect(() => {
    const farmListString = formatFarmsToExport([farmsList]);
    setFarmsList(farmListString);
  }, [submitted]);// eslint-disable-line

  const fetchData = async (event, fileType) => {
    event.preventDefault();
    setLoading(true);
    try {
      let endpoint;
      let extension;
      switch (fileType) {
        case XLSX:
          endpoint = fetchExportsXLS;
          extension = 'xls';
          break;
        case CSV:
          endpoint = fetchExportsCSV;
          extension = 'zip';
          break;
        default:
          return;
      }

      const initDate = `${initialDate.year}-${initialDate.month < 10 ? `0${parseInt(initialDate.month, 10) + 1}` : `${parseInt(initialDate.month, 10) + 1}`}`;
      const finishDate = `${finalDate.year}-${finalDate.month < 10 ? `0${parseInt(finalDate.month, 10) + 1}` : `${parseInt(finalDate.month, 10) + 1}`}`;

      const response = await endpoint(
        {
          'wherein[harvest.id]': harvest.id,
          'wherein[customer.id]': customer.id,
          'wherein[farm.id]': `${farmsList}`,
          // initialDate: initDate,
          // endDate: finishDate,
          via,
        },
        'arraybuffer', // responseType,
        {
          Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        },
      );

      fileDownload(response.data, `${response.headers['content-disposition'].split('filename=')[1]}`);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <ExportationContainer>
      {openFilter && (
        <FilterBenchmarkingDairy
          setCustomer={setCustomer}
          setHarvest={setHarvest}
          setFarmsList={setFarmsList}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          setOpenFilter={setOpenFilter}
          setSubmitted={setSubmitted}
          setVia={setVia}
        />
      )}

      {submitted && (
        <ButtonsContainer>
          <ExportationButton onClick={(e) => fetchData(e, XLSX)}>{XLSX}</ExportationButton>
          <ExportationButton onClick={(e) => fetchData(e, CSV)}>{CSV}</ExportationButton>
        </ButtonsContainer>
      )}
      {loading ? <CircularProgress color="inherit" size={20} /> : null}
    </ExportationContainer>
  );
};

export default DairyBenchmarking;
