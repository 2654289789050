const months = ['Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'];
export class JsonModel {
  static generateResponse(first_year, last_year) {
    return months?.map((item, i) => {
      const year = i <= 6 ? first_year : last_year;
      return {
        date: `${item}/${year}`,
        values: [
          {value: 0.0, type: 'FLUXO_CAIXA_SEM_INVESTIMENTO'},
          {value: 0.0, type: 'INVESTIMENTO'},
          {value: 0.0, type: 'FLUXO_CAIXA_COM_INVESTIMENTO'},
        ],
      };
    });
  }
}
