import React, {useState, useContext, useEffect} from 'react';
import {Col, Row} from 'reactstrap';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import Loading from '../../../components/Loading/Loading';
import {HeadPage, BgModal, FaqContainer, ModalContent} from './styles';
import {DataContext} from '../../../utils/DataProvider';
import {deleteFaq, fetchFaq, createFaq, updateFaq} from '../../../services/faq';
import Alert from '../../../components/Notification';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {options, editorLabels} from '../../../utils/draft-wysiwyg-utils';
import QuestionItem from './questionItem';
import {fetchCategoryFaq} from '../../../services/categoryFaq';

const FaqRegister = () => {
  const [questions, setQuestions] = useState([]);
  const [categoryFaq, setCategoryFaq] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [modal, setModal] = useState(false);
  const [alertProps, setAlertProps] = useState(null);
  const [answer, setAnswer] = useState('');
  const {state} = useContext(DataContext);
  const ENVS = state.environment === 'dairy' ? 'DAIRY_CATTLE' : 'BEEF_CATTLE';
  const [editState, setEditState] = useState(null);

  const handleUpdate = (data) => {
    setModal(true);
    setEditState(data);
  };

  const uptateQuestion = async (e) => {
    try {
      const body = {
        question: e.target.question.value,
        answer: answer || editState.answer,
        category_faq: categoryFaq.find((item) => +e.target.category.value === item.id && {...item}),
        ordenation: Number(e.target.position.value),
        environment: ENVS,
      };
      if (answer || editState.answer) {
        await updateFaq(editState.id, body);
        setRefresh((prev) => !prev);
        setAlertProps({
          type: 'success',
          message: 'Pergunta editada com sucesso.',
          time: 5000,
        });
      } else {
        setAlertProps({
          type: 'error',
          message: 'Preencha todos os campos.',
          time: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteQuestion = async (id) => {
    try {
      await deleteFaq(id);
      setQuestions((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const createQuestion = async (e) => {
    try {
      const body = {
        question: e.target.question.value,
        answer,
        category_faq: categoryFaq.find((item) => +e.target.category.value === item.id && {...item}),
        ordenation: Number(e.target.position.value),
        environment: ENVS,
      };
      if (answer) {
        await createFaq(body);
        setRefresh((prev) => !prev);
        setAlertProps({
          type: 'success',
          message: 'Pergunta cadastrada com sucesso.',
          time: 5000,
        });
      } else {
        setAlertProps({
          type: 'error',
          message: 'Preencha todos os campos.',
          time: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (editState) {
      await uptateQuestion(e);
    } else {
      await createQuestion(e);
    }
    setSubmitting(false);

    setModal(false);
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const {data} = await fetchFaq({environment: ENVS});
        const dataCategoryFaq = await fetchCategoryFaq({environment: ENVS});
        setQuestions(data.resultList);
        setCategoryFaq(dataCategoryFaq.data.resultList);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [refresh]);// eslint-disable-line

  const orderDate = questions.sort((o1, o2) => {
    if (o1.created_at > o2.created_at) return -1;
    if (o1.created_at < o2.created_at) return 1;
    return 0;
  });

  return (
    <FaqContainer>
      <HeadPage>
        <h2>FAQ - Cadastro</h2>
        <button onClick={() => setModal(true)} type="button" className="btn btn-primary">
          + Adicionar
        </button>
      </HeadPage>

      {!loading ? (
        orderDate.map((ques) => <QuestionItem hasTag key={ques.id} question={ques} hasPermission deleteQuestion={deleteQuestion} handleUpdate={handleUpdate} />)
      ) : (
        <Loading />
      )}

      {modal && (
        <>
          <BgModal />
          <ModalContent>
            <button
              type="button"
              onClick={() => {
                setModal(false);
                setEditState(null);
              }}
              className="close-btn">
              +
            </button>
            <h2>{!editState ? 'Adicionar pergunta' : 'Editar pergunta'}</h2>
            <form onSubmit={onSubmit}>
              <Row>
                <Col className="mb-3" xs={12}>
                  <label>Pergunta:</label>
                  <input type="text" name="question" className="form-control" defaultValue={editState?.question} required />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" xs={12}>
                  <label>Categoria:</label>
                  <select type="select" name="category" id="category" className="form-control" defaultValue={editState?.category_faq?.id} required>
                    {categoryFaq?.map((category) => (
                      <option value={category.id}>{category.title}</option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3 draft-wrapper" xs={12}>
                  <label>Resposta:</label>
                  <Editor
                    localization={{locale: 'pt', translations: editorLabels}}
                    toolbar={options}
                    defaultEditorState={editState && EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(editState?.answer)))}
                    onContentStateChange={(contentState) => setAnswer(draftToHtml(contentState))}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <label>Ordem:</label>
                  <input type="number" name="position" className="form-control" defaultValue={editState?.ordenation} />
                </Col>
              </Row>
              <div className="footer-buttons">
                <button type="submit" className="btn btn-primary" disabled={submitting}>
                  {!submitting ? 'Salvar' : 'Salvando...'}
                </button>
              </div>
            </form>
          </ModalContent>
        </>
      )}

      {alertProps && <Alert type={alertProps.type} message={alertProps.message} time={alertProps.time} />}
    </FaqContainer>
  );
};
export default FaqRegister;
