import moment from 'moment';
import React, {useContext, useState, useRef, useEffect, createContext} from 'react';
import {getMonthName, isObject, isEmpty} from '../../../utils/utils';
import './style.scss';
import {SyntheticContext, DateContext, ModuleContext} from './contexts';
import {SYNTHETIC_TABLE} from './constants';
import TableProvider from './TableProvider';
import {DataContext} from '../../../utils/DataProvider';

const TableRowContext = createContext({});

const YearRow = ({initialYear, finalYear, monthsSubmitted}) => {
  const {isVisible, toggleField, findByType} = useContext(DateContext);

  const colSpan = (year) => {
    const monthLimit = year === initialYear ? (month) => month > 6 : (month) => month <= 6;
    const monthsSubmittedFromCurrentYear = monthsSubmitted.filter(monthLimit).length;
    const monthsToggled = findByType(SYNTHETIC_TABLE.HEADER_TYPES.MONTH).filter((monthToggled) => monthLimit(monthToggled.label)).length;
    const yearToggled = findByType(SYNTHETIC_TABLE.HEADER_TYPES.YEAR).find((toggledYear) => toggledYear.label === year);

    const yearCoefficient = yearToggled ? monthsSubmittedFromCurrentYear : 0;
    const monthCoefficient = monthsToggled ? monthsToggled * 2 - monthsToggled : 0;

    return yearCoefficient + monthCoefficient || 1;
  };

  const rowSpan = (year) => {
    const yearsToogled = findByType(SYNTHETIC_TABLE.HEADER_TYPES.YEAR);
    const yearToogled = yearsToogled.find((toogledYear) => toogledYear.label === year);

    const monthsToogled = findByType(SYNTHETIC_TABLE.HEADER_TYPES.MONTH).length;
    if (yearToogled) {
      return 1;
    }
    if (monthsToogled) {
      return 3;
    }
    if (yearsToogled.length) {
      return 2;
    }

    return 1;
  };

  return (
    <tr>
      <th rowSpan="3" style={{width: '250px'}}>
        {' '}
      </th>
      {initialYear && (
        <th
          colSpan={colSpan(initialYear)}
          rowSpan={rowSpan(initialYear)}
          data-index={`${initialYear}`}
          onClick={() => {
            toggleField({
              type: SYNTHETIC_TABLE.HEADER_TYPES.YEAR,
              label: initialYear,
              parent: null,
            });
          }}>
          <i className={isVisible(initialYear) ? 'fas fa-angle-down' : 'fas fa-angle-right'} />
          {initialYear}
        </th>
      )}
      {finalYear && (
        <th
          colSpan={colSpan(finalYear)}
          rowSpan={rowSpan(finalYear)}
          data-index={`${finalYear}`}
          onClick={() => {
            toggleField({
              type: SYNTHETIC_TABLE.HEADER_TYPES.YEAR,
              label: finalYear,
              parent: null,
            });
          }}>
          <i className={isVisible(finalYear) ? 'fas fa-angle-down' : 'fas fa-angle-right'} />
          {finalYear}
        </th>
      )}
    </tr>
  );
};

const ValidationRow = ({month, year}) => {
  return (
    <>
      <th className="validationCell" data-index={`${year}-${month}-total`}>
        Preenchido
      </th>
      <th className="validationCell" data-index={`${year}-${month}-validated`}>
        Validado
      </th>
      {/* <th> </th> */}
    </>
  );
};

const MonthRow = ({initialYear, finalYear, months}) => {
  const monthThs = [];
  const validationThs = [];
  const monthHeaderLength = 12;
  const {isVisible, toggleField, countByType} = useContext(DateContext);
  const hasMonthOpen = countByType(SYNTHETIC_TABLE.HEADER_TYPES.MONTH);
  const monthInInterval = (month) => months.includes(month);

  for (let index = 1; index <= monthHeaderLength; index += 1) {
    if (index <= monthHeaderLength / 2) {
      const calculatedIndex = monthHeaderLength / 2 + index;
      if (monthInInterval(calculatedIndex) && isVisible(initialYear)) {
        monthThs.push(
          <th
            key={`month_${calculatedIndex}`}
            className="monthCell"
            data-index={`${initialYear}-${calculatedIndex}`}
            colSpan={isVisible(calculatedIndex) ? 2 : 1}
            rowSpan={!isVisible(calculatedIndex) && hasMonthOpen ? 2 : 1}
            onClick={() => {
              toggleField({
                type: SYNTHETIC_TABLE.HEADER_TYPES.MONTH,
                label: calculatedIndex,
                parent: initialYear,
              });
            }}>
            <i className={isVisible(calculatedIndex) ? 'fas fa-angle-down' : 'fas fa-angle-right'} />
            {getMonthName(calculatedIndex)}
          </th>,
        );
      }
      if (initialYear && monthInInterval(calculatedIndex) && isVisible(initialYear) && isVisible(calculatedIndex)) {
        validationThs.push(<ValidationRow key={`validation_${calculatedIndex}`} month={calculatedIndex} year={initialYear} />);
      }
    } else if (index > monthHeaderLength / 2) {
      const calculatedIndex = index - monthHeaderLength / 2;
      if (finalYear && monthInInterval(calculatedIndex) && isVisible(finalYear)) {
        monthThs.push(
          <th
            key={`month_${calculatedIndex}`}
            className="monthCell"
            data-index={`${finalYear}-${calculatedIndex}`}
            colSpan={isVisible(calculatedIndex) ? 2 : 1}
            rowSpan={!isVisible(calculatedIndex) && hasMonthOpen ? 2 : 1}
            onClick={() => {
              toggleField({
                type: SYNTHETIC_TABLE.HEADER_TYPES.MONTH,
                label: calculatedIndex,
                parent: finalYear,
              });
            }}>
            <i className={isVisible(calculatedIndex) ? 'fas fa-angle-down' : 'fas fa-angle-right'} />
            {getMonthName(calculatedIndex)}
          </th>,
        );
      }

      if (finalYear && monthInInterval(calculatedIndex) && isVisible(finalYear) && isVisible(calculatedIndex)) {
        validationThs.push(<ValidationRow key={`validation_${calculatedIndex}`} month={calculatedIndex} year={finalYear} />);
      }
    }
  }

  return (
    <>
      {monthThs && !!monthThs.length && <tr>{monthThs}</tr>}
      {validationThs && !!validationThs.length && <tr>{validationThs}</tr>}
    </>
  );
};

const TableHeader = ({initialYear, finalYear, months}) => {
  return (
    <thead>
      <YearRow initialYear={initialYear} finalYear={finalYear} monthsSubmitted={months} />
      <MonthRow initialYear={initialYear} finalYear={finalYear} months={months} />
    </thead>
  );
};

const TableCell = ({id, level, index, title, onClick = () => {}}) => {
  const {data, lineage, getValue} = useContext(TableRowContext);
  const {visibleFields: visibleDateFields} = useContext(DateContext);
  const {isVisible, visibleFields: visibleModuleFields} = useContext(ModuleContext);

  const ref = useRef(null);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (ref.current && !title) {
      const table = ref.current.parentNode.parentNode.parentNode;
      const tableHeaderItems = Array.from(table.querySelector('thead').querySelectorAll('th'));

      const th = tableHeaderItems
        .filter((item) => item.offsetLeft === ref.current.offsetLeft)
        .reduce((acc, item) => {
          if (!acc) {
            return item;
          }
          if (acc.dataset.index && acc.dataset.index.length < item.dataset.index.length) {
            return item;
          }

          return acc;
        }, null);

      if (th && th.dataset.index) {
        setValue(getValue(lineage, th.dataset.index.split('-')));
      } else {
        setValue(null);
      }
    }
  }, [ref.current, visibleDateFields, visibleModuleFields, data]);// eslint-disable-line

  const className = () => {
    let classString = '';
    if (title) {
      classString += ' moduleCell';
    }
    if (index === 0) {
      classString += ' firstRow';
    }

    classString += ` level-${level}`;

    return classString;
  };

  return (
    <td
      onClick={() => {
        onClick();
      }}
      className={className()}
      ref={ref}>
      {title && level < 2 && <i className={isVisible(id) ? 'fas fa-angle-down' : 'fas fa-angle-right'} />}
      {value || title}
    </td>
  );
};

const TableRow = ({rowIndex}) => {
  const {currentNode} = useContext(TableRowContext);
  const {headerLength} = useContext(DateContext);
  const {isVisible, toggleField} = useContext(ModuleContext);
  const tdArray = [];
  for (let index = 1; index < headerLength; index += 1) {
    tdArray.push(<TableCell index={rowIndex} key={`${currentNode.label}${index}`} />);
  }
  return currentNode.parent ? (
    currentNode.label && !!isVisible(currentNode.parent) && (
      <tr>
        <TableCell
          onClick={() => {
            toggleField({
              label: currentNode.id,
              type: SYNTHETIC_TABLE.LINE_TYPES.MODULE,
              parent: currentNode.parent,
            });
          }}
          key={currentNode}
          title={currentNode.labelShow}
          id={currentNode.id}
          level={currentNode.level}
        />
        {tdArray}
      </tr>
    )
  ) : (
    <tr>
      <TableCell
        onClick={() => {
          toggleField({
            label: currentNode.id,
            type: SYNTHETIC_TABLE.LINE_TYPES.YEAR,
            parent: currentNode.parent,
          });
        }}
        key={currentNode}
        title={currentNode.label}
        id={currentNode.id}
        level={currentNode.level}
      />
      {tdArray}
    </tr>
  );
};

const TableBody = ({data, hierarchy}) => {
  const findElementLineage = (fullHierarchy, rootElement) => {
    const findElementinHierarchy = (idToFind) => {
      return fullHierarchy.find((el) => el.id === idToFind);
    };

    let currentElement = rootElement;
    let lineage = [rootElement.label];

    while (currentElement.parent) {
      const newEl = findElementinHierarchy(currentElement.parent);
      lineage = [newEl.label, ...lineage];
      currentElement = newEl;
    }

    return lineage;
  };

  const sumValues = (partialData, attribute, modules, atMonthLevel = false) => {
    if (!partialData) {
      return 0;
    }
    const sum = Object.keys(partialData).reduce((acc, key) => {
      const isEqualToAttributeOrHaveNoAttribute = (modules && modules.includes(key)) || !modules.length;
      const dataIsAValidObject = partialData[key] && isObject(partialData[key]) && (isEqualToAttributeOrHaveNoAttribute || atMonthLevel);

      if (dataIsAValidObject) {
        return (
          acc +
          sumValues(
            partialData[key],
            attribute,
            modules.filter((module) => module !== key),
          )
        );
      }

      if (attribute && partialData[key] && !isObject(partialData[key])) {
        return key === attribute ? acc + partialData[key] : acc;
      }

      if (!attribute && partialData[key] && !isObject(partialData[key])) {
        return acc + partialData[key];
      }

      return acc;
    }, 0);

    return sum;
  };

  const getValue = (moduleIndexes, dateIndexes) => {
    const [farm, ...moduleRest] = moduleIndexes;
    const [year, ...dateRest] = dateIndexes;
    let atMonthLevel = true;

    if (isEmpty(data) || !farm || !year) {
      return null;
    }

    if (!data[farm]) {
      return null;
    }

    let partialData = data[farm][year];

    if (dateRest[0] && dateRest[0] !== 'total' && partialData[dateRest[0]]) {
      partialData = partialData[dateRest[0]];
      atMonthLevel = false;
    }

    return sumValues(partialData, dateRest[1] || 'total', moduleRest, atMonthLevel);
  };

  const tRows = hierarchy.map((node, index) => {
    const lineage = findElementLineage(hierarchy, node);
    return (
      <TableRowContext.Provider key={`${node.label}_${node.id}`} value={{data, currentNode: node, lineage, getValue}}>
        <TableRow rowIndex={index} />
      </TableRowContext.Provider>
    );
  });
  return <tbody>{tRows}</tbody>;
};

const SyntheticDataPresenter = ({data}) => {
  const {submittedData} = useContext(SyntheticContext);
  const {state} = useContext(DataContext);
  // const [headerLength] = useState(39);

  const moduleHierarchy = (currentFarm) => {
    return [
      {
        id: 1,
        parent: null,
        label: currentFarm,
        labelShow: currentFarm,
        level: 0,
      },
      {id: 2, parent: 1, label: 'Clima', labelShow: 'Clima', level: 3},
      // {id: 3, parent: 2, label: 'Clima', labelShow: 'Clima', level: 2},
      {id: 4, parent: 1, label: 'Equipe', labelShow: 'Equipe', level: 3},
      // {id: 5, parent: 4, label: 'Equipe', labelShow: 'Equipe', level: 2},
      {id: 6, parent: 1, label: 'Estoque', labelShow: 'Estoque', level: 3},
      // {id: 7, parent: 6, label: 'Estoque', labelShow: 'Estoque', level: 2},
      {
        id: 8,
        parent: 1,
        label: 'Financeiro',
        labelShow: 'Financeiro',
        level: 1,
      },
      {
        id: 9,
        parent: 8,
        label: 'Financeiro Despesa',
        labelShow: 'Financeiro Despesa',
        level: 2,
      },
      {
        id: 10,
        parent: 8,
        label: 'Financeiro Receita',
        labelShow: 'Financeiro Receita',
        level: 2,
      },
      {id: 11, parent: 1, label: 'Maquina', labelShow: 'Máquina', level: 3},
      // {id: 12, parent: 11, label: 'Maquina', labelShow: 'Máquina', level: 2},
      {id: 13, parent: 1, label: 'Pecuaria', labelShow: 'Pecuária', level: 1},
      {id: 14, parent: 13, label: 'Areas', labelShow: 'Áreas', level: 2},
      {
        id: 15,
        parent: 13,
        label: 'Compras e outras entradas',
        labelShow: 'Compras e outras entradas',
        level: 2,
      },
      {
        id: 16,
        parent: 13,
        label: 'Confinamento (Entrada)',
        labelShow: 'Confinamento (Entrada)',
        level: 2,
      },
      {
        id: 15,
        parent: 13,
        label: 'Confinamento (Saida)',
        labelShow: 'Confinamento (Saida)',
        level: 2,
      },
      {id: 17, parent: 13, label: 'Desmame', labelShow: 'Desmame', level: 2},
      {
        id: 18,
        parent: 13,
        label: 'Estoque Pecuario',
        labelShow: 'Estoque Pecuário',
        level: 2,
      },
      {id: 19, parent: 13, label: 'Morte', labelShow: 'Morte', level: 2},
      {
        id: 20,
        parent: 13,
        label: 'Nascimento',
        labelShow: 'Nascimento',
        level: 2,
      },
      {
        id: 21,
        parent: 13,
        label: 'Reproducao Desmame',
        labelShow: 'Reprodução Desmame',
        level: 2,
      },
      {
        id: 22,
        parent: 13,
        label: 'Reproducao Monta/Toque',
        labelShow: 'Reprodução Monta/Toque',
        level: 2,
      },
      {
        id: 23,
        parent: 13,
        label: 'Reproducao Nascimento',
        labelShow: 'Reprodução Nascimento',
        level: 2,
      },
      {
        id: 24,
        parent: 13,
        label: 'Vendas e Outras Saidas',
        labelShow: 'Vendas e Outras Saídas',
        level: 2,
      },
    ];
  };

  const recursiveIterator = (obj, level = 0, currentLevel = 0) => {
    if (currentLevel === level) {
      return obj;
    }

    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key]) {
        return {
          ...acc,
          ...recursiveIterator(obj[key], level, currentLevel + 1),
        };
      }

      return acc;
    }, {});
  };

  const getKeysByLevel = (farmData, level) => {
    const recursiveResult = recursiveIterator(farmData, level);
    return Object.keys(recursiveResult);
  };

  const generateValidMonths = (initialYear, finalYear, initialMonth, finalMonth) => {
    const validMonths = [];

    for (let i = 12; i >= 1; i -= 1) {
      if (initialYear === finalYear && i >= initialMonth && i <= finalMonth) {
        validMonths.push(i);
      } else if (initialYear !== finalYear && (i >= initialMonth || i <= finalMonth)) {
        validMonths.push(i);
      }
    }

    return validMonths;
  };

  return Object.keys(data).map((key) => {
    let currentContext = submittedData || state;
    if (submittedData) {
      currentContext = submittedData;
    } else {
      currentContext = {
        ...state,
        harvest: {label: state.harvest.title},
        initialDate: moment(`${state.harvest.first_year}-07`),
        endDate: moment(`${state.harvest.last_year}-06`),
      };
    }
    const harvestYears = currentContext.harvest.label.split('/').map((year) => parseInt(year, 10));
    const years = [new Date(currentContext.initialDate).getFullYear(), new Date(currentContext.endDate).getFullYear()];
    const months = generateValidMonths(
      years[0],
      years[1],
      new Date(currentContext.initialDate).getMonth() + 1,
      new Date(currentContext.endDate).getMonth() + 1,
    );
    return (
      <TableProvider
        totalYears={years[0] === years[1] ? 1 : 2}
        months={{
          [years[0]]: months.filter((month) => month > 6),
          [years[1]]: months.filter((month) => month <= 6),
        }}
        key={key}>
        <table id={getKeysByLevel(data[key], 0)[0]}>
          <TableHeader
            initialYear={years[0] === years[1] && years[0] === harvestYears[1] ? null : years[0]}
            finalYear={years[0] === years[1] && years[1] === harvestYears[0] ? null : years[1]}
            months={months}
          />
          <TableBody data={data[key]} hierarchy={moduleHierarchy(getKeysByLevel(data[key], 0)[0])} />
        </table>
      </TableProvider>
    );
  });
};

export default SyntheticDataPresenter;
