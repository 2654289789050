import React, {useContext, useEffect} from 'react';
import {GuardedRoute} from 'react-router-guards';
import SidebarLayout from '../SidebarLayout';
import FilterEnvironment from '../FilterForms/FilterEnvironment';
import {DataContext} from '../../utils/DataProvider';
import {getEnvironmentsTechnical} from '../../utils/helperEnv';
import {FILTER} from '../../utils/constants';

const GuardedSidebarRoute = (props) => {
  const mobile = window.location.pathname.includes('mobile');
  const {state, dispatch} = useContext(DataContext);

  useEffect(() => {
    (async () => {
      const envs = await getEnvironmentsTechnical(state?.user?.id);
      if (state?.user?.user_type === 'TECHNICAL' && Object.keys(envs).length === 1) {
        dispatch({
          type: FILTER.ACTION.SAVE_DATA,
          payload: {
            ...state,
            farm: null,
            customer: null,
            environment: Object.keys(envs)[0] === 'DAIRY_CATTLE' ? 'dairy' : 'beef',
          },
        });
      }
    })();
  }, []);// eslint-disable-line

  return (
    <>
      {!mobile ? (
        <>
          {state && !state.environment ? (
            <>
              <FilterEnvironment />
              {/* eslint-disable-next-line */}
              <GuardedRoute {...props} />
            </>
          ) : (
            <SidebarLayout>
              {/* eslint-disable-next-line */}
              <GuardedRoute {...props} />
            </SidebarLayout>
          )}
        </>
      ) : (
        <div className="mobile w-100">
          {/* eslint-disable-next-line */}
          <GuardedRoute {...props} />
        </div>
      )}
    </>
  );
};

export default GuardedSidebarRoute;
