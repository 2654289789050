import api from '../api';

const fetchIndicator = (params = {}) => {
  return api.get(`/indicators_benchmarking`, {
    params: {
      ...params,
    },
  });
};
const fetchBenchmark = (params = {}) => {
  return api.get(`/benchmarking/datagrid`, {
    params: {
      ...params,
    },
  });
};
const fetchLastUpdated = (params = {}) => {
  return api.get(`/log_benchmarking/last_update`, {
    params: {
      ...params,
    },
  });
};
const fetchstatusHarvest = (params = {}) => {
  return api.get(`/log_benchmarking/status`, {
    params: {
      ...params,
    },
  });
};

const fetchBenchmarkingPDF = (params = {}, responseType = '', headers = {}) => {
  return api.get(`/benchmarking/pdf`, {
    params: {
      ...params,
    },
    responseType: `${responseType}`,
    headers: {
      ...headers,
    },
  });
};

const createUpdated = (body = {}) => {
  return api.post(`/benchmarking/update`, {
    ...body,
  });
};
const saveData = (body) => {
  return api.post(`/benchmarking/batch`, body);
};

export {fetchIndicator, fetchBenchmark, fetchLastUpdated, createUpdated, saveData, fetchstatusHarvest, fetchBenchmarkingPDF};
