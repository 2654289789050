import React, {useState} from 'react';
import {Card} from 'reactstrap';
import {GuardedRoute} from 'react-router-guards';
import './style.scss';
import Section from '../../components/Section/Section';
import AreaDistibution from './AreaDistribution';
import ArrobaValues from './ArrobaValues';
import InitialAnalysisArguments from './InitialAnalysisArguments';
import LandsImprovements from './LandsImprovements';
import OtherSectors from './OtherSectors';
import IndicatorsPlanned from './IndicatorsPlanned';
import CashAnalysis from './CashAnalysis';

const Settings = ({match}) => {
  const [templateData] = useState([
    {
      category: 'Reportagem',
      options: [
        {
          group: ' ',
          options: [
            {
              title: 'Distribuição de área',
              url: `${match.url}/distribuicao-area`,
            },
            {
              title: 'Outros setores',
              url: `${match.url}/outros-setores`,
            },
            {
              title: 'Terra benfeitoria',
              url: `${match.url}/terra-benfeitoria`,
            },
            {
              title: 'Valor do peso',
              url: `${match.url}/valor-peso`,
            },
            {
              title: 'Indicador planejado',
              url: `${match.url}/indicador-planejado`,
            },
            {
              title: 'Ponderações iniciais sobre as análises',
              url: `${match.url}/ponderacoes-iniciais-sobre-analises`,
            },
            {
              title: 'Análise de caixa',
              url: `${match.url}/analise-caixa`,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className="settings h-100">
      <Card className="pt-0 overflow-auto h-100">
        <GuardedRoute
          exact
          path={match.url}
          meta={{moduleName: 'Configuração'}}
          render={() => <Section templateData={templateData} />}
        />
        <GuardedRoute
          path={`${match.url}/distribuicao-area`}
          meta={{moduleName: 'Distribuição de área'}}
          component={AreaDistibution}
        />
        <GuardedRoute
          path={`${match.url}/outros-setores`}
          meta={{moduleName: 'Outros setores'}}
          component={OtherSectors}
        />
        <GuardedRoute
          path={`${match.url}/terra-benfeitoria`}
          meta={{moduleName: 'Terra benfeitoria'}}
          component={LandsImprovements}
        />
        <GuardedRoute
          path={`${match.url}/valor-peso`}
          meta={{moduleName: 'Valor do peso'}}
          component={ArrobaValues}
        />
        <GuardedRoute
          path={`${match.url}/indicador-planejado`}
          meta={{moduleName: 'Indicador planejado'}}
          component={IndicatorsPlanned}
        />
        <GuardedRoute
          path={`${match.url}/ponderacoes-iniciais-sobre-analises`}
          meta={{moduleName: 'Ponderações iniciais sobre as análises'}}
          component={InitialAnalysisArguments}
        />
        <GuardedRoute
          path={`${match.url}/analise-caixa`}
          meta={{moduleName: 'Análise de caixa'}}
          component={CashAnalysis}
        />
      </Card>
    </div>
  );
};
export default Settings;
